import React, {useEffect, useRef, useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import IProductsCart from '../../../models/ICart';
import { AppState } from '../../../store';
import { getCalcCartValue } from '../../../utils/getCartTotalValue';
import { transformPrice } from '../../../utils/transformPrice';
import AlertModal, { ModalHandles } from '../../AlertModal/AlertModal';
import BuyProductInCartShippingChoiceItem from './BuyProductsCartShippingChoiceItem/BuyProductsCartShippingChoiceItem';
import ReactHtmlParser from 'react-html-parser';
import { Container, MainContainer } from './styles';
import IUserAccount from '../../../models/IUserAccount';
import { removeProductCartAction } from '../../../actions/ProductsCartActions';

const BuyProductsInCartShippingChoice: React.FC = () => {
	const productsCart: IProductsCart[] = useSelector((state: AppState) => state.ProductsCart.currentProductsCart);
	const user: IUserAccount = useSelector((state: AppState) => state.user.currentUser);
	
	const history = useHistory();
	const dispatch = useDispatch()
	const modalRef = useRef<ModalHandles>(null);

	const [buttonDisabled,setDisabledButton] = useState<boolean>(true)

	const nextStep = () => {
		if (productsCart.find((product) => !product.parameters.shippingWay)) {
			modalRef.current?.handleOpenModal();
		} else {
			history.push('/buy-payment-process-cart');
		}
	};

	useEffect(()=>{
		if(productsCart.length === 0){
			history.push('/');
		}
		if (productsCart.find((product) => !product.parameters.shippingWay)) {
			setDisabledButton(true)
		} else {
			setDisabledButton(false)
		}
	},[productsCart])

	useEffect(() => {
		removerProdutosDoProprios()
	}, [])

	function removerProdutosDoProprios(){
		if(user && productsCart){
			let produtosFiltrados = productsCart.filter((produto)=> produto.parameters.product.UserAccount.id === user.id)
			produtosFiltrados.map((itemCarrinho:IProductsCart)=>{
				 dispatch(removeProductCartAction(itemCarrinho.parameters.product.id))
			})

		}
	}


	const [showMore, setShowMore] = useState<boolean>(false);

	function getFormaDeEnvio(product:any){
		if(product){
			if(product.service?.name!) return product.service?.name!
			if(product.shippingWay === 'withdraw') return 'Combinar com o vendedor'
			if(product.shippingWay === 'freeDelivery') return 'Frete Grátis'
		}
	}

	function getCusto(product:any){
		if(product){
			if (product?.service?.custom_price!) return product?.service?.custom_price!
			if(product.shippingWay === 'withdraw') return 'Combinar com o vendedor'
			return 'Selecione uma opção de envio'
		}
	}

	return (
		<>
			<AlertModal
				ref={modalRef}
				DescriptionText="Escolha uma forma de envio para todos os produtos"
				AreaTitle="Compra"
				reload={false}
			/>
			<Container>
				<MainContainer>
					<div className="class-column">
						<div className="card-header">
							<h2>Forma de envio</h2>
						</div>

						{productsCart?.map((item: IProductsCart) => (
							<>
								<BuyProductInCartShippingChoiceItem
									item={item}
									key={item?.parameters?.product!.id}
								/>
							</>
						))}

						<div className="next-button-container">
							<button
								disabled={buttonDisabled}
								onClick={() => nextStep()}>
								Continuar
							</button>
						</div>
					</div>
					<div className="infos-card">
						<div className="card-title">
							<h2>
								{`${productsCart?.length} ${
									productsCart?.length > 1
										? 'Produtos'
										: 'Produto'
								}`}{' '}
								no carrinho
							</h2>
						</div>
						<div className="card-summary">
							{productsCart?.map((item: IProductsCart, index) => (
								<>
									{!showMore ? (
										<>
											{index < 1 && (
												<p
													key={
														item?.parameters
															?.product.id
													}
												>
													{'- '}(
													{item?.parameters
														?.quantity + 'x'}
													)
													{' ' +
														item?.parameters
															?.product?.title}
												</p>
											)}
										</>
									) : (
										<p key={item?.parameters?.product.id}>
											{'- '}(
											{item?.parameters?.quantity + 'x'})
											{' ' +
												item?.parameters?.product
													?.title}
										</p>
									)}
								</>
							))}
							{!showMore ? (
								<a onClick={() => setShowMore(true)} className="vermais">
									Ver mais {productsCart?.length - 1} itens...
								</a>
							) : (
								<a onClick={() => setShowMore(false)}>
									Recolher
								</a>
							)}
						</div>
						<div className="shipping-details">
							<h3>Detalhes do envio: </h3>
							{productsCart.map((item) => (
								<>
									<>
										{
											<>
												{item?.parameters?.product.withdraw === 'Sim' || item?.parameters?.product.withdraw === 'exclusivamente' ? (
													<>
														<p>
															{' '}
															{item
																?.parameters
																?.product
																?.title +
																':'}{' '}
														</p>
														<h3>Forma de envio:</h3><p>{getFormaDeEnvio(item?.parameters)}</p>
														<h3>Custo:</h3><p>{getCusto(item?.parameters)}</p>
													</>
												):<></>}
											</>
										}
									</>
								</>
							))}
						</div>
						<div className="price-info">
							<h2>
								Total a pagar:{' '}
								{ReactHtmlParser(transformPrice(getCalcCartValue(productsCart).toLocaleString('pt-br', {minimumFractionDigits: 2,})
									)
								)}
							</h2>
						</div>
					</div>
				</MainContainer>
			</Container>
		</>
	);
};

export default BuyProductsInCartShippingChoice;
