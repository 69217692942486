import React from 'react';
import BuyProductsInCartShippingChoice from '../../components/BuyProductsInCart/BuyProductsInCartShippingChoice/BuyProductsInCartShippingChoice';
import Layout from '../../components/Layout/Layout';

const BuyProductInCardAddressProcessPage = () => {
	return (
		<Layout title="Frete">
			<BuyProductsInCartShippingChoice />
		</Layout>
	);
};

export default BuyProductInCardAddressProcessPage;
