import { createStore, combineReducers } from 'redux'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import ProductsCartReducer from '../reducers/ProductCartReducer'
import UserCardsReducer from '../reducers/UserCardsReducer'
import UserReducer from '../reducers/UserReducer'
import { devToolsEnhancer } from 'redux-devtools-extension'
import UserCustomizationsReducer from '../reducers/UserCustomizationsReducer'
import EditedProductReducer from '../reducers/EditedProductReducer'
import AllUsersReducer from '../reducers/AllUsersReducer'
import BuyParamsReducer from '../reducers/BuyParamsResult'
import CookiesReducer from '../reducers/CookiesReducer'
import CheckpointReducer from '../reducers/CheckpointReducer'
import ModalBurgerReducer from '../reducers/ModalBurgerReducer'

const KEY = localStorage.getItem('token') || Date.now().toString()

const rootReducer: any = combineReducers({
    user: UserReducer,
    UserCards: UserCardsReducer,
    ProductsCart: ProductsCartReducer,
    UserCustomizations: UserCustomizationsReducer,
    EditedProduct: EditedProductReducer,
    AllUsers: AllUsersReducer,
    BuyParams: BuyParamsReducer,
    cookies: CookiesReducer,
    checkpoint: CheckpointReducer,
    modalBurgerReducer: ModalBurgerReducer,
})

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user', 'ProductsCart', 'BuyParams', 'cookies', 'checkpoint'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store =
    process.env.NODE_ENV === 'production'
        ? createStore(persistedReducer)
        : createStore(persistedReducer, devToolsEnhancer({}))

export type AppState = ReturnType<typeof rootReducer>

export const persistor = persistStore(store)

export default store
