import styled from 'styled-components';

export const Container = styled.div`
	#choose-delivery-address {
		width: 692px;
		height: 375px;
	}
	.modal-container {
		padding: 38px;
		.card-options {
			input {
				width: 21px;
				height: 21px;
			}
			input[type='radio']:checked {
				width: 21px;
				height: 21px;
			}
			.MuiFormControlLabel-root {
				.MuiButtonBase-root {
					.MuiIconButton-label {
						.PrivateRadioButtonIcon-root-5 {
							.MuiSvgIcon-root {
								width: 45px !important;
								height: 34px !important;
							}
						}
					}
				}
			}

			.input-color {
				root {
					color: #6b98a6;
				}
				span {
					span {
						div {
							flex-direction: row;
							svg {
							}
							svg {
								width: 45px !important;
								height: 34px !important;
							}
						}
					}
				}
			}
			.input-color:checked {
				color: #6b98a6;
			}
		}
	}
`;
export const ModalContainer = styled.div`
	padding: 40px;
	height: 260px;
	overflow: auto;
	padding-bottom: 0px;
	padding-top: 20px;
	background-color: #fff;

	@media (max-width: 768px) {
		padding: 20px;
	}

	.mobile-title {
		color: #6b98a6;
		font-family: 'Poppins';
		font-weight: bold;
		font-size: 13px;
	}
	.mobile-description {
		color: black;
		font-family: 'Poppins';
		font-weight: 400;
		font-size: 12px;
	}
	.card-options {
		border-bottom: solid 1px black;
		padding-bottom: 10px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		padding-top: 10px;
		padding: 10px;

		@media (max-width: 768px) {
			border: solid 1px #e1e1e1;
			border-radius: 15px;
		}

		label {
			color: black;
			font-size: 12px;
			font-family: 'Poppins';
			font-weight: 400;
		}
	}
`;
export const ActionsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 40px;
	padding-top: 0px;
	padding-bottom: 0px;
	background-color: #fff;

	.add-new-address {
		width: 591px;
		height: 34px;
		background: #fff 0% 0% no-repeat padding-box;
		border-radius: 8px;
		color: black;
		font-family: 'Poppins';
		border: 1px solid black;
		font-weight: 400;

		@media (max-width: 768px) {
			border-radius: 15px;
		}

		@media (max-width: 768px) {
			width: 100%;
		}

		&:focus {
			outline: none;
		}
	}

	.modal-buttons {
		margin-top: 15px;
		display: flex;
		flex-direction: row;
		margin-bottom: 15px;

		@media (max-width: 768px) {
			width: 100%;
			justify-content: space-between;
		}

		.close-modal {
			background: #ca667e;
			margin-right: 8px;
			width: 86px;
			height: 41px;
			font-family: 'Poppins';
			font-weight: bold;
			border: none;
			border-radius: 8px;
			font-size: 12px;
			color: white;
		}
		.select-address-modal {
			color: white;
			background: #6b97a5;
			font-family: 'Poppins';
			font-weight: bold;
			font-size: 12px;
			border: none;
			width: 150px;
			border-radius: 8px;
			height: 41px;
		}
	}
`;
