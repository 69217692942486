import { CardsAction, CardState } from '../models/IUserCards';

const initialState: CardState = {
	currentCards: [],
};

export default function UserCardsReducer(
	state = initialState,
	action: CardsAction
) {
	switch (action.type) {
		case 'INSERT_CARDS':
			return { ...state, currentCards: action.cards };

		case 'INSERT_ONE_CARD':
			return {
				...state,
				currentCards: [...state.currentCards, action.card],
			};

		default:
			return state;
	}
}
