import styled from 'styled-components'

export const IconPassWord = styled.div`
    cursor: pointer;
    width: 4vw;
    border-radius: 50%;
    padding: 4px;
    position: absolute;
    right: -2rem;
    top: 1.75rem;

    @media (max-width: 1460px) {
        right: 1.75rem;
    }
`

interface RegisterInputProps {
    border?: string
}

export const Container = styled.form`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 68px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    align-items: center;
    background-color: #fff;

    @media (min-width: 425px) and (max-width: 768px) {
        background-color: #05a5bf;
        height: auto;
        min-height: 100vh;
        padding-top: 0;
    }
    @media (min-width: 375px) and (max-width: 424px) {
        background-color: #05a5bf;
        height: auto;
        min-height: 100vh;
        padding-top: 0;
    }
    @media (min-width: 320px) and (max-width: 374px) {
        background-color: #05a5bf;
        height: auto;
        min-height: 100vh;
        padding-top: 0;
    }

    .line {
        border-bottom: solid 1px black;
        width: 40%;
        margin-bottom: 25px;

        @media (max-width: 768px) {
            display: none;
        }
    }
    .infos-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        img {
            display: none;
        }

        @media (min-width: 425px) and (max-width: 768px) {
            img {
                display: none;
            }
        }

        h1 {
            font-family: 'Poppins', extra-light;
            font-weight: 200;
            font-size: 40px;
            color: #6b98a6;
            margin-bottom: 5px;

            @media (min-width: 425px) and (max-width: 768px) {
                margin-top: 30px;
                margin-bottom: 50px;
                color: white;
                font-weight: 200;
            }
            @media (min-width: 375px) and (max-width: 424px) {
                margin-top: 20px;
                margin-bottom: 40px;
                color: white;
                font-weight: 200;
            }
            @media (min-width: 320px) and (max-width: 424px) {
                margin-top: 10px;
                margin-bottom: 30px;
                color: white;
                font-weight: 200;
            }
        }
        h3 {
            margin-top: 0;
            font-size: 17px;
            font-weight: 600;
            font-family: 'Poppins', semi-bold;
            color: #6b98a6;
            margin-bottom: 40px;
            @media (max-width: 768px) {
                display: none;
            }
        }
    }

    .input-organizer {
        display: flex;
        flex-direction: column;

        @media (min-width: 425px) and (max-width: 768px) {
            max-width: 100%;
            width: 90%;
            justify-content: center;
            align-items: center;
        }
        @media (min-width: 375px) and (max-width: 424px) {
            max-width: 100%;
            width: 90%;
            justify-content: center;
            align-items: center;
        }
        @media (min-width: 320px) and (max-width: 374px) {
            max-width: 100%;
            width: 90%;
            justify-content: center;
            align-items: center;
        }

        .input-container {
            display: flex;
            flex-direction: column;
            position: relative;
            label {
                color: #6b98a6;
                font-family: 'Poppins';
                font-weight: bold;
                font-size: 12px;
                margin-bottom: 5px;

                @media (max-width: 768px) {
                    color: white;
                    font-weight: 600;
                    font-size: 15px;
                    margin-left: 5px;
                }
            }
        }
        .forgot-password {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .link-mobile {
                display: none;
                color: white;
                font-family: 'Poppins';
                font-weight: 400;
                font-size: 13px;
                @media (max-width: 768px) {
                    display: flex;
                }
            }
            .link {
                @media (max-width: 768px) {
                    display: none;
                }
            }
        }
        button {
            margin-top: 30px;
            margin-bottom: 20px;
            background-color: #04aac0;
            align-self: center;
            border: 1px solid black;
            border-radius: 5px;
            color: white;
            font-family: 'Poppins';
            font-weight: bold;
            font-size: 17px;
            width: 193px;
            height: 48px;
            cursor: pointer;

            @media (max-width: 768px) {
                background-color: #04aac0;
                border-radius: 27px;
                font-size: 18px;
                width: 173px;
                height: 53px;
                outline-color: #04aac0;
                border: 1px solid black;
            }
        }

        .styleConta {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            color: black;
        }
    }
`

export const RegisterInput = styled.input<RegisterInputProps>`
    border: ${(Prop) => Prop.border};
    color: black;
    font-size: 16px;
    padding: 7px;
    font-family: 'Poppins', regular;
    border-radius: 5px;
    background-color: #fff;
    width: 372px;
    height: 48px;
    outline-color: #04aac0;

    @media (max-width: 768px) {
        border-radius: 12px;
        width: 278px;
        height: 40px;
        border: none;
        color: black;
        outline-color: #04aac0;

        input::-webkit-input-placeholder {
            font-style: italic;
            opacity: 0.5;
        }

        input:focus {
            outline: none;
        }
        input:focus::-webkit-input-placeholder {
            color: transparent;
        }
    }
`

export const MensagemError = styled.span`
    color: red;
    margin-left: 3px;
    padding: 2px;
`
export const MensagemErrorAutenticacao = styled.span`
    color: red;
    font-size: 16px;
    margin-left: 8rem;
    padding: 2px;
`
