import styled from 'styled-components';

interface ContainerProps {
	iconFill: string;
	backgroundColor: string;
}

export const Container = styled.div<ContainerProps>`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 20px 0px;
	padding-left: 40px;
	width: 100%;
	height: 50px;
	background: ${(Prop) => Prop.backgroundColor};

	h2 {
		margin: 0px;
		font-family: 'Poppins';
		font-weight: 500;
		font-size: 14px;
		color: ${(Prop) => Prop.iconFill};
		margin-left: 30px;
	}

	.icon {
		fill: ${(Prop) => Prop.iconFill};
	}
`;
