import React from 'react'
import { useHistory } from 'react-router'
import { Menu } from '@headlessui/react'
import { ProfileMenuButton, ProfileMenuBar } from './styles'
import icon_account from '../../assets/icons/icon_userprofile.svg'
import icon_arrow_categories from '../../assets/icons/icon_arrow_categories.svg'
import icon_userprofile from '../../assets/icons/profile-icon-bar.svg'
import icon_money from '../../assets/icons/icon_money.svg'
import icon_exit from '../../assets/icons/icon_exit.svg'
import icon_help from '../../assets/icons/icon_help_header.svg'
import icon_whats from '../../assets/icons/icon_whats_black.svg'
import IUserAccount from '../../models/IUserAccount'
import { AppState } from '../../store'
import { useSelector } from 'react-redux'
import { handleLogout } from '../../handlers/handleLogout'
import useWindow from '../../hooks/useWindow'

const ProfileMenu = () => {
    const window = useWindow()
    const history = useHistory()

    const user: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )

    const firstname = user.name.split(' ')[0]
    return (
        <Menu>
            <ProfileMenuButton>
                <Menu.Button
                    style={{ background: 'transparent', border: 'none' }}
                >
                    {window.innerWidth > 1023 && (
                        <div className="section-user-profile-title">
                            <img
                                src={icon_account}
                                alt="icone-usuario"
                                className="icon-profile"
                            />
                            <span className="user-profile-span-name">
                                {firstname}
                            </span>
                            <img
                                src={icon_arrow_categories}
                                alt="icon-abrir-perfil-usuario"
                                className="style-icon-arrow-user-profile"
                            />
                        </div>
                    )}
                    {window.innerWidth > 767 && window.innerWidth < 1024 && (
                        <></>
                    )}
                    {window.innerWidth < 768 && <></>}
                </Menu.Button>
            </ProfileMenuButton>
            <Menu.Items>
                <ProfileMenuBar>
                    <div className="section-menu-bar">
                        <Menu.Item>
                            <div
                                className="section-option-profile"
                                onClick={() => history.push(`/user/data/top`)}
                            >
                                <img
                                    src={icon_userprofile}
                                    alt="icon-user-profile"
                                    className="style-icon-profile-bar"
                                />
                                <h2 className="span-profile-menu-bar">
                                    Perfil
                                </h2>
                            </div>
                        </Menu.Item>
                        <Menu.Item>
                            <div
                                className="section-option-sales"
                                onClick={() => history.push(`/my-buys`)}
                            >
                                <img
                                    src={icon_money}
                                    alt="icon-vendas"
                                    className="icon-vendas"
                                />
                                <span className="span-sales-menu-bar">
                                    Compras
                                </span>
                            </div>
                        </Menu.Item>
                        <Menu.Item>
                            <div
                                className="section-option-help"
                                onClick={() => history.push(`/faq`)}
                            >
                                <img
                                    src={icon_help}
                                    alt="icone-ajuda"
                                    className="icon-help"
                                />
                                <span className="span-help-menu-bar">
                                    Ajuda
                                </span>
                            </div>
                        </Menu.Item>
                        <Menu.Item>
                            <div className="section-option-sac">
                                <a
                                    href="https://api.whatsapp.com/send?phone=5541985094049&text=Ol%C3%A1%20Sambay%20Express!%20Preciso%20de%20uma%20ajuda%20de%20voc%C3%AAs%20%3A)"
                                    className="span-sac-menu-bar"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        src={icon_whats}
                                        alt="icon-suporte"
                                        className="icon-suporte"
                                    />
                                    <span className="span-sac-menu-bar">
                                        SAC
                                    </span>
                                </a>
                            </div>
                        </Menu.Item>
                        <Menu.Item>
                            <div
                                className="section-option-exit"
                                onClick={() => handleLogout()}
                            >
                                <img
                                    src={icon_exit}
                                    alt="icon-sair"
                                    className="icon-sair"
                                />
                                <span className="span-sair-menu-bar">Sair</span>
                            </div>
                        </Menu.Item>
                    </div>
                </ProfileMenuBar>
            </Menu.Items>
        </Menu>
    )
}
export default ProfileMenu
