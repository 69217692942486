import styled from 'styled-components'
interface RegisterInputProps {
    border?: string
}

export const IconPassWord = styled.div`
    cursor: pointer;
    width: 3vw;
    border-radius: 50%;
    padding: 4px;
    position: absolute;
    right: -1.5rem;
    @media (min-width: 320px) and (max-width: 768px) {
        right: 2rem;
    }
    top: 2rem;
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    align-items: center;
    padding-bottom: 6rem;
    height: auto;

    @media (min-width: 320px) and (max-width: 475px) {
        background-color: #fff;
    }
    .other-container {
        width: 741px;
        @media (min-width: 320px) and (max-width: 475px) {
            width: 100%;
        }
    }
    .field-errors {
        font-weight: 400;
        font-size: 12px;
        color: red;
        @media (max-width: 768px) {
            font-weight: 600;
            color: red;
        }
    }

    .organizer {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (min-width: 320px) and (max-width: 475px) {
            width: 80%;
        }
    }

    .confirm-button {
        margin-right: 10px;
        border-radius: 5px;
        align-self: center;
        border: none;
        font-size: 20px;
        background-color: #04aac0;
        color: white;
        font-size: 20px;
        font-family: 'Poppins';
        font-weight: bold;
        width: 193px;
        height: 48px;

        @media (min-width: 320px) and (max-width: 768px) {
            background-color: #04aac0;
            height: 3.8rem;
            border-radius: 27px;
            font-size: 16px;
            margin-bottom: 3rem;
            border: 1px solid black;
        }
        .back-icon {
            .a {
                fill: #6b98a6;
            }
        }
    }

    .back {
        width: 44px;
        height: 48px;
    }
    .confirm-button:hover {
        cursor: pointer;
    }

    label {
        font-size: 14px;
        font-family: 'Poppins';
        font-weight: bold;
        color: #6b98a6;
        margin-bottom: 8px;
    }

    .organizer-register {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 930px;
        padding-top: 0;

        @media (min-width: 425px) and (max-width: 768px) {
            width: 100%;
        }
        @media (min-width: 375px) and (max-width: 424px) {
            width: 100%;
        }
        @media (min-width: 320px) and (max-width: 374px) {
            width: 100%;
        }
    }

    label {
        font-size: 14px;
        font-family: 'Poppins';
        font-weight: bold;
        color: #6b98a6;
        margin-bottom: 8px;

        @media (max-width: 768px) {
            font-weight: 600;
            font-size: 15px;
            margin-left: 3px;
            color: #6b98a6;
        }
    }

    @media (min-width: 425px) and (max-width: 475px) {
        .organizer-register {
            padding-top: 0;

            h1 {
                display: none;
            }

            .logoSambay {
                display: none;
            }
            input {
                border-radius: 12px;
            }
            .terms-of-use {
                .label {
                    font-size: 0.85rem;
                    padding-top: 0.75rem;
                    color: black;
                    .privacidades {
                        color: #dcdcdc;
                    }
                }
            }
        }
    }

    @media (min-width: 375px) and (max-width: 424px) {
        .organizer-register {
            padding-top: 0;

            h1 {
                display: none;
            }
            .logoSambay {
                display: none;
            }
            input {
                border-radius: 12px;
            }
            .terms-of-use {
                .label {
                    font-size: 0.75rem;
                    padding-top: 0.75rem;
                    color: black;
                    .privacidades {
                        color: #dcdcdc;
                    }
                }
            }
        }
    }

    @media (min-width: 320px) and (max-width: 374px) {
        .organizer-register {
            padding-top: 0;

            h1 {
                display: none;
            }
            .logoSambay {
                display: none;
            }
            input {
                border-radius: 12px;
            }
            .terms-of-use {
                .label {
                    font-size: 0.65rem;
                    padding-top: 0.75rem;
                    color: black;
                    .privacidades {
                        color: #dcdcdc;
                    }
                }
            }
        }
    }
`

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;

    img {
        display: none;
        width: 278px;
        height: 83px;
        @media (min-width: 425px) and (max-width: 768px) {
            display: none;
        }
    }

    h1 {
        font-size: 40px;
        color: #6b98a6;
        font-family: 'Poppins', Extra-light;
        font-weight: 200;
        margin-bottom: 4px;
        margin-top: 1rem;

        @media (min-width: 425px) and (max-width: 768px) {
            color: #6b98a6;
            font-family: 'Poppins';
            font-weight: 400;
            margin-top: 0rem;
            font-size: 2.5rem;
            padding-top: 2rem;
            padding-bottom: 1.5rem;
        }
        @media (min-width: 375px) and (max-width: 424px) {
            color: #6b98a6;
            font-family: 'Poppins';
            font-weight: 400;
            margin-top: 0rem;
            font-size: 2.5rem;
            padding-top: 2rem;
            padding-bottom: 1.5rem;
        }
        @media (min-width: 320px) and (max-width: 374px) {
            color: #6b98a6;
            font-family: 'Poppins';
            font-weight: 400;
            margin-top: 0rem;
            font-size: 2.5rem;
            padding-top: 2rem;
            padding-bottom: 1.5rem;
        }
    }
    h3 {
        font-family: 'Poppins';
        font-weight: 600;
        color: #6b98a6;
        font-size: 17px;
        @media screen and (max-width: 1366px) {
            font-size: 20px;
        }
    }

    div {
        .line {
            border-bottom: solid 1px black;
        }
    }
    .line-container {
        display: flex;
        flex-direction: column;

        .line {
            width: 90%;
            align-self: center;
        }

        h3 {
            @media (max-width: 768px) {
                display: none;
            }
        }
    }
`

export const InputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 491px;
    align-items: center;
    @media screen and (max-width: 1366px) {
        width: 91%;
        align-items: center;
    }
    @media (max-width: 768px) {
        width: 100%;
    }
    .input-organizer {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0;
        justify-content: center;
        row-gap: 10px;
        @media screen and (max-width: 1366px) {
            display: flex;
            flex-direction: column;
            width: 70%;
            margin: 0;
            justify-content: center;
        }
        @media (max-width: 475px) {
            width: 100%;
        }
    }

    .input-organizer-1 {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0;
        justify-content: center;
        row-gap: 10px;

        #mobile-row {
            display: none;
            @media (max-width: 768px) {
                display: flex;
            }
        }

        @media screen and (max-width: 1366px) {
            display: flex;
            flex-direction: column;
            width: 70%;
            margin: 0;
            justify-content: center;
        }
    }
    .terms-of-use {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 32px;
        input {
            height: 20px;
            width: 40px;
        }
        .label {
            padding-top: 10px;
            color: black;
        }
        a {
            text-decoration: underline;
        }
    }
`

export const DivRow = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 35px;
    @media (max-width: 768px) {
        flex-direction: column;
        row-gap: 10px;

        #numero-normal {
            display: block;
        }
    }
`

export const LabelBigContainer = styled.div`
    display: flex;
    flex-direction: column;
    input {
        height: 35px;
        border-radius: 5px;
        border: solid 1px black;
        padding: 4px;
        background-color: #fff;
        color: black;
        font-family: 'Poppins', regular;
        font-size: 12px;

        @media (max-width: 475px) {
            background-color: #fff;
            border-radius: 12px;
            border: 1px solid black;
            height: 35px;
        }
    }
    input:focus {
        outline: none;
    }
    input:focus:-ms-input-placeholder {
        color: transparent;
    }
    input:focus::-webkit-input-placeholder {
        color: transparent;
    }

    input:disabled {
        background-color: #eeeeee;

        @media (max-width: 768px) {
            background-color: gainsboro;
        }
    }
`

export const LabelMediumContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    input {
        height: 35px;
        border-radius: 5px;
        border: solid 1px black;
        background-color: #fff;
        padding: 4px;
        color: black;
        font-family: 'Poppins', regular;
        font-size: 12px;

        @media (max-width: 768px) {
            background-color: #fff;
            border-radius: 12px;
            border: 1px solid black;
            height: 35px;
            border: 1px solid black;
        }
    }
    #cep {
        width: 158px;
        border: 1px solid black;
        @media (max-width: 768px) {
            width: 100%;
            border: 1px solid black;
            width: 158px;
        }
    }
    input:focus {
        outline: none;
    }
    input:focus:-ms-input-placeholder {
        color: transparent;
    }
    input:focus::-webkit-input-placeholder {
        color: transparent;
    }

    input:disabled {
        background-color: #eeeeee;
        @media (max-width: 768px) {
            background-color: gainsboro;
        }
    }
`
export const LabelSmallContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    input {
        height: 35px;
        border-radius: 5px;
        border: solid 1px black;
        background-color: #fff;
        padding: 4px;
        color: black;
        font-family: 'Poppins', regular;
        font-size: 12px;

        @media (max-width: 768px) {
            background-color: #fff;
            border-radius: 12px;
            border: 1px solid black;
            height: 35px;
        }
    }
    #number {
        width: 91px;
        border: 1px solid black;
        @media (max-width: 768px) {
            width: 100%;
            border: 1px solid black;
            border-radius: 12px;
        }
    }

    input:focus {
        outline: none;
    }
    input:focus:-ms-input-placeholder {
        color: transparent;
    }
    input:focus::-webkit-input-placeholder {
        color: transparent;
    }

    input:disabled {
        background-color: #eeeeee;

        @media (max-width: 768px) {
            background-color: gainsboro;
        }
    }
`
export const LabelCPFContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    column-gap: 35px;
    input {
        height: 35px;
        border-radius: 5px;
        border: solid 1px black;
        background-color: #fff;
        padding: 4px;
        color: black;
        font-family: 'Poppins', regular;
        font-size: 12px;
        @media (max-width: 768px) {
            background-color: #fff;
            border-radius: 12px;
            border: 1px solid black;
            height: 35px;
        }
    }
    input:focus {
        outline: none;
    }
    input:focus:-ms-input-placeholder {
        color: transparent;
    }
    input:focus::-webkit-input-placeholder {
        color: transparent;
    }

    input:disabled {
        background-color: #eeeeee;

        @media (max-width: 768px) {
            background-color: gainsboro;
        }
    }
`

export const LabelLoginContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;

    input {
        height: 35px;
        border-radius: 5px;
        border: solid 1px black;
        background-color: #fff;
        padding: 4px;
        color: black;
        font-family: 'Poppins', regular;
        font-size: 12px;
    }
    #email {
        @media (max-width: 768px) {
            width: 100%;
            border-radius: 12px;
            height: 35px;
            border: 1px solid black;
            background-color: #fff;
        }
    }

    #password {
        @media (max-width: 768px) {
            width: 100%;
            border-radius: 12px;
            height: 35px;
            border: 1px solid black;
            background-color: #fff;
        }
    }
    #confPassword {
        @media (max-width: 768px) {
            width: 100%;
            border-radius: 12px;
            height: 35px;
            border: 1px solid black;
            background-color: #fff;
        }
    }
    input:focus {
        outline: none;
    }
    input:focus:-ms-input-placeholder {
        color: transparent;
    }
    input:focus::-webkit-input-placeholder {
        color: transparent;
    }

    input:disabled {
        background-color: #eeeeee;

        @media (max-width: 768px) {
            background-color: gainsboro;
        }
    }
`
export const LabelAddressContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    input {
        height: 35px;
        border-radius: 5px;
        border: solid 1px black;
        background-color: #fff;
        padding: 4px;
        color: black;
        font-family: 'Poppins', regular;
        font-size: 12px;
    }
    #cep {
        width: 274px;
        border: 1px solid black;
        background-color: #fff;
        @media (max-width: 768px) {
            width: 100%;
            border-radius: 12px;
            border: 1px solid black;
            height: 35px;
            background-color: #fff;
        }
    }
    #address {
        width: 275px;
        border: 1px solid black;
        background-color: #fff;
        @media (max-width: 768px) {
            width: 100%;
            border-radius: 12px;
            height: 35px;
            border: 1px solid black;
            background-color: #fff;
        }
    }
    input:focus {
        outline: none;
    }
    input:focus:-ms-input-placeholder {
        color: transparent;
    }
    input:focus::-webkit-input-placeholder {
        color: transparent;
    }

    input:disabled {
        background-color: #eeeeee;

        @media (max-width: 768px) {
            background-color: gainsboro;
        }
    }
    span {
        @media (min-width: 768px) {
            display: none;
        }
        font-size: 14px;
        font-family: 'Poppins';
        font-weight: 400;
        color: white;
        margin-bottom: 8px;
    }
`
export const LabelComplementContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    input {
        height: 35px;
        border-radius: 5px;
        border: solid 1px black;
        background-color: #fff;
        padding: 4px;
        color: black;
        font-family: 'Poppins', regular;
        font-size: 12px;
    }
    #complement {
        border: 1px solid black;
        background-color: #fff;
        @media (max-width: 768px) {
            width: 100%;
            border-radius: 12px;
            border: 1px solid black;
            height: 35px;
            background-color: #fff;
        }
    }
    input:focus {
        outline: none;
    }
    input:focus:-ms-input-placeholder {
        color: transparent;
    }
    input:focus::-webkit-input-placeholder {
        color: transparent;
    }
    input:disabled {
        background-color: #eeeeee;

        @media (max-width: 768px) {
            background-color: gainsboro;
            border-radius: 12px;
        }
    }
`

export const RegisterInput = styled.input<RegisterInputProps>`
    border: ${(Prop) => Prop.border};
    height: 45px;
`

export const BackContainer = styled.header`
    width: 100%;
    height: 84px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 112px;
    padding-top: 0;
    background-color: #fff;

    @media (max-width: 768px) {
        display: none;
    }

    span {
        color: #6b98a6;
        font-family: 'Poppins', semi-bold;
        font-weight: 600;
        font-size: 15px;
        margin-left: 8px;
    }
    img:hover,
    span:hover {
        cursor: pointer;
    }
`
