import { CookiesActions, currentCookiesState } from '../models/Cookies';

const initialState: currentCookiesState = {
	currentAccept: undefined,
};

export default function CookiesReducer(
	state = initialState,
	action: CookiesActions
) {
	switch (action.type) {
		case 'CONFIRM_COOKIES':
			return {
				...state,
				currentAccept: {
					...state.currentAccept,
					accept: action.accept,
				},
			};
		case 'SHOW_PRODUCTS':
			return {
				...state,
				currentAccept: {
					...state.currentAccept,
					showProducts: action.showProducts,
				},
			};
		case 'SHOW_PRODUCT_SEARCH_FILTERS':
			return {
				...state,
				currentAccept: {
					...state.currentAccept,
					showProductSearchFilters: action.showProductSearchFilters,
				},
			};
		default:
			return state;
	}
}
