import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	box-shadow: 3px 2px 9px #00000014;
	border-radius: 8px;
	margin-bottom: 20px;
	background-color: #fff;
	width: 560px;
	border: 1px solid black;
	@media (max-width: 768px) {
		width: 100%;
	}

	.product-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 12px;
		margin-left: 1rem;
		h1 {
			color: #6b98a6;
			font-size: 14px;
			font-family: 'Poppins';
			font-weight: 600;
			margin: 0;
		}

		.quantity-container {
			margin: 0;
			img {
				width: 4px;
			}
			span {
				color: black;
				font-size: 11px;
				font-family: 'Poppins', regular;
				margin-right: 5px;
				margin-left: 5px;
			}

			.qtd {
				font-size: 19px;
			}

			@media (max-width:767px){
				width: 15%;
			}
		}
	}

	.card-infos {
		padding: 10px;

		.card-options {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			padding: 16px;
			padding-bottom: 0px;
			padding-top: 0px;

			input {
				width: 21px;
				height: 21px;
			}
			input[type='radio']:checked {
				width: 21px;
				height: 21px;
			}

			.input-color {
				root {
					color: #6b98a6;
				}
				div {
					flex-direction: row;
					.MuiSvgIcon-root {
						width: 45px;
						height: 34px;
					}
					.MuiSvgIcon-root {
						width: 45px;
						height: 34px;
					}
				}
			}
			.input-color:checked {
				color: #6b98a6;
			}

			label,
			a {
				padding: 3px;
				width: 100%;
				color: black;
				font-family: 'Poppins', regular;
				font-size: 13px;
				&:hover {
					cursor: pointer;
				}
			}

			a {
				text-decoration: underline;
				color: #625ad2;
			}
		}

		label,
		a {
			padding: 3px;
			width: 100%;
			color: #979797;
			font-family: 'Poppins', regular;
			font-size: 12px;
			&:hover {
				cursor: pointer;
			}
		}
		label {
			padding: 26px;
			padding-bottom: 18px;
			padding-top: 0px;
			color: black;
		}
		a {
			text-decoration: underline;
			color: #625ad2;
		}
	}

	.button-container {
		padding: 15px 33px 15px 33px;
		display: flex;
		flex-direction: column;

		button {
			height: 44px;
			border: none;
			background-color: #fff;
			border-radius: 7px;
			color: #979797;
			font-family: 'Poppins', regular;
			font-size: 20px;
		}
		button:hover {
			cursor: pointer;
		}
		button:focus {
			outline: none;
		}
	}
`;
