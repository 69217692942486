import React from 'react';
import { Container } from './styles';

// import { Container } from './styles';

const Warning: React.FC = () => {
	return <Container />;
};

export default Warning;
