import React from 'react'

import { Container, MainCard, PageContainer, PageHeader } from './styles'
import back from '../../assets/icons/back.svg'
import { Link } from 'react-router-dom'

const ReturnPolicy: React.FC = () => {
    return (
        <PageContainer>
            <Container>
                <PageHeader>
                    <Link to="/">
                        <img src={back} alt="" />
                    </Link>
                    <Link to="/">
                        <h3>Política de Devoluções</h3>
                    </Link>
                </PageHeader>
                <MainCard>
                    <div className="items-container">
                        <h1>Política de Devoluções</h1>
                        <p>
                            <strong>
                                Condições para devolução por arrependimento:
                            </strong>
                        </p>
                        <p>
                            Caso você se arrependa da compra, também poderá
                            devolver o seu pedido em até 7 (sete) dias contados
                            da data do recebimento do pedido no seu endereço.
                        </p>
                        <p>
                            <strong>
                                Como solicitar uma devolução por arrependimento:
                            </strong>
                        </p>
                        <p>
                            Envie um e-mail para comercial@sambay.com.br,
                            informando seu nome completo e número do pedido.
                            Você receberá o reembolso em até 30 (trinta) dias
                            contados da data em que recebermos os produtos
                            devolvidos. O valor será reembolsado utilizando o
                            mesmo método de pagamento que você selecionou ao
                            comprar na nossa loja virtual. Não haverá custo
                            adicional para você receber o reembolso.
                        </p>
                        <p>
                            <strong>Produtos com defeito de fabricação:</strong>
                        </p>
                        <p>
                            De acordo com a legislação brasileira, no caso de
                            itens com defeito de fabricação, você tem o direito
                            de solicitar a devolução do valor pago em até 30
                            (trinta) dias, contados da data do recebimento do
                            pedido no seu endereço.
                        </p>
                        <p>
                            <strong>
                                Como solicitar uma devolução de produtos com
                                defeito:
                            </strong>
                        </p>
                        <p>
                            Envie um e-mail para comercial@sambay.com.br,
                            informando seu nome completo, número do pedido e
                            informações sobre o defeito de fabricação (descrição
                            com fotos ou vídeos). Analisaremos o seu caso em até
                            30 (trinta) dias contados da data em que recebermos
                            os produtos devolvidos. O valor será reembolsado
                            utilizando o mesmo método de pagamento que você
                            selecionou ao comprar na nossa loja virtual. Não
                            haverá custo adicional para você receber o
                            reembolso.
                        </p>
                        <p>
                            <strong>Como devolver os produtos:</strong>
                        </p>
                        <p>
                            Seguindo o estabelecido pelo Direito do Consumidor,
                            os custos de envio da devolução de produtos por
                            direito de arrependimento ou itens com defeito de
                            fabricação serão cobertos pela loja vendedora
                            através do processo de logística reversa.
                        </p>
                        <p>
                            Você receberá um código de autorização de postagem
                            por e-mail após a sua solicitação de troca ou
                            devolução e deverá postar a mercadoria em uma
                            agência dos Correios. Não cobrimos os custos de
                            embalagem, por isso, sugerimos que você utilize a
                            mesma embalagem na qual recebeu a sua compra (caso
                            não esteja danificada) ou uma caixa adequada que
                            preserve as peças durante o transporte.
                        </p>
                        <p>
                            O vendedor recebendo o produto será formalizada a
                            devolução do valor pago, cujo prazo pode variar de
                            acordo com a forma de pagamento efetuada (no caso de
                            Pix ou boleto, o reembolso ocorre em até 48h; em
                            caso de cartão de crédito, em até 72h).
                        </p>
                    </div>
                </MainCard>
            </Container>
        </PageContainer>
    )
}

export default ReturnPolicy
