import styled from 'styled-components';

export const NotifyContainer = styled.div`
	background-color: #fff;
	box-shadow: 0px 2px 8px #00000014;
	width: 25%;
	position: absolute;
	top: 5rem;
	right: 18%;
	border-radius: 8px;
	padding: 15px;
	overflow: auto;
	height: 400px;
	z-index: 2;

	@media (max-width:768px){
		width: 35%;
		height: 360px;
		right: 1%;
		top: 2rem;
		padding: 5px;
	}

	.notification-card {
		border-bottom: solid 1px black;
		padding: 18px;
		padding-bottom: 10px;
		padding-top: 10px;

		.notification-title {
			color: #6b97a5;
			font-family: 'Poppins', semi-bold;
			font-size: 14px;
			font-weight: 600;
		}
		h1,span{
			color: #6b97a5;
			font-family: 'Poppins', semi-bold;
			font-size: 12px;
			font-weight: 600;
		}

		span{
			display: flex;
			justify-content: end;
			justify-items: end;
			color:#6b97a5
		}
	}
`;
