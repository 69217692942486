import styled from 'styled-components';

export const TitleText = styled.h2`
	font-family: 'Poppins', semi-bold;
	font-weight: 600;
	color: #6b97a5;
`;

export const DescriptionText = styled.span`
	font-family: 'Poppins';
`;
