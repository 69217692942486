import styled from 'styled-components';

export const Container = styled.div`
    margin-bottom: 10px;
    .category {
        display: flex;
        flex-direction: column;
        
        border: 1px solid #3E7DC5;
        border-radius: 28px;
        opacity: 1;

        width: 511px;
        padding: 8px;
        margin-bottom: 10px;

        font-family:'Poppins',semi-bold;
        font-weight:600;
        font-size: 12px;
        color: #3E7DC5;

        .categorie-input {
            font-family:'Poppins',semi-bold;
            font-weight:600;
            font-size: 16px;
            color: #3E7DC5;
            border-radius: 8px;
            opacity: 1;
            margin: 4px;

            :disabled {
                background-color: #FFFFFF;
                border: none;
            }
        }

        .options {
            button {
                border-radius: 16px;
                color: white;
                border: none;
                font-family: 'Poppins';
                font-size: 14px;
                margin-left: 10px;
                padding: 8px;
                cursor: pointer;
            }
        }

    } 
    
    .sub-category{
        display: flex;
        flex: 1;
        flex-direction: row;
        flex-wrap: wrap;
        width: 40rem;
    }
`;
