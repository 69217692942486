import styled from 'styled-components';

export const ButtonContainer = styled.div`
.button-container{
	.button-add-new-card{
		background-color:#5095ab;
		color:white;
		font-size:18px;
		border: 1px solid black;
		cursor:pointer;
	}
}
`;

export const ModalContainer = styled.div`
	.modal-title {
		padding: 20px;
		background-color: #fff;

		h2 {
			color: #6b98a6;
			font-family: 'Poppins';
			font-weight: bold;
			font-size: 15px;
		}
	}
	.line {
		border-bottom: solid 1px black;
	}

	.modal-inputs {
		padding: 61px;
		padding-top: 8px;
		flex-wrap: wrap;
		background-color: #fff;

		@media( max-width:475px){
			padding:20px;
		}

		.modal-actions {
			padding: 22px;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;

			@media( max-width:475px){
				justify-content: center;
			}

			.buttons-container {
				padding: 10px;

				.positive-button {
					background-color: #6b97a5;
					border: 1px solid black;
					padding: 20px;
					color: white;
					border-radius: 7px;
					font-family: 'Poppins';
					font-weight: bold;
					font-size: 18px;
					cursor: pointer;

					@media( max-width:475px){
						padding:0;
						font-size:14px;
						height:60px;
						width:120px;
					}

				}
				.negative-button {
					background-color: #ca667e;
					border: 1px solid black;
					padding: 20px;
					color: white;
					border-radius: 7px;
					font-family: 'Poppins';
					font-weight: bold;
					font-size: 18px;
					cursor: pointer;

					@media( max-width:475px){
						padding:0;
						font-size:14px;
						height:60px;
						width:120px;
					}
				}
			}
		}

		.first-line {
		}

		.second-line {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		}

		.third-line {
			display: flex;
			flex-direction: row;
			@media (max-width:475px){
				flex-direction:column;
				align-items:stretch;
			}
		}

		.first-line,
		.second-line,
		.third-line {
			.modal-inputs-organizer {
				display: flex;
				flex-direction: column;
				padding: 18px;
				background-color: #fff;

				label {
					margin-bottom: 4px;
					color: #6b98a6;
					font-family: 'Poppins';
					font-weight: 600;
					font-size: 15px;
				}
				input {
					height: 51px;
					border: solid 1px black;
					border-radius: 7px;
					font-family: 'Poppins', regular;
					color: black;
					padding: 5px;
					background-color: #fff;
				}
				input:focus {
					outline: none;
				}

				select {
					height: 51px;
					border: solid 1px black;
					border-radius: 7px;
					font-family: 'Poppins', regular;
					color: black;
					padding: 5px;
					background-color: #fff;
					cursor: pointer;
				}
				select:focus {
					outline: none;
				}
			}
		}
	}
`;
