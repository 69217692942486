import { useSelector } from 'react-redux';
import { insertUserToken } from './actions/ProductsCartActions';
import IUserAccount from './models/IUserAccount';
import { AppState } from './store';

export let accessToken = '';

export const setAccessToken = (data: string) => {
	accessToken = data;

	insertUserToken(data);
};

export const getAccessToken = () => {
	return accessToken;
};
