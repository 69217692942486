import styled from 'styled-components';

export const DataContainer = styled.div`
	margin-top: 0px;
	border: solid 1px black;
	border-radius: 7px;
	font-family: 'Poppins', semi-bold;
	padding: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom:20px;

	@media (min-width:425px) and (max-width:767px){
		padding-left: 10px;
	}
	@media (min-width:375px) and (max-width:424px){
		padding-left: 10px;
	}
	@media (min-width:320px) and (max-width:374px){
		padding:20px;
	}

	img {
		position: absolute;
		right: 335px;
		cursor: pointer;

		@media (min-width:425px) and (max-width:767px){
			right: 75px;
		}
		@media (min-width:375px) and (max-width:424px){
			right: 60px;
		}
		@media (min-width:320px) and (max-width:374px){
			right: 15px;
		}
	}

	.org {
		display: flex;
		width: 100%;
		flex-direction: column;
		.address-title {
			position: relative;
			top: -13px;
			left: -22px;
			background-color: #69b64a;
			width: 237px;
			height: 38px;
			border-radius: 8px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			color: white;
			font-size: 14px;
			font-family: 'Poppins';
			font-weight: bold;

			@media (min-width:425px) and (max-width:767px){
				top: -28px;
				left: -7px;
				width: 230px;
			}
			@media (min-width:375px) and (max-width:424px){
				top: -28px;
				left: -5px;
				width: 190px;
			}
			@media (min-width:320px) and (max-width:374px){
				top: -15px;
				left: -5px;
				width: 220px;
			}
			}
			.address-delete { 
				position:'absolute';
				width:37px;
				right: 250px;
				cursor:pointer;
			}	
		.first-line {
			width: 100%;
			display: flex;
			flex-direction:row;
			gap:20px;
			.button-container {
				button {
					width: 200px;
					border: none;
					background-color: #6b97a5;
					font-family: 'Poppins';
					font-weight: bold;
					color: white;
					border-radius: 9px;
					font-size: 17px;
					margin: 0;
					margin-bottom: 25px;
				}
			}
			.first-section{
				display:flex;
				flex-direction:column;
				width:100%;
				label {
					color: #6b97a5;
					font-family: 'Poppins';
					font-weight: bold;
					font-size: 12px;
				}
				.title-input-container {
					display: flex;
					flex-direction: row;
					border: solid 1px black;
					padding: 6px;
					border-radius: 8px;
					margin-bottom: 25px;
					width: 100%;
					background-color: #DDDDDD;
					input {
						border: none;
						color: black;
						font-size: 14px;
						font-family: 'Poppins', regular;
						width:100%;
						background-color: #DDDDDD;
						
					}
					input:focus {
						outline: none;
					}
				}
			}
		}
		.second-line{
			width: 100%;
			display: flex;
			flex-direction:row;
			gap:20px;
			.button-container {
				button {
					width: 200px;
					border: none;
					background-color: #6b97a5;
					font-family: 'Poppins';
					font-weight: bold;
					color: white;
					border-radius: 9px;
					font-size: 17px;
					margin: 0;
					margin-bottom: 25px;
				}
			}
			.first-section{
				display:flex;
				flex-direction:column;
				width:100%;
				label {
					color: #6b97a5;
					font-family: 'Poppins';
					font-weight: bold;
					font-size: 12px;
				}
				.small-input-container{
					display: flex;
					flex-direction: row;
					border: solid 1px black;
					padding: 6px;
					border-radius: 8px;
					margin-bottom: 25px;
					width: 100%;
					background-color: #DDDDDD;
					input {
						border: none;
						color: black;
						font-size: 14px;
						font-family: 'Poppins', regular;
						width:100%;
						background-color: #DDDDDD;
						
					}
					input:focus {
						outline: none;
					}
				}
			}
		}
		
	}	
`;
