import styled from 'styled-components';

export const ProfileContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-top: 50px;
`;
export const ProfileCard = styled.main`
	height: 291px;
	background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 12px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	padding: 20px;
	width: 80%;
	max-width: 80%;
`;
export const InformationsCard = styled.div`
	display: flex;
	flex-direction: row;
	width: 85%;
	height: 100%;

	.user-photo {
		margin-top: 0;
		margin-left: 10px;
		margin-right: 16px;
		.user-image {
			width: 90px;
			height: 90px;
			border-radius: 50px;
			cursor: pointer;
		}
	}
	.user-informations-container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding-top: 20px;
		padding-bottom: 20px;

		.user-name-and-type {
			h2 {
				color: #0b0948;
				font-size: 20px;
				font-family: 'Poppins';
				font-weight: bold;
				margin: 0;
				margin-bottom: 8px;
			}
			.user-type {
				display: flex;
				flex-direction: row;
				align-items: center;
				h3 {
					color: #6b97a5;
					font-size: 15px;
					font-family: 'Poppins', regular;
					font-weight: 400;
					margin-bottom: 8px;
					margin-right: 6px;
				}
				span {
					color: #cdcdcd;
					font-family: 'Poppins', regular;
					font-size: 10px;
				}
			}
		}
		.user-informations {
			display: flex;
			flex-direction: column;

			.first-line {
				display: flex;
				flex-direction: row;
				width: max-content;
				margin-bottom: 30px;

				.input-and-label-container {
					display: flex;
					flex-direction: column;
					margin-right: 4px;

					label {
						color: #6b97a5;
						font-family: 'Poppins', regular;
						font-size: 12px;
					}
					input {
						color: #0b0948;
						font-family: 'Poppins';
						font-weight: 600;
						font-size: 15px;
						border: none;
					}
					input[type='number']::-webkit-inner-spin-button {
						-webkit-appearance: none;
					}
					input:focus {
						outline: none;
					}
				}
				.enabled {
					input {
						border: solid 1px #e1e1e1;
						border-radius: 12px;
					}
				}
			}
			.second-line {
				display: flex;
				flex-direction: row;
				width: max-content;

				.input-and-label-container {
					display: flex;
					flex-direction: column;
					margin-right: 4px;
					label {
						color: #6b97a5;
						font-family: 'Poppins', regular;
						font-size: 12px;
					}
					input {
						color: #0b0948;
						font-family: 'Poppins';
						font-weight: 600;
						font-size: 15px;
						border: none;
					}
					input[type='number']::-webkit-inner-spin-button {
						-webkit-appearance: none;
					}
					input:focus {
						outline: none;
					}
				}
				.enabled {
					input {
						border: solid 1px #e1e1e1;
						border-radius: 12px;
					}
				}
			}
		}
	}
`;
export const ActiveButtonContainer = styled.div`
	height: 100%;
	

	.account-status {
		width: 138px;
		height: 34px;
		background: var(--unnamed-color-69b64a) 0% 0% no-repeat padding-box;
		background: #69b64a 0% 0% no-repeat padding-box;
		border-radius: 17px;
		color: white;
		font-family: 'Poppins', regular;
		font-size: 15px;
		border: none;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		
		&:hover {
			cursor: pointer;
		}
		&:focus {
			outline: none;
		}
	}
`;
export const EditButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-end;
	padding-top: 30px;
	width: 80%;

	button {
		width: 96px;
		height: 34px;
		background: var(--unnamed-color-6b97a5) 0% 0% no-repeat padding-box;
		background: #6b97a5 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px #00000029;
		border-radius: 12px;
		border: none;
		color: white;
		font-size: 15px;
		font-family: 'Poppins', regular;
		&:hover {
			cursor: pointer;
		}
		&:focus {
			outline: none;
		}
	}
`;
