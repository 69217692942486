import styled from 'styled-components';

export const MainContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	padding-top: 40px;
	width: 1146px;
	background-color: #fff;

	@media (max-width: 768px) {
		width: 100%;
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.class-column {
		display: flex;
		flex-direction: column;
		flex: 3;
		align-items: center;

		h2 {
			margin-bottom: 2rem;
			color: #05a5bf;
			font-size: 30px;
			font-family: 'Poppins', semi-bold;
			font-weight: 600;
		}

		@media (max-width: 768px) {
			width: 100%;
			display: flex;
			flex-direction: column;
		}

		.items-quantity {
			height: 66px;
			background-color: #fff;
			box-shadow: 3px 2px 9px #00000014;
			border-radius: 8px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			width: 565px;
			margin-bottom: 25px;
			padding: 32px;
			border: 1px solid black;

			h2 {
				margin: 0;
				color: #05a5bf;
				font-size: 16px;
				font-family: 'Poppins', semi-bold;
				font-weight: 600;
			}

			.quantity-container {
				span {
					color: black;
					font-size: 27px;
					font-family: 'Poppins', regular;
					margin-right: 35px;
					margin-left: 35px;
				}
			}
		}
		.card-container {
			display: flex;
			flex-direction: column;
			box-shadow: 3px 2px 9px #00000014;
			margin-bottom: 30px;
			border-radius: 8px;
			background-color: #fff;
			width: 560px;
			border: 1px solid black;

			@media (max-width: 768px) {
				margin-bottom: 10px;
				width: 100%;
				box-shadow: 0px 3px 6px #00000029;
				min-width: 357px;
			}

			.card-header {
				background-color: #05a5bf;
				border-top-left-radius: 7px;
				border-top-right-radius: 7px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				padding-left: 20px;
				padding-right: 20px;
				height: 53px;

				@media (max-width: 768px) {
					background-color: transparent;
				}

				h2 {
					color: #ffffff;
					font-family: 'Poppins', semi-bold;
					font-weight: 600;
					margin: 0;
					font-size: 16px;

					@media (max-width: 768px) {
						color: #6b98a6;
					}
				}
				span {
					font-family: 'Poppins', regular;
					color: white;
					font-size: 20px;
					text-decoration: underline;

					@media (max-width: 768px) {
						color: #ff7e0f;
						font-size: 10px;
					}
				}
				span:hover {
					cursor: pointer;
				}
			}
			.card-infos {
				padding: 13px;

				.card-options {
					padding: 10px;
					display: flex;
					flex-direction: row;
					align-items: center;

					@media (max-width: 768px) {
						border: solid 1px black;
						border-radius: 15px;
					}

					input {
						width: 45px;
						height: 34px;
						margin-right: 8px;
					}
					label {
						color: black;
						font-family: 'Poppins', regular;
						font-size: 13px;
					}
					.input-color {
						div {
							flex-direction: row;
							.MuiSvgIcon-root {
								width: 45px;
								height: 34px;
							}
							.MuiSvgIcon-root {
								width: 45px;
								height: 34px;
							}
						}
						@media (max-width: 768px) {
							display: none;
						}
					}
				}
			}

			.button-container {
				padding: 15px 33px 15px 33px;
				display: flex;
				flex-direction: column;

				button {
					height: 44px;
					border: none;
					background-color: #fff;
					border-radius: 7px;
					color: #979797;
					font-family: 'Poppins', regular;
					font-size: 20px;
					border: 1px solid black;
				}
				button:hover {
					cursor: pointer;
				}
				button:focus {
					outline: none;
				}
			}
		}
	}
	.infos-card {
		display: flex;
		flex-direction: column;
		padding: 10px;
		box-shadow: 3px 2px 9px #00000014;
		border-radius: 8px;
		align-items: flex-start;
		background-color: white;
		align-items: start;
		background-color: #fff;
		width: 400px;
		height: 80%;
		justify-content: flex-start;
		border: 1px solid black;

		@media (max-width: 768px) {
			display: flex;
			flex-direction:column;
			width: 100%;
			height: 20%;
		}

		.infos {
			display: flex;
			flex-direction: column;
			padding: 20px;
			color: #979797;
			font-family: 'Poppins';
			font-weight: 600;
			width: 100%;

			
			@media (max-width:767px){
				display: none;
			}


			h4 {
				font-size: 15px;
				font-family: 'Poppins';
				font-weight: 600;
				color: #6b98a6;
			}

			label {
				color: black;
				font-family: 'Poppins', regular;
				font-size: 14px;
				font-weight: 400;
			}
		}

		.title-infos {
			padding: 20px;
			width: 100%;

			@media (max-width:767px){
				display: none;
			}

			h2 {
				color: #6b98a6;
				font-family: 'Poppins';
				font-weight: 600;
				font-size: 16px;
				margin-bottom: 0px;
			}
			label {
				color: #979797;
				font-family: 'Poppins', regular;
				font-size: 12px;
			}		
		}

		.price-info {
			padding: 20px;

			@media(max-width:767px){
				padding:10px;
				h2{
					font-size:15px;
				}
			}

			h2 {
				color: #6b98a6;
				font-family: 'Poppins';
				font-weight: 600;
				font-size: 17px;
			}
		}
		.next-button-container {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			.buy {
				width: 100%;
				height: 50px;
				background-color: #69b64a;
				color: white;
				font-family: 'Poppins';
				font-weight: bold;
				font-size: 19px;
				border: none;
				border-radius: 7px;
				width: 280px;
				height: 44px;
				.load {
					height: 30px;
					width: 30px;
				}
			}
			.buy-processing:focus {
				outline: none;
			}
			.buy-processing {
				color: white;
				font-family: 'Poppins';
				font-weight: bold;
				font-size: 19px;
				border: none;
				border-radius: 7px;
				width: 226px;
				height: 36px;
				transition: all 1s;
				padding: 0;
				.middle {
					height: 36px;
					border: none;
					border-radius: 7px;
					position: relative;
					span {
						color: white;
						font-family: 'Poppins';
						font-weight: bold;
						font-size: 19px;
						display: flex;
						flex-direction: row;
						align-items: center;
						border: 1px solid black;
					}
				}
				span {
					color: white;
					font-family: 'Poppins';
					font-weight: bold;
					font-size: 14px;
					display: flex;
					flex-direction: row;
					align-items: center;
				}

				@media (max-width:767px){
					display: flex;
					flex-direction: column;
				}
			}
			.buy-processing-2 {
				background-color: #f7e23e;
				color: white;
				font-family: 'Poppins';
				font-weight: bold;
				font-size: 19px;
				border: none;
				border-radius: 7px;
				width: 280px;
				height: 44px;
				transition: all 0.1s;
				padding: 0;
				.middle {
					background-color: #69b64a;
					width: 50%;
					height: 44px;
					border: none;
					border-radius: 7px;
				}
			}

			.load {
				height: 30px;
				width: 30px;
			}
		}
	}
`;
export const Container = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	background: #fff;
	height: 100%;
`;

export const MobileBuyButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 30px;
	width: 100%;
	position: fixed;
	bottom: 0;
	background: white;
	z-index: 1;

	@media (min-width: 768px) {
		display: none;
	}
	.price {
		display: flex;
		flex-direction: column;
		span {
			color: #6b98a6;
			font-family: 'Poppins';
			font-weight: 500;
			font-size: 15px;
		}

		h2 {
			margin: 0;
			margin-top: 8px;
			margin-bottom: 10px;
			color: #6b98a6;
			font-family: 'Poppins';
			font-weight: 500;
			font-size: 26px;
		}
	}
	button {
		background: #69b64a;
		width: 142px;
		height: 36px;
		border: none;
		border-radius: 18px;
		color: white;
		font-family: 'Poppins';
		font-weight: bold;
		font-size: 13px;
	}
`;

export const QrCodeContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content:center;

	.container-qr-code{
		display: flex;
		flex-direction: column;

		.span-vencimento{
			font-size:13px;
			font-family: 'Poppins';
			color:black;
		}

		button{
			display: flex;
			border:1px solid black;
			font-size: 14px;
			background-color: white;
			cursor: pointer;
			justify-content: center;
			margin-top: 1.5rem;
			background-color: rgb(105, 182, 74);
			border-radius:7px;
			color:white;
			font-family: 'Poppins';
			height: 35px;
			font-weight: bold;
			padding-top: 10px;
		}
	}
`

export const SucessContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 56px;
	background-color: #fff;

	.sucess-card {
		box-shadow: 3px 2px 9px #00000014;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 10px;
		width: 381px;
		border: 1px solid black;

		h2 {
			color: #6b98a6;
			font-size: 17px;
			width: 50%;
			text-align: center;
		}
		span {
			width: 54;
			text-align: center;
			margin-top: 33px;
			font-size: 17px;
			color: #979797;
		}
		.span-andamento-compras{
			font-family:'Poppins'; 
			font-size:13px;
			color:black;
		}
		img {
			width: 81px;
			height: 81px;
		}

		.card-actions {
			display: flex;
			flex-direction: column;
			width: 100%;
			padding: 18px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-top: 19px;

			.button-confirm {
				width: 280px;
				margin-bottom: 15px;
				background-color: #e96d79;
				color: white;
				border: 1px solid black;
				height: 42px;
				border-radius: 7px;
				font-size: 14px;
				font-family: 'Poppins';
				font-weight: bold;
			}
			.button-boleto {
				width: 280px;
				margin-bottom: 34px;
				background-color: #69b64a;
				color: white;
				border: none;
				height: 42px;
				border-radius: 7px;
				font-size: 14px;
				font-family: 'Poppins';
				font-weight: bold;
			}
			.button-redirect {
				background-color: #05a5bf;
				color: white;
				border: 1px solid black;
				border-radius: 7px;
				font-size: 14px;
				font-family: 'Poppins';
				font-weight: bold;
				width: 280px;
				height: 44px;
			}
		}
	}
`;
