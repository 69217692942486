import React, { useCallback, useEffect, useRef, useState } from 'react'
import IUserAccount from '../../models/IUserAccount'
import { useSelector } from 'react-redux'
import { AppState } from '../../store'

import {
    CardTitle,
    DataContainer,
    ModalContainer,
    CardShowContainerAccount,
    ContainerAccountMobile,
} from './Style'
import {
    useCreateRecipientMutation,
    useGetAccountQuery,
    useDeleteBankAccountMutation,
} from '../../generated/graphql'

import { Modal } from 'semantic-ui-react'
import { FieldError, useForm } from 'react-hook-form'
import { Combobox } from '@headlessui/react'
import AlertModal, { ModalHandles } from '../AlertModal/AlertModal'
import { setCpfMaskFunc } from '../../utils/Masks'

import { MdClose, MdCheck } from 'react-icons/md'
import useWindow from '../../hooks/useWindow'

interface RegisterBankAccountValues {
    name: string
    cpf: string
    agency: string
    account: string
    accountDv: string
    typeAccount: string
}

interface Bank {
    ispb: string
    name: string
    code: number
    fullName: string
}

const verifyErrors = (error?: FieldError): string => {
    return error ? 'solid 1px red' : 'solid 1px black'
}

const BankAccountModal: React.FC = () => {
    const { innerWidth } = useWindow()
    const { id: userId }: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<RegisterBankAccountValues>({
        mode: 'all',
        reValidateMode: 'onChange',
    })
    const [state, setState] = useState({ dimmer: undefined, open: false })
    const show = (dimmer: any): void => setState({ dimmer: dimmer, open: true })
    const [createBankAccountMutation] = useCreateRecipientMutation()
    const [deleteBankAccountMutation] = useDeleteBankAccountMutation()
    const [deleteBankAccount, setDeleteBankAccount] = useState<boolean>()
    const { data } = useGetAccountQuery({
        variables: { userId },
    })
    const [maskCpf, setMaskCpf] = useState<string>('')
    const modalRef = useRef<ModalHandles>(null)
    const [operationMessage, setOperationMessage] = useState<string>('')
    const [reload, setReload] = useState<boolean>(true)

    const [bankList, setBankList] = useState<Bank[]>()
    const [filteredBankList, setFilteredBankList] = useState<Bank[]>()
    const [selectedBank, setSelectedBank] = useState<Bank>()
    const [bankQuery, setBankQuery] = useState<string>()

    const displayBank = (bank: Bank): string => {
        return `${String(bank.code).padStart(3, '0')} - ${bank.name}`
    }

    useEffect(() => {
        const api = new XMLHttpRequest()
        api.open('GET', 'https://brasilapi.com.br/api/banks/v1')
        api.send()

        api.addEventListener('readystatechange', () => {
            if (api.readyState === 4) {
                setBankList(
                    JSON.parse(api.responseText)
                        .filter((bank: Bank) => bank.code !== null)
                        .sort((a: Bank, b: Bank) => a.code - b.code)
                )
            }
        })
    }, [])

    const handleOpenModal = useCallback((status: boolean) => {
        if (!status) {
            setOperationMessage('Falha ao cadastrar conta ')
            setReload(false)
        } else {
            setOperationMessage('Conta cadastrada com sucesso!')
            setReload(true)
        }
        modalRef.current?.handleOpenModal()
    }, [])

    const delBankAcc = async (): Promise<void> => {
        try {
            await deleteBankAccountMutation({
                variables: {
                    id: userId,
                },
            })
        } catch {
            console.log('Erro ao remover conta de recebimento')
        }
        window.location.reload()
    }

    useEffect(() => {
        if (
            bankList &&
            bankList.length > 0 &&
            bankQuery &&
            bankQuery.length > 0
        ) {
            setFilteredBankList(
                bankQuery === ''
                    ? bankList
                    : bankList.filter(
                          (bank) =>
                              bank.name
                                  .toLowerCase()
                                  .replace(/\s+/g, '')
                                  .includes(
                                      bankQuery
                                          .toLowerCase()
                                          .replace(/\s+/g, '')
                                  ) ||
                              bank.fullName
                                  .toLowerCase()
                                  .replace(/\s+/g, '')
                                  .includes(
                                      bankQuery
                                          .toLowerCase()
                                          .replace(/\s+/g, '')
                                  ) ||
                              String(bank.code)
                                  .padStart(3, '0')
                                  .includes(
                                      bankQuery
                                          .toLowerCase()
                                          .replace(/\s+/g, '')
                                  )
                      )
            )
        }
    }, [bankList, bankQuery])

    useEffect(() => {
        if (bankList && bankList.length > 0) {
            setSelectedBank(bankList[0])
        }
    }, [bankList])

    const onSubmit = async (
        formData: RegisterBankAccountValues
    ): Promise<void> => {
        let { cpf, account: conta, accountDv: conta_dv } = formData
        const {
            agency: agencia,
            name: legal_name,
            typeAccount: type,
        } = formData
        const bank_code = String(selectedBank?.code).padStart(3, '0')
        cpf = cpf.replace(/\D/g, '')
        conta = conta.replace(/\D/g, '')
        conta_dv = conta_dv.replace(/\D/g, '')

        try {
            await createBankAccountMutation({
                variables: {
                    agencia,
                    bank_code,
                    conta,
                    conta_dv,
                    cpf,
                    legal_name,
                    type,
                    userId,
                },
            })

            handleOpenModal(true)
        } catch {
            handleOpenModal(false)
        }
    }

    document.addEventListener('keypress', (event) => {
        if (event.key === 'Enter') {
            handleSubmit(onSubmit)()
        }
    })

    function exibirErro(error: FieldError): JSX.Element | undefined {
        if (error.type === 'maxLength')
            return <span>Limite de caracteres ultrapassado</span>
        if (error.type === 'required') return <span>Campo obrigatório</span>
    }

    return (
        <>
            <AlertModal
                ref={modalRef}
                AreaTitle={'Conta de recebimento'}
                DescriptionText={operationMessage}
                reload={reload}
            />
            <Modal
                dimmer={state.dimmer}
                open={state.open}
                onClose={() => state.open === false}
            >
                <ModalContainer>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="modal-title">
                            <h2>Conta de recebimento</h2>
                        </div>
                        <div className="line"></div>
                        <div className="modal-inputs">
                            <div
                                className="first-line"
                                style={{ display: 'flex' }}
                            >
                                <div
                                    className="modal-inputs-organizer"
                                    style={{ width: '60%' }}
                                >
                                    <label>Nome</label>
                                    <input
                                        type="text"
                                        {...register('name', {
                                            required: true,
                                        })}
                                        style={{
                                            border: verifyErrors(errors.name),
                                        }}
                                    />
                                    {errors.name && exibirErro(errors.name)}
                                </div>

                                <div
                                    className="modal-inputs-organizer"
                                    style={{ width: '40%' }}
                                >
                                    <label>CPF/CNPJ</label>
                                    <input
                                        type="text"
                                        placeholder="xxx.xxx.xxx-xx"
                                        {...register('cpf', {
                                            required: true,
                                            pattern: {
                                                value: /(\d{2}(?:\.?\d{3}){2}\/?\d{4}-?\d{2})|((?:\d{3}\.?){2}\d{3}-?\d{2})/,
                                                message:
                                                    'Insira um cpf/cnpj valido',
                                            },
                                        })}
                                        onChange={(e) =>
                                            setMaskCpf(
                                                setCpfMaskFunc(e.target.value)
                                            )
                                        }
                                        value={maskCpf}
                                        style={{
                                            border: verifyErrors(errors.cpf),
                                        }}
                                    />
                                    {errors.cpf && exibirErro(errors.cpf)}
                                    <span
                                        style={{
                                            color: 'black',
                                            fontFamily: 'Poppins',
                                            fontSize: '12px',
                                        }}
                                    >
                                        * O CPF ou CNPJ informado acima deve ser
                                        o mesmo utilizado no cadastro da conta
                                        bancária.
                                    </span>
                                </div>
                            </div>
                            <div className="second-line">
                                <div
                                    className="modal-inputs-organizer"
                                    style={{ flex: '2' }}
                                >
                                    <label>Banco</label>

                                    <Combobox
                                        value={selectedBank}
                                        onChange={setSelectedBank}
                                    >
                                        <Combobox.Input
                                            onChange={(event) =>
                                                setBankQuery(event.target.value)
                                            }
                                            autoComplete="no"
                                            placeholder="Procure pelo nome ou código de seu banco"
                                            displayValue={(bank: Bank) =>
                                                displayBank(bank)
                                            }
                                        />
                                        <Combobox.Options
                                            className={`absolute tw-m-0 tw-max-h-60 tw-overflow-auto tw-p-0`}
                                        >
                                            {filteredBankList &&
                                                filteredBankList.map(
                                                    (bank: Bank) => (
                                                        <Combobox.Option
                                                            key={bank.code}
                                                            value={bank}
                                                            className={`relative`}
                                                        >
                                                            {({
                                                                active,
                                                                selected,
                                                            }) => (
                                                                <li
                                                                    className={`
                                                                        tw-flex tw-w-full tw-flex-row
                                                                        tw-items-center tw-justify-start
                                                                        tw-space-x-2 tw-rounded-sm tw-px-1 tw-py-2 ${
                                                                            active
                                                                                ? 'tw-bg-smoke-300 tw-text-white'
                                                                                : 'tw-bg-white tw-text-smoke-900'
                                                                        }`}
                                                                >
                                                                    {selected && (
                                                                        <MdCheck
                                                                            size={
                                                                                16
                                                                            }
                                                                        />
                                                                    )}
                                                                    <p>
                                                                        {displayBank(
                                                                            bank
                                                                        )}
                                                                    </p>
                                                                </li>
                                                            )}
                                                        </Combobox.Option>
                                                    )
                                                )}
                                        </Combobox.Options>
                                    </Combobox>
                                </div>

                                <div
                                    className="modal-inputs-organizer"
                                    style={{ flex: '1.5' }}
                                >
                                    <label>Tipo de conta</label>
                                    <select
                                        {...register('typeAccount', {
                                            required: true,
                                            minLength: 1,
                                        })}
                                        style={{
                                            border: verifyErrors(
                                                errors.typeAccount
                                            ),
                                        }}
                                    >
                                        <option value="">Tipo de conta</option>
                                        <option value="conta_corrente">
                                            Conta Corrente
                                        </option>
                                        <option value="conta_poupanca">
                                            Conta Poupança
                                        </option>
                                        <option value="conta_poupanca_conjunta">
                                            Conta Poupança Conjunta
                                        </option>
                                        <option value="conta_corrente_conjunta">
                                            Conta Corrente Conjunta
                                        </option>
                                    </select>
                                    {errors.typeAccount &&
                                        exibirErro(errors.typeAccount)}
                                </div>
                            </div>

                            <div className="third-line">
                                <div className="modal-inputs-organizer">
                                    <label>Agência</label>
                                    <input
                                        type="number"
                                        style={{
                                            border: verifyErrors(errors.agency),
                                        }}
                                        placeholder="xxxx"
                                        {...register('agency', {
                                            required: true,
                                            maxLength: 64,
                                        })}
                                    />
                                    {errors.agency && exibirErro(errors.agency)}
                                </div>

                                <div
                                    className="modal-inputs-organizer"
                                    style={{ flex: '3' }}
                                >
                                    <label>Conta do beneficiário</label>
                                    <input
                                        type="text"
                                        style={{
                                            border: verifyErrors(
                                                errors.account
                                            ),
                                        }}
                                        placeholder="xxxxx"
                                        {...register('account', {
                                            required: true,
                                            maxLength: 64,
                                        })}
                                    />
                                    {errors.account &&
                                        exibirErro(errors.account)}
                                </div>

                                {innerWidth < 475 ? (
                                    <div className="modal-inputs-organizer">
                                        <label>Dígito</label>
                                        <input
                                            type="text"
                                            style={{
                                                border: verifyErrors(
                                                    errors.accountDv
                                                ),
                                            }}
                                            placeholder="x"
                                            {...register('accountDv', {
                                                required: true,
                                                maxLength: 1,
                                            })}
                                            maxLength={1}
                                        />
                                        {errors.accountDv &&
                                            exibirErro(errors.accountDv)}
                                    </div>
                                ) : (
                                    <div
                                        className="modal-inputs-organizer"
                                        style={{ width: '20%' }}
                                    >
                                        <label>Dígito</label>
                                        <input
                                            type="text"
                                            style={{
                                                border: verifyErrors(
                                                    errors.accountDv
                                                ),
                                            }}
                                            placeholder="x"
                                            {...register('accountDv', {
                                                required: true,
                                                maxLength: 1,
                                            })}
                                            maxLength={1}
                                        />
                                        {errors.accountDv &&
                                            exibirErro(errors.accountDv)}
                                    </div>
                                )}
                            </div>

                            <div className="modal-actions">
                                <div className="buttons-container">
                                    <button
                                        className="negative-button"
                                        onClick={() =>
                                            setState({
                                                dimmer: undefined,
                                                open: false,
                                            })
                                        }
                                    >
                                        Cancelar
                                    </button>
                                </div>
                                <div className="buttons-container">
                                    <button
                                        className="positive-button"
                                        type="submit"
                                    >
                                        Cadastrar Conta
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </ModalContainer>
            </Modal>
            <CardTitle>
                {innerWidth > 768 ? (
                    <CardShowContainerAccount>
                        {data?.getAccount && (
                            <DataContainer>
                                {data?.getAccount && (
                                    <div className="org">
                                        <div
                                            className="first-line"
                                            style={{ width: '100%' }}
                                        >
                                            <div
                                                className="title-input-container"
                                                style={{ flex: '2' }}
                                            >
                                                <label>Nome</label>
                                                <input
                                                    type="text"
                                                    required
                                                    defaultValue={
                                                        data?.getAccount
                                                            ?.legal_name
                                                    }
                                                    disabled={true}
                                                />
                                            </div>

                                            <div
                                                className="title-input-container"
                                                style={{ flex: '1.5' }}
                                            >
                                                <label>Tipo de conta</label>
                                                <select
                                                    name="contas_bancarias"
                                                    defaultValue={
                                                        data.getAccount?.type
                                                    }
                                                    disabled={true}
                                                    placeholder="Selecione uma conta"
                                                >
                                                    <option value="conta_corrente">
                                                        Conta Corrente
                                                    </option>
                                                    <option value="conta_poupanca">
                                                        Conta Poupança
                                                    </option>
                                                    <option value="conta_poupanca_conjunta">
                                                        Conta Poupança Conjunta
                                                    </option>
                                                    <option value="conta_corrente_conjunta">
                                                        Conta Corrente Conjunta
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div
                                            className="second-line"
                                            style={{ width: '100%' }}
                                        >
                                            <div
                                                className="small-input-container"
                                                style={{ flex: '1' }}
                                            >
                                                <label>CPF/CNPJ</label>
                                                <input
                                                    type="text"
                                                    required
                                                    defaultValue={
                                                        data.getAccount
                                                            ?.document_number
                                                    }
                                                    disabled={true}
                                                />
                                            </div>
                                            <div
                                                className="small-input-container"
                                                style={{ flex: '1' }}
                                            >
                                                <label>Banco</label>
                                                <input
                                                    type="text"
                                                    required
                                                    defaultValue={
                                                        data.getAccount
                                                            ?.bank_code
                                                    }
                                                    disabled={true}
                                                />
                                            </div>
                                            <div
                                                className="small-input-container"
                                                style={{ flex: '1' }}
                                            >
                                                <label>Agencia</label>
                                                <input
                                                    type="text"
                                                    required
                                                    defaultValue={
                                                        data.getAccount?.agencia
                                                    }
                                                    disabled={true}
                                                />
                                            </div>

                                            <div
                                                className="small-input-container"
                                                style={{ flex: '1' }}
                                            >
                                                <label>
                                                    Conta do beneficiário
                                                </label>
                                                <input
                                                    type="text"
                                                    required
                                                    defaultValue={
                                                        data.getAccount.conta
                                                    }
                                                    disabled={true}
                                                />
                                            </div>
                                            <div
                                                className="small-input-container"
                                                style={{ flex: '1' }}
                                            >
                                                <label>Dígito da Conta</label>
                                                <input
                                                    type="text"
                                                    required
                                                    defaultValue={
                                                        data.getAccount.conta_dv
                                                    }
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="remove-acc-container">
                                            <button
                                                onClick={() =>
                                                    setDeleteBankAccount(true)
                                                }
                                            >
                                                Remover Conta
                                            </button>
                                            {deleteBankAccount && (
                                                <>
                                                    <span className="confirm-desc">
                                                        Tem certeza?
                                                    </span>
                                                    <span
                                                        onClick={() =>
                                                            delBankAcc()
                                                        }
                                                        className="delete"
                                                    >
                                                        <MdCheck
                                                            size="2rem"
                                                            color="#44B4D7"
                                                        />
                                                    </span>
                                                    <span
                                                        onClick={() =>
                                                            setDeleteBankAccount(
                                                                false
                                                            )
                                                        }
                                                        className="cancel"
                                                    >
                                                        <MdClose
                                                            size="2rem"
                                                            color="#CA667E"
                                                        />
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </DataContainer>
                        )}

                        {!data?.getAccount && (
                            <DataContainer>
                                <div className="org">
                                    <h1>Não há nenhuma conta cadastrada</h1>
                                </div>
                            </DataContainer>
                        )}
                    </CardShowContainerAccount>
                ) : (
                    <ContainerAccountMobile>
                        {data?.getAccount && (
                            <>
                                <div className="first-line">
                                    <div className="input-container">
                                        <label htmlFor="">Nome</label>
                                        <span>
                                            {data?.getAccount.legal_name}
                                        </span>
                                    </div>
                                </div>
                                <div className="second-line">
                                    <div className="input-container">
                                        <label htmlFor="">cpf</label>
                                        <span>
                                            {' '}
                                            {
                                                data?.getAccount.document_number
                                            }{' '}
                                        </span>
                                    </div>
                                    <div className="input-container">
                                        <label htmlFor="">Banco</label>
                                        <span>
                                            {' '}
                                            {data?.getAccount.bank_code}{' '}
                                        </span>
                                    </div>
                                    <div className="input-container">
                                        <label htmlFor="">Agência</label>
                                        <span>
                                            {' '}
                                            {data?.getAccount.agencia}{' '}
                                        </span>
                                    </div>
                                </div>
                                <div className="third-line">
                                    <div className="input-container">
                                        <label htmlFor="">
                                            Conta do beneficiário
                                        </label>
                                        <span>{data?.getAccount.conta}</span>
                                    </div>
                                </div>
                            </>
                        )}
                    </ContainerAccountMobile>
                )}
                {!data?.getAccount && (
                    <div className="button-container">
                        <button
                            onClick={() => show('blurring')}
                            className="add-payment-form"
                        >
                            Adicionar conta de recebimento
                        </button>
                    </div>
                )}
            </CardTitle>
        </>
    )
}
export default BankAccountModal
