import React from 'react'

import { Link, useHistory } from 'react-router-dom'

import { useSelector } from 'react-redux'

import IUserAccount from '../../models/IUserAccount'
import { AppState } from '../../store'
import {
    Product,
    useUpdateProductStatusMutation,
    useDeleteProductMutation,
    useGetAllUserViewsProductByProductIdQuery,
} from '../../generated/graphql'
import { Button, Popup } from 'semantic-ui-react'
import { MdPersonalVideo as Screen } from 'react-icons/md'
import Modal from '../Modal/Modal'
import Img from '../Default/CommomImg/Img'
import noPhoto from "../../assets/sem-produto-detalhe.png"

type Props = {
    product: Product
}

const MyProductsCard: React.FC<Props> = ({ product }) => {
    const user: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )

    const [updateStatus] = useUpdateProductStatusMutation()
    const { data: userViews, loading: userViewsLoading } =
        useGetAllUserViewsProductByProductIdQuery({
            variables: {
                productId: String(product.id),
            },
        })

    const [deleteProductMutation] = useDeleteProductMutation()
    const history = useHistory()
    const updateProduct = async (status: boolean): Promise<void> => {
        if (!user.block) {
            await updateStatus({
                variables: { id: product.id, status: status },
            })
            window.location.reload()
        }
    }
    const deleteProduct = async (): Promise<void> => {
        if (!user.block) {
            try {
                await deleteProductMutation({
                    variables: { productId: product.id },
                })
            } catch (error: any) {
                alert(error.message.replace('GraphQL error:', ''))
            }

            window.location.reload()
        }
    }
    const redirectToProductEdit = (): void => {
        if (!user.block) {
            if (product.categorieId === 8) {
                history.push(
                    `/register-product/${product.categorieId}/${product.id}/properties/edit`
                )
            } else if (product.categorieId === 7) {
                history.push(
                    `/register-product/${product.categorieId}/${product.id}/vehicles/edit`
                )
            } else if (product.categorieId !== 7 && product.categorieId !== 8) {
                history.push(
                    `/register-product/${product.categorieId}/${product.id}/product/edit`
                )
            }
        }
    }
    return (
        <>
            <div className="product-cards">
                <Link
                    to={`${
                        !user?.block
                            ? `/product-view/${product?.id}/all-data`
                            : `/shopping/${user.id}/products`
                    }`}
                >
                    {' '}
                    <Img
                        src={`${process.env.REACT_APP_STATIC_URL}/product/${product?.id}-1.jpeg`}
                        img={noPhoto}
                    />
                </Link>
                <div className="options-category">
                    <div className="img-background">
                        <Popup
                            trigger={
                                <Button
                                    icon="edit"
                                    color="blue"
                                    size="tiny"
                                    onClick={() => redirectToProductEdit()}
                                />
                            }
                            content="Editar"
                        />
                    </div>
                    <div className="img-background">
                        <Modal
                            open={{
                                element: (
                                    <Popup
                                        trigger={
                                            <Button
                                                color="blue"
                                                icon={`eye ${
                                                    product.active
                                                        ? 'slash'
                                                        : undefined
                                                }`}
                                                size="tiny"
                                            />
                                        }
                                        content={
                                            product.active
                                                ? 'Inativar'
                                                : 'Ativar'
                                        }
                                    />
                                ),
                            }}
                            title={
                                <span
                                    className="smb-myproducts-delete-item-title tw-m-0
                                tw-p-0 tw-font-primary tw-text-lg
                                tw-font-semibold tw-text-primary-700
                                sm:tw-text-2xl"
                                >
                                    `{product.active ? 'Inativar' : 'Ativar'}` Anuncio
                                </span>
                            }
                            description={
                                <span
                                    className="smb-myproducts-delete-item-description
                                tw-m-0 tw-p-0 tw-font-primary tw-text-base
                                tw-font-regular tw-text-smoke-500
                                sm:tw-text-xl"
                                >
                                    Você tem certeza que deseja `{product.active ? 'inativar' : 'ativar'}` esse
                                    anuncio: {product.title}?
                                </span>
                            }
                            action={{
                                body: {
                                    className: `smb-myproducts-delete-item-remove-action
                                        tw-rounded sm:tw-rounded-sm
                                        hover:tw-border hover:tw-border-solid
                                        hover:tw-border-smoke-500
                                        hover:tw-bg-smoke-100 tw-font-primary
                                        tw-text-lg sm:tw-text-2xl
                                        tw-font-regular tw-text-smoke-500
                                        tw-py-1 sm:tw-py-2 tw-px-6 sm:tw-px-12`,
                                    element: <>Sim, tenho certeza!</>,
                                },
                                fn: () => {
                                    updateProduct(
                                        !product.active
                                    )
                                },
                            }}
                            close={{
                                className: `smb-myproducts-delete-item-remove-close
                                tw-rounded sm:tw-rounded-sm tw-border
                                tw-border-solid tw-border-lime-700
                                tw-bg-lime-500 hover:tw-bg-lime-400
                                tw-font-primary tw-font-regular tw-text-lg
                                sm:tw-text-2xl tw-text-white tw-py-1 sm:tw-py-2
                                tw-px-6 sm:tw-px-12`,
                                element: <>Não, mudei de ideia.</>,
                            }}
                        />
                    </div>
                    <div className="img-background">
                        <Modal
                            open={{
                                element: (
                                    <div className="img-background">
                                        <Popup
                                            trigger={
                                                <Button
                                                    icon="delete"
                                                    color="blue"
                                                    size="tiny"
                                                />
                                            }
                                            content="Excluir"
                                        />
                                    </div>
                                ),
                            }}
                            title={
                                <span
                                    className="smb-myproducts-delete-item-title tw-m-0
                                tw-p-0 tw-font-primary tw-text-lg
                                tw-font-semibold tw-text-primary-700
                                sm:tw-text-2xl"
                                >
                                    Remover Anuncio
                                </span>
                            }
                            description={
                                <span
                                    className="smb-myproducts-delete-item-description
                                tw-m-0 tw-p-0 tw-font-primary tw-text-base
                                tw-font-regular tw-text-smoke-500
                                sm:tw-text-xl"
                                >
                                    Você tem certeza que deseja excluir esse
                                    anuncio:{product.title}?
                                </span>
                            }
                            action={{
                                body: {
                                    className: `smb-myproducts-delete-item-remove-action
                                        tw-rounded sm:tw-rounded-sm
                                        hover:tw-border hover:tw-border-solid
                                        hover:tw-border-smoke-500
                                        hover:tw-bg-smoke-100 tw-font-primary
                                        tw-text-lg sm:tw-text-2xl
                                        tw-font-regular tw-text-smoke-500
                                        tw-py-1 sm:tw-py-2 tw-px-6 sm:tw-px-12`,
                                    element: <>Sim, tenho certeza!</>,
                                },
                                fn: () => {
                                    deleteProduct()
                                },
                            }}
                            close={{
                                className: `smb-myproducts-delete-item-remove-close
                                tw-rounded sm:tw-rounded-sm tw-border
                                tw-border-solid tw-border-lime-700
                                tw-bg-lime-500 hover:tw-bg-lime-400
                                tw-font-primary tw-font-regular tw-text-lg
                                sm:tw-text-2xl tw-text-white tw-py-1 sm:tw-py-2
                                tw-px-6 sm:tw-px-12`,
                                element: <>Não, mudei de ideia.</>,
                            }}
                        />
                    </div>
                </div>
                {!userViewsLoading && (
                    <Popup
                        trigger={
                            <div className="product-user-views">
                                <Screen size="24" color="#5c5c5c" />
                                <span>
                                    {
                                        userViews?.getAllUserViewsProductByProductId
                                    }
                                </span>
                            </div>
                        }
                        content="Número de Visualizações"
                    />
                )}

                <div className="product-card-content">
                    <h2 className="tw-line-clamp-2">{product.title}</h2>

                    <div className="price-container">
                        <h2>
                            {product.price.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                            })}
                        </h2>
                        {product.typeNegotiation === 'Promoção' && (
                            <span>
                                Valor Mínimo:{' '}
                                {product.InitialValue.toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL',
                                })}
                            </span>
                        )}

                        <span>Estoque: {product.stock}</span>
                        <span>
                            Parcelamento Máximo: {product.maxInstallments}
                        </span>
                        <span>
                            Frete Grátis: {product.freeDelivery ? 'Sim' : 'Não'}
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}
export default MyProductsCard
