import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { AppState } from '../../store'
import IProductsCart from '../../models/ICart'
import Layout from '../../components/Layout/Layout'
import CartItem from '../../components/CartItem/CartItem'
import { MdOutlineShoppingCart as Cart } from 'react-icons/md'

const CartPage = (): JSX.Element => {
    const [informationQuantity, setInformationQuantity] =
        useState<number>(0)
    const [informationTotal, setInformationTotal] = useState<number>(0)
    const items: IProductsCart[] = useSelector(
        (state: AppState) => state.ProductsCart.currentProductsCart
    )

    const handleInfos = useCallback((): void => {
        let quantity = 0
        let total = 0
        for (const item of items) {
            const { product, quantity: itemQuantity } = item.parameters
            quantity += itemQuantity
            total += product.price * itemQuantity
        }
        setInformationQuantity(quantity)
        setInformationTotal(total)
    }, [items])

    useEffect(() => {
        if (items.length > 0) {
            handleInfos()
        }
    }, [handleInfos, items])

    return (
        <>
            {items && items.length > 0 ? (
                <Layout title="Carrinho" navigation>
                    <div
                        className="cart tw-items-center tw-justify-between
						tw-py-2 tw-font-primary tw-font-regular
						tw-text-smoke-500 sm:tw-py-4 md:tw-flex md:tw-flex-col 
						md:tw-py-6 lg:tw-py-8"
                    >
                        <div
                            className="tw-drop-shadow-lg tw-mx-4
						tw-items-center tw-justify-between tw-rounded tw-border
						tw-border-solid tw-border-smoke-300 tw-bg-white
						sm:tw-mx-8 sm:tw-rounded-sm md:tw-mx-12 md:tw-flex
						md:tw-flex-col lg:tw-mx-0 lg:tw-w-3/5"
                        >
                            <div
                                className="tw-flex tw-w-full tw-items-center
							tw-justify-center tw-space-x-2 tw-border-0
							tw-border-b tw-border-solid tw-border-smoke-300
							tw-px-8 tw-py-2 tw-font-primary tw-text-xl
							tw-font-semibold tw-text-primary-700 sm:tw-px-16
							sm:tw-py-4 sm:tw-text-3xl md:tw-space-x-4"
                            >
                                <Cart className="tw-h-6 tw-w-auto sm:tw-h-12" />
                                <span>Produtos no carrinho</span>
                            </div>
                            {items &&
                                items.map(
                                    (
                                        item: IProductsCart,
                                        index: number
                                    ) => (
                                        <CartItem
                                            item={item}
                                            key={index}
                                        />
                                    )
                                )}
                            <div
                                className="details tw-flex tw-w-full
								tw-flex-col tw-items-center tw-justify-between
								tw-space-y-2 tw-px-8 tw-py-2 sm:tw-flex-row
								sm:tw-px-16 sm:tw-py-4"
                            >
                                <div
                                    className="tw-flex tw-flex-col
								tw-items-center tw-justify-between
								sm:tw-items-start"
                                >
                                    <span
                                        className="tw-font-primary
									tw-text-base tw-font-regular
									tw-text-smoke-500 sm:tw-text-xl"
                                    >
                                        Quantidade de produtos:{' '}
                                        <label>
                                            {informationQuantity}
                                        </label>
                                    </span>
                                    <span
                                        className="tw-font-primary tw-text-lg
									tw-font-semibold tw-text-primary-700 sm:tw-text-2xl"
                                    >
                                        Total a pagar:{' '}
                                        <label className="tw-text-smoke-500">
                                            {informationTotal.toLocaleString(
                                                'pt-br',
                                                {
                                                    style: 'currency',
                                                    currency: 'BRL'
                                                }
                                            )}
                                        </label>
                                    </span>
                                </div>

                                <Link
                                    to="/buy-address-process-cart"
                                    className="tw-rounded tw-border
									tw-border-solid tw-border-lime-700
									tw-bg-lime-500 tw-py-1 tw-px-6
									tw-font-primary tw-text-lg tw-font-regular
									tw-text-white hover:tw-bg-lime-400
									sm:tw-rounded-sm sm:tw-py-2 sm:tw-px-12
									sm:tw-text-2xl"
                                >
                                    Ir para pagamento
                                </Link>
                            </div>
                        </div>
                    </div>
                </Layout>
            ) : (
                <Layout title="Seu carrinho está vazio">
                    <div
                        className="tw-grid tw-h-full tw-place-items-center
					tw-pt-36 tw-font-primary tw-text-xl tw-font-semibold
					tw-text-primary-700 sm:tw-text-3xl"
                    >
                        <span>Não há nada por aqui...</span>
                        <Link
                            to="/"
                            className="tw-text-link-500 tw-underline hover:tw-text-link-400"
                        >
                            Voltar
                        </Link>
                    </div>
                </Layout>
            )}
        </>
    )
}

export default CartPage
