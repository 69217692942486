import React, { useEffect, useState } from 'react'
import { Product, useGetProductByUserIdQuery } from '../../../generated/graphql'
import AliceCarousel from 'react-alice-carousel'
import {
    MdChevronLeft as Previous,
    MdChevronRight as Next,
} from 'react-icons/md'
import { Container, SliderContainer } from './Style'
import ProductCard from '../../ProductCard/ProductCard'
import ALICE_CAROUSEL_DEFAULTS from '../../../utils/AliceCarouselDefaults'

type Props = {
    sellerId: number
    productId: number
    sellerName: string
}

const StoreProductsSlider: React.FC<Props> = ({
    sellerId,
    productId,
    sellerName,
}) => {
    const [storeProducts, setStoreProducts] = useState<Product[]>()
    const responsive = {
        0: { items: 1 },
        320: { items: 2 },
        325: { items: 2 },
        425: { items: 2 },
        568: { items: 3 },
        768: { items: 4 },
        1024: { items: 6 },
    }

    const renderPrevButton = ({ isDisabled }: any): JSX.Element => {
        return (
            <span style={{ opacity: isDisabled ? '' : 1 }}>
                <Previous color="black" size="3rem" />
            </span>
        )
    }

    const renderNextButton = ({ isDisabled }: any): JSX.Element => {
        return (
            <span style={{ opacity: isDisabled ? '' : 1 }}>
                <Next color="black" size="3rem" />
            </span>
        )
    }

    const { data, loading } = useGetProductByUserIdQuery({
        variables: {
            getProductByUserId: {
                userId: sellerId,
            },
        },
    })

    useEffect(() => {
        if (!loading) {
            const filterProducts = data?.getProductByUserId.filter(
                (product) =>
                    product.id !== productId &&
                    (product.stock as number) > 0 &&
                    product.active
            )

            setStoreProducts(filterProducts as Product[])
        }
    }, [data, loading, productId])

    if (storeProducts?.length === 0 || loading) {
        return <></>
    }

    return (
        <SliderContainer>
            <Container>
                <div className="home-container">
                    <div className="product-cards-container">
                        <div className="container-title">
                            <h2>{`Mais de ${sellerName}`}</h2>
                        </div>

                        <AliceCarousel
                          {...ALICE_CAROUSEL_DEFAULTS}
                          renderPrevButton={renderPrevButton}
                          renderNextButton={renderNextButton}
                            items={storeProducts?.map(
                                (product: Product, index: number) => {
                                    return (
                                        <ProductCard
                                            product={product}
                                            key={index}
                                        />
                                    )
                                }
                            )}
                        />
                    </div>
                </div>
            </Container>
        </SliderContainer>
    )
}

export default StoreProductsSlider
