const Events = {
	CREATE_BID: 'CREATE_BID',
	ERROR: 'ERROR',
	FILTER_PRODUCTS: 'FILTER_PRODUCTS',
	JOIN_ROOM: 'JOIN_ROOM',
	LOAD_BIDS: 'LOAD_BIDS',
	LOAD_MESSAGES: 'LOAD_MESSAGES',
	LOAD_NOTIFICATIONS: 'LOAD_NOTIFICATIONS',
	MESSAGE_RECIEVED: 'MESSAGE_RECIEVED',
	MESSAGE_RECIEVED_OUTSIDE_ROOM: 'MESSAGE_RECIEVED_OUTSIDE_ROOM',
	MESSAGE_SENT: 'MESSAGE_SENT',
	MESSAGE_SENT_OUTSIDE_ROOM: 'MESSAGE_SENT_OUTSIDE_ROOM',
	NEW_BID: 'NEW_BID',
	NOTIFICATION: 'NOTIFICATION',
	PRODUCT_CHANGE_STATUS: 'PRODUCT_CHANGE_STATUS',
	PRODUCT_SEARCH_PREVIEW: 'PRODUCT_SEARCH_PREVIEW',
	PURCHASE_SHOPPING_CART: 'PURCHASE_SHOPPING_CART',
	RESULT_PRODUCT_SEARCH_PREVIEW: 'RESULT_PRODUCT_SEARCH_PREVIEW',
	RESULT_SEARCH: 'RESULT_SEARCH',
	RESULT_SEARCH_CATEGORIES: 'RESULT_SEARCH_CATEGORIES',
	RESULT_SHIPPING_PRICE: 'RESULT_SHIPPING_PRICE',
	RETURN_PURCHASE_IN_SHOPPING_CART: 'RETURN_PURCHASE_IN_SHOPPING_CART',
	SEARCH_CATEGORIES: 'SEARCH_CATEGORIES',
	SEARCH_PRODUCTS: 'SEARCH_PRODUCTS',
	SET_TRUE_VIEW_NOTIFICACAO:'SET_TRUE_VIEW_NOTIFICACAO',
	USER_BLOCKED: 'USER_BLOCKED',
	USER_BLOCKED_WARNING: 'USER_BLOCKED_WARNING',
	VERIFY_ONE_PRODUCT_SHIPPING_PRICE: 'VERIFY_ONE_PRODUCT_SHIPPING_PRICE',
	VERIFY_SHIPPING_PRICE: 'VERIFY_SHIPPING_PRICE'
};
export default Events;
