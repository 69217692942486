import styled from 'styled-components'

interface ShowCaseCardProductProps {
    activeProduct:boolean
}

interface ShowCaseButtonProps {
    activeButton:boolean
}

export const ShowCaseContainer = styled.div`

    max-width:100%;
    height:100vh;
`

export const ShowCaseHeader = styled.div`
    display:flex;
    padding:15px ;

    h2{
        color:black;
        font-family: 'Poppins', semi-bold;
    }

`

export const ShowCaseCard = styled.div`
    padding:15px;
    margin-left:5%;
    width:80%;

    .cardBody{
        display:flex;
        flex-direction:row;
    }

    .cardProducts{
        display:flex;
        flex-direction:row;
        flex-wrap:wrap;
    }

    .cardFooter{
        display:flex;
        flex-direction:row;
        justify-content:end;
    }

   
`

export const ShowCaseInput = styled.div`
    display:flex;
	flex-direction:column;
    input{
        width:10rem;
        border:1px solid grey;
        border-radius:3px;
        padding:5px;
        margin-right:2rem;
    }

    input:focus{
        outline:none;
        border:1px solid #04aac0;
    }
`

export const ShowCaseCardProduct = styled.button<ShowCaseCardProductProps>`
    padding:10px;
    color:black;
    display:flex;
    margin:1rem;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    margin-right:2rem;
    height:10vh;
    width:10vw;

   border:${(props) => props.activeProduct ? '3px solid #04aac0;' : 'none'};

`

export const ButtonShowCase = styled.button<ShowCaseButtonProps>`
    background-color:${(props)=> props.activeButton ? 'rgb(105, 182, 74)' : 'grey'};
    color:#fff;
    padding:8px;
    font-family:'Poppins',semi-bold;
    border-radius:3px;
    cursor:${(props)=> props.activeButton ? 'pointer' : 'not-allowed'};

`

export const ButtonsEditAccept = styled.button`
    display:flex;
    flex-direction:row ;
    align-items:flex-end;
    justify-content:start;
    justify-items:flex-start ;

`