import React, {forwardRef,useState,useCallback,useImperativeHandle} from 'react';
import { Modal } from 'semantic-ui-react';
import { ReactComponent as Block } from '../../../assets/icons/block.svg';
import { ModalContainer } from './styles';
export interface BlockProductModalHandles {
	handleOpenModal: () => void;
}
const BlockProductModal: React.RefForwardingComponent<BlockProductModalHandles> = (
	{},
	ref
) => {
	const [modalState, setModalState] = useState<boolean>(false);

	const handleOpenModal = useCallback(() => {
		setModalState(true);
	}, [setModalState]);

	useImperativeHandle(ref, () => {
		return {
			handleOpenModal,
		};
	});
	return (
		<Modal
			open={modalState}
			onClose={() => setModalState(false)}
			onOpen={() => setModalState(true)}
			style={{
				width: '453px',
				height: '498px',
				padding: '20px 30px'
			}}
		>
			<ModalContainer>
				<div className="modal-top">
					<figure>
						<Block />
					</figure>
					<div className="modal-infos">
						<h2>Bloqueamos seu anúncio</h2>
						<p>
							Nossos moderadores detectaram irregularidades quanto
							ao conteúdo anunciado por você. Seu anúncio foi
							bloqueado, e caso hajam novas ocorrências
							suspenderemos sua conta
						</p>
					</div>
				</div>
				<div className="modal-middle">
					<label>Comentário do moderador</label>
					<span>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit,
						sed do eiusmod tempor incididunt ut labore et dolore
						magna aliqua. Ut enim ad minim veniam, quis nostrud
						exercitation ullamco laboris nisi ut aliquip ex ea
						commodo consequat.
					</span>
				</div>
				<div className="modal-bottom">
					<button
						className="close-modal"
						onClick={() => setModalState(false)}
					>
						Ver anúncio
					</button>
					<button className="contact-adm">Suporte Sambay</button>
				</div>
			</ModalContainer>
		</Modal>
	);
};

export default forwardRef(BlockProductModal);
