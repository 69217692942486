import React, { useEffect, useState } from 'react';
import defaultImage from '../../../../assets/default.svg';
import { Container } from './styles';
import { Reports } from '../../../../generated/graphql';
import Img from '../../../Default/CommomImg/Img';
import { useHistory } from 'react-router-dom';
type Props = {
	Report: Reports;
};
const NotificationsCardItem: React.FC<Props> = ({ Report }) => {
	const history = useHistory();
	return (
		<Container
			onClick={() =>
				history.push(`/product-view/${Report?.Product?.id}/all-data`)
			}
		>
			<div className="user-photo">
				<Img
					src={`${process.env.REACT_APP_STATIC_URL}/user/${Report?.UserAccount?.id}.jpeg`}
				/>
			</div>
			<div className="user-informations">
				<span>
					{' '}
					{`Nova denuncia de "${
						Report?.GroundsForReport?.id !== 7
							? Report?.GroundsForReport?.title
							: ''
					}" feita por ${
						Report?.UserAccount?.name
					} referente ao anúncio "${Report?.Product?.title}"`}{' '}
				</span>
			</div>
		</Container>
	);
};

export default NotificationsCardItem;
