import React, { useCallback, useEffect, useState } from 'react'
import { CardsContainer } from './Style'
import {
    Product,
    useGetProductsByCategoryLazyQuery,
} from '../../generated/graphql'

import { appSocket } from '../../appSocket'
import Events from '../../Events'
import LogoSamy from '../../assets/sammy_original.png'
import ProductCard from '../ProductCard/ProductCard'
import useWindow from '../../hooks/useWindow'
import {ScreenLoading} from './Style'
type Props = {
    search: string
    categorie: string
}

const Search: React.FC<Props> = ({ search, categorie }) => {
    const window = useWindow()
    const [results, setResults] = useState<any>()
    const [getProducts, { data: dataProducts, loading: loadingProducts }] =
        useGetProductsByCategoryLazyQuery()

    useEffect(() => {
        if(categorie === 'sazonalpromoslider' && search === 'category'){
            getProducts({variables:{category:categorie}})
        }
        if (search === 'category') {
            appSocket.emit(
                Events.SEARCH_PRODUCTS,
                categorie,
                {
                    states: [],
                    useStateFilter: 'all',
                    createdDateFilter: 'all',
                },
                'categorySearch'
            )
        } else {
            appSocket.emit(
                Events.SEARCH_PRODUCTS,
                search,
                {
                    states: [],
                    useStateFilter: 'all',
                    createdDateFilter: 'all',
                },
                'search'
            )
        }

        return () => {
            appSocket.off(Events.SEARCH_PRODUCTS)
        }
    }, [categorie, search])

    const searchFunc = useCallback((): void => {
        if (search) {
            appSocket.emit(
                Events.SEARCH_PRODUCTS,
                search,
                {
                    states: [],
                    useStateFilter: 'all',
                    createdDateFilter: 'all',
                },
                'search'
            )
        }
    }, [search])
    useEffect(() => {
        appSocket.on(Events.RESULT_SEARCH, (result: Product[]) => {
            setResults(result)
        })
        return () => {
            appSocket.off(Events.RESULT_SEARCH)
        }
    }, [])

    useEffect(() => {
        if (search === 'category') {
            getProducts({ variables: { category: categorie } })
        } else {
            searchFunc()
        }
    }, [categorie, getProducts, search, searchFunc])

    useEffect(() => {
        if (!loadingProducts) {
            setResults(dataProducts?.getProductsByCategory)
        }
    }, [dataProducts, loadingProducts])

    if(!results){
        return (
            <ScreenLoading>
                <img src={LogoSamy} alt="Logo Sammy" height={100} width={100} />
                <h1>Carregando!</h1>
            </ScreenLoading>
        )
    }

    return (
        <CardsContainer>
            <div className="product-cards-container">
                {results &&
                    results.map((product: Product, index: number) => {
                        return (
                            <ProductCard product={product} key={index} />
                        ) 
                    })}
            </div>
        </CardsContainer>
    )
}
export default Search
