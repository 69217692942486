import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import IUserAccount from '../../models/IUserAccount';
import { AppState } from '../../store';
import {
	useCreateProductQuestionAnswersMutation,
	useMarkAsRepeatMutation,
} from '../../generated/graphql';

type props = {
	ProductQuestionId: number;
	userId: number;
	productSelerId: number;
};

const MakeProductQuestionAnswer: React.FC<props> = ({
	ProductQuestionId,
	userId,
	productSelerId,
}) => {
	const user: IUserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const [
		createQuestionAnswerQuery,
	] = useCreateProductQuestionAnswersMutation();
	const [markRepeat] = useMarkAsRepeatMutation();
	const [commentDescription, setCommentDescription] = useState<string>();
	const [showAnswer, setShowAnswer] = useState<boolean>(false);

	const createCommentAnswer = async () => {
		await createQuestionAnswerQuery({
			variables: {
				productQuestionId: ProductQuestionId,
				userId: user.id,
				description: commentDescription!,
			},
		});
		window.location.reload();
	};
	const markAsRepeat = async () => {
		await markRepeat({
			variables: { userId: userId, id: ProductQuestionId },
		});
		window.location.reload();
	};
	const valid = () => {
		if (!showAnswer && productSelerId === user.id) {
			return (
				<>
					<button onClick={() => setShowAnswer(true)}>
						Responder
					</button>
					<div className="mark">
						<button onClick={() => markAsRepeat()}>
							Pergunta repetida
						</button>
					</div>
				</>
			);
		}
	};
	return (
		<>
			{valid()}

			{showAnswer && (
				<div className="make-question">
					<textarea
						onChange={(e) => setCommentDescription(e.target.value)}
						placeholder="Digite aqui sua resposta"
					></textarea>
					<button onClick={() => createCommentAnswer()}>
						Publicar resposta
					</button>
				</div>
			)}
		</>
	);
};
export default MakeProductQuestionAnswer;
