import React from 'react'

import { ContainerPgtos } from './Style'
import payments from '../../../assets/banner_pgtos.png'
import paymentsMobile from '../../../assets/mobile_payments.png'
import useWindow from '../../../hooks/useWindow'

const PaymentSection = (): JSX.Element => {
    const window = useWindow()
    return (
        <ContainerPgtos>
            <div className="sectionBannerPgtos">
                {window.innerWidth < 768 ? (
                    <>
                        <div className="mobile_payment_banner_container">
                            <img src={paymentsMobile} alt="mobile_payments" />
                        </div>
                    </>
                ) : (
                    <img
                        src={payments}
                        alt="banner_pgtos"
                        className="imgPgtos"
                    />
                )}
            </div>
        </ContainerPgtos>
    )
}

export default PaymentSection
