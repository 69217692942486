import React from 'react';
import Dropzone from 'react-dropzone';

import styled from './styles.module.css';

type Props = {
	onUpload: any;
};

export function Upload({ onUpload }: Props) {
	function renderDragMessage(isDragActive: any, isDragReject: any) {
		if (!isDragActive) {
			return <p>Arraste seus arquivos aqui...</p>;
		}
		if (isDragReject) {
			return <p className={styled.error}>Arquivos não suportado</p>;
		}
		return <p className={styled.success}>Solte os arquivos aqui</p>;
	}

	return (
		<Dropzone accept="image/*" onDropAccepted={onUpload}>
			{({
				getRootProps,
				getInputProps,
				isDragActive,
				isDragReject,
			}: any) => (
				<div
					{...getRootProps()}
					isDragActive={isDragActive && styled.dragActive} //Não está mudando de cor
					isDragReject={isDragReject && styled.dragReject}
					className={styled.dropzone}
				>
					<div className={styled.inputZone}>
						<input {...getInputProps()} />
						{renderDragMessage(isDragActive, isDragReject)}
					</div>
				</div>
			)}
		</Dropzone>
	);
}
