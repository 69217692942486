import React from 'react';
import MyBuys from '../../components/MyBuys/MyBuys';
import Layout from '../../components/Layout/Layout';

const MyBuysPage = () => {
	return (
		<Layout title="Minhas Compras">
			<MyBuys />
		</Layout>
	);
};
export default MyBuysPage;
