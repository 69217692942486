import React, { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import {
	useCreateProductRatingMutation,
	useGetPurchaseQuery,
	useTrackQuery,
} from '../../../generated/graphql';
import backButton from '../../../assets/icons/back.svg';
import {
	AvaliationCard,
	Back,
	Container,
	DeliveryContainer,
	DeliveryInformations,
	ProductInformations,
	RatingStarsContainer,
	StarContainer,
	Stars,
} from './Style';
import { ReactComponent as Star } from '../../../assets/icons/ratingStar.svg';
import DeliveryTruck from '../../../assets/icons/deliveryTruck.svg';
import { useSelector } from 'react-redux';
import IUserAccount from '../../../models/IUserAccount';
import { AppState } from '../../../store';
import Img from '../../Default/CommomImg/Img';
import { useForm } from 'react-hook-form';
import AlertModal, { ModalHandles } from '../../AlertModal/AlertModal';
import noPhoto from '../../../assets/sem-produto-detalhe.png'

interface myBuyItemParams {
	buyId: string;
}
interface FormInputs {
	ratingDescription: string;
}

const MyBuysItem: React.FC = () => {
	const history = useHistory();

	const buyId: myBuyItemParams = useParams();
	const { data, loading } = useGetPurchaseQuery({
		variables: { id: parseInt(buyId.buyId) },
	});
	const [valid, setValid] = useState<boolean>(false);
	const [star1, setStar1] = useState<string>('white');
	const [star2, setStar2] = useState<string>('white');
	const [star3, setStar3] = useState<string>('white');
	const [star4, setStar4] = useState<string>('white');
	const [star5, setStar5] = useState<string>('white');
	const user: IUserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const [rating, setRating] = useState<number>(1);
	const [createProductRating] = useCreateProductRatingMutation();
	const { register, handleSubmit } = useForm<FormInputs>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
	});
	const modalRef = useRef<ModalHandles>(null);
	const [modalDescription, setModalDescription] = useState<string>('');

	const { data: shippingStatus, loading: loadingShippingStatus } =
		useTrackQuery({
			variables: {
				userId: data?.getPurchase.Product?.userAccountId!,
				ticketId: data?.getPurchase?.Tickets?.ticketId!,
			},
		});
	const setActiveStar = (rating: any) => {
		if (rating === 'star1') {
			setRating(1);
			setStar1('#ff7e0f');
			setStar2('white');
			setStar3('white');
			setStar4('white');
			setStar5('white');
		}
		if (rating === 'star2') {
			setRating(2);

			setStar1('#ff7e0f');
			setStar2('#ff7e0f');
			setStar3('white');
			setStar4('white');
			setStar5('white');
		}
		if (rating === 'star3') {
			setRating(3);

			setStar1('#ff7e0f');
			setStar2('#ff7e0f');
			setStar3('#ff7e0f');
			setStar4('white');
			setStar5('white');
		}
		if (rating === 'star4') {
			setRating(4);

			setStar1('#ff7e0f');
			setStar2('#ff7e0f');
			setStar3('#ff7e0f');
			setStar4('#ff7e0f');
			setStar5('white');
		}
		if (rating === 'star5') {
			setRating(5);

			setStar1('#ff7e0f');
			setStar2('#ff7e0f');
			setStar3('#ff7e0f');
			setStar4('#ff7e0f');
			setStar5('#ff7e0f');
		}
	};

	function formatarData(data: any) {
		if (data) {
			let dataFormatada: Date = new Date(data);
			return `${dataFormatada.toLocaleDateString()}`;
		}
		return '-----';
	}

	function formatarHorario(data: any) {
		if (data) {
			let dataFormatada: Date = new Date(data);
			return `${dataFormatada.toLocaleTimeString()}`;
		}
		return '-----';
	}

	function formatarStatusCompra(statusCompra: any) {
		if (statusCompra === 'posted') return 'Objeto postado';
		if (statusCompra === 'movement')
			return 'Objeto em trânsito - por favor aguarde';
		if (statusCompra === 'onroute')
			return 'Objeto saiu para entrega ao destinatário';
		if (statusCompra === 'delivered')
			return 'Objeto entregue ao destinatário';
		return 'Aguarde por informações';
	}

	const onSubmit = async (dataForm: FormInputs) => {
		try {
			await createProductRating({
				variables: {
					productId: data!.getPurchase!.Product!.id!,
					rating: rating,
					ratingDescription: dataForm.ratingDescription,
					userId: user.id,
					sellerId: data?.getPurchase.Product
						?.userAccountId as number,
				},
			});
			setModalDescription('Avaliação registrada com sucesso');
			modalRef.current?.handleOpenModal();
		} catch (err: any) {
			setModalDescription('Falha ao avaliar produto');
			modalRef.current?.handleOpenModal();
		}
	};

	function melhorRastreio(codigoDeRastreio: string | undefined) {
		{
			/*console.log('Codigo de rastreio', codigoDeRastreio); */
		}
		if (codigoDeRastreio) {
			return `https://www.melhorrastreio.com.br/rastreio/${codigoDeRastreio}`;
		}
		return '';
	}

	return (
		<>
			<AlertModal
				ref={modalRef}
				AreaTitle="Minhas compras"
				DescriptionText={modalDescription}
				reload={false}
			/>
			<Container>
				<Back>
					<img
						src={backButton}
						onClick={() => history.push('/my-buys')}
						alt=""
					/>
					<span>{data?.getPurchase.Product?.title}</span>
				</Back>
				<DeliveryContainer>
					<DeliveryInformations>
						<div className="delivery-informations-container">
							<img src={DeliveryTruck} />
							<div className="delivery-informations">
								<h2>
									{formatarStatusCompra(
										shippingStatus?.track.status
									)}
								</h2>
								<h3>Código de Rastreio</h3>

								<a
									href={melhorRastreio(
										shippingStatus?.track
											.melhorenvio_tracking
									)}
								>
									<span>
										{
											shippingStatus?.track
												.melhorenvio_tracking
										}
									</span>
								</a>
							</div>
						</div>
					</DeliveryInformations>
					<div className="delivery-timeline">
						<div className="event-text">
							<h3
								className={`${
									data?.getPurchase.statusId === 3
										? 'active'
										: ''
								}`}
							>
								Confirmação de pagamento
							</h3>
							<h3
								className={`${
									shippingStatus?.track.status ===
										'released' ||
									shippingStatus?.track.status === 'posted'
										? 'active'
										: ''
								}`}
							>
								Objeto postado
							</h3>
							<h3
								className={`${
									shippingStatus?.track.status === 'movement'
										? 'active'
										: ''
								}`}
							>
								Objeto em trânsito - por favor aguarde
							</h3>
							<h3
								className={`${
									shippingStatus?.track.status === 'onroute'
										? 'active'
										: ''
								}`}
							>
								Objeto saiu para entrega ao destinatário
							</h3>
							<h3
								className={`${
									shippingStatus?.track.status === 'delivered'
										? 'active'
										: ''
								}`}
							>
								Objeto entregue ao destinatário!
							</h3>
						</div>
						<div className="event-points-container">
							<div className="line-container">
								<div className="line"></div>
							</div>
							<div className="active-line-container">
								{' '}
								<div className="line"></div>
							</div>
							<div
								className={`ball ${
									data?.getPurchase.statusId === 3
										? 'active'
										: ''
								}`}
							/>
							<div
								className={`ball ${
									shippingStatus?.track.status ===
										'released' ||
									shippingStatus?.track.status === 'posted'
										? 'active'
										: ''
								}`}
							/>
							<div
								className={`ball ${
									shippingStatus?.track.status === 'movement'
										? 'active'
										: ''
								}`}
							/>
							<div
								className={`ball ${
									shippingStatus?.track.status === 'onroute'
										? 'active'
										: ''
								}`}
							/>
							<div
								className={`ball ${
									shippingStatus?.track.status === 'delivered'
										? 'active'
										: ''
								}`}
							/>
						</div>
						<div className="event-date-container">
							<div className="event-date">
								<span>
									{formatarData(
										shippingStatus?.track.paid_at
									)}
								</span>
								<span>
									{formatarHorario(
										shippingStatus?.track.paid_at
									)}
								</span>
							</div>

							<div className="event-date">
								<span>
									{formatarData(
										shippingStatus?.track.posted_at
									)}
								</span>
								<span>
									{formatarHorario(
										shippingStatus?.track.posted_at
									)}
								</span>
							</div>
							<div className="event-date">
								<span>
									{formatarData(
										shippingStatus?.track.delivered_at
									)}
								</span>
								<span>
									{formatarHorario(
										shippingStatus?.track.delivered_at
									)}
								</span>
							</div>
							<div className="event-date">
								<span>
									{formatarData(
										shippingStatus?.track.delivered_at
									)}
								</span>
								<span>
									{formatarHorario(
										shippingStatus?.track.delivered_at
									)}
								</span>
							</div>
							<div className="event-date">
								<span>
									{formatarData(
										shippingStatus?.track.delivered_at
									)}
								</span>
								<span>
									{formatarHorario(
										shippingStatus?.track.delivered_at
									)}
								</span>
							</div>
						</div>
					</div>
				</DeliveryContainer>

				<ProductInformations>
					<div className="product-img">
						{data && (
							<Img
								src={`${process.env.REACT_APP_STATIC_URL}/product/${data?.getPurchase?.Product?.id}-1.jpeg`}
								className="big-img"
								alt=""
								img={noPhoto}
							/>
						)}
						<div className="product-informations">
							<h2>{data?.getPurchase?.Status?.ptName}</h2>
							{data && (
								<h1>
									Total{' '}
									{parseInt(
										data!.getPurchase!.price!
									).toLocaleString('pt-br', {
										style: 'currency',
										currency: 'BRL',
									})}{' '}
								</h1>
							)}
						</div>
					</div>
					{data && (
						<span
							className="product-informations-button"
							onClick={() =>
								history.push(
									`/product-view/${
										data!.getPurchase!.Product!.id
									}/all-data`
								)
							}
						>
							Ir para página do produto
						</span>
					)}
				</ProductInformations>
				<AvaliationCard>
					<form
						className="avaliation-card-title"
						onSubmit={handleSubmit(onSubmit)}
					>
						<h2>Avalie o vendedor</h2>
						<div className="user-infos-and-rating">
							<div className="user-img-border">
								<Img
									src={`${process.env.REACT_APP_STATIC_URL}/product/${user.id}.jpeg`}
								/>
							</div>
							<RatingStarsContainer>
								<h3>
									{
										data?.getPurchase?.Product?.UserAccount
											?.name
									}
								</h3>
								<Stars>
									<StarContainer
										fill={star1}
										stroke={'#ff7e0f'}
										onClick={() => setActiveStar('star1')}
									>
										<Star></Star>
									</StarContainer>
									<StarContainer
										fill={star2}
										stroke={'#ff7e0f'}
										onClick={() => setActiveStar('star2')}
									>
										<Star></Star>
									</StarContainer>
									<StarContainer
										fill={star3}
										stroke={'#ff7e0f'}
										onClick={() => setActiveStar('star3')}
									>
										<Star></Star>
									</StarContainer>
									<StarContainer
										fill={star4}
										stroke={'#ff7e0f'}
										onClick={() => setActiveStar('star4')}
									>
										<Star></Star>
									</StarContainer>
									<StarContainer
										fill={star5}
										stroke={'#ff7e0f'}
										onClick={() => setActiveStar('star5')}
									>
										<Star></Star>
									</StarContainer>
								</Stars>
							</RatingStarsContainer>
						</div>
						<input
							type="text"
							placeholder="Digite uma avaliação."
							className="avaliation-input"
							{...register('ratingDescription', {
								required: true,
							})}
						/>
						<button className="save-button" type="submit">
							Enviar
						</button>
					</form>
				</AvaliationCard>
			</Container>
		</>
	);
};

export default MyBuysItem;
