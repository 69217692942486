import React from 'react'

import { Container, MainCard, PageContainer, PageHeader } from './styles'
import back from '../../assets/icons/back.svg'
import { Link } from 'react-router-dom'

const WarrantyPolicy: React.FC = () => {
    return (
        <PageContainer>
            <Container>
                <PageHeader>
                    <Link to="/">
                        <img src={back} alt="" />
                    </Link>
                    <Link to="/">
                        <h3>Política de Troca</h3>
                    </Link>
                </PageHeader>
                <MainCard>
                    <div className="items-container">
                        <h1>Política de Troca</h1>
                        <p>
                            Esta política de troca é válida a partir de Mar
                            2023.
                        </p>
                        <p>
                            Caso você não esteja satisfeito com o produto ou
                            tenha se arrependido da compra, você poderá
                            solicitar uma troca ou devolução em até 7 dias
                            contados da data do recebimento do pedido no seu
                            endereço.
                        </p>
                        <p>
                            <strong>Condições para efetuar uma troca:</strong>
                        </p>
                        <p>
                            Caso queira efetuar uma troca, o produto deverá
                            estar em perfeito estado e sem sinais de uso, ou
                            seja, nas mesmas condições que você o recebeu. Além
                            disso, é indispensável que o item esteja em sua
                            embalagem original.
                        </p>
                        <p>
                            <strong>Como solicitar uma troca:</strong>
                        </p>
                        <p>
                            Envie um e-mail para comercial@sambay.com.br,
                            informando seu nome completo, número do pedido e
                            motivo da troca. Você receberá o nosso contato com
                            as informações necessárias para efetuar a troca.
                        </p>
                    </div>
                </MainCard>
            </Container>
        </PageContainer>
    )
}

export default WarrantyPolicy
