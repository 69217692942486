import IEditedProduct, {
	IUpdateEditedProductAction,
} from '../models/EditedProduct';

export const updateEditedProduct = (
	editedProduct: IEditedProduct
): IUpdateEditedProductAction => ({
	type: 'UPDATE_PRODUCT',
	editedProduct,
});
