import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {Product, useGetAllSocialProjectsQuery} from '../../generated/graphql';
import ProductCard from '../ProductCard/ProductCard';
import { Aside, Header, Main } from './styles';
import Logopp from '../../assets/logopp.png';


const SocialProjects: React.FC = () => {
	const { data, loading } = useGetAllSocialProjectsQuery();
	return (
		<>
			<Header>
				<div className="text-container">
					<h2>Projetos Sociais</h2>

					<p>
						Esta é a página do Sambay express, onde concentraremos
						nossas ações de cunho social, algo que sempre nos
						motivou. Permanentemente, teremos vendas com 100% do
						lucro revertido para entidades e hospitais
						filantrópicos, casas de assistência, lares para crianças
						em situação de vulnerabilidade social etc. As vendas
						também poderão ser localizadas através da busca
						tradicional no site. Esses anuncios terão um ícone de um
						pequeno coração azul, no início do título. A descrição
						do produto também mencionará esse importante detalhe.
						Serão sempre produtos especiais e bem bacanas,
						selecionados com muito carinho, por nossa equipe,
						especialmente, para você.
					</p>
					<span>
						Também apoiaremos algumas entidades, de forma direta.
						Afinal de contas, ajudar o próximo faz bem e todo mundo
						gosta. Participe!
					</span>
				</div>
			</Header>
			<Aside>
				<div className="link-container">
					<span>
						Interessado em cadastrar seu próprio projeto social?
						Entre em contato e conte-nos um pouco mais sobre a sua
						causa
					</span>
					<Link
						to="/social-projects-contact"
						className="redirect-button"
					>
						Clique aqui
					</Link>
				</div>
			</Aside>
			<Main>
				<div className="products-card">
					<h2>Instituições Beneficiadas</h2>
					<div className="institutions">
					<a href={`http://www.golspelavida.org.br/selecao/o-instituto/`}
						target="blank"
					>
						<img
							src={Logopp}
							alt="PequenoPrincipeFooter"
							className="PequenoPrincipeImg"
						/>
					</a>
					</div>
					<p>
						{' '}
						Seguem os produtos participantes, cujo valor de venda
						será integralmente revertido para as causas apoiados
						pela Sambay express. Contribua, adquirindo esses
						produtos!{' '}
					</p>
					<div className="products">
						{!loading &&
							data?.getAllSocialProjects.map(
								(product: Product, index) => (
									<>
										{product.stock! > 0 && product.active  && (
											<ProductCard
												product={product}
												key={product.id}
											/>
										)}
									</>
								)
							)}
					</div>
				</div>
			</Main>
		</>
	);
};

export default SocialProjects;
