import React, { forwardRef, InputHTMLAttributes } from 'react';

// import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	label: string;
	classStyle: string;
}

const ProfileInput: React.RefForwardingComponent<
	HTMLInputElement,
	InputProps
> = ({ label, classStyle, ...rest }, ref) => {
	return (
		<div className={classStyle}>
			<label> {label} </label>
			<input type="text" {...rest} ref={ref} />
		</div>
	);
};

export default forwardRef(ProfileInput);
