import React from 'react'

import { Container, MainCard, PageContainer, PageHeader } from './styles'
import back from '../../assets/icons/back.svg'
import FaqItem from './FaqItem/FaqItem'
import { FaqQuestion, faqQuestions } from '../../utils/faqQuestions'
import { useHistory } from 'react-router'

const Faq: React.FC = () => {
    const history = useHistory()
    return (
        <PageContainer>
            <Container>
                <PageHeader>
                    <img src={back} alt="" onClick={() => history.goBack()} />
                    <h3 onClick={() => history.goBack()}>
                        Central de Ajuda/FAQ
                    </h3>
                </PageHeader>
                <MainCard>
                    <h2>Central de Ajuda/FAQ</h2>
                    <div className="items-container">
                        {faqQuestions.map((faqQuestion: FaqQuestion, index) => (
                            <FaqItem key={index} {...faqQuestion} />
                        ))}
                    </div>
                </MainCard>
            </Container>
        </PageContainer>
    )
}

export default Faq
