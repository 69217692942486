import React from 'react';

import { Container } from './styles';

type Props = {
	title: string;
	description: string;
};

const AboutUsItem: React.FC<Props> = ({ ...props }) => {
	return (
		<Container>
			<h2> {props.title} </h2>
			<span> {props.description} </span>
		</Container>
	);
};

export default AboutUsItem;
