import styled from 'styled-components';

export const ContainerPgtos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color:#fff;
    .sectionBannerPgtos{
    display: flex;
    justify-content: center;

    .mobile_payment_banner_container{
      display: flex;
      flex-direction: row;
      justify-content: center;
      background-color: #fff;

      img {
        flex-direction: row;
        justify-content: center;
        width: 80%;
        margin-bottom: 2rem;
        border-radius: 8px;
      }
    }
    .imgPgtos{
      display: flex;
      border-radius:8px;
      width: 71.5%;
      margin-bottom:1rem;
    }
  }
`;
