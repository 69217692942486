import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { ProductMenu } from './Styles';


const ProductAdmMenu: React.FC = () => {
	const { url } = useRouteMatch();
	return (
		<>
			<ProductMenu>
				<div>
					<NavLink
						to={`${url}/all-data`}
						className="items"
						activeClassName="items-active"
					>
						<h2>Dados gerais</h2>
					</NavLink>			
					<NavLink
						to={`${url}/product-avaliations`}
						className="items"
						activeClassName="items-active"
					>
						<h2>Avaliações</h2>
					</NavLink>
				</div>
			</ProductMenu>
		</>
	);
};

export default ProductAdmMenu;
