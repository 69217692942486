import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import IUserAccount from '../../../models/IUserAccount'
import { AppState } from '../../../store'
import {
    Shipping,
    useGetShippingsByUserIdLazyQuery,
    useGetShippingsAsCsvLazyQuery,
    usePutShippingsFromCsvMutation,
    useDeleteShippingsMutation,
} from '../../../generated/graphql'
import { Card, MyProductContainer, Title } from '../Style'
import defaultImg from '../../../assets/icons/deleteBannerIcon.svg'
import { cepDefault } from '../../../utils/Masks'
import Modal from '../../Modal/Modal'

interface ShippingItem {
    shipping: Shipping
    isSelected: boolean
}

const ShippingMethods = () => {
    const [shippings, setShippings] = useState<ShippingItem[]>([])
    const [selectedShippings, setSelectedShippings] = useState<number[]>([])
    const [csv, setCsv] = useState<File>()
    const [selectAll, setSelectAll] = useState(false)

    const user: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )

    const [getShippingsAsCsv, { data: dataShippingsAsCsv }] =
        useGetShippingsAsCsvLazyQuery({ fetchPolicy: 'no-cache' })

    const [getShippingsByUserId, { data, loading }] =
        useGetShippingsByUserIdLazyQuery({ fetchPolicy: 'no-cache' })

    const [deleteShippings] = useDeleteShippingsMutation({
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            alert('Envios removidos com sucesso.')
            getShippingsByUserId({
                variables: {
                    userId: user.id,
                },
            })
        },
        onError(error) {
            alert(error.message)
        },
    })

    const [putShippingsFromCsv] = usePutShippingsFromCsvMutation({
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            alert('Planilha importada com sucesso!')
            getShippingsByUserId({
                variables: {
                    userId: user.id,
                },
            })
        },
        onError(error) {
            alert(error.message)
        },
    })

    function handleGetShippingsAsCsv(): void {
        getShippingsAsCsv({
            variables: {
                userId: user.id,
            },
        })
    }

    const handleCheckboxChange = (index: number) => {
        const updatedShippings = [...shippings]
        if (index === -1) {
            const selectAllValue = !selectAll
            for (const shipping of updatedShippings) {
                shipping.isSelected = selectAllValue
            }
            setSelectAll(selectAllValue)
        } else {
            updatedShippings[index].isSelected =
                !updatedShippings[index].isSelected
            setSelectAll(
                updatedShippings.every((product) => product.isSelected)
            )
        }
        setShippings(updatedShippings)

        const selectedIds = updatedShippings
            .filter((item) => item.isSelected && item.shipping.id)
            .map((item) => item.shipping.id)
        setSelectedShippings(selectedIds)
    }

    const handleDeleteShippings = () => {
        deleteShippings({
            variables: {
                userId: user.id,
                shippingIds: selectedShippings,
            },
        })
    }

    useEffect(() => {
        getShippingsByUserId({
            variables: {
                userId: user.id,
            },
        })
    }, [getShippingsByUserId, user.id])

    useEffect(() => {
        if (data && data.getShippingsByUserId && !loading) {
            setShippings(
                data.getShippingsByUserId.map((shipping) => ({
                    isSelected: false,
                    shipping: shipping as Shipping,
                }))
            )
            setSelectAll(false)
        }
    }, [data, loading])

    useEffect(() => {
        if (dataShippingsAsCsv) {
            const blob = new Blob([dataShippingsAsCsv.getShippingsAsCsv], {
                type: 'application/x-msexcel',
            })
            const downloadLink = document.createElement('a')
            document.body.append(downloadLink)
            downloadLink.setAttribute('type', 'hidden')
            downloadLink.href = window.URL.createObjectURL(blob)
            downloadLink.download =
                shippings.length > 0
                    ? 'envios_cadastrados.csv'
                    : 'envios_modelo.csv'
            downloadLink.click()
        }
    }, [dataShippingsAsCsv])

    useEffect(() => {
        if (csv)
            putShippingsFromCsv({
                variables: {
                    userId: user.id,
                    csv,
                },
            })
    }, [csv, putShippingsFromCsv, user.id])

    return (
        <MyProductContainer>
            <Title>
                <div className="title-organizer">
                    <div className="title-container tw-items-center tw-space-x-2">
                        <h2>Envios</h2>
                        <div className="tw-space-x-2 tw-rounded-md tw-border tw-border-solid tw-border-smoke-500 tw-p-2">
                            <label className="tw-text-smoke-500">
                                {'Importar planilha'}
                            </label>
                            <input
                                type="file"
                                accept=".csv"
                                onChange={({ target }) => {
                                    if (target.files) setCsv(target.files[0])
                                }}
                            />
                        </div>
                        <button
                            onClick={() => handleGetShippingsAsCsv()}
                            className={`
                                        tw-bg-smoke-100 
                                        tw-text-black
                                        disabled:tw-cursor-not-allowed
                                        disabled:tw-bg-white
                                        disabled:tw-text-smoke-300`}
                        >
                            {shippings && shippings.length > 0
                                ? 'Exportar planilha'
                                : 'Exportar modelo'}
                        </button>

                        {selectedShippings && selectedShippings.length > 0 && (
                            <Modal
                                open={{
                                    className: `
                                        tw-bg-smoke-100
                                        tw-text-black
                                        disabled:tw-cursor-not-allowed
                                        disabled:tw-bg-white
                                        disabled:tw-text-smoke-300
                                    `,
                                    element: (
                                        <span>
                                            {selectedShippings.length > 1
                                                ? 'Excluir Selecionados'
                                                : 'Excluir Selecionado'}
                                        </span>
                                    ),
                                }}
                                title={
                                    <span
                                        className="smb-cart-item-title tw-m-0
                                tw-p-0 tw-font-primary tw-text-lg
                                tw-font-semibold tw-text-primary-700
                                sm:tw-text-2xl"
                                    >
                                        {selectedShippings.length > 1
                                            ? 'Excluir envios'
                                            : 'Excluir envio'}
                                    </span>
                                }
                                description={
                                    <span
                                        className="smb-cart-item-description
                                tw-m-0 tw-p-0 tw-font-primary tw-text-base
                                tw-font-regular tw-text-smoke-500
                                sm:tw-text-xl"
                                    >
                                        {`Você tem certeza que deseja remover
                                        ${
                                            selectedShippings.length > 1
                                                ? 'os envios'
                                                : 'o envio'
                                        }? `}
                                        <strong>
                                            Essa operação é irreversível!
                                        </strong>
                                    </span>
                                }
                                action={{
                                    body: {
                                        className: `smb-cart-item-remove-action
                                        tw-rounded sm:tw-rounded-sm tw-border
                                        tw-border-solid tw-border-red-700
                                        tw-bg-red-500 hover:tw-bg-red-400
                                        tw-font-primary tw-font-regular tw-text-lg
                                        sm:tw-text-2xl tw-text-white tw-py-1 sm:tw-py-2
                                        tw-px-6 sm:tw-px-12`,
                                        element: <>Deletar</>,
                                    },
                                    fn: () => {
                                        handleDeleteShippings()
                                    },
                                }}
                                close={{
                                    className: `smb-cart-item-remove-close
                                    tw-rounded sm:tw-rounded-sm
                                    hover:tw-border hover:tw-border-solid
                                    hover:tw-border-smoke-500
                                    hover:tw-bg-smoke-100 tw-font-primary
                                    tw-text-lg sm:tw-text-2xl
                                    tw-font-regular tw-text-smoke-500
                                    tw-py-1 sm:tw-py-2 tw-px-6 sm:tw-px-12`,
                                    element: <>Cancelar</>,
                                }}
                            />
                        )}
                    </div>
                </div>
            </Title>
            {loading ? (
                <h1>Carregando...</h1>
            ) : (
                <Card>
                    <div className="overflow-x-auto w-full">
                        <table className="table w-full">
                            <thead>
                                <tr>
                                    <th>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={selectAll}
                                                onChange={() =>
                                                    handleCheckboxChange(-1)
                                                }
                                            />
                                        </label>
                                    </th>
                                    <th></th>
                                    <th>Descrição</th>
                                    <th>Transportadora</th>
                                    <th>CEP Inicial</th>
                                    <th>CEP Final</th>
                                    <th>Peso Mínimo</th>
                                    <th>Peso Máximo</th>
                                    <th>Maior Dimensão</th>
                                    <th>Preço de Envio</th>
                                    <th>Prazo de Entrega</th>
                                </tr>
                            </thead>
                            <tbody>
                                {shippings &&
                                    shippings.map(
                                        ({ isSelected, shipping }, index) => {
                                            return (
                                                <tr key={index}>
                                                    <th>
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                className="checkbox"
                                                                checked={
                                                                    isSelected
                                                                }
                                                                onChange={() =>
                                                                    handleCheckboxChange(
                                                                        index
                                                                    )
                                                                }
                                                            />
                                                        </label>
                                                    </th>
                                                    <td>
                                                        <img
                                                            src={
                                                                shipping.carrierLogo ||
                                                                defaultImg
                                                            }
                                                            style={{
                                                                width: '70px',
                                                                height: '70px',
                                                            }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {shipping.description ||
                                                                'Descrição não fornecida'}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {shipping.carrier ||
                                                                'Transportadora não fornecida'}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {cepDefault(
                                                                shipping.startPostalCode.toString()
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {cepDefault(
                                                                shipping.endPostalCode.toString()
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {`${(
                                                                shipping.minimumWeight /
                                                                1000
                                                            ).toLocaleString(
                                                                'pt-br'
                                                            )} Kg`}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {`${(
                                                                shipping.maximumWeight /
                                                                1000
                                                            ).toLocaleString(
                                                                'pt-br'
                                                            )} Kg`}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {`${shipping.longestLength.toLocaleString(
                                                                'pt-br'
                                                            )} cm`}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {(
                                                                shipping.price /
                                                                100
                                                            ).toLocaleString(
                                                                'pt-br',
                                                                {
                                                                    style: 'currency',
                                                                    currency:
                                                                        'BRL',
                                                                }
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {`${shipping.deliveryTime} Dia(s)`}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    )}
                            </tbody>
                        </table>
                    </div>
                </Card>
            )}
        </MyProductContainer>
    )
}

export default ShippingMethods
