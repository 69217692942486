import { Maybe, ProfileAddress } from '../generated/graphql'

export function getFullAddress(address: Maybe<ProfileAddress> | undefined) {
    if (!address) return
    const { street, number, neighborhood, city, state, CEP, complement } =
        address
    return `${street}, ${number}${`, ${complement}` || ''}${
        neighborhood !== null ? ' - ' + neighborhood + ', ' : ' - '
    }${city} - ${state}, ${
        CEP ? CEP.replace(/\D/g, '').replace(/(\d{5})(\d)/, '$1-$2') : ''
    }`
}
