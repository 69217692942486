import styled from 'styled-components';

export const Container = styled.div`
	height: 100vh;
	background-color: #fff;
	display: flex;
	flex-direction: row;
	justify-content: center;
	
`;
export const MainContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	column-gap: 30px;
	padding-top: 40px;
	width: 1146px;
	background-color: #fff;
	.class-column {
		display: flex;
		flex-direction: column;
		align-items: center;

		@media (max-width: 768px) {
			max-width: 357px;
			min-width: 280px;
		}

		.card-header {
			background-color: #05a5bf;
			box-shadow: 3px 2px 9px #00000014;
			border-radius: 8px;
			margin-bottom: 20px;
			padding: 23px;
			width: 560px;
			height: 53px;
			display: flex;
			flex-direction: row;
			align-items: center;
			border: 1px solid black;
			h2 {
				color: #ffffff;
				font-family: 'Poppins', semi-bold;
				font-weight: 600;
				font-size: 16px;

				@media (max-width: 768px) {
					color: white;
				}
			}
			@media (max-width: 768px) {
				width: 100%;
				box-shadow: 3px 2px 9px #00000014;
				background: #05a5bf;
			}
		}

		.next-button-container {
			width: 25%;
			cursor: pointer;
			@media (max-width: 768px) {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			button{
				background-color: #69b64a;
				color: white;
				font-family: 'Poppins';
				font-weight: bold;
				font-size: 18px;
				border: 1px solid black;
				border-radius: 7px;
				width: 226px;
				cursor: pointer;
				height: 44px;

			}
			button:disabled {
				background-color: grey;
				color: white;
				font-family: 'Poppins';
				font-weight: bold;
				font-size: 18px;
				border: 1px solid black;
				cursor:not-allowed;
				border-radius: 7px;
				width: 226px;
				height: 44px;
			}
		}
	}
	.infos-card {
		display: flex;
		flex-direction: column;
		padding: 38px;
		box-shadow: 3px 2px 9px #00000014;
		border-radius: 8px;
		align-items: flex-start;
		background-color: #fff;
		width: 382px;
		height: max-content;
		overflow: auto;
		justify-content: flex-start;
		border: 1px solid black;

		@media (max-width: 768px) {
			display: none;
		}

		.shipping-details,
		.price-info {
			width: 100%;
			padding-top: 26px;
		}

		.card-title {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			margin-bottom: 8px;
			h2 {
				color: #6b98a6;
				font-size: 16px;
				font-family: 'Poppins';
				font-weight: 600;
			}
		}

		.card-summary,
		.shipping-details {
			padding-bottom: 26px;
		}

		.card-summary {
			p,
			a {
				width: 100%;
				color: black;
				font-family: 'Poppins', regular;
				font-size: 14px;
			}

			a {
				text-decoration: underline;
				color: #625ad2;
			}
		}

		.shipping-details {
			border-top: solid 1px black;
			border-bottom: solid 1px black;

			h3 {
				font-family: 'Poppins';
				color: #6b98a6;
				font-weight: 600;
				font-size: 15px;
			}

			p {
				color: black;
				font-family: 'Poppins';
				font-weight: 400;
				font-size: 15px;
			}
		}

		.price-info {
			h2 {
				color: #6b98a6;
				font-family: 'Poppins';
				font-weight: 600;
				font-size: 17px;
			}
		}
	}
	.vermais{
		cursor: pointer;
	}
`;
