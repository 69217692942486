import React from 'react'

export interface FaqQuestion {
    title: string
    question: JSX.Element
}
export const faqQuestions: FaqQuestion[] = [
    {
        title: 'De onde surgiu o nome Sambay express?',
        question: (
            <p>
                Como temos orgulho de sermos brasileiros, unimos algumas
                palavras, como o samba, a letra “y”, muito utilizada em nomes de
                origem indígena, os sambaquis, resquícios arqueológicos de
                nossos habitantes originais e outros critérios diversos para
                definirmos esse nome. Arara, aliás, vem do tupi e significa ave
                de muitas cores! A palavra “bay”, em inglês, quer dizer baía. E
                foi na Bahia que o Brasil foi descoberto. Por fim, o adjetivo
                "express" dá a noção de agilidade que procuramos imprimir em
                nosso negócio. Ou seja: um nome moderno e internacional, mas com
                alma brasileira.
            </p>
        ),
    },
    {
        title: 'Por que o símbolo do Sambay é uma arara?',
        question: (
            <p>
                Tivemos a intenção de homenagear a rica e bela fauna brasileira.
                Assim, escolhemos a arara-canindé porque ela é uma ave majestosa
                e com as cores do Brasil, além de ser um animal inteligente e
                dócil.
            </p>
        ),
    },
    {
        title: 'Ao realizar o meu cadastro, eu já posso anunciar?',
        question: (
            <p>
                Sim, após você realizar o cadastro e confirmar o link enviado
                para seu e-mail, você já está livre para anunciar, mas se fizer
                isso imediatamente sem se conectar em nenhum serviço de frete
                que disponibilizamos, os seus anúncios só irão possuir a
                modalidade de frete "A combinar com o vendedor". Nós da Sambay
                express, recomendamos que você ao se cadastrar, acesse seu
                perfil e vá em "Conexões", e ali faça a conexão com os serviços
                que nós disponibilizamos, seja de envio de produtos (Melhor
                Envio), ou o de emissão de notas fiscais e integradora de
                anúncios (Bling).
            </p>
        ),
    },
    {
        title: 'Como eu faço para anunciar?',
        question: (
            <p>
                Para você anunciar na Sambay Express, é simples. Basta você
                clicar em "Anunciar", e preencher a tela (única) de anúncio, por
                completo, inserindo todas as informações solicitadas. Então,
                clique no botão de “anunciar” no rodapé do formulário.
            </p>
        ),
    },
    {
        title: 'Poderei retirar, pessoalmente, o produto?',
        question: (
            <p>
                Se o vendedor cadastrou essa opção, sim, esse é um direito seu.
            </p>
        ),
    },
    {
        title: 'O Sambay express faz vendas próprias?',
        question: (
            <p>
                Apenas de produtos licenciados ou exclusivos nossos, para não
                sermos concorrentes e prejudicarmos nossos próprios parceiros,
                como ocorre em outros marketplaces.
            </p>
        ),
    },
    {
        title: 'Posso reclamar ao Procon, acerca de uma aquisição?',
        question: (
            <p>
                Sim, com certeza. Além de nosso canal direito para resolução de
                conflitos e do Portal Reclame Aqui (com botão direto em nossa
                página principal), podem ser procurados os Procons municipais ou
                estaduais, conforme faculta a lei.
            </p>
        ),
    },
    {
        title: 'O Sambay express é brasileiro?',
        question: (
            <p>Orgulhosamente, sim! Pensado, criado e gerido no Brasil.</p>
        ),
    },
    {
        title: 'Posso devolver uma compra?',
        question: (
            <p>
                Sim. Basta entrar em contato com o vendedor. Caso haja algum
                conflito, pode ser acessado o nosso canal direito específico
                para isso. O produto deverá ser devolvido ao vendedor, que fará
                o estorno do valor pago. Tudo isso, seguindo as regras do
                portal. Caso o vendedor não cumpra com sua obrigação, o Sambay
                express intermediará o caso. Mas, lembramos que se evitem as
                compras por impulso, pois isso gera problemas a todos, incluindo
                perda de tempo e custos.
            </p>
        ),
    },
    {
        title: 'Como funcionará o sistema de avaliação dos usuários?',
        question: (
            <p>
                Através de um sistema de avaliação composto por estrelas (de uma
                a cinco), cada usuário terá uma reputação. A cada compra ou
                venda efetuada, o usuário será avaliado por sua contraparte. E
                receberá, a cada transação, uma “nota” de 1 a 5 estrelas,
                dependendo de sua atuação na negociação. O usuário, no momento
                da avaliação, deve levar em conta a facilidade de contatar a
                outra parte, a disponibilidade em sanar dúvidas, rapidez na
                postagem do produto e demais critérios que julgar importantes.
                Essa reputação aparecerá ao lado do nome do usuário e valerá
                tanto para vendedores, como para compradores
            </p>
        ),
    },
    {
        title: 'Haverá sistema de pontos ou programa de benefícios?',
        question: (
            <p>
                Sim. Cada transação efetuada (salvo aquelas avaliadas com duas
                ou menos estrelas) dará direito a um ponto. E cada avaliação
                feita à contraparte valerá, também, um ponto. Por isso, é
                importante fazer as avaliações! Avaliar a outra parte na
                negociação também ajuda a criar uma comunidade virtual mais
                justa. Esses pontos poderão se trocados por benefícios e
                vantagens, conforme regulamento próprio.
            </p>
        ),
    },
    {
        title: 'Há linhas de produtos exclusivos?',
        question: (
            <p>
                Sim! Eles aparecerão, nos anúncios, com um símbolo de uma
                pequena letra “E”, ao fim do título e, também, na descrição do
                produto. Esse é mais um diferencial do Sambay express. Dentro do
                possível, haverá desde artesanatos e obras de arte até aparelhos
                eletrônicos com venda exclusiva em nossa plataforma.
            </p>
        ),
    },
    {
        title: 'Posso fazer várias compras, do mesmo vendedor, economizando no valor do frete?',
        question: (
            <p>
                Sim. Basta ir adicionando os produtos escolhidos ao seu carrinho
                de compras. Você poderá comprar todos os produtos escolhidos de
                um determinado vendedor, alterar os produtos e quantidades no
                carrinho.
            </p>
        ),
    },
    {
        title: 'Sou obrigado a avaliar a outra parte?',
        question: (
            <p>
                Não, não é. Mas, avaliar a outra parte na negociação ajuda a
                criar uma comunidade virtual mais justa, com as partes sendo
                avaliadas em todos as transações efetuadas. Todos ganham com
                isso! Quem faz a avaliação também acumula pontos para nosso
                programa de benefícios, com isso. E, caso a avaliação não seja
                efetuada dentro de 45 dias da data da transação, a mesma será
                lançada de forma positiva (5 estrelas), seja para o comprador,
                seja para o vendedor. Mas, nesse, caso, a avaliação não gerará
                pontos.
            </p>
        ),
    },
    {
        title: 'Além da venda a preço fixo, haverá também a venda no formato de leilão?',
        question: (
            <p>
                Sim. Oportunamente, resgataremos essa clássica forma de venda e
                que motiva os compradores, sendo bastante democrática e justa. O
                vendedor, ao cadastrar seu produto, poderá escolher em qual
                formato quer fazer seu anúncio. Esses produtos aparecerão, na
                listagem, com uma pequena letra “L” ao lado da descrição, para
                facilitar a identificação desse tipo de anúncio.
            </p>
        ),
    },
    {
        title: 'É mais barato anunciar e vender no Sambay express?',
        question: (
            <p>
                Sempre existirá a opção de anúncio grátis. E o Sambay express
                cobra tarifas de venda em percentual justo e razoável, sendo,
                assim, uma excelente opção dentre todas as opções disponíveis,
                além de contar com vantagens exclusivas, promoções, sistema mais
                justo de avaliação etc. Tudo isso reduz os custos para o
                vendedor e ajuda a reduzir o custo dos produtos, também
                beneficiando os compradores. Assim, com certeza é mais barato
                vender e comprar no Sambay express!
            </p>
        ),
    },
    {
        title: 'Existe valor mínimo para a venda de um produto?',
        question: (
            <p>
                O valor mínimo para a venda de um produto é de apenas R$ 2,00
                (dois reais), o que é outro diferencial do Sambay express.
            </p>
        ),
    },
    {
        title: 'Quais são os produtos e serviços que não podem ser anunciados no Sambay?',
        question: <p>Na seção “produtos e serviços proibidos”.</p>,
    },
    {
        title: 'O Sambay express terá sua própria loja oficial?',
        question: (
            <p>
                Vejamos. Para a venda de produtos, como os vendedores adastrados
                em nosso site, não. Não cremos que isso seja justo com nossos
                parceiros-vendedores. Exceto para anúncios especiais (limitados
                ou de teste, por exemplo) ou de produtos com renda revertida
                para causas sociais. Nesses casos, o próprio Sambay express
                poderá fazer anúncios
            </p>
        ),
    },
    {
        title: 'O Sambay express apoia projetos e ações sociais?',
        question: (
            <p>
                Com certeza. Esse é um dos nossos principais diferenciais. Temos
                uma campanha permanente de anúncios de produtos com renda 100%
                revertida a entidades beneficentes e ações sociais. Fora outras
                políticas em fase de desenvolvimento, nesse mesmo sentido. E,
                ainda, apoiaremos, diretamente, alguns projetos a serem
                definidos por nossa equipe.
            </p>
        ),
    },
    {
        title: 'Como funciona o botão da Pechincha?',
        question: (
            <p>
                - Para o Vendedor: no momento do cadastro do produto, com valor
                fixo, aparecerá essa opção. Ali, o vendedor poderá cadastrar um
                limite autorizado de desconto, para encerramento automático do
                anúncio e concretização da venda. E limites adicionais, que
                permitirão que ele avalie a sugestão enviada. Exemplo: produto
                anunciado por R$ 100,00. Ele pode cadastrar a opção de já
                encerrar o anúncio por oferta igual ou superior R$ 90,00. É
                recusado de imediato, ofertas menores do que R$ 90,00. Aceita a
                oferta, é oficializada a venda, com todas as respectivas
                obrigações ambas as partes. Para o vendedor, o produto
                cadastrado com esta opção selecionada, permitirá que o produto
                esteja em uma seção diferente das dos demais, tendo destaque
                especial no site. - para o comprador: se ele não quer pagar o
                valor do anúncio, mas acha que pode fazer uma boa oferta, ele
                clica nesse botão e faz sua oferta, que pode ser aceita
                automaticamente ou em até 24h, dependendo do valor e do que o
                vendedor cadastrou. Aceita a oferta, é oficializada a venda, com
                todas as respectivas obrigações ambas as partes. Os anúncios que
                permitirem essa opção exibirão, ao lado do título do item, uma
                pequena letra “P”, para facilitar a sua identificação.
            </p>
        ),
    },
    {
        title: 'Poderei pagar através de boleto bancário?',
        question: (
            <p>
                Sim, há essa possibilidade de pagamento, além dos cartões e Pix.
            </p>
        ),
    },
    {
        title: 'Quem escolhe a forma de envio?',
        question: (
            <p>
                Em todos os anúncios, haverá, ao menos, duas opções de envio
                (além da opção de retirada pessoalmente, quando disponível),
                sendo que a escolha é sempre um direito do comprador.
            </p>
        ),
    },
    {
        title: 'Como funciona o sistema do Melhor Envio?',
        question: (
            <p>
                Primeiramente, registre-se, além do Sambay, também na plataforma
                do Melhor Envio. Você receberá um aviso acerca disso, logo que
                se registrar aqui. Depois, entre em sua conta aqui no Sambay,
                acesse seu “Perfil” e escolha a aba “Conexões”. Ali, conecte-se
                com o Melhor Envio. Assim, você se beneficiará pela gestão de
                etiquetas, valores mais baixos que os cobrados em “balcão” e
                outras vantagens. E não esqueça de deixar um valor como crédito
                para poder gerar suas etiquetas. Quem paga o frete é o
                comprador, mas esse pagamento será direcionado diretamente a
                você, juntamente com o valor devido pela venda. Assim, é preciso
                sempre deixar um valor provisionado em sua conta junto ao Melhor
                Envio, OK? Pode ser algo em torno de 20 a 30 reais, caso você
                não faça muitas vendas por dia. Dessa forma, você não precisa
                colocar créditos a cada vez que for gerar uma etiqueta lá na
                plataforma do melhor Envio. E, em breve, as etiquetas poderão
                ser geradas dentro do próprio Sambay, em sua área de vendas!
            </p>
        ),
    },
    {
        title: 'Qual prazo eu tenho para enviar o produto que vendi?',
        question: (
            <p>
                Recomendamos um prazo máximo de 48h a contar do momento da
                efetivação da venda. Prazos maiores desestimulam a compra e
                podem gerar reclamações, salvo casos pontuais ou aqueles em que
                o produto ainda não está liberado para envio (e estando essa
                condição expressa no anúncio!).
            </p>
        ),
    },
    {
        title: 'Quais dados ou informações não posso incluir no meu anúncio?',
        question: (
            <p>
                Não é permitida a exposição de dados pessoais, como telefone,
                e-mail, WhatsApp, redes sociais etc. Precisamos manter a
                plataforma justa para todos. Expondo seus dados, você ainda
                corre riscos de golpes, fraudes e outros problemas. E, quem
                compra fora da plataforma, fica sem as garantias e serviços do
                Sambay express.
            </p>
        ),
    },
    {
        title: 'Posso criar meus próprios cupons de desconto?',
        question: (
            <p>
                Sim, em sua loja virtual, clique em “Cupons” e, depois, e “Novo
                Cupom”. Aí, você escolhe em quais produtos se aplica o cupom,
                sua validade (duração), percentual de desconto etc. Depois de
                criado, basta divulgar para seus clientes!
            </p>
        ),
    },
    {
        title: 'Consigo saber quantas visualizações teve meu anúncio?',
        question: (
            <p>
                Sim. Dentro de sua loja virtual, entre em “Meus Produtos”. Sobre
                cada anúncio, aparece um mini monitor com um número dentro. É,
                justamente, o número de visualizações que teve seu anúncio!
            </p>
        ),
    },
    {
        title: 'Consigo editar ou excluir meus anúncios?',
        question: (
            <p>
                Sim. Dentro de sua loja virtual, entre em “Meus Produtos”. Sobre
                cada anúncio, aparecem 3 ícones, com fundo azul na parte
                superior. Ali, você pode editar, inativar ou excluir, em
                definitivo, um produto. E, caso ele esteja na seção “Produtos
                Inativos”, você poderá editá-lo lá mesmo.
            </p>
        ),
    },
    {
        title: 'Posso ativar, novamente, um anúncio?',
        question: (
            <p>
                Sim. Caso, por exemplo, tenha inativado um anúncio por engano ou
                ele voltou a ter estoque, vá em sua loja virtual e clique em
                “produtos Inativos”. Sobre cada anúncio, aparecem 3 ícones, com
                fundo azul. O do meio é o de “ativar”. Clique nele e aguarde
                alguns segundos. Ele desaparecerá da seção “Produtos Inativos” e
                aparecerá em “Meus Produtos”.
            </p>
        ),
    },
    {
        title: 'Qual o percentual de comissão cobrado?',
        question: (
            <p>Clique aqui e veja nossa tabela de comissões. (em breve).</p>
        ),
    },
    {
        title: 'Quantas fotos preciso colocar em meus anúncios?',
        question: (
            <p>
                O mínimo recomendado é de 3 (três) fotos. Mas, podendo,
                aproveite que nosso marketplace permite até 12 fotos e capriche,
                mostrando todos os detalhes e diferenciais de seu produto! Outra
                dica; procure utilizar fundo branco, em suas fotos e evite
                colocar informações “embutidas” na foto, sobre o produto, pois
                acaba encobrindo-o e ficando menos atraente.
            </p>
        ),
    },
    {
        title: 'Posso criar um único anúncio de um produto que possui variações de cor e tamanho, por exemplo?',
        question: (
            <p>
                Sim, isso é possível. Existe essa opção quando você clicar em
                “Anuncie”.
            </p>
        ),
    },
    {
        title: 'Meu produto ainda não está disponível para venda. Posso anunciá-lo, mesmo assim?',
        question: (
            <p>
                Sim. Caso seu produto necessite ser personalizado, importado,
                finalizado etc., informe isso no campo próprio que existe na
                parte final da tela de anúncio. Esse prazo não se confunde com
                prazo de entrega, OK? Informe, ali, apenas quantos dias, após a
                conformação da venda, você precisa para ter o produto em mãos,
                pronto para ser despachado.
            </p>
        ),
    },
    {
        title: 'Posso oferecer frete grátis aos meus clientes?',
        question: (
            <p>
                Sim. Na parte final da tela de anúncio você pode selecionar essa
                opção. No entanto, para que ela seja funcional, selecione “sim”
                na opção de “Retirada do produto com o vendedor”, no meio da
                tela de anúncio.
            </p>
        ),
    },
    {
        title: 'Não encontrei a categoria exata do meu produto...',
        question: (
            <p>
                Caso não encontre a categoria ou subcategoria exata ou
                aproximada de seu produto, escolha “Outros”. Lembramos que as
                opções, tanto de categoria, quanto de subcategoria estão
                listadas em ordem alfabética.
            </p>
        ),
    },
    {
        title: 'Por que preciso preencher, duas vezes, o tamanho e o peso do produto?',
        question: (
            <p>
                Excelente questão. Na verdade, uma vez é apenas a dimensão e
                peso do produto, efetivo, para que seu cliente saiba exatamente
                o que está comprando. Isso deixa o processo de compra mais
                simples e evita devoluções por arrependimento (exemplo: cliente
                achou que a embalagem continha 500 gramas, e não apenas 250
                gramas). E, depois, você precisa preencher os dados do produto
                já embalado, pronto para despacho, pois são essas informações
                que usaremos para cobrar um frete justo, ao contrário de outros
                marketplaces, que não perguntam isso e acabam cobrando fretes
                absurdos. Caso usássemos as informações, por exemplo, de uma
                escultura (apenas do produto), para calcular o frete, haveria
                divergências e problemas no envio, pois, ela, embalada, poderia
                ocupar uma caixa bem maior que a peça, em si, para evitar danos.
                OK?
            </p>
        ),
    },
    {
        title: 'Sou obrigado a preencher todos os campos do anúncio?',
        question: (
            <p>
                Não. Apenas aqueles com asterisco (*) são obrigatórios, mas,
                para que haja uma boa experiência de compra ao sue cliente,
                evitando reclamações e devoluções, que geram gasto de tempo e de
                dinheiro, recomendamos o preenchimento de todos os campos (que
                não são tantos assim, comparando-se com outros marketplaces). E
                procure caprichar na descrição, destacando todos os diferenciais
                de seu produto e de sua marca!
            </p>
        ),
    },
    {
        title: 'Quais as punições que podem ser aplicadas a maus vendedores?',
        question: (
            <p>
                Caso um vendedor descumpra as políticas do marketplace, ele pode
                ter anúncios suspensos, ter sua conta suspensa ou, ainda, ser
                excluído de nossa comunidade, dependendo da gravidade da falta,
                da reincidência, do risco oferecido a clientes e parceiros etc.
            </p>
        ),
    },
]
