import React, { useCallback, useEffect, useRef, useState } from 'react'
import NewCategorieModal, {
    NewCategorieModalHandles,
} from './NewCategorieModal/NewCategorieModal'
import CategorieGroup from './CategorieGroup/CategorieGroup'
import {
    useGetAllProductCategoriesQuery,
    useGetProductCategorieBySearchLazyQuery,
} from '../../generated/graphql'
import { Container } from './Style'
import { MdSearch as Search, MdAdd as AddFill } from 'react-icons/md'

const ProductCategorieCard: React.FC = () => {
    const ModalRef = useRef<NewCategorieModalHandles>(null)
    const openModal = useCallback(() => {
        ModalRef.current?.handleOpenModal()
    }, [ModalRef])

    const { data, loading } = useGetAllProductCategoriesQuery({
        fetchPolicy: 'cache-and-network',
    })
    const [getProductCategoriesBySearch, { data: dataQuery }] =
        useGetProductCategorieBySearchLazyQuery({
            fetchPolicy: 'cache-and-network',
        })
    const [categories, setCategories] = useState<any>([])

    useEffect(() => {
        data && setCategories(data.getAllProductCategories)
    }, [data])

    useEffect(() => {
        dataQuery && setCategories(dataQuery.getProductCategorieBySearch)
    }, [dataQuery])

    return (
        <>
            <Container>
                <NewCategorieModal
                    ref={ModalRef}
                    areaTitle="categoria"
                    registerType="categorie"
                />

                <div className="card">
                    <div className="title-and-search-bar-container">
                        <h2>Categorias e Subcategorias Cadastradas</h2>
                        <div className="search-bar">
                            <Search className="IconBuscar" />
                            <input
                                onChange={({ target }) => {
                                    target.value.length > 0 &&
                                        getProductCategoriesBySearch({
                                            variables: { name: target.value },
                                        })
                                }}
                                type="text"
                                placeholder="busque por um nome de categoria"
                            />
                        </div>
                        <div className="add-category">
                            <button onClick={openModal}>
                                <AddFill className="AddCategoryButtonIcon" />
                            </button>
                        </div>
                    </div>
                    <div className="second-group">
                        <div className="category-group">
                            {!!loading && !categories && (
                                <p>Nada encontrado.</p>
                            )}
                            {!loading &&
                                categories &&
                                categories.map(
                                    (category: any, index: number) => (
                                        <CategorieGroup
                                            category={category}
                                            key={index}
                                        />
                                    )
                                )}
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default ProductCategorieCard
