import React from 'react';

import { Container } from './styles';

type Props = {
	image: any;
	iconFill: string;
	onClick?: any;
};

const MenuItem: React.FC<Props> = ({ ...props }) => {
	return (
		<Container
			iconFill={props.iconFill}
			backgroundColor={props.iconFill === '#FF7E0F' ? '#fff' : ''}
			onClick={props.onClick}
		>
			<props.image className="icon"></props.image>
			<h2> {props.children} </h2>
		</Container>
	);
};

export default MenuItem;
