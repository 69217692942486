import styled from 'styled-components';

export const Container = styled.div`
display:flex;
flex-direction:column;
padding: 30px;

h2{
    color: #0B0948;
    font-size: 12px;
    margin-bottom: 44px;
    font-family:'Poppins';
    font-weight:bold;
}
.TituloCadastrarConta{
    color: #6b97a5;
    font-family: 'Poppins';
    font-weight: bold;
    font-size: 18px;
    margin-bottom:35px;
}
`;

export const ModalTop = styled.header`
display:flex;
flex-direction:column;  
`;

export const ModalMiddle= styled.main`
display:flex;
flex-direction:column;

    .choose-user-type-buttons-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .choose-user-type-button{
            border:solid 1px #FF7E0F;
            background:white;
            width: 151px;
            height: 30px;
            color:#FF7E0F;
            border-radius: 12px;
            margin-bottom:25px;
            font-family:'Poppins', regular;
            font-size:12px;
            display:flex;
            flex-direction:row;
            align-items:center;
            justify-content:center;
        }
        .choose-user-type-buttons-container:focus{
            outline:none;
        }
        .choose-user-type-buttons-container:hover{
            cursor:pointer;
        }
        .active{
            background:#FF7E0F;
            color:white;
        }
    }

    .modal-inputs{
            display: flex;
            flex-direction: column;
            .input-container{
                display: flex;
                flex-direction: column;
                margin-bottom: 28px;

                label{
                    margin-bottom:5px;
                    margin-bottom: 5px;
                    color: black;
                    font-size: 14px;
                    font-family:'Poppins',regular;
                }
                input{
                    border: solid 1px black;
                    height: 30px;
                    border-radius: 7px;
                    padding: 8px;
                }
                input:focus{
                    outline:none;
                }

            }
        }
`;
interface ButtonActiveButton {
    active:boolean
}

export const ChooseUserTypeButton = styled.div<ButtonActiveButton>`
       
            border:solid 1px #FF7E0F;
            background:${(Prop) => Prop.active ? '#FF7E0F' : 'white'};
            width: 151px;
            height: 30px;
            color:${(Prop) => Prop.active ? 'white' : '#FF7E0F'};
            border-radius: 12px;
            margin-bottom:25px;
            font-family:'Poppins', regular;
            font-size:12px;
            display:flex;
            flex-direction:row;
            align-items:center;
            justify-content:center;
       
        &:focus{
            outline:none;
        }
        &:hover{
            cursor:pointer;
        }
`;

export const ModalBottom= styled.footer`
display:flex;
flex-direction:row;
align-items:center;
justify-content:flex-end;

  .negative-button{
    margin-right: 18px;
    border: 1px solid var(--unnamed-color-ca667e);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CA667E;
    border-radius: 12px;
    width: 96px;
    height: 30px;
    color:#CA667E;
    font-family:'Poppins',regular;
    font-size: 12px;
    cursor: pointer;

  }
  .positive-button{
    width: 96px;
    height: 30px;
    background: var(--unnamed-color-69b64a) 0% 0% no-repeat padding-box;
    background: #69B64A 0% 0% no-repeat padding-box;
    border-radius: 12px;
    color:white;
    font-family:'Poppins',regular;
    font-size: 12px;
    border:none;
  }
  .positive-button:focus,negative-button:focus{
      outline:none;

  }
  .positive-button:hover,negative-button:hover{
      cursor:pointer;

  }
`;

export const SucessModalContainer = styled.div`
display:flex;
flex-direction:column;
padding: 30px;

h2{
    color: #0B0948;
    font-size: 12px;
    margin-bottom: 44px;
    font-family:'Poppins';
    font-weight:bold;
}
`;

export const SucessModalTop = styled.header`
display:flex;
flex-direction:row;
justify-content:center;
align-items:center;

h2{
    color: #FF7E0F;
    font-size: 15px;
    margin-bottom: 36px;
    font-family:'Poppins';
    font-weight:600;
}
`;

export const SucessModalMiddle = styled.main`
display:flex;
flex-direction:column;
justify-content:center;
align-items: center;
margin-bottom: 19px;

    .email-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        span{
            color:#6B97A5;
            font-size:12px;
            font-family:'Poppins',regular;
        }
        h3{
            font-family:'Poppins';
            font-weight:600;
            color:#0B0948;
            font-size:15px;
            margin: 0;
        }
    }
`;

export const SucessModalBottom = styled.footer`
display:flex;
flex-direction:row;
justify-content:center;
align-items:center;
    
    button{
        background:#69B64A;
        border-radius: 12px;
        width: 112px;
        height: 30px;
        color:#FFFFFF;
        font-family:'Poppins', regular;
        font-size:12px;
        border:none;
    }
`;