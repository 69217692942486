import styled from 'styled-components';

export const Container = styled.div`
display:flex;
flex-direction:column;
padding: 30px;

h2{
    color: #0B0948;
    font-size: 12px;
    margin-bottom: 22px;
    font-family:'Poppins';
    font-weight:bold;
}
.TitleCriarCategoria{
    color: #05ABC0;
    font-family: 'Poppins';
    font-weight: bold;
    font-size: 15px;
}
  
`;

export const ModalTop = styled.header`
display:flex;
flex-direction:column;
  
`;

export const ModalMiddle= styled.main`
display:flex;
flex-direction:column;

    .choose-user-type-buttons-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .choose-user-type-button{
            border:solid 1px #FF7E0F;
            background:white;
            width: 151px;
            height: 30px;
            color:#FF7E0F;
            border-radius: 12px;
            margin-bottom:25px;
            font-family:'Poppins', regular;
            font-size:12px;
            display:flex;
            flex-direction:row;
            align-items:center;
            justify-content:center;
        }
        .choose-user-type-buttons-container:focus{
            outline:none;
        }
        .choose-user-type-buttons-container:hover{
            cursor:pointer;
        }
        .active{
            background:#FF7E0F;
            color:white;
        }

        
    }
    .modal-inputs{
            display: flex;
            flex-direction: column;
            .input-container{
                display: flex;
                flex-direction: column;
                margin-bottom: 28px;

                label{
                    margin-bottom:5px;
                    margin-bottom: 5px;
                    color: #6B97A5;
                    font-size: 12px;
                    font-family:'Poppins',regular;
                }
                input{
                    border: solid 1px #CDCDCD;
                    height: 30px;
                    border-radius: 7px;
                    padding: 8px;
                }
                input:focus{
                    outline:none;
                }

            }
        }
`;

export const ModalBottom= styled.footer`
display:flex;
flex-direction:row;
align-items:center;
justify-content:flex-end;

  .negative-button{
    margin-right: 18px;
    border: 1px solid var(--unnamed-color-ca667e);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CA667E;
    border-radius: 12px;
    width: 96px;
    height: 30px;
    color:#CA667E;
    font-family:'Poppins',regular;
    font-size: 12px;
    cursor: pointer;

  }
  .positive-button{
    width: 96px;
    height: 30px;
    background: var(--unnamed-color-69b64a) 0% 0% no-repeat padding-box;
    background: #69B64A 0% 0% no-repeat padding-box;
    border-radius: 12px;
    color:white;
    font-family:'Poppins',regular;
    font-size: 12px;
    border:none;
  }
  .positive-button:focus,negative-button:focus{
      outline:none;

  }
  .positive-button:hover,negative-button:hover{
      cursor:pointer;

  }
`;