import React, { useEffect, useRef, useState } from 'react'
import {
    Product,
    useCreateProductQuestionMutation,
    useGetAllProductQuestionsQuery,
    useGetAllUserPurchasesInOneProductQuery,
    useGetProductQuery,
    UserAccount,
} from '../../generated/graphql'
import { Back, QuestionsCard } from './Style'
import { Route, useHistory, useParams, useRouteMatch } from 'react-router'
import IUserAccount from '../../models/IUserAccount'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../store'
import ProductRating from './ProductRating'
import ProductQuestions from './ProductQuestions/ProductQuestions'
import ProductDescription from './ProductDescription/ProductDescription'
import ProductCard from './ProductCard/ProductCard'
import { insertPurchase } from '../../actions/UserActions'
import BlockProductModal, {
    BlockProductModalHandles,
} from './BlockProductModal/BlockProductModal'
import { ProductMenu } from './ProductUserMenu/Style'
import { NavLink } from 'react-router-dom'
import StoreProductsSlider from './StoreProductsSlider/StoreProductsSlider'
import ProductADMMenu from './ProductADMMenu/ProductADMMenu'
import { FaWhatsapp } from 'react-icons/fa'
import { MdOutlineChevronLeft } from 'react-icons/md'

export interface IProductBids {
    id?: number | null
    createdAt?: Date | null
    userAccountId?: number | null
    bid?: number | null
    updatedAt?: Date | null
    productId?: number | null
    UserAccount?: UserAccount | null
}

interface ProductViewParams {
    productId: string
}
type Props = {
    block: boolean
}

const ProductView: React.FC<Props> = ({ block }) => {
    const user: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )
    const productId: ProductViewParams = useParams()
    const { data, loading} = useGetProductQuery({
        variables: {
            id: Number.parseInt(productId.productId),
            userId: user?.id,
        },
        fetchPolicy:'no-cache'
    })

    useEffect(() => {
        if (window.pageYOffset > 0) {
            window.scroll(0, 0)
        }
    }, [])
    const history = useHistory()
    const [description, setDescription] = useState<any>()
    const { path, url } = useRouteMatch()
    const [commentDescription, setCommentDescription] = useState<string>()
    const [createCommentMutation] = useCreateProductQuestionMutation()
    const dispatch = useDispatch()
    const { data: ProductQuestion, loading: LoadingProductQuestion } =
        useGetAllProductQuestionsQuery({
            variables: { productId: Number.parseInt(productId.productId) },
        })
    const { data: purchaseData } = useGetAllUserPurchasesInOneProductQuery({
        variables: {
            userId: user?.id,
            productId: Number.parseInt(productId.productId),
        },
        fetchPolicy: 'cache-and-network',
    })

    const shareMessage = `Olha o que eu achei na Sambay! ${
        data?.getProduct.title
    }, só ${data?.getProduct.price.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
    })} ${location.href}`.replace(/\s+/g, '+')

    useEffect(() => {
        if (
            data &&
            data.getProduct &&
            purchaseData?.getAllUserPurchasesInOneProduct
        ) {
            dispatch(
                insertPurchase({
                    productId: data!.getProduct!.id,
                    purchase: purchaseData!.getAllUserPurchasesInOneProduct!,
                })
            )
        }
    }, [data, dispatch, purchaseData])

    const createComment = async (): Promise<void> => {
        if (commentDescription) {
            await createCommentMutation({
                variables: {
                    productId: Number.parseInt(productId.productId),
                    userId: user.id,
                    description: commentDescription,
                },
            })
            window.location.reload()
        }
    }

    useEffect(() => {
        if (block) {
            blockProductModalRef.current?.handleOpenModal()
        }
    }, [block])

    useEffect(() => {
        if (user) {
            if (data?.getProduct.userAccountId === user.id) {
                setDescription(
                    <>
                        <ProductADMMenu />
                        <Route path={`${path}/questions`}>
                            <QuestionsCard>
                                <div className="question-container">
                                    <div>
                                        <h1>Perguntas</h1>
                                    </div>
                                    <div className="questions-card">
                                        {!LoadingProductQuestion &&
                                            ProductQuestion &&
                                            ProductQuestion.getAllProductQuestions?.map(
                                                (Question, index: number) => (
                                                    <>
                                                        <ProductQuestions
                                                            Question={Question}
                                                            key={index}
                                                        />
                                                    </>
                                                )
                                            )}
                                    </div>
                                </div>
                            </QuestionsCard>
                        </Route>
                        <Route path={`${path}/all-data`}>
                            <ProductDescription
                                product={data?.getProduct as Product}
                            />
                        </Route>
                        <Route path={`${path}/product-avaliations`}>
                            <ProductRating
                                productId={productId.productId}
                                userSellerId={data.getProduct.userAccountId}
                            />
                        </Route>
                    </>
                )
            }
            if (user.id !== data?.getProduct.UserAccount.id) {
                setDescription(
                    <>
                        <ProductMenu>
                            <div>
                                <NavLink
                                    to={`${url}/all-data`}
                                    className="items"
                                    activeClassName="items-active"
                                >
                                    <h2>Dados gerais</h2>
                                </NavLink>
                                <NavLink
                                    to={`${url}/product-avaliations`}
                                    className="items"
                                    activeClassName="items-active"
                                >
                                    <h2>Avaliações</h2>
                                </NavLink>
                            </div>
                        </ProductMenu>

                        <Route path={`${path}/all-data`}>
                            <ProductDescription
                                product={data?.getProduct as Product}
                            />
                        </Route>
                        <Route path={`${path}/product-questions`}>
                            <QuestionsCard>
                                <div className="question-container">
                                    <div>
                                        <h1>Perguntas</h1>
                                    </div>
                                    <div className="make-question">
                                        {' '}
                                        <h2>Faça sua pergunta</h2>
                                        <textarea
                                            onChange={(e) =>
                                                setCommentDescription(
                                                    e.target.value
                                                )
                                            }
                                        ></textarea>
                                        <button onClick={() => createComment()}>
                                            Publicar pergunta
                                        </button>
                                    </div>
                                    <div className="questions-card">
                                        {!LoadingProductQuestion &&
                                            ProductQuestion &&
                                            ProductQuestion.getAllProductQuestions?.map(
                                                (
                                                    Question: any,
                                                    index: number
                                                ) => (
                                                    <>
                                                        <ProductQuestions
                                                            Question={Question}
                                                            key={index}
                                                        ></ProductQuestions>
                                                    </>
                                                )
                                            )}
                                    </div>
                                </div>
                            </QuestionsCard>
                        </Route>
                        <Route path={`${path}/product-avaliations`}>
                            <ProductRating
                                productId={productId.productId}
                                userSellerId={data?.getProduct.userAccountId}
                            ></ProductRating>
                        </Route>
                    </>
                )
            }
        }
        if (!user) {
            setDescription(
                <>
                    <ProductMenu>
                        <div>
                            <NavLink
                                to={`${url}/all-data`}
                                className="items"
                                activeClassName="items-active"
                            >
                                <h2>Dados gerais</h2>
                            </NavLink>
                            <NavLink
                                to={`${url}/product-avaliations`}
                                className="items"
                                activeClassName="items-active"
                            >
                                <h2>Avaliações</h2>
                            </NavLink>
                        </div>
                    </ProductMenu>
                    <Route path={`${path}/all-data`}>
                        <ProductDescription
                            product={data?.getProduct as Product}
                        ></ProductDescription>
                    </Route>
                    <Route path={`${path}/product-questions`}>
                        <QuestionsCard>
                            <div className="question-container">
                                <div>
                                    <h1>Perguntas</h1>
                                </div>
                                <div className="make-question">
                                    {' '}
                                    <h2>Faça sua pergunta</h2>
                                    <textarea
                                        onChange={(e) =>
                                            setCommentDescription(
                                                e.target.value
                                            )
                                        }
                                    ></textarea>
                                    <button onClick={() => createComment()}>
                                        Publicar pergunta
                                    </button>
                                </div>
                                <div className="questions-card">
                                    {!LoadingProductQuestion &&
                                        ProductQuestion &&
                                        ProductQuestion.getAllProductQuestions?.map(
                                            (Question, index: number) => (
                                                <>
                                                    <ProductQuestions
                                                        Question={Question}
                                                        key={index}
                                                    ></ProductQuestions>
                                                </>
                                            )
                                        )}
                                </div>
                            </div>
                        </QuestionsCard>
                    </Route>
                    <Route path={`${path}/product-avaliations`}>
                        {user && (
                            <ProductRating
                                productId={productId.productId}
                                userSellerId={data?.getProduct.userAccountId}
                            ></ProductRating>
                        )}
                    </Route>
                </>
            )
        }
    }, [
        user,
        data,
        commentDescription,
        ProductQuestion,
        path,
        LoadingProductQuestion,
        productId.productId,
        url,
    ])
    const blockProductModalRef = useRef<BlockProductModalHandles>(null)
    useEffect(() => {
        if (block) {
            blockProductModalRef.current?.handleOpenModal()
        }
    }, [block])
    return !loading ? (
        <>
            <BlockProductModal ref={blockProductModalRef} />
            <Back>
                <div className="tw-flex tw-flex-row tw-items-center tw-space-x-2 tw-py-2 tw-font-primary sm:tw-space-x-4 sm:tw-py-4">
                    <button
                        onClick={() => {
                            history.goBack()
                        }}
                    >
                        <span>
                            <MdOutlineChevronLeft className="tw-h-6 tw-w-auto tw-fill-current sm:tw-h-12" />
                        </span>
                    </button>
                    <span>{data?.getProduct.title}</span>
                </div>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://api.whatsapp.com/send?text=${shareMessage}`}
                    data-action="share/whatsapp/share"
                    className={`tw-ml-2 tw-flex tw-cursor-pointer tw-items-center tw-space-x-2
                    tw-rounded-sm tw-bg-lime-600 tw-px-2 tw-py-1 tw-font-primary
                    tw-text-base tw-font-regular tw-text-white sm:tw-m-0
                    sm:tw-rounded-md sm:tw-px-4 sm:tw-py-2 md:tw-rounded-full md:tw-text-lg`}
                >
                    <FaWhatsapp className="tw-cursor-pointer tw-text-2xl" />
                    <label className={`tw-cursor-pointer`}>
                        Compartilhe no Whats!
                    </label>
                </a>
            </Back>
            {data && !loading && <ProductCard Product={data?.getProduct} />}
            {description}
            <StoreProductsSlider
                sellerId={data?.getProduct.UserAccount.id as number}
                productId={data?.getProduct.id as number}
                sellerName={data?.getProduct.UserAccount.name as string}
            />
        </>
    ) : (
        <></>
    )
}
export default ProductView
