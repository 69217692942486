import React, {useRef, useState} from 'react';
import {Modal} from 'semantic-ui-react';
import {useCreateReportMutation, useGetAllGroundsForReportQuery,} from '../../../../generated/graphql';
import clsx from 'clsx';
import {ModalBottom, ModalContainer, ModalMiddle, ModalTop,} from './styles';
import {FormControlLabel, makeStyles, Radio, RadioGroup,} from '@material-ui/core';
import {useForm} from 'react-hook-form';
import IUserAccount from '../../../../models/IUserAccount';
import {useSelector} from 'react-redux';
import {AppState} from '../../../../store';
import AlertModal, {ModalHandles} from '../../../AlertModal/AlertModal';

interface ReportInputs {
	groundForReport: string;
}
type Props = {
	productId: number;
	productUserId: number;
};

const ReportProductModal: React.FC<Props> = ({ productId, productUserId }) => {
	const user: IUserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const [modalState, setModalState] = useState<boolean>(false);
	const { data, loading } = useGetAllGroundsForReportQuery();
	const [selectedReportReason, setSelectedReportReason] = useState<string>();
	const { formState:{errors}} = useForm<ReportInputs>();
	const [createProductReport] = useCreateReportMutation();
	const [warningModalDescription, setWarningModalDescription] = useState<
		string
	>('');
	const warningModalRef = useRef<ModalHandles>(null);
	const useStyles = makeStyles({
		root: {
			'&:hover': {
				backgroundColor: 'transparent',
			},
		},
		icon: {
			borderRadius: '50%',
			width: 15,
			height: 15,
			backgroundColor: '#F1F1F1',

			'input:hover ~ &': {
				backgroundColor: '#ebf1f5',
			},
			'input:disabled ~ &': {
				boxShadow: 'none',
				background: 'rgba(206,217,224,.5)',
			},
		},
		checkedIcon: {
			backgroundColor: '#6B98A6',
			width: 15,
			height: 15,
			border: '1px solid black',
			'input:hover ~ &': {
				backgroundColor: '#6B98A6',
			},
		},
	});

	function StyledRadio(props: any) {
		const classes = useStyles();
		return (
			<Radio
				name="groundForReport"
				className={classes.root}
				disableRipple
				color="default"
				checked={props.reason.title === selectedReportReason}
				value={props.reason.id}
				onClick={() => setSelectedReportReason(props.reason.title)}
				checkedIcon={
					<span className={clsx(classes.icon, classes.checkedIcon)} />
				}
				icon={<span className={classes.icon} />}
				{...props}
				style={{
					marginRight: '8px',
				}}
			/>
		);
	}

	const onSubmit = async () => {
		try {
			await createProductReport({
				variables: {
					productId: productId,
					userAccountId: user.id,
					groundsForReportId: data!.getAllGroundsForReport!.find(
						(reason) => selectedReportReason === reason.title
					)!.id,
				},
			});
			setWarningModalDescription('Anúncio reportado com sucesso');
			setModalState(false);
			warningModalRef.current?.handleOpenModal();
		} catch (err: any) {
			setWarningModalDescription('Falha ao reportar anúncio');
			if (
				err.message.substring(15, err.message.length) ===
				'announcement already reported'
			) {
				setWarningModalDescription(
					'Você já reportou esse anúncio recentemente'
				);
			}
			warningModalRef.current?.handleOpenModal();
		}
	};
	return (
		<>
			{user?.userTypeId === 4 && productUserId !== user?.id && (
				<span
					onClick={() => setModalState(true)}
					className="report-link"
				>
					Denunciar produto
				</span>
			)}
			<AlertModal
				AreaTitle="Reportar anúncio"
				DescriptionText={warningModalDescription}
				reload={false}
				ref={warningModalRef}
			/>
			<Modal
				open={modalState}
				onClose={() => setModalState(false)}
				onOpen={() => setModalState(true)}
				style={{ width: '715px', height: '364px', padding: '30px' }}
			>
				<ModalContainer>
					<ModalTop>
						{' '}
						<h1>Reportar anúncio</h1>{' '}
					</ModalTop>
					<ModalMiddle>
						<p>
							Por favor, informe o motivo do report. A solicitação
							passará por nossa mediação e caso seja comprovada a
							violação, o anúncio será tirado do ar
						</p>
						<RadioGroup
							aria-label="gender"
							name="gender2"
							style={{ marginBottom: '35px' }}
						>
							<div className="radio-buttons">
								{data?.getAllGroundsForReport.map(
									(reason, index: number) => (
										<div
											className="card-options"
											key={reason.id}
										>
											<FormControlLabel
												control={
													<StyledRadio
														reason={reason}
													/>
												}
												label={
													<>
														<label id="radio-label">
															{' '}
															{reason.title}{' '}
														</label>
													</>
												}
											/>
										</div>
									)
								)}
							</div>
						</RadioGroup>
					</ModalMiddle>
					<ModalBottom>
						{errors.groundForReport}
						<button
							className="negative-button"
							onClick={() => setModalState(false)}
						>
							Cancelar
						</button>
						<button
							className="positive-button"
							onClick={() => onSubmit()}
						>
							Enviar
						</button>
					</ModalBottom>
				</ModalContainer>
			</Modal>
		</>
	);
};

export default ReportProductModal;
