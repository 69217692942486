import React, { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Redirect, Switch, Route } from 'react-router'
import RegisterPage from './pages/User/UserRegisterPage'
import RegisterProductPage from './pages/RegisterProduct/RegisterProductPage'
import LoginPage from './pages/Login/LoginPage'
import HomePage from './pages/Home/HomePage'
import ProductViewPage from './pages/ProductViewPage/ProductViewPage'
import UserProfilePage from './pages/UserProfile/UserProfilePage'
import SearchPage from './pages/Search/SearchPage'
import IUserAccount from './models/IUserAccount'
import { useSelector } from 'react-redux'
import { AppState } from './store'
import ShoppingPage from './pages/Shopping/ShoppingPage'
import BuyPaymentProcessPage from './pages/BuyPaymentProcess/BuyPaymentProcessPage'
import MyBuysPage from './pages/MyBuys/MyBuysPage'
import MyBuysItemPage from './pages/MyBuysItemPage/MyBuysItemPage'
import ForgotPasswordPage from './pages/ForgotPassword/ForgotPasswordPage'
import NotificationsWrapper from './components/NotificationsWrapper/NotificationsWrapper'
import ProductCategories from './pages/ProductCategories/ProductCategories'
import AdmRegisterCard from './components/AdmRegisterCard/AdmRegisterCard'
import AdmHomePage from './pages/AdmHomePage/AdmHomePage'
import AdmProfilePage from './pages/AdmProfilePage/AdmProfilePage'
import AdmNotificationsPage from './pages/AdmNotificationsPage/AdmNotificationsPage'
import BuyProductInCardAddressProcessPage from './pages/BuyProductInCardAddressProcessPage/BuyProductInCartAddressProcess'
import BuyProductInCartPayment from './pages/BuyProductInCartPayment/BuyProductInCartPayment'
import BuyProductInCartConfirmProcess from './pages/BuyProductInCartConfirmProcess/BuyProductInCartConfirmProcess'
import SocialProjectsPage from './pages/SocialProjects/SocialProjects'
import { appSocket } from './appSocket'
import Events from './Events'
import SocialProjectsContact from './pages/SocialProjectsContact/SocialProjectsContact'
import TokenTest from './pages/TokenTest/TokenTest'
import AboutUsPage from './pages/AboutUs/AboutUsPage'
import FaqPage from './pages/Faq/FaqPage'
import Warning from './components/Warning/Warning'
import CookiesWarning from './components/CookiesWarning/CookiesWarning'
import AccountActivationWarning from './pages/AccountActivationWarning/AccountActivationWarning'
import AdminProductsPage from './pages/AdmProductsPage/AdminProductsPage'
import AdmEditBanner from './pages/AdmEditBanner/AdmEditBanner'
import CartPage from './pages/Cart/CartPage'
import AdmShowCaseDinamic from './pages/AdmShowCasesDinamic/AdmShowCaseDinamic'
import { handleLogout } from './handlers/handleLogout'
import WarrantyPolicyPage from './pages/WarrantyPolicyPage/WarrantyPolicyPage'
import ExchangePolicyPage from './pages/ExchangePolicyPage/ExchangePolicyPage'
import ReturnPolicyPage from './pages/ReturnPolicyPage/ReturnPolicyPage'
import PrivacyPolicyPage from './pages/PrivacyPolicyPage/PrivacyPolicyPage'
import RefundPolicyPage from './pages/RefundPolicyPage/RefundPolicyPage'
import MelhorEnvioCallbackPage from './pages/MelhorEnvioCallbackPage/MelhorEnvioCallbackPage'
import CommissionPolicyPage from './pages/CommissionPolicyPage/CommissionPolicyPage'

const Routes: React.FC = () => {
    const user: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )

    useEffect(() => {
        appSocket.on(Events.USER_BLOCKED_WARNING, (result: number) => {
            if (user && result === user?.id) {
                handleLogout()
            }
        })
        return () => {
            appSocket.off(Events.USER_BLOCKED_WARNING)
        }
    })

    return (
        <Router>
            <NotificationsWrapper />
            <CookiesWarning />
            <Warning />
            <Switch>
                {/* Redireciona para loja "Raridades Curitiba" */}
                <Route path="/raridadescwb">
                    <Redirect to="/shopping/6/products" />
                </Route>
                {/* Redireciona para loja "Loja do Zequinha" */}
                <Route path="/zequinha">
                    <Redirect to="/shopping/16/products" />
                </Route>
                {/* Redireciona para loja "Woodson" */}
                <Route path="/woodson">
                    <Redirect to="/shopping/958/products" />
                </Route>
                {/* Redireciona para loja "LimpJA" */}
                <Route path="/limpja">
                    <Redirect to="/shopping/972/products" />
                </Route>
                {/* Redireciona para loja MP Variedades */}
                <Route path="/mpvariedades">
                    <Redirect to="/shopping/993/products" />
                </Route>
                {/* Redireciona para produtos do Prof. Noslen */}
                <Route path="/noslen">
                    <Redirect to="/search/noslen/:null" />
                </Route>
                {/* Redireciona para o caderno do noslen */}
                <Route path="/caderno-noslen">
                    <Redirect to="/product-view/398/all-data" />
                </Route>
                {/* Redireciona para o kit de cadernos do noslen */}
                <Route path="/kit-noslen">
                    <Redirect to="/product-view/404/all-data" />
                </Route>
                <Route path="/" component={HomePage} exact />
                <Route path="/cart" component={CartPage} exact />
                <Route path="/login" component={LoginPage} exact />
                <Route path="/register" component={RegisterPage} exact />
                <Route
                    path="/account-activation-warning"
                    component={AccountActivationWarning}
                    exact
                />
                {/* Callback Melhor Envio */}
                <Route path="/menvio-callback" component={MelhorEnvioCallbackPage} exact />
                <Route path="/register-product">
                    {user && <RegisterProductPage />}
                    {!user && <Redirect to="/login?next=/register-product" />}
                </Route>
                <Route path="/product-view/:productId">
                    <ProductViewPage />
                </Route>
                <Route path="/user">
                    {user && <UserProfilePage />}
                    {!user && <Redirect to="/login?next=/user/data/top" />}
                </Route>

                <Route
                    exact
                    path="/search/:searchContent/:categorie"
                    component={SearchPage}
                />

                <Route path="/shopping/:id" component={ShoppingPage} />
                <Route exact path="/buy-address-process-cart">
                    {user && <BuyProductInCardAddressProcessPage />}
                    {!user && (
                        <Redirect to="/login?next=/buy-address-process-cart" />
                    )}
                </Route>
                <Route exact path="/buy-payment-process-cart">
                    {user && <BuyProductInCartPayment />}
                    {!user && (
                        <Redirect to="/login?next=/buy-payment-process-cart" />
                    )}
                </Route>
                <Route exact path="/buy-confirm-process-cart">
                    {user && <BuyProductInCartConfirmProcess />}
                    {!user && (
                        <Redirect to="/login?next=/buy-confirm-process-cart" />
                    )}
                </Route>
                <Route exact path="/buy-payment-process">
                    {user && <BuyPaymentProcessPage />}
                    {!user && (
                        <Redirect to="/login?next=/buy-payment-process" />
                    )}
                </Route>
                <Route path="/my-buys">
                    {user && <MyBuysPage />}
                    {!user && <Redirect to="/login?next=/my-buys" />}
                </Route>
                {user && (
                    <Route path="/my-buys-item/:buyId">
                        <MyBuysItemPage />
                    </Route>
                )}
                {!user && (
                    <Route
                        path="/my-buys-item/:buyId"
                        render={(props) => (
                            <Redirect
                                to={`/login?next=/my-buys-item/${props.match.params.buyId}`}
                            />
                        )}
                    />
                )}
                <Route path="/reset-password">
                    <ForgotPasswordPage />
                </Route>
                <Route path="/product-categories">
                    {user && user.userTypeId !== 4 && <ProductCategories />}
                </Route>
                <Route path="/records">
                    {user && user.userTypeId !== 4 && <AdmRegisterCard />}
                </Route>
                <Route path="/home">
                    {user && user.userTypeId !== 4 && <AdmHomePage />}
                </Route>
                <Route path="/profile">
                    {user && user.userTypeId !== 4 && <AdmProfilePage />}
                </Route>
                <Route path="/notifications">
                    {user && user.userTypeId !== 4 && <AdmNotificationsPage />}
                </Route>
                <Route path="/products-home">
                    {user && user.userTypeId !== 4 && <AdminProductsPage />}
                </Route>

                <Route path="/showCases">
                    {user && user.userTypeId !== 4 && <AdmShowCaseDinamic />}
                </Route>

                <Route path="/edit-banner">
                    {user && user.userTypeId !== 4 && <AdmEditBanner />}
                </Route>
                <Route path="/social-projects">
                    <SocialProjectsPage />
                </Route>
                <Route path="/social-projects-contact">
                    <SocialProjectsContact />
                </Route>
                <Route path="/anuncios">
                    <SocialProjectsContact />
                </Route>
                <Route path="/melhor-envio">
                    <TokenTest />
                </Route>
                <Route path="/about-us">
                    <AboutUsPage />
                </Route>
                <Route path="/faq">
                    <FaqPage />
                </Route>
                <Route path="/warranty-policy">
                    <WarrantyPolicyPage />
                </Route>
                <Route path="/exchange-policy">
                    <ExchangePolicyPage />
                </Route>
                <Route path="/return-policy">
                    <ReturnPolicyPage />
                </Route>
                <Route path="/refund-policy">
                    <RefundPolicyPage />
                </Route>
                <Route path="/privacy-policy">
                    <PrivacyPolicyPage />
                </Route>
                <Route path="/commission-policy">
                    <CommissionPolicyPage />
                </Route>
            </Switch>
        </Router>
    )
}
export default Routes
