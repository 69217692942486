import React, { useEffect, useState } from 'react';
import { Back, Container } from './Style';
import {
	Purchase,
	useDeletePurchaseMutation,
	useGetAllUserPurchasesQuery,
	useGetTransactionLazyQuery,
} from '../../generated/graphql';
import IUserAccount from '../../models/IUserAccount';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';

import ReportProblemModal from './ReportProblemModal';
import { Modal } from 'semantic-ui-react';
import { MyBuysCard } from './MyBuysCard/MyBuysCard';

const MyBuys: React.FC = () => {
	const [reportProblemModal, setReportProblemModal] = useState(false);
	const [selectedItem, setSelectedItem] = useState<Purchase>();

	const user: IUserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	)

	const { data, loading } = useGetAllUserPurchasesQuery({
		variables: { userId: user.id },
		fetchPolicy: 'no-cache',
	});

	const [
		getPurchase,
		{ data: dataPurchase, loading: loadingPurchase, error },
	] = useGetTransactionLazyQuery({});

	const redirectToDownload = async (id: number) => {
		await getPurchase({ variables: { purchaseId: id } });
	};

	useEffect(() => {
		if (dataPurchase?.getTransaction) {
			window.open(dataPurchase?.getTransaction as any);
		}
	}, [dataPurchase]);

	return (
		<>
			<Modal
				onClose={() => setReportProblemModal(false)}
				onOpen={() => setReportProblemModal(true)}
				open={reportProblemModal}
			>
				<ReportProblemModal
					closeModal={() => setReportProblemModal(false)}
					purchase={selectedItem!}
				/>
			</Modal>
			<Back>
				<span>Compras Realizadas</span>
			</Back>
			<Container>
				{!loading &&
					data &&
					data.getAllUserPurchases.map(
						(purchase: any, index: number) => (
							<MyBuysCard purchase={purchase} index={index} />
						)
					)}
			</Container>
		</>
	);
};
export default MyBuys;
