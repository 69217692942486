import React, { useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import ReturnPolicy from '../../components/ReturnPolicy/ReturnPolicy';

const ReturnPolicyPage = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Layout title="Política de Devoluções">
			<ReturnPolicy />
		</Layout>
	);
};

export default ReturnPolicyPage;
