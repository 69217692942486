import styled from 'styled-components';

export const Container = styled.div`
display:flex;
flex-direction:column;
padding: 30px;

h2{
    color: #0B0948;
    font-size: 12px;
    margin-bottom: 44px;
    font-family:'Poppins';
    font-weight:bold;
}
.TituloCadastrarConta{
    color: #6b97a5;
    font-family: 'Poppins';
    font-weight: bold;
    font-size: 18px;
    margin-bottom:35px;
}
`;

export const ModalTop = styled.header`
display:flex;
flex-direction:column;  
`;

export const ModalMiddle= styled.main`
display:flex;
flex-direction:column;
    .choose-user-type-buttons-container{
        display: flex;
        justify-content:space-around;
        flex-wrap: wrap;
        height: 10vh;
    }

    .modal-inputs{
        display: flex;
        flex-direction: column;
        flex-wrap:wrap;
        margin-top: 8vh;

        .input-container{
            display: flex;
            flex-direction: column;
            margin-bottom: 28px;

            label{
                margin-bottom:5px;
                margin-bottom: 5px;
                color: #6B97A5;
                font-size: 15px;
                font-family:'Poppins',regular;
            }
            textarea{
                border: solid 1px #CDCDCD;
                height: 180px;
                border-radius: 7px;
                padding: 8px;
            }
            textarea:focus{
                outline:none;
            }

        }
    }
    .modal-checkbox{
       display: flex;
       width: 12vw;
       align-items: flex-start;
       justify-content:start;
       span{
           margin-left: 15px;
           font-family:'Poppins',regular;
           color: #6B97A5;
       }

       input{
           width: 15px;
           height: 15px;
       }
       input:checked{
            background-color: blue;
        }
       span,input:hover{
         cursor: pointer;
       }
    }

        
`;

export const ModalBottom= styled.footer`
display:flex;
flex-direction:row;
align-items:center;
justify-content:flex-end;

  .negative-button{
    margin-right: 18px;
    border: 1px solid var(--unnamed-color-ca667e);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CA667E;
    border-radius: 12px;
    width: 96px;
    height: 30px;
    color:#CA667E;
    font-family:'Poppins',regular;
    font-size: 12px;
    cursor: pointer;

  }
  .positive-button{
    width: 96px;
    height: 30px;
    background: var(--unnamed-color-69b64a) 0% 0% no-repeat padding-box;
    background: #69B64A 0% 0% no-repeat padding-box;
    border-radius: 12px;
    color:white;
    font-family:'Poppins',regular;
    font-size: 12px;
    border:none;
  }
  .positive-button:focus,negative-button:focus{
      outline:none;

  }
  .positive-button:hover,negative-button:hover{
      cursor:pointer;

  }
`;

export const SucessModalContainer = styled.div`
display:flex;
flex-direction:column;
padding: 30px;

h2{
    color: #0B0948;
    font-size: 12px;
    margin-bottom: 44px;
    font-family:'Poppins';
    font-weight:bold;
}
`;

export const SucessModalTop = styled.header`
display:flex;
flex-direction:row;
justify-content:center;
align-items:center;

h2{
    color: #FF7E0F;
    font-size: 15px;
    margin-bottom: 36px;
    font-family:'Poppins';
    font-weight:600;
}
`;

export const SucessModalMiddle = styled.main`
display:flex;
flex-direction:column;
justify-content:center;
align-items: center;
margin-bottom: 19px;

    .email-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        span{
            color:#6B97A5;
            font-size:12px;
            font-family:'Poppins',regular;
        }
        h3{
            font-family:'Poppins';
            font-weight:600;
            color:#0B0948;
            font-size:15px;
            margin: 0;
        }
    }
`;

export const SucessModalBottom = styled.footer`
display:flex;
flex-direction:row;
justify-content:center;
align-items:center;
    
    button{
        background:#69B64A;
        border-radius: 12px;
        width: 112px;
        height: 30px;
        color:#FFFFFF;
        font-family:'Poppins', regular;
        font-size:12px;
        border:none;
    }
`;