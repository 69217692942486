import React from 'react';
import { FilterOption } from './styles';

type Props = {
	active: boolean;
	onClick: any;
};

const FilterOptionItem: React.FC<Props> = ({ children, active, onClick }) => {
	return (
		<FilterOption onClick={onClick}>
			<div className={`checkbox ${active ? 'active' : ''}`} />
			<span>{children}</span>
		</FilterOption>
	);
};

export default FilterOptionItem;
