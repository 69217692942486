import React, { useEffect, useState } from 'react'
import { DeliveryContainer } from './Style'
import Home from '../../../assets/icons/home.svg'
import { ReactComponent as DeliveryTruck } from '../../../assets/icons/deliveryTruck.svg'
import { appSocket } from '../../../appSocket'
import Events from '../../../Events'
import moment from 'moment'
import ProductSaleCard from './ProductSaleCard/ProductSaleCard'
import {
    ProductCategories,
    useGetAllProductCategoriesQuery,
    usePurchaseFiltersLazyQuery,
} from '../../../generated/graphql'

type Props = {
    Product: any
}

type CepResponseMutationSocket = {
    Codigo: string
    Valor: string
    PrazoEntrega: string
    ValorSemAdicionais: string
    ValorMaoPropria: string
    ValorAvisoRecebimento: string
    ValorValorDeclarado: string
    EntregaDomiciliar: string
    EntregaSabado: string
    obsFim: string
    Erro: string
    MsgErro: string
}

const ProductCard: React.FC<Props> = ({ Product }) => {
    const { data: dataCategories } = useGetAllProductCategoriesQuery()

    const [filterPurchases] = usePurchaseFiltersLazyQuery()
    useEffect(() => {
        filterPurchases({
            variables: {
                purchaseFiltersInput: {
                    sellerId: Product.UserAccount.id,
                    categories: dataCategories?.getAllProductCategories?.map(
                        (categorie: ProductCategories) => {
                            return categorie.name
                        }
                    ) as any,
                    typeNegotiation: ['Promoção', 'Venda'],
                    order: 'DESC',
                },
            },
        })
    }, [Product])

    const [destinyCep, setDestinyCep] = useState<string>()
    const [validDeliveryInput, setValidDeliveryInput] = useState<boolean>(false)
    const [calculatedDeliveryCost, setCalculatedDeliveryCost] =
        useState<number>(0)
    const [calculatedDeliveryDate, setCalculatedDeliveryDate] =
        useState<number>(0)

    appSocket.on(
        Events.RESULT_SHIPPING_PRICE,
        (result: CepResponseMutationSocket) => {
            if (result) {
                setValidDeliveryInput(true)
                setCalculatedDeliveryCost(Number.parseInt(result.Valor))
                setCalculatedDeliveryDate(Number.parseInt(result.PrazoEntrega))
            }
        }
    )

    const valid = (): void => {
        if (destinyCep) {
            appSocket.emit(
                Events.VERIFY_SHIPPING_PRICE,
                Product.ProfileAddress?.CEP,
                destinyCep,
                Product.weight,
                Product.format,
                Product.height,
                Product.width,
                Product.thickness
            )
        } else {
            setValidDeliveryInput(false)
        }
    }
    const showDelivery = (): JSX.Element | undefined => {
        if (Product.categorieId !== 7 && Product.categorieId !== 8) {
            return (
                <>
                    {!validDeliveryInput && (
                        <>
                            <DeliveryContainer
                                padding={
                                    (Product.numberOfPhotos > 1 &&
                                        Product.typeNegotiation === 'Venda') ||
                                    Product.typeNegotiation === 'Promoção'
                                        ? '86px'
                                        : ''
                                }
                            >
                                <div className="delivery">
                                    <img
                                        src={Home}
                                        alt=" "
                                        id="home-img"
                                        style={{
                                            marginRight: '10px',
                                            marginBottom: '4px',
                                        }}
                                    />
                                    <div className="input-organizer">
                                        <label>Digite seu CEP</label>
                                        <input
                                            type="text"
                                            onChange={(e) =>
                                                setDestinyCep(e.target.value)
                                            }
                                        />
                                    </div>
                                    <button
                                        className="calc-button"
                                        onClick={() => valid()}
                                    >
                                        Calcular frete
                                    </button>
                                </div>
                            </DeliveryContainer>
                        </>
                    )}

                    {validDeliveryInput && (
                        <>
                            <DeliveryContainer
                                padding={
                                    (Product.numberOfPhotos > 1 &&
                                        Product.typeNegotiation === 'Venda') ||
                                    Product.typeNegotiation === 'Promoção'
                                        ? '86px'
                                        : ''
                                }
                            >
                                <div className="delivery">
                                    <DeliveryTruck
                                        id="home-img"
                                        style={{
                                            marginRight: '15px',
                                            marginBottom: '4px',
                                        }}
                                    />
                                    <div className="input-organizer">
                                        <h2>
                                            Preço do frete{' '}
                                            {calculatedDeliveryCost.toLocaleString(
                                                'pt-br',
                                                {
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                }
                                            )}
                                        </h2>
                                        <label>
                                            Chega no dia{' '}
                                            {moment()
                                                .add(
                                                    calculatedDeliveryDate,
                                                    'days'
                                                )
                                                .format('DD/MM/YYYY')}
                                        </label>
                                    </div>
                                    <button
                                        className="other-address-button"
                                        onClick={() =>
                                            setValidDeliveryInput(false)
                                        }
                                    >
                                        Calcular para outro endereço
                                    </button>
                                </div>
                            </DeliveryContainer>
                        </>
                    )}
                </>
            )
        }
    }
    useEffect(() => {
        showDelivery()
    }, [validDeliveryInput, calculatedDeliveryCost, calculatedDeliveryDate])

    return <ProductSaleCard Product={Product} />
}

export default ProductCard
