import {
    FormControl,
    FormControlLabel,
    makeStyles,
    Radio,
    RadioGroup,
    RadioProps,
    withStyles,
} from '@material-ui/core'
import React, { useRef, useState } from 'react'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import {
    insertAddressProductAction,
    insertProductShippingWay,
} from '../../../../../actions/ProductsCartActions'
import IProductsCart from '../../../../../models/ICart'

import IUserAccount from '../../../../../models/IUserAccount'
import { AppState } from '../../../../../store'

import { ActionsContainer, Container, ModalContainer } from './styles'
import { ProfileAddress } from '../../../../../generated/graphql'
import ProfileCard, {
    ModalAddAddressHandles,
} from '../../../../UserProfile/ProfileCard'
import useWindow from '../../../../../hooks/useWindow'

type Props = {
    item: IProductsCart
}

const ChooseAddressModal: React.FC<Props> = ({ item }) => {
    const window = useWindow()
    const [state, setState] = useState<boolean>(false)
    const dispatch = useDispatch()

    const user: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )
    const modalAddressRef = useRef<ModalAddAddressHandles>(null)

    const useStyles = makeStyles({
        root: {
            '&:hover': {
                backgroundColor: 'transparent',
            },
        }
    })

    function StyledRadio(props: any) {
        const classes = useStyles()

        return (
            <Radio
                className={classes.root}
                disableRipple
                color="default"
                {...props}
                name="shipping"
                style={{
                    marginRight: '8px',
                }}
                checked={item?.parameters?.address?.id === props.address.id}
                onClick={() => {
                    dispatch(
                        insertAddressProductAction(
                            item?.parameters?.product?.id,
                            props.address
                        )
                    )
                }}
            />
        )
    }
    return (
        <>
            <a onClick={() => setState(true)} style={{ textAlign: 'center' }}>
                {item.parameters.address ? (
                    <>Enviar para outro endereço</>
                ) : (
                    <>Cadastrar Endereço</>
                )}
            </a>
            <ProfileCard ref={modalAddressRef} />
            <Container>
                <Modal
                    open={state}
                    onClose={() => setState(false)}
                    onOpen={() => setState(true)}
                    style={{ height: '375px' }}
                >
                    <ModalContainer>
                        <h2 className="mobile-title">
                            {' '}
                            {item?.parameters.product.title}{' '}
                        </h2>
                        <p className="mobile-description">
                            Em qual endereço deseja receber este produto?
                        </p>

                        <FormControl
                            component="fieldset"
                            style={{ width: '100%' }}
                        >
                            <RadioGroup aria-label="gender" name="gender2">
                                {user?.ProfileAddress?.map((address) => (
                                    <div
                                        className="card-options"
                                        key={address?.id}
                                    >
                                        <FormControlLabel
                                            control={
                                                <StyledRadio
                                                    address={address}
                                                />
                                            }
                                            label={
                                                <>
                                                    <label>
                                                        {' '}
                                                        {`${address.street}, ${address.number} - ${address.city} CEP ${address.CEP}`}{' '}
                                                    </label>
                                                </>
                                            }
                                        />
                                    </div>
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </ModalContainer>
                    <ActionsContainer>
                        <button
                            className="add-new-address"
                            onClick={() =>
                                modalAddressRef.current?.handleOpenModal()
                            }
                        >
                            Adicionar novo endereço
                        </button>

                        <div className="modal-buttons">
                            <button
                                className="close-modal"
                                onClick={() => setState(false)}
                            >
                                Cancelar
                            </button>
                            <button
                                className="select-address-modal"
                                onClick={() => setState(false)}
                            >
                                Selecionar endereço
                            </button>
                        </div>
                    </ActionsContainer>
                </Modal>
            </Container>
        </>
    )
}

export default ChooseAddressModal
