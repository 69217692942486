import React, { Dispatch, FormEvent, useCallback, useEffect, useState } from 'react';

import {
	Container,
	Options,
	InputOption,
	Description,
	Buttons,
} from './styles';

import {
	Purchase,
	useReportPurchaseProblemMutation,
} from '../../../generated/graphql';
import { useSelector } from 'react-redux';
import IUserAccount from '../../../models/IUserAccount';
import { AppState } from '../../../store';

type Props =  {
	closeModal: () => void;
	purchase: Purchase;
	tentativas?:number;
	setTentativas?:React.Dispatch<React.SetStateAction<number>>;
}

const ReportProblemModal: React.FC<Props> = ({ closeModal, purchase,setTentativas,tentativas}) => {
	const [type, setType] = useState('');
	const [description, setDescription] = useState('');
	const [reportPurchaseProblem] = useReportPurchaseProblemMutation({
		onCompleted: () => {
			alert('Mensagem enviada com sucesso');
			closeModal()
		}
	});

	const user: IUserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);

	async function handleSubmit(event: FormEvent) {
		event.preventDefault();
		if (type.length <= 0) {
			alert('Selecione um tipo');
			return
		} 
		if(tentativas && tentativas > 3 ){
			alert('Você atingiu seu número de reportes para essa venda.')
			return
		}
		await reportPurchaseProblem({
			variables: {
				purchaseId: purchase.id,
				email: user!.email!,
				type: type,
				description: description,
			},
		});
		if(setTentativas){
			setTentativas((prevState:number) => prevState+1)
		}
	}
	
	return (
		<Container>
			<h1>Reportar um problema</h1>
			<Options>
				<InputOption>
					<input
						type="radio"
						name="option"
						id="Não recebi o produto"
						value="Não recebi o produto"
						onChange={(e) => setType(e.target.value)}
					/>
					<label htmlFor="Não recebi o produto">
						Não recebi o produto
					</label>
				</InputOption>
				<InputOption>
					<input
						type="radio"
						name="option"
						id="Produto vencido"
						value="Produto vencido"
						onChange={(e) => setType(e.target.value)}
					/>
					<label htmlFor="Produto vencido">Produto vencido</label>
				</InputOption>
				<InputOption>
					<input
						type="radio"
						name="option"
						value="Anúncio falso"
						id="Anúncio falso"
						onChange={(e) => setType(e.target.value)}
					/>
					<label htmlFor="Anúncio falso">Anúncio falso</label>
				</InputOption>
				<InputOption>
					<input
						type="radio"
						name="option"
						value="Propaganda enganosa"
						id="Propaganda enganosa"
						onChange={(e) => setType(e.target.value)}
					/>
					<label htmlFor="Propaganda enganosa">
						Propaganda enganosa
					</label>
				</InputOption>
				<InputOption>
					<input
						type="radio"
						name="option"
						value="Produto não é original"
						id="Produto não é original"
						onChange={(e) => setType(e.target.value)}
					/>
					<label htmlFor="Produto não é original">
						Produto não é original
					</label>
				</InputOption>
				<InputOption>
					<input
						type="radio"
						name="option"
						value="Outros"
						id="Outros"
						onChange={(e) => setType(e.target.value)}
					/>
					<label htmlFor="Outros">Outros</label>
				</InputOption>
			</Options>
			<Description>
				<span>Descreva o ocorrido</span>
				<textarea
					required
					onChange={(e) => setDescription(e.target.value)}
				/>
			</Description>
			<Buttons>
				<button className="cancel" onClick={closeModal}>
					Cancelar
				</button>
				<button className="submit" onClick={handleSubmit}>
					Enviar reporte
				</button>
			</Buttons>
		</Container>
	);
};

export default ReportProblemModal;
