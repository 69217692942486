import styled from 'styled-components';

export const Container = styled.div`
	width: 80%;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	padding: 28px;
	margin-bottom: 5rem;
	border: 1px solid black;

	.title-container {
		margin-bottom: 15px;
		h2 {
			font-weight: 600;
			margin-bottom: 23px;
			color: #6b97a5;
    		font-family: 'Poppins';
    		font-weight: bold;
    		font-size: 15px;
		}
	}
	.notifications-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
	.BotaoCadastrarNovaNotificacao{
        width: 201px;
        height: 35px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 12px;
        margin-top:15px;
        font-family:'Poppins';
        font-weight:bold;
        font-size:12px;
        color: #05ABC0;
        border: 1px solid #05ABC0;
    }
`;
