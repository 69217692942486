import React from 'react';
import { useRouteMatch } from 'react-router';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Back, Menu } from './Style';

const ProfileCardControlCard: React.FC = () => {
	const dispatch = useDispatch();
	const { path, url } = useRouteMatch();

	return (
		<>
			<Menu>
				<NavLink
					to={`${url}/data/top`}
					className="items"
					activeClassName="items-active"
				>
					<h2>Dados</h2>
				</NavLink>
				<NavLink
					to={`${url}/card`}
					className="items"
					activeClassName="items-active"
				>
					<h2>Formas de pagamento</h2>
				</NavLink>
				<NavLink
					to={`${url}/bank-account`}
					className="items"
					activeClassName="items-active"
				>
					<h2>Conta de recebimento</h2>
				</NavLink>
				<NavLink
					to={`${url}/integrations`}
					className="items"
					activeClassName="items-active"
				>
					<h2>Conexões</h2>
				</NavLink>
			</Menu>
		</>
	);
};
export default ProfileCardControlCard;
