import styled from 'styled-components';

export const Container = styled.div`
	margin-bottom: 45px;

	h2 {
		color: #004d93;
		font-size: 'Poppins';
		font-weight: 600;
		font-size: min(18px, 1.8vw);
		@media (max-width:475px){
			font-size:18px;
		}
	}
	span {
		color: black;
		font-size: 'Poppins';
		font-weight: 400;
		font-size: min(12px, 1.5vw);
		@media (max-width:475px){
			font-size:18px;
		}
	}
`;
