import React, { useRef } from 'react';
import MenuItem from './MenuItem/MenuItem';
import { Container, Dimmer } from './styles';
import { ReactComponent as Add } from '../../../../assets/mobileIcons/mobileMenu/add.svg';
import { ReactComponent as How } from '../../../../assets/mobileIcons/mobileMenu/how.svg';
import { ReactComponent as Home } from '../../../../assets/mobileIcons/mobileMenu/home.svg';
import { ReactComponent as LocalBag } from '../../../../assets/mobileIcons/mobileMenu/localBag.svg';
import { ReactComponent as ShoppingCart } from '../../../../assets/mobileIcons/mobileMenu/shoppingCart.svg';
import { ReactComponent as Store } from '../../../../assets/mobileIcons/mobileMenu/store.svg';
import { ReactComponent as Logout } from '../../../../assets/mobileIcons/mobileMenu/logout.svg';
import { ReactComponent as Whatsicon } from '../../../../assets/mobileIcons/mobileMenu/whats.svg';
import { useSelector } from 'react-redux';
import IUserAccount from '../../../../models/IUserAccount';
import { AppState } from '../../../../store';

import Img from '../../CommomImg/Img';
import { Link, useHistory } from 'react-router-dom';

import MobileSearchModal, {
	MobileSearchModalHandles,
} from '../../../Category/MobileCategorysMenu/MobileSearch/MobileSearchModal';
import MobileCategoriesModal, {
	MobileCategoriesModalHandles,
} from '../../../Category/MobileCategorysMenu/MobileCategoriesModal/MobileCategoriesModal';
import { handleLogout } from '../../../../handlers/handleLogout';

type Props = {
	positionLeft: string;
	onClick: any;
	mobile: boolean;
};
const MobileMenu: React.FC<Props> = ({ ...props }) => {
	const user: IUserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const history = useHistory();
	const searchModalRef = useRef<MobileSearchModalHandles>(null);
	const categoriesModalRef = useRef<MobileCategoriesModalHandles>(null);
	return (
		<>
			<MobileSearchModal ref={searchModalRef} />
			<MobileCategoriesModal ref={categoriesModalRef} />
			{props.mobile && (
				<Dimmer
					onClick={() => {
						props.onClick(); 
					}}
				></Dimmer>
			)}
			<Container positionLeft={props.positionLeft}>
				<div className="header-menu"> 
					{!user ? (
						<></>
					) : (
						<div className="user-informations">
							<Img
								src={`${process.env.REACT_APP_STATIC_URL}/user/${user.id}.jpeg`}
							/>
							<div className="user-name-and-link">
								<span> {user.name.slice(0, 14)} </span>
								<button className="ButtonExitMobileMenu">
								<Link to="/user/data/top" className="link">
									Acessar perfil
								</Link></button>
							</div>
						</div>
					)}
				</div>
				<div className="options-menu">
					<MenuItem
						image={Home}
						iconFill={`${
							history.location.pathname === '/'
								? '#FF7E0F'
								: '#05A5BF'
						}`}
						onClick={() => (window.location.href = '/')}
					>
						Inicio
					</MenuItem>
					{user && (
                        <>
						 <MenuItem
							image={Add}
							iconFill={`${
								history.location.pathname === '/shopping/:userId'
									? '#FF7E0F'
									: '#05A5BF'
							}`}
							onClick={() =>
								history.push(
									`/register-product/:categoryId/product`
								)
							}
						>
						Fazer Anúncio
						</MenuItem>
                        <MenuItem
							image={Store}
							iconFill={`${
								history.location.pathname === '/shopping/:userId'
									? '#FF7E0F'
									: '#05A5BF'
							}`}
							onClick={() =>
								(window.location.href = `/shopping/${user?.id}/dashboard`)
							}
						>
						Minha loja
						</MenuItem>
						<MenuItem
							image={LocalBag}
							iconFill={`${
								history.location.pathname === '/my-buys'
									? '#FF7E0F'
									: '#05A5BF'
							}`}
							onClick={() => (window.location.href = '/my-buys')}
						>
							Histórico de compras
						</MenuItem> 
                        </>
                    )}	
					<MenuItem
						image={ShoppingCart}
						iconFill={`${
							history.location.pathname === '/cart'
								? '#FF7E0F'
								: '#05A5BF'
						}`}
						onClick={() => (window.location.href = '/cart')}
					>
						Meu carrinho
					</MenuItem>
					<a href={`${process.env.REACT_APP_STATIC_URL}/manual/manual-anuncio.pdf`}
                     	className="style-title-sale-at-sambay"
                    	target="_blank"
						rel="noopener noreferrer">
						<MenuItem
							image={How}
							iconFill={'#05A5BF'}
							>
							Como Anunciar
						</MenuItem>
					</a>
					<MenuItem
						image={Whatsicon}
						iconFill={`${
							history.location.pathname === ''
								? '#FF7E0F'
								: '#05A5BF'
						}`}
						onClick={() => (window.location.href = 'https://api.whatsapp.com/send?phone=5541985094049&text=Ol%C3%A1%20Sambay%20Express!%20Preciso%20de%20uma%20ajuda%20de%20voc%C3%AAs%20%3A)')}
					>
						Fale Conosco
					</MenuItem>
					{user && (
						<MenuItem
							image={Logout}
							iconFill={'#05A5BF'}
							onClick={() => handleLogout()} 
							>
							Sair
						</MenuItem>
					)}
				</div>
			</Container>
		</>
	);
};

export default MobileMenu;
