import styled from 'styled-components'

interface VariationItemProperties {
    cor?: string
}

export const VariationContainer = styled.div`
    display: flex;
    align-items: center;

    h2 {
        margin-top: 1rem;
    }

    button {
        cursor: pointer;
        background-color: #fff;
        border: none;
    }

    input {
        margin-right: 5px;
        width: 100%;
        height: 35px;
        border-radius: 7px;
        border: solid 1px black;
        margin-bottom: 1rem;
        background-color: #fff;
    }

    .variation-button {
        background-color: white;
        border: black solid 1px;
        font-family: 'Poppins', semi-bold;
        color: black;
        font-size: 13px;
        border-radius: 7px;
        height: 40px;
        width: 100%;
        padding: 12px;
        margin-right: 10px;
        cursor: pointer;
        margin-bottom: 0.8rem;
    }

    .newVariationContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
    }
`

export const VariationItem = styled.div<VariationItemProperties>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6vw;
    height: 4vh;
    border-radius: 2px;
    margin: 1rem;
    background-color: ${(Property) => Property?.cor || 'white'};
    border: 1px solid black;
    border-radius: 4px;
    p {
        font-family: 'Poppins', semi-bold;
        color: black;
        font-size: 14px;
    }
`

export const FieldAlert = styled.span`
    margin-top: 0.6rem;
    color: #ff0000;
    font-size: 13px;
    font-family: 'Poppins';
`
export const ChoiceContainer = styled.div<inputProperties>`
    label {
        color: #6b97a5;
        font-family: 'Poppins', semi-bold;
        font-weight: bold;
        font-size: 12px;
        @media (max-width: 475px) {
            font-size: 14px;
        }
    }
    .choice-buttons-container {
        display: flex;
        flex-direction: row;
        border-radius: 8px;
        border: solid 1px black;
        margin-bottom: 25px;
        width: 100%;
        height: 40px;

        button {
            width: 100%;
            font-weight: bold;
            font-size: 13px;
            color: #6b97a5;
            font-family: 'Poppins', semi-bold;
        }
        div {
            width: 100%;
            border: none;
            border-radius: 8px;
            font-family: 'Poppins', semi-bold;
            font-weight: bold;
            font-size: 13px;
            color: #6b97a5;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            &:hover {
                cursor: pointer;
            }
            @media (max-width: 475px) {
                font-size: 14px;
            }
        }

        .button-active-left {
            background-color: #6b97a5;
            color: white;
            outline: none;
            border-radius: 8px;
            font-family: 'Poppins', semi-bold;
            font-weight: bold;
            font-size: 13px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            width: 100%;
            @media (max-width: 475px) {
                font-size: 14px;
            }
        }
        .button-active-right {
            background-color: #6b97a5;
            color: white;
            outline: none;
            border-radius: 8px;
            font-family: 'Poppins', semi-bold;
            font-weight: bold;
            font-size: 13px;
            display: flex;
            width: 100%;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            @media (max-width: 475px) {
                font-size: 14px;
            }
        }
        @media (min-width: 320px) and (max-width: 475px) {
            width: 100%;
        }
    }
`

export const Container = styled.div`
    padding: 20px;
    margin-top: 0px;
    border: solid 1px black;
    border-radius: 7px;
    padding-left: 28px;
    font-family: 'Poppins', semi-bold;
    padding-top: 25px;

    .options-container {
        width: 100%;
        height: 55px;
        border: 1px solid black;
        border-radius: 8px;
        opacity: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 11px;
        margin-top: 11px;
    }

    .input-color {
        root {
            color: #6b98a6;
        }
        div {
            flex-direction: row;
            .MuiSvgIcon-root {
                width: 45px;
                height: 34px;
            }
            .MuiSvgIcon-root {
                width: 45px;
                height: 34px;
            }
        }
    }

    .title-input-container {
        display: flex;
        flex-direction: column;
        border: solid 1px black;
        padding: 4px;
        border-radius: 8px;
        margin-bottom: 25px;
        width: 100%;
        height: 46px;
        label {
            color: black;
            font-family: 'Poppins';
            font-weight: bold;
            font-size: 10px;
        }
        input {
            border: none;
            color: black;
            font-family: 'Poppins', regular;
            height: 30px;
            font-size: 13px;
            background-color: #fff;
        }
        input:focus {
            outline: none;
        }
        input:focus::-webkit-input-placeholder {
            color: transparent;
        }
    }
    .div {
        display: flex;
        flex-direction: row;
        width: 100%;
        .subtitle-input-container {
            display: flex;
            flex-direction: column;
            border: solid 1px black;
            padding: 4px;
            border-radius: 8px;
            margin-bottom: 25px;
            margin-right: 35px;
            width: 100%;
            height: 46px;
            label {
                color: black;
                font-family: 'Poppins';
                font-weight: bold;
                font-size: 10px;
            }
            input {
                border: none;
                color: black;
                font-family: 'Poppins', regular;
                font-size: 13px;
                background-color: #fff;
            }
            input:focus {
                outline: none;
            }
            input:focus::-webkit-input-placeholder {
                color: transparent;
            }
        }
        .choice-buttons-container {
            display: flex;
            flex-direction: row;
            border-radius: 8px;
            border: solid 1px black;
            margin-bottom: 25px;
            width: 100%;
            height: 46px;

            button {
                width: 100%;
                border: none;
                background-color: #fff;
                border-radius: 8px;
                color: #6b97a5;
                font-family: 'Poppins', semi-bold;
                font-weight: bold;
                font-size: 12px;
                cursor: pointer;
            }
            button:focus {
                outline: none;
            }

            .button-active {
                background-color: #6b97a5;
                color: #fff;
                outline: none;
                border-radius: 8px;
                font-family: 'Poppins';
                font-weight: bold;
                font-size: 13px;
            }
        }
    }
    .description-container {
        display: flex;
        flex-direction: column;
        border: solid 1px black;
        padding: 6px;
        border-radius: 8px;
        margin-bottom: 25px;
        width: 740px;
        height: 89px;
        label {
            color: black;
            font-family: 'Poppins';
            font-weight: bold;
            font-size: 10px;
        }
        textarea {
            resize: none;
            min-height: 62px;
            border: none;
            color: black;
            font-family: 'Poppins', regular;
            font-size: 13px;
            background-color: #fff;
        }
        textarea:focus {
            outline: none;
        }
    }
    .other-container {
        display: flex;
        flex-direction: row;

        .inputs-organizer-brand {
            display: flex;
            flex-direction: column;
            border: solid 1px black;
            padding: 4px;
            border-radius: 8px;
            width: 253px;
            height: 46px;

            label {
                color: black;
                font-family: 'Poppins';
                font-weight: bold;
                font-size: 10px;
            }

            input {
                border: none;
                color: black;
                font-size: 13px;
                font-family: 'Poppins', regular;
                background-color: #fff;
            }
            input:focus {
                outline: none;
            }
            input:focus::-webkit-input-placeholder {
                color: transparent;
            }
        }
        .inputs-organizer-size {
            display: flex;
            flex-direction: column;
            border: solid 1px black;
            padding: 4px;
            border-radius: 8px;
            width: 87px;
            height: 46px;

            label {
                color: black;
                font-family: 'Poppins';
                font-weight: bold;
                font-size: 10px;
                height: 19px;
            }

            input {
                border: none;
                color: black;
                font-size: 13px;
                font-family: 'Poppins', regular;
                background-color: #fff;
            }
            input:focus {
                outline: none;
            }
            input:focus::-webkit-input-placeholder {
                color: transparent;
            }
        }
        .inputs-organizer-material {
            display: flex;
            flex-direction: column;
            border: solid 1px black;
            padding: 4px;
            border-radius: 8px;
            width: 253px;
            height: 46px;

            label {
                color: black;
                font-family: 'Poppins';
                font-weight: bold;
                font-size: 10px;
            }

            input {
                border: none;
                color: black;
                font-size: 13px;
                font-family: 'Poppins', regular;
                background-color: #fff;
            }
            input:focus {
                outline: none;
            }
            input:focus::-webkit-input-placeholder {
                color: transparent;
            }
        }
    }

    .prompt-delivery {
        display: flex;
        flex-direction: column;
        margin-bottom: 44px;

        label {
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 20px;
            margin-top: 10px;
            color: #6b97a5;
        }

        .prompt-delivery-buttons {
            display: flex;
            flex-direction: row;
            padding: 0px;
            border-radius: 8px;
            border: solid 1px black;
            width: 100%;
            height: 40px;

            .prompt-delivery-button {
                width: 100%;
                font-weight: bold;
                font-size: 13px;
                outline: none;
                cursor: pointer;
                border: none;
                background-color: #fff;
                border: 1px solid black;
                color: #6b97a5;
                font-family: 'Poppins', bold;
            }

            #yes {
                border-radius: 8px;
                border: none;
                margin: 0px;
            }

            #no {
                border-radius: 8px;
                border: none;
                margin: 0px;
            }

            .active {
                color: #fff;
                background-color: #6b97a5;
            }
        }
    }
`
interface inputProperties {
    borderColor?: string
}

export const InputOrganizerSize = styled.div<inputProperties>`
    display: flex;
    flex-direction: column;
    border: solid 1px ${(Prop) => Prop.borderColor};
    padding: 4px;
    border-radius: 8px;
    width: 100%;
    height: 51px;

    label {
        color: #6b97a5;
        font-family: 'Poppins', semi-bold;
        font-weight: bold;
        font-size: 12px;
        height: 19px;
    }

    input {
        border: none;
        color: black;
        background-color: #fff;
        font-family: 'Poppins', semi-bold;
        font-size: 13px;
    }
    input:focus {
        outline: none;
    }
    input:focus::-webkit-input-placeholder {
        color: transparent;
    }

    select {
        border: none;
        color: black;
        font-size: 13px;
        font-family: 'Poppins', regular;
        background-color: #fff;
    }
    select:focus {
        outline: none;
    }
`

export const SubTitleInputContainer = styled.div<inputProperties>`
    display: flex;
    flex-direction: column;
    border: solid 1px ${(Prop) => Prop.borderColor};
    padding: 4px;
    border-radius: 8px;
    margin-bottom: 25px;
    width: 100%;
    height: 46px;
    label {
        color: #6b97a5;
        font-family: 'Poppins', semi-bold;
        font-weight: bold;
        font-size: 12px;
    }
    input {
        border: none;
        color: black;
        background-color: #fff;
        font-family: 'Poppins', semi-bold;
        font-size: 13px;
    }
    input:focus {
        outline: none;
    }
    input:focus::-webkit-input-placeholder {
        color: transparent;
    }
`

export const ImagesContainer = styled.div`
    padding: 17px;
    margin-top: 0px;
    margin-bottom: 50px;
    border: solid 1px black;
    border-radius: 7px;
    padding-left: 28px;
    font-family: 'Poppins', semi-bold;

    span {
        h1 {
            font-family: 'Poppins';
            color: black;
            font-size: 10px;
        }
    }
    .img-instructions {
        color: #6b97a5;
        font-family: 'Poppins', semi-bold;
        font-weight: bold;
        font-size: 12px;
    }
    .h1-instruction {
        font-family: 'Poppins', semi-bold;
        color: #6b97a5;
        font-size: 15px;
    }
    .img-container {
        display: flex;
        flex-direction: row;
        width: 91%;
        span {
            margin-bottom: 60px;
            margin-right: 35px;
            border: solid 1px black;
            border-radius: 8px;
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 157px;
            height: 105px;
            cursor: pointer;
            label:hover {
                cursor: pointer;
            }
            .preview {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .delete-icon-background {
                    position: relative;

                    svg {
                        position: absolute;
                        left: 128px;
                        top: -43px;
                        background-color: #fff;
                        border-radius: 20px;
                        width: 27px;
                        fill: red;
                    }
                    svg:hover {
                        cursor: pointer;
                    }
                }
                img {
                    width: 157px;
                    height: 105px;
                    border-radius: 8px;
                }
            }
        }
    }
    input {
        display: none;
    }
    input:focus::-webkit-input-placeholder {
        color: transparent;
    }
    p {
        color: red;
    }
`

export const Title = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;

    h2 {
        font-family: 'Poppins', semi-bold;
        font-size: 16px;
        color: #6b97a5;
    }
`

export const TitleCategory = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    h2 {
        font-family: 'Poppins', semi-bold;
        font-size: 16px;
        color: #6b97a5;
    }
`

export const Categorys = styled.div`
    padding: 17px;
    margin-top: 0px;
    margin-bottom: 50px;
    border: solid 1px black;
    border-radius: 7px;
    font-family: 'Poppins', semi-bold;
    padding-bottom: 10px;

    span {
        h1 {
            font-family: 'Poppins';
            color: black;
            font-size: 10px;
            margin: 0;
            margin-bottom: 34px;
        }
    }
    .normal-size-active {
        background-color: blue;
    }
    .img-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-evenly;
        padding-bottom: 20px;

        .normal-size {
            margin-bottom: 10px;
            height: 50px;
            width: 235px;
            margin-right: 35px;
            border: solid 1px black;
            border-radius: 8px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            color: #6b97a5;
            font-family: 'Poppins', semi-bold;
        }
        .size {
            margin-bottom: 10px;
            height: 50px;
            width: 348px;
            margin-right: 35px;
            border: solid 1px black;
            border-radius: 8px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            color: black;
            font-family: 'Poppins', regular;
        }
        .option {
            width: 183px;
            height: 30px;
            margin-right: 10px;
            margin-bottom: 10px;
            border: solid 1px black;
            border-radius: 8px;
            align-items: center;
            color: black;
            display: flex;
            flex-direction: row;
            font-size: 12px;
            justify-content: center;
            font-family: 'Poppins', regular;
            color: black;
            cursor: pointer;

            label:hover {
                cursor: pointer;
            }
            .preview {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                img {
                    height: 175px;
                    width: 274px;
                    border-radius: 8px;
                }
            }
        }
        .option-active {
            width: 183px;
            height: 30px;
            margin-right: 10px;
            margin-bottom: 10px;
            border: solid 1px black;
            border-radius: 8px;
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
            background-color: #6b97a5;
            font-size: 12px;
            color: white;
            cursor: pointer;

            label:hover {
                cursor: pointer;
            }
        }
    }
    .sub {
        border-top: solid 1px #9e9e9e;
    }
`
export const PriceTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;

    h2 {
        font-family: 'Poppins', semi-bold;
        font-size: 16px;
        color: #6b97a5;
    }
`

export const PricePayment = styled.div`
    padding: 17px;
    margin-top: 0px;
    margin-bottom: 50px;
    border: solid 1px black;
    border-radius: 7px;
    padding-left: 28px;
    font-family: 'Poppins', semi-bold;

    .organizer {
        display: flex;
        flex-direction: column;

        flex-wrap: wrap;
        .sell-type-buttons-container {
            .sell-type-container {
                display: flex;
                flex-direction: row;
                border-radius: 8px;
                border: solid 1px black;
                margin-bottom: 25px;
                width: 100%;
                height: 40px;
                div {
                    width: 100%;
                    border: none;
                    background-color: #fff;
                    border-radius: 8px;
                    color: #6b97a5;
                    font-family: 'Poppins';
                    font-weight: bold;
                    font-size: 13px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    &:hover {
                        cursor: pointer;
                    }
                }

                .button-sell-active {
                    width: 100%;
                    color: white;
                    border: none;
                    border-radius: 8px;
                    font-family: 'Poppins';
                    font-weight: bold;
                    font-size: 13px;
                    background-color: #6b97a5;
                }
                .bargain-active {
                    width: 100%;
                    color: white;
                    border: none;
                    border-radius: 8px;
                    font-family: 'Poppins';
                    font-weight: bold;
                    font-size: 13px;
                    background-color: #6b97a5;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        .sell-options {
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 20px;
            .inputs-organizer {
                display: flex;
                flex-direction: column;
                border: solid 1px black;
                padding: 4px;
                border-radius: 8px;
                width: 100%;

                label {
                    color: #6b97a5;
                    font-family: 'Poppins', semi-bold;
                    font-weight: bold;
                    font-size: 12px;
                }

                input {
                    border: none;
                    color: black;
                    font-size: 13px;
                    font-family: 'Poppins', regular;
                    background-color: #fff;
                }
                input:focus {
                    outline: none;
                }
                input:focus::-webkit-input-placeholder {
                    color: transparent;
                }
            }
        }
    }
    .stylePechincha {
        padding-top: 1rem;
    }
    select {
        border: none;
        color: black;
        font-size: 13px;
        font-family: 'Poppins', regular;
        background-color: #fff;
    }
`
export const InputOrganizer = styled.div<inputProperties>`
    display: flex;
    flex-direction: column;
    border: solid 1px ${(Props) => Props.borderColor};
    padding: 4px;
    border-radius: 8px;
    width: 100%;

    label {
        color: #6b97a5;
        font-family: 'Poppins';
        font-weight: bold;
        font-size: 12px;
    }

    input {
        border: none;
        color: black;
        font-size: 13px;
        font-family: 'Poppins', regular;
        background-color: #fff;
    }
    input:focus {
        outline: none;
    }
    input:focus::-webkit-input-placeholder {
        color: transparent;
    }

    select {
        color: black;
    }
`

export const PlanTitleContainer = styled.div`
    display: flex;
    flex-direction: column;

    h2 {
        font-family: 'Poppins', semi-bold;
        font-size: 16px;
        color: #6b97a5;
    }
`
export const Plan = styled.div`
    margin-top: 0px;
    border-radius: 7px;
    font-family: 'Poppins', semi-bold;

    .img-container {
        display: flex;
        flex-direction: row;

        span {
            margin-bottom: 60px;
            width: 232px;
            height: 315px;
            margin-right: 35px;
            border: solid 1px #c7c7c7;
            border-radius: 8px;
            h1 {
                font-size: 28px;
                color: #69b54a;
                font-weight: 300;
                font-family: 'Poppins', Extra-light;
                padding: 15px 20px 0px;
            }
            .type {
                font-size: 15px;
                font-family: 'Poppins';
                font-weight: bold;
                color: black;
                padding: 5px 20px;
                border-bottom: solid 1px #9e9e9e;
            }

            .condition {
                color: black;
                font-size: 10px;
                font-family: 'Poppins';
                font-weight: bold;
                margin-top: 5px;
                padding: 0px 20px 0px;
            }

            .description {
                font-family: 'Poppins', regular;
                color: black;
                font-size: 13px;
                padding: 0px 20px 0px;
            }
        }
        .active {
            margin-bottom: 60px;
            width: 232px;
            height: 315px;
            margin-right: 35px;
            border: solid 2px #3398cb;
            border-radius: 8px;
            h1 {
                font-size: 28px;
                color: #69b54a;
                font-weight: 300;
                font-family: 'Poppins', Extra-light;
                padding: 15px 20px 0px;
            }
            .type {
                font-size: 15px;
                font-family: 'Poppins';
                font-weight: bold;
                color: black;
                padding: 5px 20px;
                border-bottom: solid 1px #9e9e9e;
            }

            .condition {
                color: black;
                font-size: 10px;
                font-family: 'Poppins';
                font-weight: bold;
                margin-top: 5px;
                padding: 0px 20px 0px;
            }

            .description {
                font-family: 'Poppins', regular;
                color: black;
                font-size: 13px;
                padding: 0px 20px 0px;
            }
        }
        .active:hover,
        span:hover {
            cursor: pointer;
        }
    }
`

export const BtnConfirm = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    width: 100%;
    margin-top: 10px;

    .confirm-button {
        border: none;
        background-color: #6b97a5;
        border-radius: 8px;
        color: white;
        font-family: 'Poppins';
        font-weight: bold;
        font-size: 13px;
        width: 157px;
        height: 40px;
        text-align: center;
    }
    .delete-button {
        margin-right: 20px;
        width: 157px;
        height: 40px;
        border: solid 1px black;
        border-radius: 8px;
        color: #6b97a5;
        font-family: 'Poppins';
        font-weight: bold;
        font-size: 13px;
        background-color: #fff;
    }
    .delete-button:hover,
    .confirm-button {
        cursor: pointer;
    }

    .delete-button:focus,
    .confirm-button {
        outline: none;
    }
`
export const DeliveryInformations = styled.div`
    display: flex;
    flex-direction: column;

    h1 {
        font-size: 13px;
        color: #6b97a5;
        font-family: 'Poppins';
        font-weight: bold;
    }
    span {
        display: flex;
        flex-direction: row;
        color: #000000;
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 1rem;
        font-family: 'Poppins', semi-bold;
    }
    .other-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 25px;

        .inputs-organizer-brand {
            display: flex;
            flex-direction: column;
            border: solid 1px black;
            padding: 4px;
            border-radius: 8px;
            width: 253px;
            height: 46px;

            label {
                color: #6b97a5;
                font-family: 'Poppins';
                font-weight: bold;
                font-size: 12px;
            }

            input {
                border: none;
                color: #6b97a5;
                font-size: 13px;
                font-family: 'Poppins', regular;
                background-color: #fff;
            }
            input:focus {
                outline: none;
            }
            input:focus::-webkit-input-placeholder {
                color: transparent;
            }
            select {
                border: none;
                color: black;
                font-size: 13px;
                font-family: 'Poppins', regular;
                background-color: #fff;
            }
            select:focus {
                outline: none;
            }
        }
        .inputs-organizer-size {
            display: flex;
            flex-direction: column;
            border: solid 1px black;
            padding: 4px;
            border-radius: 8px;
            width: 87px;
            height: 46px;

            label {
                color: black;
                font-family: 'Poppins';
                font-weight: bold;
                font-size: 10px;
                height: 19px;
            }

            input {
                border: none;
                color: black;
                font-size: 13px;
                font-family: 'Poppins', regular;
                background-color: #fff;
            }
            input:focus {
                outline: none;
            }
            input:focus::-webkit-input-placeholder {
                color: transparent;
            }
        }
        .inputs-organizer-material {
            display: flex;
            flex-direction: column;
            border: solid 1px black;
            padding: 4px;
            border-radius: 8px;
            width: 253px;
            height: 46px;

            label {
                color: black;
                font-family: 'Poppins';
                font-weight: bold;
                font-size: 10px;
            }

            input {
                border: none;
                color: black;
                font-size: 13px;
                font-family: 'Poppins', regular;
                background-color: #fff;
            }
            input:focus {
                outline: none;
            }
            input:focus::-webkit-input-placeholder {
                color: transparent;
            }
            select {
                border: none;
                color: black;
                font-size: 13px;
                font-family: 'Poppins', regular;
                background-color: #fff;
            }
            select:focus {
                outline: none;
            }
        }
    }
`
