import React, { useCallback, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from '../../hooks/useQuery'

import { useSetMelhorEnvioTokenMutation } from '../../generated/graphql'
import { updateMelhorEnvioToken } from '../../actions/UserActions'
import IUserAccount from '../../models/IUserAccount'
import { AppState } from '../../store'

const MelhorEnvioCallbackPage = () => {
    const query = useQuery()
    const dispatch = useDispatch()

    const callbackCode = query.get('code')

    const [codeQuery, setCodeQuery] = useState<string>()
    const [setMelhorEnvioToken] = useSetMelhorEnvioTokenMutation()

    const { id: userId }: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )

    const getAccessToken = useCallback(
        async (code: string) => {
            const response = await fetch(
                `${process.env.REACT_APP_MELHOR_ENVIO_ENDPOINT}/oauth/token`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        'User-Agent': 'Sambay (desenvolvimento@sambay.com.br)',
                        'Access-Control-Allow-Origin': `${process.env.REACT_APP_BASE_URL}`,
                    },
                    body: JSON.stringify({
                        grant_type: 'authorization_code',
                        client_id: process.env.REACT_APP_MELHOR_ENVIO_CLIENT_ID,
                        client_secret:
                            process.env.REACT_APP_MELHOR_ENVIO_SECRET,
                        redirect_uri:
                            process.env.REACT_APP_MELHOR_ENVIO_REDIRECT_URI,
                        code,
                    }),
                }
            )

            const { access_token: token, refresh_token: refreshToken } =
                await response.json()

            if (token && refreshToken && userId) {
                await setMelhorEnvioToken({
                    variables: {
                        token,
                        refreshToken,
                        userId,
                    },
                })

                dispatch(updateMelhorEnvioToken(token))
            }
        },
        [dispatch, setMelhorEnvioToken, userId]
    )

    useEffect(() => {
        if (callbackCode) setCodeQuery(callbackCode)
    }, [callbackCode])

    useEffect(() => {
        if (codeQuery) getAccessToken(codeQuery)
    }, [codeQuery, getAccessToken])

    return <></>
}

export default MelhorEnvioCallbackPage
