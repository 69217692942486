import { Label } from 'semantic-ui-react';
import styled from 'styled-components';

export const ModalContent = styled.form`
	padding: 30px;

	h3 {
		color: #6b97a5;
		font-family: 'Poppins';
		font-weight: bold;
	}
	.T{
		margin-bottom:25px;
	}

	.inputs-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		margin-bottom: 15px;
	}

	.input {
		display: flex;
		flex-direction: column;
		margin-bottom: 15px;
		width: 100%;

		label {
			margin-bottom: 5px;
			color: black;
			font-size: min(14px, 1.5vw);
			font-size: 'Poppins';
			font-weight: 400;
		}
		input {
			border: solid 1px black;
			height: 30px;
			border-radius: 7px;

			&:focus {
				outline: none;
			}
		}
	}

	.mid-line {
		display: flex;
		flex-direction: row;

		width: 100%;

		.input + .input {
			margin-left: 15px;
		}
	}

	.last-line {
		display: flex;

		.input + .input {
			margin-left: 15px;
		}
	}
	
	.TituloProjetoSocial{
		padding-bottom:10px;
	}
	
	.modal-actions {
		display: flex;
		justify-content: center;

		.negative-button {
			margin-right: 30px;

			width: 96px;
			height: 30px;
			background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
			border: 1px solid var(--unnamed-color-ca667e);
			background: #ffffff 0% 0% no-repeat padding-box;
			border: 1px solid #ca667e;
			border-radius: 12px;
			color: #ca667e;

			font-family: 'Poppins';
			font-weight: 400;
			cursor: pointer;
		}
		.positive-button {
			width: 96px;
			height: 30px;
			background: var(--unnamed-color-69b64a) 0% 0% no-repeat padding-box;
			background: #69b64a 0% 0% no-repeat padding-box;
			border-radius: 12px;
			color: white;

			font-family: 'Poppins';
			font-weight: 400;

			border: none;
			cursor: pointer;
			margin-right:10px;
		}
	}
`;
