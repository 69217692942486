import {
	FormControlLabel,
	makeStyles,
	Radio,
	RadioGroup,
} from '@material-ui/core';
import React, {
	useCallback,
	useState,
	useImperativeHandle,
	forwardRef,
	useRef,
} from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { Modalbottom, ModalContainer, ModalMiddle, ModalTop,RemetenteContainer } from './styles';
import clsx from 'clsx';
import {
	removeMelhorEnvioPaymentMethod,
	updateMelhorEnvioPaymentMethod,
} from '../../../../../actions/UserActions';
import { useDispatch, useSelector } from 'react-redux';
import { useCheckUserBalanceQuery } from '../../../../../generated/graphql';
import IUserAccount from '../../../../../models/IUserAccount';
import { AppState } from '../../../../../store';
import Spinner from '../../../../Loader/Spinner';
import { ModalInsertMelhorEnvioBalanceHandles } from '../InsertMelhorEnvioBalance/InsertMelhorEnvioBalance';
import InsertMelhorEnvioBalanceModal from '../InsertMelhorEnvioBalance/InsertMelhorEnvioBalance';

export interface ModalChoosePaymentFormMelhorEnvioHandles {
	handleOpenModal: () => void;
	nameRemetente:string;
}
type Props = {
	onClick?: any;
};

const ChoosePaymentMethodMelhorEnvio: React.ForwardRefRenderFunction<ModalChoosePaymentFormMelhorEnvioHandles, Props> = ({ onClick }, ref) => {
	const [state, setState] = useState(false);
	const [nameRemetente,setNameRemetente] = useState<string>("")
	const show = (dimmer: any) => setState(true);
	const handleOpenModal = useCallback(() => {
		setState(true);
	}, []);
	const user: IUserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const [selectedPaymentMethod, setPaymentMethod] = useState<string>('');
	const posibleMethods = [
		'Carteira Melhor Envio',
		'mercado-pago',
		'pagseguro',
		'picpay ',
		'moip',
	];
	const { data, loading } = useCheckUserBalanceQuery({
		variables: { userId: user.id },
		fetchPolicy: 'cache-and-network',
	});
	const [loaderIsVisible, setLoaderVisible] = useState(false);

	const submitButton = useRef<HTMLButtonElement>(null);

	useImperativeHandle(ref, () => {
		return {
			nameRemetente:nameRemetente,
			handleOpenModal
		};
	});

	const useStyles = makeStyles({
		root: {
			'&:hover': {
				backgroundColor: 'transparent',
			},
		},
		icon: {
			borderRadius: '50%',
			width: 15,
			height: 15,

			backgroundColor: '#F1F1F1',

			'input:hover ~ &': {
				backgroundColor: '#ebf1f5',
			},
			'input:disabled ~ &': {
				boxShadow: 'none',
				background: 'rgba(206,217,224,.5)',
			},
		},
		checkedIcon: {
			backgroundColor: '#05A5BF',

			width: 15,
			height: 15,
			'input:hover ~ &': {
				backgroundColor: '#05A5BF',
			},
		},
	});
	const dispatch = useDispatch();

	const insertBalanceModal = useRef<ModalInsertMelhorEnvioBalanceHandles>(
		null
	);

	function StyledRadio(props: any) {
		const classes = useStyles();

		return (
			<Radio
				className={classes.root}
				disableRipple
				color="default"
				checkedIcon={
					<span className={clsx(classes.icon, classes.checkedIcon)} />
				}
				icon={<span className={classes.icon} />}
				{...props}
				name="shipping"
				style={{
					marginRight: '8px',
				}}
				onClick={() => {
					setPaymentMethod(props.method);
					if (props.method !== 'Carteira Melhor Envio') {
						dispatch(updateMelhorEnvioPaymentMethod(props.method));
					} else {
						dispatch(removeMelhorEnvioPaymentMethod());
					}
				}}
				checked={selectedPaymentMethod === props.method ? true : false}
			/>
		);
	}

	return (
		<>
			<InsertMelhorEnvioBalanceModal ref={insertBalanceModal} />
			{window.outerWidth < 768 ? <></> : 
				<Modal
					centered={true}
					open={state}
					onClose={() => setState(false)}
					onOpen={() => setState(true)}
					style={{ width: '35%', height: '42rem' }}
				>
				<ModalContainer>
					<ModalTop>
						<h1>Pagamento do frete</h1> 
					</ModalTop>
					<ModalMiddle>
						<span className="middle-title">
							Como você deseja pagar o frete do produto?
						</span>
						<div className="payment-options-container">
							<RadioGroup
								aria-label="gender"
								name="gender2"
								style={{ rowGap: '15px' }}
							>
								{posibleMethods.map(
									(item: string, index: number) => (
										<div
											className="payment-option"
											key={index}
										>
											<FormControlLabel
												control={
													<StyledRadio
														method={item}
													/>
												}
												label={
													<>
														<label className="option-label">
															{item}
														</label>
													</>
												}
											/>
											{item ===
												'Carteira Melhor Envio' && (
												<span className="melhor-envio-balance">
													Saldo:{' '}
													<strong>
														{' '}
														{!loading &&
															parseInt(
																data
																	?.checkUserBalance
																	.balance!
															).toLocaleString(
																'pt-br',
																{
																	style:
																		'currency',
																	currency:
																		'BRL',
																}
															)}{' '}
													</strong>{' '}
												</span>
											)}
										</div>
									)
								)}
							</RadioGroup>
						</div>
						<RemetenteContainer>
							<label>Remetente*</label>
							<input 
								type="text"
								onChange={(e)=>setNameRemetente(e.target.value)} 
								placeholder='Digite o remetente.'/>
							<span>*Adicione um nome de Remetente para adicionar na etiqueta de envio. Lembre-se que o mesmo deve ser do CPF cadastrado na conta.</span> 
						</RemetenteContainer>
					</ModalMiddle>
					<Modalbottom>
						<span
							onClick={() => {
								insertBalanceModal.current?.handleOpenModal();
								setState(false);
							}}
							className="add-balane-link"
						>
							Adicionar fundos a carteira
						</span>{' '}
						<button
							className={`next-button ${
								selectedPaymentMethod.length > 0 && nameRemetente
									? ''
									: 'disabled-button'
							}`}
							disabled={selectedPaymentMethod.length > 0 && nameRemetente ? false : true}
							ref={submitButton}
							onClick={() => {
								onClick();
								submitButton!.current!.disabled! = true;
								setLoaderVisible(true);
							}}
						>
							{!loaderIsVisible ? 'Continuar' : <Spinner />}
						</button>{' '}
					</Modalbottom>
				</ModalContainer>
			</Modal>
			}
		</>
	);
};

export default forwardRef(ChoosePaymentMethodMelhorEnvio);
