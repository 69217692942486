import React, { forwardRef, useState, useImperativeHandle, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'semantic-ui-react';
import { useCreateProductCategorieMutation, useCreateProductSubCategorieMutation } from '../../../generated/graphql';

import { Container, ModalTop, ModalMiddle, ModalBottom } from './Style';

export interface NewCategorieModalHandles {
    handleOpenModal: () => void
}

interface CreateCategorieValues {
    name:string
}

type Props = {
    areaTitle:string
    registerType:string
    categorieId?:number | null
}

const NewCategorieModal: React.RefForwardingComponent<NewCategorieModalHandles, Props> = (Props, ref) => {

    const [state, setState] = useState(false)

    const handleOpenModal = useCallback(() => {
		setState(true);
	}, []);

    useImperativeHandle(ref, () => {
		return {
			handleOpenModal,
		};
    });
    
    const [ createNewCategorie ] = useCreateProductCategorieMutation()

    const [ createNewSubCategorie ] = useCreateProductSubCategorieMutation()

    const { register , handleSubmit } = useForm<CreateCategorieValues>({ mode:"onSubmit" , reValidateMode:"onChange"})

    const onSubmit = async (data:any) => { 
        if (Props.registerType === 'categorie') {
            await createNewCategorie({variables:{name:data.name}}) 
        } else {
            await createNewSubCategorie({variables:{name:data.name , categorieId:Props!.categorieId!}})
        }

        window.location.reload()
    }

    return (
        <>
        <Modal
            open={state}
            onClose={() => setState(false)}
            onOpen={() => setState(true)}
            style={{ width: '562px', height: '199px' , borderRadius:'15px'}}
        >
        <Container>

            <form onSubmit={handleSubmit(onSubmit)}>
            <ModalTop>
                <h2 className="TitleCriarCategoria">Criar Categoria</h2>
            </ModalTop>
            <ModalMiddle>
                    <div className="modal-inputs">
                        <div className="input-container">
                            <label>Nome da Categoria</label>
                            <input
                                type="text"
                                {...register('name',{ required: true })}
                            />
                        </div>
                    </div>
            </ModalMiddle>
            <ModalBottom>
                <button onClick={() => setState(false)} className="negative-button">
                    Cancelar
                </button>
                <button className="positive-button" type="submit">
                    Concluir
                </button>
            </ModalBottom>
            </form>
            
        </Container>
        
        </Modal>
        </>
    );
}

export default forwardRef(NewCategorieModal);