import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Menu } from '@headlessui/react'
import { NotifyContainer } from './Style'
import logo_notification from '../../assets/icons/logo_notification.svg'
import IUserAccount from '../../models/IUserAccount'
import { AppState } from '../../store'
import { appSocket } from '../../appSocket'
import Events from '../../Events'
import Badge from '@material-ui/core/Badge'
import {
    INotification,
    PossibleNotifications,
} from '../NotificationsWrapper/NotificationsWrapper'
import { updateReadNotifications } from '../../actions/UserActions'
import useWindow from '../../hooks/useWindow'

const NotifyCard = () => {
    const window = useWindow()
    const user: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )

    const [notification, setNotifications] = useState<any>([])
    const not = PossibleNotifications
    const [quantityNotificationNotRead, setquantityNotificationNotRead] =
        useState(0)

    appSocket.on('LOAD_NOTIFICATIONS', (result: any) => {
        const notificationNotRead = result.filter((value: any) => !value.read)
        setquantityNotificationNotRead(notificationNotRead.length)
        setNotifications(
            result.map((value: any) => ({
                notification: value.notification,
                read: value.read,
                userAccountId: value.userAccountId,
                dataCriacao: new Date(value.createdAt),
            }))
        )
    })

    useEffect(() => {
        if (user) {
            appSocket.emit(Events.LOAD_NOTIFICATIONS, user.id)
        }
    }, [user])

    function handleReadNotification() {
        if (user) {
            try {
                setquantityNotificationNotRead(0)
                appSocket.emit(Events.SET_TRUE_VIEW_NOTIFICACAO, user.id)
            } catch (error: any) {
                console.log('error', error.message)
            }
        }
    }

    return (
        <Menu>
            <Menu.Button style={{ background: 'transparent', border: 'none' }}>
                {window.innerWidth > 1023 && (
                    <>
                        <Badge
                            badgeContent={quantityNotificationNotRead}
                            color="secondary"
                        >
                            <img
                                src={logo_notification}
                                alt="logo_notificações"
                                className="style-icon-notification"
                                onClick={() => handleReadNotification()}
                            />
                        </Badge>

                        <span className="style-span-notifications">
                            Notificações
                        </span>
                    </>
                )}
                {window.innerWidth > 767 && window.innerWidth < 1024 && (
                    <>
                        <Badge
                            badgeContent={quantityNotificationNotRead}
                            color="secondary"
                        >
                            <img
                                src={logo_notification}
                                alt="logo-notification"
                                className="icon-header-subtitle-notification"
                                onClick={() => handleReadNotification()}
                            />
                        </Badge>
                    </>
                )}
                {window.innerWidth < 768 && (
                    <>
                        <Badge
                            badgeContent={quantityNotificationNotRead}
                            color="secondary"
                        >
                            <img
                                src={logo_notification}
                                alt="icon-notification-mobile"
                                className="icon-notification-header-mobile"
                                onClick={() => handleReadNotification()}
                            />
                        </Badge>
                    </>
                )}
            </Menu.Button>
            <Menu.Items>
                <NotifyContainer>
                    {notification &&
                        notification.map((content: any, index: number) => (
                            <Menu.Item>
                                <div className="notification-card" key={index}>
                                    <div className="notification-title">
                                        {content.notification ===
                                        not.PurchaseStatus
                                            ? 'Compra:'
                                            : 'Produto:'}
                                    </div>
                                    <h1>{content.notification}</h1>
                                    <span>
                                        {content.dataCriacao.toLocaleDateString(
                                            'pt-BR',
                                            { timeZone: 'UTC' }
                                        )}
                                    </span>
                                </div>
                            </Menu.Item>
                        ))}
                </NotifyContainer>
            </Menu.Items>
        </Menu>
    )
}
export default NotifyCard
