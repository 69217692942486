import React, {
	useCallback,
	useState,
	useImperativeHandle,
	forwardRef,
	useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import IUserAccount from '../../../../../models/IUserAccount';
import { AppState } from '../../../../../store';
import { ModalBottom, ModalContainer, ModalMiddle, ModalTop } from './styles';
import { useForm } from 'react-hook-form';
import {
	useCheckUserBalanceQuery,
	useInsertUserBalanceLazyQuery,
	useInsertUserBalanceQuery,
} from '../../../../../generated/graphql';
import Spinner from '../../../../Loader/Spinner';

export interface ModalInsertMelhorEnvioBalanceHandles {
	handleOpenModal: () => void;
}
interface FormInputs {
	paymentMethod: string;
	value: string;
}

const InsertMelhorEnvioBalanceModal: React.ForwardRefRenderFunction<ModalInsertMelhorEnvioBalanceHandles> =
	({}, ref) => {
		const [state, setState] = useState(false);
		const handleOpenModal = useCallback(() => {
			setState(true);
		}, []);

		const user: IUserAccount = useSelector(
			(state: AppState) => state.user.currentUser
		);
		const {
			register,
			handleSubmit,
			formState: { errors },
		} = useForm<FormInputs>();
		const { data, loading } = useCheckUserBalanceQuery({
			variables: { userId: user.id },
			fetchPolicy: 'cache-and-network',
		});

		const [
			insertBalance,
			{ data: dataBalance, loading: loadingBalance, error },
		] = useInsertUserBalanceLazyQuery({});

		const [isLoading, setIsLoading] = useState(false);

		useImperativeHandle(ref, () => {
			return {
				handleOpenModal,
			};
		});
		const onSubmit = async (data: FormInputs) => {
			try {
				await insertBalance({
					variables: {
						userId: user.id,
						infos: {
							gateway: data.paymentMethod,
							redirect: `${process.env.REACT_APP_API_URL}/shopping/${user.id}/my-sales`,
							value: data.value,
						},
					},
				});
			} catch (err: any) {
				if (
					err.message.substring(15, err.message.length) ===
					'refresh token expired'
				) {
					window.location.href = `${process.env.REACT_APP_MELHOR_ENVIO_ENDPOINT}/oauth/authorize?client_id=${process.env.REACT_APP_MELHOR_ENVIO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_MELHOR_ENVIO_REDIRECT_URI}&response_type=code&scope=cart-read shipping-generate shipping-print shipping-tracking users-read shipping-calculate shipping-checkout shipping-generate shipping-preview shipping-print cart-write orders-read`;
				}
			}
		};
		useEffect(() => {
			if (dataBalance?.insertUserBalance && !loadingBalance) {
				window.open(dataBalance!.insertUserBalance!);
				window.location.reload();
			}
		}, [dataBalance, loadingBalance]);

		return (
			<>
				<Modal
					centered={true}
					open={state}
					onClose={() => setState(false)}
					onOpen={() => setState(true)}
					style={{ width: '35%', height: '34%' }}
					closeOnEscape={true}
					closeOnDimmerClick={true}
				>
					<ModalContainer onSubmit={handleSubmit(onSubmit)}>
						<ModalTop>
							<h2>Saldo</h2>
						</ModalTop>
						<ModalMiddle>
							<span className="balance">
								Saldo atual:{' '}
								<strong>
									{' '}
									{!loading &&
										parseInt(
											data?.checkUserBalance.balance!
										).toLocaleString('pt-br', {
											style: 'currency',
											currency: 'BRL',
										})}{' '}
								</strong>{' '}
							</span>
							<div className="input-container">
								<label htmlFor="payment-options-input">
									Selecione a forma de pagamento:{' '}
								</label>

								<select
									id="payment-options"
									{...register('paymentMethod', {
										required: true,
									})}
									className={`select ${
										errors.paymentMethod ? 'error' : ''
									}`}
								>
									<option value="">Selecione</option>
									<option value="mercado-pago">
										Mercado pago
									</option>
									<option value="pagseguro">PagSeguro</option>
									<option value="picpay">PicPay</option>
									<option value="moip">Moip</option>
								</select>
							</div>
							<div className="input-container">
								<label htmlFor="payment-options-input">
									Valor:{' '}
								</label>

								<input
									type="number"
									step="0.01"
									className={`input ${
										errors.value ? 'error' : ''
									}`}
									{...register('value', { required: true })}
								/>
							</div>
						</ModalMiddle>
						<ModalBottom>
							<span>
								*Ao confirmar você será redirecionado a uma
								página externa para concluir o pagamento
							</span>

							<div className="modal-actions">
								<button
									onClick={() => {
										setState(false);
									}}
									className="cancel-button"
								>
									Voltar
								</button>
								<button
									type="submit"
									className={`confirm-button ${
										isLoading ? 'disabled' : ''
									}`}
									onClick={() => setIsLoading(true)}
									disabled={isLoading}
								>
									{!isLoading ? 'Confirmar' : <Spinner />}
								</button>
							</div>
						</ModalBottom>
					</ModalContainer>
				</Modal>
			</>
		);
	};

export default forwardRef(InsertMelhorEnvioBalanceModal);
