import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import IUserAccount from '../../../models/IUserAccount';
import { AppState } from '../../../store';
import { Modal } from 'semantic-ui-react';
import {useGetUserProductBargainQuery, useCreateUserBargainMutation, useUpdateUserProductBargainMutation} from '../../../generated/graphql';
import AlertModal, { ModalHandles } from '../../AlertModal/AlertModal';
import IProductsCart from '../../../models/ICart';
import { addProductCartAction, removeProductCartAction} from '../../../actions/ProductsCartActions';
import { handleMoneyValues } from '../../../utils/HandleMoneyValues';
import { ModalContainer } from '../CreateBidModal/styles';

type props = {
	ProductMinPrice: number | null | undefined;
	product: any;
	productId: string;
};

const BargainModal: React.FC<props> = ({
	ProductMinPrice,
	productId,
	product,
}) => {
	const user: IUserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const [state, setState] = useState({ dimmer: undefined, open: false });
	const show = (dimmer: any) => setState({ dimmer: dimmer, open: true });
	const [offer, setOffer] = useState<string>();
	const { data, loading } = useGetUserProductBargainQuery({
		variables: {
			userId: user?.id,
			productId: Number.parseInt(productId),
		},
	});
	const [createOffer] = useCreateUserBargainMutation();
	const [updateOffer] = useUpdateUserProductBargainMutation();
	const [bargainMaskValue, setBargainMaskValue] = useState<any>();
	const history = useHistory();
	const [modalDescriptionArea, setModalDescriptionArea] = useState<string>('');
	const productsCart: IProductsCart[] = useSelector(
		(GlobalState: AppState) => GlobalState.ProductsCart.currentProductsCart
	);
	const modalRef = useRef<ModalHandles>(null);
	const dispatch = useDispatch();
	const putOnCartFunc = () => {
		if (user) {
			if (productsCart?.length > 0) {
				const result = productsCart.find(
					(productCart) =>
						productCart?.parameters?.product.id === product.id
				);
				if (!result) {
					dispatch(
						addProductCartAction({
							installments: 1,
							parameters: { product: product, quantity: 1 },
						})
					);
				} else {
					dispatch(removeProductCartAction(Number.parseInt(productId)));
					dispatch(
						addProductCartAction({
							installments: 1,
							parameters: { product: product, quantity: 1 },
						})
					);
				}
			} else {
				dispatch(
					addProductCartAction({
						installments: 1,
						parameters: { product: product, quantity: 1 },
					})
				);
			}
		}
	};

	const VerifiyOffer = async () => {
		if (data && !data?.getUserProductBargain) {
			if (Number.parseInt(bargainMaskValue!) >= ProductMinPrice!) {
				await createOffer({
					variables: {
						bargain: bargainMaskValue!,
						isValid: true,
						productId: Number.parseInt(productId),
						userId: user.id,
					},
				});
				putOnCartFunc();
				window.location.reload();
			} else {
				modalRef.current?.handleOpenModal();
			}
		} else {
			if (Number.parseInt(bargainMaskValue!) >= ProductMinPrice!) {
				await updateOffer({
					variables: {
						bargain: bargainMaskValue!,
						isValid: true,
						productId: Number.parseInt(productId),
						userId: user.id,
					},
				});
				putOnCartFunc();
				window.location.reload();
			} else {
				setModalDescriptionArea(
					'A oferta está abaixo do valor definido pelo vendedor'
				);
				modalRef.current?.handleOpenModal();
			}
		}
	};

	return (
		<>
			<AlertModal
				ref={modalRef}
				AreaTitle="Pechincha"
				DescriptionText="A oferta realizada está abaixo do valor minimo solicitado pelo vendedor"
				reload={false}
			/>
			{product.active ? (
				<>
				<div
					className={`button-organizer-chat ${user?.id === product?.UserAccount?.id ? 'disabled' : ''} `}
					onClick={() => {
						if (user) {
							if (user.id !== product.UserAccount.id) {
								show('blurring');
							}
						} else {
							history.push(
								`/login?next=/product-view/${productId}/all-data`
							);
						}
					} }
				>
					<span>Pechinchar</span>
				</div>
					<span style={{fontSize:'15px',fontFamily:'Poppins',color:'#6b98a6', fontWeight:550, paddingTop:'0.5rem'}}>Faça sua oferta!</span>
				</>
					) : (
					<></>
					)}
						<Modal
							dimmer={state.dimmer}
							open={state.open}
							onClose={() => state.open === false}
						>
							<ModalContainer>
								<div className="modal-title">
									<h2>Dê seu lance definitivo!</h2>
								</div>
								<div className="modal-input">
									<span>QUANTO DESEJA OFERECER POR ESTE PRODUTO?</span>
									<input
										type="text"
										onChange={(e) => setBargainMaskValue(
											handleMoneyValues(e.target.value)
										)}
										value={bargainMaskValue}
										maxLength={10} />
									<p>
										Se você arrematar o produto você será notificado
										para finalizar a compra
									</p>
								</div>
								<div className="modal-actions">
									<div
										className="negative-button"
										onClick={() => setState({
											dimmer: undefined,
											open: false,
										})}
									>
										<button>cancelar</button>
									</div>
									<div className="positive-button">
										<button onClick={() => VerifiyOffer()}>
											Enviar lance
										</button>
									</div>
								</div>
							</ModalContainer>
						</Modal>
					</>
	);
};
export default BargainModal;
