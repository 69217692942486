import React, {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useState,
} from 'react';

import { Container, TopButtons, Bottom, ModalContainer } from '../styles';

import mobileSearch from '../../../../assets/mobileIcons/mobileSearch.svg';
import { Modal } from 'semantic-ui-react';
import { ReactComponent as Exit } from '../../../../assets/icons/exit.svg';
import { ReactComponent as NorthWest } from '../../../../assets/icons/northWest.svg';
import { appSocket } from '../../../../appSocket';
import Events from '../../../../Events';
import { useHistory } from 'react-router';
import {
	ProductCategories,
	useGetAllProductCategoriesQuery,
} from '../../../../generated/graphql';

export interface MobileCategoriesModalHandles {
	showModal: () => void;
}

const MobileCategoriesModal: React.ForwardRefRenderFunction<MobileCategoriesModalHandles> = (
	{},
	ref
) => {
	const { data, loading } = useGetAllProductCategoriesQuery({
		fetchPolicy: 'cache-and-network',
	});
	const [state, setState] = useState(false);
	const [searchPreviewResult, setSearchResultPreview] = useState<string[]>(
		[]
	);
	const [search, setSearch] = useState('');

	useEffect(() => {
		if (search.length > 0) {
			appSocket.emit(Events.SEARCH_CATEGORIES, search);
		}
	}, [search]);

	useEffect(() => {
		appSocket.on(Events.RESULT_SEARCH_CATEGORIES, (result: string[]) => {
			setSearchResultPreview(result);
		});
		return () => {
			appSocket.off(Events.RESULT_SEARCH_CATEGORIES);
		};
	}, []);
	const history = useHistory();
	const verifySearchInput = () => {
		if (search.length > 0) {
			history.push(`/search/category/${search}`);
		}
	};
	useEffect(() => {
	}, [searchPreviewResult]);

	useImperativeHandle(ref, () => {
		return {
			showModal,
		};
	});
	const showModal = useCallback(() => {
		setState(true);
	}, [state]);
	return (
		<Modal
			style={{ width: '100%', height: '100%' }}
			open={state}
			onClose={() => setState(false)}
			onOpen={() => setState(true)}
		>
			<ModalContainer>
				<div className="top-container">
					<div className="icon-and-input">
						<div className="img-background">
							<img
								src={mobileSearch}
								alt=""
								onClick={() => verifySearchInput()}
							/>
						</div>
						<input
							type="text"
							placeholder="procurar categorias"
							onChange={(e) => setSearch(e.target.value)}
							value={search}
						/>
					</div>

					<Exit fill="black" onClick={() => setState(false)} />
				</div>
				<div className="bottom-container">
					{search.length < 0 ? (
						<>
							{!loading &&
								data?.getAllProductCategories.map(
									(item: ProductCategories) => (
										<div
											className="item"
											/* onClick={() =>
												history.push(
													`/search/category/${item}`
												)
											} */
										>
											<div
												className="container"
												onClick={() =>
													history.push(
														`/search/category/${item}`
													)
												}
											>
												<div className="fake-icon"></div>
												<span>{item.name}</span>
											</div>
											<NorthWest
												fill="black"
												className="icon"
												onClick={() =>
													setSearch(item.name)
												}
											/>
										</div>
									)
								)}
						</>
					) : (
						<>
							{searchPreviewResult.map((item: string) => (
								<div
									className="item"
									/* onClick={() =>
											history.push(
												`/search/category/${item}`
											)
										} */
								>
									<div
										className="container"
										onClick={() =>
											history.push(
												`/search/category/${item}`
											)
										}
									>
										<div className="fake-icon"></div>
										<span>{item}</span>
									</div>
									<NorthWest
										fill="black"
										className="icon"
										onClick={() => setSearch(item)}
									/>
								</div>
							))}{' '}
						</>
					)}
				</div>
			</ModalContainer>
		</Modal>
	);
};

export default forwardRef(MobileCategoriesModal);
