import { currentUsersState, UserAccountsListAction } from '../models/AllUsers';

const initialState: currentUsersState = {
	currentUsers: [],
};

export default function AllUsersReducer(
	state = initialState,
	action: UserAccountsListAction
) {
	switch (action.type) {
		case 'ADD_USERS_LIST':
			return { ...state, currentUsers: action.userAccountsList };
		case 'ADD_ONE_USER':
			return {
				...state,
				currentUsers: [...state.currentUsers, action.userAccounts],
			};

		default:
			return state;
	}
}
