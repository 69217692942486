import React from 'react';
import { Switch, Route } from 'react-router';
import RegisterProduct from '../../components/RegisterProduct/RegisterProduct';
import EditProduct from '../../components/EditProduct/EditProduct';
import Layout from '../../components/Layout/Layout';

const RegisterProductPage = () => {
	return (
		<Layout title="Produto - Registrar / Editar">
			<Switch>
				<Route exact path="/register-product/:categorieId/:type">
					<RegisterProduct />
				</Route>
				<Route
					exact
					path="/register-product/:categoryId/:productId/:type/edit"
				>
					<EditProduct />
				</Route>
			</Switch>
		</Layout>
	);
};
export default RegisterProductPage;
