import React, {
	forwardRef,
	useCallback,
	useState,
	useImperativeHandle,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Modal } from 'semantic-ui-react';
import { DescriptionText, TitleText } from './styles';
export interface ProductWarningModalHandles {
	OpenModal: () => void;
	handleCloseModal: () => void;
}
const  WarningModal = React.forwardRef<ProductWarningModalHandles>((props, ref) => {
	const [state, setState] = useState({ dimmer: undefined, open: false });
	const show = (_dimmer: any) => setState({ dimmer: undefined, open: true });
	const OpenModal = useCallback(() => {
		setState({ dimmer: undefined, open: true });
	}, []);
	const history = useHistory();
	const handleCloseModal = useCallback(() => {
		setState({ dimmer: undefined, open: true });
	}, [history]);

	useImperativeHandle(ref, () => {
		return {
			OpenModal,
			handleCloseModal
		};
	});

	return (
		
		<Modal
			open={state.open}
			onClose={() => setState({ dimmer: undefined, open: false })}
			onOpen={() => setState({ dimmer: undefined, open: true })}
			closeOnDimmerClick={false}
			closeOnEscape={false}
			closeOnTriggerBlur={false}
			closeOnTriggerClick={false}
			closeOnDocumentClick={false}
		>
			<Modal.Header>
				<TitleText> AVISO!</TitleText>
			</Modal.Header>
			<Modal.Content>
				<Modal.Description>
					<DescriptionText>
                        Confirmo que tenho 18 anos de idade ou mais, e desejo visualizar este produto.
					</DescriptionText>
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
			<Button onClick={() => history.goBack()}>
					Voltar
				</Button>
				<Button onClick={() => setState({ dimmer: undefined, open: false })}>
					Entendi e quero continuar
				</Button>
			</Modal.Actions>
		</Modal>
	);
});

export default WarningModal;
