import styled from 'styled-components'

export const SameCategoryPoducts = styled.div`
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: auto;
    padding-top: 30px;
    background-color: #fff;

    .similar-products {
        width: 82%;
        padding-top: 2rem;
        @media (min-width: 425px) and (max-width: 475px) {
            display: none;
        }
        @media (min-width: 375px) and (max-width: 424px) {
            display: none;
        }
        @media (min-width: 320px) and (max-width: 374px) {
            display: none;
        }
        h2 {
            color: #6b98a6;
            font-size: 14px;
            font-family: 'Poppins';
            font-weight: bold;

            @media screen and (max-width: 1366px) {
                font-size: 14px;
            }
        }
        h1 {
            color: #6b98a6;
            font-size: 20px;
            font-family: 'Poppins';
            font-weight: bold;
        }
    }

    .similar-products-list {
        margin-top: 3rem;
        display: flex;
        flex-direction: row;
        column-gap: 64px;
    }
`
export const Description = styled.div`
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: auto;
    padding-top: 30px;
    background-color: #fff;

    @media (min-width: 425px) and (max-width: 475px) {
        flex-direction: column;
        padding: 0;
        margin-left: 0;
        max-width: 425px;
    }
    @media (min-width: 375px) and (max-width: 424px) {
        flex-direction: column;
        padding: 0;
        margin-left: 0;
        max-width: 375px;
    }
    @media (min-width: 320px) and (max-width: 374px) {
        flex-direction: column;
        padding: 0;
        margin-left: 0;
        max-width: 320px;
    }

    h2 {
        color: #6b98a6;
        font-size: 20px;
        font-family: 'Poppins';
        font-weight: bold;
        display: flex;

        @media (min-width: 425px) and (max-width: 475px) {
            margin-top: 0;
            margin-bottom: 0;
        }
        @media (min-width: 375px) and (max-width: 424px) {
            margin-top: 2rem;
            margin-bottom: 0;
        }
        @media (min-width: 320px) and (max-width: 374px) {
            margin-top: 2rem;
            margin-bottom: 0;
        }
    }

    .descricao-container {
        max-width: 1024px;
        @media (min-width: 425px) and (max-width: 678px) {
            width: 90%;
            margin-top: 10rem;
        }
        @media (min-width: 375px) and (max-width: 424px) {
            width: 90%;
            margin-top: 12rem;
        }
        @media (min-width: 320px) and (max-width: 374px) {
            width: 90%;
            margin-top: 12rem;
        }
        .description-container-2 {
            padding: 1.5rem;

            @media (min-width: 425px) and (min-width: 768px) {
                margin-bottom: 0;
            }
            @media (min-width: 375px) and (min-width: 424px) {
                margin-bottom: 0;
            }
            @media (min-width: 320px) and (min-width: 374px) {
                margin-bottom: 0;
            }

            h2 {
                color: #6b98a6;
                font-size: 20px;
                font-family: 'Poppins';
                font-weight: bold;

                @media (min-width: 425px) and (max-width: 768px) {
                    margin-bottom: 0%;
                    padding-left: 2rem;
                    padding-top: 1rem;
                    margin-top: 0%;
                }
                @media (min-width: 375px) and (max-width: 424px) {
                    margin-bottom: 0%;
                    padding-left: 2rem;
                    padding-top: 1rem;
                    margin-top: 0%;
                }
                @media (min-width: 320px) and (max-width: 374px) {
                    margin-bottom: 0%;
                    padding-left: 2rem;
                    padding-top: 1rem;
                    margin-top: 0%;
                }
            }
            .list-organizer {
                display: flex;
                flex-direction: row;

                .StyleTitleDescription {
                    font-family: 'Poppins', semi-bold;
                    color: #6b98a6;
                    font-size: 15px;
                    font-weight: bold;
                }
                .styleDescriptionBlack {
                    font-size: 15px;
                    font-family: 'Poppins';
                    color: black;
                    font-weight: 300;
                }
                .StyleDescription {
                    font-family: 'Poppins';
                    color: black;
                    font-size: 14px;
                }
                .styleMarginLeft {
                    margin-left: 4px;
                }

                @media (min-width: 425px) and (max-width: 768px) {
                    flex-direction: column;
                }
                @media (min-width: 375px) and (max-width: 424px) {
                    flex-direction: column;
                }
                @media (min-width: 320px) and (max-width: 374px) {
                    flex-direction: column;
                }
                ul {
                    color: black;
                    font-size: 16px;
                    font-weight: bold;
                    width: 460px;

                    @media (min-width: 425px) and (max-width: 768px) {
                        width: 100%;
                    }
                    @media (min-width: 375px) and (max-width: 424px) {
                        width: 100%;
                    }
                    @media (min-width: 320px) and (max-width: 768px) {
                        width: 100%;
                    }

                    li {
                        color: black;
                        font-family: 'Poppins';
                        font-weight: 400;
                        font-size: 14px;
                        width: 100%;
                    }
                }
                .items-list-organizer {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                }
            }
            ul {
                color: #979797;
                font-size: 14px;
                @media screen and (max-width: 1366px) {
                    font-size: 14px;
                }
                @media (min-width: 425px) and (max-width: 768px) {
                    font-size: 14px;
                    font-weight: bolder;
                }
                @media (min-width: 375px) and (max-width: 424px) {
                    font-size: 14px;
                    font-weight: bolder;
                }
                @media (min-width: 320px) and (max-width: 374px) {
                    font-size: 14px;
                    font-weight: bolder;
                }
            }
        }
    }

    .similar-products {
        padding-top: 2rem;
        @media (min-width: 425px) and (max-width: 475px) {
            display: none;
        }
        @media (min-width: 375px) and (max-width: 424px) {
            display: none;
        }
        @media (min-width: 320px) and (max-width: 374px) {
            display: none;
        }
        h2 {
            color: #6b98a6;
            font-size: 14px;
            font-family: 'Poppins';
            font-weight: bold;

            @media screen and (max-width: 1366px) {
                font-size: 14px;
            }
        }
        h1 {
            color: #6b98a6;
            font-size: 20px;
            font-family: 'Poppins';
            font-weight: bold;
        }
    }

    .similar-products-list {
        margin-top: 3rem;
        display: flex;
        flex-direction: row;
        column-gap: 64px;
    }
`
