import React, { useState } from 'react';
import { uniqueId } from 'lodash';
import filesize from 'filesize';

import { Upload } from './Upload';
import { FileList } from './FileList';

import styled from './styles.module.css';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import IUserAccount from '../../models/IUserAccount';
import { useCreateBannerMutation } from '../../generated/graphql';

const AdmEditBanner = () => {
	const [uploadedFiles, setUploadedFiles] = useState([]);

	const user: IUserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);

	const [uploadBanner] = useCreateBannerMutation();

	const handleUpload = (file: any) => {
		//Pegar as informações do arquivo
		const uploadedFiles = file.map((file: any) => ({
			file,
			id: uniqueId(),
			name: file.name,
			readableSize: filesize(file.size),
			preview: URL.createObjectURL(file), //Gerar a url de preview antes da img chegar no servidor
			//Para o progress bar, not working yet:
			progress: 0,
			uploaded: false,
			error: false,
			url: null,
		}));
		setUploadedFiles(uploadedFiles);
		console.log(uploadedFiles);

		uploadedFiles.forEach(processUpload);
	};
	//Para mandar os arquivos para o back
	const processUpload = (uploadedFile: any) => {
		const data = new FormData();
		data.append('file', uploadedFile.file, uploadedFile.name);

		//Fazer o post para o server:
		uploadBanner({
			variables: {
				createBannerInput: {
					userId: user.id,
					fileName: uploadedFile.name,
				},
				Picture: uploadedFile.file,
			},
		});
	};

	return (
		<div className={styled.container}>
			<h1 className={styled.title}>Edite seu banner</h1>

			<div className={styled.content}>
				<h2 className={styled.subtitle}>
					Adicione as imagens que você quer colocar no seu banner:
				</h2>
				<Upload onUpload={handleUpload} />
				{!!uploadedFiles.length && <FileList files={uploadedFiles} />}
			</div>
		</div>
	);
};

export default AdmEditBanner;
