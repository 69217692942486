import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';

import { Container } from './styles';
import Cookies from '../../models/Cookies';
import { insertCookies } from '../../actions/CookiesAction';

const CookiesWarning: React.FC = () => {
	const cookies: Cookies = useSelector(
		(state: AppState) => state.cookies.currentAccept
	);
	const dispatch = useDispatch();
	return (
		<>
			{!cookies?.accept && (
				<Container>
					{' '}
					<div className="container">
						<p>
							Sambay express sobre cookies: a gente usa cookies
							para personalizar anúncios e melhorar a sua
							experiência no site. Ao continuar navegando, você
							concorda com a nossa nossa{' '}
							<a
								target="_blank"
								rel="noopener noreferrer"
								href={`${process.env.REACT_APP_STATIC_URL}/policy/termos.pdf`}
							>
								Politica de privacidade.
							</a>
						</p>
						<button onClick={() => dispatch(insertCookies(true))}>
							Continuar e fechar
						</button>
					</div>
				</Container>
			)}
		</>
	);
};

export default CookiesWarning;
