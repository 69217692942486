import styled from 'styled-components';

export const Container = styled.div`
	max-height: 500px;
	padding: 30px 45px 60px 35px;

	h1 {
		color: #6b97a5;
		font-size: 24px;
		font-family: 'Poppins', semi-bold;
	}

	ul {
		font-size: 14px;
		font-size: 'Poppins';
		margin-left: 20px;
		list-style-type: none;
	}
`;
