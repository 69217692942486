import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

import { GoBackContainer, MainContent } from './styles';

import back from '../../assets/icons/back.svg';

import { useSendConfirmationEmailMutation } from '../../generated/graphql';
import Layout from '../../components/Layout/Layout';
import { ToastContainer, toast } from 'react-toastify';

const AccountActivationWarning = () => {
	const [email, setEmail] = useState<string>('');

	const [sendConfirmationEmail] = useSendConfirmationEmailMutation();

	const history = useHistory();
	const location = useLocation<any>();

	useEffect(() => {
		setEmail(location.state.state.email);
	}, [location.state]);

	async function handleSendEmail() {
		if (email.length > 0) {
			try{
				await sendConfirmationEmail({
					variables: {
						email: email,
					},
				});
				toast.success('e-mail de confirmação enviado!',{
					position: "top-right",
					autoClose: 5000,
					type:'success',
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				})
			}catch(error:any){
				toast.error('Erro no envio do e-mail',{
					position: "top-right",
					autoClose: 5000,
					type:'error',
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				})
			}
			
		}
	}

	return (
		<Layout title="Ativação de Conta">
			<ToastContainer/>
			<GoBackContainer onClick={() => history.goBack()}>
				<img src={back} alt="" />
				<span>Voltar</span>
			</GoBackContainer>
			<MainContent>
				<h1>Ativação de conta</h1>
				<span>
					Você precisa ativar a sua conta através do link que enviamos
					para o seu email para entrar
				</span>
				<a onClick={handleSendEmail}>
					Não recebi o email de confirmação
				</a>
			</MainContent>
		</Layout>
	);
};

export default AccountActivationWarning;
