const invalidRedirects = new Set(
    process.env.REACT_APP_INVALID_REDIRECTS?.split(',')
)

export function handleLogout(redirect?: true): void {
    const { pathname } = window.location
    localStorage.clear()
    window.location.href =
        invalidRedirects.has(pathname) || redirect === undefined
            ? '/'
            : `/login?next=${pathname}`
}
