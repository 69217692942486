import React, {
    useCallback,
    useState,
    useImperativeHandle,
    forwardRef,
} from 'react'
import { useSelector } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import IUserAccount from '../../../models/IUserAccount'
import { AppState } from '../../../store'
import { ModalContainer, ModalTop, ModalMiddle, ModalBottom } from './styles'

export interface ModalMelhorEnvioWarningHandles {
    handleOpenModal: () => void
}

const MelhorEnvioWarningModal: React.ForwardRefRenderFunction<
    ModalMelhorEnvioWarningHandles
> = ({}, ref) => {
    const [open, setOpen] = useState(false)

    const handleOpenModal = useCallback(() => {
        setOpen(true)
    }, [])

    const user: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )

    const buildAuthorizationLink = () => {
        const authorizationEndpoint = `${process.env.REACT_APP_MELHOR_ENVIO_ENDPOINT}/oauth/authorize`
        const clientId = process.env.REACT_APP_MELHOR_ENVIO_CLIENT_ID
        const redirectUri = process.env.REACT_APP_MELHOR_ENVIO_REDIRECT_URI
        const userId = user.id
        const scope = [
            'cart-read',
            'shipping-generate',
            'shipping-print',
            'shipping-tracking',
            'users-read',
            'shipping-calculate',
            'shipping-checkout',
            'shipping-generate',
            'shipping-preview',
            'shipping-print',
            'cart-write',
            'orders-read',
        ]

        return `
			${authorizationEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope.join(
            ' '
        )}&state=${encodeURIComponent(JSON.stringify({ userId }))}
		`
    }

    useImperativeHandle(ref, () => {
        return { handleOpenModal }
    })

    return (
        <Modal
            centered={true}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            style={{ width: '35%', height: '60%' }}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <ModalContainer>
                <ModalTop>
                    <h1>Aviso</h1>
                </ModalTop>
                <ModalMiddle>
                    <p>
                        O Sambay Express utiliza o sistema do Melhor Envio! para
                        facilitar o envio dos seus produtos aos seus clientes.
                        Para cadastrar um produto é preciso entrar na sua conta
                        do melhor envio, utilizando suas credenciais do Sambay
                        Express, e aceitar os termos e condições de uso da
                        plataforma.
                    </p>
                    <p>
                        O saldo do melhor envio será utilizado para enviar os
                        seus produtos aos seus clientes
                    </p>
                </ModalMiddle>
                <ModalBottom>
                    <button className="close" onClick={() => setOpen(false)}>
                        Fechar
                    </button>
                    <button
                        className="link"
                        onClick={() =>
                            (window.location.href = buildAuthorizationLink())
                        }
                    >
                        Acessar o Melhor Envio
                    </button>
                </ModalBottom>
            </ModalContainer>
        </Modal>
    )
}

export default forwardRef(MelhorEnvioWarningModal)
