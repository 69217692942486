import {ProductsCartAction, ProductsCartState,} from '../models/ICart';

const initialState: ProductsCartState = {
	currentProductsCart: [],
};

export default function ProductsCartReducer(
	state = initialState,
	action: ProductsCartAction
) {
	switch (action.type) {
		case 'ADD_PRODUCT':
			if (!state.currentProductsCart) {
				state.currentProductsCart = [];
			}
			if (
				action?.productsCart?.parameters?.product?.UserBargains &&
				action?.productsCart?.parameters?.product?.UserBargains[0]
					?.bargain
			) {
				action.productsCart.parameters.product.price = parseInt(
					action.productsCart.parameters?.product?.UserBargains[0]
						?.bargain
				);
			}
			if (
				action?.productsCart?.parameters?.product?.typeNegotiation ===
				'Leilao'
			) {
				if (action?.productBids) {
					action.productsCart.parameters.product.price =
						action.productBids.bid;
				} else {
					if (typeof action.productsCart.parameters.product.InitialValue === "number") {
						action.productsCart.parameters.product.price =
							action.productsCart.parameters.product.InitialValue;
					}
				}
			}
			return {
				...state,
				currentProductsCart: [
					...state.currentProductsCart,
					action.productsCart,
				],
			};

		case 'INSERT_PRODUCT_SHIPPING_COST':
			return {
				...state,
				currentProductsCart: [
					...state.currentProductsCart.map((item) => {
						if (
							item?.parameters?.product?.id === action.productId
						) {
							return {
								...item,
								parameters: {
									...item.parameters,
									productShippingPrice: action.shippingCost,
								},
							};
						}
					}),
				],
			};

		case 'INSERT_PRODUCT_DELIVERY_DATE':
			return {
				...state,
				currentProductsCart: [
					...state.currentProductsCart.map((item) => {
						if (
							item?.parameters?.product?.id === action.productId
						) {
							return {
								...item,
								parameters: {
									...item.parameters,
									productDeliveryDate: action.deliveryDate,
								},
							};
						} else {
							return { ...item };
						}
					}),
				],
			};
		case 'INSERT_PRODUCT_SHIPPING_COST':
			return {
				...state,
				currentProductsCart: [
					...state.currentProductsCart.map((item) => {
						if (item?.parameters.product?.id === action.productId) {
							return {
								...item,
								parameters: {
									...item.parameters,
									productShippingPrice: action.shippingCost,
								},
							};
						} else {
							return { ...item };
						}
					}),
				],
			};
		case 'REMOVE_CART_PRODUCT':
			return {
				currentProductsCart: state.currentProductsCart.filter(
					(item) => item.parameters.product.id !== action.productId
				),
			};

		case 'INSERT_PRODUCT_QUANTITY':
			return {
				...state,
				currentProductsCart: [
					...state.currentProductsCart.map((item) => {
						if (
							item?.parameters?.product?.id === action.productId
						) {
							if (
								action.increase &&
								(item?.parameters?.product?.stock as any) >
									item?.parameters?.quantity
							) {
								return {
									...item,
									parameters: {
										...item.parameters,
										quantity: item.parameters.quantity + 1,
									},
								};
							} else if (
								!action.increase &&
								item.parameters.quantity > 0
							) {
								return {
									...item,
									parameters: {
										...item.parameters,
										quantity: item.parameters.quantity - 1,
									},
								};
							} else {
								return { ...item };
							}
						} else {
							return { ...item };
						}
					}),
				],
			};

		case 'INSERT_PRODUCT_INSTALLMENTS':
			return {
				...state,
				currentProductsCart: [
					...state.currentProductsCart.map((item) => {
						return {
							...item,
							installments: action.installments,
						};
					}),
				],
			};
		case 'INSERT_DISCOUNT_IN_PRICE_PRODUCT':
			return {
				...state,
				currentProductsCart: [
					...state.currentProductsCart.map((item)=>{
						if(item.parameters.product.id === action.productId){
							return{
								...item,
								parameters:{
									...item.parameters,
									product:{
										...item.parameters.product,
										price:action.priceWithDiscount
									},
									cupom:action.cupom
								}
							}
						}else{
							return{...item}
						}
					})
				]
			}

		case 'INSERT_PRODUCT_PAYMENT':
			return {
				...state,
				currentProductsCart: [
					...state.currentProductsCart.map((item) => {
						if (
							item?.parameters?.product?.id === action.productId
						) {
							return {
								...item,
								formOfPayment: action.paymentForm,
							};
						} else {
							return { ...item };
						}
					}),
				],
			};
		case 'INSERT_PRODUCT_SHIPPING_WAY':
			return {
				...state,
				currentProductsCart: [
					...state.currentProductsCart.map((item) => {
						if (
							item?.parameters?.product?.id === action.productId
						) {
							return {
								...item,
								parameters: {
									...item.parameters,
									shippingWay: action.shippingWay,
								},
							};
						} else {
							return { ...item };
						}
					}),
				],
			};

		case 'INSERT_PRODUCT_ADDRESS':
			return {
				...state,
				currentProductsCart: [
					...state.currentProductsCart.map((item) => {
						if (
							item?.parameters?.product?.id === action.productId
						) {
							return {
								...item,
								parameters: {
									...item?.parameters,
									address: action.address,
								},
							};
						} else {
							return { ...item };
						}
					}),
				],
			};

		case 'INSERT_SERVICE_ID':
			return {
				...state,
				currentProductsCart: [
					...state.currentProductsCart.map((item) => {
						if (
							item?.parameters?.product?.id === action.productId
						) {
							return {
								...item,
								parameters: {
									...item.parameters,
									service: {
										id: action.serviceId,
										custom_price: action.customPrice,
										delivery_time: action.deliveryTime,
										name: action.name,
									},
								},
							};
						} else {
							return { ...item };
						}
					}),
				],
			};
		default:
			return state;
	}
}
