import React, {useCallback, useRef, useState} from 'react';
import {ProfileAddress, useDeleteAddressMutation} from '../../../generated/graphql';
import {DataContainer} from './Style';
import cancel from '../../../assets/icons/cancel.svg';

import AlertModal, {ModalHandles} from "../../AlertModal/AlertModal";
import { ToastContainer } from 'react-toastify';

type Props = {
	address: ProfileAddress;
	handledeletarEndereco:(addressId:number)=>void
};
 
const UserAddressCard: React.FC<Props> = ({ address,handledeletarEndereco }) => {
	const [deleteAdress] = useDeleteAddressMutation()
	const [operationMessage, setOperationMessage] = useState<string>('');
	const [reload, setReload] = useState<boolean>(true);
	const modalAlertRef = useRef<ModalHandles>(null);

	const OpenModal = useCallback(
		(status: boolean, message: string, productId?: number) => {
			if (!status) {
				setOperationMessage(message.replace('GraphQL error:',''));
				setReload(false);
				modalAlertRef.current?.handleOpenModal();
			}
		},
		[]
	);

	async function deleteEndereco(){
		if(address){
			handledeletarEndereco(address.id)
		}

	}
	
	return (
		<>
		 <ToastContainer/>
			<AlertModal
				ref={modalAlertRef}
				AreaTitle={'Atualização de Endereço'}
				DescriptionText={operationMessage}
				reload={reload}
			/>
			<DataContainer>
				<div className="org">
					<span className="address-title">
						{address.addressTitle}
					</span>
					<img
						src={cancel}
						id="edit"
						className="address-delete"
						onClick={deleteEndereco}
					/>
					<div className="first-line">
						<div className='first-section'>
							<label>Endereço*</label>
							<div className="title-input-container">
								<input
									type="text"
									required
									defaultValue={address?.street as any}
									disabled
								/>
							</div>
						</div>
						<div className='first-section'>
							<label>CEP*</label>
							<div className="title-input-container">
								<input
									type="text"
									required
									defaultValue={address?.CEP as any}
									disabled
								/>
							</div>
						</div>
					</div>
					<div className="second-line">
						<div className='first-section'>
							<label>Número*</label>
							<div className="small-input-container">
								<input
									type="text"
									required
									defaultValue={address?.number as any}
									disabled
								/>
							</div>
						</div>
						<div className='first-section'>
							<label>Complemento*</label>
							<div className="small-input-container">
								<input
									type="text"
									defaultValue={address?.complement as any}
									disabled
								/>
							</div>
						</div>
					</div>
				</div>
			</DataContainer>
		</>
	);
};

export default UserAddressCard;
