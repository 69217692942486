import React, {forwardRef, useState, useCallback, useImperativeHandle, useEffect} from 'react'

import { Modal } from 'semantic-ui-react';
import { ModalContainer, ModalTop, ModalMiddle, ModalBottom } from './styles'
import {
    Bling,
    GetBlingAccountQuery,
    useCreateBlingCountMutation,
    useGetBlingAccountQuery
} from "../../../generated/graphql";
import IUserAccount from "../../../models/IUserAccount";
import {useSelector} from "react-redux";
import {AppState} from "../../../store";
import {ApolloClient, ApolloError} from "apollo-boost";

export interface ModalBlingWarningHandles {
    handleOpenModal: () => void;
}

const BlingWarningModal: React.ForwardRefRenderFunction<ModalBlingWarningHandles> =
    ({},ref) => {

        const [state, setState] = useState(false);
        const [blingAcount,setBlingAcount] = useState<GetBlingAccountQuery>()
        const [apikeyBling, setApiKeyBling] = useState('')
        const [createBlingCount,{data:createBlingResponse,loading}]  = useCreateBlingCountMutation()
        const user: IUserAccount = useSelector((state: AppState) => state.user.currentUser);

        const {data:getBlingAccountResponse,loading:getBlingAccountLoading} = useGetBlingAccountQuery({
            variables:{
                getBlingAccountInputData:{
                    userId:user.id
                }
            }
        })
        const handleOpenModal = useCallback(() => {setState(true)}, []);

        useImperativeHandle(ref, () => {
			return { handleOpenModal };
		});

        async function salvarApiKeyBling(){
            if(apikeyBling){
                try{
                    await createBlingCount({
                        variables:{
                            createBlingCountInputData:{
                                userAccountId:user.id,
                                apiKey:apikeyBling
                            }
                        }})
                }catch(err: any){
                    console.log('Error',err.message)
                }finally {
                    window.location.reload();
                }

            }
        }

        useEffect(()=>{
            if(getBlingAccountResponse){
                setBlingAcount(getBlingAccountResponse)
            }
        },[getBlingAccountResponse])

    return (
       <Modal
           centered={true}
           open={state}
           onClose={() => setState(false)}
           onOpen={() => setState(true)}
           style={{ width: '35%', height: '60%' }}
           closeOnEscape={false}
           closeOnDimmerClick={false}
       >
           <ModalContainer>
               <ModalTop>
                   <h1>Integração Bling</h1>
               </ModalTop>
               {getBlingAccountResponse?.getBlingAccount?.apiKey && (
                   <>
                       <ModalMiddle>
                           <p>Chave conectada ultimamente</p>
                           <input
                               type="text"
                               value={getBlingAccountResponse?.getBlingAccount?.apiKey}
                               onChange={(e)=>{
                                   setApiKeyBling(e.target.value)
                               }}/>
                       </ModalMiddle>
                       <ModalBottom>
                           <button
                               className="close"
                               onClick={() => setState(false)}
                           >
                               Fechar
                           </button>
                           <button
                               disabled={true}
                               className="link">
                               Acessar Bling
                           </button>
                       </ModalBottom>
                   </>
               )

               }
               {!getBlingAccountResponse?.getBlingAccount?.apiKey && (
                   <>
                       <ModalMiddle>
                           <p>Informe a apiKey da sua conta do Bling</p>
                           <input
                               type="text"
                               onChange={(e)=>{
                                   setApiKeyBling(e.target.value)
                               }}/>
                       </ModalMiddle>
                       <ModalBottom>
                           <button
                               className="close"
                               onClick={() => setState(false)}
                           >
                               Fechar
                           </button>
                           <button
                               disabled={apikeyBling.length === 0}
                               onClick={salvarApiKeyBling}
                               className="link">
                               Acessar Bling
                           </button>
                       </ModalBottom>
                   </>
               )
               }
           </ModalContainer>
       </Modal>
    )
}

export default forwardRef(BlingWarningModal)