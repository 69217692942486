import React from 'react';
import Layout from '../../components/Layout/Layout';
import UserRegister from '../../components/User/UserRegister';
import { Container } from './Style';

const RegisterPage = () => {
	return (
		<Layout title="Criar Conta">
			<UserRegister />
		</Layout>
	);
};
export default RegisterPage;
