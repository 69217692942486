import styled from 'styled-components'

export const SliderContainer = styled.div`
    .alice-carousel__prev-btn,
    .alice-carousel__next-btn {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        position: absolute;
        top: 11%;
        left: 100%;
        height: 80px;
        width: 43px;
        background-color: #ffff;
        opacity: 8.3;
        text-align: unset;
        cursor: pointer;
        @media (max-width: 478px) {
            display: none;
        }
        @media (min-width: 320px) and (max-width: 375px) {
            display: none;
        }

        @media (min-width: 768px) and (max-width: 889px) {
            top: 20%;
            height: 100px;
            width: 3rem;
            background-color: #ffffff;
            opacity: 6.3;
        }
    }

    .alice-carousel__prev-btn {
        left: -45px;
        border-radius: 3.8rem 0 0 3.8rem;
        box-shadow: 4px 3px 9px #00000014;
    }

    .alice-carousel__next-btn {
        right: 2;
        border-radius: 0 3.8rem 3.8rem 0;
        box-shadow: -4px 3px 9px #00000014;
    }
`
