import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 8px;
`

export const Back = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 135px;
    padding-right: 135px;
    background-color: #fff;
    padding-top: 40px;

    span {
        font-family: 'Poppins';
        color: #6b98a6;
        font-size: 20px;
        font-weight: 600;
    }
    span:hover {
        cursor: pointer;
    }
    img:hover {
        cursor: pointer;
    }

    @media (min-width: 425px) and (max-width: 767px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 1rem;
    }
    @media (min-width: 375px) and (max-width: 424px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 1rem;
        span {
            font-size: 18px;
        }
    }
    @media (min-width: 320px) and (max-width: 374px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 1rem;
        span {
            font-size: 15px;
        }
    }
`
export const ProductOffersContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 53px;
    align-items: center;
    width: 100%;
    background-color: #fff;

    div {
        width: 1010px;
        h2 {
            color: #6b98a6;
            font-family: 'Poppins', semi-bold;
            font-weight: 600;
            font-size: 20px;
        }
        .offers {
            display: flex;
            flex-direction: row;
            margin-bottom: 30px;

            div {
                margin-right: 115px;

                span {
                    color: black;
                    font-family: 'Poppins', regular;
                    font-size: 14px;
                }
            }
        }
    }
`

export const QuestionsCard = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 53px;
    background-color: #fff;

    .resp {
        margin-left: 101px;
        color: black;
        font-family: 'Poppins', semi-bold;
        font-weight: 600;
        font-size: 17px;
        margin-top: 10px;
    }

    .question-container {
        width: 1010px;
        div {
            margin-bottom: 0 !important;
            margin-bottom: 54px;
            h1 {
                color: #6b98a6;
                font-size: 17px;
                font-family: 'Poppins', semi-bold;
                font-weight: 600;
                margin-bottom: 30px;
            }
        }
        .make-question {
            display: flex;
            flex-direction: column;
            h2 {
                color: black;
                font-family: 'Poppins';
                font-weight: 400;
                font-size: 15px;
            }

            textarea {
                width: 959px;
                height: 50px;
                border: 1px solid black;
                border-radius: 6px;
                resize: none;
                color: black;
                padding: 10px;
                font-size: 14px;
                font-family: 'Poppins', regular;
                background-color: #fff;
            }
            textarea:focus,
            button:focus {
                outline: none;
            }
            button:hover {
                cursor: pointer;
            }
            button {
                width: 160px;
                height: 33px;
                background: #6b98a6 0% 0% no-repeat padding-box;
                border-radius: 19px;
                border: none;
                margin-top: 15px;
                margin-bottom: 15px;
                color: white;
                font-size: 13px;
                font-family: 'Poppins', semi-bold;
                font-weight: 600;
            }

            .rating-stars {
                display: flex;
                flex-direction: column;
                align-items: center;

                button:hover {
                    cursor: pointer;
                }
                button {
                    width: 160px;
                    height: 33px;
                    background: #6b98a6 0% 0% no-repeat padding-box;
                    border-radius: 19px;
                    border: none;
                    margin-top: 15px;
                    margin-bottom: 15px;
                    color: white;
                    font-size: 13px;

                    font-family: 'Poppins', semi-bold;
                    font-weight: 600;
                }

                .star {
                    height: 16px;
                    width: 22px;
                    .a {
                        stroke: #ff7e0f;
                        fill: white;
                    }
                }
                .star-active {
                    height: 16px;
                    width: 22px;
                    .a {
                        stroke: #ff7e0f;
                        fill: #ff7e0f;
                    }
                }
            }
        }

        .questions-card {
            margin-top: 10px;
            width: 100%;
            .question {
                display: flex;
                flex-direction: row;
                margin-bottom: 26px !important;
                margin-top: 40px;

                .user-img {
                    margin: 0;
                    img {
                        height: 60px;
                        width: 60px;
                        border-radius: 65px;
                    }
                }
                .question-content {
                    display: flex;
                    flex-direction: column;
                    margin-left: 18px;

                    .make-question {
                        display: flex;
                        flex-direction: row;
                        h2 {
                            color: #6b98a6;
                            font-family: 'Poppins', semi-bold;
                            font-weight: 600;
                            font-size: 16px;
                        }

                        textarea {
                            width: 959px;
                            height: 127px;
                            border: 1px solid black;
                            border-radius: 6px;
                            resize: none;
                            color: black;
                            padding: 10px;
                            font-size: 14px;
                            font-family: 'Poppins', regular;
                            background-color: #fff;
                        }
                        textarea:focus,
                        button:focus {
                            outline: none;
                        }
                    }

                    .button-container {
                        margin-top: 15px;

                        button {
                            color: white;
                            background-color: #6b98a6;
                            border-radius: 19px;
                            border: none;
                            width: 219px;
                            height: 37px;
                            font-family: 'Poppins', semi-bold;
                            font-weight: 600;
                            font-size: 17px;
                            margin-top: 15px;
                            margin-bottom: 20px;
                        }
                        button:focus {
                            outline: none;
                        }
                        button:hover {
                            cursor: pointer;
                        }
                        .mark {
                        }
                    }

                    .question-infos {
                        display: flex;
                        flex-direction: row;
                        margin-bottom: 17px;
                        align-items: center;
                        h2 {
                            margin: 0;
                            color: #6b98a6;
                            font-family: 'Poppins', semi-bold;
                            font-weight: 600;
                            font-size: 17px;
                            margin-right: 8px;
                        }
                        span {
                            margin-left: 25px;
                            font-family: 'Poppins', medium;
                            font-weight: 500;
                            color: black;
                            padding: 5px;
                            border-radius: 7px;
                            &:hover {
                                cursor: pointer;
                            }
                        }

                        label {
                            margin-left: 25px;
                            font-family: 'Poppins', medium;
                            font-weight: 500;
                            color: black;
                        }
                        .edit-button {
                            margin-left: 25px;
                            font-family: 'Poppins', medium;
                            font-weight: 500;
                            color: white;
                            padding: 5px;
                            border-radius: 7px;
                            background-color: #04aac0;
                            &:hover {
                                cursor: pointer;
                            }
                        }
                        .delete-button {
                            margin-left: 25px;
                            font-family: 'Poppins', medium;
                            font-weight: 500;
                            color: white;
                            padding: 5px;
                            border-radius: 7px;
                            background-color: red;
                            &:hover {
                                cursor: pointer;
                            }
                        }
                        .cancel-button {
                            font-family: 'Poppins', medium;
                            font-weight: 500;
                            color: white;
                            padding: 5px;
                            border-radius: 7px;
                            background-color: red;
                            &:hover {
                                cursor: pointer;
                            }
                        }
                        .salvar-button {
                            margin-left: 25px;
                            font-family: 'Poppins', medium;
                            font-weight: 500;
                            color: white;
                            padding: 5px;
                            border-radius: 7px;
                            background-color: #69b64a;
                            &:hover {
                                cursor: pointer;
                            }
                        }
                        .options {
                            span {
                                display: none;
                            }
                        }
                        .options:hover {
                            span {
                                visibility: visible;
                            }
                        }
                        .star1 {
                            .a {
                                stroke: #ff7e0f;
                                fill: white;
                            }
                        }
                        .star-active {
                            .a {
                                stroke: #ff7e0f;
                                fill: #ff7e0f;
                            }
                        }
                        #star1,
                        #star2,
                        #star3,
                        #star4,
                        #star5 {
                            .a {
                                stroke: #ff7e0f;
                                fill: white;
                            }
                        }
                        #star-active {
                            .a {
                                stroke: #ff7e0f;
                                fill: #ff7e0f;
                            }
                        }

                        #star1:hover {
                            cursor: pointer;
                            .a {
                                fill: #ff7e0f;
                            }
                        }
                        #star2:hover,
                        #star3:hover,
                        #star4:hover,
                        #star5:hover {
                            .a {
                                fill: #ff7e0f;
                            }
                        }

                        .avaliations {
                            .star {
                                height: 16px;
                                width: 22px;
                                .a {
                                    stroke: #ff7e0f;
                                    fill: white;
                                }
                            }
                            .star-active {
                                height: 16px;
                                width: 22px;
                                .a {
                                    stroke: #ff7e0f;
                                    fill: #ff7e0f;
                                }
                            }
                        }
                    }
                    .question-description {
                        color: black;
                        font-family: 'Poppins', regular;
                        font-size: 16px;
                        width: 580px;
                        word-break: break-word;
                        margin-top: 20px;

                        textarea {
                            /* margin-top: 20px; */
                            width: 959px;
                            height: 50px;
                            border: 1px solid black;
                            border-radius: 6px;
                            resize: none;
                            color: black;
                            /* padding: 10px; */
                            padding: 0px;
                            font-size: 16px;
                            font-family: 'Poppins', regular;
                            background: transparent;
                        }
                    }
                }
            }
            .answer {
                display: flex;
                flex-direction: row;
                margin-left: 69px;
                margin-top: 30px;
                margin-bottom: 20px !important;
                .user-img {
                    margin: 0;
                    img {
                        height: 85px;
                        width: 85px;
                        border-radius: 65px;
                    }
                }
                .answer-content {
                    display: flex;
                    flex-direction: column;
                    margin-left: 18px;

                    .answer-infos {
                        display: flex;
                        flex-direction: row;
                        margin-bottom: 17px;
                        align-items: center;
                        justify-content: space-between;

                        .options {
                            display: none;

                            span:hover {
                                cursor: pointer;
                            }
                        }

                        .infos {
                            display: flex;
                            flex-direction: row;
                        }
                        h2 {
                            margin: 0;
                            color: #6b98a6;
                            font-family: 'Poppins', semi-bold;
                            font-weight: 600;
                            font-size: 17px;
                        }
                        span {
                            margin-left: 25px;
                            font-family: 'Poppins', medium;
                            font-weight: 500;
                            color: black;
                        }
                    }
                    .answer-description {
                        textarea {
                            color: black;
                            font-family: 'Poppins', regular;
                            font-size: 14px;
                            resize: none;
                            width: 580px;
                            word-break: break-word;
                            border: none;
                        }
                        textarea:focus {
                            outline: none;
                        }
                    }
                }
            }
            .answer:hover {
                .answer-infos {
                    .options {
                        display: flex;
                    }
                }
            }
        }
    }
`
