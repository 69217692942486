import React, { useEffect, useState } from 'react'
import 'quill/dist/quill.snow.css'

import { Product } from '../../../generated/graphql'
import { useHistory, useLocation } from 'react-router-dom'
import { Description } from './Style'

type Props = {
    product: Product
}

const ProductDescription: React.FC<Props> = ({ product }) => {
    const history = useHistory()
    const { pathname } = useLocation()
    const [diasRestantes, setDiasRestantes] = useState(0)

    useEffect(() => {
        if (product && product.deadline && product.deadline > 0) {
            setDiasRestantes(product.deadline)
        } else {
            setDiasRestantes(0)
        }
    }, [product])

    const ValidCategory = () => {
        if (
            (product && product.typeNegotiation === 'Venda') ||
            (product && product.typeNegotiation === 'Promoção')
        ) {
            return (
                <>
                    <div className="list-organizer">
                        <ul>
                            <li>
                                <span className="StyleTitleDescription">
                                    {'Categoria: '}
                                    <span className="styleDescriptionBlack">
                                        {`${product?.ProductCategories?.name}`}
                                    </span>
                                </span>
                            </li>
                        </ul>
                        <div className="items-list-organizer">
                            <ul style={{ marginBottom: '25px' }}>
                                <li>
                                    <span className="StyleTitleDescription">
                                        {'Subtítulo: '}
                                        <span className="styleDescriptionBlack">
                                            {`${product?.subtitle}`}
                                        </span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="list-organizer">
                        <ul>
                            <li>
                                <span className="StyleTitleDescription">
                                    {'Dimensões: '}
                                    <span className="styleDescriptionBlack">
                                        {`
                                            ${product?.product_height} x
                                            ${product?.product_width} x
                                            ${
                                                product?.product_length
                                            } Centímetros e
                                            ${
                                                product.product_weight &&
                                                product.product_weight < 1 &&
                                                product.product_weight > 0
                                                    ? `${
                                                          product.product_weight *
                                                          1000
                                                      } Gramas`
                                                    : `${product.product_weight} Kilogramas`
                                            }
                                            
                                        `}
                                    </span>
                                </span>
                            </li>
                        </ul>
                        <div className="items-list-organizer">
                            <ul style={{ marginBottom: '25px' }}>
                                <li>
                                    <span className="StyleTitleDescription">
                                        {'Material: '}
                                        <span className="styleDescriptionBlack">
                                            {`${product?.material}`}
                                        </span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="list-organizer">
                        <ul>
                            <li>
                                <span className="StyleTitleDescription">
                                    {'Marca: '}
                                    <span className="styleDescriptionBlack">
                                        {product.VehicleInformations
                                            ? `${product.VehicleInformations.vehicleBrand}`
                                            : `${product?.brand}`}
                                    </span>
                                </span>
                            </li>
                        </ul>
                        <div className="items-list-organizer">
                            <ul style={{ marginBottom: '25px' }}>
                                {product &&
                                product.stock &&
                                (product.deadline === null ||
                                    product.deadline === 0) &&
                                product.stock > 0 ? (
                                    <li>
                                        <span className="StyleTitleDescription">
                                            {'Disponibilidade: '}
                                        </span>
                                        <span className="styleMarginLeft">
                                            {'Pronta entrega'}
                                        </span>
                                    </li>
                                ) : (
                                    <>
                                        <li>
                                            <span className="StyleTitleDescription">
                                                {`Previsão para disponibilidade do produto: `} 
                                            </span>
                                            {`${diasRestantes} dias.`}
                                        </li>
                                        <span className="tw-text-sm">
                                            {`Estou ciente que o produto não está
                                            disponível para envio imediato,
                                            conforme descrito no anúncio!`}
                                        </span>
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="list-organizer">
                        <ul>
                            {product &&
                                product.maxInstallments &&
                                product?.maxInstallments > 1 && (
                                    <li>
                                        <span className="StyleTitleDescription">
                                            {'Parcele em: '}
                                        </span>
                                        <span className="styleMarginLeft">
                                            {`${product?.maxInstallments}x`}
                                        </span>
                                    </li>
                                )}
                            {product &&
                                product.maxInstallments &&
                                product?.maxInstallments <= 1 && (
                                    <li>
                                        <span className="StyleTitleDescription">
                                            {'Pagamento: '}
                                        </span>
                                        <span className="styleMarginLeft">
                                            {'Pague à Vista'}
                                        </span>
                                    </li>
                                )}
                        </ul>
                        <div className="items-list-organizer">
                            <ul style={{ marginBottom: '25px' }}>
                                <li>
                                    <span className="StyleTitleDescription">
                                        {'Condição: '}
                                        <span className="styleDescriptionBlack">
                                            {`${product.useState}`}
                                        </span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="list-organizer">
                        <ul>
                            <li>
                                <span className="StyleTitleDescription">
                                    {'Retirada com vendedor: '}
                                    <span className="styleDescriptionBlack">
                                        {`${product?.withdraw}`}
                                    </span>
                                </span>
                            </li>
                        </ul>
                        <div className="items-list-organizer">
                            <ul>
                                <li>
                                    <span className="StyleTitleDescription">
                                        {'Estoque: '}
                                        <span className="styleDescriptionBlack">
                                            {`${product?.stock}`}
                                        </span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </>
            )
        }
        return <></>
    }

    useEffect(() => {
        if (product && pathname === `/product-view/${product.id}/all-data`) {
            const newTitle = product.title
                .toLocaleLowerCase()
                .replaceAll(/\s+/g, '-')
                .slice(0, 50)
            const newPath = `${pathname}/${newTitle}`
            history.push(newPath)
        }
    }, [history, pathname, product])

    return (
        <>
            <Description id="descricao">
                <div className="descricao-container">
                    <div className="description-container-2">
                        {(product && product.typeNegotiation === 'Venda') ||
                            (product &&
                                product.typeNegotiation === 'Promoção' && (
                                    <h2>Detalhes do Produto</h2>
                                ))}
                        {product && product.typeNegotiation === 'Veiculos' && (
                            <h2>Detalhes do Veículo</h2>
                        )}
                        {product && product.typeNegotiation === 'Imoveis' && (
                            <h2>Detalhes do Imóvel</h2>
                        )}
                        <div className="list-organizer">
                            {product && product.typeNegotiation === 'Veiculos' && (
                                <>
                                    <div className="list-organizer">
                                        <ul>
                                            <li>
                                                <span className="StyleTitleDescription">
                                                    {'Categoria: '}
                                                </span>
                                                {`${product?.ProductCategories?.name}`}
                                            </li>
                                        </ul>
                                        <div className="items-list-organizer">
                                            <ul
                                                style={{ marginBottom: '25px' }}
                                            >
                                                <li>
                                                    <span className="StyleTitleDescription">
                                                        {'Ano: '}
                                                    </span>
                                                    {`${product?.VehicleInformations?.year}`}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </>
                            )}
                            {product && product.typeNegotiation === 'Imoveis' && (
                                <>
                                    <div className="list-organizer">
                                        <ul>
                                            <li>
                                                <span className="StyleTitleDescription">
                                                    {'Categoria: '}
                                                </span>
                                                {`${product?.ProductCategories?.name}`}
                                            </li>
                                        </ul>
                                        <div className="items-list-organizer">
                                            <ul
                                                style={{ marginBottom: '25px' }}
                                            >
                                                <li>
                                                    <span className="StyleTitleDescription">
                                                        {'CEP: '}
                                                    </span>
                                                    {`${product?.PropertyInformations?.cep}`}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <ValidCategory />
                        {product && product.typeNegotiation === 'Veiculos' && (
                            <>
                                <>
                                    <div className="list-organizer">
                                        <ul style={{ marginBottom: '25px' }}>
                                            <li>
                                                <span className="StyleTitleDescription">
                                                    {'Modelo: '}
                                                </span>
                                                {`${product?.VehicleInformations?.model}`}
                                            </li>
                                        </ul>
                                        <div className="items-list-organizer">
                                            <ul
                                                style={{ marginBottom: '25px' }}
                                            >
                                                <li>
                                                    <span className="StyleTitleDescription">
                                                        {'Quitado: '}
                                                    </span>
                                                    {`${
                                                        product &&
                                                        product
                                                            .VehicleInformations
                                                            ?.quitado &&
                                                        product
                                                            .VehicleInformations
                                                            .quitado
                                                            ? 'Sim'
                                                            : 'Não'
                                                    }`}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="list-organizer">
                                        <ul style={{ marginBottom: '25px' }}>
                                            <li>
                                                <span className="StyleTitleDescription">
                                                    {'Potência do motor: '}
                                                </span>
                                                {`${product?.VehicleInformations?.motorPower}`}
                                            </li>
                                        </ul>
                                        <div className="items-list-organizer">
                                            <ul
                                                style={{ marginBottom: '25px' }}
                                            >
                                                <li>
                                                    <span className="StyleTitleDescription">
                                                        {'Quilometragem: '}
                                                    </span>
                                                    {`${product?.VehicleInformations?.mileage}`}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="list-organizer">
                                        <ul style={{ marginBottom: '25px' }}>
                                            <li>
                                                <span className="StyleTitleDescription">
                                                    {'Combustível: '}
                                                </span>
                                                {`${product?.VehicleInformations?.fuel}`}
                                            </li>
                                        </ul>
                                        <div className="items-list-organizer">
                                            <ul
                                                style={{ marginBottom: '25px' }}
                                            >
                                                <li>
                                                    <span className="StyleTitleDescription">
                                                        {'Placa: '}
                                                    </span>
                                                    {`${product?.VehicleInformations?.licensePlate}`}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="list-organizer">
                                        <ul style={{ marginBottom: '25px' }}>
                                            <li>
                                                <span className="StyleTitleDescription">
                                                    {'Cor: '}
                                                </span>
                                                {`${product?.VehicleInformations?.color}`}
                                            </li>
                                        </ul>
                                        <div className="items-list-organizer">
                                            <ul
                                                style={{ marginBottom: '25px' }}
                                            >
                                                <li>
                                                    <span className="StyleTitleDescription">
                                                        {'Portas: '}
                                                    </span>
                                                    {`${product?.VehicleInformations?.numberDoors}`}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="list-organizer">
                                        <ul style={{ marginBottom: '25px' }}>
                                            <li>
                                                <span className="StyleTitleDescription">
                                                    {'Direção: '}
                                                </span>
                                                {`${product?.VehicleInformations?.directionType}`}
                                            </li>
                                        </ul>
                                        <div className="items-list-organizer">
                                            <ul
                                                style={{ marginBottom: '25px' }}
                                            >
                                                <li>
                                                    <span className="StyleTitleDescription">
                                                        {'Câmbio: '}
                                                    </span>
                                                    {`${product?.VehicleInformations?.exchange}`}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {!product?.VehicleInformations?.quitado && (
                                        <div className="list-organizer">
                                            <ul
                                                style={{ marginBottom: '25px' }}
                                            >
                                                <li>
                                                    <span className="StyleTitleDescription">
                                                        {'Valor Restante: '}
                                                    </span>
                                                    {`${product?.VehicleInformations?.valorRestante}`}
                                                </li>
                                            </ul>
                                            <div className="items-list-organizer">
                                                <ul
                                                    style={{
                                                        marginBottom: '25px',
                                                    }}
                                                >
                                                    <li>
                                                        <span className="StyleTitleDescription">
                                                            {
                                                                'Parcelas Restantes: '
                                                            }
                                                        </span>
                                                        {`${product?.VehicleInformations?.parcelasRestantes}`}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    )}

                                    {product?.VehicleInformations
                                        ?.mesesDeContrato && (
                                        <div className="list-organizer">
                                            <ul
                                                style={{ marginBottom: '25px' }}
                                            >
                                                <li>
                                                    <span className="StyleTitleDescription">
                                                        {'Meses de Contrato: '}
                                                    </span>
                                                    {`${product?.VehicleInformations?.mesesDeContrato}`}
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </>
                            </>
                        )}

                        {product && product.typeNegotiation === 'Imoveis' && (
                            <>
                                <div className="list-organizer">
                                    <ul style={{ marginBottom: '25px' }}>
                                        <li>
                                            <span className="StyleTitleDescription">
                                                {'Quitado: '}
                                            </span>
                                            {product &&
                                            product.PropertyInformations &&
                                            product.PropertyInformations
                                                .quitado &&
                                            product.PropertyInformations.quitado
                                                ? 'Sim'
                                                : 'Não'}
                                        </li>
                                    </ul>

                                    <div className="items-list-organizer">
                                        <ul style={{ marginBottom: '25px' }}>
                                            <li>
                                                <span className="StyleTitleDescription">
                                                    {'Rua: '}
                                                </span>
                                                {`${product?.PropertyInformations?.street}`}
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="list-organizer">
                                    <ul style={{ marginBottom: '25px' }}>
                                        <li>
                                            <span className="StyleTitleDescription">
                                                {'Cidade: '}
                                            </span>
                                            {`${product?.PropertyInformations?.city}`}
                                        </li>
                                    </ul>
                                    <ul style={{ marginBottom: '25px' }}>
                                        <li>
                                            <span className="StyleTitleDescription">
                                                {'Estado: '}
                                            </span>
                                            {`${product?.PropertyInformations?.state}`}
                                        </li>
                                    </ul>
                                </div>

                                <div className="list-organizer">
                                    <ul style={{ marginBottom: '25px' }}>
                                        <li>
                                            <span className="StyleTitleDescription">
                                                {'Bairro: '}
                                            </span>
                                            {`${product?.PropertyInformations?.neighborhood}`}
                                        </li>
                                    </ul>
                                    <ul style={{ marginBottom: '25px' }}>
                                        <li>
                                            <span className="StyleTitleDescription">
                                                {'Número: '}
                                            </span>
                                            {`${product?.PropertyInformations?.number}`}
                                        </li>
                                    </ul>
                                </div>

                                <div className="list-organizer">
                                    <ul style={{ marginBottom: '25px' }}>
                                        <li>
                                            <span className="StyleTitleDescription">
                                                {'Complemento: '}
                                            </span>
                                            {`${product?.PropertyInformations?.complement}`}
                                        </li>
                                    </ul>
                                    <ul style={{ marginBottom: '25px' }}>
                                        <li>
                                            <span className="StyleTitleDescription">
                                                {'Garagem: '}
                                            </span>
                                            {`${product?.PropertyInformations?.garage}`}
                                        </li>
                                    </ul>
                                </div>

                                <div className="list-organizer">
                                    <ul style={{ marginBottom: '25px' }}>
                                        <li>
                                            <span className="StyleTitleDescription">
                                                {'Quartos: '}
                                            </span>
                                            {`${product?.PropertyInformations?.rooms}`}
                                        </li>
                                    </ul>
                                    <div className="items-list-organizer">
                                        <ul style={{ marginBottom: '25px' }}>
                                            <li>
                                                <span className="StyleTitleDescription">
                                                    {'Banheiros: '}
                                                </span>
                                                {`${product?.PropertyInformations?.bathrooms}`}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="list-organizer">
                                    {product?.PropertyInformations?.quitado ? (
                                        <></>
                                    ) : (
                                        <div className="items-list-organizer">
                                            <ul
                                                style={{ marginBottom: '25px' }}
                                            >
                                                <li>
                                                    <span className="StyleTitleDescription">
                                                        {'Valor Restante: '}
                                                    </span>
                                                    {`${product?.PropertyInformations?.valorRestante}`}
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                    {product?.PropertyInformations?.quitado ===
                                    false ? (
                                        <div className="items-list-organizer">
                                            <ul
                                                style={{ marginBottom: '25px' }}
                                            >
                                                <li>
                                                    <span className="StyleTitleDescription">
                                                        {'Parcelas Restantes: '}
                                                    </span>
                                                    {`${product?.PropertyInformations?.parcelasRestantes}`}
                                                </li>
                                            </ul>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                {product &&
                                    product?.PropertyInformations
                                        ?.mesesDeContrato && (
                                        <div className="list-organizer">
                                            <div className="items-list-organizer">
                                                <ul
                                                    style={{
                                                        marginBottom: '25px',
                                                    }}
                                                >
                                                    <li>
                                                        <span className="StyleTitleDescription">
                                                            {
                                                                'Meses de Contrato: '
                                                            }
                                                        </span>
                                                        {`${product?.PropertyInformations?.mesesDeContrato}`}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                            </>
                        )}
                    </div>
                    <h2>Descrição</h2>
                    {product && product.description && (
                        <div
                            className="tw-font-primary tw-text-lg"
                            dangerouslySetInnerHTML={{
                                __html: product.description,
                            }}
                        />
                    )}
                </div>
            </Description>
        </>
    )
}

export default ProductDescription
