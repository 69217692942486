import React, {
	forwardRef,
	useCallback,
	useImperativeHandle,
	useState,
} from 'react';
import { Modal } from 'semantic-ui-react';
import {
	Container,
	ModalBottom,
	ModalMiddle,
	ModalTop,
	ChooseUserTypeButton,
	SucessModalTop,
	SucessModalMiddle,
	SucessModalBottom,
} from './styles';
import { useCreateUseraccountWithAdmUserMutation } from '../../../generated/graphql';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { addOneUserOnList } from '../../../actions/AllUsersAction';
export interface ModalNewUserHandles {
	showModal: () => void;
}
interface SelectedRegisterTypeFeedback {
	ADM: boolean;
	FAQ: boolean;
	moderator: boolean;
}
interface NewUserValues {
	email: string;
	name: string;
}
interface NewUserResponse {
	email?: string;
	password?: string;
}
const NewUserModal: React.RefForwardingComponent<ModalNewUserHandles> = (
	{},
	ref
) => {
	const [createNewUser] = useCreateUseraccountWithAdmUserMutation();
	const [state, setState] = useState({ dimmer: undefined, open: false });
	const [sucessModalState, setSucessModalState] = useState(false);
	const { register, handleSubmit} = useForm<NewUserValues>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
	});
	const show = (dimmer: any) => setState({ dimmer: dimmer, open: true });
	const [registerType, setRegisterType] = useState<
		SelectedRegisterTypeFeedback
	>({ ADM: true, FAQ: false, moderator: false });
	const [userTypeId, setUserTypeId] = useState<number>(1);
	const [newUserResult, setNewUserResult] = useState<NewUserResponse | null>(
		null
	);
	const dispacth = useDispatch();

	const showModal = useCallback(() => {
		setState({ dimmer: undefined, open: true });
	}, [setState, state]);

	const setRegisterFeedbackType = (registerType: string) => {
		switch (registerType) {
			case 'ADM':
				setRegisterType({ ADM: true, FAQ: false, moderator: false });
				setUserTypeId(1);
				break;
			case 'FAQ':
				setRegisterType({ ADM: false, FAQ: true, moderator: false });
				setUserTypeId(3);
				break;
			case 'moderator':
				setRegisterType({ ADM: false, FAQ: false, moderator: true });
				setUserTypeId(2);
				break;
			default:
				break;
		}
	};
	const onSubmit = async (data: any) => {
		try {
			const result = await createNewUser({
				variables: {
					email: data.email,
					name: data.name,
					userTypeId: userTypeId,
				},
			});
			setNewUserResult({
				email: result.data?.createUseraccountWithAdmUser.email,
				password: result.data?.createUseraccountWithAdmUser.password,
			});
			setState({ dimmer: undefined, open: false });
			setSucessModalState(true);
			dispacth(
				addOneUserOnList({
					email: result!.data!.createUseraccountWithAdmUser!.email!,
					name: result!.data!.createUseraccountWithAdmUser!.name!,
					UserType: result!.data!.createUseraccountWithAdmUser!
						.UserType! as any,
					userTypeId: result!.data!.createUseraccountWithAdmUser!
						.userTypeId!,
				})
			);
		} catch (err: any) {}
	};

	useImperativeHandle(ref, () => {
		return {
			showModal,
		};
	});
	return (
		<>
			<Modal
				dimmer={state.dimmer}
				open={state.open}
				onClose={() => state.open === false}
				style={{ width: '535px', height: '350px', borderRadius:'15px' }}
			>
				<Container>
					<form onSubmit={handleSubmit(onSubmit)}>
						<ModalTop>
							<h2 className="TituloCadastrarConta">Cadastrar Nova Conta</h2>
						</ModalTop>
						<ModalMiddle>
							<div className="choose-user-type-buttons-container">
								<ChooseUserTypeButton
									active={registerType.ADM}
									onClick={() =>
										setRegisterFeedbackType('ADM')
									}
								>
									Administrador
								</ChooseUserTypeButton>

								<ChooseUserTypeButton
									active={registerType.moderator}
									onClick={() =>
										setRegisterFeedbackType('moderator')
									}
								>
									Moderador
								</ChooseUserTypeButton>

								<ChooseUserTypeButton
									active={registerType.FAQ}
									onClick={() =>
										setRegisterFeedbackType('FAQ')
									}
								>
									FAQ
								</ChooseUserTypeButton>
							</div>
							<div className="modal-inputs">
								<div className="input-container">
									<label>Nome</label>
									<input
										type="text"
										{...register('name',{ required: true })}
									/>
								</div>
								<div className="input-container">
									<label>E-mail</label>
									<input
										type="email"
										{...register('email',{ required: true })}
									/>
								</div>
							</div>
						</ModalMiddle>
						<ModalBottom>
							<button
								onClick={() =>
									setState({ dimmer: undefined, open: false })
								}
								className="negative-button"
							>
								Cancelar
							</button>
							<button className="positive-button" type="submit">
								Concluir
							</button>
						</ModalBottom>
					</form>
				</Container>
			</Modal>
			<Modal
				open={sucessModalState}
				onClose={() => !sucessModalState}
				style={{ width: '560px', height: '247px', padding: '21px' }}
			>
				<SucessModalTop>
					<h2>
						Novo {userTypeId === 1 && 'Administrador'}{' '}
						{userTypeId === 2 && 'moderador'}{' '}
						{userTypeId === 3 && 'FAQ'} cadastrado!
					</h2>
				</SucessModalTop>
				<SucessModalMiddle>
					<div className="email-container">
						<span>E-mail para login</span>
						<h3>{newUserResult?.email} </h3>
					</div>
					<div className="email-container">
						<span>Senha provisória</span>
						<h3> {newUserResult?.password} </h3>
					</div>
				</SucessModalMiddle>
				<SucessModalBottom>
					<button onClick={() => setSucessModalState(false)}>
						Concluir
					</button>
				</SucessModalBottom>
			</Modal>
		</>
	);
};

export default forwardRef(NewUserModal);
