import React from 'react';
import { useParams } from 'react-router';
import Search from '../../components/Search/Search';
import Layout from '../../components/Layout/Layout';

interface params {
	searchContent: string;
	categorie: string;
}

const SearchPage = () => {
	const search: params = useParams();
	let res = search.searchContent.replace('%', ' ').replace(':', '');
	let res2 = search.categorie.replace('%', ' ').replace(':', '');
	return (
		<Layout title="Buscar">
			<Search search={res} categorie={res2} />
		</Layout>
	);
};
export default SearchPage;
