import React, {  useEffect, useState } from 'react';
import { AddNewCupomContainer, AddNewCupommodalButton, AddNewCupomModalCampos, AddNewCupomModalFooter, AddNewCupomModalHeader, AddNewCupomModalProdutos, AddNewCupommodalSucess, Container, UserCuponItem, UserCuponsHeader, UserCuponsList} from './UserCuponsStyles';
import { IoAddCircleOutline } from '@react-icons/all-files/io5/IoAddCircleOutline';
import { Modal } from 'semantic-ui-react';
import {useCreateCupomMutation,useDeleteCupomMutation,useEditCupomMutation,useGetAllCupomProductsByCupomIdLazyQuery,useGetAllCuponsbyUserIdLazyQuery,useGetCupomLazyQuery,useGetProductByUserIdLazyQuery,useGetProductByUserIdQuery} from '../../../generated/graphql';
import IUserAccount from '../../../models/IUserAccount';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store';
import { AiFillCloseCircle } from '@react-icons/all-files/ai/AiFillCloseCircle';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import getDaysBetwen from '../../../utils/getDaysBetwen';
import {FaCheck} from "@react-icons/all-files/fa/FaCheck";

type formProps = {
    code: string
    prazoValidade: number
    desconto: number
}
type cupomEditType = {
    id: number
    code: string
    prazoValidade: number
    desconto: number
    productIds: number[]
}

export default function UserCupons() {
	const [novoCupom, setNovoCupom] = useState(false);
	const [editarCupomModal, setEditarCupomModal] = useState(false);
	const [cupomSucess,setCupomSucess] = useState(false);
	const [cupomSucessEdit,setCupomSucessEdit] = useState(false);

	const [codeCupom, setCodeCupom] = useState<string>('');
	const [productsId, setProductsId] = useState<number[]>([]);

	const [produtsByUser, setProductsByUser] = useState<any[]>([]);

	const user: IUserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);

	const { data: getProductData, loading: getProducLoading } =
		useGetProductByUserIdQuery({
			fetchPolicy: 'no-cache',
			variables: {
				getProductByUserId:{
					type: 'all',
					userId: user.id,
					limit:30
				}
			},
		});

	const [
		searchProductbyUserId,
		{ data: getProductDataSearch, loading: getProductDataSearchLoading },
	] = useGetProductByUserIdLazyQuery({
		fetchPolicy: 'no-cache',
	});

	const [getCupom, { data: getCupomData, loading: getCupomLoading }] =
		useGetCupomLazyQuery({
			fetchPolicy: 'no-cache',
		});
	const [
		getCupomProduct,
		{ data: getCupomProductData, loading: getCupomProductLoading },
	] = useGetAllCupomProductsByCupomIdLazyQuery({
		fetchPolicy: 'no-cache',
	});

	const [deleteCupom] = useDeleteCupomMutation({ fetchPolicy: 'no-cache' });
	const [createCupom] = useCreateCupomMutation({ fetchPolicy: 'no-cache' });

	const [cupomEdit, setCupomEdit] = useState<cupomEditType>();
	const [editarCupomApi] = useEditCupomMutation({
		fetchPolicy: 'no-cache',
	});
	const [
		getAllCupons,
		{ data: getAllCuponsData, loading: getAllCuponsLoading },
	] = useGetAllCuponsbyUserIdLazyQuery({
		fetchPolicy: 'no-cache',
		variables: {
			getAllCuponsbyUserId: {
				userId: user.id,
			},
		},
	});

	const {
		handleSubmit,
		register,
		setValue,
		formState: { errors },
	} = useForm<formProps>({
		mode: 'all',
		reValidateMode: 'onChange',
	});

	async function onSubmit(data: formProps) {
		setNovoCupom(false);
		setCupomSucess(true)
		await createCupom({
			variables: {
				createCupomInputData: {
					code: codeCupom,
					productsId: productsId,
					userId: user.id,
					validade: Number(data.prazoValidade),
					desconto: Number(data.desconto),
				},
			},
		});

		setInterval(()=>{
			setCupomSucess(false)
		},1000)

		setCodeCupom('');
		setValue('prazoValidade', 0);
		await getAllCupons({
			variables: {
				getAllCuponsbyUserId: {
					userId: user.id,
				},
			},
		});
	}

	function handleProduct(produtoId: number) {
		let produtoJaAdicionado = productsId.includes(produtoId);

		if (!produtoJaAdicionado) {
			setProductsId(productsId.concat(produtoId));
		} else {
			setProductsId(productsId.filter((id: number) => id !== produtoId));
		}
	}

	async function removerCupom(idCupom: number | undefined) {
		if (idCupom) {
			await deleteCupom({
				variables: {
					deleteCupomInputData: {
						cupomId: idCupom,
					},
				},
			});

			setEditarCupomModal(false);
			setCupomEdit({
				id: 0,
				productIds: [],
				code: '',
				desconto: 0,
				prazoValidade: 0,
			});

			await getAllCupons({
				variables: {
					getAllCuponsbyUserId: {
						userId: user.id,
					},
				},
			});
		}
	}

	function validarCamposPreenchidos() {
		if (codeCupom && productsId.length > 0) {
			return true;
		}
		return false;
	}

	useEffect(() => {
		getAllCupons({
			variables: {
				getAllCuponsbyUserId: {
					userId: user.id,
				},
			},
		});
	}, []);

	useEffect(() => {
		if (getProductDataSearch?.getProductByUserId) {
			setProductsByUser(getProductDataSearch.getProductByUserId);
		}
	}, [getProductDataSearch]);

	useEffect(() => {
		if (getProductData?.getProductByUserId) {
			setProductsByUser(getProductData.getProductByUserId);
		}
	}, [getProductData]);

	async function abrirModalEditar(cupomId: number) {
		setEditarCupomModal(true);
		await getCupomProduct({
			variables: {
				getAllCupomProductsByCupomId: {
					cupomId: cupomId,
				},
			},
		});

		await getCupom({
			variables: {
				getCupomInputData: {
					id: cupomId,
				},
			},
		});
	}

	useEffect(() => {
		if (getCupomProductData && getCupomData) {
			setCupomEdit({
				id: getCupomData.getCupom.id,
				code: getCupomData!.getCupom.code,
				desconto: getCupomData!.getCupom.desconto,
				prazoValidade: getDaysBetwen(getCupomData.getCupom.validade),
				productIds:
					getCupomProductData.getAllCupomProductsByCupomId.map(
						(cupomProducts) => cupomProducts.productId
					),
			});
		}
	}, [getCupomProductData, getCupomData]);

	async function editarCupom() {
		if (cupomEdit) {
			setCupomSucessEdit(true)
			await editarCupomApi({
				variables: {
					editCupomInputData: {
						id: cupomEdit.id,
						code: cupomEdit.code,
						desconto: cupomEdit.desconto,
						prazoValidade: cupomEdit.prazoValidade,
						cuponProdutos: cupomEdit.productIds,
					},
				},
			});

			setEditarCupomModal(false);
			setInterval(()=>{
				setCupomSucessEdit(false)
			},1000)
			setCupomEdit({
				id: 0,
				productIds: [],
				code: '',
				desconto: 0,
				prazoValidade: 0,
			});

			await getAllCupons({
				variables: {
					getAllCuponsbyUserId: {
						userId: user.id,
					},
				},
			});
		}
	}

	async function selectedCupomProduct(productId: number) {
		if (cupomEdit) {
			const findIdProcutId = cupomEdit.productIds.find(
				(idProduct) => idProduct === productId
			);

			if (findIdProcutId as number) {
				const productsId: number[] = cupomEdit.productIds.filter(
					(value) => value !== findIdProcutId
				);
				setCupomEdit({
					...cupomEdit,
					productIds: productsId,
				});
			} else {
				setCupomEdit({
					...cupomEdit,
					productIds: cupomEdit.productIds.concat(productId),
				});
			}
		}
	}

	function produtoSelecionado(idProduct: number) {
		if (cupomEdit) {
			let produtoSelecionado = cupomEdit.productIds.find(
				(produtId) => produtId === idProduct
			);
			if (produtoSelecionado) return true;
		}
		return false;
	}

	function filterProducts(value: string) {
		searchProductbyUserId({
			variables: {
				getProductByUserId:{
					type: 'search',
					userId: user.id,
					value: value,
					limit:30
				}
			},
		});
	}

	return (
		<>
			<Container>
				<Modal
					open={novoCupom}
					dimmer="blurring"
					onClose={() => setNovoCupom(false)}
				>
					<AddNewCupomContainer>
						<form onSubmit={handleSubmit(onSubmit)}>
							<AddNewCupomModalHeader>
								<h1>Adicionar novo Cupom</h1>
								<AiFillCloseCircle
									size={30}
									className="iconCloseModal"
									onClick={() => setNovoCupom(false)}
								/>
							</AddNewCupomModalHeader>

							<AddNewCupomModalCampos>
								<span>Código</span>
								<input
									type="text"
									maxLength={15}
									onChange={(e) =>
										setCodeCupom(e.target.value.toUpperCase())
									}
									value={codeCupom}
								/>
								<span>Prazo de validade</span>
								<input
									type="number"
									placeholder="7 Dias"
									maxLength={2}
									{...register('prazoValidade', {
										required: true,
										maxLength: 2,
									})}
								/>
								<span>Desconto</span>
								<input
									type="number"
									placeholder="Ex: 75%"
									{...register('desconto', {
										required: true,
										maxLength: 3,
									})}
								/>
							</AddNewCupomModalCampos>
							<AddNewCupomModalProdutos>
								<div className="AddNewCupomModalProdutosHeader">
									<h3>Produtos Associados</h3>
								</div>
								<div className="AddNewCupomModalProdutosSearch">
									<input
										type="text"
										placeholder="Buscar produto."
										onChange={(e) =>
											filterProducts(e.target.value)
										}
									/>
								</div>
								<div className="AddNewCupomModalProdutosMain">
									{produtsByUser &&
										produtsByUser.map((produto, index) => {
											return (
												<div className="ProdutosItem">
													<input
														type="checkbox"
														name="produtoId"
														onClick={() => {
															handleProduct(
																produto.id
															);
														}}
													/>
													<span>{produto.title}</span>
												</div>
											);
										})}
								</div>
							</AddNewCupomModalProdutos>
							<AddNewCupomModalFooter>
								<AddNewCupommodalButton
									type="button"
									onClick={() => setNovoCupom(false)}
									active={false}
								>
									Cancelar
								</AddNewCupommodalButton>
								<AddNewCupommodalButton
									type="submit"
									active={validarCamposPreenchidos()}
									disabled={!validarCamposPreenchidos()}
								>
									Cadastrar
								</AddNewCupommodalButton>
							</AddNewCupomModalFooter>
						</form>
					</AddNewCupomContainer>
				</Modal>
				<Modal
					open={editarCupomModal}
					dimmer="blurring"
					onClose={() => setEditarCupomModal(false)}
				>
					<AddNewCupomContainer>
						<AddNewCupomModalHeader>
							<h1>Editar Cupom</h1>
							<AiFillCloseCircle
								size={30}
								className="iconCloseModal"
								onClick={() => setEditarCupomModal(false)}
							/>
						</AddNewCupomModalHeader>
						<AddNewCupomModalCampos>
							{getCupomData && cupomEdit && (
								<>
									<span>Código</span>
									<input
										type="text"
										maxLength={12}
										onChange={(e) =>
											setCupomEdit({
												...cupomEdit,
												code: e.target.value.toUpperCase(),
											})
										}
										value={cupomEdit.code}
									/>
									<span>Prazo de validade</span>
									<input
										type="number"
										placeholder="7 Dias"
										maxLength={2}
										onChange={(e) =>
											setCupomEdit({
												...cupomEdit,
												prazoValidade: Number(
													e.target.value
												),
											})
										}
										value={cupomEdit.prazoValidade}
									/>
									<span>Desconto</span>
									<input
										type="number"
										placeholder="Desconto do cupom"
										onChange={(e) =>
											setCupomEdit({
												...cupomEdit,
												desconto: Number(
													e.target.value
												),
											})
										}
										value={cupomEdit.desconto}
									/>
								</>
							)}
						</AddNewCupomModalCampos>
						<AddNewCupomModalProdutos>
							<div className="AddNewCupomModalProdutosHeader">
								<h3>Produtos Associados</h3>
							</div>
							<div className="AddNewCupomModalProdutosSearch">
								<input
									type="text"
									placeholder="Buscar produto."
									onChange={(e) =>
										filterProducts(e.target.value)
									}
								/>
							</div>
							<div>
								{produtsByUser &&
									produtsByUser.map((product) => (
										<>
											<input
												type="checkbox"
												checked={produtoSelecionado(
													product.id
												)}
												onChange={() =>
													selectedCupomProduct(
														product.id
													)
												}
											/>
											<span>{product.title}</span>
										</>
									))}
							</div>
						</AddNewCupomModalProdutos>
						<AddNewCupomModalFooter>
							<AddNewCupommodalButton
								active={false}
								onClick={() =>
									removerCupom(getCupomData?.getCupom.id)
								}
							>
								Deletar
							</AddNewCupommodalButton>
							<AddNewCupommodalButton
								type="button"
								active={true}
								onClick={editarCupom}
							>
								Editar
							</AddNewCupommodalButton>
						</AddNewCupomModalFooter>
					</AddNewCupomContainer>
				</Modal>
				<Modal
					open={cupomSucess}
					dimmer="blurring"
					size='tiny'
				>
					<AddNewCupommodalSucess>
						<h1>Cupom Cadastrado com Sucesso!</h1>
						<FaCheck color='green' size={150}/>
					</AddNewCupommodalSucess>

				</Modal>
				<Modal
					open={cupomSucessEdit}
					dimmer="blurring"
					size='tiny'
				>
					<AddNewCupommodalSucess>
						<h1>Cupom Editado com Sucesso!</h1>
						<FaCheck color='green' size={150}/>
					</AddNewCupommodalSucess>

				</Modal>
				<UserCuponsHeader onClick={() => setNovoCupom(true)}>
					<button>
						<IoAddCircleOutline size={30} />
					</button>
					<p>Novo Cupom</p>
				</UserCuponsHeader>
				<UserCuponsList>
					{getAllCuponsData &&
						getAllCuponsData?.getAllCuponsbyUserId.map((cupom) => (
							<UserCuponItem
								onClick={() => abrirModalEditar(cupom.id)}
							>
								<p>{cupom.code}</p>
								<span>Desconto: {cupom.desconto}%</span>
								<span>
									Validade:{' '}
									{moment(cupom.validade).format(
										'DD/MM/YYYY'
									)}
								</span>
							</UserCuponItem>
						))}
				</UserCuponsList>
			</Container>
		</>
	);
}
