import React from 'react';
import BuyProductsInCartConfirmProcess from '../../components/BuyProductsInCart/BuyProductsInCartConfirmProcess/BuyProductsInCartConfProcess';
import Layout from '../../components/Layout/Layout';

const BuyProductInCartConfirmProcess = () => {
	return (
		<Layout title="Finalizar Compra">
			<BuyProductsInCartConfirmProcess />
		</Layout>
	);
};

export default BuyProductInCartConfirmProcess;
