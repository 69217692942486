import React from 'react';
import logo from '../../../assets/sambayWelcome.svg';
import { Container } from './styles';

const WelcomeSambay: React.FC = () => {
	return (
		<Container>
			<div className="welcome">
				<h1>Bem vindo ao</h1>
				<img src={logo} alt="" />
			</div>
		</Container>
	);
};

export default WelcomeSambay;
