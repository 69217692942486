import styled from 'styled-components';

export const Container = styled.div`
	width: 80%;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	padding: 28px;

	.title-and-search-bar-container {
		margin-bottom: 15px;
		h2 {
			color: #0b0948;
			font-family: 'Poppins';
			font-weight: bold;
			font-size: 12px;
			margin-bottom: 15px;
		}
		.ConfigTitleContasCadastradas{
			color: #6b97a5;
    		font-family: 'Poppins';
    		font-weight: bold;
    		font-size: 17px;
		}
		.search-bar {
			margin-bottom: 15px;
			border: solid 1px black;
			border-radius: 7px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			padding: 7px;
			height: 30px;

			input::placeholder{
				color:#CDCDCD;
				text-align: left;
				font: italic normal normal 14px Poppins;
				letter-spacing: 0px;
				opacity: 1;	
				padding-left:5px;
			}
			
			#search-icon {
				margin-right: 12px;
				width: 18px;
				height: 18px;
				color: #FFC000;
				.a {
					fill: #ff7e0f;
				}
			}
			input {
				border: none;
			}
			input:focus {
				outline: none;
			}
			
			.IconBuscar{
				color:#FFC000;
				height: 23px; width: 33px; 
			}
			
		}
	}
	.accounts-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
`;
