import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 20px;

	@media (min-width:425px) and (max-width:767px){
		flex-direction: column;
		width: 100%;
	}
	@media (min-width:375px) and (max-width:424px){
		flex-direction: column;
		width: 100%;
	}
	@media (min-width:320px) and (max-width:374px){
		flex-direction: column;
		width: 100%;
	}
	.item {
		color: #6b98a6;
		font-family: 'Poppins';
		font-weight: 600;
		font-size: 10px;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 36px;
		width: 125px;

		@media (min-width:425px) and (max-width:767px){
			flex-direction: column;
			width:100%;
			margin-bottom: 1rem;
			font-size: 14px;
		}
		@media (min-width:375px) and (max-width:424px){
			flex-direction: column;
			width:100%;
			margin-bottom: 1rem;
			font-size: 14px;
		}
		@media (min-width:320px) and (max-width:374px){
			flex-direction: column;
			width:100%;
			margin-bottom: 1rem;
			font-size: 14px;
		}
	}
	.active {
		display: flex;
		flex-direction: column;
		align-items: center;
		color: #6b98a6;
		font-family: 'Poppins';
		font-weight: 600;
		font-size: 10px;
		text-align: center;
		height: 36px;
		width: 125px;

		@media (min-width:425px) and (max-width:767px){
			font-size: 14px;
			width: 100%
		}
		@media (min-width:375px) and (max-width:424px){
			font-size: 14px;
			width: 100%
		}
		@media (min-width:320px) and (max-width:374px){
			font-size: 14px;
			width: 100%
		}

		.border {
			width: 102px;
			height: 6px;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
			background: #6b98a6;
			margin-top: 11px;
			
			@media (min-width:425px) and (max-width:767px){
				width: 150px;
			}
			@media (min-width:375px) and (max-width:424px){
				width: 150px;
			}
			@media (min-width:320px) and (max-width:374px){
				width: 150px;
			}
		}
	}
`;
