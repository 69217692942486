import { currentUsersState, UserAccountsListAction } from '../models/AllUsers';
import { BuyParamsActions, currentBuyParamsState } from '../models/BuyParams';

const initialState: currentBuyParamsState = {
	currentParams: undefined,
};

export default function BuyParamsReducer(
	state = initialState,
	action: BuyParamsActions
) {
	switch (action.type) {
		case 'ADD_PRODUCT':
			return {
				...state,
				currentParams: {
					...state.currentParams,
					product: action.product,

				},
			};

		case 'ADD_SHIPPING_WAY':
			return {
				...state,
				currentParams: {
					...state.currentParams,
					shippingWay: action.shippingWay,
				},
			};

		case 'ADD_ADDRESS':
			return {
				...state,
				currentParams: {
					...state.currentParams,
					address: action.address,
				},
			};
		case 'REMOVE_ADDRESS':
			return {
				...state,
				currentParams: {
					...state.currentParams,
					address: {},
				},
			};

		case 'ADD_SHIPPING_VALUE':
			return {
				...state,
				currentParams: {
					...state.currentParams,
					shippingValue: action.shippingValue,
				},
			};
		case 'ADD_PAYMENT_METHOD':
			return {
				...state,
				currentParams: {
					...state.currentParams,
					paymentMethod: action.paymentMethod,
				},
			};

		case 'ADD_CARD':
			return {
				...state,
				currentParams: {
					...state.currentParams,
					card: action.card,
				},
			};
		case 'REMOVE_CARD':
			return {
				...state,
				currentParams: {
					...state.currentParams,
					card: {},
				},
			};
		case 'ADD_INSTALLMENTS_NUMBER':
			return {
				...state,
				currentParams: {
					...state.currentParams,
					installmentsNumber: action.installmentsNumber,
				},
			};
		case 'INCREASE_PRODUCT_QUANTITY':
			return {
				...state,
				currentParams: {
					...state.currentParams,
					quantity: action.productQuantity,
				},
			};

		case 'DECREASE_PRODUCT_QUANTITY':
			return {
				...state,
				currentParams: {
					...state.currentParams,
					quantity: action.productQuantity,
				},
			};

		case 'UPDATE_PRODUCT_PRICE':
			return {
				...state,
				currentParams: {
					...state.currentParams,
					product: {
						...state.currentParams?.product,
						price: action.newPrice,
					},
				},
			};

		default:
			return state;
	}
}
