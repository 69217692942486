import React from 'react';

import moment from 'moment';
import MakeProductQuestionAnswer from '../MakeProductQuestionAnswer';
import ProductQuestionAnswer from '../ProductQuestionAnswer';
import Img from '../../Default/CommomImg/Img';

type Props = {
	Question: any;
};

const ProductQuestions: React.FC<Props> = ({ Question }) => {
	return (
		<>
			{Question.repeated === false && (
				<div className="question">
					<div className="user-img">
						<Img
							src={`${process.env.REACT_APP_STATIC_URL}/user/${Question.UserAccount.id}.jpeg`}
						/>
					</div>

					<div className="question-content">
						<div className="question-infos">
							<h2>{Question.UserAccount.name}</h2>
							<span>
								{moment(Question.createdAt).format(
									'DD/MM/YYYY hh:mm'
								)}
							</span>
						</div>

						<div className="question-description">
							<span>{Question.commentDescription}</span>
						</div>
						<div
							className="button-container"
							style={{
								marginBottom: '20px',
							}}
						>
							{Question.ProductQuestionsAnswers && (
								<MakeProductQuestionAnswer
									ProductQuestionId={Question.id}
									userId={Question.UserAccount.id}
									productSelerId={
										Question.Product.userAccountId
									}
								></MakeProductQuestionAnswer>
							)}
						</div>
					</div>
				</div>
			)}
			<ProductQuestionAnswer
				ProductQuestionId={Question.id}
			></ProductQuestionAnswer>
		</>
	);
};

export default ProductQuestions;
