import React, { CSSProperties, useEffect, useState } from 'react'
import defaultImg from '../../../assets/defaultImg.svg'

type Props = {
    src: string
    img?: any
    alt?: string
    className?: string
    style?: CSSProperties
    onClick?: any
}

const Img: React.FC<Props> = ({ src, img, alt, className, style, onClick }) => {
    const [image, setImage] = useState<JSX.Element>(<></>)

    useEffect(() => {
        setImage(
            <img
                src={src}
                alt={alt || ''}
                className={className || ''}
                onError={() =>
                    setImage(
                        <>
                            <img
                                src={img || defaultImg}
                                alt={alt || ''}
                                className={className || ''}
                                style={style}
                                onClick={onClick}
                            />
                        </>
                    )
                }
                style={style}
                onClick={onClick}
            />
        )
    }, [alt, className, img, onClick, src, style])

    return image
}

export default Img
