import React, {useEffect, useState} from 'react';
import {Container, MainContainer} from './Style';

import {useDispatch, useSelector} from 'react-redux';

import Radio from '@material-ui/core/Radio';
import IUserAccount from '../../../models/IUserAccount';
import {AppState} from '../../../store';
import {
	useAplicarCuponDescontoLazyQuery,
	useAplicarCuponDescontoQuery,
	useGetAllCardsQuery
} from '../../../generated/graphql';
import IUserCards from '../../../models/IUserCards';
import IProductsCart from '../../../models/ICart';
import {InsertCards} from '../../../actions/UserCardsActions';
import AddCardModal from '../../BuyPaymentProcess/AddCardModal/AddCardModal';

import {insertProductInstallmentsNumber} from '../../../actions/ProductsCartActions';
import {FormControl, FormControlLabel, makeStyles, RadioGroup,} from '@material-ui/core';
import {inserFormOfPagament, insertPurchaseCardId, removeCardId,} from '../../../actions/UserActions';
import {useHistory} from 'react-router-dom';
import {getCalcCartValue} from '../../../utils/getCartTotalValue';
import {ReactComponent as Back} from '../../../assets/icons/back.svg';
import clsx from 'clsx';

const BuyProductsInCartPayProcess: React.FC = () => {
	const user: IUserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const { data: cardsData, loading: cardLoading } = useGetAllCardsQuery({
		fetchPolicy:'cache-and-network',
		variables: { userId: user.id },
	});

	const userCards: IUserCards[] = useSelector(
		(state: AppState) => state.UserCards.currentCards
	);
	const productsCart: IProductsCart[] = useSelector(
		(state: AppState) => state.ProductsCart.currentProductsCart
	);

	const [read, setRead] = useState<string>('true');
	const [disabled, setDisabled] = useState<boolean>(true);
	const [paymentMethod, setPaymentMethod] = useState<string | undefined>();
	const [validCheckBox, setValidCheckBox] = useState<boolean>(false);
	const [cupomDesconto,setCupomDescont] = useState<string>('');
	const [verifyForm, setVerifyForm] = useState<string>();

	const history = useHistory();
	let installmentsNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
	const dispatch = useDispatch();

	let bigInstallment = 0;

	useEffect(() => {
		if (read === 'true' || paymentMethod === 'boleto') {
			setDisabled(true);
		} else {
			setDisabled(false);
		}
	}, [read, paymentMethod]);

	useEffect(() => {
		dispatch(InsertCards(cardsData?.getAllCards));
	}, [cardsData]);

	useEffect(() => {
		if (paymentMethod == 'boleto') {
			setValidCheckBox(true);
		} else {
			setValidCheckBox(false);
		}
	}, [paymentMethod]);

	useEffect(() => {
		if (!verifyForm) {
			setValidCheckBox(false);
		} else {
			setValidCheckBox(true);
		}
	}, [verifyForm]);

	const valid = () => {
		history.push('/buy-confirm-process-cart');
	};
	useEffect(() => {
		if (!user.cardId) {
			productsCart.map((item) => {
				dispatch(
					insertProductInstallmentsNumber(
						item.parameters.product.id,
						1
					)
				);
			});
		}
	}, [user.cardId]);

	const calcCartProducts = (item: IProductsCart) => {
		let calcValue =
			item?.parameters!.product!.price! * item?.parameters.quantity;

		return calcValue.toLocaleString('pt-br', {
			style: 'currency',
			currency: 'BRL',
		});
	};

	const setInstallmentsNumber = (productId: number, installments: number) => {
		dispatch(insertProductInstallmentsNumber(productId, installments));
	};

	const useStyles = makeStyles({
		root: {
			'&:hover': {
				backgroundColor: 'transparent',
			},
		}
	});

	function StyledRadio(props: any) {
		const classes = useStyles();

		return (
			<Radio
				className={classes.root}
				disableRipple
				color="default"
				{...props}
				checked={props.checked}
				name="payment"
				style={{
					marginRight: '8px',
				}}
				onClick={() => props.onClick()}
			/>
		);
	}
	return (
		<>
				<Container>
					<MainContainer>
						<div className="class-column">
							<div className="card-container">
								<div className="card-header">
									<h2>Forma de pagamento</h2>
								</div>
								<div className="mobile-divider"/>
								<FormControl component="fieldset">
									<RadioGroup
										aria-label="gender"
										name="gender1"
									>
										<div
											className="card-infos"
											style={{
												boxShadow: 'none',
												borderBottomLeftRadius: '8px',
												borderBottomRightRadius: '8px',
											}}
										>
											{userCards &&
												userCards.map(
													(
														card: any,
														index: number
													) => (
														<div
															className="card-options"
															key={index}
														>
															<FormControlLabel
																value={card.id.toString()}
																control={
																	<StyledRadio
																		color={
																			'default'
																		}
																		checked={
																			user.cardId ===
																			card.id && user.formaDePagamento === 'credit_card'
																		}
																		onClick={() =>
																			dispatch(
																				insertPurchaseCardId(
																					card.id
																				)
																			)
																		}
																	/>
																}
																label={
																	<label>
																		{'Cartão de credito final -' +
																			card.last_digits}
																	</label>
																}
															/>
														</div>
													)
												)}
										</div>
										<AddCardModal></AddCardModal>
										<div
											className="card-infos"
											style={{
												borderTop: 'solid 1px black',
											}}
										>
											<div className="card-options">
												<FormControlLabel
													value={'boleto'}
													control={
														<StyledRadio
															checked={user.formaDePagamento === 'boleto'}
															color={'default'}
															onClick={() => {
																dispatch(
																	inserFormOfPagament('boleto')
																);
																productsCart.map(
																	(item) => {
																		dispatch(
																			insertProductInstallmentsNumber(
																				item
																					?.parameters
																					?.product
																					?.id,
																				1
																			)
																		);
																	}
																);
															}}
														/>
													}
													label={
														<label>
															Boleto bancário
														</label>
													}
												/>
											</div>
											<div className="card-options">
												<FormControlLabel
													value={'PIX'}
													control={
														<StyledRadio
															checked={user.formaDePagamento === 'pix'}
															color={'default'}
															onClick={() => {
																dispatch(
																	inserFormOfPagament('pix')
																);
																productsCart.map(
																	(item) => {
																		dispatch(
																			insertProductInstallmentsNumber(
																				item
																					?.parameters
																					?.product
																					?.id,
																				1
																			)
																		);
																	}
																);
															}}
														/>
													}
													label={
														<label>
															PIX
														</label>
													}
												/>
											</div>
										</div>
									</RadioGroup>
								</FormControl>
							</div>
							<div className="card-container">
								<div className="card-header">
									<h2>Condições do pagamento</h2>
								</div>
							</div>
							{productsCart.map((item) => (
								<div
									className="card"
									key={item?.parameters?.product!.id}
								>
									<div className="name-and-price">
										<h2>
											(
											{
												item?.parameters?.quantity
											}
											x)
											{item?.parameters?.product?.title.substring(
												0,
												18
											)}
										</h2>
										<span>{calcCartProducts(item)}</span>
									</div>
									<div className="payment-method">
										<label>
											Selecione uma forma de pagamento:
										</label>

										<select
											onChange={(e) =>
												setInstallmentsNumber(
													item?.parameters?.product
														?.id,
													parseInt(e.target.value)
												)
											}
											value={item?.installments}
											disabled={
												!user.cardId
											}
										>
											<option>Selecione</option>
											{installmentsNumbers.map(
												(values) => (
													<>
														{values <=
															item?.parameters
																.product!
																.maxInstallments! && (
																<option
																	value={values}
																	key={values}
																>
																	{values}x
																</option>
															)}
													</>
												)
											)}
										</select>
									</div>
								</div>
							))}

							<div className="next-button-container">
								<button
									className="back-button"
									onClick={() =>
										history.push(
											`/buy-address-process-cart`
										)
									}
								>
									<Back className="back-icon" />
								</button>
								<button onClick={() => valid()}>
									Continuar
								</button>
							</div>
						</div>
						{/*
						<div className="infos-card">
							<div
								className="title-infos"
								style={{ borderBottom: 'solid 1px black' }}
							>
								{productsCart.length < 2 && (
									<h2>
										{productsCart.length + 'Produto no carrinho'}
									</h2>
								)}
								{productsCart.length >= 2 && (
									<h2>
										{productsCart.length +
											' Produtos no carrinho'}
									</h2>
								)}
								{productsCart.map((item: IProductsCart) => (
									<>
										<label
											key={item?.parameters?.product!.id}
										>
											{item.parameters.quantity +
												'x' +
												' ' +
												item?.parameters?.product
													?.title}
										</label>
									</>
								))}
							</div>

							<div
								className="infos"
								style={{ borderBottom: 'solid 1px black' }}
							>
								<h4>Detalhes do envio: </h4>
								{productsCart.map((item) => (
									<>
										{item?.parameters?.service ? (
											<>
												<label>
													{item?.parameters?.product
														?.title + ':'}
												</label>
												<h4>Envio via:</h4>
													<label>
														{
														item?.parameters
															?.service?.name!
													}
												</label>
												<h4>Custo:</h4>
												<label>
													{
													item?.parameters
														?.service
														?.custom_price!
													}
												</label>
											</>
										) : (
											<>
												<label>
													{' '}
													{item?.parameters?.product
														?.title + ':'}{' '}
												</label>
												<label>
													A combinar com o vendedor
												</label>
											</>
										)}
									</>
								))}
							</div>
							<div className="price-info">
								<h2>
									Total a pagar:{' '}
									{getCalcCartValue(
										productsCart
									).toLocaleString('pt-br', {
										style: 'currency',
										currency: 'BRL',
									})}
								</h2>
							</div>
						</div>
						 */}
					</MainContainer>
				</Container>
		</>
	);
};
export default BuyProductsInCartPayProcess;
