import styled from 'styled-components';

export const TitleText = styled.h2`
	font-family: 'Poppins', semi-bold;
	font-weight: 600;
	color: red;
`;

export const DescriptionText = styled.span`
	font-family: 'Poppins';
    color:black;
    font-size:15px;
`;
