import React, { useEffect, useRef, useState } from 'react'
import {
    Purchase,
    useAddInvoiceMutation,
    useAtualizarStatuscompraMutation,
    useDeletePurchaseMutation,
    useGenerateAndPrintTicketsLazyQuery,
    useGetServiceByServiceIdLazyQuery,
    useInsertProductOnMelhorEnvioCartMutation,
    usePutTrackingCodeMutation,
} from '../../../../generated/graphql'

import {
    MdContentCopy as Copy,
    MdLocationOn as Location,
    MdSend as SendEmail,
} from 'react-icons/md'
import moment from 'moment'

import { ReactComponent as Star } from '../../../../assets/icons/ratingStar.svg'
import {
    ButtonStatus,
    CardContainer,
    VariationContainer,
    TicketPrintContainer,
    ButtonNfc,
} from '../Style'
import Img from '../../../Default/CommomImg/Img'
import { useSelector } from 'react-redux'
import IUserAccount from '../../../../models/IUserAccount'
import { AppState } from '../../../../store'
import ChoosePaymentMethodMelhorEnvio, {
    ModalChoosePaymentFormMelhorEnvioHandles,
} from './ChoosePaymentMethodMelhorEnvio/ChoosePaymentMethodMelhorEnvio'
import { getFullAddress } from '../../../../utils/getFullAddress'
import { GrDocumentPdf } from 'react-icons/gr'
import { ApolloError } from 'apollo-boost'
import Modal from '../../../Modal/Modal'
import noPhoto from '../../../../assets/sem-produto-detalhe.png'

type Props = {
    UserSellers: Purchase
}

enum PayMethod {
    creditCard = 'Cartão de Crédito',
    boleto = 'Boleto Bancário',
    pix = 'PIX',
}

function formatPayMethod(value: string) {
    if (value === 'credit_card') return PayMethod.creditCard
    if (value === 'pix') return PayMethod.pix
    if (value === 'boleto') return PayMethod.boleto
}

function getServiceShipping(serviceShippingName: string | undefined): string {
    if (serviceShippingName) {
        if (serviceShippingName === 'withdraw') return 'Combinar com o vendedor'
        if (serviceShippingName === 'freeDelivery') return 'Frete Grátis'
    }
    return ''
}

const SalesCard: React.FC<Props> = ({ UserSellers: sale }) => {
    const user: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )

    const modalRef = useRef<ModalChoosePaymentFormMelhorEnvioHandles>(null)
    const [insertProductOnMelhorEnvioCart] =
        useInsertProductOnMelhorEnvioCartMutation({
            onError: (error: ApolloError) => {
                console.log('Error', error.message)
            },
        })
    const [deletePurchase] = useDeletePurchaseMutation()
    const [addInvoice] = useAddInvoiceMutation()
    const [putTrackingCode] = usePutTrackingCodeMutation()
    const [getUrl, { data: dataUrl }] = useGenerateAndPrintTicketsLazyQuery()
    const [atualizarStatusPurchase] = useAtualizarStatuscompraMutation({
        fetchPolicy: 'no-cache',
    })

    const [getServiceByServiceId, { data: getServiceByServiceIdResponse }] =
        useGetServiceByServiceIdLazyQuery({
            fetchPolicy: 'no-cache',
        })

    const [linkUrl, setLinkUrl] = useState<any>()
    const [trackingCode, setTrackingCode] = useState<string>('')

    async function onDeletePurchase() {
        if (sale && sale.id && sale.UserAccount && sale.UserAccount.id) {
            await deletePurchase({
                variables: {
                    purchaseId: sale.id.toString(),
                    userId: sale?.UserAccount.id,
                },
            })
        }
    }

    useEffect(() => {
        if (sale.Tickets?.url) {
            setLinkUrl(
                <>
                    <a
                        href={sale!.Tickets!.url!}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            marginLeft: '0.4rem',
                            border: 'solid 1px',
                            borderRadius: '0.5rem',
                            padding: '0.4rem',
                            fontWeight: 'bold',
                            color: '#05a9c4',
                        }}
                    >
                        Imprimir etiqueta de envio
                    </a>
                </>
            )
        }

        if (sale.serviceId) {
            getServiceByServiceId({
                variables: {
                    serviceId: String(sale.serviceId),
                },
            })
        }
    }, [sale])

    const updateStatus = async () => {
        await getUrl({
            variables: {
                userId: user.id,
                ticketId: sale!.Tickets!.id!,
            },
        })

        if (dataUrl && dataUrl.generateAndPrintTickets.isPaid) {
            setLinkUrl(
                <>
                    <a
                        href={dataUrl && dataUrl.generateAndPrintTickets.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            marginLeft: '0.4rem',
                            border: 'solid 1px',
                            borderRadius: '0.5rem',
                            padding: '0.4rem',
                            fontWeight: 'bold',
                            color: '#05a9c4',
                        }}
                    >
                        Imprimir etiqueta de envio
                    </a>
                </>
            )
        }
        window.location.reload()
    }

    const verifyRatingStars = (rating: number) => {
        switch (rating) {
            case 1:
                return (
                    <>
                        <Star id="star-active"></Star>
                        <Star id="star2"></Star>
                        <Star id="star3"></Star>
                        <Star id="star4"></Star>
                        <Star id="star5"></Star>
                    </>
                )

            case 2:
                return (
                    <>
                        <Star id="star-active"></Star>
                        <Star id="star-active"></Star>
                        <Star id="star3"></Star>
                        <Star id="star4"></Star>
                        <Star id="star5"></Star>
                    </>
                )

            case 3:
                return (
                    <>
                        <Star id="star-active"></Star>
                        <Star id="star-active"></Star>
                        <Star id="star-active"></Star>
                        <Star id="star4"></Star>
                        <Star id="star5"></Star>
                    </>
                )

            case 4:
                return (
                    <>
                        <Star id="star-active"></Star>
                        <Star id="star-active"></Star>
                        <Star id="star-active"></Star>
                        <Star id="star-active"></Star>
                        <Star id="star5"></Star>
                    </>
                )

            case 5:
                return (
                    <>
                        <Star id="star-active"></Star>
                        <Star id="star-active"></Star>
                        <Star id="star-active"></Star>
                        <Star id="star-active"></Star>
                        <Star id="star-active"></Star>
                    </>
                )

            default:
                break
        }
    }

    const buyTickets = async () => {
        try {
            const result = await insertProductOnMelhorEnvioCart({
                variables: {
                    userId: user.id,
                    gateway: user?.melhorEnvioPaymentMethod,
                    ProductInfos: {
                        service: sale?.serviceId!,
                        from: {
                            name: modalRef.current?.nameRemetente as string,
                            phone: user!.ProfileContacts![0]!.phone!,
                            email: user!.email!,
                            document: user!.cpf!,
                            company_document: user!.cpf!,

                            address: sale!.Product!.ProfileAddress!.street!,
                            complement:
                                sale!.Product!.ProfileAddress!.complement!,
                            number: sale!.Product!.ProfileAddress!.number!,
                            district:
                                sale!.Product!.ProfileAddress!.neighborhood!,
                            city: sale!.Product!.ProfileAddress!.city!,
                            country_id: 'BR',
                            postal_code: sale!.Product!.ProfileAddress!.CEP!,
                            note: sale!.Product!.ProfileAddress!.references!,
                        },
                        products: [
                            {
                                name: sale!.Product!.title!,
                                quantity: Number.parseInt(
                                    sale!.productQuantity!
                                ),
                                unitary_value: Number.parseFloat(sale!.price!),
                            },
                        ],
                        to: {
                            name: sale!.UserAccount!.name,
                            phone: '4130853702',
                            email: sale!.UserAccount!.email!,
                            document: sale!.UserAccount!.cpf!,
                            address: sale!.ProfileAddress!.street!,
                            complement: sale!.ProfileAddress!.complement!,
                            number: sale!.ProfileAddress!.number!,
                            district: sale!.ProfileAddress!.neighborhood!,
                            city: sale!.ProfileAddress!.city!,
                            state_abbr: sale!.ProfileAddress!.state!,
                            country_id: 'BR',
                            postal_code: sale!.ProfileAddress!.CEP!,
                            note: sale?.ProfileAddress?.references,
                        },
                        volumes: [
                            {
                                height: sale!.Product!.height!,
                                width: sale!.Product!.width!,
                                length: sale!.Product!.thickness!,
                                weight: Number.parseFloat(
                                    sale!.Product!.weight!
                                ),
                            },
                        ],
                        options: {
                            insurance_value:
                                Number.parseFloat(sale!.price!) *
                                Number.parseInt(sale!.productQuantity!),
                            receipt: false,
                            own_hand: false,
                            reverse: false,
                            non_commercial: true, // TODO: Check necessities for Melhor Envio's JadLog shipping provider

                            invoice: {
                                key: '', // TODO: Check necessities for Melhor Envio's JadLog shipping provider
                            },
                            platform: 'Sambay express',
                            tags: [
                                {
                                    tag: sale!.id,
                                    url: null,
                                },
                            ],
                        },
                    },
                    purchaseId: sale!.id,
                },
            })

            if (
                result.data &&
                result.data.insertProductOnMelhorEnvioCart.url &&
                result.data.insertProductOnMelhorEnvioCart.url !== 'link1'
            ) {
                window.open(result.data.insertProductOnMelhorEnvioCart.url)
            } else {
                alert(
                    'Houve um problema na emissão automática da etiqueta de envio, confira a página "Meus envios" no Melhor Envio ou faça a emissão manual da etiqueta.'
                )
                window.location.reload()
            }
        } catch (error: any) {
            if (
                error.message.substring(15, error.message.length) ===
                'refresh token expired'
            ) {
                window.location.href = `${process.env.REACT_APP_MELHOR_ENVIO_ENDPOINT}/oauth/authorize?client_id=${process.env.REACT_APP_MELHOR_ENVIO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_MELHOR_ENVIO_REDIRECT_URI}&response_type=code&scope=cart-read shipping-generate shipping-print shipping-tracking users-read shipping-calculate shipping-checkout shipping-generate shipping-preview shipping-print cart-write orders-read`
            }
        }
    }

    async function atualizarStatusDaCompra() {
        if (sale && sale.pagarmeId) {
            await atualizarStatusPurchase({
                variables: {
                    atualizarStatusCompraInputData: {
                        pagarmeTransacaoId: sale.pagarmeId,
                        purchaseId: sale.id,
                    },
                },
            })

            window.location.reload()
        }
    }

    const sendInvoice = async (file: any) => {
        try {
            if (
                await addInvoice({
                    variables: { purchaseId: sale.id, purchaseInvoice: file },
                })
            ) {
                window.location.reload()
            }
        } catch (error: any) {
            throw new Error(error.message)
        }
    }

    const addTrackingCode = async (tracking: string) => {
        try {
            if (
                await putTrackingCode({
                    variables: {
                        putTrackingCodeInputData: {
                            purchaseId: sale.id,
                            trackingCode: tracking,
                        },
                    },
                })
            )
                window.location.reload()
        } catch (error: any) {
            throw new Error(error.message)
        }
    }

    const paidPurchase: boolean = sale.Status?.id === 3
    const fullAddress = getFullAddress(sale.ProfileAddress)
    return (
        <>
            <ChoosePaymentMethodMelhorEnvio
                ref={modalRef}
                onClick={() => buyTickets()}
            />
            <CardContainer>
                <div className="product-infos-data-container-1">
                    <div className="purchase-identifiers">
                        <label>Compra: #{sale.id}</label>
                        <label>Pagamento: #{sale.pagarmeId}</label>
                        <label>Produto: #{sale.productId}</label>
                    </div>

                    <div className="product-infos-data-container">
                        <div className="left-side">
                            <Img
                                src={`${process.env.REACT_APP_STATIC_URL}/product/${sale?.Product?.id}-1.jpeg`}
                                alt=" "
                                img={noPhoto}
                            />
                            <div className="style-icon-title-text">
                                <h1>{sale.Product?.title}</h1>
                                {sale.ProductVariations &&
                                    sale.ProductVariations.TamanhoVariations &&
                                    sale.ProductVariations.ColorVariation && (
                                        <VariationContainer>
                                            {sale.ProductVariations
                                                .ColorVariation && (
                                                <>
                                                    Cor:
                                                    <p
                                                        className="variationColorContainer"
                                                        style={{
                                                            backgroundColor:
                                                                sale
                                                                    .ProductVariations
                                                                    .ColorVariation
                                                                    .name,
                                                            color: sale
                                                                .ProductVariations
                                                                .ColorVariation
                                                                .name,
                                                        }}
                                                    >
                                                        0
                                                    </p>
                                                </>
                                            )}
                                            {sale.ProductVariations
                                                .TamanhoVariations && (
                                                <>
                                                    Tamanho:
                                                    <p>
                                                        {
                                                            sale
                                                                .ProductVariations
                                                                .TamanhoVariations
                                                                .name
                                                        }
                                                    </p>
                                                </>
                                            )}
                                        </VariationContainer>
                                    )}
                            </div>
                        </div>
                    </div>
                    <TicketPrintContainer>{linkUrl}</TicketPrintContainer>
                    <div className="product-infos-data-container-2">
                        <div className="product-payment-conditions">
                            <span className="payment-condition">
                                Condições de compra
                            </span>
                            <span className="installments">Quantidade:</span>
                            <h2>{sale.productQuantity}</h2>
                        </div>
                        <div className="product-payment-conditions">
                            <span className="payment-condition">
                                Forma de Pagamento:
                            </span>
                            <h2>
                                {formatPayMethod(sale.paymentMethod as string)}
                            </h2>
                        </div>
                        <div className="product-payment-conditions">
                            <span className="payment-condition">
                                Condições de pagamento
                            </span>
                            <span className="installments">
                                Valor do Produto:
                            </span>

                            {sale.price && sale.productQuantity && (
                                <h3>
                                    {(
                                        Number.parseFloat(sale.price) *
                                        Number.parseFloat(sale.productQuantity)
                                    ).toLocaleString('pt-br', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })}
                                </h3>
                            )}
                            {sale.installmentsNumber && (
                                <span className="installments">
                                    em {sale?.installmentsNumber.toString}x
                                </span>
                            )}
                        </div>
                        {sale.productShippingPrice === '0' ? (
                            <>
                                <div className="payShippingPriceContainer">
                                    <span>Serviço</span>
                                    <span>
                                        {getServiceShipping(
                                            sale.productShippingWay as string
                                        )}
                                    </span>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="payShippingPriceContainer">
                                    <span>Serviço</span>
                                    {getServiceByServiceIdResponse && (
                                        <>
                                            <p>
                                                {
                                                    getServiceByServiceIdResponse
                                                        .getServiceByServiceId
                                                        .name
                                                }
                                            </p>
                                            <img
                                                src={
                                                    getServiceByServiceIdResponse
                                                        .getServiceByServiceId
                                                        .picture
                                                }
                                                alt="servicepicutre"
                                                width={90}
                                                height={40}
                                            />
                                        </>
                                    )}
                                </div>
                                <div className="payShippingPriceContainer">
                                    <span>Valor do Frete</span>
                                    <h2>
                                        {Number.parseFloat(
                                            sale.productShippingPrice!
                                        ).toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL',
                                        })}
                                    </h2>
                                </div>
                            </>
                        )}
                        <div className="product-delivery-conditions">
                            <span className="payment-condition">
                                Status da venda
                            </span>
                            {sale && sale.Status && sale?.Status.id === 3 && (
                                <span className="installments">
                                    {moment(sale.createdAt).format(
                                        'DD/MM/YYYY'
                                    )}{' '}
                                    às {moment(sale.createdAt).format('HH:MM')}
                                </span>
                            )}
                            <h2>{sale.Status?.ptName}</h2>
                            {sale &&
                                sale.Status &&
                                sale.Status.id !== 6 &&
                                sale.Status.id !== 4 && (
                                    <ButtonStatus
                                        onClick={atualizarStatusDaCompra}
                                    >
                                        Atualizar Status
                                    </ButtonStatus>
                                )}
                            {sale && sale.Status && sale.Status.id === 6 && (
                                <>
                                    <Modal
                                        open={{
                                            element: (
                                                <ButtonStatus>
                                                    Avaliar cancelamento
                                                </ButtonStatus>
                                            ),
                                        }}
                                        title={
                                            <span
                                                className="tw-m-0
											tw-p-0 tw-font-primary tw-text-lg
											tw-font-semibold tw-text-primary-700
											sm:tw-text-2xl"
                                            >
                                                Solicitação de cancelamento de
                                                compra.
                                            </span>
                                        }
                                        description={
                                            <>
                                                <p>
                                                    Houve um pedido de
                                                    cancelamento dessa compra
                                                    com a seguinte
                                                    justificativa.
                                                </p>
                                                <div className="tw-w-auto tw-flex-row ">
                                                    <span>
                                                        <b>Justificativa:</b>
                                                        {sale.refuseReason}
                                                    </span>
                                                </div>
                                                <div>
                                                    <h3>
                                                        Deseja efetuar o
                                                        cancelamento ?
                                                    </h3>
                                                </div>
                                            </>
                                        }
                                        action={{
                                            body: {
                                                className: `tw-rounded sm:tw-rounded-sm
												hover:tw-border hover:tw-border-solid
												hover:tw-border-smoke-500
												tw-bg-primary-400 hover:tw-bg-primary-500 tw-font-primary
												tw-text-lg sm:tw-text-2xl
												tw-font-regular tw-text-smoke-50
												tw-py-1 sm:tw-py-2 tw-px-6 sm:tw-px-12`,
                                                element: <>Sim!</>,
                                            },
                                            fn: () => {
                                                onDeletePurchase()
                                            },
                                        }}
                                        close={{
                                            className: `tw-rounded sm:tw-rounded-sm
											tw-bg-smoke-300
											hover:tw-border hover:tw-border-solid
											hover:tw-border-smoke-500
											hover:tw-bg-smoke-400 tw-font-primary
											tw-text-lg sm:tw-text-2xl
											tw-font-regular tw-text-smoke-50
											tw-py-1 sm:tw-py-2 tw-px-6 sm:tw-px-12`,
                                            element: <>Não!</>,
                                        }}
                                    />
                                </>
                            )}
                        </div>
                        <div className="buyer-avaliation">
                            <div className="user-type">
                                <span>Comprador</span>
                            </div>

                            <div className="rating">
                                <div className="user-photo-and-rating">
                                    <div className="img-border">
                                        <Img
                                            src={`${process.env.REACT_APP_STATIC_URL}/user/${sale?.UserAccount?.id}.jpeg`}
                                        />
                                    </div>

                                    <div className="rating">
                                        <span>
                                            {sale.UserAccount?.name}
                                            ...
                                        </span>
                                        {sale!.Product?.ProductsRating
                                            ?.length &&
                                        sale!.Product?.ProductsRating?.find(
                                            (rating) =>
                                                rating!.userAccountId! ===
                                                sale.userAccountId
                                        ) ? (
                                            <div className="rating-stars-container">
                                                {verifyRatingStars(
                                                    sale!.Product?.ProductsRating?.find(
                                                        (rating) =>
                                                            rating!
                                                                .userAccountId ===
                                                            sale.userAccountId
                                                    )?.rating as any
                                                )}
                                            </div>
                                        ) : (
                                            <div className="rating-stars-container">
                                                <span>Não avaliou</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {sale.Status?.id === 3 && (
                        <div className="buyer-information">
                            <h4 className="buyer-information-title">
                                Informações do Comprador
                            </h4>
                            <div className="buyer-address">
                                <span>Nome:{sale.UserAccount?.name}</span>
                                <span>CPF: {sale.UserAccount?.cpf}</span>
                                {sale.Product &&
                                    sale.UserAccount &&
                                    sale.UserAccount.ProfileContacts && (
                                        <>
                                            <span>
                                                Telefone:{' '}
                                                {
                                                    sale.UserAccount
                                                        .ProfileContacts[0]
                                                        .phone
                                                }
                                            </span>
                                        </>
                                    )}
                            </div>
                            <div className="buyer-address">
                                <span>
                                    {fullAddress}
                                    <Copy
                                        size={20}
                                        color="#707070"
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                fullAddress || ''
                                            )
                                        }}
                                        className="copy"
                                    />
                                </span>

                                <a
                                    href={`https://www.google.com/maps/search/?api=1&query=${fullAddress}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="link"
                                >
                                    <Location size={24} color="#6b97a5" />
                                    <label>Acessar no Maps</label>
                                </a>
                            </div>
                            <div className="buyer-contact">
                                <span>
                                    {sale.UserAccount?.email}
                                    <Copy
                                        size={20}
                                        color="#707070"
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                sale.UserAccount?.email || ''
                                            )
                                        }}
                                        className="copy"
                                    />
                                </span>

                                <a
                                    href={`mailto:${sale.UserAccount?.email}`}
                                    className="link"
                                >
                                    <SendEmail size={24} color="#6b97a5" />
                                    <label>Enviar E-Mail</label>
                                </a>
                            </div>
                        </div>
                    )}

                    <div className="middle-size">
                        {paidPurchase && (
                            <>
                                {sale && sale.trackingCode ? (
                                    <>
                                        <label className="pdf-nf">
                                            Código de rastreio:
                                        </label>
                                        <span>{sale.trackingCode}</span>
                                    </>
                                ) : (
                                    <>
                                        <label className="pdf-nf">
                                            Insira o código de rastreio do
                                            envio:
                                        </label>
                                        <input
                                            className="input-invoice"
                                            type="text"
                                            value={trackingCode}
                                            onChange={({ target }) =>
                                                setTrackingCode(
                                                    target.value.toUpperCase()
                                                )
                                            }
                                        />
                                        <button
                                            className="right-side"
                                            onClick={() => {
                                                trackingCode &&
                                                    addTrackingCode(
                                                        trackingCode
                                                    )
                                            }}
                                        >
                                            Enviar
                                        </button>
                                    </>
                                )}
                                {sale && sale.PurchaseInvoice ? (
                                    <>
                                        <GrDocumentPdf size={25} />
                                        <ButtonNfc>
                                            <a href={sale.PurchaseInvoice.path}>
                                                {sale.PurchaseInvoice.name}
                                            </a>
                                        </ButtonNfc>
                                    </>
                                ) : (
                                    <>
                                        <label className="pdf-nf">
                                            Insira o PDF de sua nota fiscal:{' '}
                                        </label>
                                        <input
                                            className="input-invoice"
                                            type="file"
                                            accept="application/pdf"
                                            onChange={({ target }) =>
                                                target.files &&
                                                sendInvoice(target.files[0])
                                            }
                                        />
                                    </>
                                )}
                                {sale.serviceId && !sale.Tickets && (
                                    <>
                                        {user.melhorEnvioToken &&
                                            !sale.ticketId && (
                                                <button
                                                    onClick={() => {
                                                        modalRef.current?.handleOpenModal()
                                                    }}
                                                    className="right-side"
                                                >
                                                    Gerar etiqueta para envio
                                                </button>
                                            )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </CardContainer>
        </>
    )
}

export default SalesCard
