import React, { useCallback, useEffect, useRef, useState } from 'react'
import { NavLink, Route, useHistory, useParams } from 'react-router-dom'
import {
    BlockContainer,
    Card,
    Container,
    Menu,
    MyProductContainer,
    Title,
} from './Style'
import {
    Product,
    useGetBlockStatusQuery,
    useGetProductByUserIdCsvLazyQuery,
    useGetProductByUserIdLazyQuery,
    useGetProductByUserIdQuery,
    useGetProductsByCategoryLazyQuery,
    useGetUserCustomizationQuery,
} from '../../generated/graphql'
import IUserAccount from '../../models/IUserAccount'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../store'
import SellerDashboard from './SellerDashboard/SellerDashboard'
import MyProductsCard from './MyProductsCard'
import InactiveProducts from './InactiveProducts/InactiveProducts'
import Sales from './Sales/Sales'
import bannerEdit from '../../assets/icons/bannerEdit.svg'
import { ReactComponent as UserBrand } from '../../assets/icons/UserBrand.svg'
import searchIcon from '../../assets/icons/searchIcon.svg'
import { appSocket } from '../../appSocket'
import Events from '../../Events'
import CreateUserCustomizations, {
    CustomizationsHandles,
} from './CreateUserCustomizations/CreateUserCustomizations'
import IUserCustomizations from '../../models/IUserCustomizations'
import { insertCustomizations } from '../../actions/UserCustomizations'
import { ReactComponent as Block } from '../../assets/icons/block.svg'
import IEditedProduct from '../../models/EditedProduct'
import UserCupons from './Cupons/UserCupons'
import { FaFileCsv } from '@react-icons/all-files/fa/FaFileCsv'
import {
    MdChevronLeft as Previous,
    MdChevronRight as Next,
} from 'react-icons/md'
import ProductCard from '../ProductCard/ProductCard'
import BlingProducts from './BlingProducts/BlingProducts'
import ShippingMethods from './ShippingMethods/ShippingMethods'
import WarningModal, {
    ProductWarningModalHandles,
} from '../ProductView/ProductCard/ProductSaleCard/ProductWarningModal/ProductWarningModal'

interface ShoppingParams {
    id: string
}
const Shopping: React.FC = () => {
    const modalRef = useRef<ProductWarningModalHandles>(null)
    const user: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )
    const params: ShoppingParams = useParams()
    const isOwner = Number.parseInt(params?.id) === user?.id
    const [limit, setLimit] = useState<number>(30)
    const [offset, setOffset] = useState<number>(0)

    const { data, loading } = useGetProductByUserIdQuery({
        variables: {
            getProductByUserId: {
                type: 'pagination',
                userId: Number.parseInt(params.id),
                offset: isOwner ? offset : undefined,
                limit: isOwner ? limit : undefined,
                active: true,
            },
        },
        fetchPolicy: 'network-only',
    })
    const [
        getProduct,
        { data: getProductLazy, loading: getProductLazyLoading },
    ] = useGetProductByUserIdLazyQuery({
        fetchPolicy: 'network-only',
    })
    const { data: dataCustomizations, loading: loadingCustomizations } =
        useGetUserCustomizationQuery({
            variables: { userId: Number.parseInt(params.id) },
            fetchPolicy: 'network-only',
        })
    const [getProductsCsv, { data: dataGetProductsCsv }] =
        useGetProductByUserIdCsvLazyQuery({
            fetchPolicy: 'network-only',
        })
    const [
        getProductsByCategory,
        { data: dataGetByCategory, loading: loadingGetByCategory, error },
    ] = useGetProductsByCategoryLazyQuery({
        fetchPolicy: 'network-only',
    })
    const { data: dataUserBlockStatus, loading: loadingBlockUserStatus } =
        useGetBlockStatusQuery({
            variables: { userId: Number.parseInt(params.id) },
        })

    const history = useHistory()
    const [searchValue, setSearchValue] = useState<string>()
    const [products, setProducts] = useState<any[] | undefined>()
    useEffect(() => {
        if (!loading) {
            setProducts(data?.getProductByUserId)
        }
    }, [data])

    useEffect(() => {
        if (!getProductLazyLoading) {
            setProducts(getProductLazy?.getProductByUserId)
        }
    }, [getProductLazy])

    const createUserCustomizations = useRef<CustomizationsHandles>(null)
    const userCustomizations: IUserCustomizations = useSelector(
        (state: AppState) => state.UserCustomizations.currentCustomizations
    )

    const editedProduct: IEditedProduct = useSelector(
        (state: AppState) => state.EditedProduct.editedProduct
    )

    const dispatch = useDispatch()

    useEffect(() => {
        if (
            dataUserBlockStatus?.getBlockStatus &&
            user.id !== Number.parseInt(params.id)
        ) {
            history.goBack()
        }
    }, [dataUserBlockStatus, params, user])

    useEffect(() => {
        dispatch(
            insertCustomizations(
                dataCustomizations?.getUserCustomization as any
            )
        )
    }, [dataCustomizations])

    useEffect(() => {
        if (!loadingGetByCategory) {
            setProducts(dataGetByCategory?.getProductsByCategory)
        }
    }, [dataGetByCategory])

    const openCustomizations = useCallback(() => {
        createUserCustomizations.current?.showEditCustomizations()
    }, [])

    appSocket.on(Events.RESULT_SEARCH, (result: Product[]) => {
        setProducts(result)
    })

    useEffect(() => {
        if (dataGetProductsCsv) {
            const blob = new Blob([dataGetProductsCsv.getProductByUserIdCsv], {
                type: 'application/x-msexcel',
            })
            const downloadLink = document.createElement('a')
            document.body.append(downloadLink)
            downloadLink.setAttribute('type', 'hidden')
            downloadLink.href = window.URL.createObjectURL(blob)
            downloadLink.download = `produtos_cadastrados.csv`
            downloadLink.click()
        }
    }, [dataGetProductsCsv])

    useEffect(() => {
        if (searchValue) {
            getProduct({
                variables: {
                    getProductByUserId: {
                        value: searchValue,
                        type: 'search',
                        userId: Number.parseInt(params.id),
                    },
                },
            })
        }
        if (searchValue?.length === 0) {
            setProducts(data?.getProductByUserId)
        }
    }, [searchValue])

    const searchFunc = (): void => {
        if (searchValue) {
            appSocket.emit(
                Events.SEARCH_PRODUCTS,
                searchValue,
                undefined,
                'search'
            )
        }
    }
    function handleDownloadCsv(): void {
        getProductsCsv({
            variables: {
                getProductByUserIdCsv: {
                    userId: Number.parseInt(params.id),
                },
            },
        })
    }

    const getResults = (increase: boolean, length: number): void => {
        let newOffset = offset
        if (increase) {
            if (length === limit) {
                newOffset += limit
            }
        } else {
            if (offset - limit < 0) {
                newOffset = 0
            } else {
                newOffset -= limit
            }
        }

        getProduct({
            variables: {
                getProductByUserId: {
                    type: 'pagination',
                    userId: Number.parseInt(params.id),
                    offset: newOffset,
                    limit,
                    active: true,
                },
            },
        })

        setOffset(newOffset)
    }

    useEffect(() => {
        if (products) {
            const restricted = products.find(
                (product: Product) =>
                    product.categorieId === 50 ||
                    product.categorieId === 46 ||
                    product.categorieId === 65
            ) as Product

            if (restricted && !isOwner) {
                modalRef.current?.handleCloseModal()
            }
        }
    }, [products, isOwner])

    return (
        <>
            <WarningModal ref={modalRef} />

            <CreateUserCustomizations
                ref={createUserCustomizations}
                userId={Number.parseInt(params.id)}
            />

            <Container
                bannerColor={userCustomizations?.brandColor}
                textColor={userCustomizations?.bannerTextColor}
                backgroundColor={
                    userCustomizations?.hasBackground
                        ? `url(${process.env.REACT_APP_STATIC_URL}/banner/${userCustomizations?.background})`
                        : `${userCustomizations?.background}`
                }
            >
                {window.outerWidth < 768 ? (
                    <></>
                ) : (
                    <>
                        {Number.parseInt(params.id) === user?.id && (
                            <div
                                className="active-customization"
                                onClick={() => openCustomizations()}
                            >
                                <span>Editar capa</span>
                                <img src={bannerEdit}></img>
                            </div>
                        )}
                    </>
                )}

                <div className="brand-container">
                    {!userCustomizations?.hasBanner ? (
                        <>
                            {userCustomizations?.ShopName && (
                                <>
                                    <UserBrand id="brand" />
                                    <div className="title-container">
                                        <h1>{userCustomizations?.ShopName}</h1>
                                    </div>
                                </>
                            )}
                        </>
                    ) : (
                        <img
                            src={`${process.env.REACT_APP_STATIC_URL}/brand/${userCustomizations?.brand}`}
                            style={{
                                width: 'auto',
                                height: '190px',
                            }}
                        />
                    )}
                </div>
            </Container>

            {loadingCustomizations && (
                <Container>
                    <div className="ui small active centered inline loader"></div>
                </Container>
            )}
            {user && user.id === Number.parseInt(params.id) && (
                <>
                    <Menu>
                        <NavLink
                            to={`/shopping/${params.id}/dashboard`}
                            className="
                                tw-text-md item
                                tw-font-primary tw-font-semibold
                                sm:tw-text-xl"
                            activeClassName="tw-text-primary-700"
                        >
                            Painel
                        </NavLink>
                        <NavLink
                            to={`/shopping/${params.id}/products`}
                            className="
                                tw-text-md item
                                tw-font-primary tw-font-semibold
                                sm:tw-text-xl"
                            activeClassName="tw-text-primary-700"
                        >
                            Meus Produtos
                        </NavLink>
                        <NavLink
                            to={`/shopping/${params.id}/disabled-products`}
                            className="
                                tw-text-md item
                                tw-font-primary tw-font-semibold
                                sm:tw-text-xl"
                            activeClassName="tw-text-primary-700"
                        >
                            Produtos inativos
                        </NavLink>

                        <NavLink
                            to={`/shopping/${params.id}/my-sales`}
                            className="
                                tw-text-md item
                                tw-font-primary tw-font-semibold
                                sm:tw-text-xl"
                            activeClassName="tw-text-primary-700"
                        >
                            Vendas
                        </NavLink>
                        <NavLink
                            to={`/shopping/${params.id}/my-cupons`}
                            className="
                                tw-text-md item
                                tw-font-primary tw-font-semibold
                                sm:tw-text-xl"
                            activeClassName="tw-text-primary-700"
                        >
                            Cupons
                        </NavLink>
                        <NavLink
                            to={`/shopping/${params.id}/shipping-methods`}
                            className="
                                tw-text-md item
                                tw-font-primary tw-font-semibold
                                sm:tw-text-xl"
                            activeClassName="tw-text-primary-700"
                        >
                            Envios
                        </NavLink>
                        <NavLink
                            to={`/shopping/${params.id}/bling-products`}
                            className="
                                tw-text-md item
                                tw-font-primary tw-font-semibold
                                sm:tw-text-xl"
                            activeClassName="tw-text-primary-700"
                        >
                            Produtos do Bling!
                        </NavLink>
                        <NavLink
                            to={`/commission-policy`}
                            className="
                                tw-text-md item
                                tw-font-primary tw-font-semibold
                                sm:tw-text-xl"
                        >
                            Taxas e Comissões
                        </NavLink>
                        <div className="btn">
                            <button
                                onClick={() =>
                                    history.push(
                                        '/register-product/:categoryId/product'
                                    )
                                }
                                style={{
                                    opacity: `${user.block ? '0.3' : '1'}`,
                                }}
                                disabled={!!user.block}
                            >
                                Anuncie
                            </button>
                        </div>
                    </Menu>
                    {user.block && (
                        <BlockContainer>
                            <div className="block-card">
                                <Block className="block-icon-svg" />
                                <div className="block-infos">
                                    <h1>
                                        Bloqueamos sua conta temporariamente
                                    </h1>
                                    <span>
                                        Suspendemos suas atividades internas, e
                                        todos os seus anúncios
                                    </span>
                                </div>
                            </div>
                        </BlockContainer>
                    )}
                </>
            )}
            <Route path={`/shopping/${params?.id}/products`}>
                <MyProductContainer>
                    <Title>
                        {Number.parseInt(params?.id) === user?.id && (
                            <div className="title-organizer">
                                <div className="title-container">
                                    <h2>Meus Produtos</h2>
                                    <FaFileCsv
                                        onClick={handleDownloadCsv}
                                        size={26}
                                        className={`tw-text-primary-500`}
                                        style={{
                                            marginTop: '0.2rem',
                                            cursor: 'pointer',
                                        }}
                                    />
                                </div>
                                <div className="tw-flex tw-flex-col tw-items-center tw-space-y-2 sm:tw-flex-row sm:tw-space-y-0 sm:tw-space-x-2">
                                    <div>
                                        <select
                                            name="Produtos por página"
                                            id="limit"
                                            onChange={(e) =>
                                                setLimit(
                                                    Number.parseInt(
                                                        e.target.value
                                                    )
                                                )
                                            }
                                            className={`disabled:tw-cursor-not-allowed`}
                                            disabled={
                                                searchValue !== undefined &&
                                                searchValue.length > 0
                                            }
                                        >
                                            <option value="10">
                                                10 produtos por página
                                            </option>
                                            <option value="20">
                                                20 produtos por página
                                            </option>
                                            <option value="30" selected>
                                                30 produtos por página
                                            </option>
                                            <option value="40">
                                                40 produtos por página
                                            </option>
                                            <option value="50">
                                                50 produtos por página
                                            </option>
                                            <option value="100">
                                                100 produtos por página
                                            </option>
                                        </select>
                                    </div>
                                    <div className="pagination-container tw-space-x-2">
                                        <button
                                            className={`
                                            tw-bg-smoke-100 
                                            tw-text-black
                                            disabled:tw-cursor-not-allowed
                                            disabled:tw-bg-white
                                            disabled:tw-text-smoke-300`}
                                            disabled={
                                                offset === 0 ||
                                                (searchValue !== undefined &&
                                                    searchValue.length > 0)
                                            }
                                            onClick={() => {
                                                if (products && offset > 0)
                                                    getResults(
                                                        false,
                                                        products.length
                                                    )
                                            }}
                                        >
                                            <Previous size={24} />
                                        </button>
                                        <button
                                            className={`
                                            tw-bg-smoke-100 
                                            tw-text-black
                                            disabled:tw-cursor-not-allowed
                                            disabled:tw-bg-white
                                            disabled:tw-text-smoke-300`}
                                            disabled={
                                                (products &&
                                                    products.length < limit) ||
                                                (searchValue !== undefined &&
                                                    searchValue.length > 0)
                                            }
                                            onClick={() => {
                                                if (
                                                    products &&
                                                    products.length === limit
                                                )
                                                    getResults(
                                                        true,
                                                        products.length
                                                    )
                                            }}
                                        >
                                            <Next size={24} />
                                        </button>
                                    </div>

                                    <div className="search-container">
                                        <input
                                            type="text"
                                            placeholder="Busque um produto pelo Nome, Marca ou Tipo de Negociação"
                                            onChange={(e) =>
                                                setSearchValue(e.target.value)
                                            }
                                        />
                                        <img src={searchIcon} alt="" />
                                    </div>
                                </div>
                            </div>
                        )}
                        {Number.parseInt(params?.id) !== user?.id && (
                            <div className="title-organizer">
                                <h2>Produtos</h2>
                                <div className="search-container">
                                    <input
                                        type="text"
                                        placeholder="Busque um produto pelo Nome, Marca e Tipo de Negociação"
                                        onChange={(e) =>
                                            setSearchValue(e.target.value)
                                        }
                                    />
                                    <img src={searchIcon} alt="" />
                                </div>
                            </div>
                        )}
                    </Title>
                    <Card>
                        <div className="product-cards-container tw-pt-6">
                            {products?.map((product: any, index: number) => {
                                if (product.active) {
                                    return Number.parseInt(params.id) ===
                                        user?.id ? (
                                        <MyProductsCard
                                            product={product}
                                            key={index}
                                        />
                                    ) : (
                                        <div className="tw-p-3">
                                            <ProductCard
                                                product={product}
                                                key={params.id}
                                            />
                                        </div>
                                    )
                                }
                                return <></>
                            })}
                        </div>
                        {Number.parseInt(params?.id) === user?.id && (
                            <div
                                className={`
                                    pagination-container
                                    tw-flex tw-flex-row
                                    tw-items-center
                                    tw-justify-center
                                    tw-space-x-2
                                    tw-pb-6
                                `}
                            >
                                <button
                                    className={`
                                        tw-flex
                                        tw-flex-row
                                        tw-items-center 
                                        tw-rounded-sm
                                        tw-font-primary
                                        tw-text-xl
                                        tw-font-semibold
                                        tw-text-primary-700
                                        disabled:tw-cursor-not-allowed
                                        disabled:tw-text-smoke-300
                                    `}
                                    disabled={
                                        offset === 0 ||
                                        (searchValue !== undefined &&
                                            searchValue.length > 0)
                                    }
                                    onClick={() => {
                                        if (products && offset > 0)
                                            getResults(false, products.length)
                                    }}
                                >
                                    <Previous size={32} />
                                    <span className="tw-px-2 tw-py-4">
                                        Anterior
                                    </span>
                                </button>
                                <button
                                    className={`
                                            tw-flex
                                            tw-flex-row
                                            tw-items-center
                                            tw-rounded-md
                                            tw-font-primary
                                            tw-text-xl
                                            tw-font-semibold
                                            tw-text-primary-700
                                            disabled:tw-cursor-not-allowed
                                            disabled:tw-text-smoke-300`}
                                    disabled={
                                        (products && products.length < limit) ||
                                        (searchValue !== undefined &&
                                            searchValue.length > 0)
                                    }
                                    onClick={() => {
                                        if (
                                            products &&
                                            products.length === limit
                                        )
                                            getResults(true, products.length)
                                    }}
                                >
                                    <span className="tw-px-2 tw-py-4">
                                        Próxima
                                    </span>
                                    <Next size={32} />
                                </button>
                            </div>
                        )}
                    </Card>
                </MyProductContainer>
            </Route>
            <Route path={`/shopping/${params.id}/dashboard`}>
                <SellerDashboard />
            </Route>
            <Route path={`/shopping/${params.id}/disabled-products`}>
                <InactiveProducts />
            </Route>
            <Route path={`/shopping/${params.id}/my-sales`}>
                <Sales />
            </Route>
            <Route exact path={`/shopping/${params.id}/my-cupons`}>
                <UserCupons />
            </Route>
            <Route exact path={`/shopping/${params.id}/shipping-methods`}>
                <ShippingMethods />
            </Route>
            <Route exact path={`/shopping/${params.id}/bling-products`}>
                <BlingProducts />
            </Route>
        </>
    )
}
export default Shopping
