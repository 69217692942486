import styled from 'styled-components'

interface ContainerProps {
    bannerColor?: string | null
    textColor?: string | null
    backgroundColor?: string
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${(Prop) => Prop.backgroundColor};
    height: 280px;
    background-size: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #fff;
    .brand-container {
        height: 300px;

        /* margin-top: 5px; */
        #brand {
            width: 599px;
            height: 167px;

            .a {
                fill: ${(Prop) => Prop.bannerColor};
            }
            g {
                .b {
                    stroke: ${(Prop) => Prop.textColor};
                }
                .c {
                    fill: ${(Prop) => Prop.textColor};
                }
            }
        }
        .title-container {
            position: relative;
            top: -135px;
            left: 133px;
            width: 370px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            h1 {
                color: ${(Prop) => Prop.textColor};
                font-size: 32px;
                font-family: 'Poppins', semi-bold;
                font-weight: 600;
                text-align: center;
                word-break: break-word;
            }
        }
    }

    .active-customization {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #000000;
        opacity: 0.8;
        margin-top: 20px;
        margin-right: 20px;
        border-radius: 4px;
        justify-self: flex-end;
        justify-content: center;
        align-self: flex-end;
        width: 123px;
        height: 39px;
        span {
            color: #ffffff;
            font-size: 14px;
            font-family: 'Poppins';
            font-weight: bold;
        }
        img {
            margin-left: 3px;
        }
    }
    .active-customization:hover {
        cursor: pointer;
    }
    .hexagon-container {
        width: 1200px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 298px;
        .hexagon {
            height: 200px;
            width: 680px;
            background: white;
        }
        .hexagon:before,
        .hexagon:after {
            position: absolute;
            width: 0;
            border-top: 100px solid transparent;
            border-bottom: 100px solid transparent;
            content: '';
        }
        .hexagon:before {
            right: 67.86%;
            border-right: 133px solid white;
        }
        .hexagon:after {
            left: 67.91%;
            border-left: 133px solid white;
        }
    }
    @media (min-width: 425px) and (max-width: 475px) {
        height: 145px;
        .brand-container {
            width: 100%;
            #brand {
                width: 100%;
                height: 167px;
            }
            .title-container {
                top: -110px;
                width: 100%;
                left: 0px;
                h1 {
                    color: ${(Prop) => Prop.textColor};
                    font-size: 20px;
                }
            }
        }
    }
    @media (min-width: 375px) and (max-width: 424px) {
        height: 145px;
        .brand-container {
            width: 100%;
            #brand {
                width: 100%;
                height: 167px;
            }
            .title-container {
                top: -110px;
                left: 0px;
                width: 100%;
                h1 {
                    color: ${(Prop) => Prop.textColor};
                    font-size: 20px;
                }
            }
        }
    }
    @media (min-width: 320px) and (max-width: 374px) {
        height: 145px;
        .brand-container {
            width: 100%;
            #brand {
                width: 95%;
                height: 167px;
                width: 100%;
            }
            .title-container {
                top: -110px;
                left: 0px;
                width: 100%;
                h1 {
                    color: ${(Prop) => Prop.textColor};
                    font-size: 20px;
                }
            }
        }
    }
`

export const MyProductContainer = styled.div`
    padding: 80px;
    padding-top: 30px;
    padding-left: 120px;
    padding-right: 120px;
    background-color: #fff;

    @media (min-width: 425px) and (max-width: 475px) {
        padding: 0px;
    }
    @media (min-width: 375px) and (max-width: 424px) {
        padding: 0px;
    }
    @media (min-width: 320px) and (max-width: 374px) {
        padding: 0px;
    }
`
export const Menu = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    height: 56px;
    align-items: center;
    border-top: 1px solid black;
    background-color: #fff;
    .item {
        padding: 10px;
        padding: 0px 30px 0px 30px;
        height: 100%;
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 13px;
        color: #8b8b8b;
    }
    .item-active {
        border-bottom: solid 3px #6b97b0;
        padding: 10px;
        padding: 0px 30px 0px 30px;
        height: 100%;
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 13px;
        color: #6b97a5;
    }
    .btn {
        margin-left: 15px;
        button {
            color: white;
            font-family: 'Poppins';
            font-weight: 600;
            background-color: #6b97a5;
            border: none;
            align-self: center;
            font-size: 20px;
            padding: 5px;
            width: 129px;
            border-radius: 7px;
        }
        button:focus {
            outline: none;
        }
        button:hover {
            cursor: pointer;
        }
    }

    @media (min-width: 425px) and (max-width: 475px) {
        column-gap: 25px;
        .item {
            padding: 0;
        }
        .item-active {
            padding: 0;
        }
        .btn {
            display: none;
        }
    }
    @media (min-width: 375px) and (max-width: 424px) {
        column-gap: 20px;
        .item {
            padding: 0;
            font-size: 12px;
        }
        .item-active {
            padding: 0;
            font-size: 12px;
        }
        .btn {
            display: none;
        }
    }
    @media (min-width: 320px) and (max-width: 374px) {
        column-gap: 10px;
        .item {
            padding: 0;
            font-size: 11px;
        }
        .item-active {
            padding: 0;
            font-size: 11px;
        }
        .btn {
            display: none;
        }
    }
`
export const Title = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 1px;
    .title-organizer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        border-bottom: solid 1px black;
        padding-bottom: 13px;
        .title-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            h2 {
                font-size: 28px;
                color: #6b97a5;
                font-family: 'Poppins', semi-bold;
                font-weight: 600;
                margin-right: 4px;
            }
        }

        div {
            label {
                color: #c5c5c5;
                font-family: 'Poppins';
                font-weight: 600;
                font-size: 15px;
                margin-right: 8px;
            }
            select {
                width: 250px;
                height: 35px;
                border-radius:8px;
                border: solid 1px #838B83;
            }
            select:focus {
                outline: none;
            }
            button {
                height: 35px;
                border-radius:8px;
                border: solid 1px #838B83;
            }
        }
        .search-container {
            width: 25vw;
            height: 35px;
            border: solid 1px #838B83;
            border-radius:8px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            input {
                border: none;
                width: 90%;
                background-color: #fff;
            }
            input:focus {
                outline: none;
            }
        }
    }
    @media (max-width: 767px) {
        .title-organizer {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            .title-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                h2 {
                    font-size: 25px;
                    padding-left: 2rem;
                    padding-top: 2rem;
                }
            }
            .search-container {
                width: 100%;
            }
        }
    }
    @media (min-width: 375px) and (max-width: 424px) {
        .title-organizer {
            .title-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                h2 {
                    font-size: 25px;
                    padding-left: 2rem;
                    padding-top: 2rem;
                }
            }
        }
    }
    @media (min-width: 320px) and (max-width: 374px) {
        .title-organizer {
            .title-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                h2 {
                    font-size: 20px;
                    padding-left: 2rem;
                    padding-top: 2rem;
                }
            }
        }
    }
`
export const Card = styled.div`
    .product-cards-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .product-cards {
            position: relative;
            display: flex;
            flex-direction: column;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 2px 8px #00000014;
            border-radius: 8px;
            margin-right: 48px;
            margin-bottom: 40px;
            width: 181px;

            img {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                width: 100%;
                height: 139px;
            }

            .product-user-views {
                position: absolute;
                display: flex;
                flex-direction: row;
                align-content: center;
                justify-content: space-evenly;
                top: 30%;
                left: 0.25rem;
                margin: 0.25rem;
                padding: 0.25rem;
                background-color: white;
                height: 2.25rem;
                width: 4rem;
                border-radius: 0.25rem;

                span {
                    font-family: 'Poppins', regular;
                    font-weight: 600;
                    color: #5c5c5c;
                    font-size: 14px;
                }
            }

            .product-card-content {
                padding: 12px 4px 12px;
                border-radius: 8px;
                border-bottom: solid 8px #69b64a;
                height: 155px;
                word-break: break-word;
                flex-wrap: wrap;
                padding-bottom: 0px;
                width: 100%;
                height: 171px;
                h2 {
                    color: black;
                    font-family: 'Poppins';
                    font-weight: bold;
                    font-size: 13px;
                }

                p {
                    color: black;
                    font-size: 12px;
                    height: 36px;
                }
                .price-container {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    h2 {
                        font-family: 'Poppins', semi-bold;
                        font-weight: 600;
                        color: #6b98a6;
                        font-size: 20px;
                        margin: 0;
                    }
                    span {
                        font-family: 'Poppins', regular;
                        color: black;
                        font-size: 12px;
                        font-weight: 600;
                    }

                    p {
                        word-wrap: break-word;
                        height: 32px;
                    }
                }
            }
            .options-category {
                display: inline-block;
                position: absolute;
                height: 30px;
                top: 0.25rem;
                right: 0.25rem;
                border-radius: 10px;
                display: flex;
                justify-content: center;

                .img-background {
                    width: 31px;
                    height: 31px;
                    background-color: #6b98a6;
                    margin: 0.25rem;
                    border-radius: 4px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    .img {
                        width: 24px;
                        height: 24px;
                    }
                }
                .img-background:hover {
                    cursor: pointer;
                }
            }
            .options-inactive-category {
                position: absolute;
                transform: translate(50%, 50%);
                left: 55px;
                height: 22px;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                img {
                    position: absolute;
                    padding: 7px 0;
                    height: 50px;
                    width: 50px;
                }
                .img {
                    position: absolute;
                    padding: 7px 0;
                    height: 50px;
                    width: 50px;
                }
                .img-background {
                    width: 31px;
                    height: 31px;
                    background-color: #6b98a6;
                    margin-left: 3rem;
                    border-radius: 4px;

                    @media (max-width: 767px) {
                        margin-left: 0;
                        width: 0;
                    }
                }
                .img-background:hover {
                    cursor: pointer;
                }

                @media (min-width: 425px) and (max-width: 475px) {
                    left: 50px;
                }
                @media (min-width: 375px) and (max-width: 424px) {
                    left: 15px;
                }
                @media (min-width: 320px) and (max-width: 374px) {
                    left: 15px;
                }
            }
            .delete-edit {
                position: absolute;
                background-color: #f9f9f9;
                right: -99px;
                top: 40px;
                display: flex;
                flex-direction: column;
                border-radius: 8px;
                .options:hover {
                    background-color: #dedede;
                }
                .options {
                    padding-left: 15px;
                    user-select: none;
                    cursor: pointer;
                    .trash {
                        display: flex;
                        flex-direction: row;
                    }
                    .confirm {
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        justify-content: space-around;
                    }
                    .options-icon {
                        height: 25px;
                        width: 25px;
                        img {
                            height: 100%;
                            width: 100%;
                        }
                    }
                    .options-text {
                        color: #a4a4a4;
                        font-family: $lato;
                        font-weight: bold;
                        padding: 7px;
                        font-size: 16px;
                    }
                }
                .options.options-top {
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    height: 100%;
                }
                .options.options-bottom {
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
    @media (min-width: 425px) and (max-width: 475px) {
        .product-cards-container {
            padding-left: 2.5rem;
            column-gap: 0.5rem;
            .product-cards {
                margin-right: 0;
            }
        }
    }
    @media (min-width: 375px) and (max-width: 424px) {
        .product-cards-container {
            padding-left: 0;
            column-gap: 0.5rem;
            .product-cards {
                margin-right: 0;
            }
        }
    }
    @media (min-width: 320px) and (max-width: 374px) {
        .product-cards-container {
            padding-left: 0.5rem;
            column-gap: 0.5rem;
            .product-cards {
                margin-right: 0;
                width: 47%;
            }
        }
    }
`

export const OptionsContainer = styled.div``

export const BlockContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

    .block-card {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 55%;
        max-width: 1045px;
        min-width: 900px;
        height: 134px;
        border-radius: 8px;
        background: #fff;

        .block-icon-svg {
            width: 67px;
            height: 67px;
            fill: #db0707;
        }

        .block-infos {
            display: flex;
            flex-direction: column;
            margin-left: 15px;

            h1 {
                margin: 0;
                color: #6b98a6;
                font-family: 'Poppins';
                font-weight: 600;
                font-size: 16px;
            }
            span {
                color: black;
                font-size: 12px;
                font-family: 'Poppins';
                font-weight: 400;
            }
        }
    }
`
