import styled from 'styled-components';

export const Container = styled.div`
	background-color: #04aac0;
	padding-top: 16px;
	padding-bottom: 16px;
	padding-right: 180px;
	padding-left: 180px;
	@media (min-width: 768px) and (max-width: 1022px) {
		height: 100px;
		padding-right: 90px;
		padding-left: 90px;
		padding-top: 18px;
	}
	@media (max-width: 475px) {
		height: 100px;
		padding:0;
	}
	.section-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		height: 30px;
		.section-header-initial-menu {
			display: flex;
			justify-content: start;
			flex-direction: row;
			height: 28px;
			.style-title-sale-at-sambay {
				font-family: 'Poppins';
				color: white;
				font-weight: 500;
				font-size: 16px;
				padding-right: 16px;
				cursor: pointer;
				padding-top: 5px;
			}
			.style-span-follow-us {
				font-family: 'Poppins';
				color: white;
				font-weight: 500;
				font-size: 16px;
				border-left: 1px solid white;
				padding-left: 16px;
				padding-right: 8.67px;
				display: flex;
				align-items: center;
			}
			.style-icon-linkedin-header-menu {
				width: 30.72px;
				height: 30.72px;
			}
			.style-icon-instagram-header-menu {
				width: 30.72px;
				height: 30.72px;
				margin-left: 9.25px;
				margin-right: 9.25px;
			}
			.style-icon-facebook-header-menu {
				width: 29px;
				height: 29px;
			}
		}
		.section-header-second-menu {
			display: flex;
			justify-content: end;
			flex-direction: row;
			height: 28px;
			.style-icon-notification {
				width: 26px;
				height: 22px;
				cursor: pointer;
			}
			.style-span-notifications {
				font-size: 16px;
				color: white;
				font-weight: 500;
				font-family: 'Poppins';
				padding-left: 12px;
				padding-top: 5px;
				padding-right: 25px;
				cursor: pointer;
			}
			.style-icon-help {
				height: 26px;
				width: 22px;
				cursor: pointer;
			}
			.style-span-help {
				font-size: 16px;
				color: white;
				font-weight: 500;
				font-family: 'Poppins';
				padding-left: 10px;
				padding-right: 25px;
				cursor: pointer;
				display: flex;
				align-items: center;
				padding-top: 5px;
			}
			.style-span-register {
				font-size: 16px;
				color: white;
				font-weight: 500;
				font-family: 'Poppins';
				cursor: pointer;
				padding-right: 16px;
				display: flex;
				align-items: center;
				padding-top: 5px;
			}
			.style-span-login {
				font-size: 16px;
				color: white;
				font-weight: 500;
				font-family: 'Poppins';
				cursor: pointer;
				border-left: 1px solid white;
				padding-left: 16px;
				display: flex;
				align-items: center;
				padding-top: 5px;
			}
			.button-anuncie {
				width: 100px;
				height: 30px;
				border-radius: 10px;
				border: none;
				background-color: white;
				color: rgb(62, 105, 174);
				font-size: 16px;
				font-family: 'Poppins';
				font-weight: 700;
				padding-top: 3px;
				cursor: pointer;
				@media (max-width:475px){
					background-color:white;
					color:color:rgb(62,105,174);
				}
			}
			/// CSS ISNERIDO NOTIFICATIONS
			.notifications-alert-container {
				position: relative;
				top: 1px;
				left: 10px;
				width: 20px;
				height: 20px;
				.notifications-alert {
					width: 11px;
					height: 11px;
					background: red;
					position: absolute;
					top: 1px;
					left: -31px;
					border-radius: 9px;

					@media screen and (max-width: 1366px) {
						width: 9px;
						height: 9px;
						left: -27px;
					}
				}
			}
		}
	}
	.section-subtitle-second {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		.logo_sambay {
			width: 220px;
			height: auto;
			margin-top: 15px;
			cursor: pointer;
		}
		.section-input-search {
			display: flex;
			margin-top: 30px;
			.section-search-bar {
				display: flex;
				flex-direction: row;
				input {
					background-color: white;
					display: flex;
					flex-direction: row;
					width: 550px;
					height: 50px;
					border-radius: 10px;
					border: none;
					color: #797979;
					font-family: 'Poppins';
					font-size: 18px;
					font-weight: 500;
					padding-left: 16px;
					outline-color: #04aac0;
					position: relative;
				}
				.search-icon {
					position: absolute;
					float: right;
					display: flex;
					flex-direction: row;
					justify-content: end;
				}
			}
		}
		.section-shopping-cart {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			margin: 32px;
			.style-icon-carrinho-de-compras {
				width: 40px;
				cursor: pointer;
			}
		}
		.section-icons {
			display: flex;
			.style-icon-novembro-azul {
				width: 85px;
				height: 85px;
				margin-top: 1rem;
			}
			.style-icon-bandeira {
				margin-top: 15px;
				margin-left: 35px;
			}
		}
		.search-bar {
			background-color: #fff;
			width: 572px;
			height: 50px;
			border-radius: 10px;
			display: flex;
			flex-direction: row;
			justify-content: start;
			align-items: center;
			margin-top: 26px;

			.items-container {
				z-index: 1;
				display: flex;
				flex-direction: row;
				width: 100%;
				align-items: center;
				background-color: white;
				height: 0;
				justify-content: space-between;
				input {
					border-radius: 7px;
					border: none;
					font-family: 'Poppins';
					font-weight: 500;
					color: #797979;
					font-size: 18px;
					outline: none;
					padding-left: 1rem;
					width: 87%;
					background-color: #fff;
				}
				.search-bar-size {
					width: 69px;
					height: 46px;
					display: flex;
					background-color: #FFC000;
					justify-content: center;
					align-items: center;
					border-radius: 7px;
					cursor: pointer;
					margin-right: 2px;
					.search-icon {
						width: 28px;
						height: 28px;
					}
				}
			}
		}
	}
	.section-subtitle-third {
		display: flex;
		flex-direction: row;
		justify-content: center;
		.section-row-third {
			display: flex;
			flex-direction: row;
			justify-content: center;
			height: 20px;
			margin-right: 5rem;
			.style-zequinha {
				font-family: 'Poppins';
				color: white;
				font-size: 12px;
				font-weight: 400;
				cursor: pointer;
				padding-right: 10px;
			}
			.style-noslen {
				font-family: 'Poppins';
				color: white;
				font-size: 12px;
				font-weight: 400;
				cursor: pointer;
				border-left: 1px solid white;
				padding-right: 10px;
				padding-left: 10px;
			}
			.style-colecao {
				font-family: 'Poppins';
				color: white;
				font-size: 12px;
				font-weight: 400;
				cursor: pointer;
				border-left: 1px solid white;
				padding-left: 10px;
				padding-right: 10px;
			}
			.style-pintura {
				font-family: 'Poppins';
				color: white;
				font-size: 12px;
				font-weight: 400;
				cursor: pointer;
				border-left: 1px solid white;
				padding-left: 10px;
				padding-right: 10px;
			}
			.style-roupas {
				font-family: 'Poppins';
				color: white;
				font-size: 12px;
				font-weight: 400;
				cursor: pointer;
				border-left: 1px solid white;
				padding-right: 10px;
				padding-left: 10px;
			}
			.style-figurinha {
				font-family: 'Poppins';
				color: white;
				font-size: 12px;
				font-weight: 400;
				cursor: pointer;
				border-left: 1px solid white;
				padding-left: 10px;
			}
		}
	}
	.section-header-tablet {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		.section-header-icon-menu {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 100px;
			.icon-menu-header {
				width: 36px;
				height: 36px;
				cursor: pointer;
				display: flex;
			}
		}
		.section-header-logo-sambay {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			font-family: Poppins, sans-serif;
			font-size: 1.125rem/* 18px */;
    		line-height: 1.75rem/* 28px */;
			font-weight: 600;
			color: #fff;

			.logo-sambay {
				width: 190px;
				height: auto;
				cursor: pointer;
				display: flex;
			}
		}
		.section-header-button-login {
			display: flex;
			flex-direction: row;
			align-items: center;
			.icon-header-subtitle-notification {
				width: 24px;
				height: 24px;
				cursor: pointer;
				margin-right: 10px;
			}
			.style-header-button-sale-at-sambay {
				background-color: white;
				height: 36px;
				color: #038aa0;
				font-family: 'Poppins';
				font-size: 13px;
				font-weight: 600;
				border: none;
				border-radius: 5px;
				width: 135px;
				align-items: center;
				justify-content: center;
				display: flex;
			}
			.style-header-button-login {
				background-color: white;
				height: 36px;
				color: #038aa0;
				font-family: 'Poppins';
				font-size: 13px;
				font-weight: 600;
				border: none;
				border-radius: 5px;
				width: 70px;
			}
		}
	}
	.section-header-mobile {
		display: flex;
		flex-direction: row;
		height: 50%;
		justify-content: space-around;
		align-items:center;
		margin-right:1rem;
		.section-header-icon-menu {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-left:0.5rem;
			.icon-menu-header-mobile {
				width: 16px;
				height: 16px;
				cursor: pointer;
				margin-left:2rem;
			}
		}
		.section-header-sambay-logo {
			display: flex;
			flex-direction: row;
			align-items: center;
			cursor: pointer;
			@media (max-width: 475px) {
				width:50%;
			}
			.sambay-logo-header-mobile {
				height: 50px;
				@media (min-width: 320px) and (max-width: 475px) {
					height: 45px;
					margin-left:0.5rem;
					margin-top:0.3rem;
				}
			}
		}
		.section-register-login-or-help {
			display: flex;
			flex-direction: row;
			margin-top: 8px;
			justify-content: flex-end;
			margin-right:1rem;
			width:100%;
			.style-span-register-mobile {
				font-size: 13px;
				color: white;
				font-weight: 500;
				font-family: 'Poppins';
				padding-right: 15px;
				display:flex;
				align-items:center;
				cursor: pointer;
			}
			.style-span-login-mobile {
				font-size: 13px;
				color: white;
				font-weight: 500;
				font-family: 'Poppins';
				border-left: 1px solid white;
				height: 18px;
				padding-left: 5px;
			}
			.icon-help-header-mobile {
				width: 14px;
				height: 14px;
				cursor: pointer;
				margin-top: 2px;
				margin-left: 8px;
			}
			.icon-notification-header-mobile {
				width: 14px;
				height: 14px;
				cursor: pointer;
				margin-top: 2px;
				margin-left: 10px;
			}
			.section-shopping-cart {
				display: flex;
				flex-direction: row;
				.style-icon-carrinho-de-compras {
					width: 2.1rem;
					cursor: pointer;
					margin-left:1.5rem;
				}
			}
		}
		.style-header-button-login{
			@media (max-width:475px){
				background-color: white;
				color: #038aa0;
				font-family: 'Poppins';
				font-size: 13px;
				font-weight: 600;
				border: none;
				border-radius: 5px;
				display:flex;
				justify-content:center;
				align-items:center;
				text-align:center;
				cursor:pointer;
				width: 15%;
    			height: 70%;
			}
		}
		.row-user-register-login-or-help {
			display:flex;
			align-items:center;
			justify-content:center;
			display:flex;
			.icon-help-header-mobile {
				width: 15px;
				height:15px;
			}
			.icon-notification-header-mobile {
				width: 15px;
				height: 17px;
				margin-top:3px;
			}
			.icon-logged-user {
				width: 18px;
				height: 18px;
				display:flex;
				justify-content:center;
				flex-direction:row;
				margin:0;
				
			}
			.section-profile{
				display:flex;
				align-items:center;
				.user-profile-name {
					font-size: 13px;
					color: white;
					font-weight: 500;
					font-family: 'Poppins';
						@media (max-width: 475px) {
							font-size: 10px;
							color: white;
							font-weight: 500;
							font-family: 'Poppins';
							.p-name{
								font-size: 13px;
								color: white;
								font-weight: 500;
								font-family: 'Poppins';	
							}
						}
				}
			}	
		}
		
	}
	.search-bar-mobile{
		display:flex;
		flex-direction:row;
		justify-content:center;
		margin-top:0.3rem;
		.items-container-mobile{
			width:100%;
			display:flex;
			justify-content:center;
			input{
				width:82%;
				height:35px;
				border:none;
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;
				padding-left: 5px;
				font-size: 14px;
				color: black;
			}
			.search-icon{
				height: 25px;
    			background-color:#FFC000;
    			height: 35px;
    			padding: 4px;
				cursor:pointer;
				border-top-right-radius:5px;
				border-bottom-right-radius:5px;
			}
		}
	}
`;
export const Subtitle = styled.div`
	height: 72px;
	background-color: #038aa0;
	padding-right: 180px;
	padding-left: 180px;

	@media (min-width: 768px) and (max-width: 1022px) {
		height: 50px;
		padding-right: 90px;
		padding-left: 90px;
		.span-categories {
			font-size: 16px;
			color: white;
			font-weight: 500;
			font-family: 'Poppins';
			cursor: pointer;
			padding-right: 10px;
		}
	}

	@media (max-width: 475px) {
		height: 30px;
		padding:1px;
		background-color:#04aac0;

		.section-mobile-subtitle-my-buys {
			display: flex;
			flex-direction: row;
			cursor: pointer;
			.span-categories {
				font-size: 14px;
				color: white;
				font-weight: 500;
				font-family: 'Poppins';
				cursor: pointer;
				display: flex;
				align-items: center;
				@media (min-width: 320px) and (max-width: 374px) {
					font-size: 10px;
				}
			}
			.icon-subtitle-minhas-compras {
				width: 17px;
				height: 18px;
			}
		}
	}

	.section-subtitle {
		display: flex;
		flex-direction: row;
		height: 100%;
		align-items: center;
		justify-content: space-between;
		.section-subtitle-user-location {
			flex-direction: row;
			display: flex;
			.subtitle-icon-location {
				width: 36px;
				height: 44px;
				flex-direction: row;
				display: flex;
				cursor: pointer;
			}
			.subtitle-column-location-span {
				flex-direction: column;
				display: flex;
				cursor: pointer;
				.subtitle-location-span-1 {
					padding-left: 12px;
					font-size: 'Poppins';
					color: white;
					font-weight: 500;
					font-size: 16px;
				}
				.subtitle-location-span-2 {
					display: flex;
					flex-direction: column;
					padding-left: 12px;
					font-size: 'Poppins';
					color: white;
					font-weight: 700;
					font-size: 19px;
				}
			}
		}
		.section-subtitle-categories-bar {
			flex-direction: row;
			width: 100%;
			display: flex;
			justify-content: space-evenly;
			.section-subtitle-categories-bar-title {
				display: flex;
				flex-direction: row;
				.span-categories {
					font-size: 16px;
					color: white;
					font-weight: 500;
					font-family: 'Poppins';
					cursor: pointer;
					padding-right: 10px;
				}
				.style-icon-arrow-categories {
					width: 13px;
					height: 17.69px;
					cursor: pointer;
					margin-top: 3px;
				}
			}
			.span-all-categories {
				font-size: 16px;
				color: white;
				font-weight: 500;
				font-family: 'Poppins';
				cursor: pointer;
			}
		}
		.section-user-profile {
			display: flex;
			flex-direction: row;
			.section-user-store-title {
				display: flex;
				flex-direction: row;
				cursor: pointer;
				align-items: center;
				.icon-store {
					width: 34px;
					height: 34px;
				}
				.user-store-span-name {
					font-family: 'Poppins';
					font-size: 15px;
					font-weight: 500;
					color: white;
					padding-left: 7px;
					padding-right: 30px;
				}
				.style-icon-arrow-user-store {
					width: 13px;
					height: 17.69px;
					cursor: pointer;
					margin-top: 9px;
				}
			}
			.section-user-profile-title {
				display: flex;
				flex-direction: row;
				cursor: pointer;
				align-items: center;
				.icon-profile {
					width: 34px;
					height: 34px;
				}
				.user-profile-span-name {
					font-family: 'Poppins';
					font-size: 14px;
					color: white;
					padding-left: 7px;
					padding-right: 7px;
					width: 100%;
				}
				.style-icon-arrow-user-profile {
					width: 13px;
					height: 17.69px;
					cursor: pointer;
					margin-top: 9px;
				}
			}
		}
	}
	.section-tablet-subtitle {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: 100%;
		.section-tablet-subtitle-my-buys {
			display: flex;
			cursor: pointer;
			align-items: center;
			.icon-subtitle-minhas-compras {
				width: 24px;
				height: 24px;
				margin-right: 10px;
			}
			.style-span-subtitle-my-buys {
				color: white;
				font-size: 14px;
				font-weight: 500;
				font-family: 'Poppins';
			}
		}
		.section-tablet-subtitle-my-store {
			display: flex;
			cursor: pointer;
			align-items: center;
			.icon-subtitle-minha-loja {
				width: 24px;
				height: 24px;
				margin-right: 10px;
			}
			.style-span-subtitle-my-buys {
				color: white;
				font-size: 14px;
				font-weight: 500;
				font-family: 'Poppins';
			}
		}
		.section-tablet-subtitle-my-profile {
			display: flex;
			cursor: pointer;
			align-items: center;
			.icon-subtitle-my-profile {
				width: 24px;
				height: 24px;
				margin-right: 10px;
			}
			.style-span-subtitle-my-profile {
				color: white;
				font-size: 14px;
				font-weight: 500;
				font-family: 'Poppins';
			}
		}
	}
	.section-subtitle-mobile {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items:center;
		height:100%;
		.section-subtitle-menu {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 35px;
			width: 95%;
			justify-content: center;
			
			@media (min-width:375px) and (max-width: 424px) {
				gap:20px;
			}
			.section-icon-mobile-categories {
				display: flex;
				flex-direction: row;
				cursor: pointer;
				margin-right: 10px;
				align-items: center;
				.style-icon-category {
					width: 16px;
					height: 14px;
				}
				.style-span-mobile-categories {
					font-family: 'Poppins';
					font-size: 12px;
					font-weight: 500;
					color: white;
				}
			}
			.section-icon-mobile-ofertas {
				display: flex;
				flex-direction: row;
				cursor: pointer;
				align-items: center;
				.style-icon-sales {
					width: 16px;
					height: 14px;
				}
				.style-icon-news {
					width: 17px;
					height: 18px;
					margin-right: 2px;
				}
				.style-span-mobile-sales {
					font-family: 'Poppins';
					font-size: 12px;
					font-weight: 500;
					color: white;
					@media (max-width:475px){
						font-size:14px;
					}
				}
				@media (min-width:375px) and (max-width:474px){
					font-size:13px;
					max-width:375px;
					justify-content:center;
					.style-span-mobile-sales {
						font-family: 'Poppins';
						font-size: 13px;
						font-weight: 500;
						color: white;
					}
				}
			}
			.section-icon-mobile-novidades{
				display: flex;
				flex-direction: row;
				cursor: pointer;
				align-items: center;
				.style-icon-sales {
					width: 16px;
					height: 14px;
				}
				.style-icon-news {
					width: 17px;
					height: 18px;
					margin-right: 2px;
				}
				.style-span-mobile-sales {
					font-family: 'Poppins';
					font-size: 12px;
					font-weight: 500;
					color: white;
					@media (max-width:475px){
						font-size:14px;
					}
				}
				@media (min-width:375px){
					font-size:13px;
					max-width:375px;
					justify-content:center;
					.style-span-mobile-sales {
						font-family: 'Poppins';
						font-size: 13px;
						font-weight: 500;
						color: white;
					}
				}
			}
			.section-icon-mobile-antiguidades{
				display: flex;
				flex-direction: row;
				cursor: pointer;
				align-items: center;
				.style-icon-sales {
					width: 16px;
					height: 14px;
				}
				.style-icon-news {
					width: 17px;
					height: 18px;
					margin-right: 2px;
				}
				.style-span-mobile-sales {
					font-family: 'Poppins';
					font-size: 12px;
					font-weight: 500;
					color: white;
					@media (max-width:475px){
						font-size:14px;
					}
				}
				@media (min-width:375px){
					font-size:13px;
					max-width:375px;
					justify-content:center;
					.style-span-mobile-sales {
						font-family: 'Poppins';
						font-size: 13px;
						font-weight: 500;
						color: white;
					}
				}
			}
			.section-icon-mobile-baratissimos{
				display: flex;
				flex-direction: row;
				cursor: pointer;
				align-items: center;
				.style-icon-sales {
					width: 16px;
					height: 14px;
				}
				.style-icon-news {
					width: 17px;
					height: 18px;
					margin-right: 2px;
				}
				.style-span-mobile-sales {
					font-family: 'Poppins';
					font-size: 12px;
					font-weight: 500;
					color: white;
					@media (max-width:475px){
						font-size:14px;
					}
				}
				@media (min-width:375px){
					font-size:13px;
					max-width:375px;
					justify-content:center;
					.style-span-mobile-sales {
						font-family: 'Poppins';
						font-size: 13px;
						font-weight: 500;
						color: white;
					}
				}
			}
			.section-icon-mobile-search {
				display: flex;
				flex-direction: row;
				cursor: pointer;
				align-items: center;
				margin-right: 10px;
				.style-icon-search {
					width: 16px;
					height: 14px;
				}
				.style-span-mobile-search {
					font-family: 'Poppins';
					font-size: 12px;
					font-weight: 500;
					color: white;
				}
			}
			.section-icon-mobile-sale-at-sambay {
				display: flex;
				flex-direction: row;
				.style-span-mobile-sale-at-sambay {
					font-family: 'Poppins';
					font-size: 12px;
					font-weight: 500;
					color: rgb(62, 105, 174);
					background-color: white;
					font-family: 'Poppins';
					font-weight: 700;
					border: none;
					border-radius: 3px;
					justify-content: center;
					display: flex;
					flex-direction: row;
				}
				.button-sale-at-sambay {
					background-color: white;
					font-size: 12px;
					color: rgb(62, 105, 174);
					font-family: 'Poppins';
					font-weight: 700;
					border: none;
					border-radius: 3px;
					justify-content: center;
					display: flex;
					flex-direction: row;

					@media (max-width: 475px) {
						height: 30px;
    					width: 100px;
    					font-size: 12px;
					}
					@media (min-width: 375px) {
						height: 30px;
    					width: 100px;
						padding: 0;
					}
				}
			}
		}
	}
`;

export const LocationContainer = styled.div`
	.section-location-mobile{
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items:center;
		height:50px;
		background-color:#038AA0;
		.section-subtitle-user-location{
			display:flex;
			cursor:pointer;
			.subtitle-icon-location{
				height:30px;
			}
			.subtitle-column-location-span{
				display:flex;
				flex-direction:row;
				.subtitle-location-span-2{
					font-size:14px;
					color:white;
					font-family:'Poppins';
					padding-left:0.5rem;
					display:flex;
					align-items:center;
				}
				.subtitle-location-span-1{
					font-size:14px;
					color:white;
					font-family:'Poppins';
					padding-left:0.5rem;
					display:flex;
					align-items:center;
				}
				.subtitle-location-span-3{
					font-size:14px;
					color:white;
					font-family:'Poppins';
					display:flex;
					align-items:center;
					padding-left:0.4rem;
				}

			}
		}
		
	}
`;
