import styled from 'styled-components';

export const Container = styled.div`
	height: 76px;
	display: flex;
	flex-direction: row;
	background-color: #04aac0;
	padding: 10px;
	align-items: center;
	justify-content: space-between;
	transition: all 1s;
	padding-left: 25px;
	padding-right: 25px;
`;
interface menuIconsColor {
	recordsColor?: string;
	notificationsColor?: string;
	dashBoardColor?: string;
	productsColor?: string;
	categoriesColor?: string;
}

export const Tools = styled.div<menuIconsColor>`
	display: flex;
	flex-direction: row;

	height: 100%;
	align-items: center;
	margin-left: 40px;
	img {
		margin-left: 20px;
	}
	img:hover {
		cursor: pointer;
	}
	.menu-icons-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-left: 16px;

		.menu-icons-item {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			margin-left: 20px;
			height: 35px;

			border-radius: 18px;

			.notifications-text {
				color: ${(Prop) => Prop.notificationsColor};
				font-family: 'Poppins', semi-bold;
				margin-left: 8px;
				font-weight: 600;
			}
			.dashboard-text {
				color: ${(Prop) => Prop.dashBoardColor};
				font-family: 'Poppins', semi-bold;
				margin-left: 8px;
				font-weight: 600;
			}
			.productsColor-text {
				color: ${(Prop) => Prop.productsColor};
				font-family: 'Poppins', semi-bold;
				margin-left: 8px;
				font-weight: 600;
			}
			.categories-text {
				color: ${(Prop) => Prop.categoriesColor};
				font-family: 'Poppins', semi-bold;
				margin-left: 8px;
				font-weight: 600;
			}
			.records-text {
				color: white;
				font-family: 'Poppins', semi-bold;
				margin-left: 8px;
				font-weight: 600;
			}

			.records {
				.a {
					fill: ${(Prop) => Prop.recordsColor};
				}
			}
			.notifications {
				.a {
					fill: ${(Prop) => Prop.notificationsColor};
				}
			}
			.dashboard {
				.a {
					fill: ${(Prop) => Prop.dashBoardColor};
				}
			}
			.productsColor {
				.a {
					fill: ${(Prop) => Prop.productsColor};
				}
			}
			.categories {
				.a {
					fill: none;
				}
				.b {
					fill: ${(Prop) => Prop.categoriesColor};
				}
			}
			.image-text {
				font-family: 'Poppins', semi-bold;
				margin-left: 8px;
				font-weight: 600;

				color: white;
			}
		}
		.menu-icons-item-active {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			padding: 10px;
			border-radius: 25px;
			margin-left: 20px;
			height: 35px;
			background: #ffffff;

			.records-text {
				color: ${(Prop) => Prop.recordsColor};
				font-family: 'Poppins', semi-bold;
				margin-left: 8px;
				font-weight: 600;
			}

			.records {
				.a {
					fill: ${(Prop) => Prop.recordsColor};
				}
			}
			.image-text {
				color: ${(Prop) => Prop.recordsColor};
				font-family: 'Poppins', semi-bold;
				margin-left: 8px;
				font-weight: 600;
			}

			.notifications-text {
				color: ${(Prop) => Prop.notificationsColor};
				font-family: 'Poppins', semi-bold;
				margin-left: 8px;
				font-weight: 600;
			}
			.dashboard-text {
				color: ${(Prop) => Prop.dashBoardColor};
				font-family: 'Poppins', semi-bold;
				margin-left: 8px;
				font-weight: 600;
			}
			.productsColor-text {
				color: ${(Prop) => Prop.productsColor};
				font-family: 'Poppins', semi-bold;
				margin-left: 8px;
				font-weight: 600;
			}
			.categories-text {
				color: ${(Prop) => Prop.categoriesColor};
				font-family: 'Poppins', semi-bold;
				margin-left: 8px;
				font-weight: 600;
			}
			.notifications {
				.a {
					fill: ${(Prop) => Prop.notificationsColor};
				}
			}
			.dashboard {
				.b {
					fill: ${(Prop) => Prop.dashBoardColor};
				}
			}
			.productsColor {
				.a {
					fill: ${(Prop) => Prop.productsColor};
				}
			}
			.categories {
				.a {
					fill: none;
				}
				.b {
					fill: ${(Prop) => Prop.categoriesColor};
				}
			}
		}
	}

	.search-bar {
		background-color: white;
		width: 567px;
		height: 34px;

		@media screen and (max-width: 1366px) {
			width: 429px;
			height: 34px;
		}
		margin-left: 30px;
		border-radius: 20px;
		display: flex;
		flex-direction: column;
		justify-content: start;
		align-items: center;

		input {
			width: 88%;
			margin-left: 11px;
			border-radius: 20px;
			border: none;
		}
		input:focus {
			outline: none;
		}
		@media screen and (max-width: 1366px) {
			width: 48%;
		}
		img {
			width: 16px;
			height: 16px;
		}

		.items-container {
			z-index: 1;
			display: flex;
			flex-direction: row;
			width: 567px;
			height: 20px;
			align-items: center;
			background-color: white;
			width: 565px;
			@media screen and (max-width: 1366px) {
				width: 380px;
				height: 34px;
			}

			height: 31px;
			/* margin-left: 30px; */
			border-radius: 20px;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-flex-direction: row;
			-ms-flex-direction: row;
			flex-direction: row;
			-webkit-box-pack: start;
			-webkit-justify-content: start;
			-ms-flex-pack: start;
			justify-content: center;
			-webkit-align-items: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
		}
		.box {
			z-index: 0;
			width: 565px;
			height: auto;
			background-color: white;
			position: absolute;
			z-index: 0;
			margin-top: 36px;
			min-height: 65px;
			padding: 3px;
			box-shadow: 12px 9px 19px #00000014;
			.box-item {
				width: 557px;
				height: 40px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: start;
				padding: 6px;
				color: #a8a8a8;
				font-weight: 600;
				font-family: 'Poppins';
				/* font-weight: bold; */
			}
			.box-item:hover {
				background-color: rgba(0, 0, 0, 0.3);
				color: black;
				font-weight: 600;
				cursor: pointer;

				span {
					color: #a8a8a8;
				}
			}
		}
	}
`;
export const UserInfos = styled.div`
	display: flex;
	flex-direction: row;
	margin-right: 30px;
	cursor: pointer;

	.name-and-user-type {
		display: flex;
		flex-direction: column;
		margin-right: 8px;
		align-items: flex-end;

		span {
			color: white;
			font-size: 14px;
			.user-name {
				font-family: 'Poppins', semi-bold;
				font-weight: 600;
			}
			.user-type {
				font-family: 'Poppins', regular;
			}
		}
	}
	img {
		width: 38px;
		height: 38px;
		border-radius: 20px;
	}
	.down-arrow:before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
		width: 0;
		height: 0;
		margin-left: 26px;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 5px solid white;
	}

	.absolute {
		top: 9%;
		right: 10%;
		position: absolute;
		transform: translate(-50%, -50%);
		box-shadow: 0px 2px 8px #00000014;
		z-index: 999;
		background: white;
		border-radius: 4px;
		@media screen and (max-width: 1366px) {
			top: 11%;
			right: 1%;
			-webkit-transform: translate(50%, 50%);
			-ms-transform: translate(50%, 50%);
			transform: translate(-50%, -50%);
			box-shadow: 0px 2px 8px #00000014;
			z-index: 999;
		}

		.flex {
			display: flex;
			flex-direction: column;
			color: black;
			padding: 10px;
			z-index: 999;

			span:hover {
				cursor: pointer;
			}
		}
	}
`;
