import React, { useCallback, useEffect, useState } from 'react'
import {
    Container,
    MainContainer,
    QrCodeContainer,
    SucessContainer,
} from './Style'
import Slide from '@material-ui/core/Slide'
import Paper from '@material-ui/core/Paper'
import sucess from '../../../assets/icons/sucess.svg'
import IUserAccount from '../../../models/IUserAccount'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../store'
import { useHistory } from 'react-router'
import { Grow, makeStyles, Radio } from '@material-ui/core'
import IUserCards from '../../../models/IUserCards'
import IProductsCart from '../../../models/ICart'
import { usePurchaseInCartMutation } from '../../../generated/graphql'
import { getCalcCartValue } from '../../../utils/getCartTotalValue'
import { removeProductCartAction } from '../../../actions/ProductsCartActions'
import { useQRCode } from 'react-qrcodes'
import moment from 'moment'
import { ToastContainer, toast } from 'react-toastify'
import ReactGA from 'react-ga4'

interface ProductPurchaseInfos {
    productId: number
    installments: number
    quantity: number
    shippingWay: string
    address: number
    productShippingPrice: number
    price: number
    serviceId: number
    variacao?: number
}

const BuyProductsInCartConfirmProcess: React.FC = () => {
    const [checked, setChecked] = useState<boolean>(false)
    const user: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )
    const userCards: IUserCards[] = useSelector(
        (state: AppState) => state.UserCards.currentCards
    )
    const productsCart: IProductsCart[] = useSelector(
        (state: AppState) => state.ProductsCart.currentProductsCart
    )

    const history = useHistory()

    const [showLoading, setShowLoading] = useState<string>('false')
    const [loadingWidth, setLoadingWidth] = useState<number>(0)
    const [background, setBackground] = useState('#69b64a')
    const [timeTransition, setTimeTransition] = useState<number>(4)
    const [tentativasCompra, setTentativasCompra] = useState<number>(1)
    const [middleBackground, setMiddleBackground] = useState<string>('#69b64a')
    const [qrcode, setQrCode] = useState<string>()
    const [qrCodeExpirationDate, setQrCodeExpirationDate] = useState<string>()
    const [qrcodeCopied, setQrCodeCopied] = useState<boolean>(true)
    const [purchaseInfos, setPurchaseInfos] = useState<ProductPurchaseInfos[]>(
        []
    )
    const [isBlock, setBlock] = useState(false)
    const [purchaseTotal, setPurchaseTotal] = useState<number>(0)
    const [shippingTotal, setShippingTotal] = useState<number>(0)

    const [PurchaseInCart] = usePurchaseInCartMutation()

    const verifyPaymentForms = (product: IProductsCart) => {
        return !user.cardId
            ? 'Boleto - Á vista'
            : 'Cartão de crédito em' + product.installments + 'x'
    }

    useEffect(() => {
        setPurchaseInfos(
            productsCart.map(({ parameters, installments }) => {
                return {
                    address: parameters.address ? parameters.address.id : 0,
                    installments: installments || 1,
                    productId: parameters.product.id,
                    productShippingPrice:
                        parameters.service && parameters.service.custom_price
                            ? Number(parameters.service.custom_price)
                            : 0,
                    quantity: parameters.quantity,
                    price: parameters.product.price,
                    shippingWay: parameters.shippingWay
                        ? parameters.shippingWay
                        : 'A combinar com o vendedor',
                    serviceId: parameters.service ? parameters.service.id : 0,
                    variacao: parameters.variacaoId,
                }
            })
        )

        setPurchaseTotal(
            productsCart.reduce((total, { parameters }) => {
                return (
                    total +
                    (parameters.product.price * parameters.quantity +
                        (parameters.productShippingPrice || 0))
                )
            }, 0)
        )
        
        setShippingTotal(
            productsCart.reduce((total, { parameters }) => {
                return total + (parameters.productShippingPrice || 0)
            }, 0)
        )
    }, [productsCart])

    useEffect(() => {
        setTimeout(() => {
            setQrCodeCopied(true)
        }, 4000)
    }, [qrcodeCopied])

    function getFormaDeEnvio(product: any) {
        if (product) {
            if (product.service?.name!) return product.service?.name!
            if (product.shippingWay === 'withdraw')
                return 'Combinar com o vendedor'
            if (product.shippingWay === 'freeDelivery') return 'Frete Grátis'
        }
    }

    function getCusto(product: any) {
        if (product) {
            if (product?.service?.custom_price!)
                return product?.service?.custom_price!
            if (product.shippingWay === 'withdraw')
                return 'Combinar com o vendedor'
            if (product.shippingWay === 'freeDelivery') return 'Frete Grátis'
        }
    }

    const dispatch = useDispatch()

    const purchase = useCallback(async () => {
        if (!isBlock && tentativasCompra <= 3) {
            setTentativasCompra((prevState) => prevState + 1)
            setBlock(true)
            setBackground('#F7E23E')
            setShowLoading('true')
            setTimeTransition(2)
            setLoadingWidth(Math.random() * (50 - 30) + 30)

            try {
                const result = await PurchaseInCart({
                    variables: {
                        PurchaseInCartInput: {
                            productsCard: purchaseInfos,
                            formOfPayment: user.formaDePagamento,
                            userId: user.id,
                            cardId: user.cardId,
                            installments: purchaseInfos[0].installments
                        },
                    },
                })

                setTimeTransition(0.5)
                setLoadingWidth(100)
                setShowLoading('success')
                setTimeout(() => {
                    setChecked(true)
                }, 1000)
                productsCart.map((item) => 
                    dispatch(
                        removeProductCartAction(item?.parameters.product.id)
                    )
                )

                setQrCode(result.data?.PurchaseInCart?.pix_qrcode as string)
                setQrCodeExpirationDate(
                    result.data?.PurchaseInCart?.pix_date_expiration as string
                )
                ReactGA.event('purchase', {
                    currency: 'BRL',
                    transaction_id: result.data?.PurchaseInCart.transaction_id,
                    value: purchaseTotal,
                    shipping: shippingTotal,
                    items: [...productsCart.map((cartItem) => {
                        return {
                            item_id: cartItem.parameters.product.id,
                            item_name: cartItem.parameters.product.title,
                            price: cartItem.parameters.product.price,
                            quantity: cartItem.parameters.quantity,
                        }
                    })]
                })
                toast.success('Compra feita com sucesso!', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    type: 'success',
                    draggable: true,
                    progress: undefined,
                })
            } catch (err: any) {
                setTimeTransition(1.5)
                setLoadingWidth(100)
                setMiddleBackground('#F04229')
                setShowLoading('failed')
                toast.error(`${err.message}`, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    type: 'error',
                    draggable: true,
                    progress: undefined,
                })

                setInterval(() => {
                    setTimeTransition(1.5)
                    setLoadingWidth(100)
                    setMiddleBackground('#69b64a')
                    setShowLoading('reloadpayment')
                }, 3500)
            }
        } else {
            setTimeTransition(1.5)
            setLoadingWidth(100)
            setMiddleBackground('#F04229')
            setShowLoading('failed')
            toast.error('Você excedeu as tentativas de refazer sua compra', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                type: 'error',
                draggable: true,
                progress: undefined,
            })
        }
    }, [
        PurchaseInCart,
        dispatch,
        isBlock,
        productsCart,
        purchaseInfos,
        purchaseTotal,
        shippingTotal,
        tentativasCompra,
        user.cardId,
        user.formaDePagamento,
        user.id,
    ])

    const useStyles = makeStyles({
        root: {
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
    })

    function StyledRadio(props: any) {
        const classes = useStyles()

        return (
            <Radio
                className={classes.root}
                disableRipple
                color="default"
                {...props}
                checked={props.checked}
                name="payment"
                style={{
                    marginRight: '8px',
                }}
                onClick={() => props.onClick()}
            />
        )
    }

    const ExibirQrCode = () => {
        const [qrRef] = useQRCode({
            text: qrcode || 'zero',
            options: {
                level: 'H',
                margin: 10,
                scale: 8,
                width: 206,
                color: {
                    dark: '#000000',
                    light: '#ffffff',
                },
            },
        })

        return (
            <canvas
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    background: '#fff',
                }}
                // @ts-ignore
                ref={qrRef}
            />
        )
    }
    return (
        <>
            <ToastContainer />
            {!checked && (
                <Container>
                    <MainContainer>
                        <div className="class-column">
                            <h2>Revise sua compra</h2>
                            <div className="card-container">
                                <div className="card-header">
                                    <h2>Endereço de envio</h2>

                                    <span
                                        onClick={() =>
                                            history.push(
                                                `/buy-address-process-cart`
                                            )
                                        }
                                    >
                                        Alterar
                                    </span>
                                </div>
                                <div className="card-infos">
                                    <div className="card-options">
                                        {productsCart?.map(
                                            (item: any, index: number) => (
                                                <>
                                                    <label>
                                                        {
                                                            ((((((item
                                                                ?.parameters
                                                                ?.address
                                                                ?.street as any) +
                                                                ' ' +
                                                                item?.parameters
                                                                    ?.address
                                                                    .number) as any) +
                                                                ' ' +
                                                                item?.parameters
                                                                    ?.address
                                                                    .city) as any) +
                                                                ' ' +
                                                                item?.parameters
                                                                    ?.address
                                                                    .CEP) as any
                                                        }
                                                    </label>{' '}
                                                </>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="card-container">
                                <div className="card-header">
                                    <h2>Forma de envio</h2>
                                    <span
                                        onClick={() =>
                                            history.push(
                                                `/buy-address-process-cart`
                                            )
                                        }
                                    >
                                        Alterar
                                    </span>
                                </div>
                                <div className="card-infos">
                                    <div className="card-options">
                                        {productsCart?.map((item) =>
                                            item?.parameters?.shippingWay ===
                                            'delivery' ? (
                                                <label
                                                    style={{
                                                        paddingRight: '3rem',
                                                    }}
                                                    key={
                                                        item?.parameters
                                                            ?.product!.id
                                                    }
                                                >
                                                    {
                                                        item?.parameters
                                                            ?.service?.name!
                                                    }
                                                </label>
                                            ) : (
                                                <label
                                                    style={{
                                                        paddingRight: '3rem',
                                                    }}
                                                >
                                                    {item.parameters
                                                        .shippingWay ===
                                                    'withdraw'
                                                        ? 'Combinar com o vendedor'
                                                        : 'Frete Grátis'}
                                                </label>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="card-container">
                                <div className="card-header">
                                    <h2>Forma de pagamento</h2>
                                    <span
                                        onClick={() =>
                                            history.push(
                                                '/buy-payment-process-cart'
                                            )
                                        }
                                    >
                                        Alterar
                                    </span>
                                </div>
                                <div className="card-infos">
                                    <div className="card-options">
                                        {user.cardId &&
                                        user.formaDePagamento ===
                                            'credit_card' ? (
                                            <label>
                                                Cartão de crédito - final
                                                {
                                                    userCards.find(
                                                        (card) =>
                                                            card.id ===
                                                            user.cardId
                                                    )?.last_digits
                                                }
                                            </label>
                                        ) : (
                                            <></>
                                        )}

                                        {user.formaDePagamento === 'pix' && (
                                            <label>PIX</label>
                                        )}
                                        {user.formaDePagamento === 'boleto' && (
                                            <label>Boleto bancário</label>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="card-container">
                                <div className="card-header">
                                    <h2>Condições do pagamento</h2>
                                    <span
                                        onClick={() =>
                                            history.push(
                                                '/buy-payment-process-cart'
                                            )
                                        }
                                    >
                                        Alterar
                                    </span>
                                </div>

                                {productsCart?.map((item: IProductsCart) => (
                                    <div
                                        className="card-infos"
                                        key={item?.parameters?.product?.id}
                                    >
                                        <div className="card-options">
                                            <label>
                                                {`(${item?.parameters?.quantity})` +
                                                    'x' +
                                                    ' ' +
                                                    item?.parameters?.product?.title.substring(
                                                        0,
                                                        14
                                                    ) +
                                                    '-' +
                                                    verifyPaymentForms(
                                                        item
                                                    )}{' '}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="infos-card">
                            <div
                                className="title-infos"
                                style={{ borderBottom: 'solid 1px black' }}
                            >
                                {productsCart?.length > 1 && (
                                    <h2>
                                        {productsCart?.length} Produtos no
                                        carrinho:
                                    </h2>
                                )}
                                {productsCart?.length <= 1 && (
                                    <h2>
                                        {productsCart?.length} Produto no
                                        carrinho:
                                    </h2>
                                )}
                            </div>
                            <div
                                className="infos"
                                style={{ borderBottom: 'solid 1px black' }}
                            >
                                <h4>Detalhes do envio:</h4>
                                {productsCart?.map((item) => (
                                    <>
                                        {item?.parameters?.service && (
                                            <>
                                                <label>
                                                    {item?.parameters?.product
                                                        ?.title + ':'}
                                                </label>
                                                <h4>Forma de envio:</h4>
                                                <label>
                                                    {getFormaDeEnvio(
                                                        item?.parameters
                                                    )}
                                                </label>
                                                <h4>Custo:</h4>
                                                <label>
                                                    {getCusto(item?.parameters)}
                                                </label>
                                            </>
                                        )}
                                    </>
                                ))}
                            </div>
                            <div className="price-info">
                                <h2>
                                    Total a pagar:{' '}
                                    {getCalcCartValue(
                                        productsCart
                                    ).toLocaleString('pt-br', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })}
                                </h2>
                            </div>

                            <div className="next-button-container">
                                <button
                                    onClick={() => purchase()}
                                    className="buy-processing"
                                    style={{ backgroundColor: `${background}` }}
                                >
                                    <Slide
                                        direction="up"
                                        in={showLoading === 'false'}
                                        mountOnEnter
                                        unmountOnExit
                                    >
                                        <Paper
                                            elevation={1}
                                            style={{
                                                boxShadow: 'none',
                                                backgroundColor: 'transparent',
                                                width: '226px',
                                                height: '36px',
                                                maxWidth: '280px',
                                                position: 'absolute',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                zIndex: 1,
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <span>Confirmar compra</span>
                                        </Paper>
                                    </Slide>

                                    <Grow
                                        in={showLoading === 'true'}
                                        mountOnEnter
                                        unmountOnExit
                                        timeout={1100}
                                    >
                                        <Paper
                                            elevation={1}
                                            style={{
                                                boxShadow: 'none',
                                                backgroundColor: 'transparent',
                                                width: '226px',
                                                height: '36px',
                                                maxWidth: '280px',
                                                position: 'absolute',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                zIndex: 1,
                                            }}
                                        >
                                            <div className="ui small active centered inline loader"></div>
                                        </Paper>
                                    </Grow>

                                    <Slide
                                        direction="up"
                                        in={showLoading === 'success'}
                                        mountOnEnter
                                        unmountOnExit
                                    >
                                        <Paper
                                            elevation={1}
                                            style={{
                                                boxShadow: 'none',
                                                backgroundColor: 'transparent',
                                                width: '226px',
                                                height: '36px',
                                                maxWidth: '280px',
                                                position: 'absolute',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                zIndex: 1,
                                            }}
                                        >
                                            <span>Sucesso</span>
                                        </Paper>
                                    </Slide>
                                    <Slide
                                        direction="up"
                                        in={showLoading === 'reloadpayment'}
                                        mountOnEnter
                                        unmountOnExit
                                    >
                                        <Paper
                                            elevation={1}
                                            style={{
                                                boxShadow: 'none',
                                                backgroundColor: 'transparent',
                                                width: '226px',
                                                height: '36px',
                                                maxWidth: '280px',
                                                position: 'absolute',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                zIndex: 1,
                                            }}
                                        >
                                            <span>Refazer Pagamento</span>
                                        </Paper>
                                    </Slide>
                                    <Slide
                                        direction="up"
                                        in={showLoading === 'failed'}
                                        mountOnEnter
                                        unmountOnExit
                                    >
                                        <Paper
                                            elevation={1}
                                            style={{
                                                boxShadow: 'none',
                                                backgroundColor: 'transparent',
                                                width: '226px',
                                                height: '36px',
                                                maxWidth: '280px',
                                                position: 'absolute',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                zIndex: 1,
                                            }}
                                        >
                                            <span>Pagamento não efetuado</span>
                                        </Paper>
                                    </Slide>
                                    <div
                                        className="middle"
                                        style={{
                                            width: `${loadingWidth}%`,
                                            transition: `all ${timeTransition}s`,
                                            backgroundColor: `${middleBackground}`,
                                        }}
                                    />
                                </button>
                            </div>
                        </div>
                    </MainContainer>
                </Container>
            )}
            <Slide direction="up" in={checked} mountOnEnter unmountOnExit>
                <Paper elevation={1} style={{ boxShadow: 'none' }}>
                    <SucessContainer>
                        <div className="sucess-card">
                            <img src={sucess}></img>
                            <h2>Compra realizada com sucesso</h2>

                            {qrcode && (
                                <QrCodeContainer>
                                    <ExibirQrCode />
                                    <div className="container-qr-code">
                                        <span className="span-vencimento">
                                            Vencimento QRCode PIX:{' '}
                                            {moment(
                                                qrCodeExpirationDate
                                            ).format('DD/MM/YYYY')}
                                        </span>
                                        {qrcodeCopied ? (
                                            <button
                                                onClick={() => {
                                                    setQrCodeCopied(false)
                                                    navigator.clipboard.writeText(
                                                        qrcode || ''
                                                    )
                                                }}
                                            >
                                                Copiar QRCode
                                            </button>
                                        ) : (
                                            <button>QRCode Copiado!</button>
                                        )}
                                    </div>
                                </QrCodeContainer>
                            )}
                            <span className="span-andamento-compras">
                                Você pode conferir o andamento de sua compra na
                                área minhas compras
                            </span>
                            <div className="card-actions">
                                <button
                                    className="button-confirm"
                                    onClick={() =>
                                        (window.location.href = '/my-buys')
                                    }
                                >
                                    Ir para minhas compras
                                </button>
                                <button
                                    className="button-redirect"
                                    onClick={() => history.push('/')}
                                >
                                    Ir para home
                                </button>
                            </div>
                        </div>
                    </SucessContainer>
                </Paper>
            </Slide>
        </>
    )
}
export default BuyProductsInCartConfirmProcess
