import React, { useState } from 'react';

import { Container } from './styles';
import expandIcon from '../../../assets/icons/expand.svg';

type Props = {
	title: string,
	question: JSX.Element
};
const FaqItem: React.FC<Props> = ({title, question}) => {
	const [isOpen, setOpen] = useState(false);
	return (
		<Container>
			<div className="title">
				<h2> {title} </h2>
				<img
					src={expandIcon}
					alt=""
					className={`img ${isOpen ? 'rotate' : ''}`}
					onClick={() => setOpen(!isOpen)}
				/>
			</div>
			{isOpen && <div>{question}</div>}
		</Container>
	);
};

export default FaqItem;
