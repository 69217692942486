import React from 'react'

import ProductCard from '../../components/ProductCard/ProductCard'
import IUserAccount from '../../models/IUserAccount'
import IProductsCart from '../../models/ICart'
import { useSelector } from 'react-redux'
import { AppState } from '../../store'
import { Container } from './Style'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import AliceCarousel from 'react-alice-carousel'
import { SliderContainer } from '../../utils/AliceCarouselCustom'
import ALICE_CAROUSEL_DEFAULTS from '../../utils/AliceCarouselDefaults'

const CartProductsSlider: React.FC = () => {
    const productsCart: IProductsCart[] = useSelector(
        (state: AppState) => state.ProductsCart.currentProductsCart
    )
    const user: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )

    const responsive = {
        0: { items: 5 },
        568: { items: 5 },
        1024: { items: 6 },
    }

    const renderPrevButton = ({ isDisabled }: any) => {
        return (
            <span style={{ opacity: isDisabled ? '0.5' : 1 }}>
                <MdChevronLeft color="black" size="3rem" />
            </span>
        )
    }

    const renderNextButton = ({ isDisabled }: any) => {
        return (
            <span style={{ opacity: isDisabled ? '0.5' : 1 }}>
                <MdChevronRight color="black" size="3rem" />
            </span>
        )
    }

    if (productsCart?.length === 0) {
        return null
    }

    return (
        <SliderContainer>
            <Container>
                <div className="home-container">
                    <div className="product-cards-container">
                        <div className="container-title">
                            <h1>Continuar Comprando</h1>
                        </div>

                        <AliceCarousel
                            {...ALICE_CAROUSEL_DEFAULTS}
                            renderPrevButton={renderPrevButton}
                            renderNextButton={renderNextButton}
                            items={productsCart?.map(
                                (product: IProductsCart, index) => {
                                    return (
                                        <div
                                            className="product-card-content"
                                            key={index}
                                        >
                                            <ProductCard
                                                product={
                                                    product?.parameters?.product
                                                }
                                                key={
                                                    product?.parameters?.product
                                                        ?.id
                                                }
                                            />
                                        </div>
                                    )
                                }
                            )}
                        />
                    </div>
                </div>
            </Container>
        </SliderContainer>
    )
}

export default CartProductsSlider
