import styled from 'styled-components';

export const ModalContainer = styled.div``;

export const RemetenteContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top:10px;
	input{
		height: 2rem;
		border:1px solid black;
		border-radius: 5px;
		margin-bottom: 10px;
	}
`;
export const ModalTop = styled.header`
	padding: 25px;
	border-bottom: solid 1px black;

	h1 {
		color: black;
		font-family: 'Poppins';
		font-weight: bold;
		font-size: min(15px, 1vw);
	}
`;
export const ModalMiddle = styled.main`
	padding: 25px;

	.middle-title {
		color: black;
		font-family: 'Poppins';
		font-weight: 400;
		font-size: min(14px, 0.8vw);
	}

	.payment-options-container {
		margin-top: 25px;
		display: flex;
		flex-direction: column;

		.payment-option {
			border: solid 1px black;
			padding: 5px 15px;
			border-radius: 6px;

			display: flex;
			justify-content: space-between;
			align-items: center;

			.option-label {
				color: #888888;
				font-family: 'Poppins';
				font-weight: bold;
				font-size: min(12px, 0.8vw);
			}
			.melhor-envio-balance {
				color: #888888;
				font-family: 'Poppins';
				font-weight: 400;
				font-size: min(12px, 0.8vw);

				strong {
					color: #888888;

					font-family: 'Poppins';
					font-weight: bold;
					font-size: min(12px, 0.8vw);
				}
			}
		}
	}
`;
export const Modalbottom = styled.footer`
	padding: 0px 25px;
	display: flex;
	justify-content: space-between;

	.add-balane-link {
		align-self: center;
		color: #4183c4;

		&:hover {
			cursor: pointer;
			text-decoration: underline;
		}
	}

	.next-button {
		width: 25%;
		min-width: 90px;
		max-width: 116px;
		height: 41px;
		background: #6b97a5;
		color: white;
		border: none;
		border-radius: 8px;
		font-family: 'Poppins';
		font-weight: bold;
		font-size: min(12px, 0.8vw);
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	.disabled-button {
		opacity: 0.6;
	}
`;
