import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import {
	useGetMelhorEnvioTokenQuery,
	useSetMelhorEnvioTokenMutation,
} from '../../generated/graphql';
import { useDispatch, useSelector } from 'react-redux';
import IUserAccount from '../../models/IUserAccount';
import { AppState } from '../../store';
import { updateMelhorEnvioToken } from '../../actions/UserActions';


interface RefreshToken {
	token_type: string;
	expires_in: number;
	access_token: string;
	refresh_token: string;
}
type Props = {
	code?: string;
};

const TokenTest: React.FC<Props> = ({ code }) => {
	const [accessToken, setAccessToken] = useState<RefreshToken>();
	const [setMelhorEnvioToken] = useSetMelhorEnvioTokenMutation();
	const user: IUserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const { data, loading } = useGetMelhorEnvioTokenQuery({
		variables: { userId: user?.id },
	});

	const dispatch = useDispatch();

	useEffect(() => {
		if (data && data.getMelhorEnvioToken.token) {
			refreshToke(data.getMelhorEnvioToken.refreshToken as string)
			//dispatch(updateMelhorEnvioToken(data?.getMelhorEnvioToken.token!));
		}else{
			acessToken()
		}
	}, [data]);
	async function refreshToke(refreshToken:string){
		try{
			let myHeaders = new Headers();
				myHeaders.append('Accept', 'application/json');
				myHeaders.append(
					'User-Agent',
					'Sambay (teixeira@sambay.com.br)'
				);

				let formdata = new FormData();

				formdata.append('grant_type', 'refresh_token');
				formdata.append(
					'client_id',
					process.env.REACT_APP_MELHOR_ENVIO_CLIENT_ID!
				);
				formdata.append(
					'client_secret',
					process.env.REACT_APP_MELHOR_ENVIO_SECRET!
				);
				
				formdata.append('refresh_token',refreshToken)

				let requestOptions = {
					headers: myHeaders,
					url: `${process.env.REACT_APP_MELHOR_ENVIO_ENDPOINT}/oauth/token`,
					method: 'post',
					data: formdata,
				};
				// @ts-ignore
				let { data:refreshTokenData } = await axios(requestOptions);
				console.log(refreshTokenData)
				setAccessToken(refreshTokenData);
		}catch(err:any){
			console.log(err.message);
		}
		
	}
	async function acessToken() {
		if (code) {
			let acessToken = code.replace('?code=','')
			try {
				let myHeaders = new Headers();
				myHeaders.append('Accept', 'application/json');
				myHeaders.append(
					'User-Agent',
					'Sambay (teixeira@sambay.com.br)'
				);

				let formdata = new FormData();

				formdata.append('grant_type', 'authorization_code');
				formdata.append(
					'client_id',
					process.env.REACT_APP_MELHOR_ENVIO_CLIENT_ID!
				);
				formdata.append(
					'client_secret',
					process.env.REACT_APP_MELHOR_ENVIO_SECRET!
				);
				formdata.append(
					'redirect_uri',
					`${process.env.REACT_APP_MELHOR_ENVIO_REDIRECT_URI}`
				);
				formdata.append('code', acessToken);

				let requestOptions = {
					headers: myHeaders,
					url: `${process.env.REACT_APP_MELHOR_ENVIO_ENDPOINT}/oauth/token`,
					method: 'post',
					data: formdata,
				};
				// @ts-ignore
				let { data:acessTokenData } = await axios(requestOptions);
				setAccessToken(acessTokenData);
			} catch (err: any) {
				console.log(err.message);
			}
		}
	}

	useEffect(() => {
		setAcessTokenFunc();
	}, [accessToken]);

	const setAcessTokenFunc = async () => {
		if (accessToken) {
			try {
				await setMelhorEnvioToken({
					variables: {
						token: accessToken.access_token,
						refreshToken: accessToken.refresh_token,
						userId: user?.id,
					},
				});
				dispatch(updateMelhorEnvioToken(accessToken.access_token));

				//window.location.reload();
			} catch (err: any) {
				console.log(err);
			}
		}
	};

	return <></>;
};

export default TokenTest;
