import React, {
    useState,
    useEffect,
    useRef,
    useImperativeHandle,
    forwardRef,
    useCallback,
} from 'react'
import edit from '../../assets/icons/edit.svg'
import confirm from '../../assets/icons/confirm.svg'
import cancel from '../../assets/icons/cancel.svg'

import { useHistory, useParams } from 'react-router'
import IUserAccount from '../../models/IUserAccount'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '../../store'

import {
    insertUserAddress,
    updateUser,
    updateUserAddress,
} from '../../actions/UserActions'
import {
    CardTitle,
    CardContainer,
    CardContainerOrganizer,
    DataContainer,
    ModalContainer,
    ImgContainer,
} from './Style'
import {
    useUpdateUserAccountMutation,
    useCreateUserImageMutation,
    ProfileAddress,
    useCreateAddressMutation,
    useGetAllAddressQuery,
    useDeleteAddressMutation,
    useGetAllAddressLazyQuery,
    GetAllAddressQuery,
} from '../../generated/graphql'
import UserAddressCard from './UserAddressCard/UserAddressCard'
import { Modal } from 'semantic-ui-react'
import { useForm, FieldError } from 'react-hook-form'
import cep from 'cep-promise'
import AlertModal, { ModalHandles } from '../AlertModal/AlertModal'
import Img from '../Default/CommomImg/Img'
import { cepMaskFunc } from '../../utils/Masks'
import { toast, ToastContainer } from 'react-toastify'
import useWindow from '../../hooks/useWindow'

export type formProps = {
    street: string
    cep: string
    number: string
    complement?: string
    neighborhood: string
    city: string
    state: string
    errors: string
    adressTitle: string
    phone: string
}

interface PageParams {
    locale: string
}
export interface ModalAddAddressHandles {
    handleOpenModal: () => void
}

const ProfileCard: React.ForwardRefRenderFunction<ModalAddAddressHandles> = (
    {},
    ref
) => {
    const { innerWidth } = useWindow()
    const [deleteAdress] = useDeleteAddressMutation()
    const [updateUserAccount] = useUpdateUserAccountMutation()
    const [createUserImage] = useCreateUserImageMutation()
    const [myAddress, setMyAddress] = useState<ProfileAddress[]>([])

    const user: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )

    const { handleSubmit, register, setValue } = useForm<formProps>({
        mode: 'all',
        reValidateMode: 'onChange',
    })

    const dispatch = useDispatch()

    const [getAllAddress, { data: allAddress }] = useGetAllAddressLazyQuery({
        fetchPolicy: 'no-cache',
    })

    useEffect(() => {
        if (allAddress) {
            dispatch(updateUserAddress(allAddress.getAllAddress))
            setMyAddress(allAddress.getAllAddress)
        }
    }, [allAddress])

    useEffect(() => {
        if (user && user.id) {
            getAllAddress({ variables: { userId: user.id } })
        }
    }, [])

    const [showEdit, setShowEdit] = useState(false)
    const [phone, setPhone] = useState<any>(
        user.ProfileContacts ? user?.ProfileContacts[0]?.phone : undefined
    )

    const [name, setName] = useState<string>(user.name)
    const [cpf, setCpf] = useState<string>(user!.cpf!)
    const [email, setEmail] = useState<string>(user!.email!)
    const [input, setInput] = useState(true)
    const [state, setState] = useState({ dimmer: undefined, open: false })
    const [createAddress] = useCreateAddressMutation()
    const [cepMask, setCepMask] = useState<string>('')
    const [fullCep, setFullCep] = useState<any>('')

    const handleUpdate = async () => {
        try {
            let result = await updateUserAccount({
                variables: {
                    id: user.id,
                    phone: phone || '',
                    name: name,
                    cpf: cpf,
                    email: email,
                },
            })

            await dispatch(updateUser(result.data!.updateUserAccount! as any))

            setShowEdit(!showEdit)
            setInput(true)
            toast.success('Conta atualizada com sucesso!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                type: 'success',
                draggable: true,
                progress: undefined,
            })
        } catch (error: any) {
            toast.error(`${error.message}`, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                type: 'error',
                draggable: true,
                progress: undefined,
            })
        }
    }
    const deletarEndereco = useCallback(async (addressId: number) => {
        try {
            await deleteAdress({ variables: { addressId: addressId } })
            toast.success('Endereço removido com sucesso!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                type: 'success',
                draggable: true,
                progress: undefined,
            })
            getAllAddress({
                variables: { userId: user.id },
            })
        } catch (error: any) {
            toast.error(`${error.message}`, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                type: 'error',
                draggable: true,
                progress: undefined,
            })
        }
    }, [])

    const {
        formState: { errors },
    } = useForm<formProps>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
    })

    const handlePhoto = async (file: any): Promise<void> => {
        try {
            if (
                await createUserImage({
                    variables: { userId: user.id, Picture: file },
                })
            ) {
                window.location.reload()
            }
        } catch (error: any) {
            throw new Error(error.message)
        }
    }
    const setRead = (): void => {
        setShowEdit(!showEdit)
        setInput(false)
    }
    const onlyRead = (): void => {
        setShowEdit(false)
        setInput(true)
    }
    const locale: PageParams = useParams()

    useEffect(() => {
        if (locale.locale === 'address') {
            window.scrollTo({ top: window.innerHeight, behavior: 'smooth' })
        }
    }, [locale])

    const modalRef = useRef<ModalHandles>()

    const handleChange = (e: any, inputName: any): void => {
        const value = e
        setValue(inputName, value)
    }

    const handleOpenModal = (): void => {
        setState({ dimmer: undefined, open: true })
    }
    const history = useHistory()
    useImperativeHandle(ref, () => {
        return {
            handleOpenModal,
        }
    })

    const allCep = async (acep: string): Promise<void> => {
        try {
            await cep(acep).then((result: any) => setFullCep(result))
        } catch {}
    }

    useEffect(() => {
        setValue('city', fullCep.city)
        setValue('street', fullCep.street)
        setValue('state', fullCep.state)
        setValue('neighborhood', fullCep.neighborhood)
    }, [fullCep])

    const onSubmit = async (values: formProps): Promise<void> => {
        if (values) {
            try {
                const result = await createAddress({
                    variables: {
                        id: user.id,
                        addressTitle: values.adressTitle,
                        CEP: values.cep.replace('.', '').replace('-', ''),
                        number: values.number,
                        complement: values.complement,
                        city: values.city,
                        street: values.street,
                        state: values.state,
                        neighborhood: values.neighborhood,
                        phone: values.phone
                            .replace('(', '')
                            .replace(' ', '')
                            .replace(')', '')
                            .replace('-', ''),
                    },
                })
                toast.success('Endereço adicionado com sucesso!', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    type: 'success',
                    draggable: true,
                    progress: undefined,
                })
                dispatch(insertUserAddress(result!.data!.createAddress!))
                setState({
                    dimmer: undefined,
                    open: false,
                })
            } catch (err: any) {
                throw new Error(err.message)
            }
        }
    }
    const setPhoneFunc = (e: string): void => {
        setPhone(e)
    }

    return (
        <>
            <ToastContainer />
            <AlertModal
                AreaTitle="Novo endereço"
                DescriptionText="Cep não encontrado"
                reload={false}
            />
            {history.location.pathname === '/user/data/top' && (
                <>
                    <CardContainerOrganizer>
                        <CardContainer>
                            <label htmlFor="photo">
                                <div className="image-border">
                                    <Img
                                        src={`${process.env.REACT_APP_STATIC_URL}/user/${user.id}.jpeg`}
                                    />
                                </div>
                            </label>
                            <input
                                type="file"
                                id="photo"
                                onChange={(e: any) =>
                                    handlePhoto(e.target.files[0])
                                }
                            />
                            <div className="infos-container">
                                <h2>{user.name}</h2>
                                <span>Comprador/vendedor</span>
                            </div>
                        </CardContainer>
                    </CardContainerOrganizer>
                    <CardTitle>
                        <h2>Dados gerais</h2>
                        <DataContainer>
                            <div className="org">
                                <div className="field-group">
                                    <div className="first-line">
                                        <div
                                            className="title-input-container"
                                            style={{ flex: '1' }}
                                        >
                                            <label>Nome*</label>
                                            <input
                                                type="text"
                                                required
                                                onChange={(e) =>
                                                    setName(e.target.value)
                                                }
                                                defaultValue={user.name}
                                                readOnly={input}
                                            />
                                        </div>
                                    </div>
                                    <div className="second-line">
                                        <div
                                            className="small-input-container"
                                            style={{ flex: '1' }}
                                        >
                                            <label>Cpf*</label>
                                            <input
                                                type="text"
                                                required
                                                onChange={(e) =>
                                                    setCpf(e.target.value)
                                                }
                                                defaultValue={user.cpf as any}
                                                readOnly={input}
                                            />
                                        </div>

                                        <>
                                            <div
                                                className="small-input-container"
                                                style={{ flex: '1' }}
                                            >
                                                <label>Telefone*</label>
                                                <input
                                                    type="text"
                                                    required
                                                    onChange={(e) =>
                                                        setPhoneFunc(
                                                            e.target.value
                                                        )
                                                    }
                                                    value={phone}
                                                    readOnly={input}
                                                />
                                            </div>
                                        </>
                                        <div
                                            className="small-input-container"
                                            style={{ flex: '2' }}
                                        >
                                            <label>E-mail*</label>
                                            <input
                                                type="text"
                                                required
                                                onChange={(e) =>
                                                    setEmail(e.target.value)
                                                }
                                                defaultValue={user.email}
                                                readOnly={input}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ImgContainer>
                                {!showEdit && (
                                    <img
                                        src={edit}
                                        id="edit"
                                        style={{
                                            position: 'absolute',
                                            width: '37px',
                                            left: '1rem',
                                        }}
                                        onClick={() => setRead()}
                                    />
                                )}
                                {showEdit && (
                                    <>
                                        <img
                                            src={confirm}
                                            id="edit"
                                            style={{
                                                alignSelf: 'start',
                                                position: 'absolute',
                                                width: '37px',
                                                left: '1rem',
                                            }}
                                            onClick={() => handleUpdate()}
                                        />
                                        <img
                                            src={cancel}
                                            id="edit"
                                            style={{
                                                position: 'absolute',
                                                width: '37px',
                                                bottom: ' -87px',
                                                left: '1rem',
                                            }}
                                            onClick={() => onlyRead()}
                                        />
                                    </>
                                )}
                            </ImgContainer>
                        </DataContainer>
                    </CardTitle>
                    <CardTitle>
                        <h2>Endereços e localidades</h2>
                        {myAddress &&
                            myAddress.map(
                                (address: ProfileAddress, index: number) => (
                                    <UserAddressCard
                                        address={address}
                                        key={index}
                                        handledeletarEndereco={deletarEndereco}
                                    />
                                )
                            )}

                        {myAddress.length === 0 && (
                            <h3>Sem endereços cadastrados</h3>
                        )}

                        <div
                            className="button-container"
                            onClick={() =>
                                setState({ dimmer: undefined, open: true })
                            }
                        >
                            <button className="buttonAddEndereco">
                                + Adicionar novo endereço
                            </button>
                        </div>
                    </CardTitle>
                </>
            )}
            <Modal
                dimmer={state.dimmer}
                open={state.open}
                onClose={() => state.open === false}
            >
                <ModalContainer>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="modal-title">
                            <h2>Novo endereço</h2>
                        </div>
                        <div className="line"></div>
                        <div
                            className="modal-inputs"
                            style={{ padding: '30px', paddingTop: '6px' }}
                        >
                            <div
                                className="first-line"
                                style={{ width: '100%' }}
                            >
                                <div
                                    className="modal-inputs-organizer-modal"
                                    style={{
                                        height: '46px',
                                        marginBottom: '25px',
                                        marginTop: '25px',
                                        width: '100%',
                                    }}
                                >
                                    <label>Titulo da localidade*</label>
                                    <input
                                        type="text"
                                        placeholder="Ex: Local de Trabalho, Casa e etc."
                                        style={{
                                            fontStyle: 'italic',
                                            backgroundColor: '#fff',
                                            padding: '3px',
                                        }}
                                        {...register('adressTitle', {
                                            required: true,
                                        })}
                                        onChange={(e) =>
                                            handleChange(
                                                e.target.value,
                                                'adressTitle'
                                            )
                                        }
                                    />
                                </div>
                            </div>

                            <div className="second-line">
                                <div
                                    className="modal-inputs-organizer-modal"
                                    style={{
                                        height: '46px',
                                        marginBottom: '25px',
                                        width: '100%',
                                    }}
                                >
                                    <label>CEP*</label>
                                    <input
                                        type="text"
                                        placeholder="xx.xxx-xxx"
                                        autoComplete="no"
                                        {...register('cep', {
                                            required: 'Insira um CEP',
                                            pattern: {
                                                value: /^(\d{2})\.(\d{3})-(\d{3})$/,
                                                message: 'Insira um CEP válido',
                                            },
                                        })}
                                        style={{
                                            fontStyle: 'italic',
                                            backgroundColor: '#fff',
                                            padding: '3px',
                                        }}
                                        id="CEP"
                                        onChange={(e) => {
                                            setCepMask(
                                                cepMaskFunc(e.target.value)
                                            )
                                            allCep(e.target.value)
                                        }}
                                        value={cepMask}
                                    />
                                    {errors.cep && (
                                        <span className="field-errors">
                                            {`${errors.cep.message}`}
                                        </span>
                                    )}
                                </div>

                                <div
                                    className="modal-inputs-organizer-modal"
                                    style={{
                                        width: '100%',
                                        height: ' 46px',
                                        marginRight:
                                            window.innerHeight > 768
                                                ? '25px'
                                                : '0px',
                                        marginBottom: '25px',
                                    }}
                                >
                                    <label>Endereço</label>
                                    <input
                                        type="text"
                                        autoComplete="no"
                                        {...register('street', {
                                            required: true,
                                        })}
                                        onChange={(e) =>
                                            handleChange(
                                                e.target.value,
                                                'street'
                                            )
                                        }
                                        style={{
                                            backgroundColor: '#fff',
                                            padding: '3px',
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="third-line" style={{ gap: '25px' }}>
                                <div
                                    className="modal-inputs-organizer-modal bairro"
                                    style={{
                                        height: '46px',
                                        width:
                                            innerWidth < 768 ? '100%' : '100%',
                                    }}
                                >
                                    <label>Bairro</label>
                                    <input
                                        type="text"
                                        autoComplete="no"
                                        {...register('neighborhood', {
                                            required: false,
                                        })}
                                        onChange={(e) =>
                                            handleChange(
                                                e.target.value,
                                                'neighborhood'
                                            )
                                        }
                                        style={{
                                            backgroundColor: '#fff',
                                            padding: '3px',
                                        }}
                                    />
                                </div>

                                <div
                                    className="modal-inputs-organizer-modal"
                                    style={{
                                        width:
                                            window.innerHeight > 768
                                                ? '100%'
                                                : '100%',
                                        height: '46px ',
                                    }}
                                >
                                    <label>Número*</label>
                                    <input
                                        type="text"
                                        {...register('number', {
                                            required: true,
                                        })}
                                        onChange={(e) =>
                                            handleChange(
                                                e.target.value,
                                                'number'
                                            )
                                        }
                                        style={{
                                            backgroundColor: '#fff',
                                            padding: '3px',
                                        }}
                                    />
                                </div>
                                <div
                                    className="modal-inputs-organizer-modal"
                                    style={{
                                        width:
                                            window.innerHeight > 768
                                                ? '100%'
                                                : '100%',
                                        height: ' 46px',
                                    }}
                                >
                                    <label>Complemento*</label>
                                    <input
                                        type="text"
                                        {...register('complement', {
                                            required: true,
                                        })}
                                        onChange={(e) =>
                                            handleChange(
                                                e.target.value,
                                                'complement'
                                            )
                                        }
                                        style={{
                                            backgroundColor: '#fff',
                                            padding: '3px',
                                        }}
                                    />
                                </div>
                            </div>

                            <div
                                className="fourth-line"
                                style={{ gap: '25px' }}
                            >
                                <div
                                    className="modal-inputs-organizer-modal"
                                    style={{
                                        height: '46px',
                                        width:
                                            innerWidth < 768 ? '100%' : '100%',
                                    }}
                                >
                                    <label>Cidade</label>
                                    <input
                                        type="text"
                                        autoComplete="no"
                                        {...register('city', {
                                            required: false,
                                        })}
                                        id="city"
                                        style={{
                                            backgroundColor: '#fff',
                                            padding: '3px',
                                        }}
                                    />
                                </div>

                                <div
                                    className="modal-inputs-organizer-modal"
                                    style={{
                                        width:
                                            window.innerHeight > 768
                                                ? '100%'
                                                : '100%',
                                        height: '46px ',
                                    }}
                                >
                                    <label>Estado</label>
                                    <input
                                        type="text"
                                        autoComplete="no"
                                        {...register('state', {
                                            required: true,
                                        })}
                                        id="state"
                                        style={{
                                            backgroundColor: '#fff',
                                            padding: '3px',
                                        }}
                                    />
                                </div>
                                <div
                                    className="modal-inputs-organizer-modal"
                                    style={{
                                        width: '100%',
                                        marginBottom: '25px',
                                    }}
                                >
                                    <label>Telefone*</label>
                                    <input
                                        type="text"
                                        id="state"
                                        style={{
                                            backgroundColor: '#fff',
                                            padding: '3px',
                                        }}
                                        {...register('phone', {
                                            required: true,
                                        })}
                                    />
                                </div>
                            </div>
                            <span
                                style={{
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    color: '#6b97a5',
                                }}
                            >
                                Campos marcados com * são obrigatórios para
                                cadastro.
                            </span>
                            <div
                                className="modal-actions"
                                style={{ justifyContent: 'center' }}
                            >
                                <div className="buttons-container">
                                    <button
                                        className="negative-button"
                                        onClick={() =>
                                            setState({
                                                dimmer: undefined,
                                                open: false,
                                            })
                                        }
                                    >
                                        Cancelar
                                    </button>
                                </div>
                                <div className="buttons-container">
                                    <button
                                        className="positive-button"
                                        type="submit"
                                    >
                                        Cadastrar endereço
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </ModalContainer>
            </Modal>
        </>
    )
}
export default forwardRef(ProfileCard)
