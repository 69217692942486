import React from 'react';
import Layout from '../../components/Layout/Layout';
import SocialProjects from '../../components/SocialProjects/SocialProjects';

const SocialProjectsPage = () => {
	return (
		<Layout title="Projetos Sociais">
			<SocialProjects />
		</Layout>
	);
};

export default SocialProjectsPage;
