import React, { useEffect, useState } from 'react'
import { Product, useGetAllProductsQuery,useGetDinamicSliderQuery } from '../../generated/graphql'
import ProductCard from '../ProductCard/ProductCard'
import AliceCarousel from 'react-alice-carousel'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { SliderContainer } from '../../utils/AliceCarouselCustomSlider'
import { Container } from './styles'
import { useHistory } from 'react-router'
import moment from 'moment'
import ALICE_CAROUSEL_DEFAULTS from '../../utils/AliceCarouselDefaults'

const ShowCaseDinamic: React.FC = () => {

    const [startDate,setStartDate] = useState<string>('')
    const [endDate,setEndDate] = useState<string>('')
    const [isvalidDate,setIsvalidDate] = useState<boolean>(false)

    const {data:getDinamicSlider,loading:getDinamicSliderLoading} = useGetDinamicSliderQuery({
        variables:{
            GetDinamicSliderInput:{
                idSlider:1
            }
        },
        fetchPolicy:'no-cache'
    })

    const [itens, setItens] = useState<any[]>()
    const history = useHistory()
    const responsive = {
        0: { items: 1 },
        320: { items: 2 },
        325: { items: 2 },
        425: { items: 2 },
        568: { items: 3 },
        768: { items: 4 },
        1024: { items: 6},
    }

    const renderPrevButton = ({ isDisabled }: any): JSX.Element => {
        return (
            <span style={{ opacity: isDisabled ? '' : 1 }}>
                <MdChevronLeft color="black" size="3rem" />
            </span>
        )
    }

    const renderNextButton = ({ isDisabled }: any): JSX.Element => {
        return (
            <span style={{ opacity: isDisabled ? '' : 1 }}>
                <MdChevronRight color="black" size="3rem" />
            </span>
        )
    }

    useEffect(() => {
        if (getDinamicSlider && getDinamicSlider.getDinamicSlider) {

           const prodcutsShowCase = []

           for(let dinamicItem of getDinamicSlider.getDinamicSlider.DinamicSlidersItens){
                prodcutsShowCase.push(dinamicItem.Product)
           }
           
            setItens(prodcutsShowCase)
        }

        if(getDinamicSlider && getDinamicSlider.getDinamicSlider.dateStart){
            setStartDate(getDinamicSlider.getDinamicSlider.dateStart)
            setEndDate(getDinamicSlider.getDinamicSlider.dateEnd)
        }
    }, [getDinamicSlider])

    useEffect(()=>{
        if(startDate && endDate){
            const newDateEnd = new Date(endDate.replace('T00:00:00.000Z',''))
            const newDateStart = new Date(startDate)
            setIsvalidDate(false)
            if(moment().isSameOrAfter(newDateStart)){
                if(newDateEnd){
                    if(moment().isSameOrBefore(newDateEnd)){
                        setIsvalidDate(true)
                    }
                }else{
                    setIsvalidDate(true)
                }
            }
        }
    },[startDate,endDate])

    if (!isvalidDate || itens?.length === 0) {
        return <></>
    }

    return (
        <SliderContainer>
            <Container>
                <div className="home-container">
                    <div className="product-cards-container">
                        <div className="container-title">
                            <h1>{getDinamicSlider?.getDinamicSlider.title}</h1>
                        </div>
                        
                        <AliceCarousel
                           {...ALICE_CAROUSEL_DEFAULTS}
                           renderPrevButton={renderPrevButton}
                           renderNextButton={renderNextButton}
                            items={itens?.map((product: Product) => {
                                return (
                                    <ProductCard
                                        product={product}
                                        key={product?.id}
                                    />
                                )
                            })}
                        />
                    </div>
                </div>
            </Container>
        </SliderContainer>
    )
}

export default ShowCaseDinamic
