import React from 'react';
import Layout from '../../components/Layout/Layout';
import MyBuysItem from '../../components/MyBuys/MyBuysItem/MyBuysItem';

// import { Container } from './styles';

const MyBuysItemPage = () => {
	return (
		<Layout title="Minha Compra">
			<MyBuysItem />
		</Layout>
	);
};

export default MyBuysItemPage;
