import styled from 'styled-components';

export const Container = styled.div`
	width: 470px;
	height: 254px;
	background-color: white;
	box-shadow: 1px 1px 3px 1px grey;
	border-radius: 8px;
	padding: 28px;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 15px;
	margin-bottom: 25px;

	.notification-header {
		width: 100%;
		padding: 10px;
		display: flex;
		justify-content: space-between;
		height: max-content;
		h1 {
			color: #6b98a6;
			font-family: 'Poppins', semi-bold;
			font-weight: 600;
			font-size: 16px;
			width: 250px;
			margin-bottom: 0px;
		}
	}

	.image-container {
		background: #fff;
		width: 100%;
		padding: 10px;
		display: flex;
		.product-infos {
			display: flex;
			flex-direction: column;
			margin-left: 10px;

			h2 {
				margin-bottom: 10px;
				color: black;
				font-family: 'Poppins';
				font-weight: bold;
				font-size: 15px;
			}
			h5 {
				margin-top: 0px;
				color: #6b97a5;
				font-size: 24px;
				font-family: 'Poppins';
				font-weight: 600;
			}
		}
		img {
			width: 135px;
			height: 89px;
			object-fit: cover;
			border-radius: 8px;
		}
	}

	.redirect-button-container {
		width: 100%;
		padding: 10px;

		.redirect-button {
			background: #69b64a 0% 0% no-repeat padding-box;
			border-radius: 8px;
			width: 100%;
			height: 37px;
			color: white;
			border: none;
			font-family: 'Poppins';
			font-weight: bold;
			font-size: 14px;

			&:hover {
				cursor: pointer;
			}
			&:focus {
				outline: none;
			}
		}
	}

	@media (min-width:425px) and (max-width:767px){
		width:400px;
		height: 230px;
		padding-left: 0;
		padding-right: 0;
		padding:0;
	}
	@media (min-width:375px) and (max-width:424px){
		width:350px;
		height: 230px;
		padding-left: 0;
		padding-right: 0;
		padding:0;
	}
	@media (min-width:320px) and (max-width:374px){
		width:300px;
		height: 230px;
		padding-left: 0;
		padding-right: 0;
		padding:0;
	}
	
`;

export const NotificationsContainer = styled.div`
	width: 500px;
	height: auto;
	display: flex;
	flex-direction: column;
	position: fixed;
	right: -15px;
	bottom: 10px;
	transition: all 20s;
	z-index: 1;

	@media (min-width:425px) and (max-width:767px){
		width:100%;
		flex-direction: center;
	}
	
	@media (min-width:375px) and (max-width:424px){
		width:100%;
		flex-direction: center;
	}
	@media (min-width:320px) and (max-width:374px){
		width:100%;
		flex-direction: center;
	}
`;
