import React from 'react'
import { Container } from './styles'
import imgvantagem from './../../assets/vantagem2.png'
import imgvantagem2 from './../../assets/vantagem3.png'
import { useHistory } from 'react-router'
import mobile_manual from './../../assets/mobile_manual.png'
import mobile_vantagens from './../../assets/mobile_vantagens.png'
import useWindow from '../../hooks/useWindow'

const BannerPromocional: React.FC = () => {
    const history = useHistory()
    const window = useWindow()
    return (
        <>
            <Container>
                <div className="sectionBannerUpsell">
                    {window.innerWidth < 768 ? (
                        <>
                            <div className="container-mobile">
                                <img
                                    src={mobile_vantagens}
                                    alt="mobile_vantagens"
                                />
                                <img src={mobile_manual} alt="mobile_manual" />
                            </div>
                        </>
                    ) : (
                        <>
                            <img
                                src={imgvantagem}
                                alt="img-divider"
                                className="imgBannerUpsell"
                                onClick={() => history.push(`/register`)}
                            />
                            <img
                                src={imgvantagem2}
                                alt="img-divider"
                                className="imgBannerManualAnuncio"
                            />
                        </>
                    )}
                </div>
            </Container>
        </>
    )
}
export default BannerPromocional
