import React, { useEffect } from 'react';
import Faq from '../../components/Faq/Faq';
import Layout from '../../components/Layout/Layout';

const FaqPage = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Layout title="Perguntas Frequentes">
			<Faq />
		</Layout>
	);
};

export default FaqPage;
