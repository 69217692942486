import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 3rem;
	background-color:#fff;
`;

export const UserCuponsHeader = styled.div`
	display: flex;
	padding: 4px;
	cursor: pointer;
	width: 11vw;
	button {
		border: none;
		border-radius: 100px;
		background-color: white;
		cursor: pointer;
		color: var(--azul_sambay);
	}

	p {
		font-size: 22px;
		color: black;
		font-family: 'Poppins', semi-bold;
	}
`;

export const UserCuponsList = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	height: 30vh;
	margin: 2rem;
`;

export const UserCuponItem = styled.div`
	display: flex;
	height: 6vh;
	border: dashed 1px black;
	flex-direction: column;
	justify-content: center;
	margin-right: 1rem;
	padding: 35px;
	cursor: pointer;

	p {
		display: flex;
		justify-content: center;
		font-size: 22px;
		color: black;
		font-family: 'Poppins', semi-bold;
		margin: 0px;
	}

	span {
		font-size: 14px;
		color: black;
		font-family: 'Poppins', semi-bold;
	}
	button {
		width: 2vw;
		border: none;
		background-color: white;
	}
`;
export const AddNewCupomContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;
export const AddNewCupomModalHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-bottom: 1px solid black;
	padding: 8px;
	background-color: var(--azul_sambay);
	h1 {
		font-size: 25px;
		font-family: 'Poppins', semi-bold;
		font-weight: 600;
		color: white;
	}

	.iconCloseModal {
		color: white;
		cursor: pointer;
	}
`;
export const AddNewCupomModalCampos = styled.div`
	display: flex;
	flex-direction: row;
	padding: 10px;

	span {
		color: black;
		font-size: 15px;
		font-family: 'Poppins', semi-bold;
		font-weight: 600;
	}

	input {
		height: 1.6rem;
		border: 1px solid;
		color: #6b97a5;
		margin-left: 4px;
		margin-right: 4px;
		border-radius: 10px;
	}
`;
export const AddNewCupomModalProdutos = styled.div`
	padding: 10px;

	.AddNewCupomModalProdutosHeader {
		display: flex;
		flex-direction: row;
	}

	.AddNewCupomModalProdutosSearch {
		display: flex;
		flex-direction: row;
		padding: 10px;

		input {
			width: 40%;
			border-radius: 8px;
		}
	}

	.AddNewCupomModalProdutosMain {
		display: grid;
		grid: 100px / auto auto auto;

		.ProdutosItem {
			display: flex;
			flex-direction: row;
			align-items: center;
		}
	}

	span {
		color: black;
		font-size: 15px;
		font-family: 'Poppins', semi-bold;
		margin-left: 10px;
		margin-right: 10px;
	}

	input {
		width: 1.3rem;
	}
`;
export type AddNewCupomModalButtonType = {
	active: boolean;
};

export const AddNewCupomModalFooter = styled.div`
	display: flex;
	align-items: end;
	justify-items: end;
	justify-content: space-between;
	background-color: darkgray;
	border-top: 1px solid black;
	height: 4vh;
`;

export const AddNewCupommodalButton = styled.button<AddNewCupomModalButtonType>`
	background-color: ${(props) => (props.active ? 'green' : 'grey')};
	height: 4vh;
	font-weight: 600;
	color: white;
	font-size: 18px;
	border: none;
	font-family: 'Poppins', semi-bold;
	cursor: pointer;
`;

export const AddNewCupommodalSucess = styled.div`
	display: flex;
	align-items: center;
	align-content: center;
	justify-items: center;
	justify-content: space-around;
	flex-direction: column;
	padding: 15px;
	
	
	h1{
		color:black;
		font-size: 25px;
		font-family: 'Poppins', semi-bold;
	}
`;
