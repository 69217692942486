import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { ProfileMenuBar } from './styles';
import icon_userprofile from '../../assets/icons/profile-icon-bar.svg';
import icon_money from '../../assets/icons/icon_money.svg';
import icon_exit from '../../assets/icons/icon_exit.svg';
import icon_help from '../../assets/icons/icon_help_header.svg';
import icon_whats from '../../assets/icons/icon_whats_black.svg';
import { handleLogout } from '../../handlers/handleLogout';

const ProfileMenu: React.FC = () => {
	const history = useHistory();
	const [showExit, setShowExit] = useState<boolean>(false);
	const [showCategories, setShowCategories] = useState<boolean>(false);



	return (
		<>
			<ProfileMenuBar>
				<div className="section-menu-bar">
					<div
						className="section-option-profile"
						onClick={() => history.push(`/user/data/top`)}
					>
						<img
							src={icon_userprofile}
							alt="icon-user-profile"
							className="style-icon-profile-bar"
						/>
						<h2 className="span-profile-menu-bar">Perfil</h2>
					</div>
					<div
						className="section-option-sales"
						onClick={() => history.push(`/my-buys`)}
					>
						<img
							src={icon_money}
							alt="icon-vendas"
							className="icon-vendas"
						/>
						<span className="span-sales-menu-bar">Compras</span>
					</div>
					<div
						className="section-option-help"
						onClick={() => history.push(`/faq`)}
					>
						<img
							src={icon_help}
							alt="icone-ajuda"
							className="icon-help"
						/>
						<span className="span-help-menu-bar">Ajuda</span>
					</div>
					<div className="section-option-sac">
						<a
							href="https://api.whatsapp.com/send?phone=5541985094049&text=Ol%C3%A1%20Sambay%20Express!%20Preciso%20de%20uma%20ajuda%20de%20voc%C3%AAs%20%3A)"
							className="span-sac-menu-bar"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								src={icon_whats}
								alt="icon-suporte"
								className="icon-suporte"
							/>
							<span className="span-sac-menu-bar">SAC</span>
						</a>
					</div>
					<div className="section-option-exit" onClick={() => handleLogout()}>
						<img
							src={icon_exit}
							alt="icon-sair"
							className="icon-sair"
						/>
						<span className="span-sair-menu-bar">Sair</span>
					</div>
				</div>
			</ProfileMenuBar>
		</>
	);
};
export default ProfileMenu;
