import React, { useState } from 'react'
import { Modal } from 'semantic-ui-react'

import { ReactComponent as Help } from '../../../assets/icons/help.svg'
import { FaqQuestion, faqQuestions } from '../../../utils/faqQuestions'
import { Container } from '../Style'
import Item from './Item/Item'
import { GrDocumentPdf } from 'react-icons/gr/index'

import { Image, MainCard } from './styles'

const HelpPopUp: React.FC = () => {
    const [state, setState] = useState(false)
    return (
        <>
            <Image>
                <Help
                    className="icon"
                    fill="#FFC000"
                    onClick={() => setState(true)}
                />
            </Image>
            <Modal
                open={state}
                onClose={() => setState(false)}
                onOpen={() => setState(true)}
            >
                <Container>
                    <MainCard>
                        <h2>Dúvidas frequentes sobre criação de anúncio</h2>
                        <div className="items-container">
                            <div className="styleManual">
                                <a
                                    href={`${process.env.REACT_APP_STATIC_URL}/manual/manual-anuncio.pdf`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="styleManual"
                                >
                                    <GrDocumentPdf
                                        size={25}
                                        className="styleIcon"
                                    ></GrDocumentPdf>{' '}
                                    Clique aqui para baixar o manual de como
                                    anunciar.
                                </a>
                            </div>
                            {faqQuestions.map(
                                (question: FaqQuestion, index) => (
                                    <Item {...question} key={index} />
                                )
                            )}
                        </div>
                    </MainCard>
                </Container>
            </Modal>
        </>
    )
}

export default HelpPopUp
