import React from 'react'

import { useSelector } from 'react-redux'
import IUserAccount from '../../../models/IUserAccount'
import { AppState } from '../../../store'
import { useGetProductByUserIdQuery } from '../../../generated/graphql'
import { MyProductContainer, Card, Title } from '../Style'
import MyProductsCard from '../MyProductsCard'

const InactiveProducts: React.FC = () => {
    const user: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )

    const { data, loading } = useGetProductByUserIdQuery({
        variables: {
            getProductByUserId: {
                userId: user.id,
                active: false,
            },
        },
    })

    return (
        <>
            <MyProductContainer
                style={{ opacity: `${user.block ? '0.3' : '1'}` }}
            >
                <Title>
                    <div className="title-organizer ">
                        <h2
                            style={{
                                fontSize: '28px',
                                color: '#6b97a5',
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                            }}
                        >
                            Produtos inativos
                        </h2>
                    </div>
                </Title>

                <Card>
                    <div className="product-cards-container">
                        {!loading &&
                            data &&
                            data.getProductByUserId?.map(
                                (product: any, index: number) => (
                                    <>
                                        {!product.active && (
                                            <MyProductsCard
                                                product={product}
                                                key={index}
                                            />
                                        )}
                                    </>
                                )
                            )}
                    </div>
                </Card>
            </MyProductContainer>
        </>
    )
}
export default InactiveProducts;
