import UserAccountsList, {
	IInsertOneUserAction,
	IInsertUsersAction,
} from '../models/AllUsers';

export const addUserList = (
	userAccountsList: UserAccountsList[]
): IInsertUsersAction => ({
	type: 'ADD_USERS_LIST',
	userAccountsList,
});
export const addOneUserOnList = (
	userAccounts: UserAccountsList
): IInsertOneUserAction => ({
	type: 'ADD_ONE_USER',
	userAccounts,
});
