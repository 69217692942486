import React, { useState, useEffect } from 'react'
import {
    useGetAllProductsQuery,
    useCreateDinamicSliderMutation,
    useCreateDinamicSliderItemMutation,
    useGetDinamicSliderQuery,
    useUpdateDinamicSliderMutation,
    useDeleteDinamicSliderItemMutation,
    useGetAllProductsLazyQuery,
    Product,
} from '../../generated/graphql'
import {
    ShowCaseContainer,
    ShowCaseHeader,
    ShowCaseCard,
    ShowCaseInput,
    ShowCaseCardProduct,
    ButtonShowCase,
} from './styles'

import { toast, ToastContainer } from 'react-toastify'

export default function AdmShowCaseDinamic() {
    const [showCaseProducts, setShowCaseProducts] = useState<number[]>([])
    const [showCaseNewsProducts, setShowCaseNewsProducts] = useState<number[]>(
        []
    )
    const [titleShowCase, setTitleShowCase] = useState<string>('')
    const [dateStartShowCase, setDateStartShowCase] = useState<string>('')
    const [dateEndShowCase, seDateEndShowCase] = useState<string>('')
    const [sendShowCase, setSendShowCase] = useState<boolean>(false)
    const [itens, setItens] = useState<any[]>()

    const { data: getDinamicSlider } = useGetDinamicSliderQuery({
        variables: {
            GetDinamicSliderInput: {
                idSlider: 1,
            },
        },
        fetchPolicy: 'no-cache',
    })

    const [deleteDinamicSliderItem] = useDeleteDinamicSliderItemMutation({
        fetchPolicy: 'no-cache',
    })

    const [searchProduct, { data: searchProductResponse }] =
        useGetAllProductsLazyQuery()

    const { data: allProductData } = useGetAllProductsQuery({
        variables: {
            getAllProductInput: {
                limit: 100,
                type: 'pagination',
                offset: 0,
            },
        },
        fetchPolicy: 'no-cache',
    })

    useEffect(() => {
        if (searchProductResponse && searchProductResponse.getAllProducts) {
            setItens(searchProductResponse.getAllProducts)
        }
    }, [searchProductResponse])

    useEffect(() => {
        if (allProductData && allProductData.getAllProducts) {
            setItens(allProductData.getAllProducts)
        }
    }, [allProductData])

    useEffect(() => {
        if (getDinamicSlider && getDinamicSlider.getDinamicSlider) {
            const productsSelecteds =
                getDinamicSlider.getDinamicSlider.DinamicSlidersItens.map(
                    (showCaseItem) => showCaseItem.productId
                )

            setTitleShowCase(getDinamicSlider.getDinamicSlider.title)
            setDateStartShowCase(
                getDinamicSlider.getDinamicSlider.dateStart.replace(
                    'T00:00:00.000Z',
                    ''
                )
            )
            seDateEndShowCase(
                getDinamicSlider.getDinamicSlider.dateEnd.replace(
                    'T00:00:00.000Z',
                    ''
                )
            )
            setShowCaseProducts([...productsSelecteds])
        }
    }, [getDinamicSlider])
    const [sendDinamicSlider] = useCreateDinamicSliderMutation()

    const [sendDinamicSliderItem] = useCreateDinamicSliderItemMutation()
    const [updateDinamicSliderService] = useUpdateDinamicSliderMutation()

    async function handleSelectedProduct(newproductId: number) {
        const findProduct = showCaseNewsProducts.find(
            (productId) => productId === newproductId
        )
        const findProductShowCase = showCaseProducts.find(
            (productId) => productId === newproductId
        )

        if (!findProductShowCase && !findProduct) {
            setShowCaseNewsProducts([...showCaseNewsProducts, newproductId])
            setShowCaseProducts([...showCaseProducts, newproductId])
        } else {
            const newProducts = showCaseNewsProducts.filter(
                (producId) => producId !== newproductId
            )
            const newProducShowCase = showCaseProducts.filter(
                (producId) => producId !== newproductId
            )

            if (findProductShowCase) {
                try {
                    await deleteDinamicSliderItem({
                        variables: {
                            productId: newproductId,
                        },
                    })

                    toast.success('Produto removido com sucesso!', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        type: 'success',
                        draggable: true,
                        progress: undefined,
                    })
                } catch (error: any) {
                    console.log('Error', error)
                }
            }

            setShowCaseNewsProducts(newProducts)
            setShowCaseProducts(newProducShowCase)
        }
    }

    async function createDinamicSlider() {
        try {
            const { data } = await sendDinamicSlider({
                variables: {
                    createDinamicSliderInput: {
                        title: titleShowCase,
                        dateStart: dateStartShowCase,
                        dateEnd: dateEndShowCase,
                    },
                },
            })

            if (data && data.createDinamicSlider.id) {
                await sendDinamicSliderItem({
                    variables: {
                        CreateDinamicSliderItemInput: {
                            idSlider: data.createDinamicSlider.id,
                            productsId: showCaseProducts,
                        },
                    },
                })
            }

            toast.success('Slider criado com sucesso!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                type: 'success',
                draggable: true,
                progress: undefined,
            })
        } catch (error: any) {
            console.log('error', error.message)
        }
    }

    async function updateDinamicSlider() {
        const result = await updateDinamicSliderService({
            variables: {
                updateDinamicSliderInput: {
                    dinamicId: 1,
                    title: titleShowCase,
                    dateStart: dateStartShowCase,
                    dateEnd: dateEndShowCase,
                },
            },
        })

        if (result && result.data && result.data.updateDinamicSlider.id) {
            await sendDinamicSliderItem({
                variables: {
                    CreateDinamicSliderItemInput: {
                        idSlider: result.data.updateDinamicSlider.id,
                        productsId: showCaseNewsProducts,
                    },
                },
            })
        }

        toast.success('Slider atualizado com sucesso!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            type: 'success',
            draggable: true,
            progress: undefined,
        })
    }

    useEffect(() => {
        if (
            titleShowCase.length > 0 &&
            showCaseProducts.length > 0 &&
            dateStartShowCase.length > 0
        ) {
            setSendShowCase(true)
        } else {
            setSendShowCase(false)
        }
    }, [titleShowCase, showCaseProducts, dateStartShowCase, dateEndShowCase])

    async function filterProducts(value: string) {
        searchProduct({
            variables: {
                getAllProductInput: {
                    offset: 0,
                    limit: 50,
                    text: value,
                    type: 'search',
                },
            },
        })
    }

    return (
        <ShowCaseContainer>
            <ToastContainer />
            <ShowCaseHeader>
                <h1>Vitrine Dinâmica</h1>
            </ShowCaseHeader>
            <ShowCaseCard className="tw-shadow-md">
                <div className="cardBody">
                    <ShowCaseInput>
                        <span>Título</span>
                        <input
                            id="titulo"
                            type="text"
                            placeholder="Título"
                            onChange={(event) =>
                                setTitleShowCase(event.target.value)
                            }
                            defaultValue={titleShowCase}
                        />
                    </ShowCaseInput>
                    <ShowCaseInput>
                        <span>Data Início</span>
                        <input
                            id="dataInicio"
                            type="date"
                            placeholder="Data Início"
                            defaultValue={dateStartShowCase}
                            onChange={(event) =>
                                setDateStartShowCase(event.target.value)
                            }
                        />
                    </ShowCaseInput>
                    <ShowCaseInput>
                        <span>Data Fim</span>
                        <input
                            type="date"
                            placeholder="Data Fim"
                            defaultValue={dateEndShowCase}
                            onChange={(event) =>
                                seDateEndShowCase(event.target.value)
                            }
                        />
                    </ShowCaseInput>

                    <ShowCaseInput>
                        <input
                            type="text"
                            onChange={(e) => filterProducts(e.target.value)}
                            placeholder=" Busque um produto por título, estado, material , marca ou tipo de negociação."
                            style={{ marginTop: '1.3rem' }}
                        />
                    </ShowCaseInput>
                    {getDinamicSlider &&
                    getDinamicSlider?.getDinamicSlider.title ? (
                        <>
                            <div className="cardFooter">
                                <ButtonShowCase
                                    activeButton={sendShowCase}
                                    disabled={!sendShowCase}
                                    onClick={updateDinamicSlider}
                                >
                                    Atualizar
                                </ButtonShowCase>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="cardFooter">
                                <ButtonShowCase
                                    activeButton={sendShowCase}
                                    disabled={!sendShowCase}
                                    onClick={createDinamicSlider}
                                >
                                    Adicionar
                                </ButtonShowCase>
                            </div>
                        </>
                    )}
                </div>
                <h3>Lista de produtos</h3>
                <div className="cardProducts">
                    {itens &&
                        itens.map((product: Product) => (
                            <ShowCaseCardProduct
                                key={product.id}
                                activeProduct={showCaseProducts.includes(
                                    product.id
                                )}
                                className="tw-shadow-md"
                                onClick={() =>
                                    handleSelectedProduct(product.id)
                                }
                            >
                                <p className="tw-truncate">{product.title}</p>
                                <span>{`${
                                    product.active ? '(Ativo)' : '(Inativo)'
                                } (${product.stock})`}</span>
                            </ShowCaseCardProduct>
                        ))}
                </div>
            </ShowCaseCard>
        </ShowCaseContainer>
    )
}
