import React from 'react';
import AdmHome from '../../components/AdmHomePage/AdmHome';
import Layout from '../../components/Layout/Layout';

const AdmHomePage = () => {
	return (
		<Layout>
			<AdmHome />
		</Layout>
	);
};

export default AdmHomePage;
