import React, {
    ChangeEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    SnackBar,
    BtnConfirm,
    Categorys,
    Container,
    DeliveryInformations,
    DescriptionContainer,
    ImagesContainer,
    InputOrganizer,
    InputOrganizerBrand,
    InputOrganizerSize,
    PageContainer,
    Plan,
    PlanTitleContainer,
    PricePayment,
    PriceTitleContainer,
    Title,
    TitleCategory,
    TitleInputContainer,
    ChoiceContainer,
    SubTitleInputContainer,
    InputLocationContainer,
    InputPropertyInformationsContainer,
} from './Style'

import Radio from '@material-ui/core/Radio'
import {
    ProductCategories,
    ProfileAddress,
    useCreateProductImageMutation,
    useCreateProductMutation,
    useGetAllProductCategoriesQuery,
    useGetAllSubCategoriesByCategoryLazyQuery,
    useGetAllVariationProductQuery,
    useGetUserAccountQuery,
    useValidateConectionQuery,
    VariationProduct,
} from '../../generated/graphql'
import { FieldError, useForm } from 'react-hook-form'
import add from '../../assets/icons/add.svg'
import IUserAccount from '../../models/IUserAccount'
import { useSelector } from 'react-redux'
import { AppState } from '../../store'
import { NavLink, Route, useHistory, useParams } from 'react-router-dom'
import WarningModal, {
    ModalBankAccountHandles,
} from '../NoBankAccountWarningModal/WarningModal'
import { ModalHandles } from '../AlertModal/AlertModal'
import RegisterFormHeader from './RegisterFormHeader/RegisterFormHeader'
import { ModalEditImageHandles } from './RegisterFormHeader/ImageEditModal/ImageEditModal'
import { ModalMelhorEnvioWarningHandles } from '../UserProfile/MelhorEnvioWarningModal/MelhorEnvioWarningModal'
import { handleMoneyValues } from '../../utils/HandleMoneyValues'
import { ReactComponent as Help } from '../../assets/icons/help.svg'
import { ReactComponent as CloseBlack } from '../../assets/icons/closeBlack.svg'
import HelpPopUp from './HelpPopUp/HelpPopUp'
import { ReactComponent as ReportProblem } from '../../assets/icons/reportProblem.svg'
import { ReactComponent as Exit } from '../../assets/icons/exit.svg'
import NoAddressWarningModal, {
    ModalAddressHandles,
} from '../NoAddressWarningModal/NoAddressWarningModal'
import { CompactPicker } from 'react-color'
import { IoMdRemoveCircle, IoIosAddCircleOutline } from 'react-icons/io'
import { handleLogout } from '../../handlers/handleLogout'

import appendFiles, {
    arrayLengthLimit,
    invalidPublicationImageTypeMessage,
    maxFileSizeMessage,
    maxPublicationImageLimitMessage,
} from '../../utils/appendFiles'
import { toast, ToastContainer } from 'react-toastify'
import { cepMaskFunc } from '../../utils/Masks'
import cep from 'cep-promise'
import { useQuill } from 'react-quilljs'
import 'quill/dist/quill.snow.css'

type Variacao = {
    cor?: string
    tamanho?: string
    quantidade?: number
}

export type formProps = {
    title: string
    subtitle: string
    description: string
    brand: string
    height?: string
    width?: string
    thickness?: string
    material?: string
    initialValue?: string
    directValue?: string
    finalOffersTime?: string
    price?: string
    stock?: string
    maxInstallments: string
    contract: number
    withdraw: string
    format: string
    weight: string
    model: string
    year: string
    motorPower: string
    mileage: string
    fuel: string
    licensePlate: string
    color: string
    doorsNumber: string
    directionType: string
    productHeight: string
    productWidth: string
    productLength: string
    productWeight: string
    exchange: string
    cep: string
    street: string
    neighborhood: string
    state: string
    city: string
    complement: string
    numberDoors: string
    number: string
    propertyType: string
    numberRooms: string
    bathrooms: string
    garage: string
    addressId: number
    errors: string
    propertySize: string
    planId: string
    properyType: string
    mesesDeContrato?: number
    quitado: boolean
    valorRestantes: number
    deadline: number
    freeDelivery: boolean
}
interface RegisterProductParams {
    categorieId: string
}
interface ImagesArray {
    id: number
    name: string
    imagePath: string
}

const validInput = (error?: FieldError): string => {
    return error ? 'red' : 'black'
}

const RegisterProduct: React.FC = () => {
    const { quill, quillRef } = useQuill({
        placeholder: `
            Descreva aqui o seu produto!
            Capriche na descrição!
            E não inclua dados de contato!
            Vamos manter a plataforma justa para todos.
        `,
        modules: {
            toolbar: '#toolbar',
            clipboard: {
                matchVisual: true,
            },
        },
        formats: ['bold', 'italic', 'underline', 'strike'],
    })
    const user: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )
    const [createProduct] = useCreateProductMutation({
        onCompleted: () => {
            alert(
                'Pronto. Viu como é fácil anunciar na Sambay express? Vamos cadastrar mais um produto? Obrigado por voar conosco!'
            )
        },
    })
    const { data: variationsOutput } = useGetAllVariationProductQuery({
        variables: {
            getAllVariationProductInput: {
                limit: 5,
                offset: 0,
            },
        },
    })
    const [createProductImage] = useCreateProductImageMutation()
    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors },
    } = useForm<formProps>({
        mode: 'all',
        reValidateMode: 'onChange',
    })
    const categoryId: RegisterProductParams = useParams()
    const { data } = useGetAllProductCategoriesQuery()
    const [isNew, setIsNew] = useState(true)
    const [variations, setVariations] = useState<VariationProduct[]>([])
    const [productVariations, setProductVariations] = useState<Variacao[]>([])
    const [variacaoState, setVariacaoState] = useState<Variacao>({
        quantidade: 0,
        cor: '',
        tamanho: '',
    })
    async function adicionarVariacao(): Promise<void> {
        const { quantidade, cor, tamanho } = variacaoState

        if (quantidade && (cor || tamanho)) {
            setProductVariations([...productVariations, variacaoState])
            setVariacaoState({ quantidade: 0, cor: '', tamanho: '' })
        }
    }

    function configurarVariacao(variacao: VariationProduct): void {
        const variacaoFindIndex = variations.indexOf(variacao)

        if (variacaoFindIndex !== -1) {
            setVariations(
                variations.filter(
                    (variation, index) => index !== variacaoFindIndex
                )
            )
        } else {
            setVariations([...variations, variacao])
        }
    }
    const [snackBarVisible, setSnackBarVisible] = useState(false)
    const [typePropertiesNegociation, setTypePropertiesNegociation] =
        useState('VendaImovel')
    const [typeVechileNegociation, setTypeVechileNegociation] =
        useState('VendaVeiculo')
    const [isVehiclePaidOff, setIsVehiclePaidOff] = useState<boolean>(true)
    const [isPropertyPaidOff, setIsPropertyPaidOff] = useState<boolean>(true)
    const [isBargainEnabled, setIsBargainEnabled] = useState<boolean>(true)
    const [hasVariations, setHasVariations] = useState<boolean>(false)
    const [promptDelivery, setPromptDelivery] = useState(true)
    const [hasFreeDelivery, setHasFreeDelivery] = useState(false)
    const [imagesRequirements, setImagesRequirements] = useState<string[]>([
        `Adicione 3 a 5 fotos ao menos, para
        aumentar suas chances de venda!`,
        maxFileSizeMessage,
        maxPublicationImageLimitMessage,
        invalidPublicationImageTypeMessage,
    ])
    const [freePlan, setFreePlan] = useState<string>('active')
    const { data: userConection } = useValidateConectionQuery({
        variables: { userId: user.id },
    })
    const history = useHistory()
    const state = history.location.state as { typeNegociation: string }
    const modalRef = useRef<ModalBankAccountHandles>(null)
    const modalRefWarningAddress = useRef<ModalAddressHandles>(null)
    const editImageModalRef = useRef<ModalEditImageHandles>(null)
    const modalAlertRef = useRef<ModalHandles>(null)
    const [typeNegociation, setTypeNegociation] = useState<string>('')
    const melhorEnvioWarningModal = useRef<ModalMelhorEnvioWarningHandles>(null)
    const installments = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    const [selectedSubCategory, setSelectedSubCategory] = useState<number>(0)
    const [filterCategories, setFilterCategories] = useState<
        ProductCategories[]
    >([])
    const [getSubCategories, { data: dataSubCategories }] =
        useGetAllSubCategoriesByCategoryLazyQuery({ fetchPolicy: 'no-cache' })
    const [valueMaskConst, setValueMask] = useState<any>('')
    const [bargainMask, setBargainMask] = useState<string | undefined>('')
    const OpenModal = useCallback(
        (status: boolean, message: string, productId?: number) => {
            if (!status) {
                modalAlertRef.current?.handleOpenModal()
            } else {
                modalAlertRef.current?.handleOpenModal()
                history.push(`/product-view/${productId}/all-data`)
            }
        },
        [history]
    )
    const [categorySearchQuery, setCategorySearchQuery] = useState<string>('')
    const [imgs, setImgs] = useState<any>([])
    const [planId, setPlanId] = useState<number>(1)
    const [adressId, setAdressId] = useState<number>()
    const [cepMask, setCepMask] = useState<string>('')
    const [fullCep, setFullCep] = useState<any>('')
    const { data: useGetAccount } = useGetUserAccountQuery({
        variables: { userAccountId: user.id.toString() },
    })

    const handleOpenModal = useCallback(() => {
        modalRef.current?.OpenModal()
    }, [])

    const handleOpenModalAddressWarning = useCallback(() => {
        modalRefWarningAddress.current?.OpenModal()
    }, [])

    if (!user.melhorEnvioToken && user.bankAccountId) {
        melhorEnvioWarningModal.current?.handleOpenModal()
    }

    const deleteImage = useCallback(
        (image: ImagesArray) => {
            setImgs(imgs.filter((img: any) => img.name !== image.name))
        },
        [imgs]
    )

    const deleteVariacao = useCallback(
        (indexVariacao: number) => {
            setProductVariations(
                productVariations?.filter(
                    (variacao, index) => index !== indexVariacao
                )
            )
        },
        [productVariations]
    )

    const activePlan = (plan: string): void => {
        switch (history.location.pathname) {
            case `/register-product/${categoryId.categorieId}/product`:
                if (plan === 'free') {
                    setPlanId(1)
                    setFreePlan('active')
                } else {
                    setPlanId(3)
                    setFreePlan('')
                }
                break
            case `/register-product/${categoryId.categorieId}/vehicles`:
                if (plan === 'free') {
                    setPlanId(2)
                    setFreePlan('active')
                } else {
                    setPlanId(4)
                    setFreePlan('')
                }
                break
            case `/register-product/${categoryId.categorieId}/properties`:
                if (plan === 'free') {
                    setPlanId(2)
                    setFreePlan('active')
                } else {
                    setPlanId(5)
                    setFreePlan('')
                }
                break
            default:
                break
        }
    }
    const addImage = (fileList: FileList): void => {
        const { list, errors } = appendFiles(fileList, imgs)
        for (const error of errors) {
            toast.error(error.message)
        }
        setImgs(list)
    }

    function exibirErro(error: FieldError): JSX.Element | undefined {
        if (error && error.type === 'maxLength')
            return <span>Limite de caracteres ultrapassado</span>
        if (error && error.type === 'required')
            return <span>Campo obrigatório</span>
    }

    function calcularQuantidadeProduto(): number {
        let totalProdutos = 0

        for (const variprod of productVariations) {
            if (variprod.quantidade) {
                totalProdutos += variprod.quantidade
            }
        }
        return totalProdutos
    }

    useEffect(() => {
        const { length } = imgs
        length === arrayLengthLimit - 1
            ? setImagesRequirements([
                  'Você tem somente mais uma imagem disponível para adicionar no anúncio',
                  ...imagesRequirements,
              ])
            : setImagesRequirements([
                  `Adicione 3 a 5 fotos ao menos, para aumentar suas chances de venda!`,
                  maxFileSizeMessage,
                  maxPublicationImageLimitMessage,
                  invalidPublicationImageTypeMessage,
              ])
    }, [imgs])

    useEffect(() => {
        function validarValorMinimo(): void {
            if (valueMaskConst && bargainMask) {
                const valorMinimo = Number(bargainMask.replace(/\D/g, ''))
                const valorProduto = Number(valueMaskConst.replace(/\D/g, ''))
                if (valorMinimo > valorProduto) {
                    setBargainMask('')
                }
            }
        }
        validarValorMinimo()
    }, [valueMaskConst, bargainMask])

    useEffect(() => {
        if (data && data.getAllProductCategories) {
            const categoriasFiltradas = data.getAllProductCategories.filter(
                ({ id }) => id !== 8 && id !== 7
            )
            setFilterCategories(categoriasFiltradas)
        }
    }, [data])

    useEffect(() => {
        if (data && data.getAllProductCategories) {
            getSubCategories({
                variables: {
                    categoryId: 8,
                },
            })
        }
    }, [data])
    useEffect(() => {
        if (
            data &&
            data.getAllProductCategories &&
            typeNegociation === 'vehicles'
        ) {
            getSubCategories({
                variables: {
                    categoryId: 7,
                },
            })
        }
    }, [data])

    useEffect(() => {
        if (state && state.typeNegociation) {
            setTypeNegociation(state.typeNegociation)
        }
    }, [state])

    useEffect(() => {
        if (!isBargainEnabled) {
            setValue('initialValue', valueMaskConst)
        }
    }, [isBargainEnabled, setValue, valueMaskConst])

    useEffect(() => {
        getSubCategories({
            variables: {
                categoryId: Number.parseInt(categoryId.categorieId),
            },
        })
    }, [categoryId, getSubCategories])

    useEffect(() => {
        if (
            useGetAccount?.getUserAccount &&
            !useGetAccount.getUserAccount.bankAccountId
        ) {
            handleOpenModal()
        }
    }, [handleOpenModal, useGetAccount])

    useEffect(() => {
        if (user.ProfileAddress?.length === 0) {
            handleOpenModalAddressWarning()
        }
    }, [handleOpenModalAddressWarning, user])

    useEffect(() => {
        if (!userConection) {
            const show = (): void => setSnackBarVisible(true)

            setTimeout(show, 1002)
        }
    }, [userConection])

    const handleChange = (e: any, inputName: any): void => {
        const value = e
        setValue(inputName, value)
    }

    const allCep = async (acep: string): Promise<void> => {
        try {
            await cep(acep).then((result: any) => setFullCep(result))
        } catch {}
    }

    useEffect(() => {
        setValue('city', fullCep.city)
        setValue('street', fullCep.street)
        setValue('state', fullCep.state)
        setValue('neighborhood', fullCep.neighborhood)
    }, [fullCep])

    const onSubmit = async (formProps: formProps): Promise<void> => {
        let Product: any
        try {
            switch (history.location.pathname) {
                case `/register-product/${categoryId.categorieId}/product`:
                    Product = await createProduct({
                        variables: {
                            title: formProps.title,
                            typeNegotiation: isBargainEnabled
                                ? 'Promoção'
                                : 'Venda',
                            isSocialProject: user.userTypeId === 5,
                            subtitle: formProps.subtitle,
                            numberOfPhotos: imgs.length,
                            description: formProps.description,
                            brand: formProps.brand,
                            height: Number.parseFloat(formProps.height!),
                            width: Number.parseFloat(formProps.width!),
                            thickness: Number.parseFloat(
                                formProps.thickness! as string
                            ),
                            useState: isNew ? 'Novo' : 'Usado',
                            material: formProps.material,
                            categorieId: Number.parseInt(
                                categoryId.categorieId
                            ),
                            quantity: 1,
                            subCategorieId:
                                selectedSubCategory === 0
                                    ? undefined
                                    : selectedSubCategory,
                            price:
                                formProps.price !== undefined
                                    ? Number.parseFloat(
                                          formProps.price
                                              .replace('.', '')
                                              .replace(',', '.')
                                      )
                                    : Number.parseInt(formProps.price!),
                            maxInstallments: Number.parseFloat(
                                formProps.maxInstallments!.toString()
                            ),
                            stock:
                                Number.parseInt(formProps.stock!) |
                                calcularQuantidadeProduto(),
                            amount: Number.parseInt(formProps.initialValue!),
                            userAccountId: user.id,
                            shippingWay: 'correio',
                            planId: planId,
                            directValue:
                                formProps.directValue !== undefined
                                    ? Number.parseFloat(
                                          formProps.directValue
                                              .replace('.', '')
                                              .replace(',', '.')
                                      )
                                    : Number.parseInt(formProps.directValue!),
                            finalOffersTime: formProps.finalOffersTime,
                            InitialValue: Number(
                                formProps
                                    .initialValue!.replace('.', '')
                                    .replace(',', '.')
                            ),
                            propertyInformation: false,
                            vehicleInformation: false,
                            format: formProps.format,
                            weight: formProps.weight,
                            withdraw: formProps.withdraw,
                            profileAddressId: adressId,
                            product_height: Number.parseFloat(
                                formProps.productHeight
                            ),
                            product_width: Number.parseFloat(
                                formProps.productWidth
                            ),
                            product_length: Number.parseFloat(
                                formProps.productLength
                            ),
                            product_weight: Number.parseFloat(
                                formProps.productWeight
                            ),
                            prompt_delivery: promptDelivery!,
                            freeDelivery: hasFreeDelivery!,
                            variacoes: productVariations,
                            deadline: Number(formProps.deadline),
                        },
                    })
                    break

                case `/register-product/${8}/properties`:
                    Product = await createProduct({
                        variables: {
                            title: formProps.title,
                            subtitle: formProps.subtitle,
                            description: formProps.description,
                            categorieId: 8,
                            typeNegotiation: String('Imoveis'),
                            propertyInformation: true,
                            vehicleInformation: false,
                            isSocialProject: false,
                            numberOfPhotos: imgs.length,
                            subCategorieId:
                                selectedSubCategory === 0
                                    ? undefined
                                    : selectedSubCategory,
                            InitialValue: Number(
                                formProps
                                    .initialValue!.replace('.', '')
                                    .replace(',', '.')
                            ),
                            price:
                                formProps.price !== undefined
                                    ? Number.parseFloat(
                                          formProps.price
                                              .replace('.', '')
                                              .replace(',', '.')
                                      )
                                    : Number.parseInt(formProps.price!),
                            useState: isNew ? 'Novo' : 'Usado',
                            material: formProps.material,
                            quantity: 1,
                            maxInstallments: Number.parseInt(
                                formProps.maxInstallments
                            ),
                            stock: 1,
                            userAccountId: user.id,
                            directValue:
                                formProps.directValue !== undefined
                                    ? Number.parseFloat(
                                          formProps.directValue
                                              .replace('.', '')
                                              .replace(',', '.')
                                      )
                                    : Number.parseInt(formProps.directValue!),
                            cep: formProps?.cep,
                            neighborhood: formProps?.neighborhood,
                            city: formProps?.city,
                            state: formProps?.state,
                            complement: formProps?.complement,
                            garage: formProps?.garage,
                            shippingWay: 'correio',
                            street: formProps?.street,
                            number: formProps?.number,
                            mesesDeContrato: Number(formProps?.mesesDeContrato),
                            valorRestantes: Number(formProps?.valorRestantes),
                            bathrooms: formProps?.bathrooms,
                            rooms: formProps?.numberRooms,
                            quitado: isPropertyPaidOff,
                        },
                    })
                    break

                case `/register-product/${7}/vehicles`:
                    Product = await createProduct({
                        variables: {
                            title: formProps.title,
                            subtitle: formProps.subtitle,
                            description: formProps.description,
                            brand: formProps.brand,
                            categorieId: 7,
                            typeNegotiation: String('Veiculos'),
                            propertyInformation: false,
                            vehicleInformation: true,
                            isSocialProject: false,
                            numberOfPhotos: imgs.length,
                            subCategorieId:
                                selectedSubCategory === 0
                                    ? undefined
                                    : selectedSubCategory,

                            InitialValue: Number(
                                formProps
                                    .initialValue!.replace('.', '')
                                    .replace(',', '.')
                            ),
                            price:
                                formProps.price !== undefined
                                    ? Number.parseFloat(
                                          formProps.price
                                              .replace('.', '')
                                              .replace(',', '.')
                                      )
                                    : Number.parseInt(formProps.price!),
                            useState: isNew ? 'Novo' : 'Usado',
                            material: formProps.material,
                            quantity: 1,
                            maxInstallments: Number.parseInt(
                                formProps.maxInstallments
                            ),
                            stock: 1,
                            userAccountId: user.id,
                            directValue:
                                formProps.directValue !== undefined
                                    ? Number.parseFloat(
                                          formProps.directValue
                                              .replace('.', '')
                                              .replace(',', '.')
                                      )
                                    : Number.parseInt(formProps.directValue!),
                            year: formProps?.year,
                            motorPower: formProps?.motorPower,
                            fuel: formProps?.fuel,
                            licensePlate: formProps?.licensePlate,
                            exchange: formProps?.exchange,
                            directionType: formProps?.directionType,
                            color: formProps?.color,
                            mileage: formProps?.mileage,
                            vehicleBrand: formProps?.brand,
                            numberDoors: formProps?.numberDoors,
                            model: formProps?.model,
                            shippingWay: 'correio',
                            mesesDeContrato: Number(formProps?.mesesDeContrato),
                            valorRestantes: Number(formProps?.valorRestantes),
                            quitado: isVehiclePaidOff,
                        },
                    })
                    break
                default:
                    break
            }

            if (imgs.length > 0 && Product.data?.createProduct) {
                imgs.map(async (img: any, index: any) => {
                    if (img) {
                        await createProductImage({
                            variables: {
                                ProductId: Product.data!.createProduct.id,
                                PictureNumber: index + 1,
                                Picture: img,
                            },
                        })
                    }
                })
            } else {
                OpenModal(false, 'Insira ao menos 4 imagens')
            }

            OpenModal(true, '', Product.data!.createProduct.id)
        } catch (error: any) {
            if (error.message === 'not authenticated') handleLogout(true)
            console.log('ErrorCadastrar', error)
            OpenModal(false, 'Falha ao cadastrar produto')
        }
    }

    useEffect(() => {
        if (quill) {
            quill.on('text-change', () => {
                register('description', {
                    value: quill.root.innerHTML,
                    maxLength: 4000,
                })
            })
        }
    }, [quill, register])

    useEffect(() => {
        if (data) {
            setFilterCategories(
                data.getAllProductCategories.filter((category) => {
                    const query = categorySearchQuery.toLowerCase()

                    return (
                        category.name.toLowerCase().includes(query) ||
                        category.id === 39
                    )
                })
            )
        }
    }, [categorySearchQuery, data])

    return (
        <>
            <ToastContainer />
            {!userConection?.validateConection && snackBarVisible && (
                <SnackBar>
                    <ReportProblem className="icon" fill="#FFC000" />
                    <div className="Alignment">
                        <h3>
                            Por favor, conecte-se a uma opção de envio para
                            facilitar o envio dos seus produtos aos seus
                            clientes.{' '}
                        </h3>

                        <span>
                            <button>
                                {' '}
                                <a
                                    href={`${process.env.REACT_APP_MELHOR_ENVIO_REDIRECT_URI}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Cadastrar no Melhor Envio
                                </a>
                            </button>
                            <button>
                                <a
                                    href={`${process.env.REACT_APP_MELHOR_ENVIO_REDIRECT_URI}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Cadastrar no bling!
                                </a>
                            </button>
                        </span>
                    </div>
                    <div className="CloseContainer">
                        <CloseBlack
                            className="CloseIcon"
                            onClick={() => setSnackBarVisible(false)}
                        />
                    </div>
                </SnackBar>
            )}
            <HelpPopUp />
            <RegisterFormHeader />
            <WarningModal ref={modalRef} />
            <NoAddressWarningModal ref={modalRefWarningAddress} />
            <PageContainer>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="form-style"
                    autoComplete="off"
                >
                    <Route path={`/register-product/:categoryId/product`}>
                        <PlanTitleContainer>
                            <h2>Plano de anúncio</h2>
                            <Plan>
                                <div className="img-container">
                                    <span
                                        onClick={() => activePlan('free')}
                                        className={freePlan}
                                    >
                                        <h1>LIVRE*</h1>
                                        <p className="type">GRATUITO / COMUM</p>
                                        <h3 className="condition">Condições</h3>
                                        <p className="description">
                                            Anúncio grátis, com duração
                                            indeterminada e possibilidade de
                                            subir até 12 (doze) fotos. Poder de
                                            visibilidade comum no portal.{' '}
                                            <h5>
                                                <strong>
                                                    *Taxas de 9% sobre cada
                                                    venda realizada pela
                                                    plataforma exceto imóveis e
                                                    veículos. Promoção sujeita a
                                                    alterações sem prévio aviso.
                                                    Entre em contato em caso de
                                                    dúvidas.
                                                </strong>
                                            </h5>
                                        </p>
                                    </span>
                                </div>
                            </Plan>
                        </PlanTitleContainer>
                    </Route>

                    <Route path={`/register-product/${8}/properties`}>
                        <PlanTitleContainer>
                            <h2>Plano de anúncio</h2>
                            <Plan>
                                <div className="img-container">
                                    <span
                                        onClick={() => activePlan('free')}
                                        className={freePlan}
                                    >
                                        <h1>LIVRE*</h1>
                                        <p className="type">GRATUITO / COMUM</p>
                                        <h3 className="condition">Condições</h3>
                                        <p className="description">
                                            Anúncio grátis, com duração
                                            indeterminada e possibilidade de
                                            subir até 12 (doze) fotos. Poder de
                                            visibilidade comum no portal.{' '}
                                            <h5>
                                                <strong>
                                                    *Taxas de 9% sobre cada
                                                    venda realizada pela
                                                    plataforma exceto imóveis e
                                                    veículos. Promoção sujeita a
                                                    alterações sem prévio aviso.
                                                    Entre em contato em caso de
                                                    dúvidas.
                                                </strong>
                                            </h5>
                                        </p>
                                    </span>
                                </div>
                            </Plan>
                        </PlanTitleContainer>
                    </Route>

                    <Route path={`/register-product/${7}/vehicles`}>
                        <PlanTitleContainer>
                            <h2>Plano de anúncio</h2>
                            <Plan>
                                <div className="img-container">
                                    <span
                                        onClick={() => activePlan('free')}
                                        className={freePlan}
                                    >
                                        <h1>LIVRE*</h1>
                                        <p className="type">GRATUITO / COMUM</p>
                                        <h3 className="condition">Condições</h3>
                                        <p className="description">
                                            Anúncio grátis, com duração
                                            indeterminada e possibilidade de
                                            subir até 12 (doze) fotos. Poder de
                                            visibilidade comum no portal.{' '}
                                            <h5>
                                                <strong>
                                                    *Taxas de 9% sobre cada
                                                    venda realizada pela
                                                    plataforma exceto imóveis e
                                                    veículos. Promoção sujeita a
                                                    alterações sem prévio aviso.
                                                    Entre em contato em caso de
                                                    dúvidas.
                                                </strong>
                                            </h5>
                                        </p>
                                    </span>
                                </div>
                            </Plan>
                        </PlanTitleContainer>
                    </Route>

                    <Title>
                        <h2>Dados gerais</h2>
                        <Container>
                            <ChoiceContainer>
                                <label>Condição *</label>
                                <div className="choice-buttons-container">
                                    <div
                                        onClick={() => setIsNew(true)}
                                        className={
                                            isNew
                                                ? 'button-active-left'
                                                : undefined
                                        }
                                    >
                                        Novo
                                    </div>
                                    <div
                                        onClick={() => setIsNew(false)}
                                        className={
                                            !isNew
                                                ? 'button-active-right'
                                                : undefined
                                        }
                                    >
                                        Usado
                                    </div>
                                </div>
                            </ChoiceContainer>

                            <TitleInputContainer
                                borderColor={validInput(errors.title)}
                            >
                                <label>Título *</label>
                                <input
                                    type="text"
                                    {...register('title', {
                                        required: true,
                                        maxLength: 240,
                                    })}
                                />
                                {errors.title && exibirErro(errors.title)}
                            </TitleInputContainer>

                            <div className="div">
                                <SubTitleInputContainer
                                    borderColor={validInput(errors.subtitle)}
                                >
                                    <label>Subtítulo *</label>
                                    <input
                                        type="text"
                                        {...register('subtitle', {
                                            required: true,
                                            maxLength: 320,
                                        })}
                                    />
                                    {errors.subtitle &&
                                        exibirErro(errors.subtitle)}
                                </SubTitleInputContainer>
                            </div>

                            <DescriptionContainer
                                borderColor={validInput(errors.description)}
                            >
                                <div className="tw-flex tw-flex-row tw-items-start tw-justify-between">
                                    <label>
                                        Descrição (formatação disponível) *
                                    </label>
                                    <div id="toolbar">
                                        <button className="ql-bold" />
                                        <button className="ql-italic" />
                                        <button className="ql-underline" />
                                        <button className="ql-strike" />
                                    </div>
                                </div>
                                <div
                                    ref={quillRef}
                                    className="tw-h-2/3 tw-font-primary"
                                />
                                {errors.description &&
                                    exibirErro(errors.description)}
                            </DescriptionContainer>

                            <div className="other-container tw-mt-8">
                                <Route
                                    path={`/register-product/:categoryId/product`}
                                >
                                    <InputOrganizerBrand
                                        borderColor={validInput(errors.brand)}
                                    >
                                        <label>Marca *</label>
                                        <input
                                            type="text"
                                            {...register('brand', {
                                                required:
                                                    history.location
                                                        .pathname ===
                                                    `/register-product/${categoryId.categorieId}/product`,
                                            })}
                                        />
                                        {errors.brand &&
                                            exibirErro(errors.brand)}
                                    </InputOrganizerBrand>
                                </Route>
                                <Route
                                    path={`/register-product/:categoryId/vehicles`}
                                >
                                    <InputOrganizerBrand
                                        borderColor={validInput(errors.brand)}
                                    >
                                        <label>Marca *</label>
                                        <input
                                            type="text"
                                            {...register('brand', {
                                                required:
                                                    history.location
                                                        .pathname ===
                                                    `/register-product/${categoryId.categorieId}/vehicles`,
                                            })}
                                        />
                                        {errors.brand &&
                                            exibirErro(errors.brand)}
                                    </InputOrganizerBrand>

                                    <InputOrganizerBrand
                                        style={{ marginBottom: '20px' }}
                                        borderColor={validInput(errors.model)}
                                    >
                                        <label>Modelo *</label>
                                        <input
                                            type="text"
                                            {...register('model', {
                                                required:
                                                    history.location
                                                        .pathname ===
                                                    `/register-product/${categoryId.categorieId}/vehicles`,
                                            })}
                                        />
                                        {errors.model &&
                                            exibirErro(errors.model)}
                                    </InputOrganizerBrand>

                                    <InputOrganizerSize
                                        borderColor={validInput(errors.year)}
                                    >
                                        <label>Ano *</label>
                                        <input
                                            type="number"
                                            {...register('year', {
                                                required:
                                                    history.location
                                                        .pathname ===
                                                    `/register-product/${categoryId.categorieId}/vehicles`,
                                            })}
                                        />
                                        {errors.year && exibirErro(errors.year)}
                                    </InputOrganizerSize>
                                </Route>

                                <Route
                                    path={`/register-product/:categoryId/product`}
                                >
                                    <InputOrganizerBrand
                                        borderColor={validInput(
                                            errors.material
                                        )}
                                    >
                                        <label>Material *</label>
                                        <input
                                            type="text"
                                            {...register('material', {
                                                required:
                                                    history.location
                                                        .pathname ===
                                                    `/register-product/${categoryId.categorieId}/product`,
                                            })}
                                        />
                                        {errors.material &&
                                            exibirErro(errors.material)}
                                    </InputOrganizerBrand>
                                </Route>
                            </div>
                            <Route
                                path={`/register-product/:categoryId/product`}
                            >
                                <DeliveryInformations
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        paddingTop: '24px',
                                    }}
                                >
                                    <h1>
                                        Insira abaixo as dimensões do produto
                                        montado e em uso
                                    </h1>
                                    <span>
                                        *Fique atento(a) as unidades de medida
                                        pedidas no formulário
                                    </span>
                                    <div className="other-container">
                                        <InputOrganizerSize
                                            borderColor={validInput(
                                                errors.productHeight
                                            )}
                                        >
                                            <label>
                                                Altura em centímetros*
                                            </label>
                                            <input
                                                type="number"
                                                step="0.001"
                                                placeholder="Ex: 50cm"
                                                {...register('productHeight', {
                                                    required:
                                                        history.location
                                                            .pathname ===
                                                        `/register-product/${categoryId.categorieId}/product`,
                                                })}
                                            />
                                            {errors.productHeight &&
                                                exibirErro(
                                                    errors.productHeight
                                                )}
                                        </InputOrganizerSize>
                                        <InputOrganizerSize
                                            borderColor={validInput(
                                                errors.productWidth
                                            )}
                                        >
                                            <label>
                                                Largura em centímetros*
                                            </label>
                                            <input
                                                type="number"
                                                step="0.001"
                                                placeholder="Ex: 60cm"
                                                {...register('productWidth', {
                                                    required:
                                                        history.location
                                                            .pathname ===
                                                        `/register-product/${categoryId.categorieId}/product`,
                                                })}
                                            />
                                            {errors.productWidth &&
                                                exibirErro(errors.productWidth)}
                                        </InputOrganizerSize>
                                        <InputOrganizerSize
                                            borderColor={validInput(
                                                errors.productLength
                                            )}
                                        >
                                            <label>
                                                Comprimento em centímetros*
                                            </label>
                                            <input
                                                type="number"
                                                step="0.001"
                                                placeholder="Ex: 80cm"
                                                {...register('productLength', {
                                                    required:
                                                        history.location
                                                            .pathname ===
                                                        `/register-product/${categoryId.categorieId}/product`,
                                                })}
                                            />
                                            {errors.productLength &&
                                                exibirErro(
                                                    errors.productLength
                                                )}
                                        </InputOrganizerSize>
                                        <InputOrganizerSize
                                            borderColor={validInput(
                                                errors.productWeight
                                            )}
                                        >
                                            <label>Peso em kilogramas*</label>
                                            <input
                                                type="number"
                                                step="0.001"
                                                placeholder="Ex: 5kg"
                                                {...register('productWeight', {
                                                    required:
                                                        history.location
                                                            .pathname ===
                                                        `/register-product/${categoryId.categorieId}/product`,
                                                })}
                                            />
                                            {errors.productWeight &&
                                                exibirErro(
                                                    errors.productWeight
                                                )}
                                        </InputOrganizerSize>
                                    </div>
                                </DeliveryInformations>
                            </Route>
                            <Route
                                path={`/register-product/:categoryId/product`}
                            >
                                <DeliveryInformations
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <h1 className="tw-my-0">
                                        Insira abaixo as dimensões do produto
                                        embalado para o envio
                                    </h1>
                                    <span>
                                        *Fique atento(a) as unidades de medida
                                        pedidas no formulário
                                    </span>
                                    <span className="tw-text-black-500 tw-py-2">
                                        *No caso de envelopes informar altura
                                        zero (0)
                                    </span>
                                    <InputOrganizerSize
                                        borderColor={validInput(errors.format)}
                                    >
                                        <label>Formato *</label>
                                        <select
                                            placeholder="selecione"
                                            {...register('format', {
                                                required:
                                                    history.location
                                                        .pathname ===
                                                    `/register-product/${categoryId.categorieId}/product`,
                                            })}
                                        >
                                            <option value="" disabled selected>
                                                --Selecione--
                                            </option>
                                            <option value="1">
                                                Caixa/Pacote
                                            </option>
                                            <option value="2">
                                                Rolo/Prisma
                                            </option>
                                            <option value="3">Envelope</option>
                                        </select>
                                        {errors.format &&
                                            exibirErro(errors.format)}
                                    </InputOrganizerSize>
                                    <div className="other-container">
                                        <InputOrganizerSize
                                            borderColor={validInput(
                                                errors.height
                                            )}
                                        >
                                            <label>
                                                Altura em centímetros*
                                            </label>
                                            <input
                                                type="number"
                                                step="0.001"
                                                placeholder="Ex: 50cm"
                                                {...register('height', {
                                                    required:
                                                        history.location
                                                            .pathname ===
                                                        `/register-product/${categoryId.categorieId}/product`,
                                                })}
                                            />
                                            {errors.height &&
                                                exibirErro(errors.height)}
                                        </InputOrganizerSize>
                                        <InputOrganizerSize
                                            borderColor={validInput(
                                                errors.width
                                            )}
                                        >
                                            <label>
                                                Largura em centímetros*
                                            </label>
                                            <input
                                                type="number"
                                                step="0.001"
                                                placeholder="Ex: 60cm"
                                                {...register('width', {
                                                    required:
                                                        history.location
                                                            .pathname ===
                                                        `/register-product/${categoryId.categorieId}/product`,
                                                })}
                                            />
                                            {errors.width &&
                                                exibirErro(errors.width)}
                                        </InputOrganizerSize>
                                        <InputOrganizerSize
                                            borderColor={validInput(
                                                errors.thickness
                                            )}
                                        >
                                            <label>
                                                Comprimento em centímetros*
                                            </label>
                                            <input
                                                type="number"
                                                step="0.001"
                                                placeholder="Ex: 80cm"
                                                {...register('thickness', {
                                                    required:
                                                        history.location
                                                            .pathname ===
                                                        `/register-product/${categoryId.categorieId}/product`,
                                                })}
                                            />
                                            {errors.thickness &&
                                                exibirErro(errors.thickness)}
                                        </InputOrganizerSize>
                                        <InputOrganizerSize
                                            borderColor={validInput(
                                                errors.weight
                                            )}
                                        >
                                            <label>Peso em kilogramas*</label>
                                            <input
                                                type="number"
                                                step="0.001"
                                                placeholder="Ex: 5kg"
                                                {...register('weight', {
                                                    required:
                                                        history.location
                                                            .pathname ===
                                                        `/register-product/${categoryId.categorieId}/product`,
                                                })}
                                            />
                                            {errors.weight &&
                                                exibirErro(errors.weight)}
                                        </InputOrganizerSize>
                                    </div>
                                    <h1 className="tw-my-0 tw-pb-5">
                                        Insira abaixo as opções de retirada com
                                        o vendedor(a), e o tipo da embalagem do
                                        produto:
                                    </h1>
                                    <div className="other-container">
                                        <InputOrganizerSize
                                            borderColor={validInput(
                                                errors.withdraw
                                            )}
                                        >
                                            <label>
                                                Opção de retirada com vendedor *
                                            </label>
                                            <select
                                                {...register('withdraw', {
                                                    required:
                                                        history.location
                                                            .pathname ===
                                                        `/register-product/${categoryId.categorieId}/product`,
                                                })}
                                            >
                                                <option
                                                    value=""
                                                    disabled
                                                    selected
                                                >
                                                    --Selecione--
                                                </option>
                                                <option value="Sim">Sim</option>
                                                <option
                                                    value="Não"
                                                    disabled={hasFreeDelivery}
                                                >
                                                    {hasFreeDelivery
                                                        ? 'Não (indisponível com a opção de frete grátis)'
                                                        : 'Não'}
                                                </option>
                                                <option value="exclusivamente">
                                                    Exclusivamente
                                                </option>
                                            </select>
                                            {errors.withdraw &&
                                                exibirErro(errors.withdraw)}
                                        </InputOrganizerSize>
                                    </div>
                                </DeliveryInformations>
                            </Route>
                            <Route
                                path={`/register-product/:categoryId/vehicles`}
                            >
                                <div className="other-container">
                                    <InputOrganizerSize
                                        borderColor={validInput(
                                            errors.motorPower
                                        )}
                                    >
                                        <label>Potência do motor *</label>
                                        <input
                                            type="number"
                                            {...register('motorPower', {
                                                required:
                                                    history.location
                                                        .pathname ===
                                                    `/register-product/${categoryId.categorieId}/vehicles`,
                                            })}
                                        />
                                        {errors.year && exibirErro(errors.year)}
                                    </InputOrganizerSize>

                                    <InputOrganizerSize
                                        borderColor={validInput(errors.mileage)}
                                    >
                                        <label>Quilometragem *</label>
                                        <input
                                            type="number"
                                            {...register('mileage', {
                                                required:
                                                    history.location
                                                        .pathname ===
                                                    `/register-product/${categoryId.categorieId}/vehicles`,
                                            })}
                                        />
                                        {errors.mileage &&
                                            exibirErro(errors.mileage)}
                                    </InputOrganizerSize>

                                    <InputOrganizerSize
                                        borderColor={validInput(errors.fuel)}
                                    >
                                        <label>Combustível *</label>
                                        <select
                                            {...register('fuel', {
                                                required:
                                                    history.location
                                                        .pathname ===
                                                    `/register-product/${categoryId.categorieId}/vehicles`,
                                            })}
                                        >
                                            <option
                                                value="flex"
                                                disabled
                                                selected
                                            >
                                                Selecione
                                            </option>{' '}
                                            <option value="flex">Flex</option>{' '}
                                            <option value="Gasolina">
                                                Gasolina
                                            </option>
                                            <option value="Álcool">
                                                Álcool
                                            </option>
                                        </select>
                                        {errors.mileage &&
                                            exibirErro(errors.mileage)}
                                    </InputOrganizerSize>
                                </div>

                                <div
                                    className="other-container"
                                    style={{ marginTop: '20px' }}
                                >
                                    <InputOrganizerSize
                                        style={{ marginBottom: '20px' }}
                                        borderColor={validInput(
                                            errors.licensePlate
                                        )}
                                    >
                                        <label>Placa *</label>
                                        <input
                                            type="text"
                                            {...register('licensePlate', {
                                                required:
                                                    history.location
                                                        .pathname ===
                                                    `/register-product/${categoryId.categorieId}/vehicles`,
                                            })}
                                        />
                                        {errors.licensePlate &&
                                            exibirErro(errors.licensePlate)}
                                    </InputOrganizerSize>

                                    <InputOrganizerSize
                                        borderColor={validInput(errors.color)}
                                    >
                                        <label>Cor *</label>
                                        <input
                                            type="text"
                                            {...register('color', {
                                                required:
                                                    history.location
                                                        .pathname ===
                                                    `/register-product/${categoryId.categorieId}/vehicles`,
                                            })}
                                        />
                                        {errors.color &&
                                            exibirErro(errors.color)}
                                    </InputOrganizerSize>

                                    <InputOrganizerSize
                                        borderColor={validInput(
                                            errors.numberDoors
                                        )}
                                    >
                                        <label>Portas *</label>
                                        <select
                                            {...register('numberDoors', {
                                                required:
                                                    history.location
                                                        .pathname ===
                                                    `/register-product/${categoryId.categorieId}/vehicles`,
                                            })}
                                        >
                                            <option
                                                value="flex"
                                                disabled
                                                selected
                                            >
                                                ---Selecione---
                                            </option>
                                            <option value="2">2 portas</option>
                                            <option value="4">4 portas</option>
                                        </select>
                                        {errors.numberDoors &&
                                            exibirErro(errors.numberDoors)}
                                    </InputOrganizerSize>
                                </div>

                                <div className="other-container">
                                    <InputOrganizerSize
                                        borderColor={validInput(
                                            errors.directionType
                                        )}
                                    >
                                        <label>Direção *</label>
                                        <input
                                            type="text"
                                            {...register('directionType', {
                                                required:
                                                    history.location
                                                        .pathname ===
                                                    `/register-product/${categoryId.categorieId}/vehicles`,
                                            })}
                                        />
                                        {errors.directionType &&
                                            exibirErro(errors.directionType)}
                                    </InputOrganizerSize>

                                    <InputOrganizerSize
                                        borderColor={validInput(
                                            errors.exchange
                                        )}
                                    >
                                        <label>Câmbio *</label>
                                        <input
                                            type="text"
                                            {...register('exchange', {
                                                required:
                                                    history.location
                                                        .pathname ===
                                                    `/register-product/${categoryId.categorieId}/vehicles`,
                                            })}
                                        />
                                        {errors.exchange &&
                                            exibirErro(errors.exchange)}
                                    </InputOrganizerSize>
                                </div>
                            </Route>
                            <Route
                                path={`/register-product/:categoryId/properties`}
                            >
                                <InputLocationContainer>
                                    <label>Localização do Imóvel *</label>
                                    <div
                                        className="container"
                                        style={{ marginBottom: '20px' }}
                                    >
                                        <InputOrganizerSize
                                            borderColor={validInput(errors.cep)}
                                            style={{ width: '100%' }}
                                        >
                                            <label>CEP *</label>
                                            <input
                                                type="text"
                                                placeholder="xx.xxx-xxx"
                                                autoComplete="no"
                                                {...register('cep', {
                                                    required:
                                                        history.location
                                                            .pathname ===
                                                        `/register-product/${categoryId.categorieId}/properties`,
                                                    pattern: {
                                                        value: /^(\d{2})\.(\d{3})-(\d{3})$/,
                                                        message:
                                                            'Insira um CEP válido',
                                                    },
                                                })}
                                                style={{
                                                    fontStyle: 'italic',
                                                    backgroundColor: '#fff',
                                                    padding: '3px',
                                                }}
                                                id="CEP"
                                                onChange={(e) => {
                                                    setCepMask(
                                                        cepMaskFunc(
                                                            e.target.value
                                                        )
                                                    )
                                                    allCep(e.target.value)
                                                }}
                                                value={cepMask}
                                            />
                                            {errors.cep &&
                                                exibirErro(errors.cep)}
                                        </InputOrganizerSize>

                                        <InputOrganizerSize
                                            borderColor={validInput(
                                                errors.street
                                            )}
                                            style={{ width: '100%' }}
                                        >
                                            <label>Endereço *</label>
                                            <input
                                                type="text"
                                                {...register('street', {
                                                    required:
                                                        history.location
                                                            .pathname ===
                                                        `/register-product/${categoryId.categorieId}/properties`,
                                                })}
                                                onChange={(e) =>
                                                    handleChange(
                                                        e.target.value,
                                                        'street'
                                                    )
                                                }
                                            />
                                            {errors.street &&
                                                exibirErro(errors.street)}
                                        </InputOrganizerSize>

                                        <InputOrganizerSize
                                            borderColor={validInput(
                                                errors.city
                                            )}
                                            style={{ width: '100%' }}
                                        >
                                            <label>Cidade *</label>
                                            <input
                                                type="text"
                                                {...register('city', {
                                                    required:
                                                        history.location
                                                            .pathname ===
                                                        `/register-product/${categoryId.categorieId}/properties`,
                                                })}
                                                onChange={(e) =>
                                                    handleChange(
                                                        e.target.value,
                                                        'city'
                                                    )
                                                }
                                            />
                                            {errors.city &&
                                                exibirErro(errors.city)}
                                        </InputOrganizerSize>
                                    </div>
                                </InputLocationContainer>

                                <InputLocationContainer>
                                    <div
                                        className="other-container"
                                        style={{ marginBottom: '10px' }}
                                    >
                                        <InputOrganizerSize
                                            borderColor={validInput(
                                                errors.state
                                            )}
                                            style={{ width: '100%' }}
                                        >
                                            <label>Estado *</label>
                                            <input
                                                type="text"
                                                {...register('state', {
                                                    required:
                                                        history.location
                                                            .pathname ===
                                                        `/register-product/${categoryId.categorieId}/properties`,
                                                })}
                                                onChange={(e) =>
                                                    handleChange(
                                                        e.target.value,
                                                        'state'
                                                    )
                                                }
                                            />
                                            {errors.state &&
                                                exibirErro(errors.state)}
                                        </InputOrganizerSize>

                                        <InputOrganizerSize
                                            borderColor={validInput(
                                                errors.neighborhood
                                            )}
                                            style={{ width: '100%' }}
                                        >
                                            <label>Bairro *</label>
                                            <input
                                                type="text"
                                                {...register('neighborhood', {
                                                    required:
                                                        history.location
                                                            .pathname ===
                                                        `/register-product/${categoryId.categorieId}/properties`,
                                                })}
                                                onChange={(e) =>
                                                    handleChange(
                                                        e.target.value,
                                                        'neighborhood'
                                                    )
                                                }
                                            />
                                            {errors.neighborhood &&
                                                exibirErro(errors.neighborhood)}
                                        </InputOrganizerSize>

                                        <InputOrganizerSize
                                            borderColor={validInput(
                                                errors.number
                                            )}
                                            style={{ width: '100%' }}
                                        >
                                            <label>Número *</label>
                                            <input
                                                type="text"
                                                {...register('number', {
                                                    required:
                                                        history.location
                                                            .pathname ===
                                                        `/register-product/${categoryId.categorieId}/properties`,
                                                })}
                                            />
                                            {errors.number &&
                                                exibirErro(errors.number)}
                                        </InputOrganizerSize>
                                    </div>
                                </InputLocationContainer>

                                <div
                                    className="other-container"
                                    style={{
                                        marginBottom: '10px',
                                        marginTop: '10px',
                                    }}
                                >
                                    <InputOrganizerSize
                                        borderColor={validInput(
                                            errors.complement
                                        )}
                                        style={{ width: '100%' }}
                                    >
                                        <label>Complemento *</label>
                                        <input
                                            type="text"
                                            {...register('complement', {
                                                required:
                                                    history.location
                                                        .pathname ===
                                                    `/register-product/${categoryId.categorieId}/properties`,
                                            })}
                                        />
                                        {errors.number &&
                                            exibirErro(errors.number)}
                                    </InputOrganizerSize>
                                </div>

                                <InputPropertyInformationsContainer>
                                    <label>Informações do Imóvel *</label>
                                    <div className="other-container">
                                        <InputOrganizerSize
                                            borderColor={validInput(
                                                errors.numberRooms
                                            )}
                                            style={{ width: '100%' }}
                                        >
                                            <label>Quartos *</label>
                                            <input
                                                type="number"
                                                {...register('numberRooms', {
                                                    required:
                                                        history.location
                                                            .pathname ===
                                                        `/register-product/${categoryId.categorieId}/properties`,
                                                })}
                                            />
                                            {errors.numberRooms &&
                                                exibirErro(errors.numberRooms)}
                                        </InputOrganizerSize>

                                        <InputOrganizerSize
                                            borderColor={validInput(
                                                errors.bathrooms
                                            )}
                                            style={{ width: '100%' }}
                                        >
                                            <label>Banheiros *</label>
                                            <input
                                                type="number"
                                                {...register('bathrooms', {
                                                    required:
                                                        history.location
                                                            .pathname ===
                                                        `/register-product/${categoryId.categorieId}/properties`,
                                                })}
                                            />
                                            {errors.bathrooms &&
                                                exibirErro(errors.bathrooms)}
                                        </InputOrganizerSize>
                                    </div>

                                    <div
                                        className="other-container"
                                        style={{ marginTop: '20px' }}
                                    >
                                        <InputOrganizerSize
                                            borderColor={validInput(
                                                errors.garage
                                            )}
                                            style={{ width: '100%' }}
                                        >
                                            <label>Garagem *</label>
                                            <input
                                                type="number"
                                                {...register('garage', {
                                                    required:
                                                        history.location
                                                            .pathname ===
                                                        `/register-product/${categoryId.categorieId}/properties`,
                                                })}
                                            />
                                            {errors.garage &&
                                                exibirErro(errors.garage)}
                                        </InputOrganizerSize>
                                    </div>
                                </InputPropertyInformationsContainer>
                            </Route>
                        </Container>
                    </Title>
                    <Title>
                        <h2>Imagens</h2>
                        <ImagesContainer>
                            <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-py-4">
                                <span className="img-instructions">
                                    <h1>Adicione ao menos 1 foto.</h1>
                                </span>
                                <span
                                    className={`tw-rounded-sm tw-bg-primary-500 tw-py-1 tw-px-2 tw-font-primary tw-font-semibold tw-text-white`}
                                >{`${imgs.length} / ${arrayLengthLimit}`}</span>
                            </div>
                            <div className="img-container">
                                <label htmlFor="file1">
                                    <span>
                                        {' '}
                                        <img src={add} alt=" "></img>
                                        <input
                                            type="file"
                                            id="file1"
                                            multiple
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>
                                            ) => {
                                                e.target.files &&
                                                    addImage(e.target.files)
                                            }}
                                            accept="image/png,image/jpeg"
                                        />
                                    </span>
                                </label>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        overflowX: 'auto',
                                        height: '180px',
                                        width: '100%',
                                    }}
                                >
                                    {imgs.length > 0 &&
                                        imgs.map((img: any, index: any) => (
                                            <span
                                                onClick={() => {
                                                    editImageModalRef.current?.handleOpenModal()
                                                }}
                                                key={index}
                                            >
                                                <div className="preview">
                                                    <div className="delete-icon-background">
                                                        <Exit
                                                            onClick={() =>
                                                                deleteImage(img)
                                                            }
                                                        />
                                                    </div>
                                                    <img
                                                        style={{
                                                            objectFit: 'cover',
                                                        }}
                                                        src={URL.createObjectURL(
                                                            img
                                                        )}
                                                        alt=" "
                                                        key={index}
                                                    />
                                                </div>
                                            </span>
                                        ))}
                                </div>
                            </div>
                            {imagesRequirements &&
                                imagesRequirements.map((requirement, index) => {
                                    return (
                                        <p key={index}>{`* ${requirement}`}</p>
                                    )
                                })}
                        </ImagesContainer>
                    </Title>

                    <Route path={`/register-product/:categoryId/product`}>
                        <TitleCategory>
                            <h2>Variações</h2>
                            <Categorys variacao={variations.length > 0}>
                                <span className="img-instructions">
                                    <h1>Seu produto possui variações ?</h1>
                                </span>
                                <div className="sell-type-buttons-container">
                                    <div
                                        style={{ marginRight: '35px' }}
                                        className="sell-type-container"
                                    >
                                        <div
                                            onClick={() =>
                                                setHasVariations(true)
                                            }
                                            className={
                                                hasVariations
                                                    ? 'bargain-active'
                                                    : undefined
                                            }
                                        >
                                            Sim
                                        </div>

                                        {
                                            <div
                                                onClick={() =>
                                                    setHasVariations(false)
                                                }
                                                className={
                                                    !hasVariations
                                                        ? 'bargain-active'
                                                        : undefined
                                                }
                                            >
                                                Não
                                            </div>
                                        }
                                    </div>
                                </div>

                                {hasVariations && (
                                    <>
                                        <div className="img-instructions">
                                            {variationsOutput &&
                                                variationsOutput.getAllVariationProduct.map(
                                                    (variacao) => (
                                                        <>
                                                            <button
                                                                type="button"
                                                                className="variation-button"
                                                                onClick={() =>
                                                                    configurarVariacao(
                                                                        variacao
                                                                    )
                                                                }
                                                            >
                                                                {variacao.name}
                                                            </button>
                                                        </>
                                                    )
                                                )}
                                        </div>

                                        <div className="variacao-container">
                                            {variations &&
                                                variations.map((variacao) => {
                                                    if (
                                                        variacao.name === 'COR'
                                                    ) {
                                                        return (
                                                            <>
                                                                <div className="container-column-variacao">
                                                                    <h2>
                                                                        Cor:
                                                                    </h2>
                                                                    <CompactPicker
                                                                        onChangeComplete={(
                                                                            value
                                                                        ) =>
                                                                            setVariacaoState(
                                                                                {
                                                                                    ...variacaoState,
                                                                                    cor: value.hex,
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    if (
                                                        variacao.name ===
                                                        'TAMANHO'
                                                    ) {
                                                        return (
                                                            <>
                                                                <div className="container-column-variacao">
                                                                    <h2>
                                                                        Tamanho:{' '}
                                                                    </h2>
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Insira o Tamanho"
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setVariacaoState(
                                                                                {
                                                                                    ...variacaoState,
                                                                                    tamanho:
                                                                                        e
                                                                                            .target
                                                                                            .value,
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    return <></>
                                                })}
                                            <div className="margin-top-container-variations">
                                                <h2 className="title-quantidade">
                                                    Quantidade:
                                                </h2>
                                                <input
                                                    type="text"
                                                    placeholder="Estoque"
                                                    value={
                                                        variacaoState.quantidade
                                                    }
                                                    onChange={(e) =>
                                                        setVariacaoState({
                                                            ...variacaoState,
                                                            quantidade: Number(
                                                                e.target.value
                                                            ),
                                                        })
                                                    }
                                                />
                                                <button
                                                    type="button"
                                                    onClick={adicionarVariacao}
                                                >
                                                    <IoIosAddCircleOutline
                                                        size={25}
                                                        color="green"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="sugestao-container">
                                            <span>
                                                *Sugestão: Para adicionar
                                                qualquer variação, clique na
                                                cor, insira o tamanho desejado e
                                                também a quantidade em estoque.
                                                Para finalizar com sucesso,
                                                clique no botão de adicionar ao
                                                lado.
                                            </span>
                                        </div>
                                        {productVariations &&
                                            productVariations.map(
                                                (variacao, index) => (
                                                    <div
                                                        className="variacao-container"
                                                        key={index}
                                                    >
                                                        {variacao.cor && (
                                                            <>
                                                                <input
                                                                    disabled={
                                                                        true
                                                                    }
                                                                    style={{
                                                                        background:
                                                                            variacao?.cor,
                                                                        width: '5vw',
                                                                    }}
                                                                />
                                                            </>
                                                        )}

                                                        <input
                                                            disabled={true}
                                                            type="text"
                                                            placeholder="Quantidade"
                                                            value={
                                                                variacao?.quantidade
                                                            }
                                                        />

                                                        {variacao.tamanho && (
                                                            <>
                                                                <input
                                                                    disabled={
                                                                        true
                                                                    }
                                                                    type="text"
                                                                    placeholder="Tamanho"
                                                                    value={
                                                                        variacao?.tamanho
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                        <button
                                                            type="button"
                                                            onClick={() =>
                                                                deleteVariacao(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            <IoMdRemoveCircle
                                                                size={25}
                                                                color="red"
                                                            />
                                                        </button>
                                                    </div>
                                                )
                                            )}
                                    </>
                                )}
                            </Categorys>
                        </TitleCategory>
                    </Route>

                    <Route path={`/register-product/${8}/properties`}>
                        <TitleCategory>
                            <h2>Categorias</h2>
                            <Categorys>
                                <InputOrganizerSize style={{ width: '20%' }}>
                                    <label>Buscar Categoria</label>
                                    <input
                                        type="text"
                                        value={categorySearchQuery}
                                        onChange={({ target }) =>
                                            setCategorySearchQuery(target.value)
                                        }
                                    />
                                </InputOrganizerSize>
                                <div
                                    className="img-container"
                                    style={{
                                        borderBottom: 'solid 1px black',
                                        paddingBottom: '10px',
                                        width: '100%',
                                    }}
                                >
                                    <NavLink
                                        className="option"
                                        activeClassName="option-active"
                                        to={`/register-product/${8}/properties`}
                                    >
                                        Imóveis
                                    </NavLink>
                                </div>
                                <span
                                    className="img-instructions"
                                    style={{ paddingTop: '10px' }}
                                >
                                    <h1>Selecione o tipo do seu Imóvel.</h1>
                                </span>
                                <div className="img-container">
                                    {data &&
                                        dataSubCategories?.getAllSubCategoriesByCategory.map(
                                            (
                                                subCategory: any,
                                                index: number
                                            ) => (
                                                <>
                                                    <span
                                                        className={`option ${
                                                            selectedSubCategory ===
                                                            subCategory.id
                                                                ? 'option-active'
                                                                : ''
                                                        }`}
                                                        onClick={() =>
                                                            setSelectedSubCategory(
                                                                subCategory.id
                                                            )
                                                        }
                                                        key={index}
                                                    >
                                                        {subCategory.name}
                                                    </span>
                                                </>
                                            )
                                        )}
                                </div>
                            </Categorys>
                        </TitleCategory>
                    </Route>

                    <Route path={`/register-product/${7}/vehicles`}>
                        <TitleCategory>
                            <h2>Categorias</h2>
                            <Categorys>
                                <InputOrganizerSize style={{ width: '20%' }}>
                                    <label>Buscar Categoria</label>
                                    <input
                                        type="text"
                                        value={categorySearchQuery}
                                        onChange={({ target }) =>
                                            setCategorySearchQuery(target.value)
                                        }
                                    />
                                </InputOrganizerSize>
                                <div
                                    className="img-container"
                                    style={{
                                        borderBottom: 'solid 1px black',
                                        paddingBottom: '10px',
                                    }}
                                >
                                    <NavLink
                                        className="option"
                                        activeClassName="option-active"
                                        to={`/register-product/${7}/vehicles`}
                                    >
                                        Veiculos
                                    </NavLink>
                                </div>
                                <span
                                    className="img-instructions"
                                    style={{ paddingTop: '10px' }}
                                >
                                    <h1>Selecione o tipo do seu Veículo.</h1>
                                </span>
                                <div className="img-container">
                                    {data &&
                                        dataSubCategories?.getAllSubCategoriesByCategory.map(
                                            (
                                                subCategory: any,
                                                index: number
                                            ) => (
                                                <>
                                                    <span
                                                        className={`option ${
                                                            selectedSubCategory ===
                                                            subCategory.id
                                                                ? 'option-active'
                                                                : ''
                                                        }`}
                                                        onClick={() =>
                                                            setSelectedSubCategory(
                                                                subCategory.id
                                                            )
                                                        }
                                                        key={index}
                                                    >
                                                        {subCategory.name}
                                                    </span>
                                                </>
                                            )
                                        )}
                                </div>
                            </Categorys>
                        </TitleCategory>
                    </Route>

                    <Route path={`/register-product/:categoryId/product`}>
                        <TitleCategory>
                            <h2>Categorias</h2>
                            <Categorys>
                                <InputOrganizerSize style={{ width: '20%' }}>
                                    <label>Buscar Categoria</label>
                                    <input
                                        type="text"
                                        value={categorySearchQuery}
                                        onChange={({ target }) =>
                                            setCategorySearchQuery(target.value)
                                        }
                                    />
                                </InputOrganizerSize>
                                <div
                                    className="img-container"
                                    style={{
                                        borderBottom: 'solid 1px black',
                                        paddingBottom: '10px',
                                    }}
                                >
                                    {filterCategories &&
                                        filterCategories.map(
                                            (category: any, index: number) => (
                                                <>
                                                    <NavLink
                                                        className="option"
                                                        activeClassName="option-active"
                                                        to={`/register-product/${category.id}/product`}
                                                        key={index}
                                                    >
                                                        {category.name}
                                                    </NavLink>
                                                </>
                                            )
                                        )}
                                </div>
                                <span
                                    className="img-instructions"
                                    style={{ paddingTop: '10px' }}
                                >
                                    <h1>Subcategoria</h1>
                                </span>
                                <div className="img-container">
                                    {data &&
                                        dataSubCategories?.getAllSubCategoriesByCategory.map(
                                            (
                                                subCategory: any,
                                                index: number
                                            ) => (
                                                <>
                                                    <span
                                                        className={`option ${
                                                            selectedSubCategory ===
                                                            subCategory.id
                                                                ? 'option-active'
                                                                : ''
                                                        }`}
                                                        onClick={() =>
                                                            setSelectedSubCategory(
                                                                subCategory.id
                                                            )
                                                        }
                                                        key={index}
                                                    >
                                                        {subCategory.name}
                                                    </span>
                                                </>
                                            )
                                        )}
                                </div>
                                <p>
                                    *Tanto as Categorias quanto as Subcategorias
                                    se encontram na ordem alfábetica, e caso não
                                    encontre a desejada, selecione a categoria{' '}
                                    <strong>Outros</strong>*.
                                </p>
                                <p>
                                    <strong>
                                        *A Sambay express reforça, conforme sua
                                        política, que é proibida a venda de
                                        armas de fogo, munições e acessórios, no
                                        portal. E que é proibida a venda de
                                        réplicas ou armas brancas a menores de
                                        18 anos, nos termos da legislação
                                        brasileira.*
                                    </strong>
                                </p>
                            </Categorys>
                        </TitleCategory>
                    </Route>

                    <Route path="/register-product/:categoryId/product">
                        <PriceTitleContainer>
                            <h2>Preço e pagamento</h2>
                            <PricePayment>
                                <div className="organizer">
                                    <div className="sell-type-buttons-container">
                                        <div className="sell-type-container">
                                            <div
                                                className={'button-sell-active'}
                                            >
                                                Venda
                                            </div>
                                        </div>
                                    </div>
                                    <h2 className="stylesTitlePechincha">
                                        Você deseja adicionar a opção de
                                        Pechincha?
                                    </h2>
                                    <span>
                                        *É uma boa ferramenta para aumentar as
                                        vendas! Em Valor Mínimo, coloque o menor
                                        preço que aceitaria por seu produto.
                                    </span>
                                    <div className="sell-type-buttons-container">
                                        <div
                                            style={{
                                                marginRight: '35px',
                                            }}
                                            className="sell-type-container"
                                        >
                                            <div
                                                onClick={() =>
                                                    setIsBargainEnabled(true)
                                                }
                                                className={
                                                    isBargainEnabled
                                                        ? 'bargain-active'
                                                        : undefined
                                                }
                                            >
                                                Sim
                                            </div>

                                            {
                                                <div
                                                    onClick={() =>
                                                        setIsBargainEnabled(
                                                            false
                                                        )
                                                    }
                                                    className={
                                                        !isBargainEnabled
                                                            ? 'bargain-active'
                                                            : undefined
                                                    }
                                                >
                                                    Não
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className="sell-options">
                                        <>
                                            <InputOrganizer
                                                borderColor={validInput(
                                                    errors.price
                                                )}
                                            >
                                                <label>Valor *</label>
                                                <input
                                                    type="text"
                                                    {...register('price', {
                                                        required:
                                                            history.location
                                                                .pathname ===
                                                            `/register-product/${categoryId.categorieId}/product`,
                                                    })}
                                                    maxLength={15}
                                                    onChange={(e) => {
                                                        setValueMask(
                                                            handleMoneyValues(
                                                                e.target.value
                                                            )
                                                        )
                                                    }}
                                                    value={valueMaskConst}
                                                />
                                                {errors.price &&
                                                    exibirErro(errors.price)}
                                            </InputOrganizer>

                                            {isBargainEnabled ? (
                                                <InputOrganizer
                                                    borderColor={validInput(
                                                        errors.initialValue
                                                    )}
                                                >
                                                    <label>
                                                        Valor Mínimo *
                                                    </label>

                                                    <input
                                                        type="text"
                                                        {...register(
                                                            'initialValue',
                                                            {
                                                                required:
                                                                    history
                                                                        .location
                                                                        .pathname ===
                                                                    `/register-product/${categoryId.categorieId}/product`,
                                                            }
                                                        )}
                                                        value={bargainMask}
                                                        onChange={(e) =>
                                                            setBargainMask(
                                                                handleMoneyValues(
                                                                    e.target
                                                                        .value
                                                                )
                                                            )
                                                        }
                                                    />
                                                    <Help
                                                        className="icon"
                                                        fill="#FFC000"
                                                    />
                                                    <div className="field-info">
                                                        O valor minimo serve
                                                        para quando um comprador
                                                        deseja pechinchar pelo
                                                        seu produto, se o valor
                                                        proposto pelo cliente
                                                        for igual ou maior que o
                                                        valor minimo ele poderá
                                                        comprar o produto pelo
                                                        valor escolhido. O valor
                                                        de pechincha não pode
                                                        ser maior que o valor do
                                                        produto.
                                                    </div>
                                                    {errors.initialValue &&
                                                        exibirErro(
                                                            errors.initialValue
                                                        )}
                                                </InputOrganizer>
                                            ) : (
                                                <></>
                                            )}
                                            <InputOrganizer
                                                borderColor={validInput(
                                                    errors.initialValue
                                                )}
                                            >
                                                <label>
                                                    Número de parcelas *
                                                </label>
                                                <select
                                                    {...register(
                                                        'maxInstallments',
                                                        {
                                                            required:
                                                                history.location
                                                                    .pathname ===
                                                                `/register-product/${categoryId.categorieId}/product`,
                                                        }
                                                    )}
                                                >
                                                    <option
                                                        value=""
                                                        disabled
                                                        selected
                                                    >
                                                        --selecione--
                                                    </option>
                                                    {installments.map(
                                                        (installment) => (
                                                            <option
                                                                value={
                                                                    installment
                                                                }
                                                                key={
                                                                    installment
                                                                }
                                                            >
                                                                {installment}x
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                                {errors.initialValue &&
                                                    exibirErro(
                                                        errors.initialValue
                                                    )}
                                            </InputOrganizer>
                                            <InputOrganizer
                                                style={{
                                                    borderRadius: '7px',
                                                }}
                                                borderColor={validInput(
                                                    errors.stock
                                                )}
                                            >
                                                <label>Em estoque *</label>
                                                {productVariations.length >
                                                0 ? (
                                                    <>
                                                        <input
                                                            disabled={true}
                                                            type="number"
                                                            {...register(
                                                                'stock',
                                                                {
                                                                    required:
                                                                        false,
                                                                    min: 1,
                                                                }
                                                            )}
                                                            value={calcularQuantidadeProduto()}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <input
                                                            type="number"
                                                            {...register(
                                                                'stock',
                                                                {
                                                                    required:
                                                                        history
                                                                            .location
                                                                            .pathname ===
                                                                        `/register-product/${categoryId.categorieId}/product`,
                                                                    min: 1,
                                                                }
                                                            )}
                                                        />
                                                    </>
                                                )}

                                                {errors.stock &&
                                                    exibirErro(errors.stock)}
                                            </InputOrganizer>
                                        </>
                                    </div>
                                </div>
                            </PricePayment>
                        </PriceTitleContainer>
                    </Route>

                    <Route path={`/register-product/${8}/properties`}>
                        <PriceTitleContainer>
                            <h2>Preço e Objetivo de Anúncio</h2>
                            <PricePayment>
                                <div className="organizer">
                                    <div className="sell-type-buttons-container">
                                        <div
                                            style={{
                                                marginRight: '35px',
                                            }}
                                            className="sell-type-container"
                                        >
                                            <div
                                                onClick={() =>
                                                    setTypePropertiesNegociation(
                                                        'VendaImovel'
                                                    )
                                                }
                                                className={
                                                    typePropertiesNegociation ===
                                                    'VendaImovel'
                                                        ? 'button-sell-active'
                                                        : undefined
                                                }
                                            >
                                                Venda
                                            </div>
                                            <div
                                                onClick={() =>
                                                    setTypePropertiesNegociation(
                                                        'AluguelImovel'
                                                    )
                                                }
                                                className={
                                                    typePropertiesNegociation ===
                                                    'AluguelImovel'
                                                        ? 'button-sell-active'
                                                        : undefined
                                                }
                                            >
                                                Aluguel
                                            </div>
                                        </div>
                                    </div>

                                    <h2 className="stylesTitlePechincha">
                                        O seu imóvel é quitado?
                                    </h2>
                                    <div className="sell-type-buttons-container">
                                        <div
                                            style={{
                                                marginRight: '35px',
                                            }}
                                            className="sell-type-container"
                                        >
                                            <div
                                                onClick={() =>
                                                    setIsPropertyPaidOff(true)
                                                }
                                                className={
                                                    isPropertyPaidOff
                                                        ? 'button-sell-active'
                                                        : undefined
                                                }
                                            >
                                                Sim
                                            </div>
                                            <div
                                                onClick={() =>
                                                    setIsPropertyPaidOff(false)
                                                }
                                                className={
                                                    !isPropertyPaidOff
                                                        ? 'button-sell-active'
                                                        : undefined
                                                }
                                            >
                                                Não
                                            </div>
                                        </div>
                                    </div>

                                    <div className="sell-options">
                                        <>
                                            {typePropertiesNegociation ===
                                            'VendaImovel' ? (
                                                <>
                                                    <>
                                                        <InputOrganizer
                                                            borderColor={validInput(
                                                                errors.price
                                                            )}
                                                        >
                                                            <label>
                                                                Valor *
                                                            </label>
                                                            <input
                                                                type="text"
                                                                {...register(
                                                                    'price',
                                                                    {
                                                                        required:
                                                                            history
                                                                                .location
                                                                                .pathname ===
                                                                            `/register-product/${categoryId.categorieId}/properties`,
                                                                    }
                                                                )}
                                                                maxLength={10}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setValueMask(
                                                                        handleMoneyValues(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    )
                                                                }}
                                                                value={
                                                                    valueMaskConst
                                                                }
                                                            />
                                                            {errors.price &&
                                                                exibirErro(
                                                                    errors.price
                                                                )}
                                                        </InputOrganizer>
                                                    </>
                                                    <InputOrganizer
                                                        borderColor={validInput(
                                                            errors.initialValue
                                                        )}
                                                    >
                                                        <label>
                                                            Valor Mínimo *
                                                        </label>

                                                        <input
                                                            type="text"
                                                            {...register(
                                                                'initialValue',
                                                                {
                                                                    required:
                                                                        history
                                                                            .location
                                                                            .pathname ===
                                                                        `/register-product/${categoryId.categorieId}/properties`,
                                                                }
                                                            )}
                                                            value={bargainMask}
                                                            onChange={(e) =>
                                                                setBargainMask(
                                                                    handleMoneyValues(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                )
                                                            }
                                                        />
                                                        <Help
                                                            className="icon"
                                                            fill="#FFC000"
                                                        />
                                                        <div className="field-info">
                                                            O valor minimo serve
                                                            para quando um
                                                            comprador deseja
                                                            pechinchar pelo seu
                                                            produto, se o valor
                                                            proposto pelo
                                                            cliente for igual ou
                                                            maior que o valor
                                                            minimo ele poderá
                                                            comprar o produto
                                                            pelo valor
                                                            escolhido. O valor
                                                            de pechincha não
                                                            pode ser maior que o
                                                            valor do produto.
                                                        </div>
                                                        {errors.initialValue &&
                                                            exibirErro(
                                                                errors.initialValue
                                                            )}
                                                    </InputOrganizer>
                                                    {!isPropertyPaidOff ? (
                                                        <>
                                                            <InputOrganizer
                                                                borderColor={validInput(
                                                                    errors.valorRestantes
                                                                )}
                                                                style={{
                                                                    borderRadius:
                                                                        '7px',
                                                                    flexDirection:
                                                                        'column',
                                                                }}
                                                            >
                                                                <label>
                                                                    Valor
                                                                    Restante *
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    {...register(
                                                                        'valorRestantes',
                                                                        {
                                                                            required:
                                                                                history
                                                                                    .location
                                                                                    .pathname ===
                                                                                    `/register-product/${categoryId.categorieId}/properties` &&
                                                                                !isPropertyPaidOff,
                                                                        }
                                                                    )}
                                                                />
                                                                {errors.valorRestantes &&
                                                                    exibirErro(
                                                                        errors.valorRestantes
                                                                    )}
                                                            </InputOrganizer>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <InputOrganizer
                                                        style={{
                                                            borderRadius: '7px',
                                                            flexDirection:
                                                                'column',
                                                        }}
                                                        borderColor={validInput(
                                                            errors.price
                                                        )}
                                                    >
                                                        <label>Valor *</label>
                                                        <input
                                                            type="text"
                                                            {...register(
                                                                'price',
                                                                {
                                                                    required:
                                                                        history
                                                                            .location
                                                                            .pathname ===
                                                                        `/register-product/${categoryId.categorieId}/properties`,
                                                                }
                                                            )}
                                                            maxLength={10}
                                                            onChange={(e) => {
                                                                setValueMask(
                                                                    handleMoneyValues(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                )
                                                            }}
                                                            value={
                                                                valueMaskConst
                                                            }
                                                        />
                                                        {errors.price &&
                                                            exibirErro(
                                                                errors.price
                                                            )}
                                                    </InputOrganizer>
                                                    <InputOrganizer
                                                        style={{
                                                            borderRadius: '7px',
                                                        }}
                                                        borderColor={validInput(
                                                            errors.initialValue
                                                        )}
                                                    >
                                                        <label>
                                                            Valor Mínimo *
                                                        </label>
                                                        <input
                                                            type="text"
                                                            {...register(
                                                                'initialValue',
                                                                {
                                                                    required:
                                                                        history
                                                                            .location
                                                                            .pathname ===
                                                                        `/register-product/${categoryId.categorieId}/properties`,
                                                                }
                                                            )}
                                                            value={bargainMask}
                                                            onChange={(e) =>
                                                                setBargainMask(
                                                                    handleMoneyValues(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                )
                                                            }
                                                        />
                                                        <Help
                                                            className="icon"
                                                            fill="#FFC000"
                                                        />
                                                        <div className="field-info">
                                                            O valor minimo serve
                                                            para quando um
                                                            comprador deseja
                                                            pechinchar pelo seu
                                                            produto, se o valor
                                                            proposto pelo
                                                            cliente for igual ou
                                                            maior que o valor
                                                            minimo ele poderá
                                                            comprar o produto
                                                            pelo valor
                                                            escolhido. O valor
                                                            de pechincha não
                                                            pode ser maior que o
                                                            valor do produto.
                                                        </div>
                                                        {errors.initialValue &&
                                                            exibirErro(
                                                                errors.initialValue
                                                            )}
                                                    </InputOrganizer>
                                                    <InputOrganizer
                                                        borderColor={validInput(
                                                            errors.mesesDeContrato
                                                        )}
                                                        style={{
                                                            borderRadius: '7px',
                                                            flexDirection:
                                                                'column',
                                                        }}
                                                    >
                                                        <label>
                                                            Meses de Contrato *
                                                        </label>
                                                        <input
                                                            type="number"
                                                            {...register(
                                                                'mesesDeContrato',
                                                                {
                                                                    required:
                                                                        history
                                                                            .location
                                                                            .pathname ===
                                                                        `/register-product/${categoryId.categorieId}/properties`,
                                                                }
                                                            )}
                                                        />
                                                        {errors.mesesDeContrato &&
                                                            exibirErro(
                                                                errors.mesesDeContrato
                                                            )}
                                                    </InputOrganizer>
                                                    {!isPropertyPaidOff ? (
                                                        <>
                                                            <InputOrganizer
                                                                borderColor={validInput(
                                                                    errors.valorRestantes
                                                                )}
                                                                style={{
                                                                    borderRadius:
                                                                        '7px',
                                                                    flexDirection:
                                                                        'column',
                                                                }}
                                                            >
                                                                <label>
                                                                    Valor
                                                                    Restante *
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    {...register(
                                                                        'valorRestantes',
                                                                        {
                                                                            required:
                                                                                history
                                                                                    .location
                                                                                    .pathname ===
                                                                                    `/register-product/${categoryId.categorieId}/properties` &&
                                                                                typePropertiesNegociation ===
                                                                                    'AluguelImovel',
                                                                        }
                                                                    )}
                                                                />
                                                                {errors.valorRestantes &&
                                                                    exibirErro(
                                                                        errors.valorRestantes
                                                                    )}
                                                            </InputOrganizer>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    </div>
                                </div>
                            </PricePayment>
                        </PriceTitleContainer>
                    </Route>

                    <Route path={`/register-product/${7}/vehicles`}>
                        <PriceTitleContainer>
                            <h2>Preço e Objetivo de Anúncio</h2>
                            <PricePayment>
                                <div className="organizer">
                                    <div className="sell-type-buttons-container">
                                        <div className="sell-type-container">
                                            <div
                                                onClick={() =>
                                                    setTypeVechileNegociation(
                                                        'VendaVeiculo'
                                                    )
                                                }
                                                className={
                                                    typeVechileNegociation ===
                                                    'VendaVeiculo'
                                                        ? 'button-sell-active'
                                                        : undefined
                                                }
                                            >
                                                Venda
                                            </div>
                                            <div
                                                onClick={() =>
                                                    setTypeVechileNegociation(
                                                        'AluguelVeiculo'
                                                    )
                                                }
                                                className={
                                                    typeVechileNegociation ===
                                                    'AluguelVeiculo'
                                                        ? 'button-sell-active'
                                                        : undefined
                                                }
                                            >
                                                Aluguel
                                            </div>
                                        </div>
                                    </div>

                                    <h2 className="stylesTitlePechincha">
                                        O seu veículo é quitado?
                                    </h2>
                                    <div className="sell-type-buttons-container">
                                        <div className="sell-type-container">
                                            <div
                                                onClick={() =>
                                                    setIsVehiclePaidOff(true)
                                                }
                                                className={
                                                    isVehiclePaidOff
                                                        ? 'button-sell-active'
                                                        : undefined
                                                }
                                            >
                                                Sim
                                            </div>
                                            <div
                                                onClick={() =>
                                                    setIsVehiclePaidOff(false)
                                                }
                                                className={
                                                    !isVehiclePaidOff
                                                        ? 'button-sell-active'
                                                        : undefined
                                                }
                                            >
                                                Não
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sell-options">
                                        <>
                                            {typeVechileNegociation ===
                                            'VendaVeiculo' ? (
                                                <>
                                                    <>
                                                        <InputOrganizer
                                                            style={{
                                                                borderRadius:
                                                                    '7px',
                                                                flexDirection:
                                                                    'column',
                                                            }}
                                                            borderColor={validInput(
                                                                errors.price
                                                            )}
                                                        >
                                                            <label>
                                                                Valor *
                                                            </label>
                                                            <input
                                                                type="text"
                                                                {...register(
                                                                    'price',
                                                                    {
                                                                        required:
                                                                            history
                                                                                .location
                                                                                .pathname ===
                                                                            `/register-product/${categoryId.categorieId}/vehicles`,
                                                                    }
                                                                )}
                                                                maxLength={10}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setValueMask(
                                                                        handleMoneyValues(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    )
                                                                }}
                                                                value={
                                                                    valueMaskConst
                                                                }
                                                            />
                                                            {errors.price &&
                                                                exibirErro(
                                                                    errors.price
                                                                )}
                                                        </InputOrganizer>
                                                        <InputOrganizer
                                                            style={{
                                                                borderRadius:
                                                                    '7px',
                                                            }}
                                                            borderColor={validInput(
                                                                errors.initialValue
                                                            )}
                                                        >
                                                            <label>
                                                                Valor Mínimo *
                                                            </label>
                                                            <input
                                                                type="text"
                                                                {...register(
                                                                    'initialValue',
                                                                    {
                                                                        required:
                                                                            history
                                                                                .location
                                                                                .pathname ===
                                                                            `/register-product/${categoryId.categorieId}/vehicles`,
                                                                    }
                                                                )}
                                                                value={
                                                                    bargainMask
                                                                }
                                                                onChange={(e) =>
                                                                    setBargainMask(
                                                                        handleMoneyValues(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    )
                                                                }
                                                            />
                                                            <Help
                                                                className="icon"
                                                                fill="#FFC000"
                                                            />
                                                            <div className="field-info">
                                                                O valor minimo
                                                                serve para
                                                                quando um
                                                                comprador deseja
                                                                pechinchar pelo
                                                                seu produto, se
                                                                o valor proposto
                                                                pelo cliente for
                                                                igual ou maior
                                                                que o valor
                                                                minimo ele
                                                                poderá comprar o
                                                                produto pelo
                                                                valor escolhido.
                                                                O valor de
                                                                pechincha não
                                                                pode ser maior
                                                                que o valor do
                                                                produto.
                                                            </div>
                                                            {errors.initialValue &&
                                                                exibirErro(
                                                                    errors.initialValue
                                                                )}
                                                        </InputOrganizer>

                                                        {!isVehiclePaidOff ? (
                                                            <>
                                                                <InputOrganizer
                                                                    borderColor={validInput(
                                                                        errors.valorRestantes
                                                                    )}
                                                                    style={{
                                                                        borderRadius:
                                                                            '7px',
                                                                        flexDirection:
                                                                            'column',
                                                                    }}
                                                                >
                                                                    <label>
                                                                        Valor
                                                                        Restante
                                                                        *
                                                                    </label>
                                                                    <input
                                                                        type="number"
                                                                        {...register(
                                                                            'valorRestantes',
                                                                            {
                                                                                required:
                                                                                    history
                                                                                        .location
                                                                                        .pathname ===
                                                                                        `/register-product/${categoryId.categorieId}/vehicles` &&
                                                                                    !isVehiclePaidOff,
                                                                            }
                                                                        )}
                                                                    />
                                                                    {errors.valorRestantes &&
                                                                        exibirErro(
                                                                            errors.valorRestantes
                                                                        )}
                                                                </InputOrganizer>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </>
                                                </>
                                            ) : (
                                                <>
                                                    <>
                                                        <InputOrganizer
                                                            style={{
                                                                borderRadius:
                                                                    '7px',
                                                                flexDirection:
                                                                    'column',
                                                            }}
                                                            borderColor={validInput(
                                                                errors.price
                                                            )}
                                                        >
                                                            <label>
                                                                Valor *
                                                            </label>
                                                            <input
                                                                type="text"
                                                                {...register(
                                                                    'price',
                                                                    {
                                                                        required:
                                                                            history
                                                                                .location
                                                                                .pathname ===
                                                                            `/register-product/${categoryId.categorieId}/vehicles`,
                                                                    }
                                                                )}
                                                                maxLength={10}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setValueMask(
                                                                        handleMoneyValues(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    )
                                                                }}
                                                                value={
                                                                    valueMaskConst
                                                                }
                                                            />
                                                            {errors.price &&
                                                                exibirErro(
                                                                    errors.price
                                                                )}
                                                        </InputOrganizer>
                                                        <InputOrganizer
                                                            style={{
                                                                borderRadius:
                                                                    '7px',
                                                            }}
                                                            borderColor={validInput(
                                                                errors.initialValue
                                                            )}
                                                        >
                                                            <label>
                                                                Valor Mínimo *
                                                            </label>
                                                            <input
                                                                type="text"
                                                                {...register(
                                                                    'initialValue',
                                                                    {
                                                                        required:
                                                                            history
                                                                                .location
                                                                                .pathname ===
                                                                            `/register-product/${categoryId.categorieId}/vehicles`,
                                                                    }
                                                                )}
                                                                value={
                                                                    bargainMask
                                                                }
                                                                onChange={(e) =>
                                                                    setBargainMask(
                                                                        handleMoneyValues(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    )
                                                                }
                                                            />
                                                            <Help
                                                                className="icon"
                                                                fill="#FFC000"
                                                            />
                                                            <div className="field-info">
                                                                O valor minimo
                                                                serve para
                                                                quando um
                                                                comprador deseja
                                                                pechinchar pelo
                                                                seu produto, se
                                                                o valor proposto
                                                                pelo cliente for
                                                                igual ou maior
                                                                que o valor
                                                                minimo ele
                                                                poderá comprar o
                                                                produto pelo
                                                                valor escolhido.
                                                                O valor de
                                                                pechincha não
                                                                pode ser maior
                                                                que o valor do
                                                                produto.
                                                            </div>
                                                            {errors.initialValue &&
                                                                exibirErro(
                                                                    errors.initialValue
                                                                )}
                                                        </InputOrganizer>
                                                        <InputOrganizer
                                                            borderColor={validInput(
                                                                errors.mesesDeContrato
                                                            )}
                                                            style={{
                                                                borderRadius:
                                                                    '7px',
                                                                flexDirection:
                                                                    'column',
                                                            }}
                                                        >
                                                            <label>
                                                                Meses de
                                                                Contrato *
                                                            </label>
                                                            <input
                                                                type="number"
                                                                {...register(
                                                                    'mesesDeContrato',
                                                                    {
                                                                        required:
                                                                            history
                                                                                .location
                                                                                .pathname ===
                                                                                `/register-product/${categoryId.categorieId}/vehicles` &&
                                                                            typeVechileNegociation ===
                                                                                'AluguelVeiculo',
                                                                    }
                                                                )}
                                                            />
                                                            {errors.mesesDeContrato &&
                                                                exibirErro(
                                                                    errors.mesesDeContrato
                                                                )}
                                                        </InputOrganizer>
                                                    </>
                                                    {!isVehiclePaidOff ? (
                                                        <>
                                                            <InputOrganizer
                                                                borderColor={validInput(
                                                                    errors.valorRestantes
                                                                )}
                                                                style={{
                                                                    borderRadius:
                                                                        '7px',
                                                                    flexDirection:
                                                                        'column',
                                                                }}
                                                            >
                                                                <label>
                                                                    Valor
                                                                    Restante *
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    {...register(
                                                                        'valorRestantes',
                                                                        {
                                                                            required:
                                                                                history
                                                                                    .location
                                                                                    .pathname ===
                                                                                    `/register-product/${categoryId.categorieId}/vehicles` &&
                                                                                typeVechileNegociation ===
                                                                                    'AluguelVeiculo',
                                                                        }
                                                                    )}
                                                                />
                                                                {errors.valorRestantes &&
                                                                    exibirErro(
                                                                        errors.valorRestantes
                                                                    )}
                                                            </InputOrganizer>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    </div>
                                </div>
                            </PricePayment>
                        </PriceTitleContainer>
                    </Route>

                    <Route path="/register-product/:categoryId/product">
                        <Title>
                            <h2>Opções de envio</h2>
                            <Container
                                style={{
                                    paddingTop: '10px',
                                    marginBottom: '20px',
                                }}
                            >
                                <div className="prompt-delivery">
                                    <h2>À pronta entrega?</h2>
                                    <div className="prompt-delivery-buttons">
                                        <button
                                            className={`prompt-delivery-button ${
                                                promptDelivery ? 'active' : ''
                                            }`}
                                            id="yes"
                                            onClick={() =>
                                                setPromptDelivery(true)
                                            }
                                            type="button"
                                        >
                                            Sim
                                        </button>
                                        <button
                                            className={`prompt-delivery-button ${
                                                !promptDelivery ? 'active' : ''
                                            }`}
                                            id="no"
                                            onClick={() =>
                                                setPromptDelivery(false)
                                            }
                                            type="button"
                                        >
                                            Não
                                        </button>
                                    </div>
                                </div>

                                {promptDelivery === false && (
                                    <div>
                                        <h2 className="stylesTitlePechincha">
                                            Prazo estimado em que o produto
                                            ficará disponível:
                                        </h2>
                                        <InputOrganizer>
                                            <label>Prazo (dias)*</label>
                                            <input
                                                type="number"
                                                {...register('deadline', {
                                                    required:
                                                        history.location
                                                            .pathname ===
                                                        `/register-product/${categoryId.categorieId}/product`,
                                                })}
                                            />
                                        </InputOrganizer>
                                        <p>
                                            *Prazo estimado em que o produto
                                            ficará disponível para, então, poder
                                            ser enviado.
                                        </p>
                                    </div>
                                )}
                                <div className="prompt-delivery">
                                    <h2>Adicionar frete grátis?</h2>
                                    <label>
                                        Você deseja arcar com as despesas de
                                        entrega, disponibilizando assim, frete
                                        grátis para os seus clientes? Ao
                                        escolher essa opção, o produto estará
                                        disponível somente na modalidade de
                                        retirada com o vendedor.
                                    </label>
                                    <div className="prompt-delivery-buttons">
                                        <button
                                            className={`prompt-delivery-button ${
                                                hasFreeDelivery ? 'active' : ''
                                            }`}
                                            id="yes"
                                            onClick={() => {
                                                setHasFreeDelivery(true)
                                                setValue('withdraw', 'Sim')
                                            }}
                                            type="button"
                                        >
                                            Sim
                                        </button>
                                        <button
                                            className={`prompt-delivery-button ${
                                                !hasFreeDelivery ? 'active' : ''
                                            }`}
                                            id="no"
                                            onClick={() =>
                                                setHasFreeDelivery(false)
                                            }
                                            type="button"
                                        >
                                            Não
                                        </button>
                                    </div>
                                </div>

                                <h2>
                                    Escolha o endereço de onde o produto partirá
                                    para o envio:
                                </h2>
                                {user.ProfileAddress?.map(
                                    (address: ProfileAddress) => (
                                        <>
                                            <div
                                                className="options-container"
                                                style={{
                                                    border: errors.addressId
                                                        ? '1px solid red'
                                                        : '1px solid black',
                                                }}
                                            >
                                                <Radio
                                                    color={'default'}
                                                    style={{
                                                        width: '0px',
                                                        height: '0px',
                                                        margin: '15px',
                                                    }}
                                                    {...register('addressId', {
                                                        required:
                                                            history.location
                                                                .pathname ===
                                                            `/register-product/${categoryId.categorieId}/product`,
                                                    })}
                                                    onClick={() =>
                                                        setAdressId(address.id)
                                                    }
                                                    value={address?.id}
                                                    checked={
                                                        adressId === address.id
                                                    }
                                                />{' '}
                                                <span>
                                                    {`
                                                        ${address.CEP} - 
                                                        ${address.street} 
                                                        ${address.number} - 
                                                        ${address.city},
                                                        ${address.state}
                                                    `}
                                                </span>
                                            </div>
                                            {errors.addressId &&
                                                exibirErro(errors.addressId)}
                                        </>
                                    )
                                )}
                            </Container>
                        </Title>
                    </Route>
                    <Route exact path="/register-product/:categoryId/product">
                        <BtnConfirm>
                            <button className="delete-button">Cancelar</button>
                            <button className="confirm-button" type="submit">
                                Anunciar Produto
                            </button>
                        </BtnConfirm>
                    </Route>
                    <Route
                        exact
                        path="/register-product/:categoryId/properties"
                    >
                        <BtnConfirm>
                            <button className="delete-button">Cancelar</button>
                            <button className="confirm-button BotaoCadastrarConta">
                                Anunciar Imóvel
                            </button>
                        </BtnConfirm>
                    </Route>
                    <Route exact path="/register-product/:categoryId/vehicles">
                        <BtnConfirm>
                            <button className="delete-button">Cancelar</button>
                            <button className="confirm-button" type="submit">
                                Anunciar Veículo
                            </button>
                        </BtnConfirm>
                    </Route>
                </form>
            </PageContainer>
        </>
    )
}
export default RegisterProduct
