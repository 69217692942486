import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout/Layout'
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen'
import ProductCard from '../../components/ProductCard/ProductCard'
import {
    Product,
    useGetAllProductsLazyQuery,
    useGetAllProductsQuery,
    useGetAllPurchasesQuery,
    UserAccount,
} from '../../generated/graphql'
import styled from './styles.module.css'
const AdminProductsPage = (): JSX.Element => {
    const thirtiethDayBefore = moment().subtract(30, 'days')
    const { data, loading } = useGetAllProductsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            getAllProductInput: {},
        },
    })

    const { data: dataPurchases, loading: loadingPurchases } = useGetAllPurchasesQuery({
        fetchPolicy: 'cache-and-network',
    })

    const [searchProduct, { data: searchProductResponse }] =
        useGetAllProductsLazyQuery()
    const [items, setItems] = useState<Product[]>()
    const [purchases, setPurchases] = useState<any[]>([])
    const [sellers, setSellers] = useState<UserAccount[]>([])

    useEffect(() => {
        if (data && data.getAllProducts) {
            for (const { UserAccount: seller } of data.getAllProducts) {
                setSellers((s) => [...s, seller])
            }
            setItems(data.getAllProducts)
        }
    }, [data])

    useEffect(() => {
        if (dataPurchases && dataPurchases.getAllPurchases)
            setPurchases(dataPurchases.getAllPurchases)
    }, [dataPurchases])

    useEffect(() => {
        if (searchProductResponse?.getAllProducts) {
            setItems(searchProductResponse.getAllProducts)
        }
    }, [searchProductResponse])

    async function filterProducts(value: string): Promise<void> {
        searchProduct({
            variables: {
                getAllProductInput: {
                    offset: 0,
                    limit: 10,
                    text: value,
                    type: 'search',
                },
            },
        })
    }

    return (
        <Layout>
            {loading ? (
                <LoadingScreen />
            ) : (
                <div className={styled.container}>
                    <div className={styled.container_search}>
                        <h1 className={`${styled.search_title} tw-flex tw-flex-col tw-items-start`}>
                            <span>
                                {items &&
                                    `Produtos cadastrados (${items.length})`}
                            </span>
                            <span>
                                {items &&
                                    `Produtos ativos (${
                                        items.filter((item) => item.active)
                                            .length
                                    })`}
                            </span>
                            <span>
                                {sellers && items && !loading &&
                                    `Vendedores (${new Set(sellers).size})`}
                            </span>
                            <span>
                                {purchases && items && !loadingPurchases &&
                                    `Vendas concluídas nos últimos 30 dias (${
                                        purchases.filter((purchase) => {
                                            return moment(
                                                purchase.updatedAt
                                            ).isSameOrAfter(thirtiethDayBefore)
                                        }).length
                                    })`}
                            </span>
                        </h1>
                        <input
                            className={styled.serch}
                            type="text"
                            onChange={(e) => filterProducts(e.target.value)}
                            placeholder=" Busque um produto por título, estado, material , marca ou tipo de negociação."
                            style={{ border: '1px solid black' }}
                        />
                    </div>
                    <div className={styled.container_itens}>
                        {items &&
                            items.map((product: Product) => {
                                return (
                                    <div
                                        className={styled.item}
                                        key={product && product.id}
                                    >
                                        <ProductCard product={product} />
                                    </div>
                                )
                            })}
                    </div>
                </div>
            )}
        </Layout>
    )
}

export default AdminProductsPage
