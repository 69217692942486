import { ProfileAddress } from '../generated/graphql';
import IUserAccount, {
	IRemoveUserAction,
	IUpdateUserAction,
	IUpdateBankAccountAction,
	IInsertUserAddress,
	IInserPurchaseCardId,
	ISetDeliveryAddress,
	IInsertPurchaseAction,
	UserPurchases,
	IRemovePurchaseCardId,
	IUpdateNotReadNotifications,
	IUpdateMelhorEnvioPaymentMethodAction,
	IRemoveMelhorEnvioPaymentMethodAction,
	IUpdateMelhorEnvioTokenAction,
	IupdateUserAddress, IInsert_form_of_payment
} from '../models/IUserAccount';

export const updateBankIdUser = (
	bankAccountId: string
): IUpdateBankAccountAction => ({
	type: 'UPDATE_BANK_ACCOUNT',
	bankAccountId,
});

export const updateUserAddress = (
	userAddres:ProfileAddress[]
): IupdateUserAddress =>({
	type:'UPDATE_USER_ADDRESS',
	userAddres
})

export const removeCardId = (): IRemovePurchaseCardId => ({
	type: 'REMOVE_USER_PURCHASE_CARD_ID',
});

export const setDeliveryAddress = (
	addressId: number,
	bool: boolean
): ISetDeliveryAddress => ({
	type: 'SET_DELIVERY_ADDRESS',
	addressId,
	bool,
});

export const insertUserAddress = (
	address: ProfileAddress
): IInsertUserAddress => ({
	type: 'INSERT_USER_ADDRESS',
	address,
});
export const insertPurchaseCardId = (cardId: string): IInserPurchaseCardId => ({
	type: 'INSERT_USER_PURCHASE_CARD_ID',
	cardId,
});

export const inserFormOfPagament = (
	formaDePagamento:string
): IInsert_form_of_payment =>({
	type:'INSERT_FORM_OF_PAYMENT',
	formaDePagamento
})

export const updateUser = (user: IUserAccount): IUpdateUserAction => ({
	type: 'UPDATE_USER',
	user,
});

export const removeUser = (): IRemoveUserAction => ({
	type: 'REMOVE_USER',
});
export const insertPurchase = (
	UserPurchases: UserPurchases
): IInsertPurchaseAction => ({
	type: 'INSERT_PURCHASE',
	UserPurchases,
});
export const updateReadNotifications = (
	readNotification: boolean
): IUpdateNotReadNotifications => ({
	type: 'UPDATE_NOT_READ_NOTIFICATIONS',
	readNotification,
});
export const updateMelhorEnvioPaymentMethod = (
	paymentMethod: string | null
): IUpdateMelhorEnvioPaymentMethodAction => ({
	type: 'UPDATE_MELHOR_ENVIO_PAYMENT_METHOD',
	paymentMethod,
});

export const removeMelhorEnvioPaymentMethod = (): IRemoveMelhorEnvioPaymentMethodAction => ({
	type: 'REMOVE_MELHOR_ENVIO_PAYMENT_METHOD',
});

export const updateMelhorEnvioToken = (
	token: string
): IUpdateMelhorEnvioTokenAction => ({
	type: 'UPDATE_MELHOR_ENVIO_TOKEN',
	token,
});
