import styled from 'styled-components';

export const CategoriesBar = styled.div`
	//Código mostrando o menu de categorias para desktop.
	background-color: #fff;
	box-shadow: 0px 2px 8px #00000014;
	position: absolute;
	border-radius: 8px;
	padding: 15px;
	overflow: auto;
	height: 400px;
	z-index: 1;
	width: 12%;
    top: 18.5rem;
    left: 21%;
	.section-category-bar{
		display: flex;
		flex-direction: column;
		.section-options-category-bar{
			display: flex;
			justify-content: center;
			flex-direction: column;
			.title-section-category-bar{
				font-size: 16px;
				font-family: 'Poppins';
				font-weight: 700;
				display: flex;
				flex-direction: center;
				justify-content: center;
				color: #6b97a5;
				margin-bottom: 10px;
			}
			.option-category-bar{
				justify-content: center;
				display: flex;
				height: 45px;
				align-items: center;
				font-family: 'Poppins';
				font-size: 14px;
				font-weight: 400;
				color: black;
				cursor: pointer;
			}
			.last-option-category-bar{
				justify-content: center;
				display: flex;
				height: 45px;
				align-items: center;
				font-family: 'Poppins';
				font-size: 14px;
				font-weight: 400;
				border-bottom: 1px solid black;
				color: black;
				cursor: pointer;
			}
			.section-options-category-bar-2{
				font-size: 16px;
				font-family: 'Poppins';
				font-weight: 700;
				display: flex;
				flex-direction: center;
				justify-content: center;
				color: #6b97a5;
				margin-top: 10px;
			}
			.option-category-bar-2{
				justify-content: center;
				display: flex;
				height: 40px;
				align-items: center;
				font-family: 'Poppins';
				font-size: 13px;
				font-weight: 400;
				color: black;
				cursor: pointer;
			}
		}
	}
	//Código mostrando o menu de categorias para Tablet.
	@media (min-width:768px) and (max-width:1022px){
		background-color: #fff;
		box-shadow: 0px 2px 8px #00000014;
		position: absolute;
		border-radius: 8px;
		padding: 5px;
		overflow: auto;
		height: 400px;
		z-index: 1;
		width: 22%;
		top: 10.5rem;
		left: 8%;
		.section-category-bar{
		display: flex;
		flex-direction: column;
		.section-options-category-bar{
			display: flex;
			justify-content: center;
			flex-direction: column;
			.title-section-category-bar{
				font-size: 16px;
				font-family: 'Poppins';
				font-weight: 700;
				display: flex;
				flex-direction: center;
				justify-content: center;
				color: #6b97a5;
				margin-bottom: 5px;
			}
			.option-category-bar{
				justify-content: center;
				display: flex;
				height: 35px;
				align-items: center;
				font-family: 'Poppins';
				font-size: 13px;
				font-weight: 400;
				cursor: pointer;
				color: black;
			}
			.last-option-category-bar{
				justify-content: center;
				display: flex;
				height: 35px;
				align-items: center;
				font-family: 'Poppins';
				font-size: 13px;
				font-weight: 400;
				border-bottom: 1px solid black;
				color: black;
				cursor: pointer;
			}
			.section-options-category-bar-2{
				font-size: 13px;
				font-family: 'Poppins';
				font-weight: 700;
				display: flex;
				flex-direction: center;
				justify-content: center;
				color: #6b97a5;
				margin-top: 10px;
				margin-bottom: 5px;
			}
			.option-category-bar-2{
				justify-content: center;
				display: flex;
				height: 35px;
				align-items: center;
				font-family: 'Poppins';
				font-size: 13px;
				font-weight: 400;
				cursor: pointer;
				color: black;
			}
		}
		}
	}

	//Código mostrando o menu de categorias para mobile.
	@media (max-width:767px){
		background-color: #fff;
		box-shadow: 0px 2px 8px #00000014;
		position: absolute;
		border-radius: 8px;
		padding: 5px;
		overflow: auto;
		height: 400px;
		z-index: 1;
		width: 30%;
   		top: 6.5rem;
   		left: 1%;
		.section-category-bar{
		display: flex;
		flex-direction: column;
		.section-options-category-bar{
			display: flex;
			justify-content: center;
			flex-direction: column;
			.title-section-category-bar{
				font-size: 11px;
				font-family: 'Poppins';
				font-weight: 700;
				display: flex;
				flex-direction: center;
				justify-content: center;
				color: #6b97a5;
				margin-bottom: 5px;
			}
			.option-category-bar{
				justify-content: center;
				display: flex;
				height: 25px;
				align-items: center;
				font-family: 'Poppins';
				font-size: 11px;
				font-weight: 400;
				cursor: pointer;
				color: black;
			}
			.last-option-category-bar{
				justify-content: center;
				display: flex;
				height: 25px;
				align-items: center;
				font-family: 'Poppins';
				font-size: 11px;
				font-weight: 400;
				border-bottom: 1px solid black;
				color: black;
				cursor: pointer;
			}
			.section-options-category-bar-2{
				font-size: 11px;
				font-family: 'Poppins';
				font-weight: 700;
				display: flex;
				flex-direction: center;
				justify-content: center;
				color: #6b97a5;
				margin-top: 5px;
				margin-bottom: 5px;
			}
			.option-category-bar-2{
				justify-content: center;
				display: flex;
				height: 25px;
				align-items: center;
				font-family: 'Poppins';
				font-size: 11px;
				font-weight: 400;
				color: black;
				cursor: pointer;
			}
		}
		}
	}
	
`;
