import React from 'react';

import { Container, MainCard, PageContainer, PageHeader } from './styles';
import back from '../../assets/icons/back.svg';
import aboutUsLogo from '../../assets/aboutUsLogo.svg';
import AboutUsItem from './AboutUsItem/AboutUsItem';
import { Link } from 'react-router-dom';

const AboutUs: React.FC = () => {
	return (
		<PageContainer>
			<Container>
				<PageHeader>
						<Link to='/'><img src={back} alt=""/></Link>
						<Link to='/'><h3>Quem somos</h3></Link>
				</PageHeader>
				<MainCard>
					<img src={aboutUsLogo} alt="" />
					<div className="items-container">
						<AboutUsItem
							title="Quem somos?"
							description="O Sambay&#174; é uma plataforma de vendas moderna e inovadora, com DNA brasileiro."
						/>
						<AboutUsItem
							title="O que fazemos?"
							description="Aproximamos vendedores e compradores em uma plataforma inovadora, com tarifas justas e regras claras."
						/>
						<AboutUsItem
							title="Como fazemos?"
							description="Com uma equipe que une profissionais de diversas áreas, buscando a inovação constante, com ferramentas exclusivas e apoio a causas sociais."
						/>
						<AboutUsItem
							title="Para quem fazemos?"
							description="O Sambay&#174; é destinado a todos os usuários de compras on-line, desde o pequeno artesão até o grande varejista."
						/>
						<AboutUsItem
							title="Por que fazemos?"
							description="Porque acreditamos que os marketplaces podem (e devem!) ser mais democráticos, justos e responsáveis."
						/>
					</div>
				</MainCard>
			</Container>
		</PageContainer>
	);
};

export default AboutUs;
