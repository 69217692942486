import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addUserList } from '../../actions/AllUsersAction';
import { useUserAccountFilterLazyQuery } from '../../generated/graphql';
import AccountsCard from './AccountsCard/AccountsCard';
import FilterOptionItem from './FilterOptionItem/FilterOptionItem';
import NewSocialProjectsModal, {
	ModalNewSocialProjectHandles,
} from './NewSocialProjectModal/NewSocialProjectsModal';
import NewUserModal, { ModalNewUserHandles } from './NewUserModal/NewUserModal';
import { Container, FilterCard, FilterAndButtonContainer } from './styles';

interface Filters {
	includeSearch: boolean;
	searchValue?: string | null;
	name: string[];
}

const AdmRegisterCard: React.FC = () => {
	const [
		getUsersFiltered,
		{ data: dataFiltered, loading: loadingFilters, error },
	] = useUserAccountFilterLazyQuery({});
	const modalRef = useRef<ModalNewUserHandles>(null);
	const newSocialProjectModal = useRef<ModalNewSocialProjectHandles>(null);
	const [stateFilters, setStateFilters] = useState<Filters>({
		includeSearch: false,
		name: [],
	});
	const dispatch = useDispatch();

	const openModal = useCallback(() => {
		modalRef.current?.showModal();
	}, [modalRef]);
	const openNewSocialProjectModal = useCallback(() => {
		newSocialProjectModal.current?.openModal();
	}, [newSocialProjectModal]);
	const InputRef = useRef<HTMLInputElement>(null);

	const changeSelectedOption = (registerType: string) => {
		const findFilter = stateFilters.name.find(
			(name: string) => name === registerType
		);

		if (!findFilter) {
			setStateFilters({
				includeSearch: stateFilters.includeSearch,
				searchValue: stateFilters.searchValue,
				name: [...stateFilters.name, registerType],
			});
		} else {
			setStateFilters({
				includeSearch: stateFilters.includeSearch,
				searchValue: stateFilters.searchValue,
				name: stateFilters.name.filter(
					(name: string) => name !== registerType
				),
			});
		}
		applyFilters(stateFilters.name);
	};
	const applyFilters = (name: string[]) => {
		if (InputRef?.current?.value) {
			try {
				getUsersFiltered({
					variables: {
						includeSearch: true,
						searchValue: InputRef?.current?.value,
						name: name,
					},
				});

				dispatch(addUserList(dataFiltered?.userAccountFilter as any));
			} catch (err: any) {}
		} else {
			try {
				getUsersFiltered({
					variables: {
						includeSearch: false,
						searchValue: InputRef?.current?.value,
						name: name,
					},
				});

				dispatch(addUserList(dataFiltered?.userAccountFilter as any));
			} catch (err: any) {}
		}
	};
	useEffect(() => {
		dispatch(addUserList(dataFiltered?.userAccountFilter as any));
	}, [dataFiltered]);

	useEffect(() => {
		applyFilters(stateFilters.name);
	}, [stateFilters.name]);

	return (
		<>
			<Container>
				<FilterAndButtonContainer>
					<FilterCard>
						<h2>Filtros</h2>

						<FilterOptionItem
							active={
								stateFilters.name.length == 0 ? true : false
							}
							onClick={() => {
								setStateFilters({
									includeSearch: stateFilters.includeSearch,
									searchValue: stateFilters.searchValue,
									name: [],
								});
							}}
						>
							Todos
						</FilterOptionItem>
						<FilterOptionItem
							active={
								stateFilters.name.find(
									(name: string) => name === 'Compradores'
								)
									? true
									: false
							}
							onClick={() => changeSelectedOption('Compradores')}
						>
							Compradores
						</FilterOptionItem>
						
						<FilterOptionItem
							active={
								stateFilters.name.find(
									(name: string) => name === 'ProjetosSociais'
								)
									? true
									: false
							}
							onClick={() =>
								changeSelectedOption('ProjetosSociais')
							}
						>
							Projetos Sociais
						</FilterOptionItem>
					</FilterCard>
					<button
						onClick={() => openModal()}
						className="BotaoCadastrarConta"
					>
						Cadastrar nova conta
					</button>

					<button
						onClick={() => openNewSocialProjectModal()}
						className="BotaoCadastrarProjetoSocial"
					>
						Cadastrar Projeto Social
					</button>
					<NewUserModal ref={modalRef} />
					<NewSocialProjectsModal ref={newSocialProjectModal} />
				</FilterAndButtonContainer>
				<AccountsCard
					ref={InputRef}
					onChange={() => applyFilters(stateFilters.name)}
				/>
			</Container>
		</>
	);
};

export default AdmRegisterCard;
