import {IModalBurgerState,ModalBurgerAction} from '../models/IModalBurger'

const initialState:IModalBurgerState = {
    openModal:false
}


export default function ModalBurgerReducer(state = initialState ,action:ModalBurgerAction){

    switch(action.type){
        case 'UPDATE_STATUS_MODAL':
        return {...state,showModal:action.status}

        default:
        return state;

    }
}