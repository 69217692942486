import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { ApolloProvider } from '@apollo/react-hooks'
import * as serviceWorker from './serviceWorker'
import { createUploadLink } from 'apollo-upload-client'
import { ApolloClient, InMemoryCache } from 'apollo-boost'

import { ApolloLink, Observable } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import jwtDecode from 'jwt-decode'
import { handleLogout } from './handlers/handleLogout'

const link = createUploadLink({
    uri: `${process.env.REACT_APP_API_URL}/graphql`,
    credentials: 'include'
})
const cache = new InMemoryCache({})

const requestLink = new ApolloLink(
    (operation, forward) =>
        new Observable((observer) => {
            let handle: any
            Promise.resolve(operation)
                .then((operation) => {
                    const accessToken = localStorage.getItem('token')
                    if (accessToken) {
                        operation.setContext({
                            headers: {
                                authorization: `bearer ${accessToken}`
                            }
                        })
                    }
                })
                .then(() => {
                    handle = forward(operation).subscribe({
                        next: observer.next.bind(observer),
                        error: observer.error.bind(observer),
                        complete: observer.complete.bind(observer)
                    })
                })
                .catch(observer.error.bind(observer))

            return () => {
                if (handle) handle.unsubscribe()
            }
        })
)

const client = new ApolloClient({
    link: ApolloLink.from([
        new TokenRefreshLink({
            accessTokenField: 'accessToken',
            isTokenValidOrUndefined: () => {
                const token = localStorage.getItem('token')

                if (!token) {
                    return true
                }
                try {
                    const { exp } = jwtDecode(token)
                    return !(Date.now() >= exp * 1000)
                } catch {
                    return false
                }
            },
            fetchAccessToken: () => {
                return fetch(
                    `${process.env.REACT_APP_API_URL}/refresh_token`,
                    {
                        method: 'POST',
                        credentials: 'include'
                    }
                )
            },
            handleFetch: (accessToken: string) => {
                localStorage.setItem('token', accessToken)
                /* setAccessToken(accessToken); */
            },
            handleError: async (err) => {
                console.warn(
                    'Your refresh token is invalid. Try to relogin'
                )
                handleLogout(true)
                console.error(err)
            }
        }),
        onError(({ graphQLErrors, networkError }) => {
            console.log(graphQLErrors)
            console.log(networkError)
            // eslint-disable-next-line array-callback-return
            graphQLErrors?.map(({ message }) => {
                if (message === 'not authenticated') handleLogout(true)
            })
            if (networkError?.message === 'not authenticated') {
                handleLogout(true)
            }
        }),
        requestLink,
        link
    ]),
    cache
})

ReactDOM.render(
    <ApolloProvider client={client}>
        <App />
    </ApolloProvider>,
    document.querySelector('#root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
