import { CheckpointActions, CheckpointState } from '../models/Checkpoint';

const initialState: CheckpointState = {
	currentCheckpoint: undefined,
};

export default function CheckpointReducer(
	state = initialState,
	action: CheckpointActions
) {
	switch (action.type) {
		case 'UPDATE_CHECKPOINT_PATH':
			return {
				...state,
				currentCheckpoint: {
					...state.currentCheckpoint,
					path: action.path,
				},
			};
		default:
			return state;
	}
}
