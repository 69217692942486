import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	.graph {
		width: 1400px;
		height: 800px;

		@media screen and (max-width: 1366px) {
			width: 800px;
			height: 400px;
		}

		@media (min-width:425px) and (max-width:767px){
			display: none;
		}
		@media (min-width:375px) and (max-width:424px){
			display: none;
		}
		@media (min-width:320px) and (max-width:374px){
			display: none;
		}
	}
`;