import styled from 'styled-components';
export const AnnouceHeader = styled.div`
	h1{
		display:none;
	}
		@media (min-width:425px) and (max-width:767px){
			h1{
				color: #6b97a5;
				font-family: 'Poppins', semi-bold;
				font-weight: bold;
				font-size: 16px!important;
				display: flex;
    			text-align: center;
				justify-content: center;
				align-items: center;
				padding-top:2.5rem;
			}
			
		}
		@media (min-width:375px) and (max-width:424px){
			h1{
				color: #6b97a5;
				font-family: 'Poppins', semi-bold;
				font-weight: bold;
				font-size: 16px;
				display: flex;
    			text-align: center;
				justify-content: center;
				align-items: center;
				padding-top:2.5rem;
			}
			
		}
		@media (min-width:320px) and (max-width:474px){
			h1{
				color: #6b97a5;
				font-family: 'Poppins', semi-bold;
				font-weight: bold;
				font-size: 13px;
				display: flex;
    			text-align: center;
				justify-content: center;
				align-items: center;
				padding-top:2.5rem;
				background-color:white;
			}
			
		}
		
	}
	
`;

export const Back = styled.div`
	height: 9%;
	width: 100%;
	display: flex;
	background-color: #fff;

	.back-container {
		padding: 30px;
		padding-left: 100px;
		display: flex;
		flex-direction: row;
		align-items: center;

		span {
			margin-left: 14px;
			font-size: 26px;
			font-family: 'Poppins', semi-bold;
			font-weight: bold;
			color: #6b97a5;
			cursor: pointer;
		}

		img {
			width: 11px;
			height: 18px;
		}
		img:hover {
			cursor: pointer;
		}
	}

	@media (min-width:425px) and (max-width:767px){
		.back-container {
			padding-top: 20px;
			padding-left: 20px;
			padding-bottom: 0px;
			span{
				font-size:20px;
			}
		}
	}
	@media (min-width:375px) and (max-width:424px){
		.back-container {
			padding-top: 20px;
			padding-left: 20px;
			padding-bottom: 0px;
			font-size:20px;
			span{
				font-size:20px;
			}
		}
	}
	@media (min-width:320px) and (max-width:374px){
		.back-container {
			padding-top: 20px;
			padding-left: 20px;
			padding-bottom: 0px;
			font-size:20px;
			span{
				font-size:20px;
			}
		}
	}
`;
export const RegisterMenuContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px 120px;
	padding-top: 30px;
	padding-bottom: 30px;
	background-color:#fff;
	.page-title {
		width: 869px;
		display: flex;
		flex-direction: column;
		/* align-items: center; */
		justify-content: flex-start;
		margin-bottom: 20px;

		h1 {
			color: #6b97a5;
			font-family: 'Poppins', semi-bold;
			font-weight: bold;
			font-size: 18px;
		}
		p {
			width: 100%;
			font-size: 13.5px;
			font-family: 'Poppins', semi-bold;
		}
		span {
			color: #6085db;
			cursor: pointer;
			text-decoration: underline;
		}
	}

	@media (min-width:425px) and (max-width:475px){
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		padding-left:0;
		.page-title{
			display:none;
		}
		h1 {
			color: #6b97a5;
			font-family: 'Poppins', semi-bold;
			font-weight: bold;
			font-size: 16px;
			padding:0;
		}
		p{
			display:none;
		}
	}
	@media (min-width:375px) and (max-width:424px){
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		padding-bottom: 20px;
		padding-left:0;
		.page-title{
			display:none;
		}
		h1 {
			color: #6b97a5;
			font-family: 'Poppins', semi-bold;
			font-weight: bold;
			font-size: 16px;
			padding:0;
		}
		p{
			display:none;
		}
	}
	@media (min-width:320px) and (max-width:374px){
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		padding-bottom: 20px;
		padding-left:0;
		.page-title{
			display:none;
		}
		h1 {
			color: #6b97a5;
			font-family: 'Poppins', semi-bold;
			font-weight: bold;
			font-size: 16px;
			padding:0;
		}
		p{
			display:none;
		}
	}
`;

export const RegisterMenu = styled.div`
	display: flex;
	flex-direction: row;
	width: 869px;
	justify-content: space-between;

	.item {
		width: 257px;
		height: 66px;
		background: transparent;
		color: #6b97a5;
		font-size: 14px;
		border: solid 1px black;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		font-family: 'Poppins';
		font-weight: bold;
		border-radius: 8px;
	}

	.active-item {
		width: 257px;
		height: 66px;
		background-color: #6b97a5;
		color: white;
		border: solid 1px black;
		font-size: 14px;
		font-family: 'Poppins';
		font-weight: bold;
		border-radius: 8px;
	}
	@media (min-width:425px) and (max-width:767px){
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		column-gap: 13px;
		padding-left: 3.5rem;

	.item {
		width: 98px;
		height: 66px;
		background: transparent;
		color: #6b97a5;
		font-size: 12px;
		border: solid 1px black;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		font-family: 'Poppins';
		font-weight: bold;
		border-radius: 8px;
		text-align: center;
	}

	.active-item {
		width: 98px;
		height: 66px;
		background-color: #6b97a5;
		color: white;
		border: solid 1px black;
		font-size: 12px;
		font-family: 'Poppins';
		font-weight: bold;
		border-radius: 8px;
		text-align: center;
		}
	}
	@media (min-width:375px) and (max-width:424px){
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		column-gap: 13px;
		padding-left: 1.8rem;

	.item {
		width: 98px;
		height: 66px;
		background: transparent;
		color: #6b97a5;
		font-size: 12px;
		border: solid 1px black;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		font-family: 'Poppins';
		font-weight: bold;
		border-radius: 8px;
		text-align: center;
	}

	.active-item {
		width: 98px;
		height: 66px;
		background-color: #6b97a5;
		color: white;
		border: solid 1px black;
		font-size: 12px;
		font-family: 'Poppins';
		font-weight: bold;
		border-radius: 8px;
		text-align: center;
		}
	}
	@media (min-width:320px) and (max-width:374px){
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		column-gap: 13px;
		padding-left: 1.8rem;

	.item {
		width: 80px;
    	height: 60px;
		background: transparent;
		color: #6b97a5;
		font-size: 12px;
		border: solid 1px black;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		font-family: 'Poppins';
		font-weight: bold;
		border-radius: 8px;
		text-align: center;
	}

	.active-item {
		width: 80px;
    	height: 60px;
		background-color: #6b97a5;
		color: white;
		border: solid 1px black;
		font-size: 12px;
		font-family: 'Poppins';
		font-weight: bold;
		border-radius: 8px;
		text-align: center;
		}
	}
`;
