type ReturnObject = {
    list: unknown[]
    errors: Error[]
}

export const arrayLengthLimit = 12
const fileSizeLimit = 2_097_152
const validMimeTypes = new Set([
    'image/png',
    'image/jpeg',
    'image/tiff',
    'image/webp',
])

const MAX_FILE_SIZE_ERROR = `MAX_FILE_SIZE_ERROR`
export const maxFileSizeMessage = `Limite de ${
    fileSizeLimit / 1024 / 1024
} megabytes por imagem`
const MAX_PUBLICATION_IMAGE_LIMIT_ERROR = `MAX_PUBLICATION_IMAGE_LIMIT_ERROR`
export const maxPublicationImageLimitMessage = `Limite de ${arrayLengthLimit} imagens por anúncio`
const INVALID_PUBLICATION_IMAGE_TYPE_ERROR = `INVALID_PUBLICATION_IMAGE_TYPE_ERROR`
export const invalidPublicationImageTypeMessage = `Somente formatos "png", "jpeg", "tiff" e "webp"`

const appendFiles = (fileList: FileList, array: unknown[]): ReturnObject => {
    const files = [...fileList]
    let list = [...array]
    let errors: Error[] = []

    for (const file of files) {
        if (list.length < arrayLengthLimit) {
            if (validMimeTypes.has(file.type)) {
                if (file.size < fileSizeLimit) {
                    list = [...list, file]
                } else {
                    errors = [
                        ...errors,
                        {
                            name: MAX_FILE_SIZE_ERROR,
                            message: `${file.name} não pode ser enviado: ${maxFileSizeMessage}`,
                        },
                    ]
                }
            } else {
                errors = [
                    ...errors,
                    {
                        name: INVALID_PUBLICATION_IMAGE_TYPE_ERROR,
                        message: `${file.name} não pode ser enviado: ${invalidPublicationImageTypeMessage}`,
                    },
                ]
            }
        } else {
            errors = [
                ...errors,
                {
                    name: MAX_PUBLICATION_IMAGE_LIMIT_ERROR,
                    message: `${file.name} não pode ser enviado: ${maxPublicationImageLimitMessage}`,
                },
            ]
        }
    }
    return { list, errors }
}

export default appendFiles
