import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import IUserAccount from '../../models/IUserAccount'
import { AppState } from '../../store'
import ProfileInput from './ProfileInput/ProfileInput'
import moment from 'moment'
import defaultImage from '../../assets/default.svg'
import {
    ProfileContainer,
    ProfileCard,
    InformationsCard,
    ActiveButtonContainer,
    EditButtonContainer,
} from './styles'
import { useForm } from 'react-hook-form'
import { useCreateUserImageMutation } from '../../generated/graphql'

interface FormInputs {
    CPF: string
    dateBirth: string
    email: string
    phone: string
    address: string
    city: string
}

const AdmProfile: React.FC = () => {
    const user: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )

    const [disableInputs, setDisableInputs] = useState<boolean>(true)
    const { register, handleSubmit } = useForm<FormInputs>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
    })
    const [createUserImage] = useCreateUserImageMutation()
    const dispatch = useDispatch()

    const showDefaultImage = () => {
        setUserImage(
            <>
                <img src={defaultImage} alt="" className="user-image" />
            </>
        )
    }
    const [userImage, setUserImage] = useState<any>(
        <>
            <img
                src={`${process.env.REACT_APP_STATIC_URL}/user/${user.id}.jpeg`}
                alt=""
                className="user-image"
                onError={showDefaultImage}
            />
        </>
    )
    const handlePhoto = async (file: any) => {
        try {
            await createUserImage({
                variables: { userId: user.id, Picture: file },
            })
            window.location.reload()
        } catch (err: any) {}
    }
    return (
        <ProfileContainer>
            <form
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <ProfileCard>
                    <InformationsCard>
                        <figure className="user-photo">
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                id="user-input"
                                onChange={(e: any) =>
                                    handlePhoto(e.target.files[0])
                                }
                            />
                            <label htmlFor="user-input">{userImage}</label>
                        </figure>
                        <div className="user-informations-container">
                            <section className="user-name-and-type">
                                <h2> {user?.name} </h2>
                                <div className="user-type">
                                    <h3> {user?.UserType?.name} </h3>
                                    <span>
                                        desde{' '}
                                        {moment(user?.createdAt).format(
                                            'DD/MM/YYYY'
                                        )}{' '}
                                    </span>
                                </div>
                            </section>
                            <section className="user-informations">
                                <div className="first-line">
                                    <ProfileInput
                                        type="number"
                                        label="CPF"
                                        classStyle={`input-and-label-container ${
                                            !disableInputs ? 'enabled' : ''
                                        }`}
                                        {...register('CPF', { required: true })}
                                        disabled={disableInputs}
                                        defaultValue={user.cpf as any}
                                    />
                                    <ProfileInput
                                        type="text"
                                        label="Data de nascimento"
                                        {...register('dateBirth', {
                                            required: true,
                                        })}
                                        classStyle={`input-and-label-container ${
                                            !disableInputs ? 'enabled' : ''
                                        }`}
                                        disabled={disableInputs}
                                        defaultValue={
                                            user.dateBirth
                                                ? (moment(
                                                      user.dateBirth
                                                  ).format('DD/MM/YYYY') as any)
                                                : ''
                                        }
                                    />
                                    <ProfileInput
                                        type="email"
                                        {...register('email', {
                                            required: true,
                                        })}
                                        label="E-mail"
                                        classStyle={`input-and-label-container ${
                                            !disableInputs ? 'enabled' : ''
                                        }`}
                                        disabled={disableInputs}
                                        defaultValue={user.email as any}
                                    />
                                    <ProfileInput
                                        type="number"
                                        {...register('phone', {
                                            required: false,
                                        })}
                                        label="Telefone"
                                        classStyle={`input-and-label-container ${
                                            !disableInputs ? 'enabled' : ''
                                        }`}
                                        disabled={disableInputs}
                                        defaultValue={
                                            user!.ProfileContacts![0]!
                                                .phone as any
                                        }
                                    />
                                </div>
                                <div className="second-line">
                                    <ProfileInput
                                        type="text"
                                        {...register('address', {
                                            required: false,
                                        })}
                                        label="Endereço"
                                        classStyle={`input-and-label-container ${
                                            !disableInputs ? 'enabled' : ''
                                        }`}
                                        disabled={disableInputs}
                                        defaultValue={
                                            user!.ProfileAddress![0]!
                                                .street as any
                                        }
                                    />
                                    <ProfileInput
                                        type="text"
                                        {...register('city', {
                                            required: false,
                                        })}
                                        label="Cidade"
                                        classStyle={`input-and-label-container ${
                                            !disableInputs ? 'enabled' : ''
                                        }`}
                                        disabled={disableInputs}
                                        defaultValue={
                                            user!.ProfileAddress![0]!
                                                .city as any
                                        }
                                    />
                                </div>
                            </section>
                        </div>
                    </InformationsCard>
                    <ActiveButtonContainer>
                        <div className="account-status">Conta ativa</div>
                    </ActiveButtonContainer>
                </ProfileCard>
                <EditButtonContainer>
                    {disableInputs && (
                        <button
                            type="button"
                            onClick={() => setDisableInputs(false)}
                        >
                            Editar
                        </button>
                    )}
                    {!disableInputs && <button type="submit">Salvar</button>}
                </EditButtonContainer>
            </form>
        </ProfileContainer>
    )
}

export default AdmProfile
