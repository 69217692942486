import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import { InsertOneCard } from '../../../actions/UserCardsActions'
import { useCreateCardMutation } from '../../../generated/graphql'
import useWindow from '../../../hooks/useWindow'
import IUserAccount from '../../../models/IUserAccount'
import IUserCards from '../../../models/IUserCards'
import { AppState } from '../../../store'
import { setCpfMaskFunc } from '../../../utils/Masks'
import { ModalContainer, ButtonContainer } from './Style'

const AddCardModal: React.FC = () => {
    const window = useWindow()
    const [state, setState] = useState({ dimmer: undefined, open: false })
    const show = (dimmer: any) => setState({ dimmer: dimmer, open: true })
    const [cardName, setCardName] = useState<string>('')
    const [cardDateBirth, setCardDateBirth] = useState<string>('')
    const [cardPhone, setCardPhone] = useState<string>('')
    const [cardCpf, setCardCpf] = useState<string>('')
    const [cardNumber, setCardNumber] = useState<string>('')
    const [cardValid, setCardValid] = useState<string>('')
    const [cardCvv, setCardCvv] = useState<string>('')
    const [cardValidMonth, setCardValidMonth] = useState<any>()
    const [cardValidYear, setCardValidYear] = useState<any>()
    const [createCardMutation] = useCreateCardMutation()
    const dispatch = useDispatch()
    const convertDate = (date: any) => {
        setCardDateBirth(moment(date).format('YYYY-MM-DD'))
    }
    const user: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )
    const userCards: IUserCards[] = useSelector(
        (state: AppState) => state.UserCards.currentCards
    )

    const createCard = async () => {
        if (
            cardName &&
            cardDateBirth &&
            cardPhone &&
            cardCpf &&
            cardNumber &&
            cardValidMonth &&
            cardValidYear &&
            cardCvv
        ) {
            try {
                const result = await createCardMutation({
                    variables: {
                        userId: user.id,
                        card_holder_name: cardName,
                        card_cvv: cardCvv,
                        card_expiration_date: cardValidMonth + cardValidYear,
                        card_number: cardNumber,
                        cpf: cardCpf
                            .replace('.', '')
                            .replace('/', '')
                            .replace('-', ''),
                    },
                })
                dispatch(InsertOneCard(result.data?.createCard))
                setState({ dimmer: undefined, open: false })
            } catch (err: any) {
                alert('erro ao cadastrar o cartão')
            }
        } else {
            alert('Preencha todos os campos')
        }
    }

    return (
        <ButtonContainer>
            <div className="button-container">
                <button
                    onClick={() => show('blurring')}
                    className="button-add-new-card"
                >
                    Adicionar Novo Cartão
                </button>
                <Modal
                    dimmer={state.dimmer}
                    open={state.open}
                    onClose={() => state.open === false}
                >
                    <ModalContainer>
                        <div className="modal-title">
                            <h2>Cadastro de cartão</h2>
                        </div>
                        <div className="line"></div>
                        <div className="modal-inputs">
                            <div className="first-line">
                                <div className="modal-inputs-organizer">
                                    <label>Nome</label>
                                    <input
                                        type="text"
                                        onChange={(e) =>
                                            setCardName(e.target.value)
                                        }
                                    ></input>
                                </div>
                            </div>
                            <div className="second-line">
                                <div
                                    className="modal-inputs-organizer"
                                    style={{ flex: '2' }}
                                >
                                    <label>Data de nascimento</label>
                                    <input
                                        type="text"
                                        placeholder="Ex: 01/01/2000"
                                        onChange={(e) =>
                                            convertDate(e.target.value)
                                        }
                                    ></input>
                                </div>

                                <div
                                    className="modal-inputs-organizer"
                                    style={{ flex: '2' }}
                                >
                                    <label>Telefone</label>
                                    <input
                                        type="text"
                                        placeholder="xx-xxxxx-xxxx"
                                        onChange={(e) =>
                                            setCardPhone(e.target.value)
                                        }
                                    ></input>
                                </div>

                                <div
                                    className="modal-inputs-organizer"
                                    style={{ flex: '3' }}
                                >
                                    <label>CPF/CNPJ</label>
                                    <input
                                        type="text"
                                        value={cardCpf}
                                        placeholder="xxx.xxx.xxx-xx"
                                        onChange={(e) => {
                                            setCardCpf(
                                                setCpfMaskFunc(e.target.value)
                                            )
                                        }}
                                    ></input>
                                </div>
                            </div>
                            <div className="third-line">
                                <div
                                    className="modal-inputs-organizer"
                                    style={{ flex: '5' }}
                                >
                                    <label>Numero do cartão:</label>
                                    <input
                                        type="text"
                                        onChange={(e) =>
                                            setCardNumber(e.target.value)
                                        }
                                        maxLength={16}
                                    ></input>
                                </div>

                                {window.innerWidth > 475 ? (
                                    <>
                                        <div
                                            className="modal-inputs-organizer"
                                            style={{ flex: '1' }}
                                        >
                                            <label>Validade</label>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <select
                                                    onChange={(e) =>
                                                        setCardValidMonth(
                                                            e.target.value
                                                        )
                                                    }
                                                    style={{
                                                        marginRight: '25px',
                                                    }}
                                                >
                                                    <option value="01">
                                                        Janeiro
                                                    </option>
                                                    <option value="02">
                                                        Fevereiro
                                                    </option>
                                                    <option value="03">
                                                        Março
                                                    </option>
                                                    <option value="04">
                                                        Abril
                                                    </option>
                                                    <option value="05">
                                                        Maio
                                                    </option>
                                                    <option value="06">
                                                        Junho
                                                    </option>
                                                    <option value="07">
                                                        Julho
                                                    </option>
                                                    <option value="08">
                                                        Agosto
                                                    </option>
                                                    <option value="09">
                                                        Setembro
                                                    </option>
                                                    <option value="10">
                                                        Outubro
                                                    </option>
                                                    <option value="11">
                                                        Novembro
                                                    </option>
                                                    <option value="12">
                                                        Dezembro
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div  className="modal-inputs-organizer">
                                            <label>Ano</label>
                                                    <input
                                                    type='text'
                                                    maxLength={2}
                                                    placeholder='xx'
                                                    onChange={(e) =>
                                                        setCardValidYear(e.target.value)
                                                        }
                                                    style={{
                                                        flex: '1',
                                                        width: '6rem',
                                                    }}    
                                                    />        
                                        </div>
                                       
                                        <div
                                            className="modal-inputs-organizer"
                                            style={{
                                                flex: '1',
                                                width: '12rem',
                                            }}
                                        >
                                            <label>Código de seg:</label>
                                            <input
                                                type="text"
                                                placeholder="xxx"
                                                onChange={(e) =>
                                                    setCardCvv(e.target.value)
                                                }
                                                maxLength={3}
                                            ></input>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div
                                            className="modal-inputs-organizer"
                                            style={{ flex: '1' }}
                                        >
                                            <label>Validade</label>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <select
                                                    onChange={(e) =>
                                                        setCardValidMonth(
                                                            e.target.value
                                                        )
                                                    }
                                                    style={{
                                                        marginRight: '25px',
                                                    }}
                                                >
                                                    <option value="01">
                                                        Janeiro
                                                    </option>
                                                    <option value="02">
                                                        Fevereiro
                                                    </option>
                                                    <option value="03">
                                                        Março
                                                    </option>
                                                    <option value="04">
                                                        Abril
                                                    </option>
                                                    <option value="05">
                                                        Maio
                                                    </option>
                                                    <option value="06">
                                                        Junho
                                                    </option>
                                                    <option value="07">
                                                        Julho
                                                    </option>
                                                    <option value="08">
                                                        Agosto
                                                    </option>
                                                    <option value="09">
                                                        Setembro
                                                    </option>
                                                    <option value="10">
                                                        Outubro
                                                    </option>
                                                    <option value="11">
                                                        Novembro
                                                    </option>
                                                    <option value="12">
                                                        Dezembro
                                                    </option>
                                                </select>

                                              
                                            </div>
                                        </div>
                                        <div  className="modal-inputs-organizer">
                                            <label>Ano</label>
                                            <input
                                                type='text'
                                                maxLength={2}
                                                placeholder='xx'
                                                style={{ width: '100%' }}
                                                onChange={(e) =>
                                                    setCardValidYear(
                                                        e.target.value
                                                    )
                                                }
                                            />          
                                            </div>
                                        <div
                                            className="modal-inputs-organizer"
                                            style={{ flex: '1', width: '100%' }}
                                        >
                                            <label>Código de seg:</label>
                                            <input
                                                type="text"
                                                placeholder="xxx"
                                                onChange={(e) =>
                                                    setCardCvv(e.target.value)
                                                }
                                                maxLength={3}
                                            ></input>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="modal-actions">
                                <div className="buttons-container">
                                    <button
                                        onClick={() =>
                                            setState({
                                                dimmer: undefined,
                                                open: false,
                                            })
                                        }
                                        className="negative-button"
                                    >
                                        Cancelar
                                    </button>
                                </div>
                                <div className="buttons-container">
                                    <button
                                        className="positive-button"
                                        onClick={() => createCard()}
                                    >
                                        Cadastrar cartão
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ModalContainer>
                </Modal>
            </div>
        </ButtonContainer>
    )
}

export default AddCardModal
