import IProductsCart from '../models/ICart';

export const getCalcCartValue = (productsCart: IProductsCart[]) => {
	let price = 0;
	if (productsCart) {
		for (let product of productsCart) {
			price +=(product?.parameters?.product?.price! * product?.parameters?.quantity);
			if(product.parameters.service){
				price += Number(product.parameters.service.custom_price)
			}
		}
	}
	return price;
};
