import styled from 'styled-components';

export const Container = styled.div`
    height:68vh;
`;

export const GoBackContainer = styled.header`
	display: flex;
	align-items: center;
	margin: 46px 100px;

	img {
		width: 8px;
		height: 15px;
		margin-right: 15px;
	}

	span {
		color: #aaa;
		font-weight: bold;
	}
`;

export const MainContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	h1 {
		font-size: 40px;
		color: #6b98a6;
		font-weight: normal;
	}

	span {
		color: #6b98a6;
		font-size: 17px;
		font-weight: 600;
	}

	a {
		color: #6b98a6;
		font-size: 17px;
		font-weight: 600;
		margin-top: 30px;
		text-decoration: underline;
		cursor: pointer;
	}
`;
