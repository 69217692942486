import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import IUserAccount from '../../../../models/IUserAccount'
import { AppState } from '../../../../store'

import { Container } from './Style'
import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Bar,
    BarChart,
} from 'recharts'
import moment from 'moment'
import useWindow from '../../../../hooks/useWindow'

type Props = {
    data: any
}

const UserReputationGraph: React.FC<Props> = ({ data }) => {
    const { innerHeight: height, innerWidth: width } = useWindow()
    const user: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )

    useEffect(() => {
        const result = data?.filter(
            (value: any) =>
                parseInt(moment(value.createdAt).format('MM')) ===
                parseInt(moment().format('MM'))
        )
    }, [data])

    const findPurchaseMonth = (month: number) => {
        const result = data?.filter(
            (value: any) =>
                parseInt(moment(value.createdAt).format('MM')) === month
        )
        if (result) {
            return result?.length
        } else {
            return 0
        }
    }
    const findPurchaseValues = (month: number) => {
        const result = data?.filter(
            (value: any) =>
                parseInt(moment(value.createdAt).format('MM')) === month
        )
        if (result) {
            let resultValues = 0
            const result1 = result.map((value: any) => value.price)

            if (result1) {
                for (let index = 0; index < result1.length; index++) {
                    resultValues += parseInt(result1[index]!)
                }
            }

            return resultValues
        } else {
            return 0
        }
    }

    const graphData = [
        {
            name: 'Janeiro',
            QtdVendas: findPurchaseMonth(1),
            ValorTotalVendido: findPurchaseValues(1),
        },
        {
            name: 'Fevereiro',
            QtdVendas: findPurchaseMonth(2),
            ValorTotalVendido: findPurchaseValues(2),
        },
        {
            name: 'Março',
            QtdVendas: findPurchaseMonth(3),
            ValorTotalVendido: findPurchaseValues(3),
        },
        {
            name: 'Abril',
            QtdVendas: findPurchaseMonth(4),
            ValorTotalVendido: findPurchaseValues(4),
        },
        {
            name: 'Maio',
            QtdVendas: findPurchaseMonth(5),
            ValorTotalVendido: findPurchaseValues(5),
        },
        {
            name: 'Junho',
            QtdVendas: findPurchaseMonth(6),
            ValorTotalVendido: findPurchaseValues(6),
        },
        {
            name: 'Julho',
            QtdVendas: findPurchaseMonth(7),
            ValorTotalVendido: findPurchaseValues(7),
        },
        {
            name: 'Agosto',
            QtdVendas: findPurchaseMonth(8),
            ValorTotalVendido: findPurchaseValues(8),
        },
        {
            name: 'Setembro',
            QtdVendas: findPurchaseMonth(9),
            ValorTotalVendido: findPurchaseValues(9),
        },
        {
            name: 'Outubro',
            QtdVendas: findPurchaseMonth(10),
            ValorTotalVendido: findPurchaseValues(10),
        },
        {
            name: 'Novembro',
            QtdVendas: findPurchaseMonth(11),
            ValorTotalVendido: findPurchaseValues(11),
        },
        {
            name: 'Dezembro',
            QtdVendas: findPurchaseMonth(12),
            ValorTotalVendido: findPurchaseValues(12),
        },
    ]

    const windowSize = () => {
        if (height < 768 && width < 1366) {
            return (
                <BarChart
                    className="graph"
                    data={graphData}
                    width={800}
                    height={400}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis dataKey="QtdVendas" />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="ValorTotalVendido" fill="#8884d8" />
                    <Bar dataKey="QtdVendas" fill="#82ca9d" />
                </BarChart>
            )
        } else {
            return (
                <>
                    <BarChart
                        className="graph"
                        data={graphData}
                        width={790}
                        height={600}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis dataKey="QtdVendas" />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="ValorTotalVendido" fill="#8884d8" />
                        <Bar dataKey="QtdVendas" fill="#82ca9d" />
                    </BarChart>
                </>
            )
        }
    }

    return (
        <>
            <Container>{windowSize()}</Container>
        </>
    )
}
export default UserReputationGraph
