import styled from 'styled-components';

export const Container = styled.main`
    display:flex;
    flex-direction:row;
    width:100%;
    height:max-content;


    padding: 60px;
    justify-content: space-between;
    background: #EEF3F3;
    min-height: 100%;

`;

export const FilterCard = styled.div`
    width: 201px;
    height: max-content;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 22px;

    h2{
        color:#0B0948;
        font-size:15px;
        font-family:'Poppins';
        font-weight:bold;
        margin-bottom:23px;
    }
`;

export const FilterAndButtonContainer = styled.div`
    display:flex;
    flex-direction:column;
    align-items:space-between;
    justify-content:center;
    height: max-content;

    button{
        width: 201px;
        height: 35px;
        background: var(--unnamed-color-ff7e0f) 0% 0% no-repeat padding-box;
        background: #FF7E0F 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 12px;
        color:white;
        margin-top:15px;
        border:none;
        font-family:'Poppins';
        font-weight:bold;
        font-size:12px;
    }
    button:focus{
        outline:none;
    }
    button:hover{
        cursor:pointer;
    }

    .BotaoCadastrarConta{
        margin-top: 30px;
        margin-bottom:20px;
        border: 1px solid black;
    }
    .BotaoCadastrarProjetoSocial{
        width: 201px;
        height: 35px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 12px;
        margin-top:15px;
        font-family:'Poppins';
        font-weight:bold;
        font-size:12px;
        color: #6b97a5;
        border: 1px solid black;
    }
`;

