import React, {
    forwardRef,
    useCallback,
    useImperativeHandle,
    useState,
} from 'react';
import { Modal } from 'semantic-ui-react';
import {
    Container,
    ModalBottom,
    ModalMiddle,
    ModalTop,
    SucessModalTop,
    SucessModalMiddle,
    SucessModalBottom,
} from './styles';
import { useCreateUseraccountWithAdmUserMutation } from '../../../generated/graphql';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
export interface ModalNewUserHandles {
    showModal: () => void;
}

interface registerType{
    id:number,
    name:string,
    active:boolean
}

interface NewUserValues {
    email: string;
    avisoDescricao: string;
}
interface NewUserResponse {
    email?: string;
    password?: string;
}
const NewNotificationModal: React.RefForwardingComponent<ModalNewUserHandles> = (
    {},
    ref
) => {
    const [createNewUser] = useCreateUseraccountWithAdmUserMutation();
    const [state, setState] = useState({ dimmer: undefined, open: false });
    const [sucessModalState, setSucessModalState] = useState(false);
    const { register, handleSubmit} = useForm<NewUserValues>({mode: 'onSubmit',reValidateMode: 'onChange'})
    const show = (dimmer: any) => setState({ dimmer: dimmer, open: false });

    const registersType = [
        {id:1,name:'ALL',active:true},
        {id:1,name:'MODERADORES',active:false},
        {id:1,name:'FAQ',active:false},
        {id:1,name:'COMPRADORES',active:false},
        {id:1,name:'ADMINISTRADORES',active:false},
        {id:1,name:'VENDEDORES',active:false},
    ]

    const [registerType, setRegisterType] = useState<registerType[]>(registersType);
    const [userTypeId, setUserTypeId] = useState<number>(1);
    const [newUserResult, setNewUserResult] = useState<NewUserResponse | null>(
        null
    );
    const dispacth = useDispatch();

    const showModal = useCallback(() => {
        setState({ dimmer: undefined, open: true });
    }, [setState, state]);

    const onSubmit = async (data: any) => {
    }

    useImperativeHandle(ref, () => {
        return {
            showModal,
        };
    });
    return (
        <>
            <Modal
                dimmer={state.dimmer}
                open={state.open}
                onClose={() => state.open === false}
                style={{ width: '635px', height: '540px', borderRadius:'15px' }}
            >
                <Container>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <ModalTop>
                            <h2 className="TituloCadastrarConta">Emitir aviso</h2>
                        </ModalTop>
                        <ModalMiddle>
                            <div className="choose-user-type-buttons-container">
                               {registersType.map((typeRegister)=>{
                                   return(
                                      <div className="modal-checkbox">
                                        <input type="checkbox"  name={typeRegister.name}/>
                                        <span>{typeRegister.name}</span>
                                      </div>
                                   )
                               })}
                               
                            </div>
                            <div className="modal-inputs">
                                <div className="input-container">
                                    <label>Aviso</label>
                                    <textarea
                                        {...register('avisoDescricao',{ required: true })}
                                    />
                                </div>
                            </div>
                        </ModalMiddle>
                        <ModalBottom>
                            <button
                                onClick={() =>
                                    setState({ dimmer: undefined, open: false })
                                }
                                className="negative-button"
                            >
                                Cancelar
                            </button>
                            <button className="positive-button" type="submit">
                                Enviar
                            </button>
                        </ModalBottom>
                    </form>
                </Container>
            </Modal>
            <Modal
                open={sucessModalState}
                onClose={() => !sucessModalState}
                style={{ width: '560px', height: '247px', padding: '21px' }}
            >
                <SucessModalTop>
                    <h2>
                        Novo {userTypeId === 1 && 'Administrador'}{' '}
                        {userTypeId === 2 && 'moderador'}{' '}
                        {userTypeId === 3 && 'FAQ'} cadastrado!
                    </h2>
                </SucessModalTop>
                <SucessModalMiddle>
                    <div className="email-container">
                        <span>E-mail para login</span>
                        <h3>{newUserResult?.email} </h3>
                    </div>
                    <div className="email-container">
                        <span>Senha provisória</span>
                        <h3> {newUserResult?.password} </h3>
                    </div>
                </SucessModalMiddle>
                <SucessModalBottom>
                    <button onClick={() => setSucessModalState(false)}>
                        Concluir
                    </button>
                </SucessModalBottom>
            </Modal>
        </>
    );
};

export default forwardRef(NewNotificationModal);
