import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import IUserAccount from '../../models/IUserAccount';
import { AppState } from '../../store';
import moment from 'moment';
import {
	useGetAllProductQuestionAnswersQuery,
	useDeleteProductQuestionAnswerMutation,
	useUpdateProductQuestionAnswerMutation,
} from '../../generated/graphql';
import Img from '../Default/CommomImg/Img';

type props = {
	ProductQuestionId: number;
};

const ProductQuestionAnswer: React.FC<props> = ({ ProductQuestionId }) => {
	const user: IUserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const [deleteAnswer] = useDeleteProductQuestionAnswerMutation();
	const [updateAnswer] = useUpdateProductQuestionAnswerMutation();
	const [newDescription, setNewDescription] = useState<string>();
	const { data, loading } = useGetAllProductQuestionAnswersQuery({
		variables: { productQuestionId: ProductQuestionId },
	});
	const [edit, setEdit] = useState<boolean>(true);
	const deleteAnswerFunc = async (id: number) => {
		await deleteAnswer({ variables: { id: id } });
		window.location.reload();
	};
	const updateAnswerFunc = async (id: number, description: string) => {
		if (newDescription) {
			await updateAnswer({
				variables: { id: id, description: newDescription },
			});
		} else {
			await updateAnswer({
				variables: { id: id, description: description },
			});
		}

		window.location.reload();
	};

	return (
		<>
			{data &&
				data &&
				data.getAllProductQuestionAnswers?.map(
					(Answer: any, index: number) => (
						<>
							<span className="resp" key={index}>
								Resposta do vendedor:
							</span>
							<div className="answer">
								<div className="user-img">
									<Img
										src={`${process.env.REACT_APP_STATIC_URL}/user/${Answer.UserAccount.id}.jpeg`}
									/>
								</div>

								<div className="answer-content">
									<div className="answer-infos">
										<div className="infos">
											<h2>{Answer.UserAccount.name}</h2>
											<span>
												{moment(
													Answer.createdAt
												).format('DD/MM/YYYY hh:mm')}
											</span>
										</div>
										{Answer.UserAccount.id === user.id && (
											<div className="options">
												{' '}
												{edit && (
													<>
														<span
															onClick={() =>
																setEdit(false)
															}
														>
															Editar
														</span>
														<span
															onClick={() =>
																deleteAnswerFunc(
																	Answer.id
																)
															}
														>
															{' '}
															Excluir
														</span>
													</>
												)}
												{!edit && (
													<span
														onClick={() =>
															updateAnswerFunc(
																Answer.id,
																Answer.description
															)
														}
													>
														salvar
													</span>
												)}
											</div>
										)}
									</div>

									<div className="answer-description">
										<textarea
											defaultValue={Answer.description}
											onChange={(e) =>
												setNewDescription(
													e.target.value
												)
											}
											readOnly={edit}
										></textarea>
									</div>
								</div>
							</div>
						</>
					)
				)}
		</>
	);
};
export default ProductQuestionAnswer;
