import React from 'react';
import ForgotPassword from '../../components/ForgotPassword/ForgotPassword';
import Layout from '../../components/Layout/Layout';

const ForgotPasswordPage = () => {
	return (
		<Layout title="Esqueci Minha Senha">
			<ForgotPassword />;
		</Layout>
	);
};

export default ForgotPasswordPage;
