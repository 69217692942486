import React, {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { Button, Modal } from 'semantic-ui-react';
import { useCreateUseraccountWithAdmUserMutation } from '../../../generated/graphql';
import {
	TitleText,
	DescriptionText,
} from '../../NoBankAccountWarningModal/styles';

import { ModalContent } from './styles';
import cep from 'cep-promise';
import {
	SucessModalTop,
	SucessModalMiddle,
	SucessModalBottom,
} from '../NewUserModal/styles';
import { StylesProvider } from '@material-ui/styles';
import { valueFromAST } from 'graphql';
import { FormControl } from '@material-ui/core';

export interface ModalNewSocialProjectHandles {
	openModal: () => void;
}

interface ModalInputs {
	name: string;
	email: string;
	CNPJ: string;
	zip_code: string;
	street: string;
	number: string;
	complement: string;
}
interface CEP {
	state: string;
	city: string;
	country: string;
	street: string;
}
interface NewUserResponse {
	email?: string;
	password?: string;
	userTypeId: number;
}

const NewSocialProjectModal: React.ForwardRefRenderFunction<ModalNewSocialProjectHandles> = (
	{},
	ref
) => {
	const [state, setState] = useState(false);
	const show = (dimmer: any) => setState(true);
	const [createNewUser] = useCreateUseraccountWithAdmUserMutation();
	const openModal = useCallback(() => {
		show('blurring');
	}, []);
	const { register, handleSubmit } = useForm<ModalInputs>();
	const [cepResult, setCepResult] = useState<any>();
	const [sucessModalState, setSucessModalState] = useState(false);
	const [newUserResult, setNewUserResult] = useState<NewUserResponse | null>(
		null
	);
	const [modalInputs, setModalInputs] = useState<ModalInputs>(
		{} as ModalInputs
	);

	const handleChange = useCallback(
		(e: React.FormEvent<HTMLInputElement>) => {
			setModalInputs({
				...modalInputs,
				[e.currentTarget.name]: e.currentTarget.value,
			});
		},
		[modalInputs]
	);

	useImperativeHandle(ref, () => {
		return {
			openModal,
		};
	});
	const findAddress = async (zip_code: string) => {
		cep(zip_code)
			.then((response) => {
				setCepResult(response);
			})
			.catch((err) => console.error(err));
	};
	useEffect(() => {
	}, [cepResult]);

	const onSubmit = async (data: ModalInputs) => {
		const result = await createNewUser({
			variables: {
				name: data.name,
				email: data.email,
				userTypeId: 5,
				newSocialProjectTypeInput: {
					isSocialProjectAccount: true,
					socialProjectAddress: cepResult.street,
					socialProjectCEP: cepResult.cep,
					socialProjectCNPJ: data.CNPJ,
					socialProjectCity: cepResult.city,
					socialProjectNeighborhood: cepResult.neighborhood,
					socialProjectNumber: data.number,
					socialProjectState: cepResult.state,
					socialProjectComplement: data.complement,
				},
			},
		});
		setNewUserResult({
			email: result.data?.createUseraccountWithAdmUser.email,
			password: result.data?.createUseraccountWithAdmUser.password,
			userTypeId: result.data?.createUseraccountWithAdmUser.UserType.id!,
		});
		setSucessModalState(true);
	};
	return (
		<>
			<Modal
				centered={true}
				open={state}
				onClose={() => setState(false)}
				onOpen={() => setState(true)}
				style={{
					width: '562px',
					height: '460px',
					borderRadius: '15px',
				}}
			>
				<ModalContent onSubmit={handleSubmit(onSubmit)}>
					<h3 className="T">Cadastrar Projeto Social</h3>

					<div className="inputs-container">
						<div className="input">
							<label>Nome da empresa</label>
							<input
								type="text"
								{...register('name',{ required: true })}
								placeholder="Ex.: Sambay Express"
								onChange={handleChange}
							/>
						</div>

						<div className="input">
							<label>E-mail</label>
							<input
								type="text"
								{...register('email',{ required: true })}
								placeholder="Ex.: email@hotmail.com"
								onChange={handleChange}
							/>
						</div>

						<div className="mid-line">
							<div className="input ls-mask-cnpj">
								<label>CNPJ</label>
								<input
									type="text"
									{...register('CNPJ',{ required: true })}
								/>
							</div>

							<div className="input">
								<label>CEP</label>

								<input
									{...register('zip_code',{ required: true })}
									onChange={(e) =>
										findAddress(e.target.value)
									}
									placeholder="Ex.: 00000-000"
								/>
							</div>
						</div>

						<div className="input">
							<label>Endereço</label>

							<input
								type="text"
								{...register('street',{ required: true })}
								defaultValue={cepResult?.street}
								placeholder="Ex.: Rua das Flores "
								onChange={handleChange}
							/>
						</div>

						<div className="last-line">
							<div className="input">
								<label>Número</label>
								<input
									type="text"
									{...register('number',{ required: true })}
									placeholder="Ex.: 0000"
									onChange={handleChange}
								/>
							</div>
							<div className="input">
								<label>Complemento</label>

								<input
									type="text"
									{...register('complement',{ required: true })}
									placeholder="Ex.: Casa Branca"
									onChange={handleChange}
								/>
							</div>
						</div>
					</div>

					<div className="modal-actions">
						<button
							className="negative-button"
							onClick={() => setState(false)}
						>
							Cancelar
						</button>

						<button
							className="positive-button"
							type="submit"
						>
							Concluir
						</button>
					</div>
				</ModalContent>
			</Modal>
			<Modal
				open={sucessModalState}
				onClose={() => !sucessModalState}
				style={{ width: '560px', height: '247px', padding: '21px' }}
			>
				<SucessModalTop>
					<h2>
						Novo{' '}
						{newUserResult?.userTypeId === 1 && 'Administrador'}{' '}
						{newUserResult?.userTypeId === 2 && 'moderador'}{' '}
						{newUserResult?.userTypeId === 3 && 'FAQ'}{' '}
						{newUserResult?.userTypeId === 5 && 'FAQ'}cadastrado!
					</h2>
				</SucessModalTop>
				<SucessModalMiddle>
					<div className="email-container">
						<span>E-mail para login</span>
						<h3>{newUserResult?.email} </h3>
					</div>
					<div className="email-container">
						<span>Senha provisória</span>
						<h3> {newUserResult?.password} </h3>
					</div>
				</SucessModalMiddle>
				<SucessModalBottom>
					<button onClick={() => setSucessModalState(false)}>
						Concluir
					</button>
				</SucessModalBottom>
			</Modal>
		</>
	);
};

export default forwardRef(NewSocialProjectModal);
function setCnpj(arg0: {
	cnpj: string;
	name: string;
	email: string;
	CNPJ: string;
	zip_code: string;
	street: string;
	number: string;
	complement: string;
}) {
	throw new Error('Function not implemented.');
}
