import styled from 'styled-components';


export const Container = styled.div`
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    .sectionBannerUpsell{
        display:flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        padding-top:3rem;
        padding-bottom:1.5rem;
        background-color: #fff;
        .imgBannerUpsell{
        height:13.3rem;
        border-radius:0.5rem;
        flex-direction: row;
        justify-content: center;
        cursor: pointer;
        } 
        .container-mobile{
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          
          img {
          width: 45%;
          flex-direction:space-between;
          border-radius: 8px;
          }
        }
	  }
        .imgBannerManualAnuncio{
        height:13.3rem;
        border-radius:0.5rem;
        margin-left: 2.3rem;
        flex-direction: row;
        justify-content: center;

          @media (max-width:767px){
          display:none;
          }
        }
    }
    @media (max-width: 896px) {
    .imgBannerUpsell{
      height:13rem;
     }
    }
`;
