import styled from 'styled-components';

interface ContainerProps {
	positionLeft: string;
}

export const Container = styled.div<ContainerProps>`
	display: none;
	z-index: 1;
	transition: all 0.5s;
	left: ${(Prop) => Prop.positionLeft};
	height: 80%;
	background: #fff;
	width: 270px;
	position: absolute;
	top: 0;
	z-index: 1;
	min-height: 80%;
	border-bottom-right-radius: 1rem;
	
	@media (min-width:425px) and (max-width: 768px) {
		display: flex;
		flex-direction: column;
	}

	@media (min-width:375px) and (max-width: 424px) {
		display: flex;
		flex-direction: column;
	}

	@media (min-width:320px) and (max-width: 374px) {
		display: flex;
		flex-direction: column;
	}

	.header-menu {
		height: 140px;
		background: #04aac0;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		border-right: 1px solid white;

		@media (min-width:425px) and (max-width:768px){
			height: 110px;
		}
		@media (min-width:375px) and (max-width:424px){
			height: 110px;
		}
		@media (min-width:320px) and (max-width:374px){
			height: 110px;
		}

		h2 {
			padding-bottom: 15px;
			color: white;
		}

		.user-informations {
			display: flex;
			align-items: center;
			padding:20px;

			img {
				width: 50px;
				height: 50px;
				border-radius: 50%;
				margin-right:10px;
			}
			.user-name-and-link {
				display: flex;
				flex-direction: column;
				margin-left: 6px;

				.ButtonExitMobileMenu{
					background-color: #fff;
					width: 130px;
					border-radius: 5px;
					border: none;
					border-color: white;
					font-weight: bold;
					font-size: 0px;
					height: 25px;
					padding: 0px;
					color: white;
					display: block;
					cursor: pointer;
					margin-top: 0.5rem;
				}

				span {
					font-family: 'Poppins';
					font-weight: 500;
					font-size: 18px;
					margin-bottom: 2px;
					color: white;
					margin-left:0.3rem;
				}
				.link {
					font-family: 'Poppins';
					font-weight: 400;
					font-size: 14px;
					color: black;
					margin-bottom:0.5rem;
					cursor:pointer;
					margin-top:0.8rem;
					margin-left: 0.3rem;
					background-color: #fff;
				}
			}
		}
	}
	.options-menu {
		display: flex;
		flex-direction: column;

		justify-content: flex-start;
	}
`;
export const Dimmer = styled.div`
	width: 100%;
	z-index: 0;
	height: 100%;
	display: none;
	@media (max-width: 768px) {
		display: flex;
	}

	justify-content: flex-start;
	align-items: flex-start;
	background: transparent;
	position: absolute;
`;
