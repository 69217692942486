import styled from 'styled-components';

interface StarsProps {
	fill: string;
	stroke: string;
}

export const Back = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px;
	height: 90px;
	padding-left: 88px;
	background-color: #fff;
	align-self: flex-start;
	width: 100%;

	@media (min-width:425px) and (max-width:768px){
		padding-left: 20px;
	}
	@media (min-width:375px) and (max-width:424px){
		padding-left: 20px;
	}
	@media (min-width:320px) and (max-width:374px){
		padding-left: 20px;
	}

	span {
		color: #6b98a6;
		margin-left: 20px;
		font-size: 27px;
		font-weight: 600;
	}
	img:hover {
		cursor: pointer;
	}
`;
export const Container = styled.div`
	height: 100%;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
`;
export const DeliveryContainer = styled.div`
	display: flex;
	flex-direction: column;
		@media (min-width:425px) and (max-width:768px){
			width: 99%;
			max-width: 424px;
		}
		@media (min-width:375px) and (max-width:424px){
			width: 99%;
			max-width: 374px;
		}
		@media (min-width:320px) and (max-width:374px){
			width: 99%;
			max-width: 319px;
		}

	.delivery-timeline {
		width: 782px;
		height: 83px;
		display: flex;
		margin-left: 88px;
		margin-top: 71px;
		display: flex;
		flex-direction: column;
		margin-bottom: 50px;
		background-color: #fff;

		@media (min-width:425px) and (max-width:768px){
			width: 100%;
			margin-top: 5px;
			margin-left: 5px;
			margin-bottom: 10px;
			max-width: 424px;
		}
		@media (min-width:375px) and (max-width:424px){
			width: 100%;
			margin-top: 5px;
			margin-left: 5px;
			margin-bottom: 10px;
			max-width: 374px;
		}
		@media (min-width:320px) and (max-width:374px){
			width: 100%;
			margin-top: 5px;
			margin-left: 5px;
			margin-bottom: 10px;
			max-width: 319px;
		}
		.event-text {
			display: flex;
			align-items: center;
			justify-items: center;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 20px;
			width: 110%;
			margin-left: -38px;

			@media (min-width:425px) and (max-width:768px){
				width: 99%;
				margin-left: 0;
				margin-bottom: 10px;
			}
			@media (min-width:375px) and (max-width:424px){
				width: 99%;
				margin-left: 0;
				margin-bottom: 10px;
			}
			@media (min-width:320px) and (max-width:374px){
				width: 99%;
				margin-left: 0;
				margin-bottom: 10px;
			}

			h3 {
				font-size: 12px;
				margin: 0;
				width: 95px;
				text-align: center;
				font-family: 'Poppins';
				font-weight: 500;
				color: black;

				@media (min-width:425px) and (max-width:768px){
					width: 100%;
					font-size: 9px;
				}
				@media (min-width:375px) and (max-width:424px){
					width: 100%;
					font-size: 10px;
				}
				@media (min-width:320px) and (max-width:374px){
					width: 100%;
					font-size: 9px;
				}
			}

			.active {
				color: #69b64a;
			}
		}

		.event-points-container {
			display: flex;
			align-items: center;
			justify-items: center;
			justify-content: space-between;

			.active-line-container {
				position: absolute;

				.line {
					border-top: solid 1px #69b64a;
					width: 391px;
					position: relative;
					z-index: 1;

					@media (min-width:425px) and (max-width:768px){
						width: 405px;
					}
					@media (min-width:375px) and (max-width:424px){
						width: 350px;
					}
					@media (min-width:320px) and (max-width:374px){
						width: 300px;
					}
				}
			}

			.line-container {
				position: absolute;

				.line {
					border-top: solid 1px black;
					width: 776px;
					position: relative;
					z-index: 0;

					@media (min-width:425px) and (max-width:768px){
						width: 99%;
						max-width: 424px;
					}
					@media (min-width:375px) and (max-width:424px){
						width: 100%;
						max-width: 374px;
					}
					@media (min-width:320px) and (max-width:374px){
						width: 100%;
						max-width: 319px;
					}
				}
			}

			.ball {
				width: 14px;
				height: 14px;
				border: solid 1px black;
				border-radius: 50%;
				background: white;
				z-index: 2;

				@media (min-width:425px) and (max-width:768px){
					margin-right: 1rem;
				}
				@media (min-width:375px) and (max-width:424px){
					margin-right: 1rem;
				}
				@media (min-width:320px) and (max-width:374px){
					margin-right: 1rem;
				}
				
			}

			.active {
				background: #69b64a;
				border: none;
			}
		}
		.event-date-container {
			display: flex;
			align-items: center;
			justify-items: center;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 20px;
			width: 108%;
			margin-left: -48px;
			margin-top: 20px;

			@media (min-width:425px) and (max-width:768px){
				width: 97%;
				max-width: 424px;
				margin-left: 0;
				margin-top: 10px;
				margin-bottom: 10px;
			}
			@media (min-width:375px) and (max-width:424px){
				width: 97%;
				max-width: 374px;
				margin-left: 0;
				margin-top: 10px;
				margin-bottom: 10px;
			}
			@media (min-width:320px) and (max-width:374px){
				width: 97%;
				max-width: 319px;
				margin-left: 0;
				margin-top: 10px;
				margin-bottom: 10px;
			}

			span {
				color: black;
				font-family: 'Poppins';
				font-weight: 400;
				font-size: 10px;
			}

			.event-date {
				display: flex;
				flex-direction: column;

				span {
					color: black;
					font-family: 'Poppins';
					font-weight: 400;
					font-size: 10px;
				}
			}
		}
	}
`;
export const DeliveryInformations = styled.div`
	width: 782px;
	height: 83px;
	background: #fff;
	box-shadow: 3px 2px 9px #00000014;
	border-radius: 8px;
	padding: 15px;
	margin-left: 88px;
	margin-top: 71px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 24px;
	border: 1px solid black;

	@media (max-width:768px){
		width: 100%;
		margin-top: 0;
		margin-left: 0;
	}

	.delivery-informations-container {
		display: flex;
		flex-direction: row;
		align-items: center;

		img {
			margin-right: 20px;
		}
		.delivery-informations {
			h2 {
				margin-bottom: 1rem;
				color: #6b98a6;
				font-size: 16px;
				font-family: 'Poppins', semi-bold;
				font-weight: 600;
			}
			h3 {
				margin: 0;
				color: #6b98a6;
				font-size: 12px;
				font-family: 'Poppins', semi-bold;
				font-weight: 600;
			}
			span {
				color: #979797;
				font-family: 'Poppins', regular;
				font-size: 13px;
			}
		}
	}
	.delivery-date {
		border: solid 1px #69b64a;
		color: #69b64a;
		width: 223px;
		height: 35px;
		border: 1px solid #69b64a;
		border-radius: 8px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
`;
export const ProductInformations = styled.div`
	width: 783px;
	height: 136px;
	background: #fff;
	box-shadow: 3px 2px 9px #00000014;
	border-radius: 8px;
	padding: 10px;
	margin-left: 88px;
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 24px;
	border: 1px solid black;

	@media (min-width:425px) and (max-width:768px){
		width: 99%;
		max-width: 424px;
		margin-left: 0;
	}
	@media (min-width:375px) and (max-width:424px){
		width: 99%;
		max-width: 374px;
		margin-left: 0;
	}
	@media (min-width:320px) and (max-width:374px){
		width: 99%;
		max-width: 319px;
		margin-left: 0;
	}
	
	.product-img {
		display: flex;
		flex-direction: row;
		align-items: center;
		img {
			width: 135px;
			height: 89px;
			border-radius: 8px;
			margin-right: 22px;
			object-fit: cover;
			object-position: center;
		}

		.product-informations {
			display: flex;
			flex-direction: column;

			h2 {
				margin: 0;
				color: black;
				font-family: 'Poppins', regular;
				font-size: 14px;
				font-weight: 600;
				padding-bottom: 0.5rem;
			}
			h1 {
				margin: 0;
				color: #6b97a5;
				font-family: 'Poppins', semi-bold;
				font-weight: 600;
				font-size: 16px;
			}
			span {
				color: black;
				font-family: 'Poppins', regular;
				font-size: 14px;
				font-weight:400;
			}
		}
	}
	.product-informations-button {
		color: #6b97a5;
		font-family: 'Poppins', regular;
		font-size: 14px;
		text-decoration: underline;
	}
`;

export const AvaliationCard = styled.div`
	width: 783px;
	height: 250px;
	box-shadow: 3px 2px 9px #00000014;
	border-radius: 8px;
	padding: 10px;
	margin-left: 88px;
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	padding: 30px;
	margin-bottom: 2rem;
	background-color: #fff;
	border: 1px solid black;

	@media (min-width:425px) and (max-width:768px){
		width: 99%;
		margin-top: 40px;
		margin-left: 5px;
		margin-bottom: 10px;
		max-width: 424px;
	}
	@media (min-width:375px) and (max-width:424px){
		width: 99%;
		margin-top: 40px;
		margin-left: 5px;
		margin-bottom: 10px;
		max-width: 374px;
	}
	@media (min-width:320px) and (max-width:374px){
		width: 99%;
		margin-top: 40px;
		margin-left: 5px;
		margin-bottom: 10px;
		max-width: 319px;
	}

	.avaliation-card-title {
		display: flex;
		flex-direction: column;

		h2 {
			color: #6b98a6;
			font-family: 'Poppins', semi-bold;
			font-weight: 600;
			font-size: 16px;
			margin-top: 0;
		}
		.user-infos-and-rating {
			display: flex;
			flex-direction: row;

			@media (min-width:425px) and (max-width:768px){
				width: 70%;
				max-width: 400px;
			}
			@media (min-width:375px) and (max-width:424px){
				width: 70%;
				max-width: 350px;
			}
			@media (min-width:320px) and (max-width:374px){
				width: 70%;
				max-width: 300px;
			}

			.user-img-border {
				border: 1px solid black;
				padding: 28px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				border-radius: 45px;
				width: 48px;
				height: 48px;
				margin-right: 6px;

				img {
					width: 48px;
					height: 48px;
					border-radius: 45px;
				}
			}
			.name-and-rating {
				h3 {
					color: #707070;
					font-family: 'Poppins', regular;
					font-size: 14px;
					margin-bottom: 8px;
				}
			}
		}
		.avaliation-input {
			width: 717px;
			height: 38px;
			border: 1px solid black;
			border-radius: 8px;
			margin-top: 10px;
			background-color: #fff;

			@media (min-width:425px) and (max-width:768px){
				width: 100%;
			}
			@media (min-width:375px) and (max-width:424px){
				width: 100%;
			}
			@media (min-width:320px) and (max-width:374px){
				width: 100%;
			}
		}
		.avaliation-input::-webkit-input-placeholder {
			font-style: italic;
		}
		.avaliation-input:focus {
			outline: none;
		}
		.save-button {
			width: 160px;
			height: 33px;
			background: #6b98a6 0% 0% no-repeat padding-box;
			border-radius: 19px;
			border: 1px solid black;
			margin-top: 15px;
			margin-bottom: 15px;
			color: white;
			font-size: 13px;
			font-family: 'Poppins', semi-bold;
			font-weight: 600;

			&:hover {
				cursor: pointer;
			}
		}
	}
`;
export const Stars = styled.div`
	display: flex;
	flex-direction: row;
`;

export const RatingStarsContainer = styled.div`
	display: flex;
	flex-direction: column;
	h3 {
		color: #707070;
		font-family: 'Poppins', regular;
		font-size: 14px;
		margin-bottom: 8px;
	}
`;
export const StarContainer = styled.div<StarsProps>`
	h3 {
		color: #707070;
		font-family: 'Poppins', regular;
		font-size: 14px;
		margin-bottom: 8px;
	}
	svg {
		.a {
			fill: ${(Prop) => Prop.fill};
			stroke: ${(Prop) => Prop.stroke};
		}
	}
	svg:hover {
		.a {
			fill: #ff7e0f;
		}
	}
`;
