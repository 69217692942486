import React from 'react';
import AdmNotifications from '../../components/AdmNotifications/AdmNotifications';
import Layout from '../../components/Layout/Layout';

const AdmProfilePage = () => {
	return (
		<Layout>
			<AdmNotifications />
		</Layout>
	);
};

export default AdmProfilePage;
