import React from 'react';
import Shopping from '../../components/Shopping/Shopping';
import Layout from '../../components/Layout/Layout';

const ShoppingPage = () => {
	return (
		<Layout title="Loja">
			<Shopping />
		</Layout>
	);
};
export default ShoppingPage;
