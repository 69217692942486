import React, { useCallback, useEffect, useRef, useState } from 'react'
import SubCategorieItem from '../SubCategorieItem/SubCategorieItem'
import {
    ProductCategories,
    ProductSubCategories,
    useDeleteProductCategorieMutation,
    useGetAllProductSubCategoriesQuery,
    useUpdateProductCategorieMutation,
} from '../../../generated/graphql'
import NewCategorieModal, {
    NewCategorieModalHandles,
} from '../NewCategorieModal/NewCategorieModal'

import { Container } from './Style'

type Props = {
    category: ProductCategories
}

const CategorieGroup: React.FC<Props> = ({ category }) => {
    const ModalRef = useRef<NewCategorieModalHandles>(null)
    const openModal = useCallback(() => {
        ModalRef.current?.handleOpenModal()
    }, [ModalRef])

    const { data, loading } = useGetAllProductSubCategoriesQuery({
        fetchPolicy: 'cache-and-network',
        variables: { categorieId: category.id },
    })
    const [categoryName, setCategoryName] = useState<string>(category.name)
    const [disabled, setDisabled] = useState<boolean>(true)
    const [categorySplit, setCategorySplit] = useState<number>(9)

    const [update] = useUpdateProductCategorieMutation({
        onCompleted: () => {
            alert('Categoria atualizada com sucesso.')
            window.location.reload()
        },
        onError(error) {
            alert(error.message)
        },
    })

    const [destroy] = useDeleteProductCategorieMutation({
        onCompleted: () => {
            alert('Categoria removida com sucesso.')
            window.location.reload()
        },
        onError(error) {
            alert(error.message)
        },
    })

    const handleUpdate = async () => {
        const { id, name, split } = category

        if (categoryName.length > 0 && categoryName !== name)
            await update({
                variables: { id, name: categoryName },
            })

        if (categorySplit > 0 && categorySplit !== split)
            await update({
                variables: { id, split: categorySplit / 100 },
            })
    }

    const handleCancel = () => {
        const { name, split } = category
        setCategoryName(name)
        setCategorySplit((split && split * 100) || 9)
        setDisabled(true)
    }

    const handleDelete = async () => {
        const { id } = category
        await destroy({
            variables: { id },
        })
    }

    useEffect(() => {
        category.split && setCategorySplit(category.split * 100)
    }, [category.split])

    useEffect(() => {
        category.name && setCategoryName(category.name)
    }, [category.name])

    return (
        <>
            <NewCategorieModal
                ref={ModalRef}
                areaTitle="subcategoria"
                registerType="subcategorie"
                categorieId={category.id}
            />
            <Container>
                <div className="category">
                    <input
                        value={categoryName}
                        type="text"
                        onChange={({ target }) => setCategoryName(target.value)}
                        className={`categorie-input ${
                            disabled ? '' : 'active'
                        }`}
                        disabled={disabled}
                    />
                    <div className="tw-m-2 tw-flex tw-flex-row tw-items-center tw-text-base">
                        <label>Comissão:</label>
                        <input
                            value={categorySplit}
                            type="number"
                            onChange={({ target }) =>
                                setCategorySplit(Number.parseInt(target.value))
                            }
                            className={`categorie-input ${
                                disabled ? '' : 'active'
                            }`}
                            disabled={disabled}
                        />
                        <span>%</span>
                    </div>

                    <div className="options">
                        <button
                            onClick={openModal}
                            style={{ background: '#00BBAF' }}
                        >
                            Criar subcategoria
                        </button>
                        {disabled && (
                            <button
                                onClick={() => setDisabled(false)}
                                style={{ background: '#6085DB' }}
                            >
                                Editar
                            </button>
                        )}
                        {!disabled && (
                            <button
                                onClick={handleUpdate}
                                style={{ background: '#6085DB' }}
                            >
                                Salvar
                            </button>
                        )}
                        {!disabled && (
                            <button
                                onClick={handleCancel}
                                style={{ background: '#6085DB' }}
                            >
                                Cancelar
                            </button>
                        )}
                        <button
                            onClick={handleDelete}
                            style={{ background: '#FF7E0F' }}
                        >
                            Excluir
                        </button>
                    </div>
                </div>
                <div className="sub-category">
                    {!loading &&
                        data &&
                        data.getAllProductSubCategories.map(
                            (subcategory, index: number) => {
                                return (
                                    <SubCategorieItem
                                        key={index}
                                        subCategorie={
                                            subcategory as ProductSubCategories
                                        }
                                    />
                                )
                            }
                        )}
                </div>
            </Container>
        </>
    )
}

export default CategorieGroup
