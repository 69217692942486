import React from 'react'
import { useHistory } from 'react-router'
import { Menu } from '@headlessui/react'
import iconArrowCategories from '../../assets/icons/icon_arrow_categories.svg'
import { CategoriesBar } from './styles'
import useWindow from '../../hooks/useWindow'

const CategoriesMenu = (): JSX.Element => {
    const window = useWindow()
    const history = useHistory()
    return (
        <Menu>
            <Menu.Button style={{ background: 'transparent', border: 'none' }}>
                <div className="section-subtitle-categories-bar-title">
                    <span className="span-categories">Categorias</span>
                    <img
                        src={iconArrowCategories}
                        alt="icon_abrir_categorias"
                        className="style-icon-arrow-categories"
                    />
                </div>
            </Menu.Button>
            <Menu.Items>
                <CategoriesBar>
                    <div className="section-category-bar">
                        <div className="section-options-category-bar">
                            <h2 className="title-section-category-bar">
                                Destaques
                            </h2>
                            <Menu.Item>
                                <span
                                    className="option-category-bar"
                                    onClick={() =>
                                        history.push(`/search/%20/:null`)
                                    }
                                >
                                    Novidades
                                </span>
                            </Menu.Item>
                            <Menu.Item>
                                <span
                                    className="option-category-bar"
                                    onClick={() =>
                                        history.push(`/search/promoção/:null`)
                                    }
                                >
                                    Pechincha
                                </span>
                            </Menu.Item>
                            <Menu.Item>
                                <span
                                    className="option-category-bar"
                                    onClick={() =>
                                        history.push(
                                            `/search/category/Esportes`
                                        )
                                    }
                                >
                                    Esportes
                                </span>
                            </Menu.Item>
                            <Menu.Item>
                                <span
                                    className="option-category-bar"
                                    onClick={() =>
                                        history.push(
                                            `/search/category/Gibis, Livros e Revistas`
                                        )
                                    }
                                >
                                    Livros
                                </span>
                            </Menu.Item>
                            <Menu.Item>
                                <span
                                    className="option-category-bar-2"
                                    onClick={() =>
                                        history.push(
                                            '/search/category/Antiguidades%20e%20Coleções'
                                        )
                                    }
                                >
                                    Antiguidades
                                </span>
                            </Menu.Item>
                            <Menu.Item>
                                <span
                                    className="option-category-bar-2"
                                    onClick={() =>
                                        history.push(
                                            '/search/category/Vestuário'
                                        )
                                    }
                                >
                                    Vestuário
                                </span>
                            </Menu.Item>
                            <Menu.Item>
                                <span
                                    className="option-category-bar-2"
                                    onClick={() =>
                                        history.push(
                                            '/search/category/Home%20Office'
                                        )
                                    }
                                >
                                    Home-Office
                                </span>
                            </Menu.Item>
                            <Menu.Item>
                                <span
                                    className="option-category-bar-2"
                                    onClick={() =>
                                        history.push(
                                            '/search/category/Linha%20PET'
                                        )
                                    }
                                >
                                    Pets
                                </span>
                            </Menu.Item>
                            <Menu.Item>
                                <span
                                    className="option-category-bar-2"
                                    onClick={() =>
                                        history.push(
                                            '/search/category/Proteção%20contra%20a%20Covid-19'
                                        )
                                    }
                                >
                                    Covid-19
                                </span>
                            </Menu.Item>
                            <Menu.Item>
                                <span
                                    className="option-category-bar-2"
                                    onClick={() =>
                                        history.push(
                                            '/search/category/Equipamentos%20médicos'
                                        )
                                    }
                                >
                                    Equip Médico
                                </span>
                            </Menu.Item>
                            <Menu.Item>
                                <span
                                    className="last-option-category-bar"
                                    onClick={() =>
                                        history.push(`/social-projects`)
                                    }
                                >
                                    Projetos Sociais
                                </span>
                            </Menu.Item>
                        </div>
                        <div className="section-options-category-bar">
                            <h2 className="section-options-category-bar-2">
                                Lojas Oficiais
                            </h2>
                            <Menu.Item>
                                <span
                                    className="option-category-bar-2"
                                    onClick={() =>
                                        history.push('/shopping/6/products')
                                    }
                                >
                                    Raridades CWB
                                </span>
                            </Menu.Item>
                            <Menu.Item>
                                <span
                                    className="option-category-bar-2"
                                    onClick={() =>
                                        history.push('/shopping/16/products')
                                    }
                                >
                                    Zequinha
                                </span>
                            </Menu.Item>
                            <Menu.Item>
                                <span
                                    className="option-category-bar-2"
                                    onClick={() =>
                                        history.push('/shopping/48/products')
                                    }
                                >
                                    Loja Sambay Express
                                </span>
                            </Menu.Item>
                            <Menu.Item>
                                <span
                                    className="option-category-bar-2"
                                    onClick={() =>
                                        history.push('/shopping/311/products')
                                    }
                                >
                                    Anju's Mat. Construção
                                </span>
                            </Menu.Item>
                            <Menu.Item>
                                <span
                                    className="option-category-bar-2"
                                    onClick={() =>
                                        history.push('/shopping/958/products')
                                    }
                                >
                                    Woodson
                                </span>
                            </Menu.Item>
                            <Menu.Item>
                                <span
                                    className="option-category-bar-2"
                                    onClick={() =>
                                        history.push('/shopping/11/products')
                                    }
                                >
                                    Bear and Bull
                                </span>
                            </Menu.Item>
                            <Menu.Item>
                                <span
                                    className="option-category-bar-2"
                                    onClick={() =>
                                        history.push('/shopping/328/products')
                                    }
                                >
                                    Portal do Tiro
                                </span>
                            </Menu.Item>
                            <Menu.Item>
                                <span
                                    className="option-category-bar-2"
                                    onClick={() =>
                                        history.push('/shopping/972/products')
                                    }
                                >
                                    LimpJA
                                </span>
                            </Menu.Item>
                        </div>
                    </div>
                </CategoriesBar>
            </Menu.Items>
        </Menu>
    )
}
export default CategoriesMenu
