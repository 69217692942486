import React , { useRef , useCallback } from 'react';
import { ProductSubCategories , useDeleteProductSubCategorieMutation } from '../../../generated/graphql';
import { NewCategorieModalHandles } from '../NewCategorieModal/NewCategorieModal'

import { SubCategorie } from './styles';

type Props = {
  subCategorie:ProductSubCategories
}

const SubCategorieItem: React.FC<Props> = ({subCategorie}) => {
  const [ deleteProductSubCategorie ] = useDeleteProductSubCategorieMutation();
    const handleDelete = async () => {
        await deleteProductSubCategorie({
            variables: { id:subCategorie.id },
        });
        window.location.reload()
    }  

    return <SubCategorie>
      {
        subCategorie.name
      }
      <div className="options">
        <button onClick={handleDelete}>Excluir</button>
      </div>
    </SubCategorie>;
}

export default SubCategorieItem;