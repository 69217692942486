import React from 'react';
import { MdCheckCircle, MdError, MdLink } from 'react-icons/md';

import styled from './styles.module.css';

export function FileList(files: any) {
	const listSpecificFile = (uploadedFile: any) => {
		return (
			<li>
				<div className={styled.fileInfo}>
					<img
						src="https://sambayexpress.com.br/static/media/2.ab81500e.png"
						alt="Image"
						className={styled.image}
					/>
					<div>
						<strong>{uploadedFile.name}</strong>
						<span className="uploaded-file">
							{uploadedFile.readableSize}
							{!!uploadedFile.url && (
								<button onClick={() => {}}>Excluir</button>
							)}
						</span>
					</div>
				</div>

				<div>
					{uploadedFile.url && (
						<a
							href="https://sambayexpress.com.br/static/media/2.ab81500e.png"
							target="_blank"
							rel="noopener noreferrer"
						>
							<MdLink
								style={{ marginRight: 8 }}
								size={24}
								color="#222"
							/>
						</a>
					)}

					{uploadedFile.uploaded && (
						<MdCheckCircle size={24} color="#78e5d5" />
					)}
					{uploadedFile.error && (
						<MdError size={24} color="#e57878" />
					)}
				</div>
			</li>
		);
	};
	return (
		<div className={styled.containerFileList}>
			{files.files.map((file: any) => {
				return listSpecificFile(file);
			})}
		</div>
	);
}
