import styled from 'styled-components';

export const Container = styled.div`
	.welcome {
		align-items: center;
		width: 100vw;
		height: 100vh;
		transition: 2s;
		h1 {
			color: #6b98a6;
			font-size: 24px;
			font-family: 'Poppins', semi-bold;
			text-align: center;
			margin-top: 40px;
			margin-bottom: 16px;
			font-weight: 500;
		}

		img {
			display: block;
			margin-left: auto;
			margin-right: auto;
			width: 301px;
			height: auto;
		}
	}
	.welcome:animation-timing-function {
		display: none;
	}
`;
