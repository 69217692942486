import React from 'react'

import { Container, MainCard, PageContainer, PageHeader } from './styles'
import back from '../../assets/icons/back.svg'
import { Link } from 'react-router-dom'

const WarrantyPolicy: React.FC = () => {
    return (
        <PageContainer>
            <Container>
                <PageHeader>
                    <Link to="/">
                        <img src={back} alt="" />
                    </Link>
                    <Link to="/">
                        <h3>Política de Garantia</h3>
                    </Link>
                </PageHeader>
                <MainCard>
                    <div className="items-container">
                        <h1>Política de Garantia</h1>
                        <p>
                            Garantimos os produtos vendidos pela Sambay express
                            contra defeitos de fabricação pelo prazo mínimo de
                            30 (trinta) dias e, ainda, conforme garantias da
                            fábrica/loja que os comercializam, caso essas sejam
                            maiores.
                        </p>
                        <p>
                            Apenas vendemos produtos originais, de boa qualidade
                            e boa procedência, que respeitam a legislação
                            brasileira e os nossos consumidores.
                        </p>
                        <p>
                            Mas, em caso de problema/insatisfação, seguem, em
                            páginas específicas, nossas políticas de trocas e
                            devoluções.
                        </p>
                    </div>
                </MainCard>
            </Container>
        </PageContainer>
    )
}

export default WarrantyPolicy
