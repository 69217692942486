import React, {
    ChangeEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    BtnConfirm as ButtonConfirm,
    Categorys,
    Container,
    DeliveryInformations,
    FieldAlert,
    ChoiceContainer,
    ImagesContainer,
    InputOrganizer,
    InputOrganizerSize,
    PricePayment,
    PriceTitleContainer,
    Title,
    TitleCategory,
    VariationContainer,
    VariationItem,
    SubTitleInputContainer,
} from './Style'
import Radio from '@material-ui/core/Radio'
import {
    ProfileAddress,
    GetProductQuery,
    useCreateProdutVariationMutation,
    useDeleteProductVariationByIdMutation,
    useGetAllProductCategoriesQuery,
    useGetAllProductVariationByProductIdLazyQuery,
    useGetAllSubCategoriesByCategoryLazyQuery,
    useGetAllVariationProductQuery,
    useGetProductQuery,
    useGetUserAccountQuery,
    useUpdateProductMutation,
    VariationProduct,
    ProductCategories,
} from '../../generated/graphql'
import { FieldError, useForm } from 'react-hook-form'
import add from '../../assets/icons/add.svg'
import IUserAccount from '../../models/IUserAccount'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../store'
import { NavLink, Route, useHistory, useParams } from 'react-router-dom'
import WarningModal, {
    ModalBankAccountHandles,
} from '../NoBankAccountWarningModal/WarningModal'
import AlertModal, { ModalHandles } from '../AlertModal/AlertModal'
import backButton from '../../assets/icons/back.svg'
import {
    DescriptionContainer,
    InputOrganizerBrand,
    InputOrganizerMaterial,
    PageContainer,
    TitleInputContainer,
} from '../RegisterProduct/Style'
import { updateEditedProduct } from '../../actions/EditedProductActions'
import { ReactComponent as Exit } from '../../assets/icons/exit.svg'
import IEditedProduct from '../../models/EditedProduct'
import { handleMoneyValues } from '../../utils/HandleMoneyValues'
import HelpPopUp from '../RegisterProduct/HelpPopUp/HelpPopUp'
import { IoIosAddCircleOutline, IoMdRemoveCircle } from 'react-icons/io'
import { IoMdAddCircle } from '@react-icons/all-files/io/IoMdAddCircle'
import { CompactPicker } from 'react-color'
import { handleLogout } from '../../handlers/handleLogout'
import { toast, ToastContainer } from 'react-toastify'
import appendFiles, {
    arrayLengthLimit,
    invalidPublicationImageTypeMessage,
    maxFileSizeMessage,
    maxPublicationImageLimitMessage,
} from '../../utils/appendFiles'
import { Back } from '../RegisterProduct/RegisterFormHeader/styles'
import { useQuill } from 'react-quilljs'
import 'quill/dist/quill.snow.css'

export type FormInputs = {
    title: string
    subtitle: string
    description: string
    brand: string
    height?: string
    width?: string
    thickness?: string
    material?: string
    initialValue?: string
    directValue?: string
    finalOffersTime?: string
    price?: string
    stock: string
    maxInstallments?: number
    withdraw: string
    format: string
    weight: number
    vehicleBrand: string
    model: string
    year: string
    motorPower: string
    mileage: string
    fuel: string
    licensePlate: string
    color: string
    doorsNumber: string
    directionType: string
    exchange: string
    cep: string
    street: string
    numberDoors: string
    number: string
    propertyType: string
    numberRooms: string
    bathrooms: string
    garage: string
    properyType: string
    productHeight: number
    productWidth: number
    productWeight: number
    productLength: number
    deadline: number
    addressId: number
    state: string
    complement: string
    city: string
    neighborhood: string
    freeDelivery: boolean
}
interface RegisterProductParams {
    categoryId: string
    productId: string
}

type GetProductVariation = {
    id: number
    cor?: string
    quantidade: number
    tamanho?: string
    productId: number
}

const validInput = (error?: FieldError): string => {
    return error ? 'red' : 'black'
}

const EditProduct: React.FC = () => {
    const { quill, quillRef } = useQuill({
        placeholder: `
            Descreva aqui o seu produto!
            Capriche na descrição!
            E não inclua dados de contato!
            Vamos manter a plataforma justa para todos.
        `,
        modules: {
            toolbar: '#toolbar',
            clipboard: {
                matchVisual: true,
            },
        },
        formats: ['bold', 'italic', 'underline', 'strike'],
    })
    const history = useHistory()
    const user: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )
    const [updateProduct] = useUpdateProductMutation()
    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors: formErrors },
    } = useForm<FormInputs>({
        mode: 'all',
        reValidateMode: 'onSubmit',
    })

    const pageParams: RegisterProductParams = useParams()
    const categoryId: RegisterProductParams = useParams()
    const { data } = useGetAllProductCategoriesQuery({
        fetchPolicy: 'cache-and-network',
    })
    const { data: productData, loading: productLoading } = useGetProductQuery({
        fetchPolicy: 'no-cache',
        variables: { id: Number.parseInt(pageParams.productId) },
    })

    const [
        getProductVarionQuety,
        { data: getProductVariation, loading: getProductVariationLoading },
    ] = useGetAllProductVariationByProductIdLazyQuery({
        fetchPolicy: 'network-only',
        variables: { productId: Number.parseInt(pageParams.productId) },
    })

    const [createProductVariation] = useCreateProdutVariationMutation({
        fetchPolicy: 'no-cache',
    })

    const [deleteProductVariation] = useDeleteProductVariationByIdMutation({
        fetchPolicy: 'no-cache',
    })

    const { data: variationsOutput } = useGetAllVariationProductQuery({
        variables: {
            getAllVariationProductInput: {
                limit: 5,
                offset: 0,
            },
        },
    })

    const [isProduct, setIsProduct] = useState<boolean>(false)
    const [isVehicle, setIsVehicle] = useState<boolean>(false)
    const [isProperty, setIsProperty] = useState<boolean>(false)
    const [addressId, setAddressId] = useState<number>()
    const [hasFreeDelivery, setHasFreeDelivery] = useState(false)
    const [promptDelivery, setPromptDelivery] = useState(true)
    const [isNew, setIsNew] = useState<boolean>(true)
    const [bargain, setBargain] = useState<boolean>(true)
    const [selectedSubCategory, setSelectedSubCategory] = useState<number>(-1)
    const [addVariation, setAddVariation] = useState(false)
    const [disableStockEdit, setDisableStockEdit] = useState<boolean>(false)
    const [imagesRequirements, setImagesRequirements] = useState<string[]>([
        `Adicione 3 a 5 fotos ao menos, para
        aumentar suas chances de venda!`,
        maxFileSizeMessage,
        maxPublicationImageLimitMessage,
        invalidPublicationImageTypeMessage,
    ])
    const [valueMask, setValueMask] = useState<string | undefined>('')
    const installments = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    const [bargainMask, setBargainMask] = useState<string | undefined>('')
    useState<boolean>(false)

    const modalRef = useRef<ModalBankAccountHandles>(null)
    const [filterCategories, setFilterCategories] = useState<
        ProductCategories[]
    >([])
    const modalAlertRef = useRef<ModalHandles>(null)
    const [valorMinimoSuperior, setValorMinimoSuperior] =
        useState<boolean>(false)
    const [forRemoveImages, setForRemoveImages] = useState<number[]>([])
    const [variations, setVariacoes] = useState<VariationProduct[]>([])
    const [variacaoState, setVariacaoState] = useState<GetProductVariation>({
        id: 0,
        quantidade: 0,
        cor: '',
        tamanho: '',
        productId: Number(pageParams.productId),
    })
    const [date, setDate] = useState<string>()
    const dispatch = useDispatch()

    const [categorySearchQuery, setCategorySearchQuery] = useState<string>('')
    const [imgs, setImgs] = useState<any>([])

    const editedProduct: IEditedProduct = useSelector(
        (state: AppState) => state.EditedProduct.editedProduct
    )

    const [productVariation, setProductVariation] =
        useState<GetProductVariation[]>()
    const [getSubCategories, { data: dataSubCategories }] =
        useGetAllSubCategoriesByCategoryLazyQuery({})

    function setarImagens(numeroDeFotos: number): void {
        let index = 0
        const imagens = []
        while (index < numeroDeFotos) {
            imagens.push({
                url: `${process.env.REACT_APP_STATIC_URL}/product/${
                    pageParams.productId
                }-${index + 1}.jpeg`,
                currentIndex: index,
            })
            index++
        }

        setImgs(imagens)
    }

    function configurarVariacao(variationProduct: VariationProduct): void {
        const variacaoFindIndex = variations.indexOf(variationProduct)

        if (variacaoFindIndex !== -1) {
            setVariacoes(
                variations.filter(
                    (variacao, index) => index !== variacaoFindIndex
                )
            )
        } else {
            setVariacoes([...variations, variationProduct])
        }
    }

    async function adicionarVariacao(): Promise<void> {
        const { quantidade, cor, tamanho } = variacaoState
        await createProductVariation({
            variables: {
                createProductVariationInputData: {
                    quantidade: quantidade,
                    cor: cor,
                    tamanho: tamanho,
                    productId: Number(pageParams.productId),
                },
            },
        })
        setDisableStockEdit(true)

        await getProductVarionQuety({
            variables: {
                productId: Number.parseInt(pageParams.productId),
            },
        })
    }

    useEffect(() => {
        if (productVariation && productVariation.length === 0) {
            setDisableStockEdit(false)
        }
    }, [productVariation])

    useEffect(() => {
        setIsProduct(
            history.location.pathname ===
                `/register-product/${categoryId.categoryId}/${pageParams.productId}/product/edit`
        )
        setIsVehicle(
            history.location.pathname ===
                `/register-product/${7}/${pageParams.productId}/vehicles/edit`
        )
        setIsProperty(
            history.location.pathname ===
                `/register-product/${8}/${pageParams.productId}/properties/edit`
        )
    }, [pageParams])

    function setarProduto(getProductQuery: GetProductQuery): void {
        setValue('title', getProductQuery.getProduct.title)
        setValue('subtitle', getProductQuery.getProduct.subtitle)
        setValue('description', getProductQuery.getProduct.description)
        if (quill)
            quill.clipboard.dangerouslyPasteHTML(
                getProductQuery.getProduct.description
            )
        setValue('brand', getProductQuery.getProduct.brand as string)
        setValue('height', String(getProductQuery.getProduct.height))
        setValue('width', String(getProductQuery.getProduct.width))
        setValue('thickness', String(getProductQuery.getProduct.thickness))
        setValue('material', getProductQuery.getProduct.material as string)
        setValue('directValue', String(getProductQuery.getProduct.directValue))
        setValue('finalOffersTime', date)
        setHasFreeDelivery(getProductQuery!.getProduct!.freeDelivery as boolean)
        setValue('stock', String(getProductQuery.getProduct.stock))
        setValue(
            'maxInstallments',
            getProductQuery.getProduct.maxInstallments as number
        )
        setValue(
            'maxInstallments',
            getProductQuery.getProduct.maxInstallments as number
        )
        setValue('withdraw', String(getProductQuery.getProduct.withdraw))
        setValue('format', String(getProductQuery.getProduct.format))
        setValue('weight', Number(getProductQuery.getProduct.weight))
        setValue(
            'vehicleBrand',
            getProductQuery.getProduct.VehicleInformations
                ?.vehicleBrand as string
        )
        setValue(
            'model',
            getProductQuery.getProduct.VehicleInformations?.model as string
        )
        setValue(
            'year',
            getProductQuery.getProduct.VehicleInformations?.year as string
        )
        setValue(
            'motorPower',
            getProductQuery.getProduct.VehicleInformations?.motorPower as string
        )
        setValue(
            'mileage',
            getProductQuery.getProduct.VehicleInformations?.mileage as string
        )
        setValue(
            'fuel',
            getProductQuery.getProduct.VehicleInformations?.fuel as string
        )
        setValue(
            'licensePlate',
            getProductQuery.getProduct.VehicleInformations
                ?.licensePlate as string
        )
        setValue(
            'color',
            getProductQuery.getProduct.VehicleInformations?.color as string
        )
        setValue(
            'initialValue',
            String(getProductQuery.getProduct.InitialValue)
        )
        setValue('price', String(getProductQuery.getProduct.price))
        setValue(
            'doorsNumber',
            getProductQuery.getProduct.VehicleInformations
                ?.numberDoors as string
        )
        setValue('directValue', String(getProductQuery.getProduct.directValue))
        setValue(
            'exchange',
            String(getProductQuery.getProduct.VehicleInformations?.exchange)
        )
        setValue(
            'cep',
            getProductQuery.getProduct.PropertyInformations?.cep as string
        )
        setValue(
            'street',
            getProductQuery.getProduct.PropertyInformations?.street as string
        )
        setValue(
            'state',
            getProductQuery.getProduct.PropertyInformations?.state as string
        )
        setValue(
            'city',
            getProductQuery.getProduct.PropertyInformations?.city as string
        )
        setValue(
            'neighborhood',
            getProductQuery.getProduct.PropertyInformations
                ?.neighborhood as string
        )
        setValue(
            'complement',
            getProductQuery.getProduct.PropertyInformations
                ?.complement as string
        )
        setValue(
            'number',
            getProductQuery.getProduct.PropertyInformations?.number as string
        )
        setValue(
            'properyType',
            getProductQuery.getProduct.PropertyInformations
                ?.properyType as string
        )
        setValue(
            'bathrooms',
            getProductQuery.getProduct.PropertyInformations?.bathrooms as string
        )
        setValue(
            'garage',
            getProductQuery.getProduct.PropertyInformations?.garage as string
        )
        setValue(
            'numberRooms',
            getProductQuery.getProduct.PropertyInformations?.rooms as string
        )
        setValue(
            'productHeight',
            getProductQuery.getProduct.product_height as number
        )
        setValue(
            'productWidth',
            getProductQuery.getProduct.product_width as number
        )
        setValue(
            'productWeight',
            getProductQuery.getProduct.product_weight as number
        )
        setValue(
            'productLength',
            getProductQuery.getProduct.product_length as number
        )

        setBargain(getProductQuery.getProduct.typeNegotiation === 'Promoção')

        if (
            getProductQuery.getProduct.deadline &&
            getProductQuery.getProduct.deadline > 0
        ) {
            setValue('deadline', getProductQuery.getProduct.deadline)
        }
    }

    useEffect(() => {
        if (!!productData && !productLoading) {
            if (productData.getProduct.categorieId) {
                getSubCategories({
                    variables: {
                        categoryId: productData.getProduct.categorieId!,
                    },
                })
                setSelectedSubCategory(
                    productData.getProduct.subCategorieId
                        ? productData.getProduct.subCategorieId
                        : 0
                )
            }
            dispatch(updateEditedProduct(productData?.getProduct))
            setarProduto(productData)
            setarImagens(productData.getProduct.numberOfPhotos || 0)
            if (productData.getProduct.finalOffersTime)
                setDate(productData.getProduct.finalOffersTime)
            setValueMask(
                productData?.getProduct.price.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                })
            )
            setBargainMask(
                productData?.getProduct.InitialValue.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                })
            )
            setPromptDelivery(!productData.getProduct.deadline)

            if (productData.getProduct.profileAddressId)
                setAddressId(productData?.getProduct.profileAddressId)

            if (productData.getProduct.useState === 'Novo') {
                setIsNew(true)
            } else {
                setIsNew(false)
            }
        }
    }, [productData])

    useEffect(() => {
        getSubCategories({
            variables: {
                categoryId: Number.parseInt(categoryId.categoryId),
            },
        })
    }, [categoryId])

    useEffect(() => {
        if (data && data.getAllProductCategories) {
            const categoriasFiltradas = data.getAllProductCategories.filter(
                (c) => c.name !== 'Imoveis' && c.name !== 'Veículos'
            )
            setFilterCategories(categoriasFiltradas)
        }
    }, [data])

    function deleteImage(image: any): void {
        setForRemoveImages([...forRemoveImages, image.currentIndex])
        setImgs(imgs.filter((img: any) => img.url !== image.url))
    }

    useEffect(() => {
        function validarValorMinimo(): void {
            if (valueMask && bargainMask) {
                const valorMinimo = Number(bargainMask.replace(/\D/g, ''))
                const valorProduto = Number(valueMask.replace(/\D/g, ''))
                if (valorMinimo > valorProduto) {
                    setValorMinimoSuperior(true)
                    setBargainMask('')
                    setTimeout(() => {
                        setValorMinimoSuperior(false)
                    }, 3000)
                }
            }
        }
        validarValorMinimo()
    }, [valueMask, bargainMask])

    const getVariationsStockSum = (
        variations_: GetProductVariation[]
    ): number => {
        let variationsStockSum = 0
        for (const variation of variations_) {
            variationsStockSum += variation.quantidade
        }
        return variationsStockSum
    }

    const onSubmit = async (formData: FormInputs): Promise<void> => {
        let Product: any
        try {
            const imagens: any = []
            imgs.map(async (img: any) => {
                if (!img.url) {
                    imagens.push(img)
                }
            })
            switch (history.location.pathname) {
                case `/register-product/${pageParams.categoryId}/${pageParams.productId}/product/edit`:
                    Product = await updateProduct({
                        variables: {
                            id: Number.parseInt(pageParams.productId),
                            title: formData.title,
                            numberOfPhotos: imgs.length,
                            typeNegotiation: bargain ? 'Promoção' : 'Venda',
                            subtitle: formData.subtitle,
                            description: formData.description,
                            brand: formData.brand,
                            imagesForRemove: forRemoveImages,
                            height: Number.parseInt(formData.height!),
                            width: Number.parseInt(formData.width!),
                            thickness: Number.parseInt(formData.thickness!),
                            useState: isNew ? 'Novo' : 'Usado',
                            material: formData.material,

                            categorieId: Number.parseInt(pageParams.categoryId),
                            subCategorieId:
                                selectedSubCategory === 0
                                    ? undefined
                                    : selectedSubCategory,
                            quantity: 0,

                            price:
                                valueMask !== undefined
                                    ? Number.parseFloat(
                                          valueMask
                                              .replace('.', '')
                                              .replace(',', '.')
                                      )
                                    : Number.parseFloat(formData.price!),

                            maxInstallments: Number.parseFloat(
                                formData.maxInstallments!.toString()
                            ),
                            stock:
                                productVariation && productVariation.length > 0
                                    ? getVariationsStockSum(productVariation)
                                    : Number.parseInt(formData.stock),
                            amount: Number.parseInt(formData.initialValue!),
                            shippingWay: 'correio',
                            directValue:
                                formData.directValue !== null
                                    ? Number.parseFloat(
                                          formData.directValue!.replace(
                                              /\D/g,
                                              ''
                                          )
                                      )
                                    : Number.parseInt(formData.directValue),
                            finalOffersTime: formData.finalOffersTime,
                            InitialValue:
                                formData.initialValue !== null
                                    ? Number.parseFloat(
                                          formData
                                              .initialValue!.replace('.', '')
                                              .replace(',', '.')
                                      )
                                    : Number.parseInt(formData.initialValue),
                            propertyInformation: false,
                            vehicleInformation: false,
                            format: formData.format,
                            weight: formData.weight.toString(),
                            withdraw: formData.withdraw,
                            profileAddressId: addressId,
                            product_height: Number(formData.productHeight),
                            product_width: Number(formData.productWidth),
                            product_length: Number(formData.productLength),
                            product_weight: Number(formData.productWeight),
                            prompt_delivery: promptDelivery,
                            deadline: Number(formData.deadline),
                            files: imagens,
                            freeDelivery: hasFreeDelivery,
                        },
                    })

                    break

                case `/register-product/${pageParams.categoryId}/:productId/vehicles/edit`:
                    Product = await updateProduct({
                        variables: {
                            id: Number.parseInt(pageParams.productId),
                            title: formData.title,
                            typeNegotiation: bargain ? 'Promoção' : 'Venda',
                            subtitle: formData.subtitle,
                            description: formData.description,
                            numberOfPhotos: imgs.length,
                            brand: formData.brand,
                            imagesForRemove: forRemoveImages,
                            height: Number.parseInt(formData.height!),
                            width: Number.parseInt(formData.width!),
                            thickness: Number.parseInt(formData.thickness!),
                            useState: isNew ? 'Novo' : 'Usado',
                            material: formData.material,
                            categorieId: Number.parseInt(pageParams.categoryId),
                            quantity: 1,
                            price: Number.parseInt(formData.price!),
                            maxInstallments: formData.maxInstallments!,
                            stock: Number.parseInt(formData.stock),
                            amount: Number.parseInt(formData.initialValue!),
                            shippingWay: 'correio',
                            directValue: Number.parseInt(formData.directValue!),
                            finalOffersTime: formData.finalOffersTime,
                            InitialValue: Number.parseInt(
                                formData.initialValue!
                            ),
                            propertyInformation: false,
                            vehicleInformation: true,
                            vehicleBrand: formData.vehicleBrand,
                            model: formData.model,
                            year: formData.year,
                            motorPower: formData.motorPower,

                            mileage: formData.mileage,
                            fuel: formData.fuel,
                            licensePlate: formData.licensePlate,
                            color: formData.color,
                            numberDoors: formData.numberDoors,
                            directionType: formData.directionType,
                            exchange: formData.exchange,
                            deadline: Number(formData.deadline),
                            files: imagens,
                        },
                    })
                    break

                case `/register-product/${pageParams.categoryId}/:productId/properties/edit`:
                    Product = await updateProduct({
                        variables: {
                            id: Number.parseInt(pageParams.productId),
                            title: formData.title,
                            typeNegotiation: bargain ? 'Promoção' : 'Venda',
                            imagesForRemove: forRemoveImages,
                            subtitle: formData.subtitle,
                            numberOfPhotos: imgs.length,
                            description: formData.description,
                            brand: formData.brand,
                            height: Number.parseInt(formData.height!),
                            width: Number.parseInt(formData.width!),
                            thickness: Number.parseInt(formData.thickness!),
                            useState: isNew ? 'Novo' : 'Usado',
                            material: formData.material,
                            categorieId: Number.parseInt(pageParams.categoryId),
                            quantity: 1,
                            price: Number.parseInt(formData.price!),
                            maxInstallments: formData.maxInstallments,
                            stock: Number.parseInt(formData.stock),
                            deadline: formData.deadline,
                            amount: Number.parseInt(formData.initialValue!),
                            shippingWay: 'correio',
                            directValue: Number.parseInt(formData.directValue!),
                            finalOffersTime: formData.finalOffersTime,
                            InitialValue: Number.parseInt(
                                bargainMask || formData.initialValue!
                            ),
                            propertyInformation: true,
                            vehicleInformation: false,
                            cep: formData.cep,
                            street: formData.street,
                            number: formData.number,
                            properyType: formData.propertyType,
                            rooms: formData.numberRooms,
                            bathrooms: formData.bathrooms,
                            garage: formData.garage,
                            files: imagens,
                            city: formData.city,
                            complement: formData.complement,
                            state: formData.state,
                            neighborhood: formData.neighborhood,
                        },
                    })

                    break

                default:
                    break
            }

            toast.error('Anúncio Editado com Sucesso!', {
                position: 'top-right',
                autoClose: 5000,
                type: 'success',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })

            dispatch(updateEditedProduct(Product))
            setInterval(() => {
                history.push(`/shopping/${user.id}/products`)
                window.location.reload()
            }, 2000)
        } catch (error_: any) {
            if (error_.message === 'not authenticated') handleLogout(true)
        }
    }

    useEffect(() => {
        if (bargain) {
            setValue('initialValue', bargainMask)
        } else {
            setValue('initialValue', valueMask)
        }
    }, [valueMask, bargain])

    const { data: useGetAccount, loading: useGetAccountLoading } =
        useGetUserAccountQuery({
            fetchPolicy: 'cache-and-network',
            variables: { userAccountId: user.id.toString() },
        })

    const handleOpenModal = useCallback(() => {
        modalRef.current?.OpenModal()
    }, [])

    useEffect(() => {
        if (
            useGetAccount?.getUserAccount &&
            !useGetAccount.getUserAccount.bankAccountId
        ) {
            handleOpenModal()
        }
    }, [useGetAccount])

    const addImage = (fileList: FileList): void => {
        const { list, errors } = appendFiles(fileList, imgs)
        for (const error of errors) {
            toast.error(error.message)
        }
        setImgs(list)
    }

    useEffect(() => {
        const { length } = imgs
        length === arrayLengthLimit - 1
            ? setImagesRequirements([
                  'Você tem somente mais uma imagem disponível para adicionar no anúncio',
                  ...imagesRequirements,
              ])
            : setImagesRequirements([
                  `Adicione 3 a 5 fotos ao menos, para aumentar suas chances de venda!`,
                  maxFileSizeMessage,
                  maxPublicationImageLimitMessage,
                  invalidPublicationImageTypeMessage,
              ])
    }, [imgs])

    useEffect(() => {
        if (getProductVariation && !getProductVariationLoading) {
            const produtosVariation: GetProductVariation[] = []
            let variationsStockSum = 0

            for (const variation of getProductVariation.getAllProductVariationByProductId) {
                produtosVariation.push({
                    id: variation.id,
                    productId: variation.productId,
                    cor: variation?.ColorVariation?.name || '',
                    tamanho: variation?.TamanhoVariations?.name || '',
                    quantidade: variation.quantidade as number,
                })
                variationsStockSum += variation.quantidade as number
            }
            setProductVariation(produtosVariation)
            if (produtosVariation.length > 0) {
                setValue('stock', String(variationsStockSum))
            }
            setDisableStockEdit(true)
        }
    }, [getProductVariation])

    const deleteVariacao = useCallback(
        async (productVariationId: number) => {
            if (productVariation) {
                await deleteProductVariation({
                    variables: {
                        productVariationId: productVariationId,
                    },
                })
                getProductVarionQuety({
                    variables: {
                        productId: Number.parseInt(pageParams.productId),
                    },
                })
            }
        },
        [productVariation]
    )

    function exibirErro(
        fieldError: FieldError | undefined
    ): JSX.Element | undefined {
        if (fieldError && fieldError.type === 'maxLength')
            return <FieldAlert>Limite de caracteres ultrapassado</FieldAlert>
        if (fieldError && fieldError.type === 'required')
            return <FieldAlert>Campo obrigatório</FieldAlert>
    }

    useEffect(() => {
        getProductVarionQuety({
            variables: {
                productId: Number.parseInt(pageParams.productId),
            },
        })
    }, [])

    useEffect(() => {
        if (quill) {
            quill.on('text-change', () => {
                register('description', {
                    value: quill.root.innerHTML,
                    maxLength: 4000,
                })
            })
        }
    }, [quill, register])

    useEffect(() => {
        if (data) {
            setFilterCategories(
                data.getAllProductCategories.filter((category) => {
                    const query = categorySearchQuery.toLowerCase()

                    return (
                        category.name.toLowerCase().includes(query) ||
                        category.id === 39
                    )
                })
            )
        }
    }, [categorySearchQuery, data])

    return (
        <>
            <ToastContainer />
            <HelpPopUp />
            <AlertModal
                ref={modalAlertRef}
                AreaTitle={'Anúncio'}
                DescriptionText={''}
                reload={true}
            />
            <WarningModal ref={modalRef} />

            <Back>
                <div className="back-container">
                    <img
                        src={backButton}
                        onClick={() =>
                            history.push(`/shopping/${user.id}/products`)
                        }
                        alt=" "
                    />
                    <span
                        onClick={() =>
                            history.push(`/shopping/${user.id}/products`)
                        }
                    >
                        Editar Anúncio
                    </span>
                </div>
            </Back>
            <PageContainer>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ width: '100%' }}
                    autoComplete="off"
                >
                    <Title>
                        <h2>Dados gerais</h2>
                        <Container>
                            <ChoiceContainer>
                                <label>Condição*</label>
                                <div className="choice-buttons-container">
                                    <button
                                        type="button"
                                        onClick={() => setIsNew(true)}
                                        className={
                                            isNew
                                                ? 'button-active-left'
                                                : undefined
                                        }
                                    >
                                        Novo
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => setIsNew(false)}
                                        className={
                                            !isNew
                                                ? 'button-active-right'
                                                : undefined
                                        }
                                    >
                                        Usado
                                    </button>
                                </div>
                            </ChoiceContainer>
                            <TitleInputContainer
                                borderColor={validInput(formErrors?.title)}
                            >
                                <label>Título *</label>
                                <input
                                    type="text"
                                    {...register('title', {
                                        required: true,
                                        maxLength: 240,
                                    })}
                                    defaultValue={editedProduct?.title}
                                />
                                {formErrors.title &&
                                    exibirErro(formErrors.title)}
                            </TitleInputContainer>
                            <div className="div">
                                <SubTitleInputContainer
                                    borderColor={validInput(
                                        formErrors?.subtitle
                                    )}
                                >
                                    <label>Subtítulo *</label>
                                    <input
                                        type="text"
                                        {...register('subtitle', {
                                            required: true,
                                            maxLength: 320,
                                        })}
                                        defaultValue={editedProduct?.subtitle}
                                    />
                                    {formErrors.title &&
                                        exibirErro(formErrors.subtitle)}
                                </SubTitleInputContainer>
                            </div>

                            <DescriptionContainer
                                borderColor={validInput(
                                    formErrors?.description
                                )}
                            >
                                <div className="tw-flex tw-flex-row tw-items-start tw-justify-between">
                                    <label>
                                        Descrição (formatação disponível) *
                                    </label>
                                    <div id="toolbar">
                                        <button className="ql-bold" />
                                        <button className="ql-italic" />
                                        <button className="ql-underline" />
                                        <button className="ql-strike" />
                                    </div>
                                </div>

                                <div
                                    ref={quillRef}
                                    className="tw-h-2/3 tw-font-primary"
                                />
                                {formErrors.description &&
                                    exibirErro(formErrors.description)}
                            </DescriptionContainer>

                            <div
                                className="other-container"
                                style={{ width: '100%', marginTop: '25px' }}
                            >
                                <Route
                                    path={`/register-product/:categoryId/:productId/product/edit`}
                                >
                                    <InputOrganizerBrand
                                        borderColor={validInput(
                                            formErrors.brand
                                        )}
                                        style={{ marginRight: '20px' }}
                                    >
                                        <label>Marca *</label>
                                        <input
                                            type="text"
                                            {...register('brand', {
                                                required: isProduct,
                                            })}
                                            defaultValue={
                                                editedProduct?.brand as any
                                            }
                                        />
                                        {formErrors.brand &&
                                            exibirErro(formErrors.brand)}
                                    </InputOrganizerBrand>
                                </Route>
                                <Route
                                    path={`/register-product/:categoryId/:productId/vehicles/edit`}
                                >
                                    <InputOrganizerBrand
                                        borderColor={validInput(
                                            formErrors.brand
                                        )}
                                        style={{
                                            marginRight: '20px',
                                        }}
                                    >
                                        <label>Marca *</label>
                                        <input
                                            type="text"
                                            {...register('vehicleBrand', {
                                                required: isVehicle,
                                            })}
                                        />
                                        {formErrors.vehicleBrand &&
                                            exibirErro(formErrors.vehicleBrand)}
                                    </InputOrganizerBrand>
                                    <InputOrganizerBrand
                                        borderColor={validInput(
                                            formErrors.model
                                        )}
                                        style={{
                                            marginRight: '20px',
                                        }}
                                    >
                                        <label>Modelo *</label>
                                        <input
                                            type="text"
                                            {...register('model', {
                                                required: isVehicle,
                                            })}
                                            defaultValue={
                                                editedProduct
                                                    ?.VehicleInformations
                                                    ?.model as any
                                            }
                                        />
                                        {formErrors.model &&
                                            exibirErro(formErrors.model)}
                                    </InputOrganizerBrand>
                                    <InputOrganizerSize
                                        borderColor={validInput(
                                            formErrors.year
                                        )}
                                    >
                                        <label>Ano *</label>
                                        <input
                                            type="number"
                                            {...register('year', {
                                                required: isVehicle,
                                            })}
                                            defaultValue={
                                                editedProduct
                                                    ?.VehicleInformations
                                                    ?.year as any
                                            }
                                        />
                                        {formErrors.year &&
                                            exibirErro(formErrors.year)}
                                    </InputOrganizerSize>
                                </Route>

                                <Route
                                    path={`/register-product/:categoryId/:productId/product/edit`}
                                >
                                    <InputOrganizerMaterial
                                        borderColor={validInput(
                                            formErrors.material
                                        )}
                                    >
                                        <label>Material *</label>
                                        <input
                                            type="text"
                                            {...register('material', {
                                                required: isProduct,
                                            })}
                                            defaultValue={
                                                editedProduct?.material as any
                                            }
                                        />
                                        {formErrors.material &&
                                            exibirErro(formErrors.material)}
                                    </InputOrganizerMaterial>
                                </Route>
                            </div>
                            {isProduct && (
                                <>
                                    <DeliveryInformations
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            marginTop: '25px',
                                        }}
                                    >
                                        <h1>
                                            Insira abaixo as dimensões do
                                            produto montado e em uso
                                        </h1>
                                        <span>
                                            {' '}
                                            Fique atento(a) as unidades de
                                            medida pedidas no formulário
                                        </span>
                                        <div className="other-container">
                                            <InputOrganizerSize
                                                style={{
                                                    marginRight: '20px',
                                                    height: '51px',
                                                }}
                                                borderColor={validInput(
                                                    formErrors.productHeight
                                                )}
                                            >
                                                <label>
                                                    Altura em centímetros *
                                                </label>
                                                <input
                                                    type="number"
                                                    step="0.001"
                                                    placeholder="Ex: 50cm"
                                                    {...register(
                                                        'productHeight',
                                                        {
                                                            required: isProduct,
                                                        }
                                                    )}
                                                    defaultValue={
                                                        editedProduct?.product_height as number
                                                    }
                                                />
                                                {formErrors.productHeight &&
                                                    exibirErro(
                                                        formErrors.productHeight
                                                    )}
                                            </InputOrganizerSize>
                                            <InputOrganizerSize
                                                style={{
                                                    marginRight: '20px',
                                                }}
                                                borderColor={validInput(
                                                    formErrors.productWidth
                                                )}
                                            >
                                                <label>
                                                    Largura em centímetros *
                                                </label>
                                                <input
                                                    type="number"
                                                    placeholder="Ex: 60cm"
                                                    step="0.001"
                                                    {...register(
                                                        'productWidth',
                                                        {
                                                            required: isProduct,
                                                        }
                                                    )}
                                                    defaultValue={
                                                        editedProduct?.product_width as number
                                                    }
                                                />
                                                {formErrors.productWidth &&
                                                    exibirErro(
                                                        formErrors.productWidth
                                                    )}
                                            </InputOrganizerSize>
                                            <InputOrganizerSize
                                                borderColor={validInput(
                                                    formErrors.productLength
                                                )}
                                                style={{
                                                    marginRight: '20px',
                                                }}
                                            >
                                                <label>
                                                    Comprimento em centímetros *
                                                </label>
                                                <input
                                                    type="number"
                                                    step="0.001"
                                                    placeholder="Ex: 80cm"
                                                    {...register(
                                                        'productLength',
                                                        {
                                                            required: isProduct,
                                                        }
                                                    )}
                                                    defaultValue={
                                                        editedProduct?.product_length as number
                                                    }
                                                />
                                                {formErrors.productLength &&
                                                    exibirErro(
                                                        formErrors.productLength
                                                    )}
                                            </InputOrganizerSize>
                                            <InputOrganizerSize
                                                borderColor={validInput(
                                                    formErrors.productWeight
                                                )}
                                            >
                                                <label>
                                                    Peso em kilogramas *
                                                </label>
                                                <input
                                                    type="number"
                                                    placeholder="Ex: 5kg"
                                                    {...register(
                                                        'productWeight',
                                                        {
                                                            required: isProduct,
                                                        }
                                                    )}
                                                    step="0.01"
                                                    defaultValue={
                                                        editedProduct?.product_weight as number
                                                    }
                                                />
                                                {formErrors.productWeight &&
                                                    exibirErro(
                                                        formErrors.productWeight
                                                    )}
                                            </InputOrganizerSize>
                                        </div>
                                    </DeliveryInformations>
                                    <DeliveryInformations
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <div className="other-container">
                                            <InputOrganizerBrand
                                                style={{
                                                    marginRight: '20px',
                                                }}
                                                borderColor={validInput(
                                                    formErrors.withdraw
                                                )}
                                            >
                                                <label>
                                                    Opção de retirada com
                                                    vendedor *
                                                </label>
                                                <select
                                                    {...register('withdraw', {
                                                        required: false,
                                                    })}
                                                    defaultValue={String(
                                                        productData?.getProduct
                                                            .withdraw
                                                    )}
                                                    style={{
                                                        fontFamily:
                                                            'Poppins, semi-bold',
                                                        fontSize: '13px',
                                                        color: 'black',
                                                    }}
                                                >
                                                    <option
                                                        value=""
                                                        disabled
                                                        style={{
                                                            fontFamily:
                                                                'Poppins, semi-bold',
                                                            fontSize: '13px',
                                                            color: 'black',
                                                        }}
                                                    >
                                                        --selecione--
                                                    </option>
                                                    <option value="Sim">
                                                        Sim
                                                    </option>
                                                    <option
                                                        value="Não"
                                                        disabled={
                                                            hasFreeDelivery
                                                        }
                                                    >
                                                        {hasFreeDelivery
                                                            ? 'Não (indisponível com a opção de frete grátis)'
                                                            : 'Não'}
                                                    </option>
                                                    <option value="exclusivamente">
                                                        Exclusivamente
                                                    </option>
                                                </select>
                                                {formErrors.withdraw &&
                                                    exibirErro(
                                                        formErrors.withdraw
                                                    )}
                                            </InputOrganizerBrand>
                                        </div>
                                        <h1 className="tw-my-0">
                                            Insira abaixo as dimensões do
                                            produto embalado para o envio
                                        </h1>
                                        <span>
                                            {' '}
                                            Fique atento(a) as unidades de
                                            medida pedidas no formulário
                                        </span>
                                        <span className="tw-text-black-500 tw-py-2">
                                            *No caso de envelopes informar
                                            altura zero (0)
                                        </span>
                                        <InputOrganizerMaterial
                                            borderColor={validInput(
                                                formErrors.format
                                            )}
                                        >
                                            <label>Formato *</label>
                                            <select
                                                {...register('format', {
                                                    required: isProduct,
                                                })}
                                                defaultValue={String(
                                                    productData?.getProduct
                                                        .format
                                                )}
                                            >
                                                <option
                                                    disabled
                                                    style={{
                                                        fontFamily:
                                                            'Poppins, semi-bold',
                                                        fontSize: '13px',
                                                        color: 'black',
                                                    }}
                                                >
                                                    --selecione--
                                                </option>
                                                <option
                                                    value="1"
                                                    style={{
                                                        fontFamily:
                                                            'Poppins, semi-bold',
                                                        fontSize: '13px',
                                                        color: 'black',
                                                    }}
                                                >
                                                    Caixa/Pacote
                                                </option>
                                                <option
                                                    value="2"
                                                    style={{
                                                        fontFamily:
                                                            'Poppins, semi-bold',
                                                        fontSize: '13px',
                                                        color: 'black',
                                                    }}
                                                >
                                                    Rolo/Prisma
                                                </option>
                                                <option
                                                    value="3"
                                                    style={{
                                                        fontFamily:
                                                            'Poppins, semi-bold',
                                                        fontSize: '13px',
                                                        color: 'black',
                                                    }}
                                                >
                                                    Envelope
                                                </option>
                                            </select>
                                            {formErrors.format &&
                                                exibirErro(formErrors.format)}
                                        </InputOrganizerMaterial>
                                        <div className="other-container">
                                            <InputOrganizerSize
                                                style={{
                                                    marginRight: '20px',
                                                }}
                                                borderColor={validInput(
                                                    formErrors.height
                                                )}
                                            >
                                                <label>
                                                    Altura em centímetros *
                                                </label>
                                                <input
                                                    type="number"
                                                    placeholder="Ex: 50cm"
                                                    step="0.001"
                                                    {...register('height', {
                                                        required: isProduct,
                                                    })}
                                                    defaultValue={
                                                        editedProduct?.height as any
                                                    }
                                                />
                                                {formErrors.height &&
                                                    exibirErro(
                                                        formErrors.height
                                                    )}
                                            </InputOrganizerSize>
                                            <InputOrganizerSize
                                                style={{
                                                    marginRight: '20px',
                                                }}
                                                borderColor={validInput(
                                                    formErrors.width
                                                )}
                                            >
                                                <label>
                                                    Largura em centímetros *
                                                </label>
                                                <input
                                                    type="number"
                                                    step="0.001"
                                                    placeholder="Ex: 60cm"
                                                    {...register('width', {
                                                        required: isProduct,
                                                    })}
                                                    defaultValue={
                                                        editedProduct?.width as any
                                                    }
                                                />
                                                {formErrors.width &&
                                                    exibirErro(
                                                        formErrors.width
                                                    )}
                                            </InputOrganizerSize>
                                            <InputOrganizerSize
                                                borderColor={validInput(
                                                    formErrors.thickness
                                                )}
                                            >
                                                <label>
                                                    Comprimento em centímetros *
                                                </label>
                                                <input
                                                    type="number"
                                                    step="0.001"
                                                    placeholder="Ex: 80cm"
                                                    {...register('thickness', {
                                                        required: isProduct,
                                                    })}
                                                    defaultValue={
                                                        editedProduct?.thickness as any
                                                    }
                                                />
                                                {formErrors.thickness &&
                                                    exibirErro(
                                                        formErrors.thickness
                                                    )}
                                            </InputOrganizerSize>
                                            <InputOrganizerSize
                                                style={{
                                                    marginLeft: '20px',
                                                }}
                                                borderColor={validInput(
                                                    formErrors.weight
                                                )}
                                            >
                                                <label>
                                                    Peso em kilogramas *
                                                </label>
                                                <input
                                                    type="number"
                                                    placeholder="Ex: 5kg"
                                                    step="0.001"
                                                    {...register('weight', {
                                                        required: isProduct,
                                                    })}
                                                />
                                                {formErrors.weight &&
                                                    exibirErro(
                                                        formErrors.weight
                                                    )}
                                            </InputOrganizerSize>
                                        </div>
                                    </DeliveryInformations>
                                </>
                            )}
                            <Route
                                path={`/register-product/:categoryId/:productId/vehicles/edit`}
                            >
                                <div
                                    className="other-container"
                                    style={{
                                        marginBottom: '25px',
                                        marginTop: '25px',
                                        width: '100%',
                                    }}
                                >
                                    <InputOrganizerSize
                                        style={{
                                            marginRight: '20px',
                                        }}
                                        borderColor={validInput(
                                            formErrors.motorPower
                                        )}
                                    >
                                        <label>Potência do motor *</label>
                                        <input
                                            type="number"
                                            {...register('motorPower', {
                                                required: isVehicle,
                                            })}
                                            defaultValue={
                                                editedProduct
                                                    ?.VehicleInformations
                                                    ?.motorPower as any
                                            }
                                        />
                                        {formErrors.year &&
                                            exibirErro(formErrors.motorPower)}
                                    </InputOrganizerSize>

                                    <InputOrganizerSize
                                        style={{
                                            marginRight: '20px',
                                        }}
                                        borderColor={validInput(
                                            formErrors.mileage
                                        )}
                                    >
                                        <label>Quilometragem *</label>
                                        <input
                                            type="number"
                                            {...register('mileage', {
                                                required: isVehicle,
                                            })}
                                            defaultValue={
                                                editedProduct
                                                    ?.VehicleInformations
                                                    ?.mileage as any
                                            }
                                        />
                                        {formErrors.mileage &&
                                            exibirErro(formErrors.mileage)}
                                    </InputOrganizerSize>

                                    <InputOrganizerSize
                                        borderColor={validInput(
                                            formErrors.fuel
                                        )}
                                    >
                                        <label>Combustível *</label>
                                        <select
                                            {...register('fuel', {
                                                required: isVehicle,
                                            })}
                                            defaultValue={
                                                editedProduct
                                                    ?.VehicleInformations
                                                    ?.fuel as any
                                            }
                                        >
                                            <option
                                                value=""
                                                disabled
                                                style={{
                                                    fontFamily:
                                                        'Poppins, semi-bold',
                                                    fontSize: '13px',
                                                    color: 'black',
                                                }}
                                            >
                                                ---Selecione---
                                            </option>{' '}
                                            <option
                                                value="flex"
                                                style={{
                                                    fontFamily:
                                                        'Poppins, semi-bold',
                                                    fontSize: '13px',
                                                    color: 'black',
                                                }}
                                            >
                                                Flex
                                            </option>{' '}
                                            <option
                                                value="Gasolina"
                                                style={{
                                                    fontFamily:
                                                        'Poppins, semi-bold',
                                                    fontSize: '13px',
                                                    color: 'black',
                                                }}
                                            >
                                                Gasolina
                                            </option>
                                            <option
                                                value="Álcool"
                                                style={{
                                                    fontFamily:
                                                        'Poppins, semi-bold',
                                                    fontSize: '13px',
                                                    color: 'black',
                                                }}
                                            >
                                                Álcool
                                            </option>
                                        </select>
                                        {formErrors.mileage &&
                                            exibirErro(formErrors.fuel)}
                                    </InputOrganizerSize>
                                </div>

                                <div
                                    className="other-container"
                                    style={{
                                        marginBottom: '25px',
                                        marginTop: '25px',
                                        width: '100%',
                                    }}
                                >
                                    <InputOrganizerSize
                                        style={{
                                            marginRight: '20px',
                                        }}
                                        borderColor={validInput(
                                            formErrors.licensePlate
                                        )}
                                    >
                                        <label>Placa *</label>
                                        <input
                                            type="text"
                                            {...register('licensePlate', {
                                                required: isVehicle,
                                            })}
                                            defaultValue={
                                                editedProduct
                                                    ?.VehicleInformations
                                                    ?.licensePlate as any
                                            }
                                        />
                                        {formErrors.licensePlate &&
                                            exibirErro(formErrors.licensePlate)}
                                    </InputOrganizerSize>
                                    <InputOrganizerSize
                                        style={{
                                            marginRight: '20px',
                                        }}
                                        borderColor={validInput(
                                            formErrors.color
                                        )}
                                    >
                                        <label>Cor *</label>
                                        <input
                                            type="text"
                                            {...register('color', {
                                                required: isVehicle,
                                            })}
                                            defaultValue={
                                                editedProduct
                                                    ?.VehicleInformations
                                                    ?.color as any
                                            }
                                        />
                                        {formErrors.color &&
                                            exibirErro(formErrors.color)}
                                    </InputOrganizerSize>

                                    <InputOrganizerSize
                                        borderColor={validInput(
                                            formErrors.doorsNumber
                                        )}
                                    >
                                        <label>Portas *</label>
                                        <select
                                            {...register('numberDoors', {
                                                required: isVehicle,
                                            })}
                                            defaultValue={
                                                editedProduct
                                                    ?.VehicleInformations
                                                    ?.numberDoors as any
                                            }
                                        >
                                            <option
                                                value="2"
                                                style={{
                                                    fontFamily:
                                                        'Poppins, semi-bold',
                                                    fontSize: '13px',
                                                    color: 'black',
                                                }}
                                            >
                                                2 portas
                                            </option>
                                            <option
                                                value="4"
                                                style={{
                                                    fontFamily:
                                                        'Poppins, semi-bold',
                                                    fontSize: '13px',
                                                    color: 'black',
                                                }}
                                            >
                                                4 portas
                                            </option>
                                        </select>
                                        {formErrors.numberDoors &&
                                            exibirErro(formErrors.numberDoors)}
                                    </InputOrganizerSize>
                                </div>

                                <div className="other-container">
                                    <InputOrganizerSize
                                        style={{ marginRight: '20px' }}
                                        borderColor={validInput(
                                            formErrors.directionType
                                        )}
                                    >
                                        <label>Direção *</label>
                                        <input
                                            type="text"
                                            {...register('directionType', {
                                                required: isVehicle,
                                            })}
                                            defaultValue={
                                                editedProduct
                                                    ?.VehicleInformations
                                                    ?.directionType as any
                                            }
                                        />
                                        {formErrors.directionType &&
                                            exibirErro(
                                                formErrors.directionType
                                            )}
                                    </InputOrganizerSize>

                                    <InputOrganizerSize
                                        borderColor={validInput(
                                            formErrors.exchange
                                        )}
                                    >
                                        <label>Câmbio *</label>
                                        <input
                                            type="text"
                                            {...register('exchange', {
                                                required: isVehicle,
                                            })}
                                            defaultValue={
                                                editedProduct
                                                    ?.VehicleInformations
                                                    ?.exchange as any
                                            }
                                        />
                                        {formErrors.exchange &&
                                            exibirErro(formErrors.exchange)}
                                    </InputOrganizerSize>
                                </div>
                            </Route>
                            <Route
                                path={`/register-product/:categoryId/:productId/properties/edit`}
                            >
                                <div
                                    className="other-container"
                                    style={{
                                        width: '100%',
                                        marginBottom: '25px',
                                    }}
                                >
                                    <InputOrganizerSize
                                        style={{
                                            marginRight: '20px',
                                        }}
                                        borderColor={validInput(formErrors.cep)}
                                    >
                                        <label>CEP *</label>
                                        <input
                                            placeholder="xx.xxx-xx"
                                            type="number"
                                            {...register('cep', {
                                                required: isProperty,
                                            })}
                                            defaultValue={
                                                editedProduct
                                                    ?.PropertyInformations
                                                    ?.cep as any
                                            }
                                        />
                                        {formErrors.cep &&
                                            exibirErro(formErrors.cep)}
                                    </InputOrganizerSize>

                                    <InputOrganizerSize
                                        style={{
                                            marginRight: '20px',
                                        }}
                                        borderColor={validInput(
                                            formErrors.street
                                        )}
                                    >
                                        <label>Endereço *</label>
                                        <input
                                            type="text"
                                            {...register('street', {
                                                required: isProperty,
                                            })}
                                            defaultValue={
                                                editedProduct
                                                    ?.PropertyInformations
                                                    ?.street as any
                                            }
                                        />
                                        {formErrors.street &&
                                            exibirErro(formErrors.street)}
                                    </InputOrganizerSize>

                                    <InputOrganizerSize
                                        borderColor={validInput(
                                            formErrors.city
                                        )}
                                    >
                                        <label>Cidade *</label>
                                        <input
                                            {...register('city', {
                                                required: isProperty,
                                            })}
                                            defaultValue={
                                                editedProduct
                                                    ?.PropertyInformations
                                                    ?.number as any
                                            }
                                        />
                                        {formErrors.city &&
                                            exibirErro(formErrors.city)}
                                    </InputOrganizerSize>
                                </div>

                                <div
                                    className="other-container"
                                    style={{
                                        width: '100%',
                                        marginBottom: '25px',
                                    }}
                                >
                                    <InputOrganizerSize
                                        style={{
                                            marginRight: '20px',
                                        }}
                                        borderColor={validInput(
                                            formErrors.state
                                        )}
                                    >
                                        <label>Estado *</label>
                                        <input
                                            {...register('state', {
                                                required: isProperty,
                                            })}
                                            defaultValue={
                                                editedProduct
                                                    ?.PropertyInformations
                                                    ?.cep as any
                                            }
                                        />
                                        {formErrors.state &&
                                            exibirErro(formErrors.state)}
                                    </InputOrganizerSize>

                                    <InputOrganizerSize
                                        style={{
                                            marginRight: '20px',
                                        }}
                                        borderColor={validInput(
                                            formErrors.neighborhood
                                        )}
                                    >
                                        <label>Bairro *</label>
                                        <input
                                            type="text"
                                            {...register('neighborhood', {
                                                required: isProperty,
                                            })}
                                            defaultValue={
                                                editedProduct
                                                    ?.PropertyInformations
                                                    ?.street as any
                                            }
                                        />
                                        {formErrors.neighborhood &&
                                            exibirErro(formErrors.neighborhood)}
                                    </InputOrganizerSize>

                                    <InputOrganizerSize
                                        borderColor={validInput(
                                            formErrors.number
                                        )}
                                    >
                                        <label>Número *</label>
                                        <input
                                            type="number"
                                            {...register('number', {
                                                required: isProperty,
                                            })}
                                            defaultValue={
                                                editedProduct
                                                    ?.PropertyInformations
                                                    ?.number as any
                                            }
                                        />
                                        {formErrors.number &&
                                            exibirErro(formErrors.number)}
                                    </InputOrganizerSize>
                                </div>

                                <div className="other-container">
                                    <InputOrganizerSize
                                        borderColor={validInput(
                                            formErrors.complement
                                        )}
                                        style={{ marginBottom: '10px' }}
                                    >
                                        <label>Complemento *</label>
                                        <input
                                            {...register('complement', {
                                                required: isProperty,
                                            })}
                                            defaultValue={
                                                editedProduct
                                                    ?.PropertyInformations
                                                    ?.rooms as any
                                            }
                                        />
                                        {formErrors.complement &&
                                            exibirErro(formErrors.complement)}
                                    </InputOrganizerSize>
                                </div>

                                <label>Informações do Imóvel *</label>
                                <div
                                    className="other-container"
                                    style={{ marginBottom: '20px' }}
                                >
                                    <InputOrganizerSize
                                        style={{ marginRight: '20px' }}
                                        borderColor={validInput(
                                            formErrors.numberRooms
                                        )}
                                    >
                                        <label>Quartos *</label>
                                        <input
                                            type="number"
                                            {...register('numberRooms', {
                                                required: isProperty,
                                            })}
                                            defaultValue={
                                                editedProduct
                                                    ?.PropertyInformations
                                                    ?.bathrooms as any
                                            }
                                        />
                                        {formErrors.numberRooms &&
                                            exibirErro(formErrors.bathrooms)}
                                    </InputOrganizerSize>
                                    <InputOrganizerSize
                                        borderColor={validInput(
                                            formErrors.bathrooms
                                        )}
                                    >
                                        <label>Banheiros *</label>
                                        <input
                                            type="number"
                                            {...register('bathrooms', {
                                                required: isProperty,
                                            })}
                                            defaultValue={
                                                editedProduct
                                                    ?.PropertyInformations
                                                    ?.bathrooms as any
                                            }
                                        />
                                        {formErrors.numberRooms &&
                                            exibirErro(formErrors.bathrooms)}
                                    </InputOrganizerSize>
                                </div>

                                <div className="other-container">
                                    <InputOrganizerSize
                                        borderColor={validInput(
                                            formErrors.garage
                                        )}
                                    >
                                        <label>Garagem *</label>
                                        <input
                                            type="number"
                                            {...register('garage', {
                                                required: isProperty,
                                            })}
                                            defaultValue={
                                                editedProduct
                                                    ?.PropertyInformations
                                                    ?.garage as any
                                            }
                                        />
                                        {formErrors.numberRooms &&
                                            exibirErro(formErrors.garage)}
                                    </InputOrganizerSize>
                                </div>
                            </Route>
                        </Container>
                    </Title>
                    <Title>
                        <h2>Imagens</h2>
                        <ImagesContainer>
                            <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-py-4">
                                <span className="img-instructions">
                                    <h1 className="h1-instruction">
                                        Adicione ao menos 1 foto.
                                    </h1>
                                </span>
                                <span
                                    className={`tw-rounded-sm tw-bg-primary-500 tw-py-1 tw-px-2 tw-font-primary tw-font-semibold tw-text-white`}
                                >{`${imgs.length} / ${arrayLengthLimit}`}</span>
                            </div>
                            <div className="img-container">
                                <label htmlFor="file1">
                                    <span>
                                        <img src={add} alt=" " />
                                        <input
                                            type="file"
                                            id="file1"
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>
                                            ) => {
                                                e.target.files &&
                                                    addImage(e.target.files)
                                            }}
                                            multiple
                                            accept="image/png,image/jpeg"
                                        />
                                    </span>
                                </label>

                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        overflowX: 'auto',
                                        height: '180px',
                                        width: '544px',
                                    }}
                                >
                                    {imgs.length > 0 &&
                                        imgs.map((img: any, index: any) => (
                                            <span key={index}>
                                                <div className="preview">
                                                    <div className="delete-icon-background">
                                                        <Exit
                                                            onClick={() =>
                                                                deleteImage(img)
                                                            }
                                                        />
                                                    </div>
                                                    <img
                                                        style={{
                                                            objectFit: 'cover',
                                                        }}
                                                        src={
                                                            img.url ||
                                                            URL.createObjectURL(
                                                                img
                                                            )
                                                        }
                                                        alt=" "
                                                        key={index}
                                                    />
                                                </div>
                                            </span>
                                        ))}
                                </div>
                            </div>
                            {imagesRequirements &&
                                imagesRequirements.map((requirement, index) => {
                                    return (
                                        <p key={index}>{`* ${requirement}`}</p>
                                    )
                                })}
                        </ImagesContainer>
                    </Title>

                    <Route
                        path={`/register-product/:categoryId/:productId/vehicles/edit`}
                    >
                        <TitleCategory>
                            <h2>Subcategoria</h2>
                            <Categorys>
                                <div className="img-container">
                                    {data &&
                                        dataSubCategories?.getAllSubCategoriesByCategory.map(
                                            (
                                                subCategory: any,
                                                index: number
                                            ) => (
                                                <>
                                                    <span
                                                        className={`option ${
                                                            selectedSubCategory ===
                                                            subCategory.id
                                                                ? 'option-active'
                                                                : ''
                                                        }`}
                                                        onClick={() =>
                                                            setSelectedSubCategory(
                                                                subCategory.id
                                                            )
                                                        }
                                                        key={index}
                                                    >
                                                        {subCategory.name}
                                                    </span>
                                                </>
                                            )
                                        )}
                                </div>
                            </Categorys>
                        </TitleCategory>
                    </Route>
                    <Route
                        path={`/register-product/:categoryId/:productId/properties/edit`}
                    >
                        <TitleCategory>
                            <h2>Subcategoria</h2>
                            <Categorys>
                                <div className="img-container">
                                    {data &&
                                        dataSubCategories?.getAllSubCategoriesByCategory.map(
                                            (
                                                subCategory: any,
                                                index: number
                                            ) => (
                                                <>
                                                    <span
                                                        className={`option ${
                                                            selectedSubCategory ===
                                                            subCategory.id
                                                                ? 'option-active'
                                                                : ''
                                                        }`}
                                                        onClick={() =>
                                                            setSelectedSubCategory(
                                                                subCategory.id
                                                            )
                                                        }
                                                        key={index}
                                                    >
                                                        {subCategory.name}
                                                    </span>
                                                </>
                                            )
                                        )}
                                </div>
                            </Categorys>
                        </TitleCategory>
                    </Route>

                    <Route
                        path={`/register-product/:categoryId/:productId/product/edit`}
                    >
                        <TitleCategory>
                            <h2>Variações</h2>
                            <Categorys>
                                <div className="img-instructions">
                                    {productVariation &&
                                        productVariation.map(
                                            (variacao, index) => (
                                                <VariationContainer key={index}>
                                                    {variacao.cor && (
                                                        <>
                                                            <h2>Cor:</h2>
                                                            <VariationItem
                                                                cor={
                                                                    variacao.cor
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                    <h2>Estoque:</h2>
                                                    <VariationItem>
                                                        <p>
                                                            {
                                                                variacao.quantidade
                                                            }
                                                        </p>
                                                    </VariationItem>

                                                    {variacao.tamanho && (
                                                        <>
                                                            <h2>Tamanho:</h2>
                                                            <VariationItem>
                                                                <p>
                                                                    {
                                                                        variacao.tamanho
                                                                    }
                                                                </p>
                                                            </VariationItem>
                                                        </>
                                                    )}
                                                    <button type="button">
                                                        <IoMdRemoveCircle
                                                            onClick={() =>
                                                                deleteVariacao(
                                                                    variacao.id
                                                                )
                                                            }
                                                            size={25}
                                                            color="red"
                                                        />
                                                    </button>
                                                </VariationContainer>
                                            )
                                        )}
                                    <VariationContainer>
                                        <h2>Adicionar outras variações.</h2>
                                        {!addVariation && (
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    setAddVariation(true)
                                                }
                                            >
                                                <IoMdAddCircle
                                                    size={25}
                                                    color="green"
                                                />
                                            </button>
                                        )}
                                        {addVariation && (
                                            <button type="button">
                                                <IoMdRemoveCircle
                                                    onClick={() =>
                                                        setAddVariation(false)
                                                    }
                                                    size={25}
                                                    color="red"
                                                />
                                            </button>
                                        )}
                                    </VariationContainer>

                                    {addVariation && (
                                        <>
                                            <VariationContainer>
                                                <div className="newVariationContainer img-instructions">
                                                    {variationsOutput &&
                                                        variationsOutput.getAllVariationProduct.map(
                                                            (variacao) => (
                                                                <>
                                                                    <button
                                                                        type="button"
                                                                        className="variation-button"
                                                                        onClick={() =>
                                                                            configurarVariacao(
                                                                                variacao
                                                                            )
                                                                        }
                                                                    >
                                                                        {
                                                                            variacao.name
                                                                        }
                                                                    </button>
                                                                </>
                                                            )
                                                        )}
                                                </div>
                                            </VariationContainer>
                                            <VariationContainer>
                                                <div className="newVariationContainer">
                                                    {variations &&
                                                        variations.map(
                                                            (variacao) => {
                                                                if (
                                                                    variacao.name ===
                                                                    'COR'
                                                                ) {
                                                                    return (
                                                                        <>
                                                                            <div className="container-column-variacao">
                                                                                <h2>
                                                                                    Cor:
                                                                                </h2>
                                                                                <CompactPicker
                                                                                    onChangeComplete={(
                                                                                        value
                                                                                    ) =>
                                                                                        setVariacaoState(
                                                                                            {
                                                                                                ...variacaoState,
                                                                                                cor: value.hex,
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                                if (
                                                                    variacao.name ===
                                                                    'TAMANHO'
                                                                ) {
                                                                    return (
                                                                        <>
                                                                            <div className="container-column-variacao">
                                                                                <h2>
                                                                                    Tamanho:{' '}
                                                                                </h2>
                                                                                <input
                                                                                    type="text"
                                                                                    placeholder="Insira o Tamanho"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        setVariacaoState(
                                                                                            {
                                                                                                ...variacaoState,
                                                                                                tamanho:
                                                                                                    e
                                                                                                        .target
                                                                                                        .value,
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                                return <></>
                                                            }
                                                        )}
                                                    <div className="margin-top-container-variations">
                                                        <h2 className="title-quantidade">
                                                            Estoque:
                                                        </h2>
                                                        <input
                                                            type="text"
                                                            placeholder="Estoque"
                                                            value={
                                                                variacaoState.quantidade
                                                            }
                                                            onChange={(e) =>
                                                                setVariacaoState(
                                                                    {
                                                                        ...variacaoState,
                                                                        quantidade:
                                                                            Number(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            ),
                                                                    }
                                                                )
                                                            }
                                                        />
                                                        <button
                                                            type="button"
                                                            onClick={
                                                                adicionarVariacao
                                                            }
                                                        >
                                                            <IoIosAddCircleOutline
                                                                size={25}
                                                                color="green"
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            </VariationContainer>
                                            <div>
                                                <span>
                                                    Sugestão: Para adicionar
                                                    qualquer variação, clique na
                                                    cor, insira o tamanho
                                                    desejado e também a
                                                    quantidade em estoque. Para
                                                    finalizar com sucesso,
                                                    clique no botão de adicionar
                                                    ao lado.
                                                </span>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </Categorys>
                        </TitleCategory>
                    </Route>
                    <Route
                        path={`/register-product/:categoryId/:productId/product/edit`}
                    >
                        <TitleCategory>
                            <h2>Categorias</h2>
                            <Categorys>
                                <InputOrganizerSize style={{ width: '20%' }}>
                                    <label>Buscar Categoria</label>
                                    <input
                                        type="text"
                                        value={categorySearchQuery}
                                        onChange={({ target }) =>
                                            setCategorySearchQuery(target.value)
                                        }
                                    />
                                </InputOrganizerSize>
                                <div className="img-container">
                                    {filterCategories &&
                                        filterCategories.map(
                                            (category: any) => (
                                                <>
                                                    <NavLink
                                                        className="option"
                                                        activeClassName="option-active"
                                                        to={`/register-product/${category.id}/${pageParams.productId}/product/edit`}
                                                        key={category.id}
                                                    >
                                                        {category.name}
                                                    </NavLink>
                                                </>
                                            )
                                        )}
                                </div>
                                <p>
                                    *Tanto as categorias quanto as subcategorias
                                    se encontram na ordem alfabetica.
                                </p>
                                <p>
                                    *Caso não encontre a categoria exata,
                                    escolha “Outros”.
                                </p>
                            </Categorys>
                        </TitleCategory>
                        <TitleCategory>
                            <h2>Subcategoria</h2>
                            <Categorys>
                                <div className="img-container">
                                    {data &&
                                        dataSubCategories?.getAllSubCategoriesByCategory.map(
                                            (
                                                subCategory: any,
                                                index: number
                                            ) => (
                                                <>
                                                    <span
                                                        className={`option ${
                                                            selectedSubCategory ===
                                                            subCategory.id
                                                                ? 'option-active'
                                                                : ''
                                                        }`}
                                                        onClick={() =>
                                                            setSelectedSubCategory(
                                                                subCategory.id
                                                            )
                                                        }
                                                        key={index}
                                                    >
                                                        {subCategory.name}
                                                    </span>
                                                </>
                                            )
                                        )}
                                </div>
                            </Categorys>
                        </TitleCategory>
                    </Route>

                    <PriceTitleContainer>
                        <h2>Preço e pagamento</h2>
                        <PricePayment>
                            <div className="organizer">
                                <div className="sell-type-buttons-container">
                                    <div
                                        className="sell-type-container"
                                        style={{ marginBottom: '0' }}
                                    >
                                        <div className={'button-sell-active'}>
                                            Venda
                                        </div>
                                    </div>
                                </div>
                                <h2 className="stylesTitlePechincha">
                                    Você deseja adicionar a opção de Pechincha?
                                </h2>
                                <span>
                                    *É uma boa ferramenta para aumentar as
                                    vendas! Em Valor Mínimo, coloque o menor
                                    preço que aceitaria por seu produto.
                                </span>
                                <div className="sell-type-buttons-container">
                                    <div className="sell-type-container">
                                        <div
                                            onClick={() => setBargain(true)}
                                            className={
                                                bargain
                                                    ? 'bargain-active'
                                                    : undefined
                                            }
                                        >
                                            Sim
                                        </div>

                                        {
                                            <div
                                                onClick={() =>
                                                    setBargain(false)
                                                }
                                                className={
                                                    !bargain
                                                        ? 'bargain-active'
                                                        : undefined
                                                }
                                            >
                                                Não
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="sell-options">
                                    {bargain ? (
                                        <>
                                            <InputOrganizer
                                                borderColor={validInput(
                                                    formErrors.price
                                                )}
                                            >
                                                <label>Valor *</label>
                                                <input
                                                    type="text"
                                                    {...register('price', {
                                                        required: true,
                                                        maxLength: 10,
                                                    })}
                                                    onChange={(e) => {
                                                        setValueMask(
                                                            handleMoneyValues(
                                                                e.target.value
                                                            )
                                                        )
                                                    }}
                                                    value={valueMask}
                                                />
                                                {formErrors.numberRooms &&
                                                    exibirErro(
                                                        formErrors.price
                                                    )}
                                            </InputOrganizer>
                                            <></>
                                            <InputOrganizer
                                                style={{
                                                    borderRadius: '7px',
                                                }}
                                                borderColor={validInput(
                                                    formErrors.initialValue
                                                )}
                                            >
                                                <label>Valor Mínimo *</label>

                                                <input
                                                    type="text"
                                                    {...register(
                                                        'initialValue',
                                                        {
                                                            required: isProduct,
                                                        }
                                                    )}
                                                    onChange={(e) =>
                                                        setBargainMask(
                                                            handleMoneyValues(
                                                                e.target.value
                                                            )
                                                        )
                                                    }
                                                    value={bargainMask}
                                                />

                                                {valorMinimoSuperior && (
                                                    <FieldAlert>
                                                        Valor mínimo muito alto.
                                                    </FieldAlert>
                                                )}
                                                {formErrors.initialValue &&
                                                    exibirErro(
                                                        formErrors.initialValue
                                                    )}
                                            </InputOrganizer>
                                            <InputOrganizer>
                                                <label>
                                                    Número de parcelas *
                                                </label>
                                                <select
                                                    {...register(
                                                        'maxInstallments',
                                                        {
                                                            required: true,
                                                        }
                                                    )}
                                                >
                                                    <option
                                                        value=""
                                                        disabled
                                                        selected
                                                    >
                                                        --selecione--
                                                    </option>
                                                    {installments.map(
                                                        (installment) => (
                                                            <option
                                                                value={
                                                                    installment
                                                                }
                                                                key={
                                                                    installment
                                                                }
                                                            >
                                                                {installment}x
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </InputOrganizer>
                                        </>
                                    ) : (
                                        <>
                                            <InputOrganizer
                                                borderColor={validInput(
                                                    formErrors.price
                                                )}
                                            >
                                                <label>Valor *</label>
                                                <input
                                                    type="text"
                                                    {...register('price', {
                                                        required: true,
                                                        maxLength: 10,
                                                    })}
                                                    onChange={(e) => {
                                                        setValueMask(
                                                            handleMoneyValues(
                                                                e.target.value
                                                            )
                                                        )
                                                    }}
                                                    value={valueMask}
                                                />
                                                {formErrors.price &&
                                                    exibirErro(
                                                        formErrors.price
                                                    )}
                                            </InputOrganizer>
                                            <InputOrganizer>
                                                <label>
                                                    Número de parcelas *
                                                </label>
                                                <select
                                                    {...register(
                                                        'maxInstallments',
                                                        {
                                                            required: true,
                                                        }
                                                    )}
                                                >
                                                    <option
                                                        value=""
                                                        disabled
                                                        selected
                                                    >
                                                        --selecione--
                                                    </option>
                                                    {installments.map(
                                                        (installment) => (
                                                            <option
                                                                value={
                                                                    installment
                                                                }
                                                                key={
                                                                    installment
                                                                }
                                                            >
                                                                {installment}x
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </InputOrganizer>
                                        </>
                                    )}
                                    <InputOrganizer
                                        borderColor={validInput(
                                            formErrors.stock
                                        )}
                                    >
                                        <label>{`Em estoque *${
                                            disableStockEdit
                                                ? ` (definido por variações)`
                                                : ''
                                        }`}</label>
                                        <input
                                            type="number"
                                            {...register('stock', {
                                                required: true,
                                                min: 1,
                                            })}
                                            defaultValue={
                                                editedProduct?.stock as number
                                            }
                                            disabled={disableStockEdit}
                                            className={
                                                disableStockEdit
                                                    ? `tw-cursor-not-allowed tw-bg-smoke-100`
                                                    : undefined
                                            }
                                        />
                                        {formErrors.stock &&
                                            exibirErro(formErrors.stock)}
                                    </InputOrganizer>
                                </div>
                            </div>
                        </PricePayment>
                    </PriceTitleContainer>

                    <Route path="/register-product/:categoryId/:productId/product/edit">
                        <Title>
                            <h2>Opções de envio</h2>

                            <Container
                                style={{
                                    marginBottom: '20px',
                                }}
                            >
                                <div className="prompt-delivery">
                                    <h2>À pronta entrega?</h2>
                                    <div className="prompt-delivery-buttons">
                                        <button
                                            className={`prompt-delivery-button ${
                                                promptDelivery ? 'active' : ''
                                            }`}
                                            id="yes"
                                            onClick={() =>
                                                setPromptDelivery(true)
                                            }
                                            type="button"
                                        >
                                            Sim
                                        </button>
                                        <button
                                            className={`prompt-delivery-button ${
                                                !promptDelivery ? 'active' : ''
                                            }`}
                                            id="no"
                                            onClick={() =>
                                                setPromptDelivery(false)
                                            }
                                            type="button"
                                        >
                                            Não
                                        </button>
                                    </div>
                                </div>

                                {promptDelivery === false && (
                                    <div>
                                        <h2>
                                            Prazo estimado em que o produto
                                            ficará disponível:
                                        </h2>
                                        <InputOrganizerSize>
                                            <label>Prazo (dias)*</label>
                                            <input
                                                type="number"
                                                {...register('deadline', {
                                                    required:
                                                        history.location
                                                            .pathname ===
                                                        `/edit-product/${pageParams.categoryId}/product`,
                                                })}
                                            />
                                        </InputOrganizerSize>
                                    </div>
                                )}
                                <div className="prompt-delivery">
                                    <h2>Adicionar frete grátis?</h2>
                                    <span>
                                        Você deseja arcar com as despesas de
                                        entrega, disponibilizando assim, frete
                                        grátis para os seus clientes? Ao
                                        escolher essa opção, o produto estará
                                        disponível somente na modalidade de
                                        retirada com o vendedor.
                                    </span>
                                    <div className="prompt-delivery-buttons">
                                        <button
                                            className={`prompt-delivery-button ${
                                                hasFreeDelivery ? 'active' : ''
                                            }`}
                                            id="yes"
                                            onClick={() => {
                                                setHasFreeDelivery(true)
                                                setValue('withdraw', 'Sim')
                                            }}
                                            type="button"
                                        >
                                            Sim
                                        </button>
                                        <button
                                            className={`prompt-delivery-button ${
                                                !hasFreeDelivery ? 'active' : ''
                                            }`}
                                            id="no"
                                            onClick={() =>
                                                setHasFreeDelivery(false)
                                            }
                                            type="button"
                                        >
                                            Não
                                        </button>
                                    </div>
                                </div>

                                <h2>
                                    Escolha o endereço de onde o produto partirá
                                    para o envio:
                                </h2>
                                {user.ProfileAddress?.map(
                                    (address: ProfileAddress) => (
                                        <>
                                            <div
                                                className="options-container"
                                                style={{
                                                    border: formErrors.addressId
                                                        ? '1px solid red'
                                                        : '1px solid black',
                                                }}
                                            >
                                                <Radio
                                                    color={'default'}
                                                    style={{
                                                        width: '0px',
                                                        height: '0px',
                                                        margin: '15px',
                                                    }}
                                                    {...register('addressId', {
                                                        required:
                                                            history.location
                                                                .pathname ===
                                                            `/register-product/${pageParams.categoryId}/product`,
                                                    })}
                                                    onClick={() =>
                                                        setAddressId(address.id)
                                                    }
                                                    value={address.id}
                                                    checked={
                                                        addressId === address.id
                                                    }
                                                />{' '}
                                                <span>
                                                    {address.CEP} -
                                                    {address.street}{' '}
                                                    {address.number} -{' '}
                                                    {address.city},
                                                    {address.state}{' '}
                                                </span>
                                            </div>
                                            {formErrors.addressId &&
                                                exibirErro(
                                                    formErrors.addressId
                                                )}
                                        </>
                                    )
                                )}
                            </Container>
                        </Title>
                    </Route>
                    <ButtonConfirm>
                        <button className="delete-button">Cancelar</button>
                        <button className="confirm-button" type="submit">
                            Salvar alterações
                        </button>
                    </ButtonConfirm>
                </form>
            </PageContainer>
        </>
    )
}
export default EditProduct
