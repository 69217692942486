import React from 'react'
// eslint-disable-next-line unicorn/prefer-node-protocol
import { env } from 'process'
import { Link } from 'react-router-dom'
import IProductsCart from '../../models/ICart'
import {
    MdOutlineDelete as Remove,
    MdOutlineAdd as Plus,
    MdOutlineHorizontalRule as Minus
} from 'react-icons/md'
import { useDispatch } from 'react-redux'
import {
    insertProductQuantity,
    removeProductCartAction
} from '../../actions/ProductsCartActions'
import Modal from '../Modal/Modal'

type Properties = {
    item: IProductsCart
}

type QuantityChange = {
    mode: 'add' | 'subtract' | 'remove'
    value?: 1
}

const CartItem = ({ item }: Properties): JSX.Element => {
    const { product, quantity } = item.parameters
    const dispatch = useDispatch()

    const handleChange = ({ mode, value }: QuantityChange): void => {
        switch (mode) {
            case 'add':
                if (product.stock && value) {
                    if (product.stock < quantity + value) {
                        throw new Error(
                            'Product does not have more stock.'
                        )
                    } else {
                        dispatch(
                            insertProductQuantity(product.id, true)
                        )
                    }
                }

                break
            case 'subtract':
                if (product.stock && value) {
                    if (product.stock <= value) {
                        throw new Error(
                            'Product already in the smallest quantity possible.'
                        )
                    } else {
                        dispatch(
                            insertProductQuantity(product.id, false)
                        )
                    }
                }

                break
            case 'remove':
                dispatch(removeProductCartAction(product.id))
                break
            default:
                break
        }
    }

    const QuantitySelector = (): JSX.Element => {
        return (
            <div
                className="smb-cart-item-quantity tw-flex tw-flex-row
            tw-items-center"
            >
                <button
                    onClick={() => {
                        if (quantity > 1) {
                            handleChange({
                                mode: 'subtract',
                                value: 1
                            })
                        }
                    }}
                    disabled={quantity === 1}
                    className="smb-cart-item-quantity-decrease tw-grid tw-h-6
                    tw-w-6 tw-place-items-center tw-rounded-l tw-border
                    tw-border-r-0 tw-border-solid tw-border-smoke-300
                    tw-bg-white tw-text-smoke-500 hover:tw-bg-smoke-100
                    disabled:tw-cursor-not-allowed disabled:tw-bg-white
                    disabled:tw-text-smoke-200 sm:tw-h-12 sm:tw-w-12
                    sm:tw-rounded-l-sm"
                >
                    <Minus className="tw-h-3.5 tw-w-auto sm:tw-h-7" />
                </button>
                <label
                    className="smb-cart-item-quantity-number tw-grid tw-h-6
                tw-place-items-center tw-border tw-border-y tw-border-x-0
                tw-border-solid tw-border-smoke-300 tw-bg-white tw-px-2
                tw-text-base sm:tw-h-12 sm:tw-px-4 sm:tw-text-xl"
                >
                    {quantity}
                </label>
                <button
                    onClick={() => {
                        if (product.stock && quantity < product.stock) {
                            handleChange({ mode: 'add', value: 1 })
                        }
                    }}
                    disabled={quantity === product.stock}
                    className="smb-cart-item-quantity-increase tw-grid tw-h-6
                    tw-w-6 tw-place-items-center tw-rounded-r tw-border
                    tw-border-l-0 tw-border-solid tw-border-smoke-300
                    tw-bg-white tw-text-smoke-500 hover:tw-bg-smoke-100
                    disabled:tw-cursor-not-allowed disabled:tw-bg-white
                    disabled:tw-text-smoke-200 sm:tw-h-12 sm:tw-w-12
                    sm:tw-rounded-r-sm"
                >
                    <Plus className="tw-h-3.5 tw-w-auto sm:tw-h-7" />
                </button>
            </div>
        )
    }

    return (
        <div
            className="smb-cart-item tw-w-full tw-border-0 tw-border-b
        tw-border-solid tw-border-smoke-300 tw-px-4 tw-py-2 sm:tw-px-8
        sm:tw-py-4"
        >
            <div
                className="smb-cart-item-header tw-flex tw-flex-row
            tw-items-start tw-justify-between md:tw-hidden"
            >
                <span
                    className="smb-cart-item-name tw-font-primary tw-text-lg
                tw-font-semibold tw-text-primary-700 tw-line-clamp-1
                sm:tw-text-2xl"
                >
                    {product.title}
                </span>
                <QuantitySelector />
            </div>
            <div
                className="tw-flex tw-space-x-2
            sm:tw-space-x-4"
            >
                <img
                    className="cart-item-picture tw-float-left tw-h-20 tw-w-32
                    tw-flex-shrink-0
					tw-rounded-sm tw-border tw-border-solid tw-border-smoke-300
                    tw-object-cover sm:tw-h-40 sm:tw-w-64"
                    src={`${process.env.REACT_APP_STATIC_URL}/product/${product.id}-${1}.jpeg`}
                />
                <div
                    className="smb-cart-item-details tw-flex tw-flex-col
                tw-justify-between tw-space-y-1 tw-w-full"
                >
                    <div
                        className="smb-cart-item-header tw-hidden tw-flex-row
                    tw-items-start tw-justify-between md:tw-flex"
                    >
                        <span
                            className="smb-cart-item-name tw-font-primary
                        tw-text-lg tw-font-semibold tw-text-primary-700
                        tw-line-clamp-1 sm:tw-text-2xl"
                        >
                            {product.title}
                        </span>
                        <QuantitySelector />
                    </div>
                    <span
                        className="smb-cart-item-description tw-mb-1
                    tw-font-primary tw-text-base tw-font-regular
                    tw-text-smoke-500 tw-line-clamp-2 sm:tw-mb-2 sm:tw-text-xl
                    sm:tw-line-clamp-3 md:tw-line-clamp-2"
                    >
                        {product.description.replace(/<.*?>/g, ' ')}
                    </span>
                    <div
                        className="tw-flex tw-flex-row tw-items-end
                    tw-justify-between"
                    >
                        <div className="cart-item-infos tw-flex tw-flex-col">
                            <span
                                className="cart-item-price tw-font-primary
                            tw-text-lg tw-font-semibold tw-text-smoke-500
                            sm:tw-text-2xl"
                            >
                                {product.price.toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL'
                                })}
                            </span>
                            <Link
                                to={`/product-view/${product.id}/all-data`}
                                className="cart-item-link tw-text-sm
                                tw-text-link-500 tw-underline
                                hover:tw-text-link-400 sm:tw-text-lg"
                            >
                                Ir para o produto
                            </Link>
                        </div>
                        <Modal
                            open={{
                                className: `smb-cart-item-remove tw-grid
                                    tw-place-items-center tw-bg-white
                                    hover:tw-bg-smoke-100 tw-rounded
                                    sm:tw-rounded-sm tw-border tw-border-solid
                                    tw-border-smoke-300 tw-h-6 sm:tw-h-12
                                    tw-w-6 sm:tw-w-12 tw-text-smoke-500`,
                                element: (
                                    <Remove
                                        className="tw-h-3.5 tw-w-auto sm:tw-h-7"
                                    />
                                )
                            }}
                            title={
                                <span
                                    className="smb-cart-item-title tw-m-0
                                tw-p-0 tw-font-primary tw-text-lg
                                tw-font-semibold tw-text-primary-700
                                sm:tw-text-2xl"
                                >
                                    Remover item
                                </span>
                            }
                            description={
                                <span
                                    className="smb-cart-item-description
                                tw-m-0 tw-p-0 tw-font-primary tw-text-base
                                tw-font-regular tw-text-smoke-500
                                sm:tw-text-xl"
                                >
                                    Você tem certeza que deseja remover
                                    o item: {product.title}?
                                </span>
                            }
                            action={{
                                body: {
                                    className: `smb-cart-item-remove-action
                                        tw-rounded sm:tw-rounded-sm
                                        hover:tw-border hover:tw-border-solid
                                        hover:tw-border-smoke-500
                                        hover:tw-bg-smoke-100 tw-font-primary
                                        tw-text-lg sm:tw-text-2xl
                                        tw-font-regular tw-text-smoke-500
                                        tw-py-1 sm:tw-py-2 tw-px-6 sm:tw-px-12`,
                                    element: <>Sim, tenho certeza!</>
                                },
                                fn: () => {
                                    handleChange({ mode: 'remove' })
                                }
                            }}
                            close={{
                                className: `smb-cart-item-remove-close
                                tw-rounded sm:tw-rounded-sm tw-border
                                tw-border-solid tw-border-lime-700
                                tw-bg-lime-500 hover:tw-bg-lime-400
                                tw-font-primary tw-font-regular tw-text-lg
                                sm:tw-text-2xl tw-text-white tw-py-1 sm:tw-py-2
                                tw-px-6 sm:tw-px-12`,
                                element: <>Não, mudei de ideia.</>
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CartItem
