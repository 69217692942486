import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import { appSocket } from '../../../../appSocket';
import Events from '../../../../Events';
import {useBlockUserMutation,useGetBlockStatusQuery} from '../../../../generated/graphql';
import IUserAccount from '../../../../models/IUserAccount';
import { AppState } from '../../../../store';
import AlertModal, { ModalHandles } from '../../../AlertModal/AlertModal';
import { ModalContainer } from './styles';

interface BlockProductInputs {
	reasonForBlocking: string;
}
type Props = {
	sellerId: number;
};

const BlockUserModal: React.FC<Props> = ({ sellerId }) => {
	const user: IUserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const [modalState, setModalState] = useState<boolean>(false);
	const [blockUser] = useBlockUserMutation();
	const { register, handleSubmit } = useForm<BlockProductInputs>();
	const warningModalRef = useRef<ModalHandles>(null);
	const [warningModalDescription, setWarningModalDescription] = useState<
		string
	>('');
	const { data } = useGetBlockStatusQuery({
		variables: { userId: sellerId },
		fetchPolicy: 'cache-and-network',
	});

	const onSubmit = async (data: any) => {
		try {
			await blockUser({
				variables: {
					block: true,
					userId: sellerId,
				},
			});
			appSocket.emit(Events.USER_BLOCKED, sellerId);
			setWarningModalDescription('Vendedor bloqueado com sucesso');
			warningModalRef.current?.handleOpenModal();
		} catch (err: any) {
			setWarningModalDescription('Falha ao bloquear vendedor');
			warningModalRef.current?.handleOpenModal();
		}
	};
	const unlockUser = async () => {
		try {
			await blockUser({
				variables: {
					block: false,
					userId: sellerId,
				},
			});
			setWarningModalDescription('Vendedor desbloqueado com sucesso');
			warningModalRef.current?.handleOpenModal();
		} catch (err: any) {
			setWarningModalDescription('Falha ao desbloquear vendedor');
			warningModalRef.current?.handleOpenModal();
		}
	};

	return (
		<>
			{user && user.userTypeId === 1 && (
				<button
					className="block-seller"
					onClick={() => {
						data?.getBlockStatus
							? unlockUser()
							: setModalState(true);
					}}
				>
					{data?.getBlockStatus
						? 'Desbloquear vendedor'
						: 'Bloquear Vendedor'}
				</button>
			)}
			<AlertModal
				ref={warningModalRef}
				AreaTitle="Anúncio"
				DescriptionText={warningModalDescription}
				reload={true}
			/>
			<Modal
				open={modalState}
				onClose={() => setModalState(false)}
				onOpen={() => setModalState(true)}
				style={{
					width: '560px',
					height: '184px',
					padding: ' 5px 30px 5px',
				}}
			>
				<ModalContainer onSubmit={handleSubmit(onSubmit)}>
					<h2>Bloquear vendedor</h2>
					<div className="select-container">
						<label>Motivo do bloqueio</label>
						<select
							{...register('reasonForBlocking',{ required: 'Selecione um motivo' })}
						>
							<option value="">motivo</option>
							<option value="block">
								Muitos produtos bloqueados
							</option>
						</select>
					</div>
					<button type="submit">Concluir</button>
				</ModalContainer>
			</Modal>
		</>
	);
};

export default BlockUserModal;
