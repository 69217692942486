import React from 'react';
import Layout from '../../components/Layout/Layout';
import UserProfile from '../../components/UserProfile/UserProfile';

const UserProfilePage: React.FC = () => {
	return (
		<Layout title="Meu Perfil">
			<UserProfile />
		</Layout>
	);
};
export default UserProfilePage;
