export const handleMoneyValues = (value: string | undefined | null) => {
    if (value) {
      value = value.replace(/\D/g, "");
      if (value?.length <= 1) {
        return `0,0${value}`;
      } else if (value?.indexOf("00") === 0) {
        return value?.replace("00", "0,");
      } else if (value?.indexOf("0") === 0) {
        return value.replace("0", "").replace(/(\d{1})(\d)/, "$1,$2");
      } else {
        const fullString = value.replace(",", "");
        let atString = fullString.slice(0, fullString.length - 2);
        while (atString.indexOf(".") !== -1) {
          atString = atString.replace(".", "");
        }
        if (atString.length < 4) {
          return (
            fullString.slice(0, fullString.length - 2) +
            "," +
            fullString.slice(fullString.length - 2)
          );
        } else if (atString.length % 3 === 1) {
          let returnString: string = atString.replace(/(\d{1})(\d)/, "$1.$2");
          for (
            let index: number = 1;
            index <= atString.length;
            index = index + 3
          ) {
            returnString = returnString.replace(/(\d{3})(\d)/, "$1.$2");
          }
          return returnString + "," + fullString.slice(fullString.length - 2);
        } else if (atString.length % 3 === 2) {
          let returnString: string = atString.replace(/(\d{2})(\d)/, "$1.$2");
          for (
            let index: number = 2;
            index <= atString.length;
            index = index + 3
          ) {
            returnString = returnString.replace(/(\d{3})(\d)/, "$1.$2");
          }
          return returnString + "," + fullString.slice(fullString.length - 2);
        } else if (atString.length % 3 === 0) {
          let returnString: string = atString.replace(/(\d{3})(\d)/, "$1.$2");
          for (
            let index: number = 3;
            index <= atString.length;
            index = index + 3
          ) {
            returnString = returnString.replace(/(\d{3})(\d)/, "$1.$2");
          }
          return returnString + "," + fullString.slice(fullString.length - 2);
        }
      }
    }
  };
  