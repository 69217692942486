import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';

type StyledElement = {
	className?: string;
	element: JSX.Element;
};

type Props = {
	title: JSX.Element;
	description?: JSX.Element;
	open: StyledElement;
	action?: {
		body: StyledElement;
		fn: () => any;
	};
	close?: StyledElement;
};

const Modal = ({ open, title, description, action, close }: Props) => {
	let [isOpen, setIsOpen] = useState<boolean>(false);

	const handleClose = () => {
		setIsOpen(false);
	};

	const handleOpen = () => {
		setIsOpen(true);
	};

	const handleAction = () => {
		if (action) action.fn();
		handleClose();
	};

	return (
		// TODO: Make modal follow background content
		<>
			<button onClick={handleOpen} className={open.className}>
				{open.element}
			</button>

			<Dialog
				open={isOpen}
				onClose={() => setIsOpen(false)}
				className="tw-fixed tw-z-10 tw-inset-0 tw-overflow-y-auto"
			>
				<div className="tw-flex tw-items-center tw-justify-center tw-min-h-screen">
					<Dialog.Overlay className="tw-fixed tw-inset-0 tw-bg-smoke-500 tw-opacity-30" />
					<div className="tw-relative tw-font-primary tw-font-normal tw-text-smoke-500 tw-border tw-border-solid tw-border-smoke-300 tw-rounded sm:tw-rounded-sm tw-bg-white tw-shadow tw-px-6 sm:tw-px-12 tw-py-2 sm:tw-py-4 tw-mx-4 sm:tw-mx-8 tw-my-2 sm:tw-my-4 tw-space-y-2 sm:tw-space-y-4">
						<Dialog.Title className="tw-m-0 tw-p-0 tw-font-primary tw-text-base">
							{/* Unset styles */}
							{title}
						</Dialog.Title>
						{description && (
							<Dialog.Description className="tw-m-0 tw-p-0 tw-font-primary tw-text-base">
								{/* Unset styles */}
								{description}
							</Dialog.Description>
						)}
						<div className="tw-flex tw-flex-col tw-space-y-2 sm:tw-space-y-0 sm:tw-flex-row tw-items-center tw-justify-between sm:tw-justify-between">
							{close && (
								<button
									onClick={handleClose}
									className={close.className}
								>
									{close.element}
								</button>
							)}
							{action && (
								<button
									onClick={handleAction}
									className={action.body.className}
								>
									{action.body.element}
								</button>
							)}
						</div>
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default Modal;
