import React from 'react'
import { ContainerCopyright } from './styles'

const CopyrightBanner: React.FC = () => {
    return (
        <ContainerCopyright>
            <div className="section-copyright">
                <span>
                    Copyright © 2021 SAMBAY EXPRESS LTDA | CNPJ n.°
                    40.559.278/0001-18 / Av. Des. Hugo Simas 1570 - Bom Retiro,
                    Curitiba - PR, 80520-251
                </span>
            </div>
        </ContainerCopyright>
    )
}

export default CopyrightBanner
