import styled from 'styled-components';

export const ProfileMenuButton = styled.div`
	.section-user-profile-title {
		display: flex;
		flex-direction: row;
		cursor: pointer;
		align-items: center;
		.icon-profile {
			width: 34px;
			height: 34px;
		}
		.user-profile-span-name {
			font-family: 'Poppins';
			font-size: 14px;
			color: white;
			padding-left: 7px;
			padding-right: 7px;
			width: 100%;
		}
		.style-icon-arrow-user-profile {
			width: 13px;
			height: 17.69px;
			cursor: pointer;
			margin-top: 9px;
		}
	}
`;

export const ProfileMenuBar = styled.div`
	background-color: #fff;
	box-shadow: 0px 2px 8px #00000014;
	position: absolute;
	border-radius: 8px;
	padding: 5px;
	overflow: auto;
	height: 250px;
	z-index: 1;
	width: 11%;
	top: 18.5rem;
	right: 10%;
	.section-menu-bar {
		display: flex;
		flex-direction: column;
		justify-content: center;
		.section-option-profile {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			height: 40px;
			margin-bottom: 8px;
			cursor: pointer;
			.style-icon-profile-bar {
				margin-top: 1.2rem;
				margin-right: 5px;
				height: 45%;
			}
			.span-profile-menu-bar {
				padding-bottom: 8px;
				font-size: 15px;
				font-family: 'Poppins';
				font-weight: 400;
			}
		}
		.section-option-sales {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			height: 40px;
			cursor: pointer;
			margin-bottom: 8px;
			.icon-vendas {
				height: 45%;
				margin-right: 5px;
			}
			.span-sales-menu-bar {
				font-size: 15px;
				font-family: 'Poppins';
				font-weight: 400;
				color: black;
			}
		}
		.section-option-help {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			height: 40px;
			cursor: pointer;
			margin-bottom: 8px;
			.icon-help {
				height: 45%;
				margin-right: 5px;
			}
			.span-help-menu-bar {
				font-size: 15px;
				font-family: 'Poppins';
				font-weight: 400;
				color: black;
			}
		}
		.section-option-exit {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			height: 40px;
			cursor: pointer;
			margin-bottom: 8px;
			.icon-sair {
				height: 45%;
				margin-right: 5px;
			}
			.span-sair-menu-bar {
				font-size: 15px;
				font-family: 'Poppins';
				font-weight: 400;
				color: black;
			}
		}
		.section-option-sac {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			height: 40px;
			cursor: pointer;
			margin-bottom: 8px;
			.icon-suporte {
				height: 18px;
				margin-right: 5px;
			}
			.span-sac-menu-bar {
				font-size: 15px;
				font-family: 'Poppins';
				font-weight: 400;
				color: black;
			}
		}

		.style-title-profile-menu-bar {
			font-family: 'Poppins';
			font-weight: 700;
			font-size: 16px;
			cursor: pointer;
			color: black;
			height: 40px;
			justify-content: center;
			display: flex;
			align-items: center;
			color: #6b97a5;
		}
		.span-profile-menu-bar {
			font-family: 'Poppins';
			font-weight: 400;
			font-size: 15px;
			cursor: pointer;
			color: black;
			height: 40px;
			justify-content: center;
			display: flex;
			align-items: center;
		}
	}
`;
