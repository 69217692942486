import styled from 'styled-components';

export const PageContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	background-color:#fff;
`;

export const Container = styled.div`
	width: 80%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color:#fff;
`;
export const PageHeader = styled.header`
	height: 107px;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	background-color:#fff;

	h3 {
		margin: 0;
		font-family: 'Poppins';
		font-weight: 600;
		font-size: min(20px, 2vw);
		color: #6b98a6;
		cursor: pointer;
		@media (max-width:475px){
			font-size:18px;
		}
	}
	img {
		margin-right: 20px;

		&:hover {
			cursor: pointer;
		}
	}
`;
export const MainCard = styled.main`
	width: 60%;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media (max-width:475px){
		width:100%;
	}

	img {
		width: 287px;
		height: 86px;

		margin-bottom: 65px;
	}

	.items-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 60%;
	}
`;
