import React from 'react'
import { ContainerPgtos } from './styles'
import entrega from '../../../assets//banners/desktop/13.png'
import entregaMobile from '../../../assets//banners/desktop/14.png'
import useWindow from '../../../hooks/useWindow'

const MiddleBanner = (): JSX.Element => {
    const window = useWindow()
    return (
        <ContainerPgtos>
            <div className="sectionBannerPgtos">
                {window.innerWidth < 768 ? (
                    <>
                        <div className="mobile_payment_banner_container">
                            <img src={entregaMobile} alt="mobile_payments" />
                        </div>
                    </>
                ) : (
                    <img
                        src={entrega}
                        alt="banner_pgtos"
                        className="imgPgtos"
                    />
                )}
            </div>
        </ContainerPgtos>
    )
}

export default MiddleBanner
