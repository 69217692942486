import { useState, useEffect, useCallback } from 'react'

interface Window {
    // TODO: Use DOM types
    innerHeight: number
    innerWidth: number
}

const useWindow = (): Window => {
    const { innerHeight, innerWidth } = window
    const [windowState, setWindowState] = useState<Window>({
        innerHeight,
        innerWidth,
    })

    const handleHeight = useCallback((): void => {
        setWindowState({ ...windowState, innerHeight })
    }, [innerHeight, windowState])

    const handleWidth = useCallback((): void => {
        setWindowState({ ...windowState, innerWidth })
    }, [innerWidth, windowState])

    useEffect(() => {
        window.addEventListener('resize', handleHeight, { passive: true })
        return () => {
            window.removeEventListener('resize', handleHeight)
        }
    }, [handleHeight])

    useEffect(() => {
        window.addEventListener('resize', handleWidth, { passive: true })
        return () => {
            window.removeEventListener('resize', handleWidth)
        }
    }, [handleWidth])

    return windowState
}
export default useWindow
