import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { useCreateUserAccountMutation } from '../../generated/graphql'
import {
    BackContainer,
    Container,
    DivRow,
    IconPassWord,
    InputsContainer,
    LabelBigContainer,
    LabelCPFContainer,
    LabelLoginContainer,
    RegisterInput,
    TitleContainer,
} from './Style'
import { FieldError, useForm } from 'react-hook-form'
import { ReactComponent as Back } from '../../assets/icons/back.svg'
import { AiOutlineEye } from '@react-icons/all-files/ai/AiOutlineEye'
import { AiOutlineEyeInvisible } from '@react-icons/all-files/ai/AiOutlineEyeInvisible'
import { FormControlLabel } from '@material-ui/core'
import { setCpfMaskFunc } from '../../utils/Masks'
import { ToastContainer } from 'react-toastify'
import useWindow from '../../hooks/useWindow'

interface RegisterValues {
    name: string
    type: string
    cpf: string
    email: string
    date: string
    password: string
    confirmPassword: string
}

const UserRegister: React.FC = () => {
    const window = useWindow()

const validInput = (error?: FieldError): string => {
    if (error && error.ref?.value.length > 0) {
        return 'solid 1px red'
    } else {
        return window.innerWidth > 768 ? 'solid 1px black' : 'solid 1px black'
    }
}
    const [createUser] = useCreateUserAccountMutation()
    const history = useHistory()
    const [cpfMask, setCpfMask] = useState<string>('')
    const [seePassWord, setSeePassWord] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<RegisterValues>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
    })
    const [acceptTerms, setAcceptTerms] = useState<boolean>(false)

    const onSubmit = async (registerValues: RegisterValues): Promise<void> => {
        let { name, cpf, email, password } = registerValues
        cpf = cpf.replace(/\D/g, '')
        try {
            const { data } = await createUser({
                variables: {
                    name,
                    userTypeId: 4,
                    cpf,
                    email,
                    password,
                    ratting: 0,
                },
            })

            history.push('/account-activation-warning', {
                state: {
                    email: data?.createUserAccount.email,
                },
            })
        } catch (error: any) {
            let { message } = error
            message = message.replace('GraphQL error: ', '')
            if (message === 'CNPJ/CPF já cadastrado')
                history.push(`/login?identifier=${cpf}&unavailable=true`)
            if (message === 'Email já cadastrado')
                history.push(`/login?email=${email}&unavailable=true`)
        }
    }

    return (
        <>
            <ToastContainer />
            <BackContainer onClick={() => history.push('/login')}>
                <Back className="IconVoltar" />
                <span>Voltar</span>
            </BackContainer>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Container>
                    <div className="organizer">
                        <TitleContainer>
                            <h1>Crie sua conta</h1>
                            <div className="line-container">
                                <h3>
                                    Cadastre-se na plataforma de marketplace
                                    genuinamente brasileira
                                </h3>
                                <div className="line"></div>
                            </div>
                        </TitleContainer>
                        <InputsContainer>
                            <div
                                className="input-organizer"
                                style={{ fontSize: '10px' }}
                            >
                                <LabelBigContainer>
                                    <label>
                                        Nome ou Razão Social/Nome Fantasia
                                    </label>
                                    <RegisterInput
                                        autoComplete="no"
                                        type="text"
                                        className="big-input"
                                        max="35"
                                        {...register('name', {
                                            required: 'Insira um nome',
                                        })}
                                        border={
                                            errors.name
                                                ? 'solid 1px red'
                                                : 'solid 1px black'
                                        }
                                        style={{
                                            border: `${validInput(errors.name)},
												`,
                                        }}
                                    />
                                    {errors.name && (
                                        <span className="field-errors">
                                            {`${errors.name.message}`}
                                        </span>
                                    )}
                                </LabelBigContainer>
                                <DivRow>
                                    <LabelCPFContainer>
                                        <label>CPF ou CNPJ</label>

                                        <RegisterInput
                                            type="text"
                                            autoComplete="no"
                                            id="cpf"
                                            value={cpfMask}
                                            placeholder="xxx.xxx.xxx-xx"
                                            {...register('cpf', {
                                                required:
                                                    'Insira um CPF ou CNPJ',

                                                pattern: {
                                                    value: /(\d{2}(?:\.?\d{3}){2}\/?\d{4}-?\d{2})|((?:\d{3}\.?){2}\d{3}-?\d{2})$/,
                                                    message:
                                                        'Insira um CPF ou CNPJ válido',
                                                },
                                            })}
                                            onChange={(e) => {
                                                setCpfMask(
                                                    setCpfMaskFunc(
                                                        e.target.value
                                                    )
                                                )
                                            }}
                                            style={{
                                                border: errors.cpf?.types
                                                    ?.pattern
                                                    ? 'solid 1px red'
                                                    : 'solid 1px black',
                                            }}
                                        />
                                        {errors.cpf && (
                                            <span className="field-errors">
                                                {`${errors.cpf.message}`}
                                            </span>
                                        )}
                                    </LabelCPFContainer>
                                </DivRow>
                                <DivRow>
                                    <LabelLoginContainer>
                                        <label>Email</label>
                                        <RegisterInput
                                            type="text"
                                            autoComplete="no"
                                            style={{
                                                border: `${validInput(
                                                    errors.email
                                                )}
													`,
                                            }}
                                            id="email"
                                            {...register('email', {
                                                required: 'Insira um email',
                                                pattern: {
                                                    value: /^[\w!#$%&'*+./=?^`{|}~-]+@[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?(?:\.[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?)*$/,
                                                    message:
                                                        'Insira um email válido',
                                                },
                                            })}
                                            border={
                                                errors.email
                                                    ? 'solid 1px red'
                                                    : 'solid 1px black'
                                            }
                                        />
                                        {errors.email && (
                                            <span className="field-errors">
                                                {`${errors.email.message}`}
                                            </span>
                                        )}
                                    </LabelLoginContainer>
                                    <LabelLoginContainer>
                                        <label>Senha</label>
                                        <RegisterInput
                                            type={
                                                !seePassWord
                                                    ? 'password'
                                                    : 'text'
                                            }
                                            autoComplete="no"
                                            {...register('password', {
                                                required: 'Insira uma senha',
                                                minLength: {
                                                    value: 6,
                                                    message:
                                                        'Mínimo de seis caracteres',
                                                },
                                            })}
                                            style={{
                                                border: `${validInput(
                                                    errors.password
                                                )}
													`,
                                            }}
                                            id="password"
                                            border={
                                                errors.password
                                                    ? 'solid 1px red'
                                                    : 'solid 1px black'
                                            }
                                        />
                                        <IconPassWord
                                            onClick={() =>
                                                setSeePassWord(!seePassWord)
                                            }
                                        >
                                            {seePassWord ? (
                                                <AiOutlineEye
                                                    size={25}
                                                    color="#898989"
                                                />
                                            ) : (
                                                <AiOutlineEyeInvisible
                                                    size={25}
                                                    color="#898989"
                                                />
                                            )}
                                        </IconPassWord>
                                        {errors.password && (
                                            <span className="field-errors">
                                                {`${errors.password.message}`}
                                            </span>
                                        )}
                                    </LabelLoginContainer>
                                </DivRow>
                            </div>
                            <div className="terms-of-use">
                                <FormControlLabel
                                    control={
                                        <input
                                            type="checkbox"
                                            onClick={() => {
                                                setAcceptTerms(!acceptTerms)
                                            }}
                                        />
                                    }
                                    label={
                                        <>
                                            <span className="label">
                                                Li e concordo com os{' '}
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={`${process.env.REACT_APP_STATIC_URL}/policy/termos.pdf`}
                                                    className="privacidades"
                                                >
                                                    Termos de Uso
                                                </a>{' '}
                                                da Sambay Express
                                            </span>
                                        </>
                                    }
                                />
                            </div>
                        </InputsContainer>
                        <div className="buttons-container">
                            <button
                                type="submit"
                                className="confirm-button"
                                style={{
                                    opacity: `${!acceptTerms ? '0.5' : '1'}`,
                                }}
                                disabled={!acceptTerms}
                            >
                                Criar conta
                            </button>
                        </div>
                    </div>
                </Container>
            </form>
        </>
    )
}
export default UserRegister
