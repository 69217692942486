import {IRemoveUserAction, IUpdateUserToken} from '../models/IUserAccount';
import IProductsCart, {
	IAddProductAction,
	IInserProductShippingWay,
	IInsertAddressIdProductAction,
	IInsertAddressProductAction,
	IInsertDeliveryDatetAction,
	IInsertdiscountInPriceProduct,
	IInsertProductInstallmentsProductAction,
	IInsertProductPaymentFormProductAction,
	IInsertProductQuantityProductAction,
	IInsertServiceId,
	IInsertShippingCostAction,
	IRemoveProductAction,
} from '../models/ICart';
import {ProfileAddress} from '../generated/graphql';
import {CupomType} from "../models/IProductParameters";

export interface Service {}
export const addProductCartAction = (
	productsCart: IProductsCart,
	productBids?: any
): IAddProductAction => ({
	type: 'ADD_PRODUCT',
	productsCart,
	productBids,
	variacaoId:productsCart.parameters.variacaoId
});

export const insertServiceId = (
	productId: number,
	serviceId: number,
	name: string,
	customPrice: string | undefined,
	deliveryTime: number | undefined
): IInsertServiceId => ({
	type: 'INSERT_SERVICE_ID',
	productId,
	serviceId,
	name,
	customPrice,
	deliveryTime,
});

export const insertShippingCostAction = (
	productId: number,
	shippingCost: number
): IInsertShippingCostAction => ({
	type: 'INSERT_PRODUCT_SHIPPING_COST',
	productId,
	shippingCost,
});
export const insertDeliveryDateAction = (
	productId: number,
	deliveryDate: string
): IInsertDeliveryDatetAction => ({
	type: 'INSERT_PRODUCT_DELIVERY_DATE',
	productId,
	deliveryDate,
});
export const insertAddressProductAction = (
	productId: number,
	address: ProfileAddress
): IInsertAddressProductAction => ({
	type: 'INSERT_PRODUCT_ADDRESS',
	productId,
	address,
});
export const insertUserToken = (token: string): IUpdateUserToken => ({
	type: 'UPDATE_USER_TOKEN',
	token,
});
export const removeProductCartAction = (
	productId: number
): IRemoveProductAction => ({
	type: 'REMOVE_CART_PRODUCT',
	productId,
});
export const insertProductQuantity = (
	productId: number,
	increase: boolean
): IInsertProductQuantityProductAction => ({
	type: 'INSERT_PRODUCT_QUANTITY',
	productId,
	increase,
});

export const insertDiscountInPriceProduct = (
	productId:number,
	newPrice:number,
	cupom:CupomType,
):IInsertdiscountInPriceProduct => ({
	type:'INSERT_DISCOUNT_IN_PRICE_PRODUCT',
	productId:productId,
	priceWithDiscount:newPrice,
	cupom:cupom
})

export const insertProductShippingWay = (
	productId: number,
	shippingWay: string
): IInserProductShippingWay => ({
	type: 'INSERT_PRODUCT_SHIPPING_WAY',
	productId,
	shippingWay,
});
export const insertProductAddressId = (
	productId: number,
	addressId: number
): IInsertAddressIdProductAction => ({
	type: 'INSERT_PRODUCT_ADDRESS_ID',
	productId,
	addressId,
});

export const insertProductPaymentForm = (
	productId: number,
	paymentForm: 'boleto' | 'credit_card'
): IInsertProductPaymentFormProductAction => ({
	type: 'INSERT_PRODUCT_PAYMENT',
	productId,
	paymentForm,
});

export const insertProductInstallmentsNumber = (
	productId: number,
	installments: number
): IInsertProductInstallmentsProductAction => ({
	type: 'INSERT_PRODUCT_INSTALLMENTS',
	productId,
	installments,
});

export const removeUser = (): IRemoveUserAction => ({
	type: 'REMOVE_USER',
});
