import React, { useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import WarrantyPolicy from '../../components/WarrantyPolicy/WarrantyPolicy';

const WarrantyPolicyPage = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Layout title="Política de Garantia">
			<WarrantyPolicy />
		</Layout>
	);
};

export default WarrantyPolicyPage;
