import styled from 'styled-components';

export const Container = styled.main`
        height: max-content;
        padding: 80px;
        display: flex;
        flex-direction: row;
        justify-items: center;
        align-content: center;
        padding-top: 56px;

`;
export const MenuContainer = styled.div`
    width: 50%;
    display: flex;
    flex-wrap: wrap;
`;

export const ProfileAdminContainer = styled.div`
    width: 30%;
    height: 65vh;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px;
    background:#ffffff;
` 
export const ProfileAdmin = styled.div`
    width: 100%;
    height: 45vh;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 10px;
    text-align: center;
    flex-direction: column;
`
export const ActiveButtonContainer = styled.div`
    width: 100%;
    justify-content: flex-end;
    display: flex;
	.account-status {
        margin-bottom:1rem;
		width: 7rem;
		height: 34px;
        margin-left: 12rem;
		background: var(--unnamed-color-69b64a) 0% 0% no-repeat padding-box;
		background: #69b64a 0% 0% no-repeat padding-box;
		border-radius: 17px;
		color: white;
		font-family: 'Poppins', regular;
		font-size: 15px;
		border: none;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
`;

export const ProfileAdminHeader = styled.div`
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    align-items: flex-start;
    justify-items: flex-start;
	justify-content: center;
    
    img{
        border-radius: 50%;
        width: 23rem;
        height: 23rem;
    }

    @media(max-width: 1320px){
       img{
        width: 12rem;
        height: 12rem;
       }
    }
`
export const ProfileAdminMain = styled.div`
    margin-top: 2rem;
    p,span{
        font-weight:bold;
        font-family:'Poppins';
    }

    p{
        font-size: 20px;
        color:'primary';
        
    }
     span{
        font-size: 15px;
        color:#eb7a17;
    }

`

export const ExitButton = styled.button`
    margin-left: 10rem;
    width: 80px;
    height: 31px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px;
    color:#6B97A5;
    font-size:15px;
    font-family:'Poppins';
    font-weight:bold;
    border: 1px solid black;
    &:hover{
        cursor:pointer;
    }
    &:focus{
        outline:none;
    }
`;
