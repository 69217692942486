import { AutoPlayStrategy } from 'react-alice-carousel'

const ALICE_CAROUSEL_DEFAULTS = {
    infinite: true,
    responsive: {
        0: { items: 1 },
        320: { items: 2 },
        325: { items: 2 },
        425: { items: 2 },
        568: { items: 3 },
        768: { items: 4 },
        1024: { items: 6 },
    },
    disableDotsControls: true,
    animationDuration: 300,
    autoPlayStrategy: AutoPlayStrategy.ALL,
    autoPlay: true,
    autoPlayInterval: 1000,
}

export default ALICE_CAROUSEL_DEFAULTS
