import React, {forwardRef, useCallback, useImperativeHandle, useState} from 'react';
import {Modal} from 'semantic-ui-react';
import {Container} from './styles';
import {useDispatch} from 'react-redux';
import {UserAccount} from '../../../../generated/graphql';
import WhatsIcon from '../../../../assets/icon_whats.png';
import mail from '../../../../assets/mail.png';
import phone from '../../../../assets/phone.png';

type Props = {
	contact: UserAccount;
};
export interface InterfaceContactSellerModal {
    showModal: () => void;
}

const ContactSellerModal: React.RefForwardingComponent<InterfaceContactSellerModal, Props> = (
    {contact},
    ref,
) => {
    const dispacth = useDispatch();
    const [state, setState] = useState({ dimmer: undefined, open: false });
    const show = (dimmer: any) => setState({ dimmer: dimmer, open: false });
    
    const showModal = useCallback(() => {
        setState({ dimmer: undefined, open: true });
    }, [setState, state]);

    useImperativeHandle(ref, () => {
        return {
            showModal,
        };
    });
  
    return (
        <>  
            <Modal
                dimmer={state.dimmer}
                open={state.open}
                onClose={() => !state.open}
                style={{width: '350px', height: '270px', borderRadius:'8px'}}
            >
                <Container>
                    <div className="flex-direction-container-modal">
                        <div className="title-contact-seller-modal">
                            <h2 className="title-contact-seller-modal-props">Informações do Vendedor</h2>
                        </div>
                    </div>
                    <h4 className="style-title-h4">Clique no ícone abaixo para entrar em contato:</h4>
                    <div className="contact-seller-informations-modal">
                        <h2>Nome:</h2><span> {contact.name.slice(0, 21)}</span>
                    </div>
                    <div className="contact-seller-informations-modal">
                       <h2 className="h2-telefone">Telefone:</h2><a href={`https://wa.me/${contact.ProfileContacts?.[0].phone}`} target="_blank" rel="noopener noreferrer"><img src={WhatsIcon} className="style-icon-whats" alt="icon-whats"></img></a>
                       <a href={`tel:${contact.ProfileContacts?.[0].phone}`} target="_blank" rel="noopener noreferrer"><img src={phone} className="style-icon-phone" alt="icon-whats"></img></a>
                       <h2 className="h2-mail">E-mail:</h2><a href={`mailto:${contact.email}`}><img src={mail} alt="email" className="icon-mail"/></a> 
                    </div>   
                    <div className="contact-seller-button-container">
                        <button onClick={() => show(false)} className="contact-seller-button">Fechar</button>
                    </div>
                </Container>
            </Modal>
        </>
    );
};

export default forwardRef(ContactSellerModal);
