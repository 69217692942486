import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	justify-content: center;
	background: #eef3f3;
	height: 100%;
	padding-top: 33px;
	padding-left: 93px;
	padding-right: 93px;

	.card {
		background: #ffffff;
		box-shadow: 0px 3px 6px #00000029;
		border-radius: 12px;
		opacity: 1;
		width: 70vw;
		height: 100%;
		padding-top: 27px;
		padding-left: 30px;
		padding-right: 30px;
		margin-bottom: 5rem;

		.title-and-search-bar-container {
			margin-bottom: 30px;

			h2 {
				color: #05abc0;
				font-family: 'Poppins';
				font-weight: bold;
				font-size: 17px;
				margin-bottom: 20px;
			}

			.search-bar {
				margin-bottom: 15px;
				border: solid 1px black;
				border-radius: 7px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;
				padding: 7px;
				height: 30px;
				width: 827px;
				float: left;

				#search-icon {
					margin-right: 12px;
					width: 18px;
					height: 18px;
					.a {
						fill: #ff7e0f;
					}
				}
				input {
					border: none;
					width: 100%;
					font: italic normal normal 12px Poppins;
				}
				input:focus {
					outline: none;
					text-align: left;
					font: normal normal normal 12px Poppins;
				}
			}
			.add-category {
				button {
					height: 30px;
					width: 259px;
					margin-left: 32px;
					background: #05abc0;
					border-radius: 16px;
					color: white;
					border: 1px solid black;
					font-family: 'Poppins';
					font-size: 9px;
					cursor: pointer;
				}
			}
			.IconBuscar {
				color: #ffc000;
				height: 23px;
				width: 33px;
			}
			input::placeholder {
				color: #cdcdcd;
				text-align: left;
				font: italic normal normal 14px Poppins;
				letter-spacing: 0px;
				opacity: 1;
				padding-left: 5px;
			}
			.AddCategoryButtonIcon {
				color: #ffffff;
				height: 2rem;
				width: 2rem;
			}
		}
	}
`;
