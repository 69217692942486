import React, { useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import PrivacyPolicy from '../../components/PrivacyPolicy/PrivacyPolicy';

const PrivacyPolicyPage = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Layout title="Política de Privacidade">
			<PrivacyPolicy />
		</Layout>
	);
};

export default PrivacyPolicyPage;
