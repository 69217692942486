import React, { useState } from 'react';
import { Back, RegisterMenuContainer, RegisterMenu , AnnouceHeader} from './styles';
import backButton from '../../../assets/icons/back.svg';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import ProhibitedItemsModal from './ProhibitedItemsModal/ProhibitedItemsModal';

interface RegisterProductParams {
	categorieId: string;
}

const RegisterFormHeader: React.FC = () => {
	const categoryId: RegisterProductParams = useParams();
	const history = useHistory();
	const [modalIsOpen, setIsOpen] = useState(false);

	function closeModal() {
		setIsOpen(false);
	}
	function handleOpenModal() {
		setIsOpen(true);
	}

	return (
		<>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => {
					closeModal();
				}}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						zIndex: 1001,
						left: 0,
						right: 0,
						bottom: 0,
						background: '#0007',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						padding: '0 auto',
						bottom: 'auto',
						marginRight: '-50%',
						maxHeight: '100%',
						border: 'none',
						borderRadius: '11px',
						transform: 'translate(-50%, -50%)',
					},
				}}
			>
				<ProhibitedItemsModal />
			</Modal>
			<Back>
				<div className="back-container">
					<img
						src={backButton}
						onClick={() => history.push('/')}
						alt=" "
					/>
					<span onClick={() => history.push('/')}>Novo Anúncio</span>
				</div>
			</Back>
			<AnnouceHeader>
				<h1 className="AnnouceProductMobileHeader">Que tipo de anúncio você deseja fazer?</h1>
			</AnnouceHeader>
			<RegisterMenuContainer>
				<div className="page-title">
				<h1>Que tipo de anúncio você deseja fazer?</h1>
					<p>
						Antes de cadastrar um produto certifique-se de que não
						se enquadra dentro dos produtos proibidos, você pode ver
						a lista de produtos proibidos{' '}
						<span onClick={() => handleOpenModal()}>aqui</span>
					</p>
				</div>
				<RegisterMenu>
					<NavLink
						className="item"
						activeClassName="active-item"
						to={{
							pathname:`/register-product/:categoryId/product`,
							state:{typeNegociation:'product'}
						}}
					>
						Produtos e bens
					</NavLink>
					<NavLink
						className="item"
						activeClassName="active-item"
						to={{
							pathname:`/register-product/${8}/properties`,
							state:{typeNegociation:'properties'}
						}}
					>
						Imóveis
					</NavLink>

					<NavLink
						className="item"
						activeClassName="active-item"
						to={{
							pathname:`/register-product/${7}/vehicles`,
							state:{typeNegociation:'vehicles'}
						}}
					>
						Veículos
					</NavLink>
				</RegisterMenu>
			</RegisterMenuContainer>
		</>
	);
};

export default RegisterFormHeader;
