import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items:center;
	position: relative;
	background-color: #fff;
	
	.home-container {
		background-color: #fff;
		margin-top:2.5rem;
		@media (min-width: 1280px) {
			width: 1005px;
		}

		.container-title {
		margin-bottom:3.375rem;
		width: 100%;
  		display: flex;
		flex-direction:row;
		h1 {
			font-family: 'Poppins', semi-bold;
			font-weight: 600;
			color: black;
			font-size: 2.3rem;
			cursor: pointer;
		}
		@media (max-width: 768px) {
			max-width: max-content;
			margin-bottom: 1.313rem;
			font-size:1.6rem;
			background-color: #fff;
		}
		@media (max-width: 476px) {
			h1 {
				font-size: 1.6rem;
			}
		}
		@media (min-width:320px) and (max-width:375px) {
			h1 {
				font-size: 1.6rem;
			}

		}
		@media (min-width:425px) and (max-width:768px) {
			h1 {
				font-size: 1.8rem;
				margin-left:0rem;
			}
			
		}
	}
	}
	.product-cards-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		justify-content: flex-start;
		flex-flow: row wrap;
		column-gap: 1.563rem;

		@media (max-width: 768px) {
			justify-content: center;
			width: 335px;
			max-width: 335px;
		}

		.product-cards-container {
			display: flex;
			background: #ffffff 0% 0% no-repeat padding-box;
			box-shadow: 0px 2px 8px #00000014;
			border-radius: 8px;
			margin-bottom: 40px;
			width: 181px;
			height: 298px;

			img {
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
				width: 100%;
				height: 139px;
			}

			.product-card-content {
				padding: 12px 4px 12px;
				border-radius: 8px;
				border-bottom: solid 8px #69b64a;
				height: 155px;
				word-break: break-word;
				flex-wrap: wrap;
				padding-bottom: 0px;
				width: 100%;
				height: 171px;
				h2 {
					color: black;
					font-family: 'Poppins';
					font-weight: bold;
					font-size: 13px;
				}

				p {
					color: #707070;
					font-size: 10px;
				}
				.price-container {
					display: flex;
					flex-direction: column;
					align-items: flex-start;

					h2 {
						font-family: 'Poppins', semi-bold;
						font-weight: 600;
						color: #6b98a6;
						font-size: 20px;
						margin: 0;
					}
					span {
						font-family: 'Poppins', regular;
						color: black;
						font-size: 10px;
					}

					p {
						word-wrap: break-word;
						height: 32px;
					}
				}
			}

			.section-carousel{
				display:flex;
				flex-direction:row;
				align-items:center;
			}
		}
	}
`;
