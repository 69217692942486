import React from 'react';

import { ReactComponent as Macaw } from '../../assets/icons/macaw.svg';

import { Loading } from './Style';

const LoadingScreen = () => {
	return (
		<Loading>
			<Macaw className="pulsing-macaw" />
		</Loading>
	);
};

export default LoadingScreen;
