import React from 'react';
import ProductView from '../../components/ProductView/ProductView';
import { useParams } from 'react-router-dom';
import { useVerifyProductsInListQuery } from '../../generated/graphql';
import { useSelector } from 'react-redux';
import IUserAccount from '../../models/IUserAccount';
import { AppState } from '../../store';
import Layout from '../../components/Layout/Layout';
interface ProductViewParams {
	productId: string;
}

const ProductViewPage = () => {
	const user: IUserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	let productViewParams: ProductViewParams = useParams();

	const { data, loading } = useVerifyProductsInListQuery({
		variables: { productId: parseInt(productViewParams.productId) },
	});

	if (loading) return <h2>Carregando...</h2>;
	else {
		if (data?.verifyProductsInList) {
			if (
				user?.id ===
				data?.verifyProductsInList?.Product?.UserAccount?.id
			) {
				return (
					<Layout title="Produto">
						<ProductView block={true} />
					</Layout>
				);
			} else if (user?.userTypeId === 1) {
				return (
					<Layout title="Produto">
						<ProductView block={false} />
					</Layout>
				);
			} else {
				return <h1>Produto indisponivel</h1>;
			}
		} else {
			return (
				<Layout title="Produto">
					<ProductView block={false} />
				</Layout>
			);
		}
	}
};
export default ProductViewPage;
