import styled from 'styled-components'

//CSS RODAPÉ PARA DESKTOP ##################
export const Container = styled.div`
    background-color: #038aa0;
    .footer-layout {
        display: flex;
        padding: 30px;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .section-footer-logo {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .footer-logo {
                display: flex;
                flex-direction: row;
                justify-content: center;
                img {
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    width: 170px;
                }
            }
        }
    }
    .footer-infos {
        display: flex;
        flex-direction: row;
        padding: 30px;
        justify-content: space-evenly;
        .section-footer-infos {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            h2 {
                display: flex;
                flex-direction: column;
                font-size: 21px;
                font-family: 'Poppins';
                color: white;
            }
            a {
                flex-direction: row;
                display: flex;
                color: white;
                font-size: 14px;
                font-family: 'Poppins';
                font-weight: 500;
            }
            .span-redirect {
                font-size: 14px;
                font-family: 'Poppins';
                font-weight: 500;
                cursor: pointer;
                color: white;
                padding-bottom: 15px;
            }
            .logo-visa {
                background-color: white;
                border-radius: 3px;
                width: 75px;
                height: 23px;
                margin-bottom: 8px;
            }
            .logo-mastercard {
                width: 66px;
                height: 51px;
                margin-bottom: 8px;
            }
            .logo-elo {
                width: 66px;
                height: auto;
                margin-bottom: 8px;
            }
            .logo-pix {
                width: 66px;
                height: 51px;
            }
            .logo-boleto {
                width: 66px;
                height: 51px;
            }
            .span-pix {
                color: white;
                font-family: 'Poppins';
                font-weight: 500;
                padding-left: 1.5rem;
            }
            .icon-social-media {
                padding-right: 8px;
            }
            .section-sac {
                display: flex;
                flex-direction: row;
                justify-content: center;
                .section-img-sac {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    text-align: center;
                    img {
                        display: flex;
                        flex-direction: row;
                        height: 60%;
                    }
                    .section-phone-number {
                        display: flex;
                        flex-direction: column;
                        h2 {
                            display: flex;
                            flex-direction: column;
                            font-size: 33px;
                            font-weight: 650;
                            font-family: 'Poppins';
                            margin-bottom: 0;
                        }
                        span {
                            font-family: 'Poppins';
                            color: white;
                            font-weight: 400;
                            text-align: center;
                            padding-top: 0;
                            padding-left: 0;
                            margin-left: 0;
                            border: none;
                        }
                    }
                }
                span {
                    font-family: 'Poppins';
                    color: white;
                    font-weight: 350;
                    text-align: center;
                    border-left: 1px solid white;
                    margin-left: 3rem;
                    padding-left: 2rem;
                }
            }
            .span-email {
                margin-top: 1rem;
                font-family: 'Poppins';
                color: white;
                font-weight: 400;
                text-align: center;
                display: flex;
                justify-content: center;
            }
            .section-stickers {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                margin-top: 1.5rem;
                flex-wrap: wrap;
                width: 370px;
                .section-wrap {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    .img-ra-verificado {
                        width: 152px;
                        height: 52px;
                    }
                    .img-google-safe {
                        height: 52px;
                        width: 170px;
                    }
                    .img-seguro-ssl {
                        height: 72px;
                        width: 154px;
                    }
                    .img-lets-encrypt {
                        width: 184px;
                        height: 112px;
                    }
                    .img-logo-pp {
                        height: 150px;
                        width: 150px;
                    }
                }
            }
            .company-details {
                color: #979797;
                margin-top: 5px;
                color: white;
            }
        }
        .cnpjConfig {
            margin-bottom: 2px;
        }
        .div-social-contacts {
            margin-top: 0.5rem;
            width: 220%;
        }
        .TitleLinksRedesSociais {
            margin-top: 0.625rem;
            color: white;
            font-size: 1rem;
            font-family: 'Poppins', semi-bold;
            font-weight: 600;
            margin-bottom: 0.313rem;
        }
        .instalogofooter {
            width: 25px;
        }
        .facelogofooter {
            width: 25px;
            margin-left: 8px;
            border-radius: 10rem;
        }
        .linkedinlogofooter {
            width: 26px;
            margin-left: 8px;
            border-radius: 10px;
        }
        .whatsapp_float {
            position: fixed;
            width: 100px;
            height: 100px;
            bottom: 100px;
            right: 30px;
            border-radius: 110px;
            text-align: center;
            font-size: 30px;
            z-index: 100;
            background-color: #038aa0;
            border: 2px solid white;
            .SammyButton {
                display: flex;
                justify-items: right;
                flex-direction: column;
                text-align: right;
                .StyleIconButton {
                    color: #fff;
                    width: 4rem;
                    padding-top: 0.5rem;
                    padding-left: 0.3rem;
                    position: fixed;
                    bottom: 126px;
                    right: 53px;
                }
            }
        }
    }
    .section-seal {
        display: flex;
        flex-direction: row;
        justify-content: center;
        #ra-verified-seal {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-bottom: 10px;
        }
    }

    //CSS RODAPÉ PARA TABLET ##################
    .section-footer-tablet {
        display: flex;
        background-color: #038aa0;
        height: 150px;
        padding-left: 10px;
        padding-top: 18px;
        justify-content: space-around;
        .footer-tablet-sambay-logo {
            border-right: 1px solid white;
            display: flex;
            flex-direction: column;
            height: 83%;
            width: 30%;
            .sambay-logo-tablet-footer {
                width: 160px;
                height: 40px;
            }
            .section-row-socials-tablet {
                display: flex;
                flex-direction: row;
                justify-content: center;
                padding-top: 10px;
                .facebook-logo-tablet-footer {
                    padding-right: 1rem;
                    height: 70%;
                }
                .instagram-logo-tablet-footer {
                    padding-right: 1rem;
                    height: 70%;
                }
                .linkedin-logo-tablet-footer {
                    padding-right: 1rem;
                    height: 70%;
                }
            }
        }
        .cnpjConfig {
            margin-bottom: 2px;
        }
        .div-social-contacts {
            margin-top: 0.5rem;
            width: 100%;
        }
        .TitleLinksRedesSociais {
            margin-top: 0.625rem;
            color: white;
            font-size: 1.3rem;
            font-family: 'Poppins', semi-bold;
            font-weight: 600;
            margin-bottom: 0.313rem;
        }
        .instalogofooter {
            width: 1.875rem;
        }
        .facelogofooter {
            width: 1.875rem;
            margin-left: 8px;
            border-radius: 10rem;
        }
        .linkedinlogofooter {
            width: 1.875rem;
            margin-left: 8px;
            border-radius: 10px;
        }
        .whatsapp_float {
            display: none;
            .StyleIconButton {
                display: none;
            }
        }
    }
    .footer-tablet-infos {
        padding-left: 1rem;
        padding-right: 1rem;
        .title-footer-tablet-contact-infos {
            color: white;
            font-size: 18px;
            font-weight: 400;
            font-family: 'Poppins';
            margin-bottom: 0.5rem;
        }
        span {
            color: white;
            font-family: 'Poppins';
            font-size: 10px;
            font-weight: 700;
        }
    }
    .footer-tablet-institutional-infos {
        display: flex;
        flex-direction: column;
        border-left: 1px solid white;
        padding-left: 2rem;
        height: 80%;
        padding-right: 2rem;
        .title-footer-tablet-institutional-infos {
            color: white;
            font-size: 18px;
            font-weight: 400;
            font-family: 'Poppins';
            margin-bottom: 0.5rem;
        }
        .footer-tablet-instuticional-title-initial-page {
            color: white;
            font-family: 'Poppins';
            font-size: 10px;
            font-weight: 700;
        }
        .footer-tablet-instuticional-title-who-we-are {
            color: white;
            font-family: 'Poppins';
            font-size: 10px;
            font-weight: 700;
        }
        .footer-tablet-instuticional-title-faq {
            color: white;
            font-family: 'Poppins';
            font-size: 10px;
            font-weight: 700;
        }
        .footer-tablet-instuticional-usability-terms {
            color: white;
            font-family: 'Poppins';
            font-size: 10px;
            font-weight: 700;
        }
    }
    .footer-tablet-section-imgs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border-left: 1px solid white;
        height: 80%;
        padding-left: 2rem;
        .section-imgs {
            justify-content: space-around;
            flex-wrap: wrap;
            .footer-tablet-section-imgs-ra-verificado {
                width: 68px;
                height: 24px;
                margin-right: 0.5rem;
            }
            .footer-tablet-section-imgs-google-safe {
                width: 81px;
                height: 23px;
            }
            .footer-tablet-section-imgs-pp {
                width: 48px;
                height: 48px;
            }
            .footer-tablet-section-imgs-ssl {
                width: 69px;
                height: 32px;
                margin-left: 1rem;
            }
            .footer-tablet-section-imgs-lets-encrypt {
                width: 71px;
                height: 21px;
            }
        }
        .img-logo-pp {
            width: 60px;
            height: 60px;
            margin-left: 1rem;
        }
    }

    @media (min-width: 375px) and (max-width: 424px) {
        background-size: 0%;
        display: flex;
        justify-content: center;
        max-width: 375px;
        .footer-layout {
            display: flex;
            flex-direction: column;
            max-width: 375px;
            justify-content: center;
            .section-logos {
                display: flex;
                flex-direction: column;
                padding-left: 2rem;
                padding-right: 2rem;
                .title-footer-tablet-contact-infos {
                    color: white;
                    font-size: 18px;
                    font-weight: 400;
                    font-family: 'Poppins';
                    margin-bottom: 0.5rem;
                }
                span {
                    color: white;
                    font-family: 'Poppins';
                    font-size: 10px;
                    font-weight: 700;
                }
            }
            .footer-tablet-institutional-infos {
                display: flex;
                flex-direction: column;
                border-left: 1px solid white;
                padding-left: 2rem;
                height: 80%;
                padding-right: 2rem;
                .title-footer-tablet-institutional-infos {
                    color: white;
                    font-size: 18px;
                    font-weight: 400;
                    font-family: 'Poppins';
                    margin-bottom: 0.5rem;
                }
                .footer-tablet-instuticional-title-initial-page {
                    color: white;
                    font-family: 'Poppins';
                    font-size: 10px;
                    font-weight: 700;
                }
                .footer-tablet-instuticional-title-who-we-are {
                    color: white;
                    font-family: 'Poppins';
                    font-size: 10px;
                    font-weight: 700;
                }
                .footer-tablet-instuticional-title-faq {
                    color: white;
                    font-family: 'Poppins';
                    font-size: 10px;
                    font-weight: 700;
                }
                .footer-tablet-instuticional-usability-terms {
                    color: white;
                    font-family: 'Poppins';
                    font-size: 10px;
                    font-weight: 700;
                }
            }
            .footer-tablet-section-imgs {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                border-left: 1px solid white;
                height: 80%;
                padding-left: 2rem;
                .section-imgs {
                    justify-content: space-around;
                    flex-wrap: wrap;
                    .footer-tablet-section-imgs-ra-verificado {
                        width: 68px;
                        height: 24px;
                        margin-right: 0.5rem;
                    }
                    .footer-tablet-section-imgs-google-safe {
                        width: 81px;
                        height: 23px;
                    }
                    .footer-tablet-section-imgs-pp {
                        width: 48px;
                        height: 48px;
                    }
                    .footer-tablet-section-imgs-ssl {
                        width: 69px;
                        height: 32px;
                        margin-right: 0.5rem;
                    }
                    .footer-tablet-section-imgs-lets-encrypt {
                        width: 71px;
                        height: 21px;
                    }
                }
            }
            .cnpjConfig {
                margin-bottom: 2px;
            }
            .div-social-contacts {
                margin-top: 0.5rem;
                width: 100%;
            }
            .TitleLinksRedesSociais {
                margin-top: 0.625rem;
                color: white;
                font-size: 1.3rem;
                font-family: 'Poppins', semi-bold;
                font-weight: 600;
                margin-bottom: 0.313rem;
            }
            .instalogofooter {
                width: 1.875rem;
            }
            .facelogofooter {
                width: 1.875rem;
                margin-left: 8px;
                border-radius: 10rem;
            }
            .linkedinlogofooter {
                width: 1.875rem;
                margin-left: 8px;
                border-radius: 10px;
            }
            .whatsapp_float {
                display: none;
            }
        }
    }

    //CSS RODAPÉ PARA MOBILE ##################
    .section-footer-mobile {
        background-color: #038aa0;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .section-footer-mobile-logo-sambay {
            display: flex;
            justify-content: center;
            margin: 1rem;
            .logo-sambay {
                width: 140px;
                height: auto;
                @media (min-width: 320px) and (max-width: 374px) {
                    width: 130px;
                }
            }
        }
        .section-footer-mobile-sac {
            display: flex;
            flex-direction: row;
            justify-content: center;
            .section-footer-mobile-img-sac {
                display: flex;
                flex-direction: row;
                justify-content: center;
                text-align: center;
                .img-mobile-footer-sac {
                    display: flex;
                    flex-direction: row;
                    height: 60%;
                    @media (min-width: 320px) and (max-width: 374px) {
                        height: 40%;
                    }
                }
                .section-mobile-footer-phone-number {
                    display: flex;
                    flex-direction: column;
                    padding-right: 1rem;
                    @media (min-width: 320px) and (max-width: 374px) {
                        padding-right: 0.5rem;
                    }
                    .title-sac-footer-mobile {
                        display: flex;
                        flex-direction: column;
                        font-size: 25px;
                        font-weight: 650;
                        font-family: 'Poppins';
                        margin-bottom: 5px;
                        color: white;
                    }
                    .phone-number-footer-mobile-sac {
                        font-family: 'Poppins';
                        color: white;
                        font-weight: 400;
                        text-align: center;
                        padding-top: 0;
                        padding-left: 0;
                        margin-left: 0;
                        border: none;
                    }
                }
            }
            .support-number-footer-mobile-sac {
                font-family: 'Poppins';
                color: white;
                font-weight: 350;
                text-align: center;
                border-left: 1px solid white;
                padding-left: 2.3rem;
                @media (min-width: 320px) and (max-width: 374px) {
                    padding-left: 1.5rem;
                }
            }
        }
        .section-contact-sambay {
            display: flex;
            justify-content: center;
            flex-direction: row;
            .contact-email-adress-footer-mobile-sac {
                color: white;
                font-family: 'Poppins';
                padding-top: 1rem;
                padding-bottom: 1rem;
                width: 65%;
                justify-content: center;
                display: flex;
                border-bottom: 1px solid white;
                margin-bottom: 1.5rem;
            }
        }

        .cnpjConfig {
            margin-bottom: 2px;
        }
        .div-social-contacts {
            margin-top: 0.5rem;
            width: 100%;
        }
        .TitleLinksRedesSociais {
            margin-top: 0.625rem;
            color: white;
            font-size: 1.3rem;
            font-family: 'Poppins', semi-bold;
            font-weight: 600;
            margin-bottom: 0.313rem;
        }
        .instalogofooter {
            width: 1.875rem;
        }
        .facelogofooter {
            width: 1.875rem;
            margin-left: 8px;
            border-radius: 10rem;
        }
        .linkedinlogofooter {
            width: 1.875rem;
            margin-left: 8px;
            border-radius: 10px;
        }
        .whatsapp_float {
            display: none;
            .StyleIconButton {
                display: none;
            }
        }
        .section-container-footer-mobile-socials {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;

            .icon-facebook-footer-mobile {
                width: 21px;
                height: 24px;
            }
            .icon-instagram-footer-mobile {
                width: 23.04px;
                height: 23.04px;
            }
            .icon-linkedin-footer-mobile {
                width: 23.04px;
                height: 23.04px;
            }
        }

        .section-client-support-footer-mobile {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 1rem;
            .span-redirect {
                font-size: 14px;
                font-family: 'Poppins';
                font-weight: 500;
                cursor: pointer;
                color: white;
                padding-bottom: 15px;
            }
            .title-section-client-support-footer-mobile {
                color: white;
                font-weight: 700;
                font-size: 14px;
                font-family: 'Poppins';
            }
            .section-links-client-support-footer-mobile {
                display: flex;
                flex-direction: column;
                text-align: center;
                justify-content: center;
                border-bottom: 1px solid white;
                padding-bottom: 1rem;
                width: 65%;
                a {
                    color: white;
                    padding-bottom: 15px;
                    font-size: 14px;
                    font-family: 'Poppins';
                    font-weight: 500;
                }
            }
        }
        .section-payments-methods-footer-mobile {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-top: 1.5rem;
            .title-section-payments-methods-footer-mobile {
                font-size: 16px;
                font-family: 'Poppins';
                color: white;
                font-weight: 700;
                text-align: center;
            }
            .section-container-payment-methods-footer-mobile {
                display: flex;
                flex-direction: row;
                justify-content: center;
                .container-center-width-payment-methods-footer-mobile {
                    width: 25%;
                    justify-content: center;
                    @media (min-width: 320px) and (max-width: 424px) {
                        //Tamanho de container de selos de pgto - Mobile Small -> Medium.
                        width: 35%;
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        flex-wrap: wrap;
                    }
                    .logo-visa-footer-mobile {
                        width: 45.73px;
                        height: 35.16px;
                        margin-right: 0.5rem;
                    }
                    .logo-mastercard-footer-mobile {
                        width: 40px;
                        height: 32px;
                    }
                    .logo-mastercard-footer-mobile {
                        width: 40px;
                        height: auto;
                    }
                    .logo-pix-footer-mobile {
                        width: 40px;
                        height: 50px;
                        margin-right: 0.7rem;
                    }
                    .logo-boleto-footer-mobile {
                        width: 50px;
                        height: 48px;
                    }
                }
            }

            .section-container-stickers-footer-mobile {
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-bottom: 1.5rem;
                .container-center-width-payment-methods-footer-mobile {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .logo-google-safe {
                        width: 130px;
                        height: 40px;
                    }
                    .logo-seguro-ssl {
                        width: 120px;
                        height: 60px;
                    }
                    .logo-lets-encrypt {
                        width: 130px;
                        height: 42px;
                    }
                    .logo-pp {
                        width: 96px;
                        height: 96px;
                    }
                }
            }

            .TitleLinksRedesSociais {
                margin-top: 0.625rem;
                color: white;
                font-size: 1rem;
                font-family: 'Poppins', semi-bold;
                font-weight: 600;
                margin-bottom: 0.313rem;
            }
            .instalogofooter {
                width: 1.875rem;
            }
        }
        .section-reclame-aqui {
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
        .section-copyright-footer-mobile {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding-top: 1rem;
            .section-container-copyright-footer-mobile {
                display: flex;
                justify-content: center;
                width: 80%;
                border-top: 1px solid white;
                flex-direction: column;
                .span-copyright-footer-mobile {
                    font-size: 9px;
                    font-family: 'Poppins';
                    color: white;
                    font-weight: 400;
                    text-align: center;
                    line-height: 9px;
                    padding-top: 1rem;
                }
                .span-copyright-footer-mobile-two {
                    font-size: 9px;
                    font-family: 'Poppins';
                    color: white;
                    font-weight: 400;
                    text-align: center;
                    line-height: 9px;
                    padding-bottom: 1rem;
                    padding-top: 0.3rem;
                }
            }
        }

        .section-client-support-footer-mobile {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 1.5rem;
            .span-redirect {
                font-size: 14px;
                font-family: 'Poppins';
                font-weight: 500;
                cursor: pointer;
                color: white;
                padding-bottom: 15px;
            }
            .title-section-client-support-footer-mobile {
                color: white;
                font-weight: 700;
                font-size: 14px;
                font-family: 'Poppins';
            }
            .section-links-client-support-footer-mobile {
                display: flex;
                flex-direction: column;
                text-align: center;
                justify-content: center;
                border-bottom: 1px solid white;
                padding-bottom: 1rem;
                width: 65%;
                a {
                    color: white;
                    padding-bottom: 15px;
                    font-size: 14px;
                    font-family: 'Poppins';
                    font-weight: 500;
                }
            }
        }
    }
`

export const ContainerCopyright = styled.div`
    background-color: #05a5bf;
    display: flex;
    justify-content: center;
    .section-copyright {
        display: flex;
        flex-direction: row;
        text-align: center;
        justify-content: center;
        span {
            font-size: 13px;
            font-family: 'Poppins';
            color: white;
            font-weight: 400;
            padding: 10px;
        }
    }
`
