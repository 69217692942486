import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	height: 93px;
	background: black;
	display: none;
	flex-direction: column;

	&:hover {
		cursor: pointer;
	}

	@media (max-width: 1022px) {
		display: none;
	}
`;
export const TopButtons = styled.div`
	display: flex;
	justify-content: space-between;
	column-gap: 9px;
	
	.categories,
	.filters,
	.favorites {
		background: transparent;
		border: solid 1px white;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 6px;
		column-gap: 6px;
		flex: 1;

		.icon {
			fill: white;
			max-width: 15px;
			.a {
				fill: transparent;
			}
			.b {
				fill: white;
			}
		}

		span {
			color: white;
			font-family: 'Poppins';
			font-weight: 500;
			font-size: 12px;
		}
	}
	&:hover {
		cursor: pointer;
	}
`;
export const Bottom = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	background: #fff;
	border-radius: 6px;
	column-gap: 6px;

	.img-background {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 6px;
		background: #04aac0;
	}
	span {
		align-self: center;
		flex: 100%;
		text-align: center;
		font-size: 12px;
		color: black;
		font-family: 'Poppins';
		font-weight: 400;
	}
	&:hover {
		cursor: pointer;
	}
`;
export const ModalContainer = styled.div`
	padding: 10px 30px;
	.top-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.icon-and-input {
			display: flex;
			align-items: center;
			width: 100%;
			.img-background {
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 6px;
				background: #ff7e0f;
			}

			input {
				margin-left: 19px;
				border: none;
				color: #525252;
				font-size: 12px;
				font-family: 'Poppins';
				font-style: italic;
				width: 100%;

				&:focus {
					outline: none;
				}
			}
		}
	}
	.bottom-container {
		display: flex;
		flex-direction: column;

		margin-top: 42px;
		.item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.container {
				display: flex;
				align-items: center;
				.fake-icon {
					width: 30px;
					height: 30px;
				}
				span {
					margin-left: 19px;
					color: #969696;
					font-family: 'Poppins';
					font-weight: 400;
					font-size: 12px;
				}
			}
			.icon {
				width: 20px;
			}
		}
	}
`;
