import styled from 'styled-components';

export const CommentsTitle = styled.div`
	width: 100%;
	.title-container {
		margin-bottom: 20px;
		border-bottom: solid 1px black;
		padding-bottom: 13px;

		h2 {
			color: #6b97a5;
			font-size: 40px;
			font-family: 'Poppins', semi-bold;
			font-weight: 600;

			@media (min-width:425px) and (max-width:767px){
				font-size: 23px;
				padding-left:1rem;
			}
			@media (min-width:375px) and (max-width:424px){
				font-size: 23px;
				padding-left:1rem;
			}
			@media (min-width:320px) and (max-width:374px){
				font-size: 20px;
				padding-left:1rem;
			}
		}
	}
`;

export const ReputationContainter = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 80px;
	background-color:#fff;

	.questions-card {
		margin-top: 10px;
		width: 100%;
		.question {
			display: flex;
			flex-direction: row;
			margin-bottom: 26px !important;
			margin-top: 40px;

			.user-img {
				margin: 0;
				img {
					height: 85px;
					width: 85px;
					border-radius: 65px;
				}
			}
			.question-content {
				display: flex;
				flex-direction: column;
				margin-left: 18px;

				.make-question {
					display: flex;
					flex-direction: row;
					h2 {
						color: #6b98a6;
						font-family: 'Poppins', semi-bold;
						font-weight: 600;
						font-size: 16px;
					}

					textarea {
						width: 959px;
						height: 127px;
						border: 1px solid black;
						border-radius: 6px;
						resize: none;
						color: #979797;
						padding: 10px;
						font-size: 14px;
						font-family: 'Poppins', regular;
						background-color: #fff;
					}
					textarea:focus,
					button:focus {
						outline: none;
					}
				}

				.button-container {
					margin-top: 15px;

					button {
						color: white;
						background-color: #6b98a6;
						border-radius: 19px;
						border: none;
						width: 219px;
						height: 37px;
						font-family: 'Poppins', semi-bold;
						font-weight: 600;
						font-size: 17px;
						margin-top: 15px;
						margin-bottom: 20px;
					}
					button:focus {
						outline: none;
					}
					button:hover {
						cursor: pointer;
					}
					.mark {
					}
				}

				.question-infos {
					display: flex;
					flex-direction: row;
					margin-bottom: 17px;
					align-items: center;
					h2 {
						margin: 0;
						color: #979797;
						font-family: 'Poppins';
						font-weight: bold;
						font-size: 17px;
					}
					span {
						margin-left: 25px;
						font-family: 'Poppins', medium;
						font-weight: 500;
						color: #a9a9a9;
					}
					.options {
						span {
							display: none;
						}
					}
					.options:hover {
						span {
							visibility: visible;
						}
					}

					#star1,
					#star2,
					#star3,
					#star4,
					#star5 {
						.a {
							stroke: #ff7e0f;
							fill: white;
						}
					}
					#star-active {
						.a {
							stroke: #ff7e0f;
							fill: #ff7e0f;
						}
					}

					#star1:hover {
						cursor: pointer;
						.a {
							fill: #ff7e0f;
						}
					}
					#star2:hover,
					#star3:hover,
					#star4:hover,
					#star5:hover {
						.a {
							fill: #ff7e0f;
						}
					}
				}
				.question-description {
					color: #979797;
					font-family: 'Poppins', regular;
					font-size: 12px;
					width: 700px;
					word-break: break-word;
					background-color: #fff;
					padding: 12px;

					.rating-stars {
						margin-bottom: 14px;
						display: flex;
						flex-direction: row;
						align-items: center;

						#star {
							.b {
								fill: white;
								stroke: #6b97a5;
							}
						}
						#active-star {
							.b {
								fill: #6b97a5;
							}
						}
					}
				}
			}
		}
		.answer {
			display: flex;
			flex-direction: row;
			margin-left: 69px;
			margin-top: 30px;
			margin-bottom: 20px !important;
			.user-img {
				margin: 0;
				img {
					height: 85px;
					width: 85px;
					border-radius: 65px;
				}
			}
			.answer-content {
				display: flex;
				flex-direction: column;
				margin-left: 18px;

				.answer-infos {
					display: flex;
					flex-direction: row;
					margin-bottom: 17px;
					align-items: center;
					justify-content: space-between;

					.options {
						display: none;

						span:hover {
							cursor: pointer;
						}
					}

					.infos {
						display: flex;
						flex-direction: row;
					}
					h2 {
						margin: 0;
						color: #6b98a6;
						font-family: 'Poppins', semi-bold;
						font-weight: 600;
						font-size: 17px;
					}
					span {
						margin-left: 25px;
						font-family: 'Poppins', medium;
						font-weight: 500;
						color: #a9a9a9;
					}
				}
				.answer-description {
					textarea {
						color: #979797;
						font-family: 'Poppins', regular;
						font-size: 14px;
						resize: none;
						width: 580px;
						word-break: break-word;
						border: none;
					}
					textarea:focus {
						outline: none;
					}
				}
			}
		}
		.answer:hover {
			.answer-infos {
				.options {
					display: flex;
				}
			}
		}
	}
	@media (min-width:425px) and (max-width:767px){
		padding:0;
		margin-top:1rem;
	}
	@media (min-width:375px) and (max-width:424px){
		padding:0;
		margin-top:1rem;
	}
	@media (min-width:320px) and (max-width:374px){
		padding:0;
		margin-top:1rem;
	}
`;

export const Title = styled.div`
	.title-container {
		h2 {
			color: #6b97a5;
			font-size: 40px;
			font-family: 'Poppins', semi-bold;
			font-weight: 600;
		}
	}
`;
export const SelectorMenu = styled.div`
	width: 200px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	border: solid 1px black;
	border-radius: 4px;

	.item {
		color: #a8a8a8;
		font-size: 15px;
		font-family: 'Poppins';
		font-weight: bold;
		height: 41px;
		display: flex;
		flex-direction: revert;
		align-items: center;
		width: 100px;
		justify-content: center;
		border-radius: 4px;
	}
	.active-item {
		background-color: #6b97a5;
		font-size: 15px;
		font-family: 'Poppins';
		font-weight: bold;
		color: white;
		height: 41px;
		text-align: center;
		display: flex;
		flex-direction: revert;
		align-items: center;
		width: 100px;
		justify-content: center;
		border-radius: 4px;
	}
`;

export const RankingContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 80px;
	padding-top: 30px;
	padding-left: 120px;
	padding-right: 120px;
	background-color:#fff;

	@media (min-width:425px) and (max-width:767px){
		padding:0;
	}
	@media (min-width:375px) and (max-width:424px){
		padding:0;
	}
	@media (min-width:320px) and (max-width:374px){
		padding:0;
	}
`;
export const FlexContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	@media (max-width:767px){
		flex-direction: column;
		width: 100%;
		max-width:100%;
	}
`;

export const ProductSellersContainer = styled.div`
  width: 20vw;
  @media (max-width:767px){
    flex-direction: column;
    width: 100%;
    max-width:100%;
  }
`
export const ProductSellersItem = styled.div`
  width: 14.5vw;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border:1px solid darkgray;
  border-radius: 12px;
  margin-bottom: 2rem;
  padding: 12px;

  
`

export const RankingTitle = styled.div`
	width: 100%;
	margin-bottom: 20px;
	.ranking-title-container {
		margin-bottom: 20px;
		border-bottom: solid 1px black;
		padding-bottom: 13px;

		h2 {
			font-size: 28px;
			color: #6b97a5;
			font-family: 'Poppins', semi-bold;
			font-weight: 600;
			margin: 0;
		}
	}

	@media (min-width:425px) and (max-width:767px){
		margin-bottom:0;
		.ranking-title-container{
			h2{
				margin-left: 2rem;
				margin-top: 1rem;
				font-size: 25px;
			}
		}
	}
	@media (min-width:375px) and (max-width:424px){
		margin-bottom:0;
		.ranking-title-container{
			h2{
				margin-left: 2rem;
				margin-top: 1rem;
				font-size: 23px;
			}
		}
	}
	@media (min-width:320px) and (max-width:374px){
		margin-bottom:0;
		.ranking-title-container{
			h2{
				margin-left: 1rem;
				margin-top: 1rem;
				font-size: 22px;
			}
		}
	}
`;

export const RankingContent = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
	border: solid 1px black;
	width: 336px;
	height: 320px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	max-width: 100%;
	h2 {
		color: #6b97a5;
		font-family: 'Poppins', semi-bold;
		font-weight: 600;
		font-size: 40px;
		margin-top: 5px;
	}
	span {
		color: #979797;
		font-family: 'Poppins', regular;
		font-size: 16px;
	}
`;
