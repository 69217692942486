import styled from 'styled-components';

export const PageContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	background-color:#fff;
`;

export const Container = styled.div`
	width: 80%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color:#fff;
`;
export const PageHeader = styled.header`
	height: 107px;
	cursor: pointer;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	background-color:#fff;

	@media (min-width:425px) and (max-width:768px){
		height: 50px;
	}
	@media (min-width:375px) and (max-width:424px){
		height: 50px;
	}
	@media (min-width:320px) and (max-width:374px){
		height: 50px;
	}

	h3 {
		margin: 0;
		font-family: 'Poppins';
		font-weight: 600;
		font-size: min(20px, 2vw);
		color: #6b98a6;

		@media (min-width:425px) and (max-width:768px){
			font-size: 15px;
			font-weight: 600;
		}
		@media (min-width:375px) and (max-width:424px){
			font-size: 14px;
			font-weight: 600;
		}
		@media (min-width:320px) and (max-width:374px){
			font-size: 12px;
			font-weight: 600;
		}
	}
	img {
		margin-right: 20px;

		&:hover {
			cursor: pointer;
		}
	}
`;
export const MainCard = styled.main`
	width: 80%;
	height: max-content;
	background: #fff;
	box-shadow: 3px 2px 9px #00000014;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 25px;
	padding-top: 30px;

	h2 {
		font-family: 'Poppins';
		font-weight: 600;
		font-size: min(24px, 2vw);
		color: #6b97a5;
		margin-bottom: 25px;

		@media (min-width:425px) and (max-width:768px){
			font-family: 'Poppins';
			font-weight: 600;
			font-size: 14px;
			color: #6b97a5;
		}
		@media (min-width:375px) and (max-width:424px){
			font-family: 'Poppins';
			font-weight: 600;
			font-size: 13px;
			color: #6b97a5;
		}
		@media (min-width:320px) and (max-width:374px){
			font-family: 'Poppins';
			font-weight: 600;
			font-size: 12px;
			color: #6b97a5;
		}
	}

	.items-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		min-width: 100%;
	}
	
	@media (min-width:425px) and (max-width:768px){
		padding: 10px;
	}
	@media (min-width:375px) and (max-width:424px){
		padding: 10px;
	}
	@media (min-width:320px) and (max-width:374px){
		padding: 10px;
	}
`;
