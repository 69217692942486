import React from 'react';
import { Container } from './styles';

type Props = {
	Icon: any;
	title: string;
	description: string;
	onClick?: any;
	disabled?: boolean;
};

const MenuItem: React.FC<Props> = ({
	Icon,
	title,
	description,
	onClick,
	disabled,
}) => {
	return (
		<Container
			onClick={onClick}
			background={disabled ? '#e1e1e1' : '#FFFFFF'}
		>
			<Icon className="menu-icon" />
			<div className="item-informations">
				<h3> {title} </h3>
				<span> {description} </span>
			</div>
		</Container>
	);
};

export default MenuItem;
