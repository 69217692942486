import styled from 'styled-components';

export const Container = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:flex-start;
    border:solid 1px black;
    width: 427px;
    height: 84px;
    border-radius: 7px;
    padding: 18px;
    margin-right: 29px;
    margin-bottom: 38px;

    @media (max-aspect-ratio: 4/3){
        margin-right: 9px;
        margin-bottom: 30px;
    }

    .user-photo{
        margin-right: 15px;
        img{
            width: 66px;
            height: 66px;
            border-radius: 50px;
            object-fit: cover;
            border: 1px solid black;
        }
    }

    .user-informations{
        h3{
            color:#0B0948;
            margin-bottom: -5px;
            font-family:'Poppins';
            font-weight:bold;
            font-size:20px;

        }
        span{
            color:#6B97A5;
            font-family:'Poppins', regular;
            font-size:14px;
        }
    }

`;
