import styled from 'styled-components';

export const SpinnerItem = styled.div`
	border: 2px solid rgba(0, 0, 0, 0.1);
	border-left-color: #04aac0;
	border-radius: 50%;
	height: 25px;
	width: 25px;
	animation: spin 1s linear infinite;

	@keyframes spin {
		to {
			transform: rotate(360deg);
		}
	}
`;
