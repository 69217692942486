import {UserCustomization} from '../generated/graphql';
import {
	InsertUserCustomizations,
	IUpdateBackgroundColor,
	IUpdateBrandColor,
	IUpdateBrandTextColor,
	IUpdateShopName,
	SetCustomBackground,
	SetCustomBrand,
} from '../models/IUserCustomizations';

export const insertCustomizations = (
	userCustomizations: UserCustomization
): InsertUserCustomizations => ({
	type: 'INSERT_USER_CUSTOMIZATIONS',
	userCustomizations,
});

export const updateBackgroundColor = (
	backgroundColor?: string
): IUpdateBackgroundColor => ({
	type: 'UPDATE_BACKGROUND_COLOR',
	backgroundColor,
});
export const setCustomBackground = (
	background?: string
): SetCustomBackground => ({
	type: 'SET_CUSTOM_BACKGROUND',
	background,
});
export const setCustomBrand = (brand?: string): SetCustomBrand => ({
	type: 'SET_CUSTOM_BRAND',
	brand,
});

export const updateBrandName = (brandName: string): IUpdateShopName => ({
	type: 'UPDATE_SHOP_NAME',
	brandName,
});

export const updateBrandTextColor = (
	brandTextColor: string
): IUpdateBrandTextColor => ({
	type: 'UPDATE_BRAND_TEXT_COLOR',
	brandTextColor,
});

export const updateBrandColor = (brandColor: string): IUpdateBrandColor => ({
	type: 'UPDATE_BRAND_COLOR',
	brandColor,
});
