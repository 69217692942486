import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { Container } from './styles';

const ProfileControlCardMobile: React.FC = () => {
	const { url, path } = useRouteMatch();

	return (
		<>
			<Container>
				<NavLink
					to={`${url}/data/top`}
					className="item"
					activeClassName="active"
				>
					Dados
					<div className="border"></div>
				</NavLink>
				<NavLink
					to={`${url}/card`}
					className="item"
					activeClassName="active"
				>
					Formas de pagamento
					<div className="border"></div>
				</NavLink>
				<NavLink
					to={`${url}/bank-account`}
					className="item"
					activeClassName="active"
				>
					Conta de recebimento
					<div className="border"></div>
				</NavLink>
			</Container>
		</>
	);
};

export default ProfileControlCardMobile;
