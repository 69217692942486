import React, { useEffect, useState } from 'react'
import defaultImage from '../../../../assets/default.svg'
import { Container } from './styles'
import UserAccountsList from '../../../../models/AllUsers'
type Props = {
    account: UserAccountsList
}
const AccountsCardItem: React.FC<Props> = ({ account }) => {
    const [imageState, setImageState] = useState<any>()

    const changeImageState = () => {
        setImageState(
            <>
                <img src={defaultImage} />
            </>
        )
    }

    useEffect(() => {
        setImageState(
            <>
                <img
                    src={`${process.env.REACT_APP_STATIC_URL}/user/${account?.id}.jpeg`}
                    alt={defaultImage}
                    onError={changeImageState}
                />
            </>
        )
    }, [account])

    return (
        <Container>
            <div className="user-photo">{imageState}</div>
            <div className="user-informations">
                <h3>{account.name}</h3>
                <span>{account.UserType.name}</span><br />
                {account.ProfileContacts && account.ProfileContacts[0] && (
                    <span>Telefone: {account.ProfileContacts[0].phone}</span>
                )}
                <span> Email: {account.email}</span>
            </div>
        </Container>
    )
}

export default AccountsCardItem
