import React from 'react';
import MenuItem from './MenuItem/MenuItem';
import {
	ActiveButtonContainer,
	Container,
	ExitButton,
	MenuContainer,
	ProfileAdmin,
	ProfileAdminContainer,
	ProfileAdminHeader,
	ProfileAdminMain,
} from './styles';
import {ReactComponent as AdmCategorys} from '../../assets/admMenuIcons/admCategorys.svg';
import {ReactComponent as AdmDashboard} from '../../assets/admMenuIcons/admDashboard.svg';
import {ReactComponent as AdmNotification} from '../../assets/admMenuIcons/admNotification.svg';
import {ReactComponent as AdmProducts} from '../../assets/admMenuIcons/admProducts.svg';
import {ReactComponent as AdmRecords} from '../../assets/admMenuIcons/admRecords.svg';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import IUserAccount from '../../models/IUserAccount';
import {AppState} from '../../store';
import moment from 'moment';
import { handleLogout } from '../../handlers/handleLogout';

const AdmHome: React.FC = () => {
	const user: IUserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);

	return (
		<Container>
			<MenuContainer>
				<Link to="/notifications">
					<MenuItem
						Icon={AdmNotification}
						title="Notificações"
						description="Veja notificações dos moderadores e de denúncia."
					/>
				</Link>
				<MenuItem
					Icon={AdmDashboard}
					disabled={true}
					title="Dashboard"
					description="Análise de métricas relacionadas a plataforma - EM BREVE."
				/>
				<Link to="/records">
					<MenuItem
						Icon={AdmRecords}
						title="Cadastros"
						description="Realize o cadastro de novas contas de Administrador, moderador e FAQ."
					/>
				</Link>
				
				<Link to="/showCases">
					<MenuItem
							Icon={AdmDashboard}
							title="Vitrines Dinâmicas"
							description="Realize o cadastro de vitrines dinâmicas."
						/>
				</Link>

				<Link to="/product-categories">
					<MenuItem
						Icon={AdmCategorys}
						title="Categorias"
						description="Visualize e edite as categorias do sistema Sambay Express."
					/>
				</Link>

				<Link to="/products-home">
					<MenuItem
						Icon={AdmProducts}
						title="Produtos"
						description="Veja e acompanhe os produtos e lojas cadastrados no Sambay Express."
					/>
				</Link>
				<Link to="/edit-banner">
					<MenuItem
						Icon={AdmProducts}
						title="Edite Banner"
						description="Visualize e edite o banner do seu site - EM BREVE"
					></MenuItem>
				</Link>
			</MenuContainer>

			<ProfileAdminContainer>
				<Link to="profile">
					<ProfileAdmin>
						<ActiveButtonContainer>
							<span className="account-status">Conta Ativa</span>
						</ActiveButtonContainer>
						<ProfileAdminHeader>
							<img
								src={`${process.env.REACT_APP_STATIC_URL}/user/${user.id}.jpeg`}
								alt=""
								className="user-image"
							/>
						</ProfileAdminHeader>
						<ProfileAdminMain>
							<p>{user?.name}</p>
							<span> {user?.UserType?.name} </span>
							<span>
								desde{'  '}
								{moment(user?.createdAt).format(
									'DD/MM/YYYY'
								)}{' '}
							</span>
						</ProfileAdminMain>
					</ProfileAdmin>
				</Link>
			</ProfileAdminContainer>

			<ExitButton onClick={() => handleLogout()}>Sair</ExitButton>
		</Container>
	);
};

export default AdmHome;
