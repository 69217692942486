import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router'
import CopyrightBanner from '../Footer/CopyrightBanner'
import Header from '../Default/Header'
import MobileCategorysMenu from '../Category/MobileCategorysMenu/MobileCategorysMenu'
import Navigation from '../Navigation/Navigation'
import Footer from '../Footer/Footer'

type Props = {
    navigation?: true
    children: React.ReactNode
    title?: string
}

const Layout = ({ navigation, children, title }: Props): JSX.Element => {
    const location = useLocation()
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title && `${title} | Sambay express`}</title>
                <meta name="description" content={title && `| ${title}`} />
            </Helmet>
            <div className="tw-min-w-screen tw-min-h-screen tw-bg-white">
                <Header />
                <MobileCategorysMenu />
                {navigation && <Navigation />}
                {children}
            </div>
            {location.pathname !== '/user/data/top' &&
                location.pathname !== '/buy-address-process-cart' &&
                location.pathname !== '/buy-payment-process-cart' &&
                location.pathname !== '/buy-confirm-process-cart' && (
                    <>
                        <Footer />
                        <CopyrightBanner />
                    </>
                )}
        </>
    )
}

export default Layout
