import styled from 'styled-components'

export const Container = styled.div``

export const MyProductContainer = styled.div`
    padding: 80px;
`

export const ButtonStatus = styled.button`
    width: 7vw;
    background-color: #04aac0;
    margin-top: 5px;
    color: white;
    border: 1px solid black;
    border-radius: 8px;
    cursor: pointer;
    @media (max-width: 475px) {
        width: 50%;
        height: 3rem;
        font-size: 14px;
    }
`

export const ButtonNfc = styled.button`
    width: 15vw;
    align-items: center;
    justify-items: center;
    color: white;
    cursor: pointer;
    border-radius: 10px;
`

export const Title = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    height: 90px;
    padding-bottom: 1px;
    .title-organizer {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        border-bottom: solid 1px black;
        padding-bottom: 13px;
        h2 {
            font-size: 28px;
            color: #6b97a5;
            font-family: 'Poppins', semi-bold;
            font-weight: 600;
            margin: 0;
        }
        div {
            label {
                color: #c5c5c5;
                font-family: 'Poppins';
                font-weight: 600;
                font-size: 15px;
                margin-right: 8px;
            }
            select {
                width: 250px;
                height: 35px;
                border: solid 1px black;
            }
            select:focus {
                outline: none;
            }
        }
    }
    @media (min-width: 425px) and (max-width: 475px) {
        .title-organizer {
            h2 {
                font-size: 25px;
                padding-left: 2rem;
                padding-top: 2rem;
            }
        }
    }
    @media (min-width: 375px) and (max-width: 424px) {
        .title-organizer {
            h2 {
                font-size: 22px;
                padding-left: 2rem;
                padding-top: 2rem;
            }
        }
    }
    @media (min-width: 320px) and (max-width: 374px) {
        .title-organizer {
            h2 {
                font-size: 20px;
                padding-left: 2rem;
                padding-top: 2rem;
            }
        }
    }
`

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: row;

    .options-container {
        height: 52px;
        border: 1px solid black;
        border-radius: 8px;
        opacity: 1;
        display: flex;
        padding: 5px;
        padding-top: 0px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: start;
        margin-right: 30px;
        span {
            display: flex;
            flex-direction: row;
            align-items: start;
            margin-bottom: 5px;
            color: #6b97a5;
            font-family: 'Poppins';
            font-weight: bold;
            background-color: #fff;
            justify-content: start;
        }
        select {
            border: none;
            font-family: 'Poppins', regular;
            width: 100%;
            color: black;
            font-size: 14px;
            background-color: #fff;
            font-weight: 400;

            option {
                width: 120px;
                border: none;
            }
        }
        select:focus {
            outline: none;
        }
        select:hover {
            cursor: pointer;
        }
        input {
            width: 100%;
            font-size: 14;
            border: none;
        }
    }
    @media (min-width: 425px) and (max-width: 475px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        .options-container {
            margin-right: 0;
            width: 50%;
            margin-top: 1rem;
            select {
                width: 100%;
            }
        }
    }
    @media (min-width: 375px) and (max-width: 424px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        .options-container {
            margin-right: 0;
            width: 50%;
            margin-top: 1rem;
            select {
                width: 100%;
            }
        }
    }
    @media (min-width: 320px) and (max-width: 374px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        .options-container {
            margin-right: 0;
            width: 50%;
            margin-top: 1rem;
            select {
                width: 100%;
            }
        }
    }
`
export const FilterSelects = styled.div`
    width: 137px;
    height: 52px;
    border: 1px solid black;
    border-radius: 8px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    span {
        display: flex;
        flex-direction: row;
        align-items: start;
        margin-bottom: 5px;
        color: #6b97a5;
        font-family: 'Poppins';
        font-weight: bold;
        width: 111px;
        justify-content: start;
    }
    select {
        border: none;
        font-family: 'Poppins', regular;
        width: 120px;
        color: #a8a8a8;
        font-size: 14px;

        option {
            width: 120px;
            border: none;
        }
    }
    select:focus {
        outline: none;
    }
    select:hover {
        cursor: pointer;
    }
`
export const Card = styled.div`
    display: flex;
    flex-direction: column;
`
export const CardContainer = styled.div`
    width: 100%;
    border: 1px solid black;
    border-radius: 7px;
    margin-top: 70px;

    .product-infos-data-container-1 {
        padding: 20px;
        @media (max-width: 475px) {
            padding: 0;
            display: flex;
            flex-direction: column;
        }
        .purchase-identifiers {
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: center;

            h3 {
                font-size: 20px;
                color: #6b97a5;
                font-family: 'Poppins', semi-bold;
                font-weight: 600;
                @media (max-width: 475px) {
                    margin-bottom: 0;
                    font-size: 18px;
                }
            }

            label {
                font-size: 20px;
                font-family: 'Poppins';
                font-weight: 600;
                color: #6b97a5;
                background-color: #fff;
                padding: 0.5rem;
                border-radius: 1rem;
                @media (max-width: 475px) {
                    margin-bottom: 0;
                    font-size: 17px;
                }
            }
        }

        .refresh-button {
            margin-left: 15px;
            color: #4183c4;

            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }
        .product-infos-data-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 14px;

            @media (max-width: 475px) {
                flex-direction: column;
            }

            .left-side {
                display: flex;
                align-items: center;
                @media (max-width: 475px) {
                    gap: 1rem;
                }
                img {
                    width: 150px;
                    height: 98px;
                    border-radius: 15px;
                    object-fit: cover;
                }
                .style-icon-title-text {
                    color: #6b98a6;
                    font-family: 'Poppins';
                    @media (max-width: 475px) {
                        gap: 1rem;
                        h1 {
                            font-size: 16px;
                        }
                    }
                }
                @media (max-width: 375px) {
                    width: 100%;
                }
                @media (max-width: 320px) {
                    width: 100%;
                }
            }
            .left-sid2 {
                display: flex;
                flex-direction: column;
                justify-content: center;
                h1 {
                    color: #6b97a5;
                    font-size: 15px;
                    font-family: 'Poppins';
                    font-weight: bold;
                    width: 241px;
                    height: 66px;
                    white-space: 17px;
                    margin-left: 18px;

                    @media (max-width: 475px) {
                        font-size: 16px;
                    }
                }
            }
        }
        .buyer-information {
            border: 1px solid black;
            padding: 10px;
            border-radius: 8px;
            .buyer-information-title {
                font-size: 14px;
                color: #6b97a5;
                font-family: 'Poppins', semi-bold;
                font-weight: 600;
                margin: 0;
            }
        }

        .buyer-address,
        .buyer-contact {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 1rem;
            .link {
                display: flex;
                flex-direction: row;
                align-content: center;
                margin: 0.5rem;
                label {
                    padding: 0.25rem;
                    cursor: pointer;
                    color: #6b97a5;
                    font-size: 12px;
                    font-family: 'Poppins', semi-bold;
                    font-weight: 600;
                    @media (max-width: 475px) {
                        font-size: 14px;
                    }
                }
            }
            span {
                font-size: 13px;
                font-family: 'Poppins';
                font-weight: 600;
                color: #707070;
                background-color: #fff;
                padding: 0.75rem;
                border-radius: 1.25rem;
            }
            .copy {
                cursor: pointer;
            }
        }

        .middle-size {
            display: flex;
            align-items: center;

            @media (max-width: 475px) {
                flex-direction: column;
            }

            .input-invoice {
                padding: 1.08rem;
                background: #fff;
                border: 1px solid black;
                border-radius: 7px;
                color: #05a9c4;
                font-family: 'Poppins';
                font-weight: bold;
                font-size: min(12px, 0.9vw);

                @media (max-width: 475px) {
                    width: 95%;
                    font-size: 14px;
                }
            }
            .right-side {
                width: 120px;
                height: 51px;
                background: var(--unnamed-color-ffffff) 0% 0% no-repeat
                    padding-box;
                background: #ffffff 0% 0% no-repeat padding-box;
                border: 1px solid #05a9c4;
                border-radius: 7px;
                color: #05a9c4;
                font-family: 'Poppins';
                font-weight: bold;
                font-size: min(12px, 0.9vw);
                @media (max-width: 475px) {
                    width: 95%;
                    font-size: 14px;
                    margin: 0;
                    padding: 0;
                }

                &:hover {
                    cursor: pointer;
                }
                &:disabled {
                    background-color: #e8e8e8;
                    cursor: not-allowed;
                    color: black;
                }
            }

            .pdf-nf {
                color: black;
                font-weight: 600;
                margin: 0.5rem;
                font-family: 'Poppins';

                @media (min-width: 320px) and (max-width: 425px) {
                    flex-direction: column;
                    display: flex;
                }
            }
        }
    }
    .payShippingPriceContainer {
        display: flex;
        flex-direction: column;
        width: 8rem;
        span {
            color: #6b97a5;
            font-size: 11px;
            font-family: 'Poppins', semi-bold;
            font-weight: 600;
            background-color: #fff;
            margin-top: 0.6rem;
        }
        h2 {
            margin-top: 2px;
            margin-bottom: 2px;
            color: #707070;
            font-size: 15px;
            font-family: 'Poppins';
            font-weight: bold;
        }
        @media (max-width: 475px) {
            width: 100%;
            margin: 0;
            padding: 0;
            align-items: center;
            flex-direction: center;
            text-align: center;
            padding-bottom: 1rem;
            padding-top: 1rem;
        }
    }
    .product-infos-data-container-2 {
        display: flex;
        flex-direction: row;
        padding: 14px;
        border: 1px solid black;
        border-radius: 8px;
        @media (max-width: 475px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .product-payment-conditions,
        .product-delivery-conditions {
            width: 9vw;
            background: #fff;
            margin-right: 30px;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: start;
            padding: 10px;
            border-radius: 4px;

            @media (max-width: 475px) {
                width: 100%;
                margin: 0;
                padding: 0;
                align-items: center;
                flex-direction: center;
                text-align: center;
            }

            .payment-condition {
                color: #6b97a5;
                font-size: 11px;
                font-family: 'Poppins', semi-bold;
                font-weight: 600;
                background-color: #fff;

                @media (max-width: 475px) {
                    width: 100%;
                    margin: 0;
                    padding: 0;
                    align-items: center;
                    flex-direction: center;
                    text-align: center;
                    font-size: 16px;
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                }
            }

            .installments {
                font-size: 13px;
                font-family: 'Poppins', regular;
                color: #707070;
                @media (max-width: 475px) {
                    width: 100%;
                    margin: 0;
                    padding: 0;
                    align-items: center;
                    flex-direction: center;
                    text-align: center;
                    font-size: 15px;
                }
            }

            h2,
            h3 {
                margin-top: 2px;
                margin-bottom: 2px;
                color: #707070;
                font-size: 15px;
                font-family: 'Poppins';
                font-weight: bold;
            }
        }
        .buyer-avaliation {
            width: 237px;
            height: 106px;
            background: #fff;
            margin-right: 30px;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: start;
            padding: 10px;
            border-radius: 4px;

            .user-type {
                display: flex;
                @media (max-width: 475px) {
                    font-size: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    width: 100%;
                    padding-bottom: 1rem;
                }
                span {
                    color: #6b97a5;
                    font-size: 11px;
                    font-family: 'Poppins', semi-bold;
                    font-weight: 600;
                    display: flex;
                    @media (max-width: 475px) {
                        font-size: 16px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                    }
                }
            }

            .user-photo-and-rating {
                display: flex;
                flex-direction: row;

                .img-border {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 2px;
                    border-radius: 46px;
                    border: solid 1px black;
                    img {
                        width: 45px;
                        height: 45px;
                        border-radius: 25px;
                        object-fit: cover;
                    }
                }
            }
            .rating {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-left: 4px;
                justify-content: center;

                span {
                    color: #707070;
                    font-size: 16px;
                    font-family: 'Poppins', regular;
                    font-weight: 400;
                }
                .rating-stars-container {
                    display: flex;
                    flex-direction: row;

                    #star1,
                    #star2,
                    #star3,
                    #star4,
                    #star5 {
                        margin-right: 3px;
                        .a {
                            fill: white;
                            stroke: #ff7e0f;
                        }
                    }

                    #star-active {
                        .a {
                            fill: #ff7e0f;
                        }
                    }
                }
            }
            @media (max-width: 475px) {
                width: 50%;
                padding: 0;
                margin: 0;
                padding-top: 1rem;
                padding-bottom: 1rem;
            }
        }
        .chat-button-container {
            width: 158px;
            height: 106px;
            background-color: #69b64a;
            border-radius: 7px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .chat-button {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .chat-icon {
                }
                .text-container {
                    display: flex;
                    flex-direction: row;
                    justify-self: flex-end !important;
                    span {
                        color: white;
                        font-size: 15px;
                        font-family: 'Poppins';
                        font-weight: bold;
                    }
                }
            }
            .chat-button:hover {
                cursor: pointer;
            }
        }
    }
`

export const VariationContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .variationColorContainer {
        width: 3vw;
        height: 3vh;
        border-radius: 100px;
    }
`

export const TicketPrintContainer = styled.div`
    margin: 1rem;
`
