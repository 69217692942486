import styled from 'styled-components'

export const CardsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 45px;
    height: 100%;
    background-color: #fff;

    h1 {
        flex: 2;
    }

    .filters {
        flex: 2;
        margin-right: 87px;
        padding: 10px;
        padding-left: 100px;

        @media (max-width: 768px) {
            display: none;
        }

        h1 {
            color: black;
            border-bottom: solid 1px black;
        }

        .localization {
            color: black;
            border-bottom: solid 1px black;

            .localization-head {
                display: flex;
                flex-direction: row;
                align-items: center;

                span {
                    margin-bottom: 10px;
                }
            }

            .inputs-organizer {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 18px;
            }

            input[type='checkbox'] {
                height: 20px;
                width: 40px;
            }
            span {
                color: #6b98a6;
                opacity: 1;
                margin-left: 10px;
                font-weight: 600;
            }
            span:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .date {
            margin-top: 30px;

            .date-infos-container {
                display: flex;
                flex-direction: row;
                align-items: center;

                span {
                    color: #6b98a6;
                    opacity: 1;
                    margin-left: 10px;
                    font-weight: 600;
                }
                span:hover {
                    cursor: pointer;
                }
                h3 {
                    color: black;
                    margin: 0;
                }
            }
            input {
                width: 100%;
                height: 32px;
                border: none;
                border-bottom: solid 1px black;
                color: black;
                background-color: #fff;
            }
        }
        .association {
            margin-top: 30px;

            h3 {
                color: black;
            }

            select {
                color: black;
                width: 100%;
                height: 32px;
                border: solid 1px black;
            }
        }

        .bug-report {
            display: flex;
            flex-direction: column;
            margin-top: 30px;
            color: #6b98a6;
            font-family: 'Poppins';
            font-weight: 600;
            font-size: 12px;

            span {
                font-family: 'Poppins';
                font-size: 12px;
                font-weight: 600;
                text-decoration: underline;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .product-cards-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex: 11;
        column-gap: 25px;
        justify-content: center;

        @media (max-width: 768px) {
            column-gap: 9px;
            row-gap: 10px;

            justify-content: center;
        }
    }
`

export const ModalDimmer = styled.div`
    position: absolute;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    z-index: 0;
`

export const FiltersModalContainer = styled.div`
    background: #fff;
    width: 100%;
    height: 85%;
    position: absolute;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
`

export const ScreenLoading = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    transform: scale(5);

    h1{
        color:#05a5bf;
        font-family:'Poppins' ;
    }

    animation: is-rotating 3s infinite;
    
    @keyframes is-rotating {
    to {
        transform: rotate(2turn);
    }
    }

`

export const FiltersModal = styled.div`
    background: #fff;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    .modal-header {
        background: #05a5bf;
        height: 44px;
        width: 100%;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;

        h2 {
            height: 100%;
            width: 100%;
            padding: 9px 20px;
            color: white;
            font-family: 'Poppins';
            font-weight: bold;
            font-size: 20px;
        }
    }

    .modal-main {
        width: 100%;
        height: max-content;
        min-height: 400px;

        padding: 37px 32px;
        .inputs-organizer {
            display: flex;
            align-items: center;
            label {
                margin-left: 9px;
                color: black;
                font-family: 'Poppins';
                font-weight: 400;
                font-size: 10px;
            }
        }
        .localization-filters {
            display: flex;
            flex-direction: column;
            height: max-content;
            min-height: 105px;
            border-bottom: solid 1px black;

            h4 {
                font-family: 'Poppins';
                font-weight: 600;
                font-size: 13px;
                color: black;
            }

            span {
                color: #05a5bf;
                font-family: 'Poppins';
                font-weight: 400;
                font-size: 10px;
                margin-top: 20px;
            }
        }
        .created-at-container {
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            .title-and-clean-button {
                display: flex;
                align-items: flex-end;
                margin-bottom: 15px;

                h2 {
                    display: flex;
                    margin: 0;
                    margin-right: 15px;
                    color: black;
                    font-family: 'Poppins';
                    font-weight: 600;
                    font-size: 13px;
                }

                span {
                    color: #05a5bf;
                    font-family: 'Poppins';
                    font-weight: 400;
                    font-size: 10px;
                    margin-top: 5px;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .input-date {
                font-family: 'Poppins';
                font-weight: 400;
                font-size: 12px;
                color: black;
                padding-bottom: 7px;
                border: none;
                border-bottom: solid 1px black;
            }
        }
        .use-state-filter {
            display: flex;
            flex-direction: column;
            height: max-content;
            margin-top: 22px;
            h4 {
                font-family: 'Poppins';
                font-weight: 600;
                font-size: 13px;
                color: black;
            }

            span {
                color: #05a5bf;
                font-family: 'Poppins';
                font-weight: 400;
                font-size: 10px;
                margin-top: 20px;
            }
        }
    }
`
