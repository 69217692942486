import React, { useEffect, useState } from 'react'
import { ShippingsModalContainer, DeliveryContainer } from './styles'
import Home from '../../../../assets/icons/home.svg'
import {
    Product,
    ResponseShippingCalculate,
    useCalculateShippingValueLazyQuery,
    useGetShippingsByQuoteLazyQuery,
    useGetUserHasShippingServiceQuery,
} from '../../../../generated/graphql'
import { cepMaskFunc } from '../../../../utils/Masks'
import { Modal } from 'semantic-ui-react'
import Spinner from '../../../Loader/Spinner'
import {
    MdClose as Close,
} from 'react-icons/md'
import { IconButton } from '@material-ui/core'
import useWindow from '../../../../hooks/useWindow'
export function QuoteShipping(product: Product) {
    const window = useWindow()

    const [toCep, setToCep] = useState<string>()
    const [handleButton, setHandleButton] = useState<boolean>(false)
    const [state, setState] = useState({ dimmer: undefined, open: false })
    const show = (dimmer: any) => setState({ dimmer: dimmer, open: true })

    const [melhorEnvioShippings, setMelhorEnvioShippings] = useState<
        ResponseShippingCalculate[]
    >([])
    const [shippingsByQuote, setShippingsByQuote] = useState<any[]>([])

    const { data: hasService, loading: loadingHasService } =
        useGetUserHasShippingServiceQuery({
            fetchPolicy: 'network-only',
            variables: {
                userId: product!.userAccountId!,
            },
        })

    const [
        getShippingsByQuote,
        { data: dataShippingsByQuote, loading: loadingShippingsByQuote },
    ] = useGetShippingsByQuoteLazyQuery({ fetchPolicy: 'network-only' })

    const [
        getMelhorEnvioShippings,
        {
            data: dataMelhorEnvioShippings,
            loading: loadingMelhorEnvioShippings,
        },
    ] = useCalculateShippingValueLazyQuery({ fetchPolicy: 'network-only' })

    async function getQuery() {
        try {
            if (toCep) {
                getMelhorEnvioShippings({
                    variables: {
                        infos: {
                            from: {
                                postal_code: product!.ProfileAddress!.CEP!,
                            },
                            to: {
                                postal_code: toCep,
                            },
                            products: [
                                {
                                    height: product!.height!,
                                    id: product!.id.toString(),
                                    length: product!.thickness!,
                                    weight: Number.parseInt(product!.weight!),
                                    quantity: 1,
                                    width: product!.width!,
                                    insurance_value: product!.price!,
                                },
                            ],
                        },
                        userId: product!.userAccountId!,
                        productId: product.id,
                    },
                })
                getShippingsByQuote({
                    variables: {
                        productId: product.id,
                        postalCode: Number.parseInt(toCep.replace(/\D+/g, '')),
                    },
                })
                setToCep('')
                setHandleButton(false)
            }
        } catch (error) {
            console.log('Error', error)
        }
    }

    useEffect(() => {
        if (dataMelhorEnvioShippings) {
            setMelhorEnvioShippings(
                dataMelhorEnvioShippings.calculateShippingValue
            )

            if (!state.open) show('blurring')
        }

        if (dataShippingsByQuote) {
            setShippingsByQuote(dataShippingsByQuote.getShippingsByQuote)

            if (!state.open) show('blurring')
        }
    }, [dataMelhorEnvioShippings, dataShippingsByQuote])

    useEffect(() => {
        if (toCep) {
            if (/^(\d{2})\.(\d{3})-(\d{3})$/.test(toCep)) setHandleButton(true)
            else setHandleButton(false)
        }
    }, [toCep])

    if (
        !loadingHasService &&
        hasService &&
        hasService.getUserHasShippingService &&
        product.withdraw !== 'exclusivamente'
    ) {
        return (
            <>
                <Modal
                    dimmer="blurring"
                    open={state.open}
                    onClose={() => {
                        setState({
                            dimmer: undefined,
                            open: false,
                        })
                        setToCep('')
                    }}
                    size={window.innerWidth > 768 ? 'tiny' : 'mini'}
                    closeOnTriggerBlur
                >
                    <ShippingsModalContainer>
                        <div className="shipping-header">
                            <div>
                                <h2>Opções de envio:</h2>
                            </div>
                            <div className="close-modal">
                                <IconButton
                                    onClick={() =>
                                        setState({
                                            dimmer: undefined,
                                            open: false,
                                        })
                                    }
                                >
                                    <Close color="white" size={28} />
                                </IconButton>
                            </div>
                        </div>
                        <ul className="shipping-list">
                            {melhorEnvioShippings &&
                                melhorEnvioShippings.map(
                                    (shipping: ResponseShippingCalculate) => {
                                        return (
                                            <li
                                                className="shipping-item"
                                                key={shipping.name}
                                            >
                                                <div className="shipping-service">
                                                    <img
                                                        src={
                                                            shipping.company
                                                                ?.picture
                                                        }
                                                        alt={
                                                            shipping.company
                                                                ?.name
                                                        }
                                                        className="shipping-company"
                                                    />
                                                    <span className="shipping-name">
                                                        {shipping.name}
                                                    </span>
                                                </div>
                                                <div className="shipping-price">
                                                    <h5>
                                                        {Number.parseFloat(
                                                            shipping.custom_price!
                                                        ).toLocaleString(
                                                            'pt-br',
                                                            {
                                                                style: 'currency',
                                                                currency: 'BRL',
                                                            }
                                                        )}
                                                    </h5>
                                                    <span>
                                                        até{' '}
                                                        {shipping.delivery_time}{' '}
                                                        {shipping.delivery_time! >
                                                        1
                                                            ? 'dias úteis'
                                                            : 'dia util'}
                                                    </span>
                                                </div>
                                            </li>
                                        )
                                    }
                                )}
                            {shippingsByQuote &&
                                shippingsByQuote.map((shipping) => {
                                    return (
                                        <li
                                            className="shipping-item"
                                            key={shipping.description}
                                        >
                                            <div className="shipping-service">
                                                {shipping.carrierLogo ? (
                                                    <img
                                                        src={
                                                            shipping.carrierLogo
                                                        }
                                                        alt={
                                                            shipping.carrier ||
                                                            'Própria'
                                                        }
                                                        className="shipping-company"
                                                    />
                                                ) : (
                                                    <label className="shipping-carrier">
                                                        {shipping.carrier ||
                                                            'Própria'}
                                                    </label>
                                                )}
                                                <span className="shipping-name">
                                                    {shipping.description}
                                                </span>
                                            </div>
                                            <div className="shipping-price">
                                                <h5>
                                                    {(
                                                        shipping.price / 100
                                                    ).toLocaleString('pt-br', {
                                                        style: 'currency',
                                                        currency: 'BRL',
                                                    })}
                                                </h5>
                                                <span>
                                                    até {shipping.deliveryTime}{' '}
                                                    {shipping.deliveryTime! > 1
                                                        ? 'dias úteis'
                                                        : 'dia util'}
                                                </span>
                                            </div>
                                        </li>
                                    )
                                })}
                        </ul>
                    </ShippingsModalContainer>
                </Modal>
                <DeliveryContainer>
                    <div className="delivery">
                        <img src={Home} alt=" " id="home-img" />
                        <div className="input-organizer">
                            <label>Digite seu CEP</label>
                            <input
                                type="text"
                                onChange={(e) => {
                                    setToCep(cepMaskFunc(e.target.value))
                                }}
                                value={toCep}
                            />
                        </div>
                        <button
                            className={
                                handleButton
                                    ? 'calc-button calc-button-active'
                                    : 'calc-button calc-button-disabled'
                            }
                            onClick={getQuery}
                            disabled={!handleButton}
                        >
                            {loadingMelhorEnvioShippings ? (
                                <Spinner />
                            ) : (
                                'Calcular frete'
                            )}
                        </button>
                    </div>
                </DeliveryContainer>
            </>
        )
    }
}
