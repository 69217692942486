import React, { useState } from 'react';
import { Container, Tools, UserInfos } from './styles';
import brazil from '../../../assets/icons/brazil.svg';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import logo from '../../../assets/icons/logo.svg';
import { ReactComponent as AdmCategoryIcon } from '../../../assets/admMenuIcons/admCategorys.svg';
import { ReactComponent as AdmDashboardIcon } from '../../../assets/admMenuIcons/admDashboard.svg';
import { ReactComponent as AdmNotificationIcon } from '../../../assets/admMenuIcons/admNotification.svg';
import { ReactComponent as AdmProductsIcon } from '../../../assets/admMenuIcons/admProducts.svg';
import { ReactComponent as AdmRecordsIcon } from '../../../assets/admMenuIcons/admRecords.svg';
import IUserAccount from '../../../models/IUserAccount';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store';
import Img from '../CommomImg/Img';
import IProductsCart from '../../../models/ICart';
import { handleLogout } from '../../../handlers/handleLogout';

const AdmininistratorHeader: React.FC = () => {
	const history = useHistory();
	const location = useLocation();
	const user: IUserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const [showExit, setShowExit] = useState<boolean>(false);

	return (
		<>
			<Container>
				<Tools
					recordsColor={
						location.pathname === '/records' ? '#FF7E0F' : 'white'
					}
					notificationsColor={
						location.pathname === '/notifications'
							? '#FF7E0F'
							: 'white'
					}
					dashBoardColor={
						location.pathname === '/dashboard' ? '#FF7E0F' : 'white'
					}
					productsColor={
						location.pathname === '/products-home'
							? '#FF7E0F'
							: 'white'
					}
					categoriesColor={
						location.pathname === '/product-categories'
							? '#FF7E0F'
							: 'white'
					}
				>
					<img
						src={brazil}
						style={{ width: ' 30px', height: '21px' }}
						alt=" "
						title="Brasileiro, com orgulho!"
					/>
					<Link to="/home">
						<img
							src={logo}
							style={{
								width: '113px',
								height: '34px',
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center',
							}}
							alt=" "
						></img>
					</Link>
					{location.pathname !== '/home' && (
						<div className="menu-icons-container">
							<NavLink
								className="menu-icons-item"
								to="/notifications"
								activeClassName="menu-icons-item-active"
							>
								<AdmNotificationIcon className="notifications" />
								<span className="notifications-text">
									Notificações
								</span>
							</NavLink>
							<NavLink
								className="menu-icons-item"
								to="/home"
								activeClassName="menu-icons-item-active"
							>
								<AdmDashboardIcon className="dashboard" />

								<span className="dashboard-text">
									Dashboard
								</span>
							</NavLink>
							<NavLink
								to="/records"
								className="menu-icons-item"
								activeClassName="menu-icons-item-active"
							>
								<AdmRecordsIcon className="records" />

								<span className="records-text">Cadastros</span>
							</NavLink>
							<NavLink
								className="menu-icons-item"
								to="/product-categories"
								activeClassName="menu-icons-item-active"
							>
								<AdmCategoryIcon className="categories" />
								<span className="categories-text">
									Categorias
								</span>
							</NavLink>
							<NavLink
								className="menu-icons-item"
								to="/products-home"
								activeClassName="menu-icons-item-active"
							>
								<AdmProductsIcon className="productsColor" />

								<span className="productsColor-text">
									Produtos
								</span>
							</NavLink>
						</div>
					)}
				</Tools>
				<UserInfos>
					<div className="name-and-user-type">
						<span className="user-name">{user?.name}</span>
						<span className="user-type">
							{user?.UserType?.name}{' '}
						</span>
					</div>
					<Img
						src={`${process.env.REACT_APP_STATIC_URL}/user/${user.id}.jpeg`}
					/>
					{user && (
						<div
							className="down-arrow"
							onClick={() => setShowExit(!showExit)}
						/>
					)}
					{showExit && user !== null && (
						<div className="absolute">
							<div className="flex">
								<span onClick={() => history.push('/profile')}>
									Acessar Perfil
								</span>
								<span onClick={() => handleLogout()}>Sair</span>
							</div>
						</div>
					)}
				</UserInfos>
			</Container>
		</>
	);
};

export default AdmininistratorHeader;
