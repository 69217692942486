import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Additional_Services = {
   __typename?: 'Additional_services';
  collect: Scalars['Boolean'];
  own_hand: Scalars['Boolean'];
  receipt: Scalars['Boolean'];
};

export type AplicarDescontoInputData = {
  code: Scalars['String'];
  productId: Scalars['Float'];
};

export type AtualizarStatusCompraInputData = {
  purchaseId: Scalars['Float'];
  pagarmeTransacaoId: Scalars['String'];
};

export type BankAccount = {
   __typename?: 'BankAccount';
  object: Scalars['String'];
  id: Scalars['String'];
  bank_code: Scalars['String'];
  agencia: Scalars['String'];
  conta: Scalars['String'];
  conta_dv: Scalars['String'];
  legal_name: Scalars['String'];
  type: Scalars['String'];
  document_type: Scalars['String'];
  document_number: Scalars['String'];
};

export type BankAccountInCartReturn = {
   __typename?: 'BankAccountInCartReturn';
  boleto_url?: Maybe<Scalars['String']>;
  pix_qrcode?: Maybe<Scalars['String']>;
  pix_date_expiration?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['String']>;
};

export type BankAccountReturn = {
   __typename?: 'BankAccountReturn';
  boleto_url?: Maybe<Scalars['String']>;
};

export type BannerHomePages = {
   __typename?: 'BannerHomePages';
  id: Scalars['Float'];
  pathS3: Scalars['String'];
};

export type Banners = {
   __typename?: 'Banners';
  standardBannersVariable: Array<ShopBanners>;
  userBanners: Array<ShopBanners>;
};

export type Bling = {
   __typename?: 'Bling';
  /** Chave primaria da tabela de bling */
  id: Scalars['Float'];
  /** Chave de api da bling */
  apiKey: Scalars['String'];
  /** Id do usuario que solicitou o cadastro */
  userAccountId: Scalars['Float'];
};

export type Bling_Nfcs = {
   __typename?: 'Bling_Nfcs';
  id: Scalars['Float'];
  nfcId: Scalars['String'];
};

export type BlingProduct = {
   __typename?: 'BlingProduct';
  id: Scalars['String'];
  codigo?: Maybe<Scalars['String']>;
  descricao: Scalars['String'];
  tipo: Scalars['String'];
  situacao: Scalars['String'];
  unidade?: Maybe<Scalars['String']>;
  preco: Scalars['String'];
  precoCusto?: Maybe<Scalars['String']>;
  descricaoCurta?: Maybe<Scalars['String']>;
  descricaoComplementar?: Maybe<Scalars['String']>;
  dataInclusao: Scalars['String'];
  dataAlteracao: Scalars['String'];
  imageThumbnail?: Maybe<Scalars['String']>;
  urlVideo?: Maybe<Scalars['String']>;
  nomeFornecedor?: Maybe<Scalars['String']>;
  codigoFabricante?: Maybe<Scalars['String']>;
  marca?: Maybe<Scalars['String']>;
  class_fiscal?: Maybe<Scalars['String']>;
  cest?: Maybe<Scalars['String']>;
  origem?: Maybe<Scalars['String']>;
  idGrupoProduto?: Maybe<Scalars['String']>;
  linkExterno?: Maybe<Scalars['String']>;
  observacoes?: Maybe<Scalars['String']>;
  grupoProduto?: Maybe<Scalars['String']>;
  garantia?: Maybe<Scalars['String']>;
  descricaoFornecedor?: Maybe<Scalars['String']>;
  idFabricante: Scalars['String'];
  categoria: Categoria;
  pesoLiq: Scalars['String'];
  pesoBruto: Scalars['String'];
  estoqueMinimo?: Maybe<Scalars['String']>;
  estoqueMaximo?: Maybe<Scalars['String']>;
  gtin?: Maybe<Scalars['String']>;
  gtinEmbalagem?: Maybe<Scalars['String']>;
  larguraProduto?: Maybe<Scalars['String']>;
  alturaProduto?: Maybe<Scalars['String']>;
  profundidadeProduto?: Maybe<Scalars['String']>;
  unidadeMedida: Scalars['String'];
  itensPorCaixa: Scalars['Float'];
  volumes: Scalars['Float'];
  localizacao?: Maybe<Scalars['String']>;
  crossdocking: Scalars['String'];
  condicao: Scalars['String'];
  freteGratis: Scalars['String'];
  producao: Scalars['String'];
  dataValidade?: Maybe<Scalars['String']>;
  imagem?: Maybe<Array<ImagemProduto>>;
  produtoLoja?: Maybe<ProdutoLoja>;
  codigopai?: Maybe<Scalars['String']>;
  estoqueAtual?: Maybe<Scalars['Float']>;
  depositos?: Maybe<Array<DepositoProduto>>;
};

export type BuyTicketsRequest = {
  orders: Array<Scalars['String']>;
  gateway?: Maybe<Scalars['String']>;
  redirect: Scalars['String'];
};

export type BuyTocketsReturn = {
   __typename?: 'BuyTocketsReturn';
  url: Scalars['String'];
};

export type Card = {
   __typename?: 'Card';
  object?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  date_created: Scalars['String'];
  date_updated: Scalars['String'];
  brand: Scalars['String'];
  holder_name: Scalars['String'];
  first_digits: Scalars['String'];
  last_digits: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  fingerprint: Scalars['String'];
  customer?: Maybe<Scalars['String']>;
  valid: Scalars['Boolean'];
  expiration_date: Scalars['String'];
};

export type Categoria = {
   __typename?: 'Categoria';
  id: Scalars['String'];
  descricao: Scalars['String'];
};

export type CepResponse = {
   __typename?: 'CepResponse';
  productId?: Maybe<Scalars['Float']>;
  Codigo?: Maybe<Scalars['String']>;
  Valor?: Maybe<Scalars['String']>;
  PrazoEntrega?: Maybe<Scalars['String']>;
  ValorSemAdicionais?: Maybe<Scalars['String']>;
  ValorMaoPropria?: Maybe<Scalars['String']>;
  ValorAvisoRecebimento?: Maybe<Scalars['String']>;
  ValorValorDeclarado?: Maybe<Scalars['String']>;
  EntregaDomiciliar?: Maybe<Scalars['String']>;
  EntregaSabado?: Maybe<Scalars['String']>;
  obsFim?: Maybe<Scalars['String']>;
  Erro?: Maybe<Scalars['String']>;
  MsgErro?: Maybe<Scalars['String']>;
};

export type CepResponse2 = {
   __typename?: 'CepResponse2';
  Codigo?: Maybe<Scalars['String']>;
  Valor?: Maybe<Scalars['String']>;
  PrazoEntrega?: Maybe<Scalars['String']>;
  ValorSemAdicionais?: Maybe<Scalars['String']>;
  ValorMaoPropria?: Maybe<Scalars['String']>;
  ValorAvisoRecebimento?: Maybe<Scalars['String']>;
  ValorValorDeclarado?: Maybe<Scalars['String']>;
  EntregaDomiciliar?: Maybe<Scalars['String']>;
  EntregaSabado?: Maybe<Scalars['String']>;
  obsFim?: Maybe<Scalars['String']>;
  Erro?: Maybe<Scalars['String']>;
  MsgErro?: Maybe<Scalars['String']>;
};

export type ChatHub = {
   __typename?: 'ChatHub';
  id: Scalars['Float'];
  title: Scalars['String'];
  ChatHubMessages?: Maybe<Array<ChatHubMessages>>;
  purchaseId: Scalars['Float'];
  Purchase?: Maybe<Purchase>;
};

export type ChatHubMessages = {
   __typename?: 'ChatHubMessages';
  id?: Maybe<Scalars['Float']>;
  message: Scalars['String'];
  createdAt: Scalars['DateTime'];
  chatHubId?: Maybe<Scalars['Float']>;
  ChatHub?: Maybe<ChatHub>;
  userAccountId: Scalars['Float'];
  UserAccount?: Maybe<UserAccount>;
};

export type CheckBalanceResponse = {
   __typename?: 'CheckBalanceResponse';
  balance: Scalars['String'];
  reserved: Scalars['String'];
  debts: Scalars['String'];
};

export type ColorVariations = {
   __typename?: 'ColorVariations';
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type Company = {
   __typename?: 'Company';
  id: Scalars['Float'];
  name: Scalars['String'];
  picture: Scalars['String'];
};

export type CreateBannerInput = {
  userId: Scalars['Float'];
  fileName: Scalars['String'];
};

export type CreateBlingCountInputData = {
  apiKey: Scalars['String'];
  userAccountId: Scalars['Float'];
};

export type CreateCupomInputData = {
  code: Scalars['String'];
  userId: Scalars['Float'];
  productsId: Array<Scalars['Float']>;
  desconto: Scalars['Float'];
  validade: Scalars['Float'];
};

export type CreateDinamicSliderInput = {
  title: Scalars['String'];
  dateStart: Scalars['DateTime'];
  dateEnd: Scalars['DateTime'];
};

export type CreateDinamicSliderItemInput = {
  idSlider: Scalars['Float'];
  productsId: Array<Scalars['Float']>;
};

export type CreateMelhorEnvioUsersRequest = {
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  document: Scalars['String'];
  birthdate: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  phone_mobile: Scalars['String'];
  address: Scalars['String'];
  address_label: Scalars['String'];
  address_postal_code: Scalars['String'];
  address_number: Scalars['String'];
  address_city: Scalars['String'];
  address_state_abbr: Scalars['String'];
};

export type CreateProductPaidInput = {
  userId: Scalars['Float'];
  numInstallments: Scalars['Float'];
  payment_method: Scalars['String'];
  cardId: Scalars['String'];
  product: ProductInput;
};

export type CreateProductVariationInputData = {
  productId: Scalars['Float'];
  cor?: Maybe<Scalars['String']>;
  tamanho?: Maybe<Scalars['String']>;
  quantidade: Scalars['Float'];
};

export type CupomProducts = {
   __typename?: 'CupomProducts';
  id: Scalars['Float'];
  productId: Scalars['Float'];
  cupomId: Scalars['Float'];
  Product: Product;
};

export type Cupons = {
   __typename?: 'Cupons';
  id: Scalars['Float'];
  code: Scalars['String'];
  desconto: Scalars['Float'];
  validade: Scalars['DateTime'];
  userId: Scalars['Float'];
};

export type Custom_Delivery_Range = {
   __typename?: 'Custom_delivery_range';
  max: Scalars['Float'];
  min: Scalars['Float'];
};


export type DeleteCupomInputData = {
  cupomId: Scalars['Float'];
};

export type Delivery_Range = {
   __typename?: 'Delivery_range';
  max: Scalars['Float'];
  min: Scalars['Float'];
};

export type Deposito = {
   __typename?: 'Deposito';
  id: Scalars['String'];
  nome: Scalars['String'];
  saldo: Scalars['Float'];
  desconsiderar: Scalars['String'];
  saldoVirtual: Scalars['Float'];
};

export type DepositoProduto = {
   __typename?: 'DepositoProduto';
  deposito: Deposito;
};

export type Dimensions = {
   __typename?: 'Dimensions';
  height: Scalars['Float'];
  width: Scalars['Float'];
  length: Scalars['Float'];
};

export type DinamicSliders = {
   __typename?: 'DinamicSliders';
  id: Scalars['Float'];
  title: Scalars['String'];
  dateStart: Scalars['DateTime'];
  dateEnd?: Maybe<Scalars['DateTime']>;
  DinamicSlidersItens: Array<DinamicSlidersItens>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type DinamicSlidersItens = {
   __typename?: 'DinamicSlidersItens';
  id: Scalars['Float'];
  dinamicId: Scalars['Float'];
  productId: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  Product?: Maybe<Product>;
};

export type EditCupomInputData = {
  id: Scalars['Float'];
  code: Scalars['String'];
  prazoValidade: Scalars['Float'];
  desconto: Scalars['Float'];
  cuponProdutos: Array<Scalars['Float']>;
};

export type Filters = {
  categorieId: Scalars['Float'];
  type: Scalars['String'];
  status: Scalars['String'];
};

export type From = {
  postal_code: Scalars['String'];
};

export type GenerateTicketsRequest = {
  orders: Array<Scalars['String']>;
};

export type GerarNotaFiscalInputData = {
  userId: Scalars['Float'];
  productId: Scalars['Float'];
  ticketId?: Maybe<Scalars['Float']>;
  purchaseId: Scalars['Float'];
};

export type GetAllCupomByUserIdInputData = {
  userId: Scalars['Float'];
};

export type GetAllCupomProductsByCupomIdInputData = {
  cupomId: Scalars['Float'];
};

export type GetAllProductInput = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type GetAllVariationProductInput = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type GetBlingAccountInputData = {
  userId: Scalars['Float'];
};

export type GetCupomInputData = {
  id: Scalars['Float'];
};

export type GetDinamicSliderInput = {
  idSlider: Scalars['Float'];
};

export type GetProductByUserId = {
  userId: Scalars['Float'];
  active?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type GetProductInput = {
  userId?: Maybe<Scalars['Float']>;
  id: Scalars['Float'];
};

export type GetProductsSoldByPeriodOutput = {
   __typename?: 'GetProductsSoldByPeriodOutput';
  n_totalProduto: Scalars['String'];
  productId: Scalars['Float'];
  Product: Product;
};

export type GetPurchaseStatusByPurchaseIdInputData = {
  purchaseId: Scalars['Float'];
};

export type GetServiceResponse = {
   __typename?: 'GetServiceResponse';
  id: Scalars['Float'];
  name: Scalars['String'];
  picture: Scalars['String'];
};

export type GroundsForReports = {
   __typename?: 'GroundsForReports';
  id: Scalars['Float'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type ImagemProduto = {
   __typename?: 'ImagemProduto';
  link: Scalars['String'];
  validade: Scalars['String'];
  tipoArmazenamento: Scalars['String'];
};

export type InsertBalanceRequest = {
  gateway: Scalars['String'];
  redirect: Scalars['String'];
  value: Scalars['String'];
};

export type InsertTicketOnCartResponse = {
  additional_info: Scalars['String'];
  agency_id: Scalars['String'];
  authorization_code: Scalars['String'];
  billed_weight: Scalars['Float'];
  canceled_at: Scalars['String'];
  collect: Scalars['String'];
  collect_scheduled_at: Scalars['String'];
  contract: Scalars['String'];
  coupon: Scalars['String'];
  created_at: Scalars['String'];
  cte_key: Scalars['String'];
  delivered_at: Scalars['String'];
  delivery_max: Scalars['Float'];
  delivery_min: Scalars['Float'];
  delivery_receipt: Scalars['String'];
  diameter: Scalars['Float'];
  discount: Scalars['Float'];
  expired_at: Scalars['String'];
  format: Scalars['String'];
  generated_at: Scalars['String'];
  height: Scalars['Float'];
  id: Scalars['String'];
  insurance_value: Scalars['Float'];
  length: Scalars['Float'];
  non_commercial: Scalars['Boolean'];
  own_hand: Scalars['Boolean'];
  paid_at: Scalars['String'];
  parse_pi_at: Scalars['String'];
  posted_at: Scalars['String'];
  price: Scalars['Float'];
  protocol: Scalars['String'];
  quote: Scalars['Float'];
  receipt: Scalars['Boolean'];
  reminder: Scalars['String'];
  reverse: Scalars['Boolean'];
  self_tracking: Scalars['String'];
  service_code: Scalars['String'];
  service_id: Scalars['Float'];
  status: Scalars['String'];
  suspended_at: Scalars['String'];
  tracking: Scalars['String'];
  updated_at: Scalars['String'];
  weight: Scalars['String'];
  width: Scalars['String'];
};

export type InsertTicketsOnCartRequest = {
  service: Scalars['Float'];
  agency?: Maybe<Scalars['Float']>;
  from: TicketFrom;
  to: TicketTo;
  products: Array<TicketProducts>;
  volumes: Array<TicketVolumes>;
  options: TicketOptions;
};

export type Invoice = {
  key: Scalars['String'];
};

export type LoginResponse = {
   __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  user: UserAccount;
};

export type MelhorEnvioTokenReturn = {
   __typename?: 'MelhorEnvioTokenReturn';
  token?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type Mutation = {
   __typename?: 'Mutation';
  createBlingCount: Bling;
  findBlingNfcs: Scalars['Boolean'];
  gerarNotaFiscal: Scalars['Boolean'];
  publishBlingProducts: Scalars['Boolean'];
  createChatHubForFreeComunication: ChatHub;
  createCupom: Scalars['Boolean'];
  deleteCupom: Scalars['Boolean'];
  editCupom: Scalars['Boolean'];
  createDinamicSlider: DinamicSliders;
  createDinamicSliderItem: DinamicSliders;
  updateDinamicSlider: DinamicSliders;
  deleteDinamicSliderItem: Scalars['Boolean'];
  createGroundsReport: GroundsForReports;
  desconectarConexao: Scalars['Boolean'];
  createProductQuestionAnswers: ProductQuestionsAnswers;
  updateProductQuestionAnswer: Scalars['Boolean'];
  deleteProductQuestionAnswer: Scalars['Boolean'];
  createProductQuestion: ProductQuestions;
  markAsRepeat: Scalars['Boolean'];
  createProductRating: ProductsRating;
  deleteRating: Scalars['Boolean'];
  updateProductRating: Scalars['Boolean'];
  addVisitsProduct: Scalars['Boolean'];
  createProduct: Product;
  createProductImage: Scalars['Boolean'];
  createProductBid: ProductBid;
  createProductPaid: UserAccount;
  updateProductStatus: Scalars['Boolean'];
  updateProduct: Scalars['Boolean'];
  deleteProduct: Scalars['Boolean'];
  createProductCategorie: ProductCategories;
  updateProductCategorie: Scalars['Boolean'];
  deleteProductCategorie: Scalars['Boolean'];
  includeProduct: Scalars['Boolean'];
  removeProduct: Scalars['Boolean'];
  createProductSubCategorie: ProductSubCategories;
  deleteProductSubCategorie: Scalars['Boolean'];
  PurchaseInCart: BankAccountInCartReturn;
  createCartNotification: Scalars['Boolean'];
  setTicketId: Scalars['Boolean'];
  reportPurchaseProblem: Scalars['Boolean'];
  deletePurchase: Scalars['Boolean'];
  sendSolicitationCancelPurchase: Scalars['Boolean'];
  createRecipient: UserAccount;
  atualizarStatusCompra: Scalars['Boolean'];
  putTrackingCode: Scalars['Boolean'];
  addInvoice: Scalars['Boolean'];
  createReport: Reports;
  insertProductOnMelhorEnvioCart: BuyTocketsReturn;
  createUserOnMelhorEnvio: Scalars['Boolean'];
  putShippings: Scalars['Boolean'];
  putShippingsFromCsv: Scalars['Boolean'];
  deleteShippings: Scalars['Boolean'];
  createShopBanner: ShopBanners;
  createBanner: Scalars['Boolean'];
  createCard?: Maybe<Card>;
  deleteCard: Scalars['Boolean'];
  createUserAccount: UserAccount;
  createUseraccountWithAdmUser: NewUser;
  login: LoginResponse;
  logout: Scalars['Boolean'];
  updateUserAccount: UserAccount;
  updateAddress: Scalars['Boolean'];
  deleteAddress: Scalars['Boolean'];
  createAddress: ProfileAddress;
  createUserImage: Scalars['Boolean'];
  resetUserPassword: Scalars['Boolean'];
  blockUser: Scalars['Boolean'];
  setMelhorEnvioToken: Scalars['Boolean'];
  reportBug: Scalars['Boolean'];
  sendConfirmationEmail: Scalars['Boolean'];
  deleteBankAccount: Scalars['Boolean'];
  createUserBargain: UserBargains;
  updateUserProductBargain: Scalars['Boolean'];
  createUserCustomization: UserCustomization;
  createUserCustomizationWithCustomBrand: UserCustomization;
  uploadBrandOrBackground: Scalars['String'];
  returnToStandard: Scalars['Boolean'];
  deleteLogoMarca: Scalars['Boolean'];
  updateUserNotificationStatus: Scalars['Boolean'];
  deleteProductVariationById: Scalars['Boolean'];
  updateProductVariationById: Scalars['Boolean'];
  createProdutVariation: Scalars['Boolean'];
};


export type MutationCreateBlingCountArgs = {
  createBlingCountInputData: CreateBlingCountInputData;
};


export type MutationFindBlingNfcsArgs = {
  purchaseId: Scalars['Float'];
};


export type MutationGerarNotaFiscalArgs = {
  gerarNotaFiscalInputData: GerarNotaFiscalInputData;
};


export type MutationPublishBlingProductsArgs = {
  minimumInstallmentPrice?: Maybe<Scalars['Float']>;
  profileAddressId?: Maybe<Scalars['Float']>;
  categoryId?: Maybe<Scalars['Float']>;
  blingProductIds: Array<Scalars['String']>;
  userId: Scalars['Float'];
};


export type MutationCreateChatHubForFreeComunicationArgs = {
  userId: Scalars['Float'];
  productId: Scalars['Float'];
};


export type MutationCreateCupomArgs = {
  createCupomInputData: CreateCupomInputData;
};


export type MutationDeleteCupomArgs = {
  deleteCupomInputData: DeleteCupomInputData;
};


export type MutationEditCupomArgs = {
  editCupomInputData: EditCupomInputData;
};


export type MutationCreateDinamicSliderArgs = {
  CreateDinamicSliderInput: CreateDinamicSliderInput;
};


export type MutationCreateDinamicSliderItemArgs = {
  createDinamicSliderItemInput: CreateDinamicSliderItemInput;
};


export type MutationUpdateDinamicSliderArgs = {
  updateDinamicSliderInput: UpdateDinamicSliderInput;
};


export type MutationDeleteDinamicSliderItemArgs = {
  productId: Scalars['Float'];
};


export type MutationDesconectarConexaoArgs = {
  userId: Scalars['Float'];
};


export type MutationCreateProductQuestionAnswersArgs = {
  description: Scalars['String'];
  userId: Scalars['Float'];
  productQuestionId: Scalars['Float'];
};


export type MutationUpdateProductQuestionAnswerArgs = {
  description: Scalars['String'];
  id: Scalars['Float'];
};


export type MutationDeleteProductQuestionAnswerArgs = {
  id: Scalars['Float'];
};


export type MutationCreateProductQuestionArgs = {
  description: Scalars['String'];
  userId: Scalars['Float'];
  productId: Scalars['Float'];
};


export type MutationMarkAsRepeatArgs = {
  userId: Scalars['Float'];
  id: Scalars['Float'];
};


export type MutationCreateProductRatingArgs = {
  productInput: ProductRatingInput;
};


export type MutationDeleteRatingArgs = {
  id: Scalars['Float'];
};


export type MutationUpdateProductRatingArgs = {
  ratingDescription: Scalars['String'];
  rating: Scalars['Float'];
  ratingId: Scalars['Float'];
};


export type MutationAddVisitsProductArgs = {
  productId: Scalars['Float'];
  codeUserView: Scalars['String'];
};


export type MutationCreateProductArgs = {
  productInput: ProductInput;
};


export type MutationCreateProductImageArgs = {
  Picture: Array<Scalars['Upload']>;
  PictureNumber: Scalars['Float'];
  ProductId: Scalars['Float'];
};


export type MutationCreateProductBidArgs = {
  bid: Scalars['String'];
  userId: Scalars['Float'];
  productId: Scalars['Float'];
};


export type MutationCreateProductPaidArgs = {
  createProductPaidInput: CreateProductPaidInput;
};


export type MutationUpdateProductStatusArgs = {
  id: Scalars['Float'];
  status: Scalars['Boolean'];
};


export type MutationUpdateProductArgs = {
  productUpdateInput: ProductUpdateInput;
};


export type MutationDeleteProductArgs = {
  productId: Scalars['Float'];
};


export type MutationCreateProductCategorieArgs = {
  split?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
};


export type MutationUpdateProductCategorieArgs = {
  split?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
};


export type MutationDeleteProductCategorieArgs = {
  id: Scalars['Float'];
};


export type MutationIncludeProductArgs = {
  blockReason: Scalars['String'];
  productId: Scalars['Float'];
};


export type MutationRemoveProductArgs = {
  productId: Scalars['Float'];
};


export type MutationCreateProductSubCategorieArgs = {
  name: Scalars['String'];
  categorieId: Scalars['Float'];
};


export type MutationDeleteProductSubCategorieArgs = {
  id: Scalars['Float'];
};


export type MutationPurchaseInCartArgs = {
  purchaseInCartInput: PurchaseInCartInput;
};


export type MutationCreateCartNotificationArgs = {
  productId: Scalars['Float'];
  userId: Scalars['Float'];
};


export type MutationSetTicketIdArgs = {
  ticketId: Scalars['Float'];
  purchaseId: Scalars['Float'];
};


export type MutationReportPurchaseProblemArgs = {
  description: Scalars['String'];
  type: Scalars['String'];
  email: Scalars['String'];
  purchaseId: Scalars['Float'];
};


export type MutationDeletePurchaseArgs = {
  userId: Scalars['Float'];
  purchaseId: Scalars['String'];
};


export type MutationSendSolicitationCancelPurchaseArgs = {
  descriptionCancel: Scalars['String'];
  userId: Scalars['Float'];
  purchaseId: Scalars['String'];
};


export type MutationCreateRecipientArgs = {
  recipientInput: RecipientInput;
};


export type MutationAtualizarStatusCompraArgs = {
  atualizarStatusCompraInputData: AtualizarStatusCompraInputData;
};


export type MutationPutTrackingCodeArgs = {
  putTrackingCodeInputData: PutTrackingCodeInputData;
};


export type MutationAddInvoiceArgs = {
  purchaseId: Scalars['Float'];
  purchaseInvoice: Scalars['Upload'];
};


export type MutationCreateReportArgs = {
  groundsForReportId: Scalars['Float'];
  userAccountId: Scalars['Float'];
  productId: Scalars['Float'];
};


export type MutationInsertProductOnMelhorEnvioCartArgs = {
  gateway?: Maybe<Scalars['String']>;
  purchaseId: Scalars['Float'];
  userId: Scalars['Float'];
  productInfos: InsertTicketsOnCartRequest;
};


export type MutationCreateUserOnMelhorEnvioArgs = {
  createMelhorEnvioUsersRequest: CreateMelhorEnvioUsersRequest;
};


export type MutationPutShippingsArgs = {
  shippingInput: Array<ShippingInput>;
  userId: Scalars['Float'];
};


export type MutationPutShippingsFromCsvArgs = {
  csv: Scalars['Upload'];
  userId: Scalars['Float'];
};


export type MutationDeleteShippingsArgs = {
  shippingIds: Array<Scalars['Float']>;
  userId: Scalars['Float'];
};


export type MutationCreateShopBannerArgs = {
  Picture: Scalars['Upload'];
  name: Scalars['String'];
  userId: Scalars['Float'];
};


export type MutationCreateBannerArgs = {
  Picture: Scalars['Upload'];
  createBannerInput: CreateBannerInput;
};


export type MutationCreateCardArgs = {
  userId: Scalars['Float'];
  card: RequestCard;
};


export type MutationDeleteCardArgs = {
  id: Scalars['String'];
};


export type MutationCreateUserAccountArgs = {
  accountInput: UserAccountInput;
};


export type MutationCreateUseraccountWithAdmUserArgs = {
  newSocialProjectTypeInput?: Maybe<NewSocialProjectTypeInput>;
  userTypeId: Scalars['Float'];
  name: Scalars['String'];
  email: Scalars['String'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  email: Scalars['String'];
};


export type MutationUpdateUserAccountArgs = {
  accountInput: UserAccountUpdateInput;
  id: Scalars['Float'];
};


export type MutationUpdateAddressArgs = {
  userAccountUpdateAddressInput: UserAccountUpdateAddressInput;
  id: Scalars['Float'];
};


export type MutationDeleteAddressArgs = {
  addressId: Scalars['Float'];
};


export type MutationCreateAddressArgs = {
  userAccountUpdateAddressInput: UserAccountUpdateAddressInput;
  id: Scalars['Float'];
};


export type MutationCreateUserImageArgs = {
  Picture: Scalars['Upload'];
  userId: Scalars['Float'];
};


export type MutationResetUserPasswordArgs = {
  email: Scalars['String'];
};


export type MutationBlockUserArgs = {
  block: Scalars['Boolean'];
  userId: Scalars['Float'];
};


export type MutationSetMelhorEnvioTokenArgs = {
  userId: Scalars['Float'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};


export type MutationReportBugArgs = {
  problemDescription: Scalars['String'];
  contactEmail: Scalars['String'];
};


export type MutationSendConfirmationEmailArgs = {
  email: Scalars['String'];
};


export type MutationDeleteBankAccountArgs = {
  id: Scalars['Float'];
};


export type MutationCreateUserBargainArgs = {
  productId: Scalars['Float'];
  userId: Scalars['Float'];
  isValid: Scalars['Boolean'];
  bargain: Scalars['String'];
};


export type MutationUpdateUserProductBargainArgs = {
  productId: Scalars['Float'];
  bargain: Scalars['String'];
  isValid: Scalars['Boolean'];
  userId: Scalars['Float'];
};


export type MutationCreateUserCustomizationArgs = {
  userId: Scalars['Float'];
  brandColor?: Maybe<Scalars['String']>;
  shopName?: Maybe<Scalars['String']>;
  brandTextColor?: Maybe<Scalars['String']>;
  brandIsImage?: Maybe<Scalars['Boolean']>;
  brand?: Maybe<Scalars['String']>;
  backgroundIsImage?: Maybe<Scalars['Boolean']>;
  background?: Maybe<Scalars['String']>;
};


export type MutationCreateUserCustomizationWithCustomBrandArgs = {
  Picture?: Maybe<Scalars['Upload']>;
  userId: Scalars['Float'];
};


export type MutationUploadBrandOrBackgroundArgs = {
  userId: Scalars['Float'];
  Picture?: Maybe<Scalars['Upload']>;
  isBackground: Scalars['Boolean'];
};


export type MutationReturnToStandardArgs = {
  userId: Scalars['Float'];
};


export type MutationDeleteLogoMarcaArgs = {
  userId: Scalars['Float'];
};


export type MutationUpdateUserNotificationStatusArgs = {
  id: Scalars['Float'];
};


export type MutationDeleteProductVariationByIdArgs = {
  productVariationId: Scalars['Float'];
};


export type MutationUpdateProductVariationByIdArgs = {
  updateProductVariation: UpdateProductVariationInputData;
};


export type MutationCreateProdutVariationArgs = {
  createProductVariation: CreateProductVariationInputData;
};

export type NewSocialProjectTypeInput = {
  isSocialProjectAccount?: Maybe<Scalars['Boolean']>;
  socialProjectCNPJ?: Maybe<Scalars['String']>;
  socialProjectCEP?: Maybe<Scalars['String']>;
  socialProjectCity?: Maybe<Scalars['String']>;
  socialProjectNeighborhood?: Maybe<Scalars['String']>;
  socialProjectState?: Maybe<Scalars['String']>;
  socialProjectAddress?: Maybe<Scalars['String']>;
  socialProjectNumber?: Maybe<Scalars['String']>;
  socialProjectComplement?: Maybe<Scalars['String']>;
};

export type NewUser = {
   __typename?: 'NewUser';
  email: Scalars['String'];
  password: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
  userTypeId: Scalars['Float'];
  UserType: UserType;
};

export type Package = {
   __typename?: 'Package';
  dimensions: Dimensions;
  discount?: Maybe<Scalars['String']>;
  format: Scalars['String'];
  insurance_value: Scalars['String'];
  price: Scalars['String'];
  products: Products;
  weight: Scalars['String'];
};

export type PackageClass = {
  id: Scalars['String'];
  height: Scalars['Float'];
  width: Scalars['Float'];
  length: Scalars['Float'];
  weight: Scalars['Float'];
  quantity: Scalars['Float'];
  insurance_value: Scalars['Float'];
};

export type Plans = {
   __typename?: 'Plans';
  id: Scalars['Float'];
  title: Scalars['String'];
  price: Scalars['Float'];
  product?: Maybe<Array<Product>>;
};

export type PrintTicketsRequest = {
  orders: Array<Scalars['String']>;
  mode: Scalars['String'];
};

export type Product = {
   __typename?: 'Product';
  id: Scalars['Float'];
  title: Scalars['String'];
  subtitle: Scalars['String'];
  amount?: Maybe<Scalars['Float']>;
  typeNegotiation: Scalars['String'];
  InitialValue: Scalars['Float'];
  price: Scalars['Float'];
  directValue?: Maybe<Scalars['Float']>;
  finalOffersTime?: Maybe<Scalars['DateTime']>;
  active: Scalars['Boolean'];
  quantity: Scalars['Float'];
  stock?: Maybe<Scalars['Float']>;
  description: Scalars['String'];
  brand?: Maybe<Scalars['String']>;
  useState?: Maybe<Scalars['String']>;
  maxInstallments?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  thickness?: Maybe<Scalars['Float']>;
  withdraw?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  numberOfPhotos?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['String']>;
  material?: Maybe<Scalars['String']>;
  isSocialProject?: Maybe<Scalars['Boolean']>;
  shippingWay?: Maybe<Scalars['String']>;
  product_height?: Maybe<Scalars['Float']>;
  product_width?: Maybe<Scalars['Float']>;
  product_length?: Maybe<Scalars['Float']>;
  product_weight?: Maybe<Scalars['Float']>;
  prompt_delivery?: Maybe<Scalars['Boolean']>;
  planId?: Maybe<Scalars['Float']>;
  userAccountId?: Maybe<Scalars['Float']>;
  profileAddressId?: Maybe<Scalars['Float']>;
  ProfileAddress?: Maybe<ProfileAddress>;
  subCategorieId?: Maybe<Scalars['Float']>;
  ProductSubCategories?: Maybe<ProductSubCategories>;
  categorieId?: Maybe<Scalars['Float']>;
  ProductCategories?: Maybe<ProductCategories>;
  UserAccount: UserAccount;
  Plan?: Maybe<Plans>;
  productBids?: Maybe<Array<ProductBid>>;
  ProductsBlackList?: Maybe<ProductsBlackList>;
  purchase?: Maybe<Array<Purchase>>;
  ProductVariations?: Maybe<Array<ProductVariations>>;
  ProductQuestions?: Maybe<Array<ProductQuestions>>;
  ProductsRating?: Maybe<Array<ProductsRating>>;
  UserBargains?: Maybe<Array<UserBargains>>;
  PropertyInformations?: Maybe<PropertyInformations>;
  VehicleInformations?: Maybe<VehicleInformations>;
  deadline?: Maybe<Scalars['Float']>;
  freeDelivery?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dataInicioDeadline?: Maybe<Scalars['DateTime']>;
  blingProductId?: Maybe<Scalars['String']>;
};

export type ProductBid = {
   __typename?: 'ProductBid';
  id?: Maybe<Scalars['Float']>;
  bid: Scalars['Float'];
  userAccountId?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  UserAccount?: Maybe<UserAccount>;
  productId: Scalars['Float'];
  Product?: Maybe<Product>;
};

export type ProductCart = {
  productId?: Maybe<Scalars['Float']>;
  quantity: Scalars['Float'];
  address: Scalars['Float'];
  price: Scalars['Float'];
  productShippingPrice?: Maybe<Scalars['Float']>;
  installments: Scalars['Float'];
  variacao?: Maybe<Scalars['Float']>;
  shippingWay: Scalars['String'];
  serviceId?: Maybe<Scalars['Float']>;
};

export type ProductCartBuyInput = {
  numInstallments?: Maybe<Scalars['Float']>;
  productShippingPrice?: Maybe<Scalars['Float']>;
  productShippingWay?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  productId: Scalars['Float'];
};

export type ProductCategories = {
   __typename?: 'ProductCategories';
  id: Scalars['Float'];
  name: Scalars['String'];
  split?: Maybe<Scalars['Float']>;
  ProductSubCategories?: Maybe<Array<ProductSubCategories>>;
};

export type ProductInput = {
  title: Scalars['String'];
  isSocialProject: Scalars['Boolean'];
  numberOfPhotos: Scalars['Float'];
  propertyInformation: Scalars['Boolean'];
  profileAddressId?: Maybe<Scalars['Float']>;
  vehicleInformation: Scalars['Boolean'];
  subtitle?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  withdraw?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  typeNegotiation: Scalars['String'];
  InitialValue?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  directValue?: Maybe<Scalars['Float']>;
  finalOffersTime?: Maybe<Scalars['DateTime']>;
  quantity?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Float']>;
  categorieId?: Maybe<Scalars['Float']>;
  subCategorieId?: Maybe<Scalars['Float']>;
  description: Scalars['String'];
  planId?: Maybe<Scalars['Float']>;
  brand?: Maybe<Scalars['String']>;
  useState: Scalars['String'];
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  thickness?: Maybe<Scalars['Float']>;
  material?: Maybe<Scalars['String']>;
  maxInstallments?: Maybe<Scalars['Float']>;
  userAccountId: Scalars['Float'];
  shippingWay?: Maybe<Scalars['String']>;
  cep?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  properyType?: Maybe<Scalars['String']>;
  rooms?: Maybe<Scalars['String']>;
  bathrooms?: Maybe<Scalars['String']>;
  garage?: Maybe<Scalars['String']>;
  vehicleBrand?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
  motorPower?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['String']>;
  fuel?: Maybe<Scalars['String']>;
  licensePlate?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  numberDoors?: Maybe<Scalars['String']>;
  directionType?: Maybe<Scalars['String']>;
  exchange?: Maybe<Scalars['String']>;
  product_height?: Maybe<Scalars['Float']>;
  product_width?: Maybe<Scalars['Float']>;
  product_length?: Maybe<Scalars['Float']>;
  product_weight?: Maybe<Scalars['Float']>;
  prompt_delivery?: Maybe<Scalars['Boolean']>;
  parcelasRestantes?: Maybe<Scalars['Float']>;
  valorRestantes?: Maybe<Scalars['Float']>;
  mesesDeContrato?: Maybe<Scalars['Float']>;
  quitado?: Maybe<Scalars['Boolean']>;
  freeDelivery?: Maybe<Scalars['Boolean']>;
  variacoes?: Maybe<Array<Variacoes>>;
  deadline?: Maybe<Scalars['Float']>;
};

export type ProductQuestions = {
   __typename?: 'ProductQuestions';
  id: Scalars['Float'];
  commentDescription: Scalars['String'];
  repeated?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  userAccountId?: Maybe<Scalars['Float']>;
  UserAccount: UserAccount;
  productId?: Maybe<Scalars['Float']>;
  Product: Product;
  ProductQuestionsAnswers?: Maybe<Array<ProductQuestionsAnswers>>;
};

export type ProductQuestionsAnswers = {
   __typename?: 'ProductQuestionsAnswers';
  id: Scalars['Float'];
  description: Scalars['String'];
  createdAt: Scalars['DateTime'];
  userAccountId?: Maybe<Scalars['Float']>;
  UserAccount: UserAccount;
  productQuestionId?: Maybe<Scalars['Float']>;
  ProductQuestions: ProductQuestions;
};

export type ProductRatingInput = {
  userId: Scalars['Float'];
  productId: Scalars['Float'];
  rating?: Maybe<Scalars['Float']>;
  ratingDescription?: Maybe<Scalars['String']>;
  sellerId: Scalars['Float'];
};

export type Products = {
   __typename?: 'Products';
  id: Scalars['String'];
  quantity: Scalars['Float'];
};

export type ProductsBlackList = {
   __typename?: 'ProductsBlackList';
  id: Scalars['Float'];
  blockReason?: Maybe<Scalars['String']>;
  Product?: Maybe<Product>;
  productId?: Maybe<Scalars['Float']>;
};

export type ProductsRating = {
   __typename?: 'ProductsRating';
  id: Scalars['Float'];
  rating?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  ratingDescription?: Maybe<Scalars['String']>;
  userAccountId?: Maybe<Scalars['Float']>;
  UserAccount?: Maybe<UserAccount>;
  productId?: Maybe<Scalars['Float']>;
  sellerId?: Maybe<Scalars['Float']>;
  Product?: Maybe<Product>;
};

export type ProductSubCategories = {
   __typename?: 'ProductSubCategories';
  id: Scalars['Float'];
  name: Scalars['String'];
  categorieId?: Maybe<Scalars['Float']>;
  ProductCategories: ProductCategories;
};

export type ProductUpdateInput = {
  id?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  numberOfPhotos?: Maybe<Scalars['Float']>;
  propertyInformation: Scalars['Boolean'];
  profileAddressId?: Maybe<Scalars['Float']>;
  vehicleInformation: Scalars['Boolean'];
  subtitle?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  typeNegotiation?: Maybe<Scalars['String']>;
  withdraw?: Maybe<Scalars['String']>;
  InitialValue?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Float']>;
  maxInstallments?: Maybe<Scalars['Float']>;
  categorieId?: Maybe<Scalars['Float']>;
  subCategorieId?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['Float']>;
  brand?: Maybe<Scalars['String']>;
  useState?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  thickness?: Maybe<Scalars['Float']>;
  material?: Maybe<Scalars['String']>;
  shippingWay?: Maybe<Scalars['String']>;
  directValue?: Maybe<Scalars['Float']>;
  finalOffersTime?: Maybe<Scalars['DateTime']>;
  cep?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  properyType?: Maybe<Scalars['String']>;
  rooms?: Maybe<Scalars['String']>;
  bathrooms?: Maybe<Scalars['String']>;
  garage?: Maybe<Scalars['String']>;
  vehicleBrand?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
  motorPower?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['String']>;
  fuel?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  licensePlate?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  numberDoors?: Maybe<Scalars['String']>;
  directionType?: Maybe<Scalars['String']>;
  exchange?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  imagesForRemove: Array<Scalars['Float']>;
  product_height?: Maybe<Scalars['Float']>;
  product_width?: Maybe<Scalars['Float']>;
  product_length?: Maybe<Scalars['Float']>;
  product_weight?: Maybe<Scalars['Float']>;
  prompt_delivery?: Maybe<Scalars['Boolean']>;
  deadline?: Maybe<Scalars['Float']>;
  freeDelivery?: Maybe<Scalars['Boolean']>;
  files?: Maybe<Array<Scalars['Upload']>>;
};

export type ProductVariation = {
  cor?: Maybe<Scalars['String']>;
  quantidade?: Maybe<Scalars['Float']>;
  tamanho?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['Float']>;
};

export type ProductVariations = {
   __typename?: 'ProductVariations';
  id: Scalars['Float'];
  cor?: Maybe<Scalars['String']>;
  quantidade?: Maybe<Scalars['Float']>;
  tamanho?: Maybe<Scalars['String']>;
  productId: Scalars['Float'];
  colorVariationId: Scalars['Float'];
  tamanhoVariationId: Scalars['Float'];
  ColorVariation?: Maybe<ColorVariations>;
  TamanhoVariations?: Maybe<TamanhoVariations>;
};

export type ProdutoLoja = {
   __typename?: 'ProdutoLoja';
  idProdutoLoja: Scalars['String'];
  preco: Scalars['Float'];
  precoPromocional: Scalars['String'];
  dataInclusao: Scalars['String'];
  dataAlteracao: Scalars['String'];
};

export type ProfileAddress = {
   __typename?: 'ProfileAddress';
  id: Scalars['Float'];
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  addressTitle?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  deliveryAddress?: Maybe<Scalars['Boolean']>;
  CEP?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  references?: Maybe<Scalars['String']>;
  userAccountId?: Maybe<Scalars['Float']>;
  Purchase?: Maybe<Array<Purchase>>;
  UserAccount?: Maybe<UserAccount>;
};

export type ProfileContacts = {
   __typename?: 'ProfileContacts';
  id: Scalars['Float'];
  phone?: Maybe<Scalars['String']>;
  userAccountId?: Maybe<Scalars['Float']>;
  UserAccount?: Maybe<UserAccount>;
};

export type PropertyInformations = {
   __typename?: 'PropertyInformations';
  id: Scalars['Float'];
  cep: Scalars['String'];
  street?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  rooms?: Maybe<Scalars['String']>;
  bathrooms: Scalars['String'];
  garage: Scalars['String'];
  properyType?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['Float']>;
  parcelasRestantes?: Maybe<Scalars['Float']>;
  valorRestante?: Maybe<Scalars['Float']>;
  mesesDeContrato?: Maybe<Scalars['Float']>;
  quitado: Scalars['Boolean'];
  neighborhood: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  complement: Scalars['String'];
};

export type Purchase = {
   __typename?: 'Purchase';
  id: Scalars['Float'];
  price?: Maybe<Scalars['String']>;
  pagarmeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentMethod?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['Float']>;
  productVariationId?: Maybe<Scalars['Float']>;
  productQuantity?: Maybe<Scalars['String']>;
  productShippingPrice?: Maybe<Scalars['String']>;
  productShippingWay?: Maybe<Scalars['String']>;
  refuseReason?: Maybe<Scalars['String']>;
  profileAddressId?: Maybe<Scalars['Float']>;
  ProfileAddress?: Maybe<ProfileAddress>;
  ticketId?: Maybe<Scalars['Float']>;
  Tickets?: Maybe<Tickets>;
  cardId?: Maybe<Scalars['String']>;
  statusId?: Maybe<Scalars['Float']>;
  Status?: Maybe<PurchaseStatus>;
  UserCard?: Maybe<UserCard>;
  installmentsNumber?: Maybe<Scalars['Float']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  userAccountId?: Maybe<Scalars['Float']>;
  UserAccount?: Maybe<UserAccount>;
  productId?: Maybe<Scalars['Float']>;
  Product?: Maybe<Product>;
  ChatHub?: Maybe<ChatHub>;
  PurchaseInvoice?: Maybe<PurchaseInvoice>;
  BlingNfscId?: Maybe<Scalars['Float']>;
  Bling_Nfcs?: Maybe<Bling_Nfcs>;
  n_totalProduto?: Maybe<Scalars['String']>;
  boletoUrl?: Maybe<Scalars['String']>;
  boletoCode?: Maybe<Scalars['String']>;
  trackingCode?: Maybe<Scalars['String']>;
  ProductVariations?: Maybe<ProductVariations>;
};

export type PurchaseFiltersInput = {
  sellerId: Scalars['Float'];
  order?: Maybe<Scalars['String']>;
  categories: Array<Scalars['String']>;
  typeNegotiation: Array<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type PurchaseInCartInput = {
  productsCard: Array<ProductCart>;
  userId: Scalars['Float'];
  cardId?: Maybe<Scalars['String']>;
  formOfPayment: Scalars['String'];
  installments?: Maybe<Scalars['Float']>;
};

export type PurchaseInput = {
  userId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  transactionValue?: Maybe<Scalars['String']>;
  numInstallments?: Maybe<Scalars['Float']>;
  quantity: Scalars['Float'];
  payment_method: Scalars['String'];
  cardId?: Maybe<Scalars['String']>;
  productShippingPrice?: Maybe<Scalars['Float']>;
  addressId?: Maybe<Scalars['Float']>;
};

export type PurchaseInvoice = {
   __typename?: 'PurchaseInvoice';
  id: Scalars['Float'];
  name: Scalars['String'];
  path: Scalars['String'];
  purchaseId: Scalars['Float'];
};

export type PurchaseStatus = {
   __typename?: 'PurchaseStatus';
  id: Scalars['Float'];
  engName?: Maybe<Scalars['String']>;
  ptName: Scalars['String'];
};

export type PutTrackingCodeInputData = {
  purchaseId: Scalars['Float'];
  trackingCode: Scalars['String'];
};

export type Query = {
   __typename?: 'Query';
  getBlingAccount?: Maybe<Bling>;
  getAllBlingProducts: Array<BlingProduct>;
  getChatHub?: Maybe<ChatHub>;
  getAllChatHubs: Array<Purchase>;
  getAllCuponsbyUserId: Array<Cupons>;
  getAllCupomProductsByCupomId: Array<CupomProducts>;
  getCupom: Cupons;
  aplicarCuponDesconto: Cupons;
  getDinamicSlider: DinamicSliders;
  getAllGroundsForReport: Array<GroundsForReports>;
  getBiggerProductBid: Array<ProductBid>;
  getAllProductQuestionAnswers: Array<ProductQuestionsAnswers>;
  getAllProductQuestions: Array<ProductQuestions>;
  getAllProductRatings: Array<ProductsRating>;
  getAllUserRatings: Array<ProductsRating>;
  getAllProducts: Array<Product>;
  getProduct: Product;
  getProductByUserId: Array<Product>;
  getProductByUserIdCsv: Scalars['String'];
  getAllProductBid: Array<ProductBid>;
  getProductBySearch: Array<Product>;
  getProductsByCategory: Array<Product>;
  getAllUserViewsProductByProductId: Scalars['Float'];
  getAllSocialProjects: Array<Product>;
  getAllProductCategories: Array<ProductCategories>;
  getProductCategorieBySearch: Array<ProductCategories>;
  verifyProductsInList: ProductsBlackList;
  getAllProductSubCategories: Array<ProductSubCategories>;
  getAllSubCategoriesByCategory: Array<ProductSubCategories>;
  getTransaction: Scalars['String'];
  getQrCodePix: Scalars['String'];
  getProductsSoldByPeriod: Array<GetProductsSoldByPeriodOutput>;
  getAllUserPurchases: Array<Purchase>;
  getAllPurchases: Array<Purchase>;
  getPurchase: Purchase;
  getAllUserPurchasesInOneProduct: Scalars['Boolean'];
  getAccount: BankAccount;
  getAllUserSells: Array<Purchase>;
  getAllSells: Array<Purchase>;
  purchaseFilters: Array<Purchase>;
  getPurchasesFiltersCsv: Scalars['String'];
  getPurchaseSlip: SlipData;
  getAllPurchaseStatus: Array<PurchaseStatus>;
  getPurchaseStatusByPurchaseId: Scalars['String'];
  getAllReports: Array<Reports>;
  calculateShippingValue: Array<ResponseShippingCalculate>;
  searchTicket: Scalars['String'];
  track: TrackResponse;
  getServiceByServiceId: GetServiceResponse;
  searchTicketById: Scalars['Boolean'];
  listMelhorEnvioAgencys: Scalars['Boolean'];
  generateAndPrintTickets: TicketGenerateReturn;
  checkUserBalance: CheckBalanceResponse;
  insertUserBalance: Scalars['String'];
  getShippingsByUserId: Array<Shipping>;
  getShippingsByQuote: Array<Shipping>;
  getShippingsAsCsv: Scalars['String'];
  getAllStandardShopBanners: Banners;
  getCard: Card;
  getAllCards: Array<Card>;
  getAllCardsId: UserCard;
  validateConection: Scalars['Boolean'];
  getAllUserAccounts: Array<UserAccount>;
  getAllAddress: Array<ProfileAddress>;
  getUserAccount: UserAccount;
  userAccountFilter: Array<UserAccount>;
  getBlockStatus: Scalars['Boolean'];
  getMelhorEnvioToken: MelhorEnvioTokenReturn;
  getUserHasShippingService: Scalars['Boolean'];
  getUserProductBargain: Scalars['Boolean'];
  getUserCustomization?: Maybe<UserCustomization>;
  getAvailableCorreiosServices: Scalars['Boolean'];
  getStatusCartaoPostagem: Scalars['Boolean'];
  getAllVariationProduct: Array<VariationProduct>;
  getAllProductVariationByProductId: Array<ProductVariations>;
};


export type QueryGetBlingAccountArgs = {
  getBlingAccountInputData: GetBlingAccountInputData;
};


export type QueryGetAllBlingProductsArgs = {
  userId: Scalars['Float'];
};


export type QueryGetChatHubArgs = {
  productId: Scalars['Float'];
  userId: Scalars['Float'];
};


export type QueryGetAllChatHubsArgs = {
  productId: Scalars['Float'];
};


export type QueryGetAllCuponsbyUserIdArgs = {
  getAllCupomByUserIdInputData: GetAllCupomByUserIdInputData;
};


export type QueryGetAllCupomProductsByCupomIdArgs = {
  getAllCupomProductsByCupomIdInputData: GetAllCupomProductsByCupomIdInputData;
};


export type QueryGetCupomArgs = {
  getCupom: GetCupomInputData;
};


export type QueryAplicarCuponDescontoArgs = {
  aplicarDescontoInputData: AplicarDescontoInputData;
};


export type QueryGetDinamicSliderArgs = {
  getDinamicSliderInput: GetDinamicSliderInput;
};


export type QueryGetBiggerProductBidArgs = {
  productId: Scalars['Float'];
};


export type QueryGetAllProductQuestionAnswersArgs = {
  productQuestionId: Scalars['Float'];
};


export type QueryGetAllProductQuestionsArgs = {
  productId: Scalars['Float'];
};


export type QueryGetAllProductRatingsArgs = {
  productId: Scalars['Float'];
};


export type QueryGetAllUserRatingsArgs = {
  userSalerId: Scalars['Float'];
};


export type QueryGetAllProductsArgs = {
  getAllProductInput: GetAllProductInput;
};


export type QueryGetProductArgs = {
  getProductInput: GetProductInput;
};


export type QueryGetProductByUserIdArgs = {
  getProductByUserId: GetProductByUserId;
};


export type QueryGetProductByUserIdCsvArgs = {
  getProductByUserId: GetProductByUserId;
};


export type QueryGetAllProductBidArgs = {
  productId: Scalars['Float'];
};


export type QueryGetProductBySearchArgs = {
  title: Scalars['String'];
};


export type QueryGetProductsByCategoryArgs = {
  categoryId?: Maybe<Scalars['Float']>;
  category?: Maybe<Scalars['String']>;
};


export type QueryGetAllUserViewsProductByProductIdArgs = {
  productId: Scalars['String'];
};


export type QueryGetProductCategorieBySearchArgs = {
  name: Scalars['String'];
};


export type QueryVerifyProductsInListArgs = {
  productId: Scalars['Float'];
};


export type QueryGetAllProductSubCategoriesArgs = {
  categorieId: Scalars['Float'];
};


export type QueryGetAllSubCategoriesByCategoryArgs = {
  categoryId: Scalars['Float'];
};


export type QueryGetTransactionArgs = {
  purchaseId: Scalars['Float'];
};


export type QueryGetQrCodePixArgs = {
  purchaseId: Scalars['Float'];
};


export type QueryGetProductsSoldByPeriodArgs = {
  userId: Scalars['Float'];
  period: Scalars['Float'];
};


export type QueryGetAllUserPurchasesArgs = {
  userId: Scalars['Float'];
};


export type QueryGetPurchaseArgs = {
  id: Scalars['Float'];
};


export type QueryGetAllUserPurchasesInOneProductArgs = {
  productId: Scalars['Float'];
  userId?: Maybe<Scalars['Float']>;
};


export type QueryGetAccountArgs = {
  userId: Scalars['Float'];
};


export type QueryGetAllUserSellsArgs = {
  sellerId: Scalars['Float'];
};


export type QueryGetAllSellsArgs = {
  sellerId: Scalars['Float'];
};


export type QueryPurchaseFiltersArgs = {
  purchaseFiltersInput: PurchaseFiltersInput;
};


export type QueryGetPurchasesFiltersCsvArgs = {
  purchaseFiltersInput: PurchaseFiltersInput;
};


export type QueryGetPurchaseSlipArgs = {
  purchaseId: Scalars['Float'];
};


export type QueryGetPurchaseStatusByPurchaseIdArgs = {
  getPurchaseStatusByPurchaseIdInputData: GetPurchaseStatusByPurchaseIdInputData;
};


export type QueryCalculateShippingValueArgs = {
  productId: Scalars['Float'];
  userId: Scalars['Float'];
  infos: RequestShippingCalculate;
};


export type QuerySearchTicketArgs = {
  purchaseId: Scalars['Float'];
  userId: Scalars['Float'];
};


export type QueryTrackArgs = {
  ticketId: Scalars['String'];
  userId: Scalars['Float'];
};


export type QueryGetServiceByServiceIdArgs = {
  serviceId: Scalars['String'];
};


export type QuerySearchTicketByIdArgs = {
  ticketId: Scalars['String'];
  userId: Scalars['Float'];
};


export type QueryListMelhorEnvioAgencysArgs = {
  city: Scalars['String'];
  state: Scalars['String'];
};


export type QueryGenerateAndPrintTicketsArgs = {
  userId: Scalars['Float'];
  ticketId: Scalars['Float'];
};


export type QueryCheckUserBalanceArgs = {
  userId: Scalars['Float'];
};


export type QueryInsertUserBalanceArgs = {
  infos: InsertBalanceRequest;
  userId: Scalars['Float'];
};


export type QueryGetShippingsByUserIdArgs = {
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  userId: Scalars['Float'];
};


export type QueryGetShippingsByQuoteArgs = {
  postalCode: Scalars['Float'];
  productId: Scalars['Float'];
};


export type QueryGetShippingsAsCsvArgs = {
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  userId: Scalars['Float'];
};


export type QueryGetAllStandardShopBannersArgs = {
  userId: Scalars['Float'];
};


export type QueryGetCardArgs = {
  cardId: Scalars['String'];
};


export type QueryGetAllCardsArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  userId: Scalars['Float'];
};


export type QueryGetAllCardsIdArgs = {
  userId: Scalars['Float'];
};


export type QueryValidateConectionArgs = {
  userId: Scalars['Float'];
};


export type QueryGetAllAddressArgs = {
  userId: Scalars['Float'];
};


export type QueryGetUserAccountArgs = {
  userAccountId: Scalars['String'];
};


export type QueryUserAccountFilterArgs = {
  userAccountFilterInput: UserAccountFilterInput;
};


export type QueryGetBlockStatusArgs = {
  userId: Scalars['Float'];
};


export type QueryGetMelhorEnvioTokenArgs = {
  userId: Scalars['Float'];
};


export type QueryGetUserHasShippingServiceArgs = {
  userId: Scalars['Float'];
};


export type QueryGetUserProductBargainArgs = {
  productId: Scalars['Float'];
  userId?: Maybe<Scalars['Float']>;
};


export type QueryGetUserCustomizationArgs = {
  userId: Scalars['Float'];
};


export type QueryGetAllVariationProductArgs = {
  getAllVariationProductInput: GetAllVariationProductInput;
};


export type QueryGetAllProductVariationByProductIdArgs = {
  productId: Scalars['Float'];
};

export type RecipientInput = {
  userId?: Maybe<Scalars['Float']>;
  bank_code?: Maybe<Scalars['String']>;
  agencia?: Maybe<Scalars['String']>;
  conta: Scalars['String'];
  cpf: Scalars['String'];
  legal_name: Scalars['String'];
  conta_dv: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type RefreshTokenRequest = {
  token_type: Scalars['String'];
  expires_in: Scalars['String'];
  access_token: Scalars['String'];
  refresh_token: Scalars['String'];
};

export type Reports = {
   __typename?: 'Reports';
  id: Scalars['Float'];
  productId?: Maybe<Scalars['Float']>;
  Product?: Maybe<Product>;
  groundForReportId?: Maybe<Scalars['Float']>;
  GroundsForReport?: Maybe<GroundsForReports>;
  userAccountId?: Maybe<Scalars['Float']>;
  UserAccount?: Maybe<UserAccount>;
};

export type RequestCard = {
  cpf: Scalars['String'];
  card_number: Scalars['String'];
  card_holder_name: Scalars['String'];
  card_expiration_date: Scalars['String'];
  card_cvv: Scalars['String'];
};

export type RequestShippingCalculate = {
  from: From;
  to: From;
  products: Array<PackageClass>;
};

export type ResponseShippingCalculate = {
   __typename?: 'ResponseShippingCalculate';
  additional_services?: Maybe<Additional_Services>;
  company?: Maybe<Company>;
  custom_delivery_range?: Maybe<Custom_Delivery_Range>;
  custom_delivery_time?: Maybe<Scalars['Float']>;
  custom_price?: Maybe<Scalars['String']>;
  delivery_range?: Maybe<Delivery_Range>;
  delivery_time?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  error?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  productId: Scalars['Float'];
};

export type Shipping = {
   __typename?: 'Shipping';
  id: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  carrier?: Maybe<Scalars['String']>;
  startPostalCode: Scalars['Float'];
  endPostalCode: Scalars['Float'];
  minimumWeight: Scalars['Float'];
  maximumWeight: Scalars['Float'];
  longestLength: Scalars['Float'];
  price: Scalars['Float'];
  deliveryTime: Scalars['Float'];
  carrierLogo?: Maybe<Scalars['String']>;
  userAccountId: Scalars['Float'];
};

export type ShippingInput = {
  id?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  carrier?: Maybe<Scalars['String']>;
  startPostalCode: Scalars['Float'];
  endPostalCode: Scalars['Float'];
  minimumWeight: Scalars['Float'];
  maximumWeight: Scalars['Float'];
  longestLength: Scalars['Float'];
  price: Scalars['Float'];
  deliveryTime: Scalars['Float'];
  carrierLogo?: Maybe<Scalars['String']>;
};

export type ShopBanners = {
   __typename?: 'ShopBanners';
  id: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
  dataType?: Maybe<Scalars['String']>;
  userAccountId?: Maybe<Scalars['Float']>;
  UserAccount?: Maybe<UserAccount>;
};

export type ShopBrands = {
   __typename?: 'ShopBrands';
  id: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
  dataType?: Maybe<Scalars['String']>;
  userAccountId?: Maybe<Scalars['Float']>;
  UserAccount?: Maybe<UserAccount>;
};

export type SlipData = {
   __typename?: 'SlipData';
  slip_url: Scalars['String'];
  slip_barcode: Scalars['String'];
};

export type TamanhoVariations = {
   __typename?: 'TamanhoVariations';
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type TicketFrom = {
  name: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
  document: Scalars['String'];
  company_document: Scalars['String'];
  address: Scalars['String'];
  complement: Scalars['String'];
  number: Scalars['String'];
  district: Scalars['String'];
  city: Scalars['String'];
  country_id: Scalars['String'];
  postal_code: Scalars['String'];
  note?: Maybe<Scalars['String']>;
};

export type TicketGenerateReturn = {
   __typename?: 'TicketGenerateReturn';
  isPaid: Scalars['Boolean'];
  url: Scalars['String'];
};

export type TicketOptions = {
  insurance_value: Scalars['Float'];
  receipt: Scalars['Boolean'];
  own_hand: Scalars['Boolean'];
  reverse: Scalars['Boolean'];
  non_commercial: Scalars['Boolean'];
  platform: Scalars['String'];
  tags: Array<TicketTags>;
  invoice: Invoice;
};

export type TicketProducts = {
  name: Scalars['String'];
  quantity: Scalars['Float'];
  unitary_value: Scalars['Float'];
};

export type Tickets = {
   __typename?: 'Tickets';
  id: Scalars['Float'];
  url?: Maybe<Scalars['String']>;
  ticketId?: Maybe<Scalars['String']>;
  codigoRastreio?: Maybe<Scalars['String']>;
  serviceName?: Maybe<Scalars['String']>;
};

export type TicketTags = {
  tag: Scalars['Float'];
  url?: Maybe<Scalars['String']>;
};

export type TicketTo = {
  name: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
  document: Scalars['String'];
  address: Scalars['String'];
  complement: Scalars['String'];
  number: Scalars['String'];
  district: Scalars['String'];
  city: Scalars['String'];
  country_id: Scalars['String'];
  postal_code: Scalars['String'];
  state_abbr: Scalars['String'];
  note?: Maybe<Scalars['String']>;
};

export type TicketVolumes = {
  height: Scalars['Float'];
  width: Scalars['Float'];
  length: Scalars['Float'];
  weight: Scalars['Float'];
};

export type TrackResponse = {
   __typename?: 'TrackResponse';
  id: Scalars['String'];
  protocol: Scalars['String'];
  status: Scalars['String'];
  tracking: Scalars['String'];
  melhorenvio_tracking: Scalars['String'];
  created_at: Scalars['String'];
  paid_at?: Maybe<Scalars['String']>;
  generated_at?: Maybe<Scalars['String']>;
  posted_at?: Maybe<Scalars['String']>;
  delivered_at?: Maybe<Scalars['String']>;
  canceled_at?: Maybe<Scalars['String']>;
  expired_at?: Maybe<Scalars['String']>;
};

export type UpdateDinamicSliderInput = {
  dinamicId: Scalars['Float'];
  title?: Maybe<Scalars['String']>;
  dateStart?: Maybe<Scalars['DateTime']>;
  dateEnd?: Maybe<Scalars['DateTime']>;
};

export type UpdateDinamicSliderItemsInput = {
  dinamicId: Scalars['Float'];
  productsId: Array<Scalars['Float']>;
};

export type UpdateProductVariationInputData = {
  id: Scalars['Float'];
  cor?: Maybe<Scalars['String']>;
  tamanho?: Maybe<Scalars['String']>;
  quantidade: Scalars['Float'];
};


export type UserAccount = {
   __typename?: 'UserAccount';
  id: Scalars['Float'];
  name: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  recipientId?: Maybe<Scalars['String']>;
  bankAccountId?: Maybe<Scalars['String']>;
  block?: Maybe<Scalars['Boolean']>;
  dateBirth?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  RG?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  password?: Maybe<Scalars['String']>;
  cardId?: Maybe<Scalars['String']>;
  ratting?: Maybe<Scalars['Float']>;
  melhorEnvioToken?: Maybe<Scalars['String']>;
  melhorEnvioRefreshToken?: Maybe<Scalars['String']>;
  userTypeId?: Maybe<Scalars['Float']>;
  active?: Maybe<Scalars['Boolean']>;
  activation_token?: Maybe<Scalars['String']>;
  UserType?: Maybe<UserType>;
  ProfileAddress?: Maybe<Array<ProfileAddress>>;
  ProfileContacts?: Maybe<Array<ProfileContacts>>;
  product?: Maybe<Array<Product>>;
  userNotifications?: Maybe<Array<UserNotifications>>;
  Purchase?: Maybe<Array<Purchase>>;
  ChatHubMessages?: Maybe<Array<ChatHubMessages>>;
  ProductsRating?: Maybe<Array<ProductsRating>>;
  UserBargains?: Maybe<Array<UserBargains>>;
};

export type UserAccountFilterInput = {
  includeSearch: Scalars['Boolean'];
  searchValue?: Maybe<Scalars['String']>;
  name: Array<Scalars['String']>;
};

export type UserAccountInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  name: Scalars['String'];
  cpf: Scalars['String'];
  userTypeId: Scalars['Float'];
  ratting?: Maybe<Scalars['Float']>;
};

export type UserAccountUpdateAddressInput = {
  addressTitle?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  CEP?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  references?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
};

export type UserAccountUpdateInput = {
  email?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  ratting?: Maybe<Scalars['Float']>;
};

export type UserBargains = {
   __typename?: 'UserBargains';
  id: Scalars['Float'];
  bargain?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  userAccountId?: Maybe<Scalars['Float']>;
  UserAccount?: Maybe<UserAccount>;
  productId?: Maybe<Scalars['Float']>;
  Product?: Maybe<Product>;
};

export type UserCard = {
   __typename?: 'UserCard';
  id: Scalars['Float'];
  cardId?: Maybe<Scalars['String']>;
  userAccountId?: Maybe<Scalars['Float']>;
  UserAccount?: Maybe<UserAccount>;
};

export type UserCustomization = {
   __typename?: 'UserCustomization';
  id: Scalars['Float'];
  brand?: Maybe<Scalars['String']>;
  ShopName?: Maybe<Scalars['String']>;
  bannerTextColor?: Maybe<Scalars['String']>;
  brandColor?: Maybe<Scalars['String']>;
  background?: Maybe<Scalars['String']>;
  hasBackground?: Maybe<Scalars['Boolean']>;
  hasBanner?: Maybe<Scalars['Boolean']>;
  userAccountId?: Maybe<Scalars['Float']>;
  UserAccount?: Maybe<UserAccount>;
};

export type UserNotifications = {
   __typename?: 'UserNotifications';
  id: Scalars['Float'];
  notification?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  userAccountId?: Maybe<Scalars['Float']>;
  UserAccount: UserAccount;
  createdAt: Scalars['DateTime'];
};

export type UserType = {
   __typename?: 'UserType';
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type UserViewsProduct = {
   __typename?: 'UserViewsProduct';
  /** Tabela referente ao numero de acesso que o produto tem */
  id: Scalars['Float'];
  codeUser: Scalars['String'];
  productId: Scalars['Float'];
};

export type Variacoes = {
  cor?: Maybe<Scalars['String']>;
  quantidade?: Maybe<Scalars['Float']>;
  tamanho?: Maybe<Scalars['String']>;
};

export type VariationProduct = {
   __typename?: 'VariationProduct';
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type VehicleInformations = {
   __typename?: 'VehicleInformations';
  id: Scalars['Float'];
  vehicleBrand: Scalars['String'];
  model?: Maybe<Scalars['String']>;
  year: Scalars['String'];
  motorPower?: Maybe<Scalars['String']>;
  mileage: Scalars['String'];
  fuel: Scalars['String'];
  licensePlate?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  numberDoors?: Maybe<Scalars['String']>;
  directionType: Scalars['String'];
  exchange?: Maybe<Scalars['String']>;
  parcelasRestantes?: Maybe<Scalars['Float']>;
  valorRestante?: Maybe<Scalars['Float']>;
  mesesDeContrato?: Maybe<Scalars['Float']>;
  quitado: Scalars['Boolean'];
  productId?: Maybe<Scalars['Float']>;
};

export type GetChatHubQueryVariables = {
  userId: Scalars['Float'];
  productId: Scalars['Float'];
};


export type GetChatHubQuery = (
  { __typename?: 'Query' }
  & { getChatHub?: Maybe<(
    { __typename?: 'ChatHub' }
    & Pick<ChatHub, 'id' | 'title' | 'purchaseId'>
  )> }
);

export type GetAllChatHubsQueryVariables = {
  productId: Scalars['Float'];
};


export type GetAllChatHubsQuery = (
  { __typename?: 'Query' }
  & { getAllChatHubs: Array<(
    { __typename?: 'Purchase' }
    & Pick<Purchase, 'id'>
    & { UserAccount?: Maybe<(
      { __typename?: 'UserAccount' }
      & Pick<UserAccount, 'id' | 'name' | 'email'>
    )>, ChatHub?: Maybe<(
      { __typename?: 'ChatHub' }
      & Pick<ChatHub, 'id' | 'title'>
      & { ChatHubMessages?: Maybe<Array<(
        { __typename?: 'ChatHubMessages' }
        & Pick<ChatHubMessages, 'id' | 'message'>
        & { UserAccount?: Maybe<(
          { __typename?: 'UserAccount' }
          & Pick<UserAccount, 'id' | 'name' | 'email'>
        )> }
      )>> }
    )> }
  )> }
);

export type CreateChatHubForFreeComunicationMutationVariables = {
  productId: Scalars['Float'];
  userId: Scalars['Float'];
};


export type CreateChatHubForFreeComunicationMutation = (
  { __typename?: 'Mutation' }
  & { createChatHubForFreeComunication: (
    { __typename?: 'ChatHub' }
    & Pick<ChatHub, 'id'>
    & { Purchase?: Maybe<(
      { __typename?: 'Purchase' }
      & Pick<Purchase, 'id'>
      & { Product?: Maybe<(
        { __typename?: 'Product' }
        & Pick<Product, 'id'>
      )> }
    )> }
  ) }
);

export type CreateCupomMutationVariables = {
  createCupomInputData: CreateCupomInputData;
};


export type CreateCupomMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createCupom'>
);

export type GetAllCuponsbyUserIdQueryVariables = {
  getAllCuponsbyUserId: GetAllCupomByUserIdInputData;
};


export type GetAllCuponsbyUserIdQuery = (
  { __typename?: 'Query' }
  & { getAllCuponsbyUserId: Array<(
    { __typename?: 'Cupons' }
    & Pick<Cupons, 'id' | 'code' | 'userId' | 'validade' | 'desconto'>
  )> }
);

export type DeleteCupomMutationVariables = {
  deleteCupomInputData: DeleteCupomInputData;
};


export type DeleteCupomMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCupom'>
);

export type GetAllCupomProductsByCupomIdQueryVariables = {
  getAllCupomProductsByCupomId: GetAllCupomProductsByCupomIdInputData;
};


export type GetAllCupomProductsByCupomIdQuery = (
  { __typename?: 'Query' }
  & { getAllCupomProductsByCupomId: Array<(
    { __typename?: 'CupomProducts' }
    & Pick<CupomProducts, 'id' | 'cupomId' | 'productId'>
    & { Product: (
      { __typename?: 'Product' }
      & Pick<Product, 'title' | 'id'>
    ) }
  )> }
);

export type GetCupomQueryVariables = {
  getCupomInputData: GetCupomInputData;
};


export type GetCupomQuery = (
  { __typename?: 'Query' }
  & { getCupom: (
    { __typename?: 'Cupons' }
    & Pick<Cupons, 'id' | 'userId' | 'code' | 'desconto' | 'validade'>
  ) }
);

export type EditCupomMutationVariables = {
  editCupomInputData: EditCupomInputData;
};


export type EditCupomMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'editCupom'>
);

export type AplicarCuponDescontoQueryVariables = {
  aplicarDescontoInputData: AplicarDescontoInputData;
};


export type AplicarCuponDescontoQuery = (
  { __typename?: 'Query' }
  & { aplicarCuponDesconto: (
    { __typename?: 'Cupons' }
    & Pick<Cupons, 'id' | 'desconto'>
  ) }
);

export type CreateDinamicSliderMutationVariables = {
  createDinamicSliderInput: CreateDinamicSliderInput;
};


export type CreateDinamicSliderMutation = (
  { __typename?: 'Mutation' }
  & { createDinamicSlider: (
    { __typename?: 'DinamicSliders' }
    & Pick<DinamicSliders, 'id'>
  ) }
);

export type CreateDinamicSliderItemMutationVariables = {
  CreateDinamicSliderItemInput: CreateDinamicSliderItemInput;
};


export type CreateDinamicSliderItemMutation = (
  { __typename?: 'Mutation' }
  & { createDinamicSliderItem: (
    { __typename?: 'DinamicSliders' }
    & Pick<DinamicSliders, 'title'>
  ) }
);

export type GetDinamicSliderQueryVariables = {
  GetDinamicSliderInput: GetDinamicSliderInput;
};


export type GetDinamicSliderQuery = (
  { __typename?: 'Query' }
  & { getDinamicSlider: (
    { __typename?: 'DinamicSliders' }
    & Pick<DinamicSliders, 'title' | 'dateStart' | 'dateEnd'>
    & { DinamicSlidersItens: Array<(
      { __typename?: 'DinamicSlidersItens' }
      & Pick<DinamicSlidersItens, 'id' | 'productId'>
      & { Product?: Maybe<(
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'title' | 'subtitle' | 'amount' | 'typeNegotiation' | 'InitialValue' | 'price' | 'quantity' | 'stock' | 'categorieId' | 'numberOfPhotos' | 'description' | 'active' | 'isSocialProject' | 'brand' | 'useState' | 'maxInstallments' | 'planId' | 'height' | 'width' | 'thickness' | 'material' | 'shippingWay' | 'deadline' | 'freeDelivery'>
        & { ProductsBlackList?: Maybe<(
          { __typename?: 'ProductsBlackList' }
          & Pick<ProductsBlackList, 'id' | 'productId'>
        )> }
      )> }
    )> }
  ) }
);

export type UpdateDinamicSliderMutationVariables = {
  updateDinamicSliderInput: UpdateDinamicSliderInput;
};


export type UpdateDinamicSliderMutation = (
  { __typename?: 'Mutation' }
  & { updateDinamicSlider: (
    { __typename?: 'DinamicSliders' }
    & Pick<DinamicSliders, 'id' | 'title' | 'dateStart' | 'dateEnd'>
  ) }
);

export type DeleteDinamicSliderItemMutationVariables = {
  productId: Scalars['Float'];
};


export type DeleteDinamicSliderItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDinamicSliderItem'>
);

export type GetAllGroundsForReportQueryVariables = {};


export type GetAllGroundsForReportQuery = (
  { __typename?: 'Query' }
  & { getAllGroundsForReport: Array<(
    { __typename?: 'GroundsForReports' }
    & Pick<GroundsForReports, 'id' | 'title' | 'description'>
  )> }
);

export type CreateProductMutationVariables = {
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  typeNegotiation: Scalars['String'];
  InitialValue?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  quantity: Scalars['Float'];
  stock?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  thickness?: Maybe<Scalars['Float']>;
  description: Scalars['String'];
  brand?: Maybe<Scalars['String']>;
  useState: Scalars['String'];
  numberOfPhotos: Scalars['Float'];
  maxInstallments?: Maybe<Scalars['Float']>;
  material?: Maybe<Scalars['String']>;
  userAccountId: Scalars['Float'];
  shippingWay: Scalars['String'];
  categorieId: Scalars['Float'];
  planId?: Maybe<Scalars['Float']>;
  directValue?: Maybe<Scalars['Float']>;
  finalOffersTime?: Maybe<Scalars['DateTime']>;
  propertyInformation: Scalars['Boolean'];
  vehicleInformation: Scalars['Boolean'];
  cep?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  properyType?: Maybe<Scalars['String']>;
  rooms?: Maybe<Scalars['String']>;
  bathrooms?: Maybe<Scalars['String']>;
  garage?: Maybe<Scalars['String']>;
  vehicleBrand?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
  motorPower?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['String']>;
  fuel?: Maybe<Scalars['String']>;
  licensePlate?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  numberDoors?: Maybe<Scalars['String']>;
  directionType?: Maybe<Scalars['String']>;
  exchange?: Maybe<Scalars['String']>;
  profileAddressId?: Maybe<Scalars['Float']>;
  withdraw?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  subCategorieId?: Maybe<Scalars['Float']>;
  isSocialProject: Scalars['Boolean'];
  product_height?: Maybe<Scalars['Float']>;
  product_width?: Maybe<Scalars['Float']>;
  product_length?: Maybe<Scalars['Float']>;
  product_weight?: Maybe<Scalars['Float']>;
  prompt_delivery?: Maybe<Scalars['Boolean']>;
  parcelasRestantes?: Maybe<Scalars['Float']>;
  valorRestantes?: Maybe<Scalars['Float']>;
  mesesDeContrato?: Maybe<Scalars['Float']>;
  quitado?: Maybe<Scalars['Boolean']>;
  variacoes?: Maybe<Array<Variacoes>>;
  deadline?: Maybe<Scalars['Float']>;
  freeDelivery?: Maybe<Scalars['Boolean']>;
};


export type CreateProductMutation = (
  { __typename?: 'Mutation' }
  & { createProduct: (
    { __typename?: 'Product' }
    & Pick<Product, 'id'>
  ) }
);

export type CreateProductPaidMutationVariables = {
  userId: Scalars['Float'];
  numInstallments: Scalars['Float'];
  payment_method: Scalars['String'];
  cardId: Scalars['String'];
  product: ProductInput;
};


export type CreateProductPaidMutation = (
  { __typename?: 'Mutation' }
  & { createProductPaid: (
    { __typename?: 'UserAccount' }
    & Pick<UserAccount, 'id'>
  ) }
);

export type CreateProductImageMutationVariables = {
  ProductId: Scalars['Float'];
  PictureNumber: Scalars['Float'];
  Picture: Array<Scalars['Upload']>;
};


export type CreateProductImageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createProductImage'>
);

export type AddVisitsProductMutationVariables = {
  codeUserView: Scalars['String'];
  productId: Scalars['Float'];
};


export type AddVisitsProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addVisitsProduct'>
);

export type GetAllProductsQueryVariables = {
  getAllProductInput: GetAllProductInput;
};


export type GetAllProductsQuery = (
  { __typename?: 'Query' }
  & { getAllProducts: Array<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'title' | 'subtitle' | 'amount' | 'typeNegotiation' | 'InitialValue' | 'price' | 'quantity' | 'stock' | 'categorieId' | 'numberOfPhotos' | 'description' | 'active' | 'isSocialProject' | 'brand' | 'useState' | 'maxInstallments' | 'planId' | 'height' | 'width' | 'thickness' | 'material' | 'shippingWay' | 'deadline' | 'freeDelivery'>
    & { ProductsBlackList?: Maybe<(
      { __typename?: 'ProductsBlackList' }
      & Pick<ProductsBlackList, 'id' | 'productId'>
    )>, VehicleInformations?: Maybe<(
      { __typename?: 'VehicleInformations' }
      & Pick<VehicleInformations, 'id' | 'vehicleBrand' | 'year' | 'motorPower' | 'mileage' | 'fuel' | 'directionType' | 'quitado' | 'parcelasRestantes' | 'valorRestante' | 'mesesDeContrato' | 'productId' | 'numberDoors'>
    )>, PropertyInformations?: Maybe<(
      { __typename?: 'PropertyInformations' }
      & Pick<PropertyInformations, 'id' | 'cep' | 'number' | 'bathrooms' | 'garage' | 'productId' | 'quitado' | 'mesesDeContrato' | 'valorRestante' | 'parcelasRestantes' | 'city' | 'neighborhood' | 'state' | 'complement'>
    )>, UserAccount: (
      { __typename?: 'UserAccount' }
      & Pick<UserAccount, 'id' | 'name' | 'userTypeId' | 'email' | 'ratting'>
      & { ProfileContacts?: Maybe<Array<(
        { __typename?: 'ProfileContacts' }
        & Pick<ProfileContacts, 'id' | 'phone'>
      )>>, ProfileAddress?: Maybe<Array<(
        { __typename?: 'ProfileAddress' }
        & Pick<ProfileAddress, 'id' | 'city' | 'CEP' | 'userAccountId' | 'state'>
      )>> }
    ) }
  )> }
);

export type GetProductByUserIdCsvQueryVariables = {
  getProductByUserIdCsv: GetProductByUserId;
};


export type GetProductByUserIdCsvQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getProductByUserIdCsv'>
);

export type GetAllUserViewsProductByProductIdQueryVariables = {
  productId: Scalars['String'];
};


export type GetAllUserViewsProductByProductIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAllUserViewsProductByProductId'>
);

export type GetProductQueryVariables = {
  id: Scalars['Float'];
  userId?: Maybe<Scalars['Float']>;
};


export type GetProductQuery = (
  { __typename?: 'Query' }
  & { getProduct: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'title' | 'subtitle' | 'amount' | 'typeNegotiation' | 'InitialValue' | 'price' | 'quantity' | 'stock' | 'categorieId' | 'subCategorieId' | 'description' | 'planId' | 'numberOfPhotos' | 'brand' | 'active' | 'maxInstallments' | 'deadline' | 'freeDelivery' | 'finalOffersTime' | 'directValue' | 'useState' | 'height' | 'width' | 'weight' | 'withdraw' | 'format' | 'profileAddressId' | 'prompt_delivery' | 'thickness' | 'material' | 'shippingWay' | 'userAccountId' | 'product_height' | 'product_length' | 'product_width' | 'product_weight' | 'createdAt' | 'updatedAt' | 'dataInicioDeadline'>
    & { ProductsBlackList?: Maybe<(
      { __typename?: 'ProductsBlackList' }
      & Pick<ProductsBlackList, 'id'>
    )>, VehicleInformations?: Maybe<(
      { __typename?: 'VehicleInformations' }
      & Pick<VehicleInformations, 'vehicleBrand' | 'model' | 'year' | 'motorPower' | 'mileage' | 'fuel' | 'licensePlate' | 'color' | 'numberDoors' | 'directionType' | 'exchange' | 'productId' | 'valorRestante' | 'quitado' | 'mesesDeContrato' | 'parcelasRestantes'>
    )>, PropertyInformations?: Maybe<(
      { __typename?: 'PropertyInformations' }
      & Pick<PropertyInformations, 'cep' | 'street' | 'number' | 'rooms' | 'bathrooms' | 'garage' | 'properyType' | 'valorRestante' | 'quitado' | 'mesesDeContrato' | 'parcelasRestantes' | 'city' | 'neighborhood' | 'state' | 'complement'>
    )>, ProductCategories?: Maybe<(
      { __typename?: 'ProductCategories' }
      & Pick<ProductCategories, 'id' | 'name'>
    )>, UserAccount: (
      { __typename?: 'UserAccount' }
      & Pick<UserAccount, 'id' | 'name' | 'userTypeId' | 'email' | 'ratting'>
      & { Purchase?: Maybe<Array<(
        { __typename?: 'Purchase' }
        & Pick<Purchase, 'id'>
      )>>, ProfileContacts?: Maybe<Array<(
        { __typename?: 'ProfileContacts' }
        & Pick<ProfileContacts, 'phone'>
      )>> }
    ), ProductQuestions?: Maybe<Array<(
      { __typename?: 'ProductQuestions' }
      & Pick<ProductQuestions, 'id' | 'commentDescription'>
      & { UserAccount: (
        { __typename?: 'UserAccount' }
        & Pick<UserAccount, 'id' | 'name'>
      ) }
    )>>, productBids?: Maybe<Array<(
      { __typename?: 'ProductBid' }
      & Pick<ProductBid, 'id' | 'bid' | 'userAccountId' | 'productId' | 'createdAt' | 'updatedAt'>
      & { UserAccount?: Maybe<(
        { __typename?: 'UserAccount' }
        & Pick<UserAccount, 'id' | 'name' | 'photo' | 'userTypeId' | 'email' | 'password' | 'ratting'>
      )> }
    )>>, UserBargains?: Maybe<Array<(
      { __typename?: 'UserBargains' }
      & Pick<UserBargains, 'id' | 'bargain' | 'userAccountId' | 'productId'>
    )>>, ProfileAddress?: Maybe<(
      { __typename?: 'ProfileAddress' }
      & Pick<ProfileAddress, 'id' | 'CEP'>
    )>, ProductVariations?: Maybe<Array<(
      { __typename?: 'ProductVariations' }
      & Pick<ProductVariations, 'id' | 'quantidade' | 'cor' | 'tamanho'>
      & { ColorVariation?: Maybe<(
        { __typename?: 'ColorVariations' }
        & Pick<ColorVariations, 'id' | 'name'>
      )>, TamanhoVariations?: Maybe<(
        { __typename?: 'TamanhoVariations' }
        & Pick<TamanhoVariations, 'id' | 'name'>
      )> }
    )>> }
  ) }
);

export type GetProductByUserIdQueryVariables = {
  getProductByUserId: GetProductByUserId;
};


export type GetProductByUserIdQuery = (
  { __typename?: 'Query' }
  & { getProductByUserId: Array<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'title' | 'subtitle' | 'amount' | 'typeNegotiation' | 'InitialValue' | 'price' | 'quantity' | 'stock' | 'planId' | 'categorieId' | 'description' | 'maxInstallments' | 'brand' | 'active' | 'useState' | 'height' | 'width' | 'thickness' | 'material' | 'deadline' | 'freeDelivery' | 'shippingWay' | 'userAccountId'>
    & { VehicleInformations?: Maybe<(
      { __typename?: 'VehicleInformations' }
      & Pick<VehicleInformations, 'id' | 'vehicleBrand' | 'model' | 'year' | 'motorPower' | 'mileage' | 'fuel' | 'licensePlate' | 'color' | 'numberDoors' | 'directionType' | 'exchange' | 'productId' | 'valorRestante' | 'quitado' | 'mesesDeContrato' | 'parcelasRestantes'>
    )>, PropertyInformations?: Maybe<(
      { __typename?: 'PropertyInformations' }
      & Pick<PropertyInformations, 'id' | 'cep' | 'street' | 'number' | 'rooms' | 'bathrooms' | 'garage' | 'properyType' | 'productId' | 'valorRestante' | 'quitado' | 'mesesDeContrato' | 'parcelasRestantes' | 'city' | 'neighborhood' | 'state' | 'complement'>
    )>, productBids?: Maybe<Array<(
      { __typename?: 'ProductBid' }
      & Pick<ProductBid, 'id' | 'bid' | 'userAccountId' | 'productId'>
      & { UserAccount?: Maybe<(
        { __typename?: 'UserAccount' }
        & Pick<UserAccount, 'id' | 'name'>
      )> }
    )>>, UserAccount: (
      { __typename?: 'UserAccount' }
      & Pick<UserAccount, 'id' | 'name' | 'photo' | 'email' | 'ratting'>
      & { ProfileAddress?: Maybe<Array<(
        { __typename?: 'ProfileAddress' }
        & Pick<ProfileAddress, 'CEP'>
      )>>, ProfileContacts?: Maybe<Array<(
        { __typename?: 'ProfileContacts' }
        & Pick<ProfileContacts, 'id' | 'phone'>
      )>> }
    ) }
  )> }
);

export type CreateProductBidMutationVariables = {
  userId: Scalars['Float'];
  productId: Scalars['Float'];
  bid: Scalars['String'];
};


export type CreateProductBidMutation = (
  { __typename?: 'Mutation' }
  & { createProductBid: (
    { __typename?: 'ProductBid' }
    & Pick<ProductBid, 'id' | 'bid'>
  ) }
);

export type GetAllProductBidQueryVariables = {
  productId: Scalars['Float'];
};


export type GetAllProductBidQuery = (
  { __typename?: 'Query' }
  & { getAllProductBid: Array<(
    { __typename?: 'ProductBid' }
    & Pick<ProductBid, 'id' | 'bid' | 'userAccountId' | 'productId'>
    & { Product?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'title' | 'subtitle' | 'amount' | 'typeNegotiation' | 'InitialValue' | 'price' | 'quantity' | 'stock' | 'categorieId' | 'description' | 'brand' | 'useState' | 'height' | 'width' | 'thickness' | 'material' | 'shippingWay' | 'deadline' | 'freeDelivery'>
    )> }
  )> }
);

export type UpdateProductStatusMutationVariables = {
  id: Scalars['Float'];
  status: Scalars['Boolean'];
};


export type UpdateProductStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateProductStatus'>
);

export type DeleteProductMutationVariables = {
  productId: Scalars['Float'];
};


export type DeleteProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProduct'>
);

export type GetProductBySearchQueryVariables = {
  title: Scalars['String'];
};


export type GetProductBySearchQuery = (
  { __typename?: 'Query' }
  & { getProductBySearch: Array<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'title' | 'subtitle' | 'amount' | 'typeNegotiation' | 'InitialValue' | 'price' | 'quantity' | 'stock' | 'categorieId' | 'description' | 'planId' | 'brand' | 'active' | 'maxInstallments' | 'finalOffersTime' | 'directValue' | 'useState' | 'height' | 'width' | 'thickness' | 'material' | 'deadline' | 'freeDelivery' | 'shippingWay' | 'userAccountId'>
    & { ProductCategories?: Maybe<(
      { __typename?: 'ProductCategories' }
      & Pick<ProductCategories, 'id' | 'name'>
    )>, UserAccount: (
      { __typename?: 'UserAccount' }
      & Pick<UserAccount, 'id' | 'name' | 'userTypeId' | 'cpf' | 'email' | 'ratting'>
    ), productBids?: Maybe<Array<(
      { __typename?: 'ProductBid' }
      & Pick<ProductBid, 'id' | 'bid' | 'userAccountId' | 'productId' | 'createdAt' | 'updatedAt'>
      & { UserAccount?: Maybe<(
        { __typename?: 'UserAccount' }
        & Pick<UserAccount, 'id' | 'name' | 'photo' | 'recipientId' | 'bankAccountId' | 'userTypeId' | 'cpf' | 'RG' | 'email' | 'password' | 'cardId' | 'ratting'>
      )> }
    )>> }
  )> }
);

export type UpdateProductMutationVariables = {
  id?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  typeNegotiation?: Maybe<Scalars['String']>;
  InitialValue?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  useState?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  thickness?: Maybe<Scalars['Float']>;
  maxInstallments?: Maybe<Scalars['Float']>;
  material?: Maybe<Scalars['String']>;
  shippingWay?: Maybe<Scalars['String']>;
  categorieId?: Maybe<Scalars['Float']>;
  subCategorieId?: Maybe<Scalars['Float']>;
  planId?: Maybe<Scalars['Float']>;
  directValue?: Maybe<Scalars['Float']>;
  finalOffersTime?: Maybe<Scalars['DateTime']>;
  propertyInformation: Scalars['Boolean'];
  vehicleInformation: Scalars['Boolean'];
  cep?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  properyType?: Maybe<Scalars['String']>;
  rooms?: Maybe<Scalars['String']>;
  bathrooms?: Maybe<Scalars['String']>;
  garage?: Maybe<Scalars['String']>;
  vehicleBrand?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
  motorPower?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['String']>;
  fuel?: Maybe<Scalars['String']>;
  licensePlate?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  numberDoors?: Maybe<Scalars['String']>;
  directionType?: Maybe<Scalars['String']>;
  exchange?: Maybe<Scalars['String']>;
  profileAddressId?: Maybe<Scalars['Float']>;
  withdraw?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  numberOfPhotos?: Maybe<Scalars['Float']>;
  imagesForRemove: Array<Scalars['Float']>;
  product_height?: Maybe<Scalars['Float']>;
  product_width?: Maybe<Scalars['Float']>;
  product_length?: Maybe<Scalars['Float']>;
  product_weight?: Maybe<Scalars['Float']>;
  prompt_delivery?: Maybe<Scalars['Boolean']>;
  deadline?: Maybe<Scalars['Float']>;
  freeDelivery?: Maybe<Scalars['Boolean']>;
  files: Array<Scalars['Upload']>;
};


export type UpdateProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateProduct'>
);

export type GetProductsByCategoryQueryVariables = {
  category?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Float']>;
};


export type GetProductsByCategoryQuery = (
  { __typename?: 'Query' }
  & { getProductsByCategory: Array<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'isSocialProject' | 'title' | 'subtitle' | 'amount' | 'typeNegotiation' | 'InitialValue' | 'price' | 'quantity' | 'stock' | 'categorieId' | 'description' | 'planId' | 'brand' | 'active' | 'maxInstallments' | 'finalOffersTime' | 'directValue' | 'useState' | 'height' | 'width' | 'thickness' | 'material' | 'shippingWay' | 'userAccountId' | 'deadline' | 'freeDelivery'>
    & { ProductCategories?: Maybe<(
      { __typename?: 'ProductCategories' }
      & Pick<ProductCategories, 'id' | 'name'>
    )>, UserAccount: (
      { __typename?: 'UserAccount' }
      & Pick<UserAccount, 'id' | 'name' | 'userTypeId' | 'cpf' | 'email' | 'ratting'>
    ) }
  )> }
);

export type GetAllSocialProjectsQueryVariables = {};


export type GetAllSocialProjectsQuery = (
  { __typename?: 'Query' }
  & { getAllSocialProjects: Array<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'title' | 'subtitle' | 'amount' | 'typeNegotiation' | 'InitialValue' | 'price' | 'quantity' | 'stock' | 'categorieId' | 'numberOfPhotos' | 'description' | 'active' | 'isSocialProject' | 'brand' | 'useState' | 'maxInstallments' | 'planId' | 'height' | 'width' | 'thickness' | 'material' | 'shippingWay' | 'deadline' | 'freeDelivery'>
    & { ProductsBlackList?: Maybe<(
      { __typename?: 'ProductsBlackList' }
      & Pick<ProductsBlackList, 'id' | 'productId'>
    )>, UserAccount: (
      { __typename?: 'UserAccount' }
      & Pick<UserAccount, 'id' | 'name' | 'userTypeId' | 'email' | 'ratting'>
      & { ProfileContacts?: Maybe<Array<(
        { __typename?: 'ProfileContacts' }
        & Pick<ProfileContacts, 'id' | 'phone'>
      )>>, ProfileAddress?: Maybe<Array<(
        { __typename?: 'ProfileAddress' }
        & Pick<ProfileAddress, 'id' | 'CEP' | 'street' | 'complement' | 'references' | 'userAccountId' | 'state'>
      )>> }
    ) }
  )> }
);

export type CreateUserBargainMutationVariables = {
  bargain: Scalars['String'];
  isValid: Scalars['Boolean'];
  userId: Scalars['Float'];
  productId: Scalars['Float'];
};


export type CreateUserBargainMutation = (
  { __typename?: 'Mutation' }
  & { createUserBargain: (
    { __typename?: 'UserBargains' }
    & Pick<UserBargains, 'id'>
  ) }
);

export type GetUserProductBargainQueryVariables = {
  userId: Scalars['Float'];
  productId: Scalars['Float'];
};


export type GetUserProductBargainQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUserProductBargain'>
);

export type UpdateUserProductBargainMutationVariables = {
  userId: Scalars['Float'];
  bargain: Scalars['String'];
  isValid: Scalars['Boolean'];
  productId: Scalars['Float'];
};


export type UpdateUserProductBargainMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUserProductBargain'>
);

export type GetAllProductCategoriesQueryVariables = {};


export type GetAllProductCategoriesQuery = (
  { __typename?: 'Query' }
  & { getAllProductCategories: Array<(
    { __typename?: 'ProductCategories' }
    & Pick<ProductCategories, 'id' | 'name' | 'split'>
  )> }
);

export type GetProductCategorieBySearchQueryVariables = {
  name: Scalars['String'];
};


export type GetProductCategorieBySearchQuery = (
  { __typename?: 'Query' }
  & { getProductCategorieBySearch: Array<(
    { __typename?: 'ProductCategories' }
    & Pick<ProductCategories, 'id' | 'name' | 'split'>
    & { ProductSubCategories?: Maybe<Array<(
      { __typename?: 'ProductSubCategories' }
      & Pick<ProductSubCategories, 'id' | 'name'>
    )>> }
  )> }
);

export type CreateProductCategorieMutationVariables = {
  name: Scalars['String'];
  split?: Maybe<Scalars['Float']>;
};


export type CreateProductCategorieMutation = (
  { __typename?: 'Mutation' }
  & { createProductCategorie: (
    { __typename?: 'ProductCategories' }
    & Pick<ProductCategories, 'id' | 'name' | 'split'>
  ) }
);

export type UpdateProductCategorieMutationVariables = {
  id: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
  split?: Maybe<Scalars['Float']>;
};


export type UpdateProductCategorieMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateProductCategorie'>
);

export type DeleteProductCategorieMutationVariables = {
  id: Scalars['Float'];
};


export type DeleteProductCategorieMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProductCategorie'>
);

export type CreateProductQuestionMutationVariables = {
  productId: Scalars['Float'];
  userId: Scalars['Float'];
  description: Scalars['String'];
};


export type CreateProductQuestionMutation = (
  { __typename?: 'Mutation' }
  & { createProductQuestion: (
    { __typename?: 'ProductQuestions' }
    & Pick<ProductQuestions, 'id' | 'commentDescription'>
  ) }
);

export type GetAllProductQuestionsQueryVariables = {
  productId: Scalars['Float'];
};


export type GetAllProductQuestionsQuery = (
  { __typename?: 'Query' }
  & { getAllProductQuestions: Array<(
    { __typename?: 'ProductQuestions' }
    & Pick<ProductQuestions, 'id' | 'commentDescription' | 'userAccountId' | 'productId' | 'createdAt' | 'repeated'>
    & { UserAccount: (
      { __typename?: 'UserAccount' }
      & Pick<UserAccount, 'id' | 'name' | 'cpf'>
    ), Product: (
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'userAccountId'>
      & { UserAccount: (
        { __typename?: 'UserAccount' }
        & Pick<UserAccount, 'id'>
      ) }
    ), ProductQuestionsAnswers?: Maybe<Array<(
      { __typename?: 'ProductQuestionsAnswers' }
      & Pick<ProductQuestionsAnswers, 'id'>
    )>> }
  )> }
);

export type MarkAsRepeatMutationVariables = {
  id: Scalars['Float'];
  userId: Scalars['Float'];
};


export type MarkAsRepeatMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markAsRepeat'>
);

export type GetAllProductQuestionAnswersQueryVariables = {
  productQuestionId: Scalars['Float'];
};


export type GetAllProductQuestionAnswersQuery = (
  { __typename?: 'Query' }
  & { getAllProductQuestionAnswers: Array<(
    { __typename?: 'ProductQuestionsAnswers' }
    & Pick<ProductQuestionsAnswers, 'id' | 'description' | 'createdAt' | 'userAccountId' | 'productQuestionId'>
    & { UserAccount: (
      { __typename?: 'UserAccount' }
      & Pick<UserAccount, 'id' | 'name' | 'cpf'>
    ) }
  )> }
);

export type CreateProductQuestionAnswersMutationVariables = {
  productQuestionId: Scalars['Float'];
  userId: Scalars['Float'];
  description: Scalars['String'];
};


export type CreateProductQuestionAnswersMutation = (
  { __typename?: 'Mutation' }
  & { createProductQuestionAnswers: (
    { __typename?: 'ProductQuestionsAnswers' }
    & Pick<ProductQuestionsAnswers, 'id' | 'description'>
  ) }
);

export type UpdateProductQuestionAnswerMutationVariables = {
  id: Scalars['Float'];
  description: Scalars['String'];
};


export type UpdateProductQuestionAnswerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateProductQuestionAnswer'>
);

export type DeleteProductQuestionAnswerMutationVariables = {
  id: Scalars['Float'];
};


export type DeleteProductQuestionAnswerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProductQuestionAnswer'>
);

export type GetAllProductRatingsQueryVariables = {
  productId: Scalars['Float'];
};


export type GetAllProductRatingsQuery = (
  { __typename?: 'Query' }
  & { getAllProductRatings: Array<(
    { __typename?: 'ProductsRating' }
    & Pick<ProductsRating, 'id' | 'rating' | 'ratingDescription' | 'userAccountId' | 'createdAt' | 'productId'>
    & { UserAccount?: Maybe<(
      { __typename?: 'UserAccount' }
      & Pick<UserAccount, 'id' | 'name' | 'cpf'>
    )> }
  )> }
);

export type CreateProductRatingMutationVariables = {
  userId: Scalars['Float'];
  productId: Scalars['Float'];
  rating: Scalars['Float'];
  ratingDescription: Scalars['String'];
  sellerId: Scalars['Float'];
};


export type CreateProductRatingMutation = (
  { __typename?: 'Mutation' }
  & { createProductRating: (
    { __typename?: 'ProductsRating' }
    & Pick<ProductsRating, 'id'>
  ) }
);

export type DeleteRatingMutationVariables = {
  id: Scalars['Float'];
};


export type DeleteRatingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteRating'>
);

export type UpdateProductRatingMutationVariables = {
  ratingId: Scalars['Float'];
  rating: Scalars['Float'];
  ratingDescription: Scalars['String'];
};


export type UpdateProductRatingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateProductRating'>
);

export type GetAllUserRatingsQueryVariables = {
  userSalerId: Scalars['Float'];
};


export type GetAllUserRatingsQuery = (
  { __typename?: 'Query' }
  & { getAllUserRatings: Array<(
    { __typename?: 'ProductsRating' }
    & Pick<ProductsRating, 'id' | 'rating' | 'createdAt' | 'ratingDescription' | 'userAccountId' | 'productId'>
    & { UserAccount?: Maybe<(
      { __typename?: 'UserAccount' }
      & Pick<UserAccount, 'id' | 'name' | 'userTypeId' | 'cpf' | 'email' | 'ratting' | 'RG' | 'password'>
    )> }
  )> }
);

export type GetAllProductSubCategoriesQueryVariables = {
  categorieId: Scalars['Float'];
};


export type GetAllProductSubCategoriesQuery = (
  { __typename?: 'Query' }
  & { getAllProductSubCategories: Array<(
    { __typename?: 'ProductSubCategories' }
    & Pick<ProductSubCategories, 'id' | 'name' | 'categorieId'>
  )> }
);

export type CreateProductSubCategorieMutationVariables = {
  categorieId: Scalars['Float'];
  name: Scalars['String'];
};


export type CreateProductSubCategorieMutation = (
  { __typename?: 'Mutation' }
  & { createProductSubCategorie: (
    { __typename?: 'ProductSubCategories' }
    & Pick<ProductSubCategories, 'id' | 'name' | 'categorieId'>
  ) }
);

export type DeleteProductSubCategorieMutationVariables = {
  id: Scalars['Float'];
};


export type DeleteProductSubCategorieMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProductSubCategorie'>
);

export type GetAllSubCategoriesByCategoryQueryVariables = {
  categoryId: Scalars['Float'];
};


export type GetAllSubCategoriesByCategoryQuery = (
  { __typename?: 'Query' }
  & { getAllSubCategoriesByCategory: Array<(
    { __typename?: 'ProductSubCategories' }
    & Pick<ProductSubCategories, 'id' | 'name' | 'categorieId'>
  )> }
);

export type IncludeProductMutationVariables = {
  productId: Scalars['Float'];
  blockReason: Scalars['String'];
};


export type IncludeProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'includeProduct'>
);

export type RemoveProductMutationVariables = {
  productId: Scalars['Float'];
};


export type RemoveProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeProduct'>
);

export type VerifyProductsInListQueryVariables = {
  productId: Scalars['Float'];
};


export type VerifyProductsInListQuery = (
  { __typename?: 'Query' }
  & { verifyProductsInList: (
    { __typename?: 'ProductsBlackList' }
    & Pick<ProductsBlackList, 'id' | 'blockReason' | 'productId'>
    & { Product?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id'>
      & { UserAccount: (
        { __typename?: 'UserAccount' }
        & Pick<UserAccount, 'id'>
      ) }
    )> }
  ) }
);

export type PurchaseInCartMutationVariables = {
  PurchaseInCartInput: PurchaseInCartInput;
};


export type PurchaseInCartMutation = (
  { __typename?: 'Mutation' }
  & { PurchaseInCart: (
    { __typename?: 'BankAccountInCartReturn' }
    & Pick<BankAccountInCartReturn, 'boleto_url' | 'pix_date_expiration' | 'pix_qrcode' | 'transaction_id'>
  ) }
);

export type CreateCartNotificationMutationVariables = {
  userId: Scalars['Float'];
  productId: Scalars['Float'];
};


export type CreateCartNotificationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createCartNotification'>
);

export type GetProductsSoldByPeriodQueryVariables = {
  period: Scalars['Float'];
  userId: Scalars['Float'];
};


export type GetProductsSoldByPeriodQuery = (
  { __typename?: 'Query' }
  & { getProductsSoldByPeriod: Array<(
    { __typename?: 'GetProductsSoldByPeriodOutput' }
    & Pick<GetProductsSoldByPeriodOutput, 'productId' | 'n_totalProduto'>
    & { Product: (
      { __typename?: 'Product' }
      & Pick<Product, 'title' | 'price' | 'subtitle' | 'description'>
    ) }
  )> }
);

export type AtualizarStatuscompraMutationVariables = {
  atualizarStatusCompraInputData: AtualizarStatusCompraInputData;
};


export type AtualizarStatuscompraMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'atualizarStatusCompra'>
);

export type GetQrCodePixQueryVariables = {
  purchaseId: Scalars['Float'];
};


export type GetQrCodePixQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getQrCodePix'>
);

export type GetPurchaseStatusByPurchaseIdQueryVariables = {
  getPurchaseStatusByPurchaseIdInputData: GetPurchaseStatusByPurchaseIdInputData;
};


export type GetPurchaseStatusByPurchaseIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPurchaseStatusByPurchaseId'>
);

export type AddInvoiceMutationVariables = {
  purchaseInvoice: Scalars['Upload'];
  purchaseId: Scalars['Float'];
};


export type AddInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addInvoice'>
);

export type CreateReportMutationVariables = {
  groundsForReportId: Scalars['Float'];
  userAccountId: Scalars['Float'];
  productId: Scalars['Float'];
};


export type CreateReportMutation = (
  { __typename?: 'Mutation' }
  & { createReport: (
    { __typename?: 'Reports' }
    & Pick<Reports, 'id'>
  ) }
);

export type GetAllReportsQueryVariables = {};


export type GetAllReportsQuery = (
  { __typename?: 'Query' }
  & { getAllReports: Array<(
    { __typename?: 'Reports' }
    & Pick<Reports, 'id'>
    & { GroundsForReport?: Maybe<(
      { __typename?: 'GroundsForReports' }
      & Pick<GroundsForReports, 'id' | 'title'>
    )>, UserAccount?: Maybe<(
      { __typename?: 'UserAccount' }
      & Pick<UserAccount, 'id' | 'name'>
    )>, Product?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'title'>
    )> }
  )> }
);

export type GetShippingsByUserIdQueryVariables = {
  userId: Scalars['Float'];
};


export type GetShippingsByUserIdQuery = (
  { __typename?: 'Query' }
  & { getShippingsByUserId: Array<(
    { __typename?: 'Shipping' }
    & Pick<Shipping, 'id' | 'description' | 'carrier' | 'startPostalCode' | 'endPostalCode' | 'minimumWeight' | 'maximumWeight' | 'longestLength' | 'price' | 'deliveryTime' | 'carrierLogo'>
  )> }
);

export type GetShippingsByQuoteQueryVariables = {
  productId: Scalars['Float'];
  postalCode: Scalars['Float'];
};


export type GetShippingsByQuoteQuery = (
  { __typename?: 'Query' }
  & { getShippingsByQuote: Array<(
    { __typename?: 'Shipping' }
    & Pick<Shipping, 'id' | 'description' | 'carrier' | 'startPostalCode' | 'endPostalCode' | 'minimumWeight' | 'maximumWeight' | 'longestLength' | 'price' | 'deliveryTime' | 'carrierLogo'>
  )> }
);

export type GetShippingsAsCsvQueryVariables = {
  userId: Scalars['Float'];
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};


export type GetShippingsAsCsvQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getShippingsAsCsv'>
);

export type PutShippingsMutationVariables = {
  userId: Scalars['Float'];
  shippingInput: Array<ShippingInput>;
};


export type PutShippingsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'putShippings'>
);

export type PutShippingsFromCsvMutationVariables = {
  userId: Scalars['Float'];
  csv: Scalars['Upload'];
};


export type PutShippingsFromCsvMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'putShippingsFromCsv'>
);

export type DeleteShippingsMutationVariables = {
  userId: Scalars['Float'];
  shippingIds: Array<Scalars['Float']>;
};


export type DeleteShippingsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteShippings'>
);

export type GetAllStandardShopBannersQueryVariables = {
  userId: Scalars['Float'];
};


export type GetAllStandardShopBannersQuery = (
  { __typename?: 'Query' }
  & { getAllStandardShopBanners: (
    { __typename?: 'Banners' }
    & { standardBannersVariable: Array<(
      { __typename?: 'ShopBanners' }
      & Pick<ShopBanners, 'id' | 'userAccountId' | 'name' | 'dataType'>
    )>, userBanners: Array<(
      { __typename?: 'ShopBanners' }
      & Pick<ShopBanners, 'id' | 'userAccountId' | 'name' | 'dataType'>
    )> }
  ) }
);

export type CreateShopBannerMutationVariables = {
  name: Scalars['String'];
  Picture: Scalars['Upload'];
  userId: Scalars['Float'];
};


export type CreateShopBannerMutation = (
  { __typename?: 'Mutation' }
  & { createShopBanner: (
    { __typename?: 'ShopBanners' }
    & Pick<ShopBanners, 'id' | 'userAccountId' | 'name' | 'dataType'>
  ) }
);

export type CreateBannerMutationVariables = {
  createBannerInput: CreateBannerInput;
  Picture: Scalars['Upload'];
};


export type CreateBannerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBanner'>
);

export type CreateUserAccountMutationVariables = {
  name: Scalars['String'];
  userTypeId: Scalars['Float'];
  cpf: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  ratting: Scalars['Float'];
};


export type CreateUserAccountMutation = (
  { __typename?: 'Mutation' }
  & { createUserAccount: (
    { __typename?: 'UserAccount' }
    & Pick<UserAccount, 'id' | 'email'>
  ) }
);

export type CreateUseraccountWithAdmUserMutationVariables = {
  email: Scalars['String'];
  name: Scalars['String'];
  userTypeId: Scalars['Float'];
  newSocialProjectTypeInput?: Maybe<NewSocialProjectTypeInput>;
};


export type CreateUseraccountWithAdmUserMutation = (
  { __typename?: 'Mutation' }
  & { createUseraccountWithAdmUser: (
    { __typename?: 'NewUser' }
    & Pick<NewUser, 'email' | 'password' | 'id' | 'name' | 'userTypeId'>
    & { UserType: (
      { __typename?: 'UserType' }
      & Pick<UserType, 'id' | 'name'>
    ) }
  ) }
);

export type DeleteAddressMutationVariables = {
  addressId: Scalars['Float'];
};


export type DeleteAddressMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAddress'>
);

export type GetAllAddressQueryVariables = {
  userId: Scalars['Float'];
};


export type GetAllAddressQuery = (
  { __typename?: 'Query' }
  & { getAllAddress: Array<(
    { __typename?: 'ProfileAddress' }
    & Pick<ProfileAddress, 'id' | 'state' | 'city' | 'street' | 'addressTitle' | 'neighborhood' | 'number' | 'CEP' | 'complement' | 'references'>
  )> }
);

export type UpdateUserAccountMutationVariables = {
  id: Scalars['Float'];
  phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  ratting?: Maybe<Scalars['Float']>;
};


export type UpdateUserAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateUserAccount: (
    { __typename?: 'UserAccount' }
    & Pick<UserAccount, 'id' | 'name' | 'userTypeId' | 'cpf' | 'RG' | 'email' | 'password' | 'cardId' | 'ratting'>
    & { ProfileAddress?: Maybe<Array<(
      { __typename?: 'ProfileAddress' }
      & Pick<ProfileAddress, 'id' | 'state' | 'city' | 'street' | 'addressTitle' | 'neighborhood' | 'number' | 'CEP' | 'complement' | 'references'>
    )>>, ProfileContacts?: Maybe<Array<(
      { __typename?: 'ProfileContacts' }
      & Pick<ProfileContacts, 'id' | 'phone'>
    )>> }
  ) }
);

export type GetAllUserAccountsQueryVariables = {};


export type GetAllUserAccountsQuery = (
  { __typename?: 'Query' }
  & { getAllUserAccounts: Array<(
    { __typename?: 'UserAccount' }
    & Pick<UserAccount, 'id' | 'name' | 'email' | 'userTypeId'>
    & { UserType?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'id' | 'name'>
    )>, ProfileContacts?: Maybe<Array<(
      { __typename?: 'ProfileContacts' }
      & Pick<ProfileContacts, 'id' | 'phone'>
    )>> }
  )> }
);

export type GetUserAccountQueryVariables = {
  userAccountId: Scalars['String'];
};


export type GetUserAccountQuery = (
  { __typename?: 'Query' }
  & { getUserAccount: (
    { __typename?: 'UserAccount' }
    & Pick<UserAccount, 'id' | 'name' | 'recipientId' | 'bankAccountId' | 'token' | 'cpf' | 'RG' | 'email'>
  ) }
);

export type CreateUserImageMutationVariables = {
  userId: Scalars['Float'];
  Picture: Scalars['Upload'];
};


export type CreateUserImageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createUserImage'>
);

export type CreateAddressMutationVariables = {
  addressTitle?: Maybe<Scalars['String']>;
  CEP?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  references?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
};


export type CreateAddressMutation = (
  { __typename?: 'Mutation' }
  & { createAddress: (
    { __typename?: 'ProfileAddress' }
    & Pick<ProfileAddress, 'id' | 'state' | 'city' | 'street' | 'addressTitle' | 'neighborhood' | 'number' | 'CEP' | 'complement' | 'references' | 'userAccountId'>
  ) }
);

export type ResetUserPasswordMutationVariables = {
  email: Scalars['String'];
};


export type ResetUserPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetUserPassword'>
);

export type UserAccountFilterQueryVariables = {
  includeSearch: Scalars['Boolean'];
  searchValue?: Maybe<Scalars['String']>;
  name: Array<Scalars['String']>;
};


export type UserAccountFilterQuery = (
  { __typename?: 'Query' }
  & { userAccountFilter: Array<(
    { __typename?: 'UserAccount' }
    & Pick<UserAccount, 'id' | 'name' | 'email'>
    & { UserType?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'id' | 'name'>
    )>, ProfileContacts?: Maybe<Array<(
      { __typename?: 'ProfileContacts' }
      & Pick<ProfileContacts, 'id' | 'phone'>
    )>> }
  )> }
);

export type BlockUserMutationVariables = {
  userId: Scalars['Float'];
  block: Scalars['Boolean'];
};


export type BlockUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'blockUser'>
);

export type GetBlockStatusQueryVariables = {
  userId: Scalars['Float'];
};


export type GetBlockStatusQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getBlockStatus'>
);

export type ReportBugMutationVariables = {
  contactEmail: Scalars['String'];
  problemDescription: Scalars['String'];
};


export type ReportBugMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reportBug'>
);

export type SendConfirmationEmailMutationVariables = {
  email: Scalars['String'];
};


export type SendConfirmationEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendConfirmationEmail'>
);

export type DeleteBankAccountMutationVariables = {
  id: Scalars['Float'];
};


export type DeleteBankAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBankAccount'>
);

export type GetUserHasShippingServiceQueryVariables = {
  userId: Scalars['Float'];
};


export type GetUserHasShippingServiceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUserHasShippingService'>
);

export type ValidateConectionQueryVariables = {
  userId: Scalars['Float'];
};


export type ValidateConectionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'validateConection'>
);

export type CreateUserCustomizationMutationVariables = {
  background?: Maybe<Scalars['String']>;
  backgroundIsImage?: Maybe<Scalars['Boolean']>;
  brand?: Maybe<Scalars['String']>;
  brandIsImage?: Maybe<Scalars['Boolean']>;
  brandTextColor?: Maybe<Scalars['String']>;
  shopName?: Maybe<Scalars['String']>;
  userId: Scalars['Float'];
  brandColor?: Maybe<Scalars['String']>;
};


export type CreateUserCustomizationMutation = (
  { __typename?: 'Mutation' }
  & { createUserCustomization: (
    { __typename?: 'UserCustomization' }
    & Pick<UserCustomization, 'id'>
  ) }
);

export type UploadBrandOrBackgroundMutationVariables = {
  isBackground: Scalars['Boolean'];
  Picture: Scalars['Upload'];
  userId: Scalars['Float'];
};


export type UploadBrandOrBackgroundMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadBrandOrBackground'>
);

export type CreateUserCustomizationWithCustomBrandMutationVariables = {
  userId: Scalars['Float'];
  Picture?: Maybe<Scalars['Upload']>;
};


export type CreateUserCustomizationWithCustomBrandMutation = (
  { __typename?: 'Mutation' }
  & { createUserCustomizationWithCustomBrand: (
    { __typename?: 'UserCustomization' }
    & Pick<UserCustomization, 'id'>
  ) }
);

export type GetUserCustomizationQueryVariables = {
  userId: Scalars['Float'];
};


export type GetUserCustomizationQuery = (
  { __typename?: 'Query' }
  & { getUserCustomization?: Maybe<(
    { __typename?: 'UserCustomization' }
    & Pick<UserCustomization, 'id' | 'brand' | 'bannerTextColor' | 'background' | 'brandColor' | 'hasBackground' | 'ShopName' | 'hasBanner' | 'userAccountId'>
  )> }
);

export type ReturnToStandardMutationVariables = {
  userId: Scalars['Float'];
};


export type ReturnToStandardMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'returnToStandard'>
);

export type DeleteLogoMarcaMutationVariables = {
  userId: Scalars['Float'];
};


export type DeleteLogoMarcaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteLogoMarca'>
);

export type UpdateUserNotificationStatusMutationVariables = {
  id: Scalars['Float'];
};


export type UpdateUserNotificationStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUserNotificationStatus'>
);

export type GetAllVariationProductQueryVariables = {
  getAllVariationProductInput: GetAllVariationProductInput;
};


export type GetAllVariationProductQuery = (
  { __typename?: 'Query' }
  & { getAllVariationProduct: Array<(
    { __typename?: 'VariationProduct' }
    & Pick<VariationProduct, 'id' | 'name'>
  )> }
);

export type GetAllProductVariationByProductIdQueryVariables = {
  productId: Scalars['Float'];
};


export type GetAllProductVariationByProductIdQuery = (
  { __typename?: 'Query' }
  & { getAllProductVariationByProductId: Array<(
    { __typename?: 'ProductVariations' }
    & Pick<ProductVariations, 'id' | 'cor' | 'tamanho' | 'productId' | 'quantidade'>
    & { ColorVariation?: Maybe<(
      { __typename?: 'ColorVariations' }
      & Pick<ColorVariations, 'id' | 'name'>
    )>, TamanhoVariations?: Maybe<(
      { __typename?: 'TamanhoVariations' }
      & Pick<TamanhoVariations, 'id' | 'name'>
    )> }
  )> }
);

export type DeleteProductVariationByIdMutationVariables = {
  productVariationId: Scalars['Float'];
};


export type DeleteProductVariationByIdMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProductVariationById'>
);

export type UpdateProductVariationByIdMutationVariables = {
  updateProductVariation: UpdateProductVariationInputData;
};


export type UpdateProductVariationByIdMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateProductVariationById'>
);

export type CreateProdutVariationMutationVariables = {
  createProductVariationInputData: CreateProductVariationInputData;
};


export type CreateProdutVariationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createProdutVariation'>
);

export type CreateRecipientMutationVariables = {
  userId: Scalars['Float'];
  bank_code: Scalars['String'];
  agencia: Scalars['String'];
  conta: Scalars['String'];
  cpf: Scalars['String'];
  legal_name: Scalars['String'];
  conta_dv: Scalars['String'];
  type: Scalars['String'];
};


export type CreateRecipientMutation = (
  { __typename?: 'Mutation' }
  & { createRecipient: (
    { __typename?: 'UserAccount' }
    & Pick<UserAccount, 'id' | 'bankAccountId'>
  ) }
);

export type GetAccountQueryVariables = {
  userId: Scalars['Float'];
};


export type GetAccountQuery = (
  { __typename?: 'Query' }
  & { getAccount: (
    { __typename?: 'BankAccount' }
    & Pick<BankAccount, 'id' | 'bank_code' | 'agencia' | 'legal_name' | 'conta' | 'conta_dv' | 'type' | 'document_type' | 'document_number'>
  ) }
);

export type CreateBlingCountMutationVariables = {
  createBlingCountInputData: CreateBlingCountInputData;
};


export type CreateBlingCountMutation = (
  { __typename?: 'Mutation' }
  & { createBlingCount: (
    { __typename?: 'Bling' }
    & Pick<Bling, 'id' | 'apiKey' | 'userAccountId'>
  ) }
);

export type GetBlingAccountQueryVariables = {
  getBlingAccountInputData: GetBlingAccountInputData;
};


export type GetBlingAccountQuery = (
  { __typename?: 'Query' }
  & { getBlingAccount?: Maybe<(
    { __typename?: 'Bling' }
    & Pick<Bling, 'id' | 'apiKey' | 'userAccountId'>
  )> }
);

export type GerarNotaFiscalMutationVariables = {
  gerarNotaFiscalInputData: GerarNotaFiscalInputData;
};


export type GerarNotaFiscalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarNotaFiscal'>
);

export type FindBlingNfcsMutationVariables = {
  purchaseId: Scalars['Float'];
};


export type FindBlingNfcsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'findBlingNfcs'>
);

export type GetAllBlingProductsQueryVariables = {
  userId: Scalars['Float'];
};


export type GetAllBlingProductsQuery = (
  { __typename?: 'Query' }
  & { getAllBlingProducts: Array<(
    { __typename?: 'BlingProduct' }
    & Pick<BlingProduct, 'id' | 'codigo' | 'descricao' | 'preco' | 'imageThumbnail' | 'estoqueAtual'>
  )> }
);

export type PublishBlingProductsMutationVariables = {
  userId: Scalars['Float'];
  blingProductIds: Array<Scalars['String']>;
};


export type PublishBlingProductsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'publishBlingProducts'>
);

export type CreateCardMutationVariables = {
  userId: Scalars['Float'];
  card_number: Scalars['String'];
  card_expiration_date: Scalars['String'];
  card_cvv: Scalars['String'];
  card_holder_name: Scalars['String'];
  cpf: Scalars['String'];
};


export type CreateCardMutation = (
  { __typename?: 'Mutation' }
  & { createCard?: Maybe<(
    { __typename?: 'Card' }
    & Pick<Card, 'id' | 'date_created' | 'date_updated' | 'brand' | 'holder_name' | 'first_digits' | 'last_digits' | 'customer' | 'valid' | 'expiration_date'>
  )> }
);

export type GetAllCardsQueryVariables = {
  userId: Scalars['Float'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
};


export type GetAllCardsQuery = (
  { __typename?: 'Query' }
  & { getAllCards: Array<(
    { __typename?: 'Card' }
    & Pick<Card, 'id' | 'object' | 'date_created' | 'date_updated' | 'holder_name' | 'last_digits' | 'fingerprint' | 'valid' | 'expiration_date' | 'first_digits' | 'brand'>
  )> }
);

export type DeleteCardMutationVariables = {
  id: Scalars['String'];
};


export type DeleteCardMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCard'>
);

export type GetAllCardsIdQueryVariables = {
  userId: Scalars['Float'];
};


export type GetAllCardsIdQuery = (
  { __typename?: 'Query' }
  & { getAllCardsId: (
    { __typename?: 'UserCard' }
    & Pick<UserCard, 'id' | 'cardId' | 'userAccountId'>
  ) }
);

export type LoginMutationVariables = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'accessToken'>
    & { user: (
      { __typename?: 'UserAccount' }
      & Pick<UserAccount, 'id' | 'name' | 'userTypeId' | 'cpf' | 'RG' | 'createdAt' | 'email' | 'dateBirth' | 'bankAccountId' | 'password' | 'melhorEnvioToken' | 'block' | 'cardId' | 'ratting'>
      & { UserType?: Maybe<(
        { __typename?: 'UserType' }
        & Pick<UserType, 'id' | 'name'>
      )>, ProfileAddress?: Maybe<Array<(
        { __typename?: 'ProfileAddress' }
        & Pick<ProfileAddress, 'id' | 'state' | 'city' | 'street' | 'addressTitle' | 'neighborhood' | 'number' | 'CEP' | 'complement' | 'references'>
      )>>, ProfileContacts?: Maybe<Array<(
        { __typename?: 'ProfileContacts' }
        & Pick<ProfileContacts, 'id' | 'phone'>
      )>> }
    ) }
  ) }
);

export type SetMelhorEnvioTokenMutationVariables = {
  token: Scalars['String'];
  refreshToken: Scalars['String'];
  userId: Scalars['Float'];
};


export type SetMelhorEnvioTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setMelhorEnvioToken'>
);

export type GetMelhorEnvioTokenQueryVariables = {
  userId: Scalars['Float'];
};


export type GetMelhorEnvioTokenQuery = (
  { __typename?: 'Query' }
  & { getMelhorEnvioToken: (
    { __typename?: 'MelhorEnvioTokenReturn' }
    & Pick<MelhorEnvioTokenReturn, 'token' | 'refreshToken'>
  ) }
);

export type InsertProductOnMelhorEnvioCartMutationVariables = {
  ProductInfos: InsertTicketsOnCartRequest;
  userId: Scalars['Float'];
  purchaseId: Scalars['Float'];
  gateway?: Maybe<Scalars['String']>;
};


export type InsertProductOnMelhorEnvioCartMutation = (
  { __typename?: 'Mutation' }
  & { insertProductOnMelhorEnvioCart: (
    { __typename?: 'BuyTocketsReturn' }
    & Pick<BuyTocketsReturn, 'url'>
  ) }
);

export type CalculateShippingValueQueryVariables = {
  infos: RequestShippingCalculate;
  userId: Scalars['Float'];
  productId: Scalars['Float'];
};


export type CalculateShippingValueQuery = (
  { __typename?: 'Query' }
  & { calculateShippingValue: Array<(
    { __typename?: 'ResponseShippingCalculate' }
    & Pick<ResponseShippingCalculate, 'custom_delivery_time' | 'custom_price' | 'delivery_time' | 'discount' | 'id' | 'productId' | 'name'>
    & { additional_services?: Maybe<(
      { __typename?: 'Additional_services' }
      & Pick<Additional_Services, 'collect' | 'own_hand' | 'receipt'>
    )>, company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name' | 'picture'>
    )>, custom_delivery_range?: Maybe<(
      { __typename?: 'Custom_delivery_range' }
      & Pick<Custom_Delivery_Range, 'max' | 'min'>
    )>, delivery_range?: Maybe<(
      { __typename?: 'Delivery_range' }
      & Pick<Delivery_Range, 'max' | 'min'>
    )> }
  )> }
);

export type GenerateAndPrintTicketsQueryVariables = {
  userId: Scalars['Float'];
  ticketId: Scalars['Float'];
};


export type GenerateAndPrintTicketsQuery = (
  { __typename?: 'Query' }
  & { generateAndPrintTickets: (
    { __typename?: 'TicketGenerateReturn' }
    & Pick<TicketGenerateReturn, 'isPaid' | 'url'>
  ) }
);

export type GetServiceByServiceIdQueryVariables = {
  serviceId: Scalars['String'];
};


export type GetServiceByServiceIdQuery = (
  { __typename?: 'Query' }
  & { getServiceByServiceId: (
    { __typename?: 'GetServiceResponse' }
    & Pick<GetServiceResponse, 'id' | 'name' | 'picture'>
  ) }
);

export type SearchTicketQueryVariables = {
  userId: Scalars['Float'];
  purchaseId: Scalars['Float'];
};


export type SearchTicketQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'searchTicket'>
);

export type TrackQueryVariables = {
  userId: Scalars['Float'];
  ticketId: Scalars['String'];
};


export type TrackQuery = (
  { __typename?: 'Query' }
  & { track: (
    { __typename?: 'TrackResponse' }
    & Pick<TrackResponse, 'id' | 'status' | 'paid_at' | 'generated_at' | 'posted_at' | 'delivered_at' | 'canceled_at' | 'expired_at' | 'melhorenvio_tracking'>
  ) }
);

export type CreateUserOnMelhorEnvioMutationVariables = {
  createMelhorEnvioUsersRequest: CreateMelhorEnvioUsersRequest;
};


export type CreateUserOnMelhorEnvioMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createUserOnMelhorEnvio'>
);

export type CheckUserBalanceQueryVariables = {
  userId: Scalars['Float'];
};


export type CheckUserBalanceQuery = (
  { __typename?: 'Query' }
  & { checkUserBalance: (
    { __typename?: 'CheckBalanceResponse' }
    & Pick<CheckBalanceResponse, 'balance' | 'reserved' | 'debts'>
  ) }
);

export type InsertUserBalanceQueryVariables = {
  userId: Scalars['Float'];
  infos: InsertBalanceRequest;
};


export type InsertUserBalanceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'insertUserBalance'>
);

export type DesconectarConexaoMutationVariables = {
  userId: Scalars['Float'];
};


export type DesconectarConexaoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'desconectarConexao'>
);

export type GetBiggerProductBidQueryVariables = {
  productId: Scalars['Float'];
};


export type GetBiggerProductBidQuery = (
  { __typename?: 'Query' }
  & { getBiggerProductBid: Array<(
    { __typename?: 'ProductBid' }
    & Pick<ProductBid, 'id' | 'bid'>
    & { UserAccount?: Maybe<(
      { __typename?: 'UserAccount' }
      & Pick<UserAccount, 'id' | 'name'>
    )> }
  )> }
);

export type DeletePurchaseMutationVariables = {
  purchaseId: Scalars['String'];
  userId: Scalars['Float'];
};


export type DeletePurchaseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePurchase'>
);

export type SendSolicitationCancelPurchaseMutationVariables = {
  purchaseId: Scalars['String'];
  userId: Scalars['Float'];
  descriptionCancel: Scalars['String'];
};


export type SendSolicitationCancelPurchaseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendSolicitationCancelPurchase'>
);

export type GetAllUserPurchasesInOneProductQueryVariables = {
  userId: Scalars['Float'];
  productId: Scalars['Float'];
};


export type GetAllUserPurchasesInOneProductQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAllUserPurchasesInOneProduct'>
);

export type GetAllUserPurchasesQueryVariables = {
  userId: Scalars['Float'];
};


export type GetAllUserPurchasesQuery = (
  { __typename?: 'Query' }
  & { getAllUserPurchases: Array<(
    { __typename?: 'Purchase' }
    & Pick<Purchase, 'id' | 'statusId' | 'price' | 'productQuantity' | 'pagarmeId' | 'paymentMethod' | 'userAccountId' | 'ticketId' | 'productShippingPrice' | 'paidAt' | 'trackingCode'>
    & { Status?: Maybe<(
      { __typename?: 'PurchaseStatus' }
      & Pick<PurchaseStatus, 'id' | 'engName' | 'ptName'>
    )>, UserAccount?: Maybe<(
      { __typename?: 'UserAccount' }
      & Pick<UserAccount, 'id' | 'name' | 'photo' | 'userTypeId' | 'cpf' | 'RG' | 'email' | 'password' | 'cardId' | 'ratting'>
      & { ProfileAddress?: Maybe<Array<(
        { __typename?: 'ProfileAddress' }
        & Pick<ProfileAddress, 'id' | 'state' | 'city' | 'street' | 'neighborhood' | 'number' | 'CEP' | 'complement' | 'references'>
      )>>, ProfileContacts?: Maybe<Array<(
        { __typename?: 'ProfileContacts' }
        & Pick<ProfileContacts, 'id' | 'phone'>
      )>> }
    )>, Product?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'title' | 'subtitle' | 'amount' | 'typeNegotiation' | 'InitialValue' | 'price' | 'quantity' | 'stock' | 'categorieId' | 'description' | 'brand' | 'useState' | 'maxInstallments' | 'height' | 'width' | 'thickness' | 'material' | 'shippingWay' | 'deadline' | 'userAccountId'>
      & { UserAccount: (
        { __typename?: 'UserAccount' }
        & Pick<UserAccount, 'id' | 'name'>
      ) }
    )>, PurchaseInvoice?: Maybe<(
      { __typename?: 'PurchaseInvoice' }
      & Pick<PurchaseInvoice, 'id' | 'name' | 'path'>
    )> }
  )> }
);

export type GetAllPurchasesQueryVariables = {};


export type GetAllPurchasesQuery = (
  { __typename?: 'Query' }
  & { getAllPurchases: Array<(
    { __typename?: 'Purchase' }
    & Pick<Purchase, 'updatedAt'>
  )> }
);

export type GetPurchaseQueryVariables = {
  id: Scalars['Float'];
};


export type GetPurchaseQuery = (
  { __typename?: 'Query' }
  & { getPurchase: (
    { __typename?: 'Purchase' }
    & Pick<Purchase, 'id' | 'statusId' | 'price' | 'pagarmeId' | 'userAccountId'>
    & { Status?: Maybe<(
      { __typename?: 'PurchaseStatus' }
      & Pick<PurchaseStatus, 'id' | 'ptName' | 'engName'>
    )>, Tickets?: Maybe<(
      { __typename?: 'Tickets' }
      & Pick<Tickets, 'id' | 'ticketId' | 'url'>
    )>, UserAccount?: Maybe<(
      { __typename?: 'UserAccount' }
      & Pick<UserAccount, 'id' | 'name' | 'photo' | 'userTypeId' | 'cpf' | 'RG' | 'email' | 'password' | 'cardId' | 'ratting'>
      & { ProfileAddress?: Maybe<Array<(
        { __typename?: 'ProfileAddress' }
        & Pick<ProfileAddress, 'id' | 'state' | 'city' | 'street' | 'neighborhood' | 'number' | 'CEP' | 'complement' | 'references'>
      )>>, ProfileContacts?: Maybe<Array<(
        { __typename?: 'ProfileContacts' }
        & Pick<ProfileContacts, 'id' | 'phone'>
      )>> }
    )>, Product?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'title' | 'subtitle' | 'amount' | 'typeNegotiation' | 'InitialValue' | 'price' | 'quantity' | 'stock' | 'categorieId' | 'description' | 'brand' | 'useState' | 'maxInstallments' | 'height' | 'width' | 'thickness' | 'material' | 'shippingWay' | 'deadline' | 'userAccountId'>
      & { UserAccount: (
        { __typename?: 'UserAccount' }
        & Pick<UserAccount, 'id' | 'name' | 'photo' | 'userTypeId' | 'cpf' | 'RG' | 'email' | 'password' | 'cardId' | 'ratting'>
        & { ProfileAddress?: Maybe<Array<(
          { __typename?: 'ProfileAddress' }
          & Pick<ProfileAddress, 'id' | 'state' | 'city' | 'street' | 'neighborhood' | 'number' | 'CEP' | 'complement' | 'references'>
        )>>, ProfileContacts?: Maybe<Array<(
          { __typename?: 'ProfileContacts' }
          & Pick<ProfileContacts, 'id' | 'phone'>
        )>> }
      ) }
    )> }
  ) }
);

export type GetAllUserSellsQueryVariables = {
  sellerId: Scalars['Float'];
};


export type GetAllUserSellsQuery = (
  { __typename?: 'Query' }
  & { getAllUserSells: Array<(
    { __typename?: 'Purchase' }
    & Pick<Purchase, 'id' | 'price' | 'statusId' | 'pagarmeId' | 'userAccountId' | 'createdAt' | 'productId'>
    & { Status?: Maybe<(
      { __typename?: 'PurchaseStatus' }
      & Pick<PurchaseStatus, 'id' | 'engName' | 'ptName'>
    )>, ChatHub?: Maybe<(
      { __typename?: 'ChatHub' }
      & Pick<ChatHub, 'id'>
    )>, UserAccount?: Maybe<(
      { __typename?: 'UserAccount' }
      & Pick<UserAccount, 'id' | 'name'>
    )>, Product?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'title' | 'subtitle' | 'amount' | 'typeNegotiation' | 'InitialValue' | 'price' | 'directValue' | 'finalOffersTime' | 'active' | 'quantity' | 'stock' | 'description' | 'categorieId' | 'brand' | 'useState' | 'maxInstallments' | 'height' | 'width' | 'thickness' | 'material' | 'shippingWay' | 'planId' | 'userAccountId'>
      & { Plan?: Maybe<(
        { __typename?: 'Plans' }
        & Pick<Plans, 'id' | 'title' | 'price'>
      )>, UserAccount: (
        { __typename?: 'UserAccount' }
        & Pick<UserAccount, 'id' | 'name' | 'photo' | 'recipientId' | 'bankAccountId' | 'userTypeId' | 'cpf' | 'RG' | 'email' | 'password' | 'cardId' | 'ratting'>
      ), ProductsRating?: Maybe<Array<(
        { __typename?: 'ProductsRating' }
        & Pick<ProductsRating, 'id' | 'rating' | 'createdAt' | 'ratingDescription' | 'userAccountId' | 'productId'>
        & { UserAccount?: Maybe<(
          { __typename?: 'UserAccount' }
          & Pick<UserAccount, 'id' | 'name' | 'photo' | 'recipientId' | 'bankAccountId' | 'userTypeId' | 'cpf' | 'RG' | 'email' | 'password' | 'cardId' | 'ratting'>
        )> }
      )>> }
    )> }
  )> }
);

export type GetAllSellsQueryVariables = {
  sellerId: Scalars['Float'];
};


export type GetAllSellsQuery = (
  { __typename?: 'Query' }
  & { getAllSells: Array<(
    { __typename?: 'Purchase' }
    & Pick<Purchase, 'id' | 'price' | 'statusId' | 'pagarmeId' | 'userAccountId' | 'createdAt' | 'installmentsNumber' | 'productId'>
    & { Status?: Maybe<(
      { __typename?: 'PurchaseStatus' }
      & Pick<PurchaseStatus, 'id' | 'engName' | 'ptName'>
    )>, UserAccount?: Maybe<(
      { __typename?: 'UserAccount' }
      & Pick<UserAccount, 'id' | 'name' | 'photo' | 'recipientId' | 'bankAccountId' | 'userTypeId' | 'cpf' | 'RG' | 'email' | 'password' | 'cardId' | 'ratting'>
    )>, Product?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'title' | 'subtitle' | 'amount' | 'typeNegotiation' | 'InitialValue' | 'price' | 'directValue' | 'finalOffersTime' | 'active' | 'quantity' | 'stock' | 'description' | 'categorieId' | 'brand' | 'useState' | 'maxInstallments' | 'height' | 'width' | 'thickness' | 'material' | 'shippingWay' | 'planId' | 'userAccountId'>
      & { Plan?: Maybe<(
        { __typename?: 'Plans' }
        & Pick<Plans, 'id' | 'title' | 'price'>
      )>, UserAccount: (
        { __typename?: 'UserAccount' }
        & Pick<UserAccount, 'id' | 'name' | 'photo' | 'recipientId' | 'bankAccountId' | 'userTypeId' | 'cpf' | 'RG' | 'email' | 'password' | 'cardId' | 'ratting'>
      ), ProductsRating?: Maybe<Array<(
        { __typename?: 'ProductsRating' }
        & Pick<ProductsRating, 'id' | 'rating' | 'createdAt' | 'ratingDescription' | 'userAccountId' | 'productId'>
        & { UserAccount?: Maybe<(
          { __typename?: 'UserAccount' }
          & Pick<UserAccount, 'id' | 'name' | 'photo' | 'recipientId' | 'bankAccountId' | 'userTypeId' | 'cpf' | 'RG' | 'email' | 'password' | 'cardId' | 'ratting'>
        )> }
      )>> }
    )>, ChatHub?: Maybe<(
      { __typename?: 'ChatHub' }
      & Pick<ChatHub, 'id' | 'title' | 'purchaseId'>
    )> }
  )> }
);

export type GetTransactionQueryVariables = {
  purchaseId: Scalars['Float'];
};


export type GetTransactionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getTransaction'>
);

export type GetPurchasesFiltersCsvQueryVariables = {
  purchaseFiltersInput: PurchaseFiltersInput;
};


export type GetPurchasesFiltersCsvQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPurchasesFiltersCsv'>
);

export type PurchaseFiltersQueryVariables = {
  purchaseFiltersInput: PurchaseFiltersInput;
};


export type PurchaseFiltersQuery = (
  { __typename?: 'Query' }
  & { purchaseFilters: Array<(
    { __typename?: 'Purchase' }
    & Pick<Purchase, 'id' | 'price' | 'productQuantity' | 'paymentMethod' | 'productShippingPrice' | 'productShippingWay' | 'BlingNfscId' | 'ticketId' | 'serviceId' | 'refuseReason' | 'pagarmeId' | 'userAccountId' | 'createdAt' | 'productId' | 'trackingCode'>
    & { Bling_Nfcs?: Maybe<(
      { __typename?: 'Bling_Nfcs' }
      & Pick<Bling_Nfcs, 'id' | 'nfcId'>
    )>, Tickets?: Maybe<(
      { __typename?: 'Tickets' }
      & Pick<Tickets, 'id' | 'ticketId' | 'url'>
    )>, UserAccount?: Maybe<(
      { __typename?: 'UserAccount' }
      & Pick<UserAccount, 'id' | 'name' | 'photo' | 'recipientId' | 'bankAccountId' | 'userTypeId' | 'cpf' | 'RG' | 'email' | 'password' | 'cardId' | 'ratting'>
      & { ProfileAddress?: Maybe<Array<(
        { __typename?: 'ProfileAddress' }
        & Pick<ProfileAddress, 'id' | 'state' | 'city' | 'addressTitle' | 'neighborhood' | 'deliveryAddress' | 'CEP' | 'complement' | 'references' | 'userAccountId' | 'street' | 'number'>
      )>>, ProfileContacts?: Maybe<Array<(
        { __typename?: 'ProfileContacts' }
        & Pick<ProfileContacts, 'id' | 'phone'>
      )>> }
    )>, Status?: Maybe<(
      { __typename?: 'PurchaseStatus' }
      & Pick<PurchaseStatus, 'id' | 'engName' | 'ptName'>
    )>, ProductVariations?: Maybe<(
      { __typename?: 'ProductVariations' }
      & Pick<ProductVariations, 'id'>
      & { ColorVariation?: Maybe<(
        { __typename?: 'ColorVariations' }
        & Pick<ColorVariations, 'id' | 'name'>
      )>, TamanhoVariations?: Maybe<(
        { __typename?: 'TamanhoVariations' }
        & Pick<TamanhoVariations, 'id' | 'name'>
      )> }
    )>, Product?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'title' | 'subtitle' | 'amount' | 'typeNegotiation' | 'InitialValue' | 'price' | 'directValue' | 'finalOffersTime' | 'active' | 'quantity' | 'stock' | 'description' | 'categorieId' | 'brand' | 'useState' | 'weight' | 'maxInstallments' | 'height' | 'width' | 'thickness' | 'material' | 'shippingWay' | 'planId' | 'userAccountId'>
      & { Plan?: Maybe<(
        { __typename?: 'Plans' }
        & Pick<Plans, 'id' | 'title' | 'price'>
      )>, UserAccount: (
        { __typename?: 'UserAccount' }
        & Pick<UserAccount, 'id' | 'name' | 'photo' | 'recipientId' | 'bankAccountId' | 'userTypeId' | 'cpf' | 'RG' | 'email' | 'password' | 'cardId' | 'ratting'>
        & { ProfileContacts?: Maybe<Array<(
          { __typename?: 'ProfileContacts' }
          & Pick<ProfileContacts, 'phone'>
        )>> }
      ), ProductsRating?: Maybe<Array<(
        { __typename?: 'ProductsRating' }
        & Pick<ProductsRating, 'id' | 'rating' | 'createdAt' | 'ratingDescription' | 'userAccountId' | 'productId'>
        & { UserAccount?: Maybe<(
          { __typename?: 'UserAccount' }
          & Pick<UserAccount, 'id' | 'name' | 'photo' | 'recipientId' | 'bankAccountId' | 'userTypeId' | 'cpf' | 'RG' | 'email' | 'password' | 'cardId' | 'ratting'>
        )> }
      )>>, ProductCategories?: Maybe<(
        { __typename?: 'ProductCategories' }
        & Pick<ProductCategories, 'id' | 'name'>
      )>, ProfileAddress?: Maybe<(
        { __typename?: 'ProfileAddress' }
        & Pick<ProfileAddress, 'id' | 'state' | 'city' | 'addressTitle' | 'neighborhood' | 'deliveryAddress' | 'CEP' | 'complement' | 'references' | 'userAccountId' | 'street' | 'number'>
      )> }
    )>, ProfileAddress?: Maybe<(
      { __typename?: 'ProfileAddress' }
      & Pick<ProfileAddress, 'id' | 'state' | 'city' | 'addressTitle' | 'neighborhood' | 'deliveryAddress' | 'CEP' | 'complement' | 'references' | 'userAccountId' | 'street' | 'number'>
    )>, PurchaseInvoice?: Maybe<(
      { __typename?: 'PurchaseInvoice' }
      & Pick<PurchaseInvoice, 'id' | 'name' | 'path'>
    )> }
  )> }
);

export type PutTrackingCodeMutationVariables = {
  putTrackingCodeInputData: PutTrackingCodeInputData;
};


export type PutTrackingCodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'putTrackingCode'>
);

export type GetAllPurchaseStatusQueryVariables = {};


export type GetAllPurchaseStatusQuery = (
  { __typename?: 'Query' }
  & { getAllPurchaseStatus: Array<(
    { __typename?: 'PurchaseStatus' }
    & Pick<PurchaseStatus, 'id' | 'engName' | 'ptName'>
  )> }
);

export type ReportPurchaseProblemMutationVariables = {
  purchaseId: Scalars['Float'];
  email: Scalars['String'];
  type: Scalars['String'];
  description: Scalars['String'];
};


export type ReportPurchaseProblemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reportPurchaseProblem'>
);

export type GetPurchaseSlipQueryVariables = {
  purchaseId: Scalars['Float'];
};


export type GetPurchaseSlipQuery = (
  { __typename?: 'Query' }
  & { getPurchaseSlip: (
    { __typename?: 'SlipData' }
    & Pick<SlipData, 'slip_url'>
  ) }
);


export const GetChatHubDocument = gql`
    query getChatHub($userId: Float!, $productId: Float!) {
  getChatHub(userId: $userId, productId: $productId) {
    id
    title
    purchaseId
  }
}
    `;

/**
 * __useGetChatHubQuery__
 *
 * To run a query within a React component, call `useGetChatHubQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatHubQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatHubQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetChatHubQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetChatHubQuery, GetChatHubQueryVariables>) {
        return ApolloReactHooks.useQuery<GetChatHubQuery, GetChatHubQueryVariables>(GetChatHubDocument, baseOptions);
      }
export function useGetChatHubLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetChatHubQuery, GetChatHubQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetChatHubQuery, GetChatHubQueryVariables>(GetChatHubDocument, baseOptions);
        }
export type GetChatHubQueryHookResult = ReturnType<typeof useGetChatHubQuery>;
export type GetChatHubLazyQueryHookResult = ReturnType<typeof useGetChatHubLazyQuery>;
export type GetChatHubQueryResult = ApolloReactCommon.QueryResult<GetChatHubQuery, GetChatHubQueryVariables>;
export const GetAllChatHubsDocument = gql`
    query getAllChatHubs($productId: Float!) {
  getAllChatHubs(productId: $productId) {
    id
    UserAccount {
      id
      name
      email
    }
    ChatHub {
      id
      title
      ChatHubMessages {
        id
        message
        UserAccount {
          id
          name
          email
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllChatHubsQuery__
 *
 * To run a query within a React component, call `useGetAllChatHubsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllChatHubsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllChatHubsQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetAllChatHubsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllChatHubsQuery, GetAllChatHubsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllChatHubsQuery, GetAllChatHubsQueryVariables>(GetAllChatHubsDocument, baseOptions);
      }
export function useGetAllChatHubsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllChatHubsQuery, GetAllChatHubsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllChatHubsQuery, GetAllChatHubsQueryVariables>(GetAllChatHubsDocument, baseOptions);
        }
export type GetAllChatHubsQueryHookResult = ReturnType<typeof useGetAllChatHubsQuery>;
export type GetAllChatHubsLazyQueryHookResult = ReturnType<typeof useGetAllChatHubsLazyQuery>;
export type GetAllChatHubsQueryResult = ApolloReactCommon.QueryResult<GetAllChatHubsQuery, GetAllChatHubsQueryVariables>;
export const CreateChatHubForFreeComunicationDocument = gql`
    mutation createChatHubForFreeComunication($productId: Float!, $userId: Float!) {
  createChatHubForFreeComunication(productId: $productId, userId: $userId) {
    id
    Purchase {
      id
      Product {
        id
      }
    }
  }
}
    `;
export type CreateChatHubForFreeComunicationMutationFn = ApolloReactCommon.MutationFunction<CreateChatHubForFreeComunicationMutation, CreateChatHubForFreeComunicationMutationVariables>;

/**
 * __useCreateChatHubForFreeComunicationMutation__
 *
 * To run a mutation, you first call `useCreateChatHubForFreeComunicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatHubForFreeComunicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatHubForFreeComunicationMutation, { data, loading, error }] = useCreateChatHubForFreeComunicationMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateChatHubForFreeComunicationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateChatHubForFreeComunicationMutation, CreateChatHubForFreeComunicationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateChatHubForFreeComunicationMutation, CreateChatHubForFreeComunicationMutationVariables>(CreateChatHubForFreeComunicationDocument, baseOptions);
      }
export type CreateChatHubForFreeComunicationMutationHookResult = ReturnType<typeof useCreateChatHubForFreeComunicationMutation>;
export type CreateChatHubForFreeComunicationMutationResult = ApolloReactCommon.MutationResult<CreateChatHubForFreeComunicationMutation>;
export type CreateChatHubForFreeComunicationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateChatHubForFreeComunicationMutation, CreateChatHubForFreeComunicationMutationVariables>;
export const CreateCupomDocument = gql`
    mutation createCupom($createCupomInputData: CreateCupomInputData!) {
  createCupom(createCupomInputData: $createCupomInputData)
}
    `;
export type CreateCupomMutationFn = ApolloReactCommon.MutationFunction<CreateCupomMutation, CreateCupomMutationVariables>;

/**
 * __useCreateCupomMutation__
 *
 * To run a mutation, you first call `useCreateCupomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCupomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCupomMutation, { data, loading, error }] = useCreateCupomMutation({
 *   variables: {
 *      createCupomInputData: // value for 'createCupomInputData'
 *   },
 * });
 */
export function useCreateCupomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCupomMutation, CreateCupomMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCupomMutation, CreateCupomMutationVariables>(CreateCupomDocument, baseOptions);
      }
export type CreateCupomMutationHookResult = ReturnType<typeof useCreateCupomMutation>;
export type CreateCupomMutationResult = ApolloReactCommon.MutationResult<CreateCupomMutation>;
export type CreateCupomMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCupomMutation, CreateCupomMutationVariables>;
export const GetAllCuponsbyUserIdDocument = gql`
    query getAllCuponsbyUserId($getAllCuponsbyUserId: GetAllCupomByUserIdInputData!) {
  getAllCuponsbyUserId(getAllCupomByUserIdInputData: $getAllCuponsbyUserId) {
    id
    code
    userId
    validade
    desconto
  }
}
    `;

/**
 * __useGetAllCuponsbyUserIdQuery__
 *
 * To run a query within a React component, call `useGetAllCuponsbyUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCuponsbyUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCuponsbyUserIdQuery({
 *   variables: {
 *      getAllCuponsbyUserId: // value for 'getAllCuponsbyUserId'
 *   },
 * });
 */
export function useGetAllCuponsbyUserIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllCuponsbyUserIdQuery, GetAllCuponsbyUserIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllCuponsbyUserIdQuery, GetAllCuponsbyUserIdQueryVariables>(GetAllCuponsbyUserIdDocument, baseOptions);
      }
export function useGetAllCuponsbyUserIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllCuponsbyUserIdQuery, GetAllCuponsbyUserIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllCuponsbyUserIdQuery, GetAllCuponsbyUserIdQueryVariables>(GetAllCuponsbyUserIdDocument, baseOptions);
        }
export type GetAllCuponsbyUserIdQueryHookResult = ReturnType<typeof useGetAllCuponsbyUserIdQuery>;
export type GetAllCuponsbyUserIdLazyQueryHookResult = ReturnType<typeof useGetAllCuponsbyUserIdLazyQuery>;
export type GetAllCuponsbyUserIdQueryResult = ApolloReactCommon.QueryResult<GetAllCuponsbyUserIdQuery, GetAllCuponsbyUserIdQueryVariables>;
export const DeleteCupomDocument = gql`
    mutation deleteCupom($deleteCupomInputData: DeleteCupomInputData!) {
  deleteCupom(deleteCupomInputData: $deleteCupomInputData)
}
    `;
export type DeleteCupomMutationFn = ApolloReactCommon.MutationFunction<DeleteCupomMutation, DeleteCupomMutationVariables>;

/**
 * __useDeleteCupomMutation__
 *
 * To run a mutation, you first call `useDeleteCupomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCupomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCupomMutation, { data, loading, error }] = useDeleteCupomMutation({
 *   variables: {
 *      deleteCupomInputData: // value for 'deleteCupomInputData'
 *   },
 * });
 */
export function useDeleteCupomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCupomMutation, DeleteCupomMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCupomMutation, DeleteCupomMutationVariables>(DeleteCupomDocument, baseOptions);
      }
export type DeleteCupomMutationHookResult = ReturnType<typeof useDeleteCupomMutation>;
export type DeleteCupomMutationResult = ApolloReactCommon.MutationResult<DeleteCupomMutation>;
export type DeleteCupomMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCupomMutation, DeleteCupomMutationVariables>;
export const GetAllCupomProductsByCupomIdDocument = gql`
    query getAllCupomProductsByCupomId($getAllCupomProductsByCupomId: GetAllCupomProductsByCupomIdInputData!) {
  getAllCupomProductsByCupomId(
    getAllCupomProductsByCupomIdInputData: $getAllCupomProductsByCupomId
  ) {
    id
    cupomId
    productId
    Product {
      title
      id
    }
  }
}
    `;

/**
 * __useGetAllCupomProductsByCupomIdQuery__
 *
 * To run a query within a React component, call `useGetAllCupomProductsByCupomIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCupomProductsByCupomIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCupomProductsByCupomIdQuery({
 *   variables: {
 *      getAllCupomProductsByCupomId: // value for 'getAllCupomProductsByCupomId'
 *   },
 * });
 */
export function useGetAllCupomProductsByCupomIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllCupomProductsByCupomIdQuery, GetAllCupomProductsByCupomIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllCupomProductsByCupomIdQuery, GetAllCupomProductsByCupomIdQueryVariables>(GetAllCupomProductsByCupomIdDocument, baseOptions);
      }
export function useGetAllCupomProductsByCupomIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllCupomProductsByCupomIdQuery, GetAllCupomProductsByCupomIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllCupomProductsByCupomIdQuery, GetAllCupomProductsByCupomIdQueryVariables>(GetAllCupomProductsByCupomIdDocument, baseOptions);
        }
export type GetAllCupomProductsByCupomIdQueryHookResult = ReturnType<typeof useGetAllCupomProductsByCupomIdQuery>;
export type GetAllCupomProductsByCupomIdLazyQueryHookResult = ReturnType<typeof useGetAllCupomProductsByCupomIdLazyQuery>;
export type GetAllCupomProductsByCupomIdQueryResult = ApolloReactCommon.QueryResult<GetAllCupomProductsByCupomIdQuery, GetAllCupomProductsByCupomIdQueryVariables>;
export const GetCupomDocument = gql`
    query getCupom($getCupomInputData: GetCupomInputData!) {
  getCupom(getCupom: $getCupomInputData) {
    id
    userId
    code
    desconto
    validade
  }
}
    `;

/**
 * __useGetCupomQuery__
 *
 * To run a query within a React component, call `useGetCupomQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCupomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCupomQuery({
 *   variables: {
 *      getCupomInputData: // value for 'getCupomInputData'
 *   },
 * });
 */
export function useGetCupomQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCupomQuery, GetCupomQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCupomQuery, GetCupomQueryVariables>(GetCupomDocument, baseOptions);
      }
export function useGetCupomLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCupomQuery, GetCupomQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCupomQuery, GetCupomQueryVariables>(GetCupomDocument, baseOptions);
        }
export type GetCupomQueryHookResult = ReturnType<typeof useGetCupomQuery>;
export type GetCupomLazyQueryHookResult = ReturnType<typeof useGetCupomLazyQuery>;
export type GetCupomQueryResult = ApolloReactCommon.QueryResult<GetCupomQuery, GetCupomQueryVariables>;
export const EditCupomDocument = gql`
    mutation editCupom($editCupomInputData: EditCupomInputData!) {
  editCupom(editCupomInputData: $editCupomInputData)
}
    `;
export type EditCupomMutationFn = ApolloReactCommon.MutationFunction<EditCupomMutation, EditCupomMutationVariables>;

/**
 * __useEditCupomMutation__
 *
 * To run a mutation, you first call `useEditCupomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCupomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCupomMutation, { data, loading, error }] = useEditCupomMutation({
 *   variables: {
 *      editCupomInputData: // value for 'editCupomInputData'
 *   },
 * });
 */
export function useEditCupomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditCupomMutation, EditCupomMutationVariables>) {
        return ApolloReactHooks.useMutation<EditCupomMutation, EditCupomMutationVariables>(EditCupomDocument, baseOptions);
      }
export type EditCupomMutationHookResult = ReturnType<typeof useEditCupomMutation>;
export type EditCupomMutationResult = ApolloReactCommon.MutationResult<EditCupomMutation>;
export type EditCupomMutationOptions = ApolloReactCommon.BaseMutationOptions<EditCupomMutation, EditCupomMutationVariables>;
export const AplicarCuponDescontoDocument = gql`
    query aplicarCuponDesconto($aplicarDescontoInputData: AplicarDescontoInputData!) {
  aplicarCuponDesconto(aplicarDescontoInputData: $aplicarDescontoInputData) {
    id
    desconto
  }
}
    `;

/**
 * __useAplicarCuponDescontoQuery__
 *
 * To run a query within a React component, call `useAplicarCuponDescontoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAplicarCuponDescontoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAplicarCuponDescontoQuery({
 *   variables: {
 *      aplicarDescontoInputData: // value for 'aplicarDescontoInputData'
 *   },
 * });
 */
export function useAplicarCuponDescontoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AplicarCuponDescontoQuery, AplicarCuponDescontoQueryVariables>) {
        return ApolloReactHooks.useQuery<AplicarCuponDescontoQuery, AplicarCuponDescontoQueryVariables>(AplicarCuponDescontoDocument, baseOptions);
      }
export function useAplicarCuponDescontoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AplicarCuponDescontoQuery, AplicarCuponDescontoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AplicarCuponDescontoQuery, AplicarCuponDescontoQueryVariables>(AplicarCuponDescontoDocument, baseOptions);
        }
export type AplicarCuponDescontoQueryHookResult = ReturnType<typeof useAplicarCuponDescontoQuery>;
export type AplicarCuponDescontoLazyQueryHookResult = ReturnType<typeof useAplicarCuponDescontoLazyQuery>;
export type AplicarCuponDescontoQueryResult = ApolloReactCommon.QueryResult<AplicarCuponDescontoQuery, AplicarCuponDescontoQueryVariables>;
export const CreateDinamicSliderDocument = gql`
    mutation createDinamicSlider($createDinamicSliderInput: CreateDinamicSliderInput!) {
  createDinamicSlider(CreateDinamicSliderInput: $createDinamicSliderInput) {
    id
  }
}
    `;
export type CreateDinamicSliderMutationFn = ApolloReactCommon.MutationFunction<CreateDinamicSliderMutation, CreateDinamicSliderMutationVariables>;

/**
 * __useCreateDinamicSliderMutation__
 *
 * To run a mutation, you first call `useCreateDinamicSliderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDinamicSliderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDinamicSliderMutation, { data, loading, error }] = useCreateDinamicSliderMutation({
 *   variables: {
 *      createDinamicSliderInput: // value for 'createDinamicSliderInput'
 *   },
 * });
 */
export function useCreateDinamicSliderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDinamicSliderMutation, CreateDinamicSliderMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDinamicSliderMutation, CreateDinamicSliderMutationVariables>(CreateDinamicSliderDocument, baseOptions);
      }
export type CreateDinamicSliderMutationHookResult = ReturnType<typeof useCreateDinamicSliderMutation>;
export type CreateDinamicSliderMutationResult = ApolloReactCommon.MutationResult<CreateDinamicSliderMutation>;
export type CreateDinamicSliderMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDinamicSliderMutation, CreateDinamicSliderMutationVariables>;
export const CreateDinamicSliderItemDocument = gql`
    mutation createDinamicSliderItem($CreateDinamicSliderItemInput: CreateDinamicSliderItemInput!) {
  createDinamicSliderItem(
    createDinamicSliderItemInput: $CreateDinamicSliderItemInput
  ) {
    title
  }
}
    `;
export type CreateDinamicSliderItemMutationFn = ApolloReactCommon.MutationFunction<CreateDinamicSliderItemMutation, CreateDinamicSliderItemMutationVariables>;

/**
 * __useCreateDinamicSliderItemMutation__
 *
 * To run a mutation, you first call `useCreateDinamicSliderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDinamicSliderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDinamicSliderItemMutation, { data, loading, error }] = useCreateDinamicSliderItemMutation({
 *   variables: {
 *      CreateDinamicSliderItemInput: // value for 'CreateDinamicSliderItemInput'
 *   },
 * });
 */
export function useCreateDinamicSliderItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDinamicSliderItemMutation, CreateDinamicSliderItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDinamicSliderItemMutation, CreateDinamicSliderItemMutationVariables>(CreateDinamicSliderItemDocument, baseOptions);
      }
export type CreateDinamicSliderItemMutationHookResult = ReturnType<typeof useCreateDinamicSliderItemMutation>;
export type CreateDinamicSliderItemMutationResult = ApolloReactCommon.MutationResult<CreateDinamicSliderItemMutation>;
export type CreateDinamicSliderItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDinamicSliderItemMutation, CreateDinamicSliderItemMutationVariables>;
export const GetDinamicSliderDocument = gql`
    query getDinamicSlider($GetDinamicSliderInput: GetDinamicSliderInput!) {
  getDinamicSlider(getDinamicSliderInput: $GetDinamicSliderInput) {
    title
    dateStart
    dateEnd
    DinamicSlidersItens {
      id
      productId
      Product {
        id
        title
        subtitle
        amount
        typeNegotiation
        InitialValue
        price
        quantity
        stock
        categorieId
        numberOfPhotos
        description
        active
        isSocialProject
        brand
        useState
        maxInstallments
        planId
        height
        width
        thickness
        material
        shippingWay
        deadline
        freeDelivery
        ProductsBlackList {
          id
          productId
        }
      }
    }
  }
}
    `;

/**
 * __useGetDinamicSliderQuery__
 *
 * To run a query within a React component, call `useGetDinamicSliderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDinamicSliderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDinamicSliderQuery({
 *   variables: {
 *      GetDinamicSliderInput: // value for 'GetDinamicSliderInput'
 *   },
 * });
 */
export function useGetDinamicSliderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDinamicSliderQuery, GetDinamicSliderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDinamicSliderQuery, GetDinamicSliderQueryVariables>(GetDinamicSliderDocument, baseOptions);
      }
export function useGetDinamicSliderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDinamicSliderQuery, GetDinamicSliderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDinamicSliderQuery, GetDinamicSliderQueryVariables>(GetDinamicSliderDocument, baseOptions);
        }
export type GetDinamicSliderQueryHookResult = ReturnType<typeof useGetDinamicSliderQuery>;
export type GetDinamicSliderLazyQueryHookResult = ReturnType<typeof useGetDinamicSliderLazyQuery>;
export type GetDinamicSliderQueryResult = ApolloReactCommon.QueryResult<GetDinamicSliderQuery, GetDinamicSliderQueryVariables>;
export const UpdateDinamicSliderDocument = gql`
    mutation updateDinamicSlider($updateDinamicSliderInput: UpdateDinamicSliderInput!) {
  updateDinamicSlider(updateDinamicSliderInput: $updateDinamicSliderInput) {
    id
    title
    dateStart
    dateEnd
  }
}
    `;
export type UpdateDinamicSliderMutationFn = ApolloReactCommon.MutationFunction<UpdateDinamicSliderMutation, UpdateDinamicSliderMutationVariables>;

/**
 * __useUpdateDinamicSliderMutation__
 *
 * To run a mutation, you first call `useUpdateDinamicSliderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDinamicSliderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDinamicSliderMutation, { data, loading, error }] = useUpdateDinamicSliderMutation({
 *   variables: {
 *      updateDinamicSliderInput: // value for 'updateDinamicSliderInput'
 *   },
 * });
 */
export function useUpdateDinamicSliderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDinamicSliderMutation, UpdateDinamicSliderMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDinamicSliderMutation, UpdateDinamicSliderMutationVariables>(UpdateDinamicSliderDocument, baseOptions);
      }
export type UpdateDinamicSliderMutationHookResult = ReturnType<typeof useUpdateDinamicSliderMutation>;
export type UpdateDinamicSliderMutationResult = ApolloReactCommon.MutationResult<UpdateDinamicSliderMutation>;
export type UpdateDinamicSliderMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDinamicSliderMutation, UpdateDinamicSliderMutationVariables>;
export const DeleteDinamicSliderItemDocument = gql`
    mutation deleteDinamicSliderItem($productId: Float!) {
  deleteDinamicSliderItem(productId: $productId)
}
    `;
export type DeleteDinamicSliderItemMutationFn = ApolloReactCommon.MutationFunction<DeleteDinamicSliderItemMutation, DeleteDinamicSliderItemMutationVariables>;

/**
 * __useDeleteDinamicSliderItemMutation__
 *
 * To run a mutation, you first call `useDeleteDinamicSliderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDinamicSliderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDinamicSliderItemMutation, { data, loading, error }] = useDeleteDinamicSliderItemMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useDeleteDinamicSliderItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDinamicSliderItemMutation, DeleteDinamicSliderItemMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDinamicSliderItemMutation, DeleteDinamicSliderItemMutationVariables>(DeleteDinamicSliderItemDocument, baseOptions);
      }
export type DeleteDinamicSliderItemMutationHookResult = ReturnType<typeof useDeleteDinamicSliderItemMutation>;
export type DeleteDinamicSliderItemMutationResult = ApolloReactCommon.MutationResult<DeleteDinamicSliderItemMutation>;
export type DeleteDinamicSliderItemMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDinamicSliderItemMutation, DeleteDinamicSliderItemMutationVariables>;
export const GetAllGroundsForReportDocument = gql`
    query getAllGroundsForReport {
  getAllGroundsForReport {
    id
    title
    description
  }
}
    `;

/**
 * __useGetAllGroundsForReportQuery__
 *
 * To run a query within a React component, call `useGetAllGroundsForReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllGroundsForReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllGroundsForReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllGroundsForReportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllGroundsForReportQuery, GetAllGroundsForReportQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllGroundsForReportQuery, GetAllGroundsForReportQueryVariables>(GetAllGroundsForReportDocument, baseOptions);
      }
export function useGetAllGroundsForReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllGroundsForReportQuery, GetAllGroundsForReportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllGroundsForReportQuery, GetAllGroundsForReportQueryVariables>(GetAllGroundsForReportDocument, baseOptions);
        }
export type GetAllGroundsForReportQueryHookResult = ReturnType<typeof useGetAllGroundsForReportQuery>;
export type GetAllGroundsForReportLazyQueryHookResult = ReturnType<typeof useGetAllGroundsForReportLazyQuery>;
export type GetAllGroundsForReportQueryResult = ApolloReactCommon.QueryResult<GetAllGroundsForReportQuery, GetAllGroundsForReportQueryVariables>;
export const CreateProductDocument = gql`
    mutation createProduct($title: String!, $subtitle: String, $amount: Float, $typeNegotiation: String!, $InitialValue: Float, $price: Float, $quantity: Float!, $stock: Float, $height: Float, $width: Float, $thickness: Float, $description: String!, $brand: String, $useState: String!, $numberOfPhotos: Float!, $maxInstallments: Float, $material: String, $userAccountId: Float!, $shippingWay: String!, $categorieId: Float!, $planId: Float, $directValue: Float, $finalOffersTime: DateTime, $propertyInformation: Boolean!, $vehicleInformation: Boolean!, $cep: String, $street: String, $number: String, $properyType: String, $rooms: String, $bathrooms: String, $garage: String, $vehicleBrand: String, $model: String, $year: String, $motorPower: String, $mileage: String, $fuel: String, $licensePlate: String, $color: String, $numberDoors: String, $directionType: String, $exchange: String, $profileAddressId: Float, $withdraw: String, $format: String, $complement: String, $state: String, $neighborhood: String, $city: String, $weight: String, $subCategorieId: Float, $isSocialProject: Boolean!, $product_height: Float, $product_width: Float, $product_length: Float, $product_weight: Float, $prompt_delivery: Boolean, $parcelasRestantes: Float, $valorRestantes: Float, $mesesDeContrato: Float, $quitado: Boolean, $variacoes: [Variacoes!], $deadline: Float, $freeDelivery: Boolean) {
  createProduct(
    productInput: {title: $title, subtitle: $subtitle, numberOfPhotos: $numberOfPhotos, isSocialProject: $isSocialProject, typeNegotiation: $typeNegotiation, InitialValue: $InitialValue, price: $price, quantity: $quantity, categorieId: $categorieId, stock: $stock, description: $description, brand: $brand, useState: $useState, height: $height, width: $width, thickness: $thickness, maxInstallments: $maxInstallments, material: $material, userAccountId: $userAccountId, shippingWay: $shippingWay, amount: $amount, planId: $planId, parcelasRestantes: $parcelasRestantes, valorRestantes: $valorRestantes, mesesDeContrato: $mesesDeContrato, quitado: $quitado, directValue: $directValue, finalOffersTime: $finalOffersTime, propertyInformation: $propertyInformation, vehicleInformation: $vehicleInformation, cep: $cep, street: $street, complement: $complement, state: $state, neighborhood: $neighborhood, number: $number, city: $city, properyType: $properyType, rooms: $rooms, bathrooms: $bathrooms, garage: $garage, vehicleBrand: $vehicleBrand, model: $model, year: $year, motorPower: $motorPower, mileage: $mileage, fuel: $fuel, licensePlate: $licensePlate, color: $color, numberDoors: $numberDoors, directionType: $directionType, exchange: $exchange, profileAddressId: $profileAddressId, withdraw: $withdraw, format: $format, weight: $weight, subCategorieId: $subCategorieId, product_height: $product_height, product_width: $product_width, product_length: $product_length, product_weight: $product_weight, prompt_delivery: $prompt_delivery, variacoes: $variacoes, deadline: $deadline, freeDelivery: $freeDelivery}
  ) {
    id
  }
}
    `;
export type CreateProductMutationFn = ApolloReactCommon.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      title: // value for 'title'
 *      subtitle: // value for 'subtitle'
 *      amount: // value for 'amount'
 *      typeNegotiation: // value for 'typeNegotiation'
 *      InitialValue: // value for 'InitialValue'
 *      price: // value for 'price'
 *      quantity: // value for 'quantity'
 *      stock: // value for 'stock'
 *      height: // value for 'height'
 *      width: // value for 'width'
 *      thickness: // value for 'thickness'
 *      description: // value for 'description'
 *      brand: // value for 'brand'
 *      useState: // value for 'useState'
 *      numberOfPhotos: // value for 'numberOfPhotos'
 *      maxInstallments: // value for 'maxInstallments'
 *      material: // value for 'material'
 *      userAccountId: // value for 'userAccountId'
 *      shippingWay: // value for 'shippingWay'
 *      categorieId: // value for 'categorieId'
 *      planId: // value for 'planId'
 *      directValue: // value for 'directValue'
 *      finalOffersTime: // value for 'finalOffersTime'
 *      propertyInformation: // value for 'propertyInformation'
 *      vehicleInformation: // value for 'vehicleInformation'
 *      cep: // value for 'cep'
 *      street: // value for 'street'
 *      number: // value for 'number'
 *      properyType: // value for 'properyType'
 *      rooms: // value for 'rooms'
 *      bathrooms: // value for 'bathrooms'
 *      garage: // value for 'garage'
 *      vehicleBrand: // value for 'vehicleBrand'
 *      model: // value for 'model'
 *      year: // value for 'year'
 *      motorPower: // value for 'motorPower'
 *      mileage: // value for 'mileage'
 *      fuel: // value for 'fuel'
 *      licensePlate: // value for 'licensePlate'
 *      color: // value for 'color'
 *      numberDoors: // value for 'numberDoors'
 *      directionType: // value for 'directionType'
 *      exchange: // value for 'exchange'
 *      profileAddressId: // value for 'profileAddressId'
 *      withdraw: // value for 'withdraw'
 *      format: // value for 'format'
 *      complement: // value for 'complement'
 *      state: // value for 'state'
 *      neighborhood: // value for 'neighborhood'
 *      city: // value for 'city'
 *      weight: // value for 'weight'
 *      subCategorieId: // value for 'subCategorieId'
 *      isSocialProject: // value for 'isSocialProject'
 *      product_height: // value for 'product_height'
 *      product_width: // value for 'product_width'
 *      product_length: // value for 'product_length'
 *      product_weight: // value for 'product_weight'
 *      prompt_delivery: // value for 'prompt_delivery'
 *      parcelasRestantes: // value for 'parcelasRestantes'
 *      valorRestantes: // value for 'valorRestantes'
 *      mesesDeContrato: // value for 'mesesDeContrato'
 *      quitado: // value for 'quitado'
 *      variacoes: // value for 'variacoes'
 *      deadline: // value for 'deadline'
 *      freeDelivery: // value for 'freeDelivery'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, baseOptions);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = ApolloReactCommon.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const CreateProductPaidDocument = gql`
    mutation createProductPaid($userId: Float!, $numInstallments: Float!, $payment_method: String!, $cardId: String!, $product: ProductInput!) {
  createProductPaid(
    createProductPaidInput: {userId: $userId, numInstallments: $numInstallments, payment_method: $payment_method, cardId: $cardId, product: $product}
  ) {
    id
  }
}
    `;
export type CreateProductPaidMutationFn = ApolloReactCommon.MutationFunction<CreateProductPaidMutation, CreateProductPaidMutationVariables>;

/**
 * __useCreateProductPaidMutation__
 *
 * To run a mutation, you first call `useCreateProductPaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductPaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductPaidMutation, { data, loading, error }] = useCreateProductPaidMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      numInstallments: // value for 'numInstallments'
 *      payment_method: // value for 'payment_method'
 *      cardId: // value for 'cardId'
 *      product: // value for 'product'
 *   },
 * });
 */
export function useCreateProductPaidMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProductPaidMutation, CreateProductPaidMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProductPaidMutation, CreateProductPaidMutationVariables>(CreateProductPaidDocument, baseOptions);
      }
export type CreateProductPaidMutationHookResult = ReturnType<typeof useCreateProductPaidMutation>;
export type CreateProductPaidMutationResult = ApolloReactCommon.MutationResult<CreateProductPaidMutation>;
export type CreateProductPaidMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProductPaidMutation, CreateProductPaidMutationVariables>;
export const CreateProductImageDocument = gql`
    mutation createProductImage($ProductId: Float!, $PictureNumber: Float!, $Picture: [Upload!]!) {
  createProductImage(
    ProductId: $ProductId
    PictureNumber: $PictureNumber
    Picture: $Picture
  )
}
    `;
export type CreateProductImageMutationFn = ApolloReactCommon.MutationFunction<CreateProductImageMutation, CreateProductImageMutationVariables>;

/**
 * __useCreateProductImageMutation__
 *
 * To run a mutation, you first call `useCreateProductImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductImageMutation, { data, loading, error }] = useCreateProductImageMutation({
 *   variables: {
 *      ProductId: // value for 'ProductId'
 *      PictureNumber: // value for 'PictureNumber'
 *      Picture: // value for 'Picture'
 *   },
 * });
 */
export function useCreateProductImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProductImageMutation, CreateProductImageMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProductImageMutation, CreateProductImageMutationVariables>(CreateProductImageDocument, baseOptions);
      }
export type CreateProductImageMutationHookResult = ReturnType<typeof useCreateProductImageMutation>;
export type CreateProductImageMutationResult = ApolloReactCommon.MutationResult<CreateProductImageMutation>;
export type CreateProductImageMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProductImageMutation, CreateProductImageMutationVariables>;
export const AddVisitsProductDocument = gql`
    mutation addVisitsProduct($codeUserView: String!, $productId: Float!) {
  addVisitsProduct(codeUserView: $codeUserView, productId: $productId)
}
    `;
export type AddVisitsProductMutationFn = ApolloReactCommon.MutationFunction<AddVisitsProductMutation, AddVisitsProductMutationVariables>;

/**
 * __useAddVisitsProductMutation__
 *
 * To run a mutation, you first call `useAddVisitsProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVisitsProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVisitsProductMutation, { data, loading, error }] = useAddVisitsProductMutation({
 *   variables: {
 *      codeUserView: // value for 'codeUserView'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useAddVisitsProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddVisitsProductMutation, AddVisitsProductMutationVariables>) {
        return ApolloReactHooks.useMutation<AddVisitsProductMutation, AddVisitsProductMutationVariables>(AddVisitsProductDocument, baseOptions);
      }
export type AddVisitsProductMutationHookResult = ReturnType<typeof useAddVisitsProductMutation>;
export type AddVisitsProductMutationResult = ApolloReactCommon.MutationResult<AddVisitsProductMutation>;
export type AddVisitsProductMutationOptions = ApolloReactCommon.BaseMutationOptions<AddVisitsProductMutation, AddVisitsProductMutationVariables>;
export const GetAllProductsDocument = gql`
    query getAllProducts($getAllProductInput: GetAllProductInput!) {
  getAllProducts(getAllProductInput: $getAllProductInput) {
    id
    title
    subtitle
    amount
    typeNegotiation
    InitialValue
    price
    quantity
    stock
    categorieId
    numberOfPhotos
    description
    active
    isSocialProject
    brand
    useState
    maxInstallments
    planId
    height
    width
    thickness
    material
    shippingWay
    deadline
    freeDelivery
    ProductsBlackList {
      id
      productId
    }
    VehicleInformations {
      id
      vehicleBrand
      year
      motorPower
      mileage
      fuel
      directionType
      quitado
      parcelasRestantes
      valorRestante
      mesesDeContrato
      productId
      numberDoors
      productId
    }
    PropertyInformations {
      id
      cep
      number
      bathrooms
      garage
      productId
      quitado
      mesesDeContrato
      valorRestante
      parcelasRestantes
      city
      neighborhood
      state
      complement
    }
    UserAccount {
      id
      name
      userTypeId
      email
      ratting
      ProfileContacts {
        id
        phone
      }
      ProfileAddress {
        id
        city
        CEP
        userAccountId
        state
      }
    }
  }
}
    `;

/**
 * __useGetAllProductsQuery__
 *
 * To run a query within a React component, call `useGetAllProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProductsQuery({
 *   variables: {
 *      getAllProductInput: // value for 'getAllProductInput'
 *   },
 * });
 */
export function useGetAllProductsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllProductsQuery, GetAllProductsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllProductsQuery, GetAllProductsQueryVariables>(GetAllProductsDocument, baseOptions);
      }
export function useGetAllProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllProductsQuery, GetAllProductsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllProductsQuery, GetAllProductsQueryVariables>(GetAllProductsDocument, baseOptions);
        }
export type GetAllProductsQueryHookResult = ReturnType<typeof useGetAllProductsQuery>;
export type GetAllProductsLazyQueryHookResult = ReturnType<typeof useGetAllProductsLazyQuery>;
export type GetAllProductsQueryResult = ApolloReactCommon.QueryResult<GetAllProductsQuery, GetAllProductsQueryVariables>;
export const GetProductByUserIdCsvDocument = gql`
    query getProductByUserIdCsv($getProductByUserIdCsv: GetProductByUserId!) {
  getProductByUserIdCsv(getProductByUserId: $getProductByUserIdCsv)
}
    `;

/**
 * __useGetProductByUserIdCsvQuery__
 *
 * To run a query within a React component, call `useGetProductByUserIdCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductByUserIdCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductByUserIdCsvQuery({
 *   variables: {
 *      getProductByUserIdCsv: // value for 'getProductByUserIdCsv'
 *   },
 * });
 */
export function useGetProductByUserIdCsvQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProductByUserIdCsvQuery, GetProductByUserIdCsvQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProductByUserIdCsvQuery, GetProductByUserIdCsvQueryVariables>(GetProductByUserIdCsvDocument, baseOptions);
      }
export function useGetProductByUserIdCsvLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProductByUserIdCsvQuery, GetProductByUserIdCsvQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProductByUserIdCsvQuery, GetProductByUserIdCsvQueryVariables>(GetProductByUserIdCsvDocument, baseOptions);
        }
export type GetProductByUserIdCsvQueryHookResult = ReturnType<typeof useGetProductByUserIdCsvQuery>;
export type GetProductByUserIdCsvLazyQueryHookResult = ReturnType<typeof useGetProductByUserIdCsvLazyQuery>;
export type GetProductByUserIdCsvQueryResult = ApolloReactCommon.QueryResult<GetProductByUserIdCsvQuery, GetProductByUserIdCsvQueryVariables>;
export const GetAllUserViewsProductByProductIdDocument = gql`
    query getAllUserViewsProductByProductId($productId: String!) {
  getAllUserViewsProductByProductId(productId: $productId)
}
    `;

/**
 * __useGetAllUserViewsProductByProductIdQuery__
 *
 * To run a query within a React component, call `useGetAllUserViewsProductByProductIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUserViewsProductByProductIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUserViewsProductByProductIdQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetAllUserViewsProductByProductIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllUserViewsProductByProductIdQuery, GetAllUserViewsProductByProductIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllUserViewsProductByProductIdQuery, GetAllUserViewsProductByProductIdQueryVariables>(GetAllUserViewsProductByProductIdDocument, baseOptions);
      }
export function useGetAllUserViewsProductByProductIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllUserViewsProductByProductIdQuery, GetAllUserViewsProductByProductIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllUserViewsProductByProductIdQuery, GetAllUserViewsProductByProductIdQueryVariables>(GetAllUserViewsProductByProductIdDocument, baseOptions);
        }
export type GetAllUserViewsProductByProductIdQueryHookResult = ReturnType<typeof useGetAllUserViewsProductByProductIdQuery>;
export type GetAllUserViewsProductByProductIdLazyQueryHookResult = ReturnType<typeof useGetAllUserViewsProductByProductIdLazyQuery>;
export type GetAllUserViewsProductByProductIdQueryResult = ApolloReactCommon.QueryResult<GetAllUserViewsProductByProductIdQuery, GetAllUserViewsProductByProductIdQueryVariables>;
export const GetProductDocument = gql`
    query getProduct($id: Float!, $userId: Float) {
  getProduct(getProductInput: {id: $id, userId: $userId}) {
    id
    title
    subtitle
    amount
    typeNegotiation
    InitialValue
    price
    quantity
    stock
    categorieId
    subCategorieId
    description
    planId
    numberOfPhotos
    brand
    active
    maxInstallments
    deadline
    freeDelivery
    finalOffersTime
    directValue
    InitialValue
    useState
    height
    numberOfPhotos
    width
    weight
    withdraw
    format
    profileAddressId
    prompt_delivery
    thickness
    material
    shippingWay
    userAccountId
    product_height
    product_length
    product_width
    product_weight
    createdAt
    updatedAt
    dataInicioDeadline
    ProductsBlackList {
      id
    }
    VehicleInformations {
      vehicleBrand
      model
      year
      motorPower
      mileage
      fuel
      licensePlate
      color
      numberDoors
      directionType
      exchange
      productId
      valorRestante
      quitado
      mesesDeContrato
      parcelasRestantes
    }
    PropertyInformations {
      cep
      street
      number
      rooms
      bathrooms
      garage
      properyType
      valorRestante
      quitado
      mesesDeContrato
      parcelasRestantes
      city
      neighborhood
      state
      complement
    }
    ProductCategories {
      id
      name
    }
    UserAccount {
      id
      name
      userTypeId
      email
      ratting
      Purchase {
        id
      }
      ProfileContacts {
        phone
      }
    }
    ProductQuestions {
      id
      commentDescription
      UserAccount {
        id
        name
      }
    }
    productBids {
      id
      bid
      userAccountId
      productId
      createdAt
      updatedAt
      UserAccount {
        id
        name
        photo
        userTypeId
        email
        password
        ratting
      }
    }
    UserBargains {
      id
      bargain
      userAccountId
      productId
    }
    ProfileAddress {
      id
      CEP
    }
    ProductVariations {
      id
      quantidade
      cor
      tamanho
      ColorVariation {
        id
        name
      }
      TamanhoVariations {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetProductQuery__
 *
 * To run a query within a React component, call `useGetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetProductQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, baseOptions);
      }
export function useGetProductLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, baseOptions);
        }
export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>;
export type GetProductLazyQueryHookResult = ReturnType<typeof useGetProductLazyQuery>;
export type GetProductQueryResult = ApolloReactCommon.QueryResult<GetProductQuery, GetProductQueryVariables>;
export const GetProductByUserIdDocument = gql`
    query getProductByUserId($getProductByUserId: GetProductByUserId!) {
  getProductByUserId(getProductByUserId: $getProductByUserId) {
    id
    title
    subtitle
    amount
    typeNegotiation
    InitialValue
    price
    quantity
    stock
    planId
    categorieId
    description
    maxInstallments
    brand
    active
    useState
    height
    width
    thickness
    material
    deadline
    freeDelivery
    VehicleInformations {
      id
      vehicleBrand
      model
      year
      motorPower
      mileage
      fuel
      licensePlate
      color
      numberDoors
      directionType
      exchange
      productId
      valorRestante
      quitado
      mesesDeContrato
      parcelasRestantes
    }
    PropertyInformations {
      id
      cep
      street
      number
      rooms
      bathrooms
      garage
      properyType
      productId
      valorRestante
      quitado
      mesesDeContrato
      parcelasRestantes
      city
      neighborhood
      state
      complement
    }
    productBids {
      id
      bid
      userAccountId
      productId
      UserAccount {
        id
        name
      }
    }
    shippingWay
    userAccountId
    UserAccount {
      id
      name
      photo
      email
      ratting
      ProfileAddress {
        CEP
      }
      ProfileContacts {
        id
        phone
      }
    }
  }
}
    `;

/**
 * __useGetProductByUserIdQuery__
 *
 * To run a query within a React component, call `useGetProductByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductByUserIdQuery({
 *   variables: {
 *      getProductByUserId: // value for 'getProductByUserId'
 *   },
 * });
 */
export function useGetProductByUserIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProductByUserIdQuery, GetProductByUserIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProductByUserIdQuery, GetProductByUserIdQueryVariables>(GetProductByUserIdDocument, baseOptions);
      }
export function useGetProductByUserIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProductByUserIdQuery, GetProductByUserIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProductByUserIdQuery, GetProductByUserIdQueryVariables>(GetProductByUserIdDocument, baseOptions);
        }
export type GetProductByUserIdQueryHookResult = ReturnType<typeof useGetProductByUserIdQuery>;
export type GetProductByUserIdLazyQueryHookResult = ReturnType<typeof useGetProductByUserIdLazyQuery>;
export type GetProductByUserIdQueryResult = ApolloReactCommon.QueryResult<GetProductByUserIdQuery, GetProductByUserIdQueryVariables>;
export const CreateProductBidDocument = gql`
    mutation createProductBid($userId: Float!, $productId: Float!, $bid: String!) {
  createProductBid(userId: $userId, productId: $productId, bid: $bid) {
    id
    bid
  }
}
    `;
export type CreateProductBidMutationFn = ApolloReactCommon.MutationFunction<CreateProductBidMutation, CreateProductBidMutationVariables>;

/**
 * __useCreateProductBidMutation__
 *
 * To run a mutation, you first call `useCreateProductBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductBidMutation, { data, loading, error }] = useCreateProductBidMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      productId: // value for 'productId'
 *      bid: // value for 'bid'
 *   },
 * });
 */
export function useCreateProductBidMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProductBidMutation, CreateProductBidMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProductBidMutation, CreateProductBidMutationVariables>(CreateProductBidDocument, baseOptions);
      }
export type CreateProductBidMutationHookResult = ReturnType<typeof useCreateProductBidMutation>;
export type CreateProductBidMutationResult = ApolloReactCommon.MutationResult<CreateProductBidMutation>;
export type CreateProductBidMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProductBidMutation, CreateProductBidMutationVariables>;
export const GetAllProductBidDocument = gql`
    query getAllProductBid($productId: Float!) {
  getAllProductBid(productId: $productId) {
    id
    bid
    userAccountId
    productId
    Product {
      id
      title
      subtitle
      amount
      typeNegotiation
      InitialValue
      price
      quantity
      stock
      categorieId
      description
      brand
      useState
      height
      width
      thickness
      material
      shippingWay
      deadline
      freeDelivery
    }
  }
}
    `;

/**
 * __useGetAllProductBidQuery__
 *
 * To run a query within a React component, call `useGetAllProductBidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductBidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProductBidQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetAllProductBidQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllProductBidQuery, GetAllProductBidQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllProductBidQuery, GetAllProductBidQueryVariables>(GetAllProductBidDocument, baseOptions);
      }
export function useGetAllProductBidLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllProductBidQuery, GetAllProductBidQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllProductBidQuery, GetAllProductBidQueryVariables>(GetAllProductBidDocument, baseOptions);
        }
export type GetAllProductBidQueryHookResult = ReturnType<typeof useGetAllProductBidQuery>;
export type GetAllProductBidLazyQueryHookResult = ReturnType<typeof useGetAllProductBidLazyQuery>;
export type GetAllProductBidQueryResult = ApolloReactCommon.QueryResult<GetAllProductBidQuery, GetAllProductBidQueryVariables>;
export const UpdateProductStatusDocument = gql`
    mutation updateProductStatus($id: Float!, $status: Boolean!) {
  updateProductStatus(id: $id, status: $status)
}
    `;
export type UpdateProductStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateProductStatusMutation, UpdateProductStatusMutationVariables>;

/**
 * __useUpdateProductStatusMutation__
 *
 * To run a mutation, you first call `useUpdateProductStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductStatusMutation, { data, loading, error }] = useUpdateProductStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateProductStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProductStatusMutation, UpdateProductStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProductStatusMutation, UpdateProductStatusMutationVariables>(UpdateProductStatusDocument, baseOptions);
      }
export type UpdateProductStatusMutationHookResult = ReturnType<typeof useUpdateProductStatusMutation>;
export type UpdateProductStatusMutationResult = ApolloReactCommon.MutationResult<UpdateProductStatusMutation>;
export type UpdateProductStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProductStatusMutation, UpdateProductStatusMutationVariables>;
export const DeleteProductDocument = gql`
    mutation deleteProduct($productId: Float!) {
  deleteProduct(productId: $productId)
}
    `;
export type DeleteProductMutationFn = ApolloReactCommon.MutationFunction<DeleteProductMutation, DeleteProductMutationVariables>;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useDeleteProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, baseOptions);
      }
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = ApolloReactCommon.MutationResult<DeleteProductMutation>;
export type DeleteProductMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProductMutation, DeleteProductMutationVariables>;
export const GetProductBySearchDocument = gql`
    query getProductBySearch($title: String!) {
  getProductBySearch(title: $title) {
    id
    title
    subtitle
    amount
    typeNegotiation
    InitialValue
    price
    quantity
    stock
    categorieId
    description
    planId
    brand
    active
    maxInstallments
    finalOffersTime
    directValue
    InitialValue
    useState
    height
    width
    thickness
    material
    deadline
    freeDelivery
    shippingWay
    userAccountId
    ProductCategories {
      id
      name
    }
    UserAccount {
      id
      name
      userTypeId
      cpf
      email
      ratting
    }
    productBids {
      id
      bid
      userAccountId
      productId
      createdAt
      updatedAt
      UserAccount {
        id
        name
        photo
        recipientId
        bankAccountId
        userTypeId
        cpf
        RG
        email
        password
        cardId
        ratting
      }
    }
  }
}
    `;

/**
 * __useGetProductBySearchQuery__
 *
 * To run a query within a React component, call `useGetProductBySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductBySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductBySearchQuery({
 *   variables: {
 *      title: // value for 'title'
 *   },
 * });
 */
export function useGetProductBySearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProductBySearchQuery, GetProductBySearchQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProductBySearchQuery, GetProductBySearchQueryVariables>(GetProductBySearchDocument, baseOptions);
      }
export function useGetProductBySearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProductBySearchQuery, GetProductBySearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProductBySearchQuery, GetProductBySearchQueryVariables>(GetProductBySearchDocument, baseOptions);
        }
export type GetProductBySearchQueryHookResult = ReturnType<typeof useGetProductBySearchQuery>;
export type GetProductBySearchLazyQueryHookResult = ReturnType<typeof useGetProductBySearchLazyQuery>;
export type GetProductBySearchQueryResult = ApolloReactCommon.QueryResult<GetProductBySearchQuery, GetProductBySearchQueryVariables>;
export const UpdateProductDocument = gql`
    mutation updateProduct($id: Float, $title: String, $subtitle: String, $amount: Float, $typeNegotiation: String, $InitialValue: Float, $price: Float, $quantity: Float, $stock: Float, $description: String, $brand: String, $useState: String, $height: Float, $width: Float, $thickness: Float, $maxInstallments: Float, $material: String, $shippingWay: String, $categorieId: Float, $subCategorieId: Float, $planId: Float, $directValue: Float, $finalOffersTime: DateTime, $propertyInformation: Boolean!, $vehicleInformation: Boolean!, $cep: String, $street: String, $city: String, $neighborhood: String, $state: String, $complement: String, $number: String, $properyType: String, $rooms: String, $bathrooms: String, $garage: String, $vehicleBrand: String, $model: String, $year: String, $motorPower: String, $mileage: String, $fuel: String, $licensePlate: String, $color: String, $numberDoors: String, $directionType: String, $exchange: String, $profileAddressId: Float, $withdraw: String, $format: String, $weight: String, $numberOfPhotos: Float, $imagesForRemove: [Float!]!, $product_height: Float, $product_width: Float, $product_length: Float, $product_weight: Float, $prompt_delivery: Boolean, $deadline: Float, $freeDelivery: Boolean, $files: [Upload!]!) {
  updateProduct(
    productUpdateInput: {id: $id, title: $title, subtitle: $subtitle, typeNegotiation: $typeNegotiation, InitialValue: $InitialValue, price: $price, quantity: $quantity, categorieId: $categorieId, subCategorieId: $subCategorieId, stock: $stock, description: $description, brand: $brand, useState: $useState, height: $height, width: $width, thickness: $thickness, maxInstallments: $maxInstallments, material: $material, shippingWay: $shippingWay, amount: $amount, planId: $planId, directValue: $directValue, finalOffersTime: $finalOffersTime, propertyInformation: $propertyInformation, vehicleInformation: $vehicleInformation, cep: $cep, street: $street, number: $number, city: $city, state: $state, complement: $complement, neighborhood: $neighborhood, properyType: $properyType, rooms: $rooms, bathrooms: $bathrooms, garage: $garage, vehicleBrand: $vehicleBrand, model: $model, year: $year, motorPower: $motorPower, mileage: $mileage, fuel: $fuel, licensePlate: $licensePlate, color: $color, numberDoors: $numberDoors, directionType: $directionType, exchange: $exchange, profileAddressId: $profileAddressId, withdraw: $withdraw, format: $format, weight: $weight, numberOfPhotos: $numberOfPhotos, imagesForRemove: $imagesForRemove, product_height: $product_height, product_width: $product_width, product_length: $product_length, product_weight: $product_weight, prompt_delivery: $prompt_delivery, deadline: $deadline, freeDelivery: $freeDelivery, files: $files}
  )
}
    `;
export type UpdateProductMutationFn = ApolloReactCommon.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      subtitle: // value for 'subtitle'
 *      amount: // value for 'amount'
 *      typeNegotiation: // value for 'typeNegotiation'
 *      InitialValue: // value for 'InitialValue'
 *      price: // value for 'price'
 *      quantity: // value for 'quantity'
 *      stock: // value for 'stock'
 *      description: // value for 'description'
 *      brand: // value for 'brand'
 *      useState: // value for 'useState'
 *      height: // value for 'height'
 *      width: // value for 'width'
 *      thickness: // value for 'thickness'
 *      maxInstallments: // value for 'maxInstallments'
 *      material: // value for 'material'
 *      shippingWay: // value for 'shippingWay'
 *      categorieId: // value for 'categorieId'
 *      subCategorieId: // value for 'subCategorieId'
 *      planId: // value for 'planId'
 *      directValue: // value for 'directValue'
 *      finalOffersTime: // value for 'finalOffersTime'
 *      propertyInformation: // value for 'propertyInformation'
 *      vehicleInformation: // value for 'vehicleInformation'
 *      cep: // value for 'cep'
 *      street: // value for 'street'
 *      city: // value for 'city'
 *      neighborhood: // value for 'neighborhood'
 *      state: // value for 'state'
 *      complement: // value for 'complement'
 *      number: // value for 'number'
 *      properyType: // value for 'properyType'
 *      rooms: // value for 'rooms'
 *      bathrooms: // value for 'bathrooms'
 *      garage: // value for 'garage'
 *      vehicleBrand: // value for 'vehicleBrand'
 *      model: // value for 'model'
 *      year: // value for 'year'
 *      motorPower: // value for 'motorPower'
 *      mileage: // value for 'mileage'
 *      fuel: // value for 'fuel'
 *      licensePlate: // value for 'licensePlate'
 *      color: // value for 'color'
 *      numberDoors: // value for 'numberDoors'
 *      directionType: // value for 'directionType'
 *      exchange: // value for 'exchange'
 *      profileAddressId: // value for 'profileAddressId'
 *      withdraw: // value for 'withdraw'
 *      format: // value for 'format'
 *      weight: // value for 'weight'
 *      numberOfPhotos: // value for 'numberOfPhotos'
 *      imagesForRemove: // value for 'imagesForRemove'
 *      product_height: // value for 'product_height'
 *      product_width: // value for 'product_width'
 *      product_length: // value for 'product_length'
 *      product_weight: // value for 'product_weight'
 *      prompt_delivery: // value for 'prompt_delivery'
 *      deadline: // value for 'deadline'
 *      freeDelivery: // value for 'freeDelivery'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, baseOptions);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = ApolloReactCommon.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const GetProductsByCategoryDocument = gql`
    query getProductsByCategory($category: String, $categoryId: Float) {
  getProductsByCategory(category: $category, categoryId: $categoryId) {
    id
    isSocialProject
    title
    subtitle
    amount
    typeNegotiation
    InitialValue
    price
    quantity
    stock
    categorieId
    description
    planId
    brand
    active
    maxInstallments
    finalOffersTime
    directValue
    InitialValue
    useState
    height
    width
    thickness
    material
    shippingWay
    userAccountId
    deadline
    freeDelivery
    ProductCategories {
      id
      name
    }
    UserAccount {
      id
      name
      userTypeId
      cpf
      email
      ratting
    }
  }
}
    `;

/**
 * __useGetProductsByCategoryQuery__
 *
 * To run a query within a React component, call `useGetProductsByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsByCategoryQuery({
 *   variables: {
 *      category: // value for 'category'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useGetProductsByCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProductsByCategoryQuery, GetProductsByCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProductsByCategoryQuery, GetProductsByCategoryQueryVariables>(GetProductsByCategoryDocument, baseOptions);
      }
export function useGetProductsByCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProductsByCategoryQuery, GetProductsByCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProductsByCategoryQuery, GetProductsByCategoryQueryVariables>(GetProductsByCategoryDocument, baseOptions);
        }
export type GetProductsByCategoryQueryHookResult = ReturnType<typeof useGetProductsByCategoryQuery>;
export type GetProductsByCategoryLazyQueryHookResult = ReturnType<typeof useGetProductsByCategoryLazyQuery>;
export type GetProductsByCategoryQueryResult = ApolloReactCommon.QueryResult<GetProductsByCategoryQuery, GetProductsByCategoryQueryVariables>;
export const GetAllSocialProjectsDocument = gql`
    query getAllSocialProjects {
  getAllSocialProjects {
    id
    title
    subtitle
    amount
    typeNegotiation
    InitialValue
    price
    quantity
    stock
    categorieId
    numberOfPhotos
    description
    active
    isSocialProject
    brand
    useState
    maxInstallments
    planId
    height
    width
    thickness
    height
    width
    thickness
    material
    shippingWay
    deadline
    freeDelivery
    ProductsBlackList {
      id
      productId
    }
    UserAccount {
      id
      name
      userTypeId
      email
      ratting
      ProfileContacts {
        id
        phone
      }
      ProfileAddress {
        id
        CEP
        street
        complement
        references
        userAccountId
        state
      }
    }
  }
}
    `;

/**
 * __useGetAllSocialProjectsQuery__
 *
 * To run a query within a React component, call `useGetAllSocialProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSocialProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSocialProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllSocialProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllSocialProjectsQuery, GetAllSocialProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllSocialProjectsQuery, GetAllSocialProjectsQueryVariables>(GetAllSocialProjectsDocument, baseOptions);
      }
export function useGetAllSocialProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllSocialProjectsQuery, GetAllSocialProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllSocialProjectsQuery, GetAllSocialProjectsQueryVariables>(GetAllSocialProjectsDocument, baseOptions);
        }
export type GetAllSocialProjectsQueryHookResult = ReturnType<typeof useGetAllSocialProjectsQuery>;
export type GetAllSocialProjectsLazyQueryHookResult = ReturnType<typeof useGetAllSocialProjectsLazyQuery>;
export type GetAllSocialProjectsQueryResult = ApolloReactCommon.QueryResult<GetAllSocialProjectsQuery, GetAllSocialProjectsQueryVariables>;
export const CreateUserBargainDocument = gql`
    mutation createUserBargain($bargain: String!, $isValid: Boolean!, $userId: Float!, $productId: Float!) {
  createUserBargain(
    bargain: $bargain
    isValid: $isValid
    userId: $userId
    productId: $productId
  ) {
    id
  }
}
    `;
export type CreateUserBargainMutationFn = ApolloReactCommon.MutationFunction<CreateUserBargainMutation, CreateUserBargainMutationVariables>;

/**
 * __useCreateUserBargainMutation__
 *
 * To run a mutation, you first call `useCreateUserBargainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserBargainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserBargainMutation, { data, loading, error }] = useCreateUserBargainMutation({
 *   variables: {
 *      bargain: // value for 'bargain'
 *      isValid: // value for 'isValid'
 *      userId: // value for 'userId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useCreateUserBargainMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserBargainMutation, CreateUserBargainMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserBargainMutation, CreateUserBargainMutationVariables>(CreateUserBargainDocument, baseOptions);
      }
export type CreateUserBargainMutationHookResult = ReturnType<typeof useCreateUserBargainMutation>;
export type CreateUserBargainMutationResult = ApolloReactCommon.MutationResult<CreateUserBargainMutation>;
export type CreateUserBargainMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserBargainMutation, CreateUserBargainMutationVariables>;
export const GetUserProductBargainDocument = gql`
    query getUserProductBargain($userId: Float!, $productId: Float!) {
  getUserProductBargain(userId: $userId, productId: $productId)
}
    `;

/**
 * __useGetUserProductBargainQuery__
 *
 * To run a query within a React component, call `useGetUserProductBargainQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProductBargainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProductBargainQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetUserProductBargainQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserProductBargainQuery, GetUserProductBargainQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserProductBargainQuery, GetUserProductBargainQueryVariables>(GetUserProductBargainDocument, baseOptions);
      }
export function useGetUserProductBargainLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserProductBargainQuery, GetUserProductBargainQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserProductBargainQuery, GetUserProductBargainQueryVariables>(GetUserProductBargainDocument, baseOptions);
        }
export type GetUserProductBargainQueryHookResult = ReturnType<typeof useGetUserProductBargainQuery>;
export type GetUserProductBargainLazyQueryHookResult = ReturnType<typeof useGetUserProductBargainLazyQuery>;
export type GetUserProductBargainQueryResult = ApolloReactCommon.QueryResult<GetUserProductBargainQuery, GetUserProductBargainQueryVariables>;
export const UpdateUserProductBargainDocument = gql`
    mutation updateUserProductBargain($userId: Float!, $bargain: String!, $isValid: Boolean!, $productId: Float!) {
  updateUserProductBargain(
    userId: $userId
    bargain: $bargain
    isValid: $isValid
    productId: $productId
  )
}
    `;
export type UpdateUserProductBargainMutationFn = ApolloReactCommon.MutationFunction<UpdateUserProductBargainMutation, UpdateUserProductBargainMutationVariables>;

/**
 * __useUpdateUserProductBargainMutation__
 *
 * To run a mutation, you first call `useUpdateUserProductBargainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProductBargainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProductBargainMutation, { data, loading, error }] = useUpdateUserProductBargainMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      bargain: // value for 'bargain'
 *      isValid: // value for 'isValid'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useUpdateUserProductBargainMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserProductBargainMutation, UpdateUserProductBargainMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserProductBargainMutation, UpdateUserProductBargainMutationVariables>(UpdateUserProductBargainDocument, baseOptions);
      }
export type UpdateUserProductBargainMutationHookResult = ReturnType<typeof useUpdateUserProductBargainMutation>;
export type UpdateUserProductBargainMutationResult = ApolloReactCommon.MutationResult<UpdateUserProductBargainMutation>;
export type UpdateUserProductBargainMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserProductBargainMutation, UpdateUserProductBargainMutationVariables>;
export const GetAllProductCategoriesDocument = gql`
    query getAllProductCategories {
  getAllProductCategories {
    id
    name
    split
  }
}
    `;

/**
 * __useGetAllProductCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAllProductCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProductCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllProductCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllProductCategoriesQuery, GetAllProductCategoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllProductCategoriesQuery, GetAllProductCategoriesQueryVariables>(GetAllProductCategoriesDocument, baseOptions);
      }
export function useGetAllProductCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllProductCategoriesQuery, GetAllProductCategoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllProductCategoriesQuery, GetAllProductCategoriesQueryVariables>(GetAllProductCategoriesDocument, baseOptions);
        }
export type GetAllProductCategoriesQueryHookResult = ReturnType<typeof useGetAllProductCategoriesQuery>;
export type GetAllProductCategoriesLazyQueryHookResult = ReturnType<typeof useGetAllProductCategoriesLazyQuery>;
export type GetAllProductCategoriesQueryResult = ApolloReactCommon.QueryResult<GetAllProductCategoriesQuery, GetAllProductCategoriesQueryVariables>;
export const GetProductCategorieBySearchDocument = gql`
    query getProductCategorieBySearch($name: String!) {
  getProductCategorieBySearch(name: $name) {
    id
    name
    split
    ProductSubCategories {
      id
      name
    }
  }
}
    `;

/**
 * __useGetProductCategorieBySearchQuery__
 *
 * To run a query within a React component, call `useGetProductCategorieBySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductCategorieBySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductCategorieBySearchQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetProductCategorieBySearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProductCategorieBySearchQuery, GetProductCategorieBySearchQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProductCategorieBySearchQuery, GetProductCategorieBySearchQueryVariables>(GetProductCategorieBySearchDocument, baseOptions);
      }
export function useGetProductCategorieBySearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProductCategorieBySearchQuery, GetProductCategorieBySearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProductCategorieBySearchQuery, GetProductCategorieBySearchQueryVariables>(GetProductCategorieBySearchDocument, baseOptions);
        }
export type GetProductCategorieBySearchQueryHookResult = ReturnType<typeof useGetProductCategorieBySearchQuery>;
export type GetProductCategorieBySearchLazyQueryHookResult = ReturnType<typeof useGetProductCategorieBySearchLazyQuery>;
export type GetProductCategorieBySearchQueryResult = ApolloReactCommon.QueryResult<GetProductCategorieBySearchQuery, GetProductCategorieBySearchQueryVariables>;
export const CreateProductCategorieDocument = gql`
    mutation createProductCategorie($name: String!, $split: Float) {
  createProductCategorie(name: $name) {
    id
    name
    split
  }
}
    `;
export type CreateProductCategorieMutationFn = ApolloReactCommon.MutationFunction<CreateProductCategorieMutation, CreateProductCategorieMutationVariables>;

/**
 * __useCreateProductCategorieMutation__
 *
 * To run a mutation, you first call `useCreateProductCategorieMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductCategorieMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductCategorieMutation, { data, loading, error }] = useCreateProductCategorieMutation({
 *   variables: {
 *      name: // value for 'name'
 *      split: // value for 'split'
 *   },
 * });
 */
export function useCreateProductCategorieMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProductCategorieMutation, CreateProductCategorieMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProductCategorieMutation, CreateProductCategorieMutationVariables>(CreateProductCategorieDocument, baseOptions);
      }
export type CreateProductCategorieMutationHookResult = ReturnType<typeof useCreateProductCategorieMutation>;
export type CreateProductCategorieMutationResult = ApolloReactCommon.MutationResult<CreateProductCategorieMutation>;
export type CreateProductCategorieMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProductCategorieMutation, CreateProductCategorieMutationVariables>;
export const UpdateProductCategorieDocument = gql`
    mutation updateProductCategorie($id: Float!, $name: String, $split: Float) {
  updateProductCategorie(id: $id, name: $name, split: $split)
}
    `;
export type UpdateProductCategorieMutationFn = ApolloReactCommon.MutationFunction<UpdateProductCategorieMutation, UpdateProductCategorieMutationVariables>;

/**
 * __useUpdateProductCategorieMutation__
 *
 * To run a mutation, you first call `useUpdateProductCategorieMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductCategorieMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductCategorieMutation, { data, loading, error }] = useUpdateProductCategorieMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      split: // value for 'split'
 *   },
 * });
 */
export function useUpdateProductCategorieMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProductCategorieMutation, UpdateProductCategorieMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProductCategorieMutation, UpdateProductCategorieMutationVariables>(UpdateProductCategorieDocument, baseOptions);
      }
export type UpdateProductCategorieMutationHookResult = ReturnType<typeof useUpdateProductCategorieMutation>;
export type UpdateProductCategorieMutationResult = ApolloReactCommon.MutationResult<UpdateProductCategorieMutation>;
export type UpdateProductCategorieMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProductCategorieMutation, UpdateProductCategorieMutationVariables>;
export const DeleteProductCategorieDocument = gql`
    mutation deleteProductCategorie($id: Float!) {
  deleteProductCategorie(id: $id)
}
    `;
export type DeleteProductCategorieMutationFn = ApolloReactCommon.MutationFunction<DeleteProductCategorieMutation, DeleteProductCategorieMutationVariables>;

/**
 * __useDeleteProductCategorieMutation__
 *
 * To run a mutation, you first call `useDeleteProductCategorieMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductCategorieMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductCategorieMutation, { data, loading, error }] = useDeleteProductCategorieMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductCategorieMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProductCategorieMutation, DeleteProductCategorieMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProductCategorieMutation, DeleteProductCategorieMutationVariables>(DeleteProductCategorieDocument, baseOptions);
      }
export type DeleteProductCategorieMutationHookResult = ReturnType<typeof useDeleteProductCategorieMutation>;
export type DeleteProductCategorieMutationResult = ApolloReactCommon.MutationResult<DeleteProductCategorieMutation>;
export type DeleteProductCategorieMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProductCategorieMutation, DeleteProductCategorieMutationVariables>;
export const CreateProductQuestionDocument = gql`
    mutation createProductQuestion($productId: Float!, $userId: Float!, $description: String!) {
  createProductQuestion(
    productId: $productId
    userId: $userId
    description: $description
  ) {
    id
    commentDescription
  }
}
    `;
export type CreateProductQuestionMutationFn = ApolloReactCommon.MutationFunction<CreateProductQuestionMutation, CreateProductQuestionMutationVariables>;

/**
 * __useCreateProductQuestionMutation__
 *
 * To run a mutation, you first call `useCreateProductQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductQuestionMutation, { data, loading, error }] = useCreateProductQuestionMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      userId: // value for 'userId'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateProductQuestionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProductQuestionMutation, CreateProductQuestionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProductQuestionMutation, CreateProductQuestionMutationVariables>(CreateProductQuestionDocument, baseOptions);
      }
export type CreateProductQuestionMutationHookResult = ReturnType<typeof useCreateProductQuestionMutation>;
export type CreateProductQuestionMutationResult = ApolloReactCommon.MutationResult<CreateProductQuestionMutation>;
export type CreateProductQuestionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProductQuestionMutation, CreateProductQuestionMutationVariables>;
export const GetAllProductQuestionsDocument = gql`
    query getAllProductQuestions($productId: Float!) {
  getAllProductQuestions(productId: $productId) {
    id
    commentDescription
    userAccountId
    productId
    createdAt
    repeated
    UserAccount {
      id
      name
      cpf
    }
    Product {
      id
      userAccountId
      UserAccount {
        id
      }
    }
    ProductQuestionsAnswers {
      id
    }
  }
}
    `;

/**
 * __useGetAllProductQuestionsQuery__
 *
 * To run a query within a React component, call `useGetAllProductQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProductQuestionsQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetAllProductQuestionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllProductQuestionsQuery, GetAllProductQuestionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllProductQuestionsQuery, GetAllProductQuestionsQueryVariables>(GetAllProductQuestionsDocument, baseOptions);
      }
export function useGetAllProductQuestionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllProductQuestionsQuery, GetAllProductQuestionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllProductQuestionsQuery, GetAllProductQuestionsQueryVariables>(GetAllProductQuestionsDocument, baseOptions);
        }
export type GetAllProductQuestionsQueryHookResult = ReturnType<typeof useGetAllProductQuestionsQuery>;
export type GetAllProductQuestionsLazyQueryHookResult = ReturnType<typeof useGetAllProductQuestionsLazyQuery>;
export type GetAllProductQuestionsQueryResult = ApolloReactCommon.QueryResult<GetAllProductQuestionsQuery, GetAllProductQuestionsQueryVariables>;
export const MarkAsRepeatDocument = gql`
    mutation markAsRepeat($id: Float!, $userId: Float!) {
  markAsRepeat(id: $id, userId: $userId)
}
    `;
export type MarkAsRepeatMutationFn = ApolloReactCommon.MutationFunction<MarkAsRepeatMutation, MarkAsRepeatMutationVariables>;

/**
 * __useMarkAsRepeatMutation__
 *
 * To run a mutation, you first call `useMarkAsRepeatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsRepeatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsRepeatMutation, { data, loading, error }] = useMarkAsRepeatMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMarkAsRepeatMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkAsRepeatMutation, MarkAsRepeatMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkAsRepeatMutation, MarkAsRepeatMutationVariables>(MarkAsRepeatDocument, baseOptions);
      }
export type MarkAsRepeatMutationHookResult = ReturnType<typeof useMarkAsRepeatMutation>;
export type MarkAsRepeatMutationResult = ApolloReactCommon.MutationResult<MarkAsRepeatMutation>;
export type MarkAsRepeatMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkAsRepeatMutation, MarkAsRepeatMutationVariables>;
export const GetAllProductQuestionAnswersDocument = gql`
    query getAllProductQuestionAnswers($productQuestionId: Float!) {
  getAllProductQuestionAnswers(productQuestionId: $productQuestionId) {
    id
    description
    createdAt
    userAccountId
    productQuestionId
    createdAt
    UserAccount {
      id
      name
      cpf
    }
  }
}
    `;

/**
 * __useGetAllProductQuestionAnswersQuery__
 *
 * To run a query within a React component, call `useGetAllProductQuestionAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductQuestionAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProductQuestionAnswersQuery({
 *   variables: {
 *      productQuestionId: // value for 'productQuestionId'
 *   },
 * });
 */
export function useGetAllProductQuestionAnswersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllProductQuestionAnswersQuery, GetAllProductQuestionAnswersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllProductQuestionAnswersQuery, GetAllProductQuestionAnswersQueryVariables>(GetAllProductQuestionAnswersDocument, baseOptions);
      }
export function useGetAllProductQuestionAnswersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllProductQuestionAnswersQuery, GetAllProductQuestionAnswersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllProductQuestionAnswersQuery, GetAllProductQuestionAnswersQueryVariables>(GetAllProductQuestionAnswersDocument, baseOptions);
        }
export type GetAllProductQuestionAnswersQueryHookResult = ReturnType<typeof useGetAllProductQuestionAnswersQuery>;
export type GetAllProductQuestionAnswersLazyQueryHookResult = ReturnType<typeof useGetAllProductQuestionAnswersLazyQuery>;
export type GetAllProductQuestionAnswersQueryResult = ApolloReactCommon.QueryResult<GetAllProductQuestionAnswersQuery, GetAllProductQuestionAnswersQueryVariables>;
export const CreateProductQuestionAnswersDocument = gql`
    mutation createProductQuestionAnswers($productQuestionId: Float!, $userId: Float!, $description: String!) {
  createProductQuestionAnswers(
    productQuestionId: $productQuestionId
    userId: $userId
    description: $description
  ) {
    id
    description
  }
}
    `;
export type CreateProductQuestionAnswersMutationFn = ApolloReactCommon.MutationFunction<CreateProductQuestionAnswersMutation, CreateProductQuestionAnswersMutationVariables>;

/**
 * __useCreateProductQuestionAnswersMutation__
 *
 * To run a mutation, you first call `useCreateProductQuestionAnswersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductQuestionAnswersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductQuestionAnswersMutation, { data, loading, error }] = useCreateProductQuestionAnswersMutation({
 *   variables: {
 *      productQuestionId: // value for 'productQuestionId'
 *      userId: // value for 'userId'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateProductQuestionAnswersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProductQuestionAnswersMutation, CreateProductQuestionAnswersMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProductQuestionAnswersMutation, CreateProductQuestionAnswersMutationVariables>(CreateProductQuestionAnswersDocument, baseOptions);
      }
export type CreateProductQuestionAnswersMutationHookResult = ReturnType<typeof useCreateProductQuestionAnswersMutation>;
export type CreateProductQuestionAnswersMutationResult = ApolloReactCommon.MutationResult<CreateProductQuestionAnswersMutation>;
export type CreateProductQuestionAnswersMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProductQuestionAnswersMutation, CreateProductQuestionAnswersMutationVariables>;
export const UpdateProductQuestionAnswerDocument = gql`
    mutation updateProductQuestionAnswer($id: Float!, $description: String!) {
  updateProductQuestionAnswer(id: $id, description: $description)
}
    `;
export type UpdateProductQuestionAnswerMutationFn = ApolloReactCommon.MutationFunction<UpdateProductQuestionAnswerMutation, UpdateProductQuestionAnswerMutationVariables>;

/**
 * __useUpdateProductQuestionAnswerMutation__
 *
 * To run a mutation, you first call `useUpdateProductQuestionAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductQuestionAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductQuestionAnswerMutation, { data, loading, error }] = useUpdateProductQuestionAnswerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateProductQuestionAnswerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProductQuestionAnswerMutation, UpdateProductQuestionAnswerMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProductQuestionAnswerMutation, UpdateProductQuestionAnswerMutationVariables>(UpdateProductQuestionAnswerDocument, baseOptions);
      }
export type UpdateProductQuestionAnswerMutationHookResult = ReturnType<typeof useUpdateProductQuestionAnswerMutation>;
export type UpdateProductQuestionAnswerMutationResult = ApolloReactCommon.MutationResult<UpdateProductQuestionAnswerMutation>;
export type UpdateProductQuestionAnswerMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProductQuestionAnswerMutation, UpdateProductQuestionAnswerMutationVariables>;
export const DeleteProductQuestionAnswerDocument = gql`
    mutation deleteProductQuestionAnswer($id: Float!) {
  deleteProductQuestionAnswer(id: $id)
}
    `;
export type DeleteProductQuestionAnswerMutationFn = ApolloReactCommon.MutationFunction<DeleteProductQuestionAnswerMutation, DeleteProductQuestionAnswerMutationVariables>;

/**
 * __useDeleteProductQuestionAnswerMutation__
 *
 * To run a mutation, you first call `useDeleteProductQuestionAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductQuestionAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductQuestionAnswerMutation, { data, loading, error }] = useDeleteProductQuestionAnswerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductQuestionAnswerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProductQuestionAnswerMutation, DeleteProductQuestionAnswerMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProductQuestionAnswerMutation, DeleteProductQuestionAnswerMutationVariables>(DeleteProductQuestionAnswerDocument, baseOptions);
      }
export type DeleteProductQuestionAnswerMutationHookResult = ReturnType<typeof useDeleteProductQuestionAnswerMutation>;
export type DeleteProductQuestionAnswerMutationResult = ApolloReactCommon.MutationResult<DeleteProductQuestionAnswerMutation>;
export type DeleteProductQuestionAnswerMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProductQuestionAnswerMutation, DeleteProductQuestionAnswerMutationVariables>;
export const GetAllProductRatingsDocument = gql`
    query getAllProductRatings($productId: Float!) {
  getAllProductRatings(productId: $productId) {
    id
    rating
    ratingDescription
    userAccountId
    createdAt
    UserAccount {
      id
      name
      cpf
    }
    productId
  }
}
    `;

/**
 * __useGetAllProductRatingsQuery__
 *
 * To run a query within a React component, call `useGetAllProductRatingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductRatingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProductRatingsQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetAllProductRatingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllProductRatingsQuery, GetAllProductRatingsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllProductRatingsQuery, GetAllProductRatingsQueryVariables>(GetAllProductRatingsDocument, baseOptions);
      }
export function useGetAllProductRatingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllProductRatingsQuery, GetAllProductRatingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllProductRatingsQuery, GetAllProductRatingsQueryVariables>(GetAllProductRatingsDocument, baseOptions);
        }
export type GetAllProductRatingsQueryHookResult = ReturnType<typeof useGetAllProductRatingsQuery>;
export type GetAllProductRatingsLazyQueryHookResult = ReturnType<typeof useGetAllProductRatingsLazyQuery>;
export type GetAllProductRatingsQueryResult = ApolloReactCommon.QueryResult<GetAllProductRatingsQuery, GetAllProductRatingsQueryVariables>;
export const CreateProductRatingDocument = gql`
    mutation createProductRating($userId: Float!, $productId: Float!, $rating: Float!, $ratingDescription: String!, $sellerId: Float!) {
  createProductRating(
    productInput: {userId: $userId, productId: $productId, rating: $rating, ratingDescription: $ratingDescription, sellerId: $sellerId}
  ) {
    id
  }
}
    `;
export type CreateProductRatingMutationFn = ApolloReactCommon.MutationFunction<CreateProductRatingMutation, CreateProductRatingMutationVariables>;

/**
 * __useCreateProductRatingMutation__
 *
 * To run a mutation, you first call `useCreateProductRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductRatingMutation, { data, loading, error }] = useCreateProductRatingMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      productId: // value for 'productId'
 *      rating: // value for 'rating'
 *      ratingDescription: // value for 'ratingDescription'
 *      sellerId: // value for 'sellerId'
 *   },
 * });
 */
export function useCreateProductRatingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProductRatingMutation, CreateProductRatingMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProductRatingMutation, CreateProductRatingMutationVariables>(CreateProductRatingDocument, baseOptions);
      }
export type CreateProductRatingMutationHookResult = ReturnType<typeof useCreateProductRatingMutation>;
export type CreateProductRatingMutationResult = ApolloReactCommon.MutationResult<CreateProductRatingMutation>;
export type CreateProductRatingMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProductRatingMutation, CreateProductRatingMutationVariables>;
export const DeleteRatingDocument = gql`
    mutation deleteRating($id: Float!) {
  deleteRating(id: $id)
}
    `;
export type DeleteRatingMutationFn = ApolloReactCommon.MutationFunction<DeleteRatingMutation, DeleteRatingMutationVariables>;

/**
 * __useDeleteRatingMutation__
 *
 * To run a mutation, you first call `useDeleteRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRatingMutation, { data, loading, error }] = useDeleteRatingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRatingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRatingMutation, DeleteRatingMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRatingMutation, DeleteRatingMutationVariables>(DeleteRatingDocument, baseOptions);
      }
export type DeleteRatingMutationHookResult = ReturnType<typeof useDeleteRatingMutation>;
export type DeleteRatingMutationResult = ApolloReactCommon.MutationResult<DeleteRatingMutation>;
export type DeleteRatingMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRatingMutation, DeleteRatingMutationVariables>;
export const UpdateProductRatingDocument = gql`
    mutation updateProductRating($ratingId: Float!, $rating: Float!, $ratingDescription: String!) {
  updateProductRating(
    ratingId: $ratingId
    rating: $rating
    ratingDescription: $ratingDescription
  )
}
    `;
export type UpdateProductRatingMutationFn = ApolloReactCommon.MutationFunction<UpdateProductRatingMutation, UpdateProductRatingMutationVariables>;

/**
 * __useUpdateProductRatingMutation__
 *
 * To run a mutation, you first call `useUpdateProductRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductRatingMutation, { data, loading, error }] = useUpdateProductRatingMutation({
 *   variables: {
 *      ratingId: // value for 'ratingId'
 *      rating: // value for 'rating'
 *      ratingDescription: // value for 'ratingDescription'
 *   },
 * });
 */
export function useUpdateProductRatingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProductRatingMutation, UpdateProductRatingMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProductRatingMutation, UpdateProductRatingMutationVariables>(UpdateProductRatingDocument, baseOptions);
      }
export type UpdateProductRatingMutationHookResult = ReturnType<typeof useUpdateProductRatingMutation>;
export type UpdateProductRatingMutationResult = ApolloReactCommon.MutationResult<UpdateProductRatingMutation>;
export type UpdateProductRatingMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProductRatingMutation, UpdateProductRatingMutationVariables>;
export const GetAllUserRatingsDocument = gql`
    query getAllUserRatings($userSalerId: Float!) {
  getAllUserRatings(userSalerId: $userSalerId) {
    id
    rating
    createdAt
    ratingDescription
    userAccountId
    productId
    UserAccount {
      id
      name
      userTypeId
      cpf
      email
      ratting
      RG
      password
    }
  }
}
    `;

/**
 * __useGetAllUserRatingsQuery__
 *
 * To run a query within a React component, call `useGetAllUserRatingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUserRatingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUserRatingsQuery({
 *   variables: {
 *      userSalerId: // value for 'userSalerId'
 *   },
 * });
 */
export function useGetAllUserRatingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllUserRatingsQuery, GetAllUserRatingsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllUserRatingsQuery, GetAllUserRatingsQueryVariables>(GetAllUserRatingsDocument, baseOptions);
      }
export function useGetAllUserRatingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllUserRatingsQuery, GetAllUserRatingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllUserRatingsQuery, GetAllUserRatingsQueryVariables>(GetAllUserRatingsDocument, baseOptions);
        }
export type GetAllUserRatingsQueryHookResult = ReturnType<typeof useGetAllUserRatingsQuery>;
export type GetAllUserRatingsLazyQueryHookResult = ReturnType<typeof useGetAllUserRatingsLazyQuery>;
export type GetAllUserRatingsQueryResult = ApolloReactCommon.QueryResult<GetAllUserRatingsQuery, GetAllUserRatingsQueryVariables>;
export const GetAllProductSubCategoriesDocument = gql`
    query getAllProductSubCategories($categorieId: Float!) {
  getAllProductSubCategories(categorieId: $categorieId) {
    id
    name
    categorieId
  }
}
    `;

/**
 * __useGetAllProductSubCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAllProductSubCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductSubCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProductSubCategoriesQuery({
 *   variables: {
 *      categorieId: // value for 'categorieId'
 *   },
 * });
 */
export function useGetAllProductSubCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllProductSubCategoriesQuery, GetAllProductSubCategoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllProductSubCategoriesQuery, GetAllProductSubCategoriesQueryVariables>(GetAllProductSubCategoriesDocument, baseOptions);
      }
export function useGetAllProductSubCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllProductSubCategoriesQuery, GetAllProductSubCategoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllProductSubCategoriesQuery, GetAllProductSubCategoriesQueryVariables>(GetAllProductSubCategoriesDocument, baseOptions);
        }
export type GetAllProductSubCategoriesQueryHookResult = ReturnType<typeof useGetAllProductSubCategoriesQuery>;
export type GetAllProductSubCategoriesLazyQueryHookResult = ReturnType<typeof useGetAllProductSubCategoriesLazyQuery>;
export type GetAllProductSubCategoriesQueryResult = ApolloReactCommon.QueryResult<GetAllProductSubCategoriesQuery, GetAllProductSubCategoriesQueryVariables>;
export const CreateProductSubCategorieDocument = gql`
    mutation createProductSubCategorie($categorieId: Float!, $name: String!) {
  createProductSubCategorie(categorieId: $categorieId, name: $name) {
    id
    name
    categorieId
  }
}
    `;
export type CreateProductSubCategorieMutationFn = ApolloReactCommon.MutationFunction<CreateProductSubCategorieMutation, CreateProductSubCategorieMutationVariables>;

/**
 * __useCreateProductSubCategorieMutation__
 *
 * To run a mutation, you first call `useCreateProductSubCategorieMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductSubCategorieMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductSubCategorieMutation, { data, loading, error }] = useCreateProductSubCategorieMutation({
 *   variables: {
 *      categorieId: // value for 'categorieId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateProductSubCategorieMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProductSubCategorieMutation, CreateProductSubCategorieMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProductSubCategorieMutation, CreateProductSubCategorieMutationVariables>(CreateProductSubCategorieDocument, baseOptions);
      }
export type CreateProductSubCategorieMutationHookResult = ReturnType<typeof useCreateProductSubCategorieMutation>;
export type CreateProductSubCategorieMutationResult = ApolloReactCommon.MutationResult<CreateProductSubCategorieMutation>;
export type CreateProductSubCategorieMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProductSubCategorieMutation, CreateProductSubCategorieMutationVariables>;
export const DeleteProductSubCategorieDocument = gql`
    mutation deleteProductSubCategorie($id: Float!) {
  deleteProductSubCategorie(id: $id)
}
    `;
export type DeleteProductSubCategorieMutationFn = ApolloReactCommon.MutationFunction<DeleteProductSubCategorieMutation, DeleteProductSubCategorieMutationVariables>;

/**
 * __useDeleteProductSubCategorieMutation__
 *
 * To run a mutation, you first call `useDeleteProductSubCategorieMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductSubCategorieMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductSubCategorieMutation, { data, loading, error }] = useDeleteProductSubCategorieMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductSubCategorieMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProductSubCategorieMutation, DeleteProductSubCategorieMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProductSubCategorieMutation, DeleteProductSubCategorieMutationVariables>(DeleteProductSubCategorieDocument, baseOptions);
      }
export type DeleteProductSubCategorieMutationHookResult = ReturnType<typeof useDeleteProductSubCategorieMutation>;
export type DeleteProductSubCategorieMutationResult = ApolloReactCommon.MutationResult<DeleteProductSubCategorieMutation>;
export type DeleteProductSubCategorieMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProductSubCategorieMutation, DeleteProductSubCategorieMutationVariables>;
export const GetAllSubCategoriesByCategoryDocument = gql`
    query getAllSubCategoriesByCategory($categoryId: Float!) {
  getAllSubCategoriesByCategory(categoryId: $categoryId) {
    id
    name
    categorieId
  }
}
    `;

/**
 * __useGetAllSubCategoriesByCategoryQuery__
 *
 * To run a query within a React component, call `useGetAllSubCategoriesByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSubCategoriesByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSubCategoriesByCategoryQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useGetAllSubCategoriesByCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllSubCategoriesByCategoryQuery, GetAllSubCategoriesByCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllSubCategoriesByCategoryQuery, GetAllSubCategoriesByCategoryQueryVariables>(GetAllSubCategoriesByCategoryDocument, baseOptions);
      }
export function useGetAllSubCategoriesByCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllSubCategoriesByCategoryQuery, GetAllSubCategoriesByCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllSubCategoriesByCategoryQuery, GetAllSubCategoriesByCategoryQueryVariables>(GetAllSubCategoriesByCategoryDocument, baseOptions);
        }
export type GetAllSubCategoriesByCategoryQueryHookResult = ReturnType<typeof useGetAllSubCategoriesByCategoryQuery>;
export type GetAllSubCategoriesByCategoryLazyQueryHookResult = ReturnType<typeof useGetAllSubCategoriesByCategoryLazyQuery>;
export type GetAllSubCategoriesByCategoryQueryResult = ApolloReactCommon.QueryResult<GetAllSubCategoriesByCategoryQuery, GetAllSubCategoriesByCategoryQueryVariables>;
export const IncludeProductDocument = gql`
    mutation includeProduct($productId: Float!, $blockReason: String!) {
  includeProduct(productId: $productId, blockReason: $blockReason)
}
    `;
export type IncludeProductMutationFn = ApolloReactCommon.MutationFunction<IncludeProductMutation, IncludeProductMutationVariables>;

/**
 * __useIncludeProductMutation__
 *
 * To run a mutation, you first call `useIncludeProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncludeProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [includeProductMutation, { data, loading, error }] = useIncludeProductMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      blockReason: // value for 'blockReason'
 *   },
 * });
 */
export function useIncludeProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IncludeProductMutation, IncludeProductMutationVariables>) {
        return ApolloReactHooks.useMutation<IncludeProductMutation, IncludeProductMutationVariables>(IncludeProductDocument, baseOptions);
      }
export type IncludeProductMutationHookResult = ReturnType<typeof useIncludeProductMutation>;
export type IncludeProductMutationResult = ApolloReactCommon.MutationResult<IncludeProductMutation>;
export type IncludeProductMutationOptions = ApolloReactCommon.BaseMutationOptions<IncludeProductMutation, IncludeProductMutationVariables>;
export const RemoveProductDocument = gql`
    mutation removeProduct($productId: Float!) {
  removeProduct(productId: $productId)
}
    `;
export type RemoveProductMutationFn = ApolloReactCommon.MutationFunction<RemoveProductMutation, RemoveProductMutationVariables>;

/**
 * __useRemoveProductMutation__
 *
 * To run a mutation, you first call `useRemoveProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProductMutation, { data, loading, error }] = useRemoveProductMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useRemoveProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveProductMutation, RemoveProductMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveProductMutation, RemoveProductMutationVariables>(RemoveProductDocument, baseOptions);
      }
export type RemoveProductMutationHookResult = ReturnType<typeof useRemoveProductMutation>;
export type RemoveProductMutationResult = ApolloReactCommon.MutationResult<RemoveProductMutation>;
export type RemoveProductMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveProductMutation, RemoveProductMutationVariables>;
export const VerifyProductsInListDocument = gql`
    query verifyProductsInList($productId: Float!) {
  verifyProductsInList(productId: $productId) {
    id
    blockReason
    productId
    Product {
      id
      UserAccount {
        id
      }
    }
  }
}
    `;

/**
 * __useVerifyProductsInListQuery__
 *
 * To run a query within a React component, call `useVerifyProductsInListQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyProductsInListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyProductsInListQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useVerifyProductsInListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VerifyProductsInListQuery, VerifyProductsInListQueryVariables>) {
        return ApolloReactHooks.useQuery<VerifyProductsInListQuery, VerifyProductsInListQueryVariables>(VerifyProductsInListDocument, baseOptions);
      }
export function useVerifyProductsInListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VerifyProductsInListQuery, VerifyProductsInListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VerifyProductsInListQuery, VerifyProductsInListQueryVariables>(VerifyProductsInListDocument, baseOptions);
        }
export type VerifyProductsInListQueryHookResult = ReturnType<typeof useVerifyProductsInListQuery>;
export type VerifyProductsInListLazyQueryHookResult = ReturnType<typeof useVerifyProductsInListLazyQuery>;
export type VerifyProductsInListQueryResult = ApolloReactCommon.QueryResult<VerifyProductsInListQuery, VerifyProductsInListQueryVariables>;
export const PurchaseInCartDocument = gql`
    mutation PurchaseInCart($PurchaseInCartInput: PurchaseInCartInput!) {
  PurchaseInCart(purchaseInCartInput: $PurchaseInCartInput) {
    boleto_url
    pix_date_expiration
    pix_qrcode
    transaction_id
  }
}
    `;
export type PurchaseInCartMutationFn = ApolloReactCommon.MutationFunction<PurchaseInCartMutation, PurchaseInCartMutationVariables>;

/**
 * __usePurchaseInCartMutation__
 *
 * To run a mutation, you first call `usePurchaseInCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseInCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseInCartMutation, { data, loading, error }] = usePurchaseInCartMutation({
 *   variables: {
 *      PurchaseInCartInput: // value for 'PurchaseInCartInput'
 *   },
 * });
 */
export function usePurchaseInCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PurchaseInCartMutation, PurchaseInCartMutationVariables>) {
        return ApolloReactHooks.useMutation<PurchaseInCartMutation, PurchaseInCartMutationVariables>(PurchaseInCartDocument, baseOptions);
      }
export type PurchaseInCartMutationHookResult = ReturnType<typeof usePurchaseInCartMutation>;
export type PurchaseInCartMutationResult = ApolloReactCommon.MutationResult<PurchaseInCartMutation>;
export type PurchaseInCartMutationOptions = ApolloReactCommon.BaseMutationOptions<PurchaseInCartMutation, PurchaseInCartMutationVariables>;
export const CreateCartNotificationDocument = gql`
    mutation createCartNotification($userId: Float!, $productId: Float!) {
  createCartNotification(userId: $userId, productId: $productId)
}
    `;
export type CreateCartNotificationMutationFn = ApolloReactCommon.MutationFunction<CreateCartNotificationMutation, CreateCartNotificationMutationVariables>;

/**
 * __useCreateCartNotificationMutation__
 *
 * To run a mutation, you first call `useCreateCartNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCartNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCartNotificationMutation, { data, loading, error }] = useCreateCartNotificationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useCreateCartNotificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCartNotificationMutation, CreateCartNotificationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCartNotificationMutation, CreateCartNotificationMutationVariables>(CreateCartNotificationDocument, baseOptions);
      }
export type CreateCartNotificationMutationHookResult = ReturnType<typeof useCreateCartNotificationMutation>;
export type CreateCartNotificationMutationResult = ApolloReactCommon.MutationResult<CreateCartNotificationMutation>;
export type CreateCartNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCartNotificationMutation, CreateCartNotificationMutationVariables>;
export const GetProductsSoldByPeriodDocument = gql`
    query getProductsSoldByPeriod($period: Float!, $userId: Float!) {
  getProductsSoldByPeriod(period: $period, userId: $userId) {
    productId
    n_totalProduto
    Product {
      title
      price
      subtitle
      description
    }
  }
}
    `;

/**
 * __useGetProductsSoldByPeriodQuery__
 *
 * To run a query within a React component, call `useGetProductsSoldByPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsSoldByPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsSoldByPeriodQuery({
 *   variables: {
 *      period: // value for 'period'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetProductsSoldByPeriodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProductsSoldByPeriodQuery, GetProductsSoldByPeriodQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProductsSoldByPeriodQuery, GetProductsSoldByPeriodQueryVariables>(GetProductsSoldByPeriodDocument, baseOptions);
      }
export function useGetProductsSoldByPeriodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProductsSoldByPeriodQuery, GetProductsSoldByPeriodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProductsSoldByPeriodQuery, GetProductsSoldByPeriodQueryVariables>(GetProductsSoldByPeriodDocument, baseOptions);
        }
export type GetProductsSoldByPeriodQueryHookResult = ReturnType<typeof useGetProductsSoldByPeriodQuery>;
export type GetProductsSoldByPeriodLazyQueryHookResult = ReturnType<typeof useGetProductsSoldByPeriodLazyQuery>;
export type GetProductsSoldByPeriodQueryResult = ApolloReactCommon.QueryResult<GetProductsSoldByPeriodQuery, GetProductsSoldByPeriodQueryVariables>;
export const AtualizarStatuscompraDocument = gql`
    mutation atualizarStatuscompra($atualizarStatusCompraInputData: AtualizarStatusCompraInputData!) {
  atualizarStatusCompra(
    atualizarStatusCompraInputData: $atualizarStatusCompraInputData
  )
}
    `;
export type AtualizarStatuscompraMutationFn = ApolloReactCommon.MutationFunction<AtualizarStatuscompraMutation, AtualizarStatuscompraMutationVariables>;

/**
 * __useAtualizarStatuscompraMutation__
 *
 * To run a mutation, you first call `useAtualizarStatuscompraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAtualizarStatuscompraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [atualizarStatuscompraMutation, { data, loading, error }] = useAtualizarStatuscompraMutation({
 *   variables: {
 *      atualizarStatusCompraInputData: // value for 'atualizarStatusCompraInputData'
 *   },
 * });
 */
export function useAtualizarStatuscompraMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AtualizarStatuscompraMutation, AtualizarStatuscompraMutationVariables>) {
        return ApolloReactHooks.useMutation<AtualizarStatuscompraMutation, AtualizarStatuscompraMutationVariables>(AtualizarStatuscompraDocument, baseOptions);
      }
export type AtualizarStatuscompraMutationHookResult = ReturnType<typeof useAtualizarStatuscompraMutation>;
export type AtualizarStatuscompraMutationResult = ApolloReactCommon.MutationResult<AtualizarStatuscompraMutation>;
export type AtualizarStatuscompraMutationOptions = ApolloReactCommon.BaseMutationOptions<AtualizarStatuscompraMutation, AtualizarStatuscompraMutationVariables>;
export const GetQrCodePixDocument = gql`
    query getQrCodePix($purchaseId: Float!) {
  getQrCodePix(purchaseId: $purchaseId)
}
    `;

/**
 * __useGetQrCodePixQuery__
 *
 * To run a query within a React component, call `useGetQrCodePixQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQrCodePixQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQrCodePixQuery({
 *   variables: {
 *      purchaseId: // value for 'purchaseId'
 *   },
 * });
 */
export function useGetQrCodePixQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetQrCodePixQuery, GetQrCodePixQueryVariables>) {
        return ApolloReactHooks.useQuery<GetQrCodePixQuery, GetQrCodePixQueryVariables>(GetQrCodePixDocument, baseOptions);
      }
export function useGetQrCodePixLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetQrCodePixQuery, GetQrCodePixQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetQrCodePixQuery, GetQrCodePixQueryVariables>(GetQrCodePixDocument, baseOptions);
        }
export type GetQrCodePixQueryHookResult = ReturnType<typeof useGetQrCodePixQuery>;
export type GetQrCodePixLazyQueryHookResult = ReturnType<typeof useGetQrCodePixLazyQuery>;
export type GetQrCodePixQueryResult = ApolloReactCommon.QueryResult<GetQrCodePixQuery, GetQrCodePixQueryVariables>;
export const GetPurchaseStatusByPurchaseIdDocument = gql`
    query getPurchaseStatusByPurchaseId($getPurchaseStatusByPurchaseIdInputData: GetPurchaseStatusByPurchaseIdInputData!) {
  getPurchaseStatusByPurchaseId(
    getPurchaseStatusByPurchaseIdInputData: $getPurchaseStatusByPurchaseIdInputData
  )
}
    `;

/**
 * __useGetPurchaseStatusByPurchaseIdQuery__
 *
 * To run a query within a React component, call `useGetPurchaseStatusByPurchaseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseStatusByPurchaseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseStatusByPurchaseIdQuery({
 *   variables: {
 *      getPurchaseStatusByPurchaseIdInputData: // value for 'getPurchaseStatusByPurchaseIdInputData'
 *   },
 * });
 */
export function useGetPurchaseStatusByPurchaseIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPurchaseStatusByPurchaseIdQuery, GetPurchaseStatusByPurchaseIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPurchaseStatusByPurchaseIdQuery, GetPurchaseStatusByPurchaseIdQueryVariables>(GetPurchaseStatusByPurchaseIdDocument, baseOptions);
      }
export function useGetPurchaseStatusByPurchaseIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPurchaseStatusByPurchaseIdQuery, GetPurchaseStatusByPurchaseIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPurchaseStatusByPurchaseIdQuery, GetPurchaseStatusByPurchaseIdQueryVariables>(GetPurchaseStatusByPurchaseIdDocument, baseOptions);
        }
export type GetPurchaseStatusByPurchaseIdQueryHookResult = ReturnType<typeof useGetPurchaseStatusByPurchaseIdQuery>;
export type GetPurchaseStatusByPurchaseIdLazyQueryHookResult = ReturnType<typeof useGetPurchaseStatusByPurchaseIdLazyQuery>;
export type GetPurchaseStatusByPurchaseIdQueryResult = ApolloReactCommon.QueryResult<GetPurchaseStatusByPurchaseIdQuery, GetPurchaseStatusByPurchaseIdQueryVariables>;
export const AddInvoiceDocument = gql`
    mutation addInvoice($purchaseInvoice: Upload!, $purchaseId: Float!) {
  addInvoice(purchaseInvoice: $purchaseInvoice, purchaseId: $purchaseId)
}
    `;
export type AddInvoiceMutationFn = ApolloReactCommon.MutationFunction<AddInvoiceMutation, AddInvoiceMutationVariables>;

/**
 * __useAddInvoiceMutation__
 *
 * To run a mutation, you first call `useAddInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInvoiceMutation, { data, loading, error }] = useAddInvoiceMutation({
 *   variables: {
 *      purchaseInvoice: // value for 'purchaseInvoice'
 *      purchaseId: // value for 'purchaseId'
 *   },
 * });
 */
export function useAddInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddInvoiceMutation, AddInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<AddInvoiceMutation, AddInvoiceMutationVariables>(AddInvoiceDocument, baseOptions);
      }
export type AddInvoiceMutationHookResult = ReturnType<typeof useAddInvoiceMutation>;
export type AddInvoiceMutationResult = ApolloReactCommon.MutationResult<AddInvoiceMutation>;
export type AddInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<AddInvoiceMutation, AddInvoiceMutationVariables>;
export const CreateReportDocument = gql`
    mutation createReport($groundsForReportId: Float!, $userAccountId: Float!, $productId: Float!) {
  createReport(
    groundsForReportId: $groundsForReportId
    userAccountId: $userAccountId
    productId: $productId
  ) {
    id
  }
}
    `;
export type CreateReportMutationFn = ApolloReactCommon.MutationFunction<CreateReportMutation, CreateReportMutationVariables>;

/**
 * __useCreateReportMutation__
 *
 * To run a mutation, you first call `useCreateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportMutation, { data, loading, error }] = useCreateReportMutation({
 *   variables: {
 *      groundsForReportId: // value for 'groundsForReportId'
 *      userAccountId: // value for 'userAccountId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useCreateReportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateReportMutation, CreateReportMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateReportMutation, CreateReportMutationVariables>(CreateReportDocument, baseOptions);
      }
export type CreateReportMutationHookResult = ReturnType<typeof useCreateReportMutation>;
export type CreateReportMutationResult = ApolloReactCommon.MutationResult<CreateReportMutation>;
export type CreateReportMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateReportMutation, CreateReportMutationVariables>;
export const GetAllReportsDocument = gql`
    query getAllReports {
  getAllReports {
    id
    GroundsForReport {
      id
      title
    }
    UserAccount {
      id
      name
    }
    Product {
      id
      title
    }
  }
}
    `;

/**
 * __useGetAllReportsQuery__
 *
 * To run a query within a React component, call `useGetAllReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllReportsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllReportsQuery, GetAllReportsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllReportsQuery, GetAllReportsQueryVariables>(GetAllReportsDocument, baseOptions);
      }
export function useGetAllReportsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllReportsQuery, GetAllReportsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllReportsQuery, GetAllReportsQueryVariables>(GetAllReportsDocument, baseOptions);
        }
export type GetAllReportsQueryHookResult = ReturnType<typeof useGetAllReportsQuery>;
export type GetAllReportsLazyQueryHookResult = ReturnType<typeof useGetAllReportsLazyQuery>;
export type GetAllReportsQueryResult = ApolloReactCommon.QueryResult<GetAllReportsQuery, GetAllReportsQueryVariables>;
export const GetShippingsByUserIdDocument = gql`
    query getShippingsByUserId($userId: Float!) {
  getShippingsByUserId(userId: $userId) {
    id
    description
    carrier
    startPostalCode
    endPostalCode
    minimumWeight
    maximumWeight
    longestLength
    price
    deliveryTime
    carrierLogo
  }
}
    `;

/**
 * __useGetShippingsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetShippingsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShippingsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShippingsByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetShippingsByUserIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShippingsByUserIdQuery, GetShippingsByUserIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShippingsByUserIdQuery, GetShippingsByUserIdQueryVariables>(GetShippingsByUserIdDocument, baseOptions);
      }
export function useGetShippingsByUserIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShippingsByUserIdQuery, GetShippingsByUserIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShippingsByUserIdQuery, GetShippingsByUserIdQueryVariables>(GetShippingsByUserIdDocument, baseOptions);
        }
export type GetShippingsByUserIdQueryHookResult = ReturnType<typeof useGetShippingsByUserIdQuery>;
export type GetShippingsByUserIdLazyQueryHookResult = ReturnType<typeof useGetShippingsByUserIdLazyQuery>;
export type GetShippingsByUserIdQueryResult = ApolloReactCommon.QueryResult<GetShippingsByUserIdQuery, GetShippingsByUserIdQueryVariables>;
export const GetShippingsByQuoteDocument = gql`
    query getShippingsByQuote($productId: Float!, $postalCode: Float!) {
  getShippingsByQuote(productId: $productId, postalCode: $postalCode) {
    id
    description
    carrier
    startPostalCode
    endPostalCode
    minimumWeight
    maximumWeight
    longestLength
    price
    deliveryTime
    carrierLogo
  }
}
    `;

/**
 * __useGetShippingsByQuoteQuery__
 *
 * To run a query within a React component, call `useGetShippingsByQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShippingsByQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShippingsByQuoteQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      postalCode: // value for 'postalCode'
 *   },
 * });
 */
export function useGetShippingsByQuoteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShippingsByQuoteQuery, GetShippingsByQuoteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShippingsByQuoteQuery, GetShippingsByQuoteQueryVariables>(GetShippingsByQuoteDocument, baseOptions);
      }
export function useGetShippingsByQuoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShippingsByQuoteQuery, GetShippingsByQuoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShippingsByQuoteQuery, GetShippingsByQuoteQueryVariables>(GetShippingsByQuoteDocument, baseOptions);
        }
export type GetShippingsByQuoteQueryHookResult = ReturnType<typeof useGetShippingsByQuoteQuery>;
export type GetShippingsByQuoteLazyQueryHookResult = ReturnType<typeof useGetShippingsByQuoteLazyQuery>;
export type GetShippingsByQuoteQueryResult = ApolloReactCommon.QueryResult<GetShippingsByQuoteQuery, GetShippingsByQuoteQueryVariables>;
export const GetShippingsAsCsvDocument = gql`
    query getShippingsAsCsv($userId: Float!, $limit: Float, $offset: Float) {
  getShippingsAsCsv(userId: $userId, limit: $limit, offset: $offset)
}
    `;

/**
 * __useGetShippingsAsCsvQuery__
 *
 * To run a query within a React component, call `useGetShippingsAsCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShippingsAsCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShippingsAsCsvQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetShippingsAsCsvQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShippingsAsCsvQuery, GetShippingsAsCsvQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShippingsAsCsvQuery, GetShippingsAsCsvQueryVariables>(GetShippingsAsCsvDocument, baseOptions);
      }
export function useGetShippingsAsCsvLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShippingsAsCsvQuery, GetShippingsAsCsvQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShippingsAsCsvQuery, GetShippingsAsCsvQueryVariables>(GetShippingsAsCsvDocument, baseOptions);
        }
export type GetShippingsAsCsvQueryHookResult = ReturnType<typeof useGetShippingsAsCsvQuery>;
export type GetShippingsAsCsvLazyQueryHookResult = ReturnType<typeof useGetShippingsAsCsvLazyQuery>;
export type GetShippingsAsCsvQueryResult = ApolloReactCommon.QueryResult<GetShippingsAsCsvQuery, GetShippingsAsCsvQueryVariables>;
export const PutShippingsDocument = gql`
    mutation putShippings($userId: Float!, $shippingInput: [ShippingInput!]!) {
  putShippings(userId: $userId, shippingInput: $shippingInput)
}
    `;
export type PutShippingsMutationFn = ApolloReactCommon.MutationFunction<PutShippingsMutation, PutShippingsMutationVariables>;

/**
 * __usePutShippingsMutation__
 *
 * To run a mutation, you first call `usePutShippingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePutShippingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [putShippingsMutation, { data, loading, error }] = usePutShippingsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      shippingInput: // value for 'shippingInput'
 *   },
 * });
 */
export function usePutShippingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PutShippingsMutation, PutShippingsMutationVariables>) {
        return ApolloReactHooks.useMutation<PutShippingsMutation, PutShippingsMutationVariables>(PutShippingsDocument, baseOptions);
      }
export type PutShippingsMutationHookResult = ReturnType<typeof usePutShippingsMutation>;
export type PutShippingsMutationResult = ApolloReactCommon.MutationResult<PutShippingsMutation>;
export type PutShippingsMutationOptions = ApolloReactCommon.BaseMutationOptions<PutShippingsMutation, PutShippingsMutationVariables>;
export const PutShippingsFromCsvDocument = gql`
    mutation putShippingsFromCsv($userId: Float!, $csv: Upload!) {
  putShippingsFromCsv(userId: $userId, csv: $csv)
}
    `;
export type PutShippingsFromCsvMutationFn = ApolloReactCommon.MutationFunction<PutShippingsFromCsvMutation, PutShippingsFromCsvMutationVariables>;

/**
 * __usePutShippingsFromCsvMutation__
 *
 * To run a mutation, you first call `usePutShippingsFromCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePutShippingsFromCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [putShippingsFromCsvMutation, { data, loading, error }] = usePutShippingsFromCsvMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      csv: // value for 'csv'
 *   },
 * });
 */
export function usePutShippingsFromCsvMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PutShippingsFromCsvMutation, PutShippingsFromCsvMutationVariables>) {
        return ApolloReactHooks.useMutation<PutShippingsFromCsvMutation, PutShippingsFromCsvMutationVariables>(PutShippingsFromCsvDocument, baseOptions);
      }
export type PutShippingsFromCsvMutationHookResult = ReturnType<typeof usePutShippingsFromCsvMutation>;
export type PutShippingsFromCsvMutationResult = ApolloReactCommon.MutationResult<PutShippingsFromCsvMutation>;
export type PutShippingsFromCsvMutationOptions = ApolloReactCommon.BaseMutationOptions<PutShippingsFromCsvMutation, PutShippingsFromCsvMutationVariables>;
export const DeleteShippingsDocument = gql`
    mutation deleteShippings($userId: Float!, $shippingIds: [Float!]!) {
  deleteShippings(userId: $userId, shippingIds: $shippingIds)
}
    `;
export type DeleteShippingsMutationFn = ApolloReactCommon.MutationFunction<DeleteShippingsMutation, DeleteShippingsMutationVariables>;

/**
 * __useDeleteShippingsMutation__
 *
 * To run a mutation, you first call `useDeleteShippingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShippingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShippingsMutation, { data, loading, error }] = useDeleteShippingsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      shippingIds: // value for 'shippingIds'
 *   },
 * });
 */
export function useDeleteShippingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteShippingsMutation, DeleteShippingsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteShippingsMutation, DeleteShippingsMutationVariables>(DeleteShippingsDocument, baseOptions);
      }
export type DeleteShippingsMutationHookResult = ReturnType<typeof useDeleteShippingsMutation>;
export type DeleteShippingsMutationResult = ApolloReactCommon.MutationResult<DeleteShippingsMutation>;
export type DeleteShippingsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteShippingsMutation, DeleteShippingsMutationVariables>;
export const GetAllStandardShopBannersDocument = gql`
    query getAllStandardShopBanners($userId: Float!) {
  getAllStandardShopBanners(userId: $userId) {
    standardBannersVariable {
      id
      userAccountId
      name
      dataType
    }
    userBanners {
      id
      userAccountId
      name
      dataType
    }
  }
}
    `;

/**
 * __useGetAllStandardShopBannersQuery__
 *
 * To run a query within a React component, call `useGetAllStandardShopBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllStandardShopBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllStandardShopBannersQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAllStandardShopBannersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllStandardShopBannersQuery, GetAllStandardShopBannersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllStandardShopBannersQuery, GetAllStandardShopBannersQueryVariables>(GetAllStandardShopBannersDocument, baseOptions);
      }
export function useGetAllStandardShopBannersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllStandardShopBannersQuery, GetAllStandardShopBannersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllStandardShopBannersQuery, GetAllStandardShopBannersQueryVariables>(GetAllStandardShopBannersDocument, baseOptions);
        }
export type GetAllStandardShopBannersQueryHookResult = ReturnType<typeof useGetAllStandardShopBannersQuery>;
export type GetAllStandardShopBannersLazyQueryHookResult = ReturnType<typeof useGetAllStandardShopBannersLazyQuery>;
export type GetAllStandardShopBannersQueryResult = ApolloReactCommon.QueryResult<GetAllStandardShopBannersQuery, GetAllStandardShopBannersQueryVariables>;
export const CreateShopBannerDocument = gql`
    mutation createShopBanner($name: String!, $Picture: Upload!, $userId: Float!) {
  createShopBanner(name: $name, Picture: $Picture, userId: $userId) {
    id
    userAccountId
    name
    dataType
  }
}
    `;
export type CreateShopBannerMutationFn = ApolloReactCommon.MutationFunction<CreateShopBannerMutation, CreateShopBannerMutationVariables>;

/**
 * __useCreateShopBannerMutation__
 *
 * To run a mutation, you first call `useCreateShopBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShopBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShopBannerMutation, { data, loading, error }] = useCreateShopBannerMutation({
 *   variables: {
 *      name: // value for 'name'
 *      Picture: // value for 'Picture'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateShopBannerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateShopBannerMutation, CreateShopBannerMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateShopBannerMutation, CreateShopBannerMutationVariables>(CreateShopBannerDocument, baseOptions);
      }
export type CreateShopBannerMutationHookResult = ReturnType<typeof useCreateShopBannerMutation>;
export type CreateShopBannerMutationResult = ApolloReactCommon.MutationResult<CreateShopBannerMutation>;
export type CreateShopBannerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateShopBannerMutation, CreateShopBannerMutationVariables>;
export const CreateBannerDocument = gql`
    mutation createBanner($createBannerInput: CreateBannerInput!, $Picture: Upload!) {
  createBanner(createBannerInput: $createBannerInput, Picture: $Picture)
}
    `;
export type CreateBannerMutationFn = ApolloReactCommon.MutationFunction<CreateBannerMutation, CreateBannerMutationVariables>;

/**
 * __useCreateBannerMutation__
 *
 * To run a mutation, you first call `useCreateBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBannerMutation, { data, loading, error }] = useCreateBannerMutation({
 *   variables: {
 *      createBannerInput: // value for 'createBannerInput'
 *      Picture: // value for 'Picture'
 *   },
 * });
 */
export function useCreateBannerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBannerMutation, CreateBannerMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBannerMutation, CreateBannerMutationVariables>(CreateBannerDocument, baseOptions);
      }
export type CreateBannerMutationHookResult = ReturnType<typeof useCreateBannerMutation>;
export type CreateBannerMutationResult = ApolloReactCommon.MutationResult<CreateBannerMutation>;
export type CreateBannerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBannerMutation, CreateBannerMutationVariables>;
export const CreateUserAccountDocument = gql`
    mutation createUserAccount($name: String!, $userTypeId: Float!, $cpf: String!, $email: String!, $password: String!, $ratting: Float!) {
  createUserAccount(
    accountInput: {name: $name, userTypeId: $userTypeId, cpf: $cpf, email: $email, password: $password, ratting: $ratting}
  ) {
    id
    email
  }
}
    `;
export type CreateUserAccountMutationFn = ApolloReactCommon.MutationFunction<CreateUserAccountMutation, CreateUserAccountMutationVariables>;

/**
 * __useCreateUserAccountMutation__
 *
 * To run a mutation, you first call `useCreateUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserAccountMutation, { data, loading, error }] = useCreateUserAccountMutation({
 *   variables: {
 *      name: // value for 'name'
 *      userTypeId: // value for 'userTypeId'
 *      cpf: // value for 'cpf'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      ratting: // value for 'ratting'
 *   },
 * });
 */
export function useCreateUserAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserAccountMutation, CreateUserAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserAccountMutation, CreateUserAccountMutationVariables>(CreateUserAccountDocument, baseOptions);
      }
export type CreateUserAccountMutationHookResult = ReturnType<typeof useCreateUserAccountMutation>;
export type CreateUserAccountMutationResult = ApolloReactCommon.MutationResult<CreateUserAccountMutation>;
export type CreateUserAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserAccountMutation, CreateUserAccountMutationVariables>;
export const CreateUseraccountWithAdmUserDocument = gql`
    mutation createUseraccountWithAdmUser($email: String!, $name: String!, $userTypeId: Float!, $newSocialProjectTypeInput: NewSocialProjectTypeInput) {
  createUseraccountWithAdmUser(
    email: $email
    name: $name
    userTypeId: $userTypeId
    newSocialProjectTypeInput: $newSocialProjectTypeInput
  ) {
    email
    password
    id
    name
    userTypeId
    UserType {
      id
      name
    }
  }
}
    `;
export type CreateUseraccountWithAdmUserMutationFn = ApolloReactCommon.MutationFunction<CreateUseraccountWithAdmUserMutation, CreateUseraccountWithAdmUserMutationVariables>;

/**
 * __useCreateUseraccountWithAdmUserMutation__
 *
 * To run a mutation, you first call `useCreateUseraccountWithAdmUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUseraccountWithAdmUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUseraccountWithAdmUserMutation, { data, loading, error }] = useCreateUseraccountWithAdmUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      userTypeId: // value for 'userTypeId'
 *      newSocialProjectTypeInput: // value for 'newSocialProjectTypeInput'
 *   },
 * });
 */
export function useCreateUseraccountWithAdmUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUseraccountWithAdmUserMutation, CreateUseraccountWithAdmUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUseraccountWithAdmUserMutation, CreateUseraccountWithAdmUserMutationVariables>(CreateUseraccountWithAdmUserDocument, baseOptions);
      }
export type CreateUseraccountWithAdmUserMutationHookResult = ReturnType<typeof useCreateUseraccountWithAdmUserMutation>;
export type CreateUseraccountWithAdmUserMutationResult = ApolloReactCommon.MutationResult<CreateUseraccountWithAdmUserMutation>;
export type CreateUseraccountWithAdmUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUseraccountWithAdmUserMutation, CreateUseraccountWithAdmUserMutationVariables>;
export const DeleteAddressDocument = gql`
    mutation deleteAddress($addressId: Float!) {
  deleteAddress(addressId: $addressId)
}
    `;
export type DeleteAddressMutationFn = ApolloReactCommon.MutationFunction<DeleteAddressMutation, DeleteAddressMutationVariables>;

/**
 * __useDeleteAddressMutation__
 *
 * To run a mutation, you first call `useDeleteAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAddressMutation, { data, loading, error }] = useDeleteAddressMutation({
 *   variables: {
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useDeleteAddressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAddressMutation, DeleteAddressMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAddressMutation, DeleteAddressMutationVariables>(DeleteAddressDocument, baseOptions);
      }
export type DeleteAddressMutationHookResult = ReturnType<typeof useDeleteAddressMutation>;
export type DeleteAddressMutationResult = ApolloReactCommon.MutationResult<DeleteAddressMutation>;
export type DeleteAddressMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAddressMutation, DeleteAddressMutationVariables>;
export const GetAllAddressDocument = gql`
    query getAllAddress($userId: Float!) {
  getAllAddress(userId: $userId) {
    id
    state
    city
    street
    addressTitle
    neighborhood
    number
    CEP
    complement
    references
  }
}
    `;

/**
 * __useGetAllAddressQuery__
 *
 * To run a query within a React component, call `useGetAllAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAddressQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAllAddressQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllAddressQuery, GetAllAddressQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllAddressQuery, GetAllAddressQueryVariables>(GetAllAddressDocument, baseOptions);
      }
export function useGetAllAddressLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllAddressQuery, GetAllAddressQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllAddressQuery, GetAllAddressQueryVariables>(GetAllAddressDocument, baseOptions);
        }
export type GetAllAddressQueryHookResult = ReturnType<typeof useGetAllAddressQuery>;
export type GetAllAddressLazyQueryHookResult = ReturnType<typeof useGetAllAddressLazyQuery>;
export type GetAllAddressQueryResult = ApolloReactCommon.QueryResult<GetAllAddressQuery, GetAllAddressQueryVariables>;
export const UpdateUserAccountDocument = gql`
    mutation updateUserAccount($id: Float!, $phone: String, $name: String, $cpf: String, $email: String, $ratting: Float) {
  updateUserAccount(
    id: $id
    accountInput: {phone: $phone, name: $name, cpf: $cpf, email: $email, ratting: $ratting}
  ) {
    id
    name
    userTypeId
    cpf
    RG
    email
    password
    cardId
    ratting
    ProfileAddress {
      id
      state
      city
      street
      addressTitle
      neighborhood
      number
      CEP
      complement
      references
    }
    ProfileContacts {
      id
      phone
    }
  }
}
    `;
export type UpdateUserAccountMutationFn = ApolloReactCommon.MutationFunction<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>;

/**
 * __useUpdateUserAccountMutation__
 *
 * To run a mutation, you first call `useUpdateUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAccountMutation, { data, loading, error }] = useUpdateUserAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      phone: // value for 'phone'
 *      name: // value for 'name'
 *      cpf: // value for 'cpf'
 *      email: // value for 'email'
 *      ratting: // value for 'ratting'
 *   },
 * });
 */
export function useUpdateUserAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>(UpdateUserAccountDocument, baseOptions);
      }
export type UpdateUserAccountMutationHookResult = ReturnType<typeof useUpdateUserAccountMutation>;
export type UpdateUserAccountMutationResult = ApolloReactCommon.MutationResult<UpdateUserAccountMutation>;
export type UpdateUserAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>;
export const GetAllUserAccountsDocument = gql`
    query getAllUserAccounts {
  getAllUserAccounts {
    id
    name
    email
    userTypeId
    UserType {
      id
      name
    }
    ProfileContacts {
      id
      phone
    }
  }
}
    `;

/**
 * __useGetAllUserAccountsQuery__
 *
 * To run a query within a React component, call `useGetAllUserAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUserAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUserAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUserAccountsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllUserAccountsQuery, GetAllUserAccountsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllUserAccountsQuery, GetAllUserAccountsQueryVariables>(GetAllUserAccountsDocument, baseOptions);
      }
export function useGetAllUserAccountsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllUserAccountsQuery, GetAllUserAccountsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllUserAccountsQuery, GetAllUserAccountsQueryVariables>(GetAllUserAccountsDocument, baseOptions);
        }
export type GetAllUserAccountsQueryHookResult = ReturnType<typeof useGetAllUserAccountsQuery>;
export type GetAllUserAccountsLazyQueryHookResult = ReturnType<typeof useGetAllUserAccountsLazyQuery>;
export type GetAllUserAccountsQueryResult = ApolloReactCommon.QueryResult<GetAllUserAccountsQuery, GetAllUserAccountsQueryVariables>;
export const GetUserAccountDocument = gql`
    query getUserAccount($userAccountId: String!) {
  getUserAccount(userAccountId: $userAccountId) {
    id
    name
    recipientId
    bankAccountId
    token
    cpf
    RG
    email
  }
}
    `;

/**
 * __useGetUserAccountQuery__
 *
 * To run a query within a React component, call `useGetUserAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAccountQuery({
 *   variables: {
 *      userAccountId: // value for 'userAccountId'
 *   },
 * });
 */
export function useGetUserAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserAccountQuery, GetUserAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserAccountQuery, GetUserAccountQueryVariables>(GetUserAccountDocument, baseOptions);
      }
export function useGetUserAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserAccountQuery, GetUserAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserAccountQuery, GetUserAccountQueryVariables>(GetUserAccountDocument, baseOptions);
        }
export type GetUserAccountQueryHookResult = ReturnType<typeof useGetUserAccountQuery>;
export type GetUserAccountLazyQueryHookResult = ReturnType<typeof useGetUserAccountLazyQuery>;
export type GetUserAccountQueryResult = ApolloReactCommon.QueryResult<GetUserAccountQuery, GetUserAccountQueryVariables>;
export const CreateUserImageDocument = gql`
    mutation createUserImage($userId: Float!, $Picture: Upload!) {
  createUserImage(userId: $userId, Picture: $Picture)
}
    `;
export type CreateUserImageMutationFn = ApolloReactCommon.MutationFunction<CreateUserImageMutation, CreateUserImageMutationVariables>;

/**
 * __useCreateUserImageMutation__
 *
 * To run a mutation, you first call `useCreateUserImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserImageMutation, { data, loading, error }] = useCreateUserImageMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      Picture: // value for 'Picture'
 *   },
 * });
 */
export function useCreateUserImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserImageMutation, CreateUserImageMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserImageMutation, CreateUserImageMutationVariables>(CreateUserImageDocument, baseOptions);
      }
export type CreateUserImageMutationHookResult = ReturnType<typeof useCreateUserImageMutation>;
export type CreateUserImageMutationResult = ApolloReactCommon.MutationResult<CreateUserImageMutation>;
export type CreateUserImageMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserImageMutation, CreateUserImageMutationVariables>;
export const CreateAddressDocument = gql`
    mutation createAddress($addressTitle: String, $CEP: String, $complement: String, $references: String, $street: String, $neighborhood: String, $number: String, $id: Float!, $city: String, $state: String, $phone: String!) {
  createAddress(
    userAccountUpdateAddressInput: {addressTitle: $addressTitle, CEP: $CEP, complement: $complement, references: $references, street: $street, neighborhood: $neighborhood, number: $number, city: $city, state: $state, phone: $phone}
    id: $id
  ) {
    id
    state
    city
    street
    addressTitle
    neighborhood
    number
    CEP
    complement
    references
    userAccountId
  }
}
    `;
export type CreateAddressMutationFn = ApolloReactCommon.MutationFunction<CreateAddressMutation, CreateAddressMutationVariables>;

/**
 * __useCreateAddressMutation__
 *
 * To run a mutation, you first call `useCreateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAddressMutation, { data, loading, error }] = useCreateAddressMutation({
 *   variables: {
 *      addressTitle: // value for 'addressTitle'
 *      CEP: // value for 'CEP'
 *      complement: // value for 'complement'
 *      references: // value for 'references'
 *      street: // value for 'street'
 *      neighborhood: // value for 'neighborhood'
 *      number: // value for 'number'
 *      id: // value for 'id'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useCreateAddressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAddressMutation, CreateAddressMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAddressMutation, CreateAddressMutationVariables>(CreateAddressDocument, baseOptions);
      }
export type CreateAddressMutationHookResult = ReturnType<typeof useCreateAddressMutation>;
export type CreateAddressMutationResult = ApolloReactCommon.MutationResult<CreateAddressMutation>;
export type CreateAddressMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAddressMutation, CreateAddressMutationVariables>;
export const ResetUserPasswordDocument = gql`
    mutation resetUserPassword($email: String!) {
  resetUserPassword(email: $email)
}
    `;
export type ResetUserPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>;

/**
 * __useResetUserPasswordMutation__
 *
 * To run a mutation, you first call `useResetUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUserPasswordMutation, { data, loading, error }] = useResetUserPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetUserPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>(ResetUserPasswordDocument, baseOptions);
      }
export type ResetUserPasswordMutationHookResult = ReturnType<typeof useResetUserPasswordMutation>;
export type ResetUserPasswordMutationResult = ApolloReactCommon.MutationResult<ResetUserPasswordMutation>;
export type ResetUserPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>;
export const UserAccountFilterDocument = gql`
    query userAccountFilter($includeSearch: Boolean!, $searchValue: String, $name: [String!]!) {
  userAccountFilter(
    userAccountFilterInput: {includeSearch: $includeSearch, searchValue: $searchValue, name: $name}
  ) {
    id
    name
    email
    UserType {
      id
      name
    }
    ProfileContacts {
      id
      phone
    }
  }
}
    `;

/**
 * __useUserAccountFilterQuery__
 *
 * To run a query within a React component, call `useUserAccountFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAccountFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAccountFilterQuery({
 *   variables: {
 *      includeSearch: // value for 'includeSearch'
 *      searchValue: // value for 'searchValue'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUserAccountFilterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserAccountFilterQuery, UserAccountFilterQueryVariables>) {
        return ApolloReactHooks.useQuery<UserAccountFilterQuery, UserAccountFilterQueryVariables>(UserAccountFilterDocument, baseOptions);
      }
export function useUserAccountFilterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserAccountFilterQuery, UserAccountFilterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserAccountFilterQuery, UserAccountFilterQueryVariables>(UserAccountFilterDocument, baseOptions);
        }
export type UserAccountFilterQueryHookResult = ReturnType<typeof useUserAccountFilterQuery>;
export type UserAccountFilterLazyQueryHookResult = ReturnType<typeof useUserAccountFilterLazyQuery>;
export type UserAccountFilterQueryResult = ApolloReactCommon.QueryResult<UserAccountFilterQuery, UserAccountFilterQueryVariables>;
export const BlockUserDocument = gql`
    mutation blockUser($userId: Float!, $block: Boolean!) {
  blockUser(userId: $userId, block: $block)
}
    `;
export type BlockUserMutationFn = ApolloReactCommon.MutationFunction<BlockUserMutation, BlockUserMutationVariables>;

/**
 * __useBlockUserMutation__
 *
 * To run a mutation, you first call `useBlockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockUserMutation, { data, loading, error }] = useBlockUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      block: // value for 'block'
 *   },
 * });
 */
export function useBlockUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BlockUserMutation, BlockUserMutationVariables>) {
        return ApolloReactHooks.useMutation<BlockUserMutation, BlockUserMutationVariables>(BlockUserDocument, baseOptions);
      }
export type BlockUserMutationHookResult = ReturnType<typeof useBlockUserMutation>;
export type BlockUserMutationResult = ApolloReactCommon.MutationResult<BlockUserMutation>;
export type BlockUserMutationOptions = ApolloReactCommon.BaseMutationOptions<BlockUserMutation, BlockUserMutationVariables>;
export const GetBlockStatusDocument = gql`
    query getBlockStatus($userId: Float!) {
  getBlockStatus(userId: $userId)
}
    `;

/**
 * __useGetBlockStatusQuery__
 *
 * To run a query within a React component, call `useGetBlockStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlockStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlockStatusQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetBlockStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBlockStatusQuery, GetBlockStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBlockStatusQuery, GetBlockStatusQueryVariables>(GetBlockStatusDocument, baseOptions);
      }
export function useGetBlockStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBlockStatusQuery, GetBlockStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBlockStatusQuery, GetBlockStatusQueryVariables>(GetBlockStatusDocument, baseOptions);
        }
export type GetBlockStatusQueryHookResult = ReturnType<typeof useGetBlockStatusQuery>;
export type GetBlockStatusLazyQueryHookResult = ReturnType<typeof useGetBlockStatusLazyQuery>;
export type GetBlockStatusQueryResult = ApolloReactCommon.QueryResult<GetBlockStatusQuery, GetBlockStatusQueryVariables>;
export const ReportBugDocument = gql`
    mutation reportBug($contactEmail: String!, $problemDescription: String!) {
  reportBug(contactEmail: $contactEmail, problemDescription: $problemDescription)
}
    `;
export type ReportBugMutationFn = ApolloReactCommon.MutationFunction<ReportBugMutation, ReportBugMutationVariables>;

/**
 * __useReportBugMutation__
 *
 * To run a mutation, you first call `useReportBugMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportBugMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportBugMutation, { data, loading, error }] = useReportBugMutation({
 *   variables: {
 *      contactEmail: // value for 'contactEmail'
 *      problemDescription: // value for 'problemDescription'
 *   },
 * });
 */
export function useReportBugMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReportBugMutation, ReportBugMutationVariables>) {
        return ApolloReactHooks.useMutation<ReportBugMutation, ReportBugMutationVariables>(ReportBugDocument, baseOptions);
      }
export type ReportBugMutationHookResult = ReturnType<typeof useReportBugMutation>;
export type ReportBugMutationResult = ApolloReactCommon.MutationResult<ReportBugMutation>;
export type ReportBugMutationOptions = ApolloReactCommon.BaseMutationOptions<ReportBugMutation, ReportBugMutationVariables>;
export const SendConfirmationEmailDocument = gql`
    mutation sendConfirmationEmail($email: String!) {
  sendConfirmationEmail(email: $email)
}
    `;
export type SendConfirmationEmailMutationFn = ApolloReactCommon.MutationFunction<SendConfirmationEmailMutation, SendConfirmationEmailMutationVariables>;

/**
 * __useSendConfirmationEmailMutation__
 *
 * To run a mutation, you first call `useSendConfirmationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendConfirmationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendConfirmationEmailMutation, { data, loading, error }] = useSendConfirmationEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendConfirmationEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendConfirmationEmailMutation, SendConfirmationEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<SendConfirmationEmailMutation, SendConfirmationEmailMutationVariables>(SendConfirmationEmailDocument, baseOptions);
      }
export type SendConfirmationEmailMutationHookResult = ReturnType<typeof useSendConfirmationEmailMutation>;
export type SendConfirmationEmailMutationResult = ApolloReactCommon.MutationResult<SendConfirmationEmailMutation>;
export type SendConfirmationEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendConfirmationEmailMutation, SendConfirmationEmailMutationVariables>;
export const DeleteBankAccountDocument = gql`
    mutation deleteBankAccount($id: Float!) {
  deleteBankAccount(id: $id)
}
    `;
export type DeleteBankAccountMutationFn = ApolloReactCommon.MutationFunction<DeleteBankAccountMutation, DeleteBankAccountMutationVariables>;

/**
 * __useDeleteBankAccountMutation__
 *
 * To run a mutation, you first call `useDeleteBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBankAccountMutation, { data, loading, error }] = useDeleteBankAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBankAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBankAccountMutation, DeleteBankAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteBankAccountMutation, DeleteBankAccountMutationVariables>(DeleteBankAccountDocument, baseOptions);
      }
export type DeleteBankAccountMutationHookResult = ReturnType<typeof useDeleteBankAccountMutation>;
export type DeleteBankAccountMutationResult = ApolloReactCommon.MutationResult<DeleteBankAccountMutation>;
export type DeleteBankAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteBankAccountMutation, DeleteBankAccountMutationVariables>;
export const GetUserHasShippingServiceDocument = gql`
    query getUserHasShippingService($userId: Float!) {
  getUserHasShippingService(userId: $userId)
}
    `;

/**
 * __useGetUserHasShippingServiceQuery__
 *
 * To run a query within a React component, call `useGetUserHasShippingServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserHasShippingServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserHasShippingServiceQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserHasShippingServiceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserHasShippingServiceQuery, GetUserHasShippingServiceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserHasShippingServiceQuery, GetUserHasShippingServiceQueryVariables>(GetUserHasShippingServiceDocument, baseOptions);
      }
export function useGetUserHasShippingServiceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserHasShippingServiceQuery, GetUserHasShippingServiceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserHasShippingServiceQuery, GetUserHasShippingServiceQueryVariables>(GetUserHasShippingServiceDocument, baseOptions);
        }
export type GetUserHasShippingServiceQueryHookResult = ReturnType<typeof useGetUserHasShippingServiceQuery>;
export type GetUserHasShippingServiceLazyQueryHookResult = ReturnType<typeof useGetUserHasShippingServiceLazyQuery>;
export type GetUserHasShippingServiceQueryResult = ApolloReactCommon.QueryResult<GetUserHasShippingServiceQuery, GetUserHasShippingServiceQueryVariables>;
export const ValidateConectionDocument = gql`
    query validateConection($userId: Float!) {
  validateConection(userId: $userId)
}
    `;

/**
 * __useValidateConectionQuery__
 *
 * To run a query within a React component, call `useValidateConectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateConectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateConectionQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useValidateConectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ValidateConectionQuery, ValidateConectionQueryVariables>) {
        return ApolloReactHooks.useQuery<ValidateConectionQuery, ValidateConectionQueryVariables>(ValidateConectionDocument, baseOptions);
      }
export function useValidateConectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ValidateConectionQuery, ValidateConectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ValidateConectionQuery, ValidateConectionQueryVariables>(ValidateConectionDocument, baseOptions);
        }
export type ValidateConectionQueryHookResult = ReturnType<typeof useValidateConectionQuery>;
export type ValidateConectionLazyQueryHookResult = ReturnType<typeof useValidateConectionLazyQuery>;
export type ValidateConectionQueryResult = ApolloReactCommon.QueryResult<ValidateConectionQuery, ValidateConectionQueryVariables>;
export const CreateUserCustomizationDocument = gql`
    mutation createUserCustomization($background: String, $backgroundIsImage: Boolean, $brand: String, $brandIsImage: Boolean, $brandTextColor: String, $shopName: String, $userId: Float!, $brandColor: String) {
  createUserCustomization(
    background: $background
    backgroundIsImage: $backgroundIsImage
    brand: $brand
    brandIsImage: $brandIsImage
    brandTextColor: $brandTextColor
    shopName: $shopName
    userId: $userId
    brandColor: $brandColor
  ) {
    id
  }
}
    `;
export type CreateUserCustomizationMutationFn = ApolloReactCommon.MutationFunction<CreateUserCustomizationMutation, CreateUserCustomizationMutationVariables>;

/**
 * __useCreateUserCustomizationMutation__
 *
 * To run a mutation, you first call `useCreateUserCustomizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserCustomizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserCustomizationMutation, { data, loading, error }] = useCreateUserCustomizationMutation({
 *   variables: {
 *      background: // value for 'background'
 *      backgroundIsImage: // value for 'backgroundIsImage'
 *      brand: // value for 'brand'
 *      brandIsImage: // value for 'brandIsImage'
 *      brandTextColor: // value for 'brandTextColor'
 *      shopName: // value for 'shopName'
 *      userId: // value for 'userId'
 *      brandColor: // value for 'brandColor'
 *   },
 * });
 */
export function useCreateUserCustomizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserCustomizationMutation, CreateUserCustomizationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserCustomizationMutation, CreateUserCustomizationMutationVariables>(CreateUserCustomizationDocument, baseOptions);
      }
export type CreateUserCustomizationMutationHookResult = ReturnType<typeof useCreateUserCustomizationMutation>;
export type CreateUserCustomizationMutationResult = ApolloReactCommon.MutationResult<CreateUserCustomizationMutation>;
export type CreateUserCustomizationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserCustomizationMutation, CreateUserCustomizationMutationVariables>;
export const UploadBrandOrBackgroundDocument = gql`
    mutation uploadBrandOrBackground($isBackground: Boolean!, $Picture: Upload!, $userId: Float!) {
  uploadBrandOrBackground(
    isBackground: $isBackground
    Picture: $Picture
    userId: $userId
  )
}
    `;
export type UploadBrandOrBackgroundMutationFn = ApolloReactCommon.MutationFunction<UploadBrandOrBackgroundMutation, UploadBrandOrBackgroundMutationVariables>;

/**
 * __useUploadBrandOrBackgroundMutation__
 *
 * To run a mutation, you first call `useUploadBrandOrBackgroundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBrandOrBackgroundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBrandOrBackgroundMutation, { data, loading, error }] = useUploadBrandOrBackgroundMutation({
 *   variables: {
 *      isBackground: // value for 'isBackground'
 *      Picture: // value for 'Picture'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUploadBrandOrBackgroundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadBrandOrBackgroundMutation, UploadBrandOrBackgroundMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadBrandOrBackgroundMutation, UploadBrandOrBackgroundMutationVariables>(UploadBrandOrBackgroundDocument, baseOptions);
      }
export type UploadBrandOrBackgroundMutationHookResult = ReturnType<typeof useUploadBrandOrBackgroundMutation>;
export type UploadBrandOrBackgroundMutationResult = ApolloReactCommon.MutationResult<UploadBrandOrBackgroundMutation>;
export type UploadBrandOrBackgroundMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadBrandOrBackgroundMutation, UploadBrandOrBackgroundMutationVariables>;
export const CreateUserCustomizationWithCustomBrandDocument = gql`
    mutation createUserCustomizationWithCustomBrand($userId: Float!, $Picture: Upload) {
  createUserCustomizationWithCustomBrand(userId: $userId, Picture: $Picture) {
    id
  }
}
    `;
export type CreateUserCustomizationWithCustomBrandMutationFn = ApolloReactCommon.MutationFunction<CreateUserCustomizationWithCustomBrandMutation, CreateUserCustomizationWithCustomBrandMutationVariables>;

/**
 * __useCreateUserCustomizationWithCustomBrandMutation__
 *
 * To run a mutation, you first call `useCreateUserCustomizationWithCustomBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserCustomizationWithCustomBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserCustomizationWithCustomBrandMutation, { data, loading, error }] = useCreateUserCustomizationWithCustomBrandMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      Picture: // value for 'Picture'
 *   },
 * });
 */
export function useCreateUserCustomizationWithCustomBrandMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserCustomizationWithCustomBrandMutation, CreateUserCustomizationWithCustomBrandMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserCustomizationWithCustomBrandMutation, CreateUserCustomizationWithCustomBrandMutationVariables>(CreateUserCustomizationWithCustomBrandDocument, baseOptions);
      }
export type CreateUserCustomizationWithCustomBrandMutationHookResult = ReturnType<typeof useCreateUserCustomizationWithCustomBrandMutation>;
export type CreateUserCustomizationWithCustomBrandMutationResult = ApolloReactCommon.MutationResult<CreateUserCustomizationWithCustomBrandMutation>;
export type CreateUserCustomizationWithCustomBrandMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserCustomizationWithCustomBrandMutation, CreateUserCustomizationWithCustomBrandMutationVariables>;
export const GetUserCustomizationDocument = gql`
    query getUserCustomization($userId: Float!) {
  getUserCustomization(userId: $userId) {
    id
    brand
    bannerTextColor
    background
    brandColor
    hasBackground
    ShopName
    hasBanner
    userAccountId
  }
}
    `;

/**
 * __useGetUserCustomizationQuery__
 *
 * To run a query within a React component, call `useGetUserCustomizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCustomizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCustomizationQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserCustomizationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserCustomizationQuery, GetUserCustomizationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserCustomizationQuery, GetUserCustomizationQueryVariables>(GetUserCustomizationDocument, baseOptions);
      }
export function useGetUserCustomizationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserCustomizationQuery, GetUserCustomizationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserCustomizationQuery, GetUserCustomizationQueryVariables>(GetUserCustomizationDocument, baseOptions);
        }
export type GetUserCustomizationQueryHookResult = ReturnType<typeof useGetUserCustomizationQuery>;
export type GetUserCustomizationLazyQueryHookResult = ReturnType<typeof useGetUserCustomizationLazyQuery>;
export type GetUserCustomizationQueryResult = ApolloReactCommon.QueryResult<GetUserCustomizationQuery, GetUserCustomizationQueryVariables>;
export const ReturnToStandardDocument = gql`
    mutation returnToStandard($userId: Float!) {
  returnToStandard(userId: $userId)
}
    `;
export type ReturnToStandardMutationFn = ApolloReactCommon.MutationFunction<ReturnToStandardMutation, ReturnToStandardMutationVariables>;

/**
 * __useReturnToStandardMutation__
 *
 * To run a mutation, you first call `useReturnToStandardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReturnToStandardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [returnToStandardMutation, { data, loading, error }] = useReturnToStandardMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useReturnToStandardMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReturnToStandardMutation, ReturnToStandardMutationVariables>) {
        return ApolloReactHooks.useMutation<ReturnToStandardMutation, ReturnToStandardMutationVariables>(ReturnToStandardDocument, baseOptions);
      }
export type ReturnToStandardMutationHookResult = ReturnType<typeof useReturnToStandardMutation>;
export type ReturnToStandardMutationResult = ApolloReactCommon.MutationResult<ReturnToStandardMutation>;
export type ReturnToStandardMutationOptions = ApolloReactCommon.BaseMutationOptions<ReturnToStandardMutation, ReturnToStandardMutationVariables>;
export const DeleteLogoMarcaDocument = gql`
    mutation deleteLogoMarca($userId: Float!) {
  deleteLogoMarca(userId: $userId)
}
    `;
export type DeleteLogoMarcaMutationFn = ApolloReactCommon.MutationFunction<DeleteLogoMarcaMutation, DeleteLogoMarcaMutationVariables>;

/**
 * __useDeleteLogoMarcaMutation__
 *
 * To run a mutation, you first call `useDeleteLogoMarcaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLogoMarcaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLogoMarcaMutation, { data, loading, error }] = useDeleteLogoMarcaMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteLogoMarcaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteLogoMarcaMutation, DeleteLogoMarcaMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteLogoMarcaMutation, DeleteLogoMarcaMutationVariables>(DeleteLogoMarcaDocument, baseOptions);
      }
export type DeleteLogoMarcaMutationHookResult = ReturnType<typeof useDeleteLogoMarcaMutation>;
export type DeleteLogoMarcaMutationResult = ApolloReactCommon.MutationResult<DeleteLogoMarcaMutation>;
export type DeleteLogoMarcaMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteLogoMarcaMutation, DeleteLogoMarcaMutationVariables>;
export const UpdateUserNotificationStatusDocument = gql`
    mutation updateUserNotificationStatus($id: Float!) {
  updateUserNotificationStatus(id: $id)
}
    `;
export type UpdateUserNotificationStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateUserNotificationStatusMutation, UpdateUserNotificationStatusMutationVariables>;

/**
 * __useUpdateUserNotificationStatusMutation__
 *
 * To run a mutation, you first call `useUpdateUserNotificationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserNotificationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserNotificationStatusMutation, { data, loading, error }] = useUpdateUserNotificationStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateUserNotificationStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserNotificationStatusMutation, UpdateUserNotificationStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserNotificationStatusMutation, UpdateUserNotificationStatusMutationVariables>(UpdateUserNotificationStatusDocument, baseOptions);
      }
export type UpdateUserNotificationStatusMutationHookResult = ReturnType<typeof useUpdateUserNotificationStatusMutation>;
export type UpdateUserNotificationStatusMutationResult = ApolloReactCommon.MutationResult<UpdateUserNotificationStatusMutation>;
export type UpdateUserNotificationStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserNotificationStatusMutation, UpdateUserNotificationStatusMutationVariables>;
export const GetAllVariationProductDocument = gql`
    query getAllVariationProduct($getAllVariationProductInput: GetAllVariationProductInput!) {
  getAllVariationProduct(
    getAllVariationProductInput: $getAllVariationProductInput
  ) {
    id
    name
  }
}
    `;

/**
 * __useGetAllVariationProductQuery__
 *
 * To run a query within a React component, call `useGetAllVariationProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllVariationProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllVariationProductQuery({
 *   variables: {
 *      getAllVariationProductInput: // value for 'getAllVariationProductInput'
 *   },
 * });
 */
export function useGetAllVariationProductQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllVariationProductQuery, GetAllVariationProductQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllVariationProductQuery, GetAllVariationProductQueryVariables>(GetAllVariationProductDocument, baseOptions);
      }
export function useGetAllVariationProductLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllVariationProductQuery, GetAllVariationProductQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllVariationProductQuery, GetAllVariationProductQueryVariables>(GetAllVariationProductDocument, baseOptions);
        }
export type GetAllVariationProductQueryHookResult = ReturnType<typeof useGetAllVariationProductQuery>;
export type GetAllVariationProductLazyQueryHookResult = ReturnType<typeof useGetAllVariationProductLazyQuery>;
export type GetAllVariationProductQueryResult = ApolloReactCommon.QueryResult<GetAllVariationProductQuery, GetAllVariationProductQueryVariables>;
export const GetAllProductVariationByProductIdDocument = gql`
    query getAllProductVariationByProductId($productId: Float!) {
  getAllProductVariationByProductId(productId: $productId) {
    id
    cor
    tamanho
    productId
    quantidade
    ColorVariation {
      id
      name
    }
    TamanhoVariations {
      id
      name
    }
  }
}
    `;

/**
 * __useGetAllProductVariationByProductIdQuery__
 *
 * To run a query within a React component, call `useGetAllProductVariationByProductIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductVariationByProductIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProductVariationByProductIdQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetAllProductVariationByProductIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllProductVariationByProductIdQuery, GetAllProductVariationByProductIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllProductVariationByProductIdQuery, GetAllProductVariationByProductIdQueryVariables>(GetAllProductVariationByProductIdDocument, baseOptions);
      }
export function useGetAllProductVariationByProductIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllProductVariationByProductIdQuery, GetAllProductVariationByProductIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllProductVariationByProductIdQuery, GetAllProductVariationByProductIdQueryVariables>(GetAllProductVariationByProductIdDocument, baseOptions);
        }
export type GetAllProductVariationByProductIdQueryHookResult = ReturnType<typeof useGetAllProductVariationByProductIdQuery>;
export type GetAllProductVariationByProductIdLazyQueryHookResult = ReturnType<typeof useGetAllProductVariationByProductIdLazyQuery>;
export type GetAllProductVariationByProductIdQueryResult = ApolloReactCommon.QueryResult<GetAllProductVariationByProductIdQuery, GetAllProductVariationByProductIdQueryVariables>;
export const DeleteProductVariationByIdDocument = gql`
    mutation deleteProductVariationById($productVariationId: Float!) {
  deleteProductVariationById(productVariationId: $productVariationId)
}
    `;
export type DeleteProductVariationByIdMutationFn = ApolloReactCommon.MutationFunction<DeleteProductVariationByIdMutation, DeleteProductVariationByIdMutationVariables>;

/**
 * __useDeleteProductVariationByIdMutation__
 *
 * To run a mutation, you first call `useDeleteProductVariationByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductVariationByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductVariationByIdMutation, { data, loading, error }] = useDeleteProductVariationByIdMutation({
 *   variables: {
 *      productVariationId: // value for 'productVariationId'
 *   },
 * });
 */
export function useDeleteProductVariationByIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProductVariationByIdMutation, DeleteProductVariationByIdMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProductVariationByIdMutation, DeleteProductVariationByIdMutationVariables>(DeleteProductVariationByIdDocument, baseOptions);
      }
export type DeleteProductVariationByIdMutationHookResult = ReturnType<typeof useDeleteProductVariationByIdMutation>;
export type DeleteProductVariationByIdMutationResult = ApolloReactCommon.MutationResult<DeleteProductVariationByIdMutation>;
export type DeleteProductVariationByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProductVariationByIdMutation, DeleteProductVariationByIdMutationVariables>;
export const UpdateProductVariationByIdDocument = gql`
    mutation updateProductVariationById($updateProductVariation: UpdateProductVariationInputData!) {
  updateProductVariationById(updateProductVariation: $updateProductVariation)
}
    `;
export type UpdateProductVariationByIdMutationFn = ApolloReactCommon.MutationFunction<UpdateProductVariationByIdMutation, UpdateProductVariationByIdMutationVariables>;

/**
 * __useUpdateProductVariationByIdMutation__
 *
 * To run a mutation, you first call `useUpdateProductVariationByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductVariationByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductVariationByIdMutation, { data, loading, error }] = useUpdateProductVariationByIdMutation({
 *   variables: {
 *      updateProductVariation: // value for 'updateProductVariation'
 *   },
 * });
 */
export function useUpdateProductVariationByIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProductVariationByIdMutation, UpdateProductVariationByIdMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProductVariationByIdMutation, UpdateProductVariationByIdMutationVariables>(UpdateProductVariationByIdDocument, baseOptions);
      }
export type UpdateProductVariationByIdMutationHookResult = ReturnType<typeof useUpdateProductVariationByIdMutation>;
export type UpdateProductVariationByIdMutationResult = ApolloReactCommon.MutationResult<UpdateProductVariationByIdMutation>;
export type UpdateProductVariationByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProductVariationByIdMutation, UpdateProductVariationByIdMutationVariables>;
export const CreateProdutVariationDocument = gql`
    mutation createProdutVariation($createProductVariationInputData: CreateProductVariationInputData!) {
  createProdutVariation(createProductVariation: $createProductVariationInputData)
}
    `;
export type CreateProdutVariationMutationFn = ApolloReactCommon.MutationFunction<CreateProdutVariationMutation, CreateProdutVariationMutationVariables>;

/**
 * __useCreateProdutVariationMutation__
 *
 * To run a mutation, you first call `useCreateProdutVariationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProdutVariationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProdutVariationMutation, { data, loading, error }] = useCreateProdutVariationMutation({
 *   variables: {
 *      createProductVariationInputData: // value for 'createProductVariationInputData'
 *   },
 * });
 */
export function useCreateProdutVariationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProdutVariationMutation, CreateProdutVariationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProdutVariationMutation, CreateProdutVariationMutationVariables>(CreateProdutVariationDocument, baseOptions);
      }
export type CreateProdutVariationMutationHookResult = ReturnType<typeof useCreateProdutVariationMutation>;
export type CreateProdutVariationMutationResult = ApolloReactCommon.MutationResult<CreateProdutVariationMutation>;
export type CreateProdutVariationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProdutVariationMutation, CreateProdutVariationMutationVariables>;
export const CreateRecipientDocument = gql`
    mutation createRecipient($userId: Float!, $bank_code: String!, $agencia: String!, $conta: String!, $cpf: String!, $legal_name: String!, $conta_dv: String!, $type: String!) {
  createRecipient(
    recipientInput: {userId: $userId, bank_code: $bank_code, agencia: $agencia, conta: $conta, cpf: $cpf, legal_name: $legal_name, conta_dv: $conta_dv, type: $type}
  ) {
    id
    bankAccountId
  }
}
    `;
export type CreateRecipientMutationFn = ApolloReactCommon.MutationFunction<CreateRecipientMutation, CreateRecipientMutationVariables>;

/**
 * __useCreateRecipientMutation__
 *
 * To run a mutation, you first call `useCreateRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecipientMutation, { data, loading, error }] = useCreateRecipientMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      bank_code: // value for 'bank_code'
 *      agencia: // value for 'agencia'
 *      conta: // value for 'conta'
 *      cpf: // value for 'cpf'
 *      legal_name: // value for 'legal_name'
 *      conta_dv: // value for 'conta_dv'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCreateRecipientMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRecipientMutation, CreateRecipientMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRecipientMutation, CreateRecipientMutationVariables>(CreateRecipientDocument, baseOptions);
      }
export type CreateRecipientMutationHookResult = ReturnType<typeof useCreateRecipientMutation>;
export type CreateRecipientMutationResult = ApolloReactCommon.MutationResult<CreateRecipientMutation>;
export type CreateRecipientMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRecipientMutation, CreateRecipientMutationVariables>;
export const GetAccountDocument = gql`
    query getAccount($userId: Float!) {
  getAccount(userId: $userId) {
    id
    bank_code
    agencia
    legal_name
    conta
    conta_dv
    type
    document_type
    document_number
  }
}
    `;

/**
 * __useGetAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, baseOptions);
      }
export function useGetAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, baseOptions);
        }
export type GetAccountQueryHookResult = ReturnType<typeof useGetAccountQuery>;
export type GetAccountLazyQueryHookResult = ReturnType<typeof useGetAccountLazyQuery>;
export type GetAccountQueryResult = ApolloReactCommon.QueryResult<GetAccountQuery, GetAccountQueryVariables>;
export const CreateBlingCountDocument = gql`
    mutation createBlingCount($createBlingCountInputData: CreateBlingCountInputData!) {
  createBlingCount(createBlingCountInputData: $createBlingCountInputData) {
    id
    apiKey
    userAccountId
  }
}
    `;
export type CreateBlingCountMutationFn = ApolloReactCommon.MutationFunction<CreateBlingCountMutation, CreateBlingCountMutationVariables>;

/**
 * __useCreateBlingCountMutation__
 *
 * To run a mutation, you first call `useCreateBlingCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlingCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlingCountMutation, { data, loading, error }] = useCreateBlingCountMutation({
 *   variables: {
 *      createBlingCountInputData: // value for 'createBlingCountInputData'
 *   },
 * });
 */
export function useCreateBlingCountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBlingCountMutation, CreateBlingCountMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBlingCountMutation, CreateBlingCountMutationVariables>(CreateBlingCountDocument, baseOptions);
      }
export type CreateBlingCountMutationHookResult = ReturnType<typeof useCreateBlingCountMutation>;
export type CreateBlingCountMutationResult = ApolloReactCommon.MutationResult<CreateBlingCountMutation>;
export type CreateBlingCountMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBlingCountMutation, CreateBlingCountMutationVariables>;
export const GetBlingAccountDocument = gql`
    query getBlingAccount($getBlingAccountInputData: GetBlingAccountInputData!) {
  getBlingAccount(getBlingAccountInputData: $getBlingAccountInputData) {
    id
    apiKey
    userAccountId
  }
}
    `;

/**
 * __useGetBlingAccountQuery__
 *
 * To run a query within a React component, call `useGetBlingAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlingAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlingAccountQuery({
 *   variables: {
 *      getBlingAccountInputData: // value for 'getBlingAccountInputData'
 *   },
 * });
 */
export function useGetBlingAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBlingAccountQuery, GetBlingAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBlingAccountQuery, GetBlingAccountQueryVariables>(GetBlingAccountDocument, baseOptions);
      }
export function useGetBlingAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBlingAccountQuery, GetBlingAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBlingAccountQuery, GetBlingAccountQueryVariables>(GetBlingAccountDocument, baseOptions);
        }
export type GetBlingAccountQueryHookResult = ReturnType<typeof useGetBlingAccountQuery>;
export type GetBlingAccountLazyQueryHookResult = ReturnType<typeof useGetBlingAccountLazyQuery>;
export type GetBlingAccountQueryResult = ApolloReactCommon.QueryResult<GetBlingAccountQuery, GetBlingAccountQueryVariables>;
export const GerarNotaFiscalDocument = gql`
    mutation gerarNotaFiscal($gerarNotaFiscalInputData: GerarNotaFiscalInputData!) {
  gerarNotaFiscal(gerarNotaFiscalInputData: $gerarNotaFiscalInputData)
}
    `;
export type GerarNotaFiscalMutationFn = ApolloReactCommon.MutationFunction<GerarNotaFiscalMutation, GerarNotaFiscalMutationVariables>;

/**
 * __useGerarNotaFiscalMutation__
 *
 * To run a mutation, you first call `useGerarNotaFiscalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGerarNotaFiscalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gerarNotaFiscalMutation, { data, loading, error }] = useGerarNotaFiscalMutation({
 *   variables: {
 *      gerarNotaFiscalInputData: // value for 'gerarNotaFiscalInputData'
 *   },
 * });
 */
export function useGerarNotaFiscalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GerarNotaFiscalMutation, GerarNotaFiscalMutationVariables>) {
        return ApolloReactHooks.useMutation<GerarNotaFiscalMutation, GerarNotaFiscalMutationVariables>(GerarNotaFiscalDocument, baseOptions);
      }
export type GerarNotaFiscalMutationHookResult = ReturnType<typeof useGerarNotaFiscalMutation>;
export type GerarNotaFiscalMutationResult = ApolloReactCommon.MutationResult<GerarNotaFiscalMutation>;
export type GerarNotaFiscalMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarNotaFiscalMutation, GerarNotaFiscalMutationVariables>;
export const FindBlingNfcsDocument = gql`
    mutation findBlingNfcs($purchaseId: Float!) {
  findBlingNfcs(purchaseId: $purchaseId)
}
    `;
export type FindBlingNfcsMutationFn = ApolloReactCommon.MutationFunction<FindBlingNfcsMutation, FindBlingNfcsMutationVariables>;

/**
 * __useFindBlingNfcsMutation__
 *
 * To run a mutation, you first call `useFindBlingNfcsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFindBlingNfcsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [findBlingNfcsMutation, { data, loading, error }] = useFindBlingNfcsMutation({
 *   variables: {
 *      purchaseId: // value for 'purchaseId'
 *   },
 * });
 */
export function useFindBlingNfcsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FindBlingNfcsMutation, FindBlingNfcsMutationVariables>) {
        return ApolloReactHooks.useMutation<FindBlingNfcsMutation, FindBlingNfcsMutationVariables>(FindBlingNfcsDocument, baseOptions);
      }
export type FindBlingNfcsMutationHookResult = ReturnType<typeof useFindBlingNfcsMutation>;
export type FindBlingNfcsMutationResult = ApolloReactCommon.MutationResult<FindBlingNfcsMutation>;
export type FindBlingNfcsMutationOptions = ApolloReactCommon.BaseMutationOptions<FindBlingNfcsMutation, FindBlingNfcsMutationVariables>;
export const GetAllBlingProductsDocument = gql`
    query getAllBlingProducts($userId: Float!) {
  getAllBlingProducts(userId: $userId) {
    id
    codigo
    descricao
    preco
    imageThumbnail
    estoqueAtual
  }
}
    `;

/**
 * __useGetAllBlingProductsQuery__
 *
 * To run a query within a React component, call `useGetAllBlingProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBlingProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBlingProductsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAllBlingProductsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllBlingProductsQuery, GetAllBlingProductsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllBlingProductsQuery, GetAllBlingProductsQueryVariables>(GetAllBlingProductsDocument, baseOptions);
      }
export function useGetAllBlingProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllBlingProductsQuery, GetAllBlingProductsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllBlingProductsQuery, GetAllBlingProductsQueryVariables>(GetAllBlingProductsDocument, baseOptions);
        }
export type GetAllBlingProductsQueryHookResult = ReturnType<typeof useGetAllBlingProductsQuery>;
export type GetAllBlingProductsLazyQueryHookResult = ReturnType<typeof useGetAllBlingProductsLazyQuery>;
export type GetAllBlingProductsQueryResult = ApolloReactCommon.QueryResult<GetAllBlingProductsQuery, GetAllBlingProductsQueryVariables>;
export const PublishBlingProductsDocument = gql`
    mutation publishBlingProducts($userId: Float!, $blingProductIds: [String!]!) {
  publishBlingProducts(userId: $userId, blingProductIds: $blingProductIds)
}
    `;
export type PublishBlingProductsMutationFn = ApolloReactCommon.MutationFunction<PublishBlingProductsMutation, PublishBlingProductsMutationVariables>;

/**
 * __usePublishBlingProductsMutation__
 *
 * To run a mutation, you first call `usePublishBlingProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishBlingProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishBlingProductsMutation, { data, loading, error }] = usePublishBlingProductsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      blingProductIds: // value for 'blingProductIds'
 *   },
 * });
 */
export function usePublishBlingProductsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PublishBlingProductsMutation, PublishBlingProductsMutationVariables>) {
        return ApolloReactHooks.useMutation<PublishBlingProductsMutation, PublishBlingProductsMutationVariables>(PublishBlingProductsDocument, baseOptions);
      }
export type PublishBlingProductsMutationHookResult = ReturnType<typeof usePublishBlingProductsMutation>;
export type PublishBlingProductsMutationResult = ApolloReactCommon.MutationResult<PublishBlingProductsMutation>;
export type PublishBlingProductsMutationOptions = ApolloReactCommon.BaseMutationOptions<PublishBlingProductsMutation, PublishBlingProductsMutationVariables>;
export const CreateCardDocument = gql`
    mutation createCard($userId: Float!, $card_number: String!, $card_expiration_date: String!, $card_cvv: String!, $card_holder_name: String!, $cpf: String!) {
  createCard(
    card: {card_cvv: $card_cvv, card_number: $card_number, card_expiration_date: $card_expiration_date, card_holder_name: $card_holder_name, cpf: $cpf}
    userId: $userId
  ) {
    id
    date_created
    date_updated
    brand
    holder_name
    first_digits
    last_digits
    customer
    valid
    expiration_date
  }
}
    `;
export type CreateCardMutationFn = ApolloReactCommon.MutationFunction<CreateCardMutation, CreateCardMutationVariables>;

/**
 * __useCreateCardMutation__
 *
 * To run a mutation, you first call `useCreateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCardMutation, { data, loading, error }] = useCreateCardMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      card_number: // value for 'card_number'
 *      card_expiration_date: // value for 'card_expiration_date'
 *      card_cvv: // value for 'card_cvv'
 *      card_holder_name: // value for 'card_holder_name'
 *      cpf: // value for 'cpf'
 *   },
 * });
 */
export function useCreateCardMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCardMutation, CreateCardMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCardMutation, CreateCardMutationVariables>(CreateCardDocument, baseOptions);
      }
export type CreateCardMutationHookResult = ReturnType<typeof useCreateCardMutation>;
export type CreateCardMutationResult = ApolloReactCommon.MutationResult<CreateCardMutation>;
export type CreateCardMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCardMutation, CreateCardMutationVariables>;
export const GetAllCardsDocument = gql`
    query getAllCards($userId: Float!, $offset: Float, $limit: Float) {
  getAllCards(userId: $userId, offset: $offset, limit: $limit) {
    id
    object
    date_created
    date_updated
    holder_name
    last_digits
    fingerprint
    valid
    expiration_date
    first_digits
    brand
    valid
  }
}
    `;

/**
 * __useGetAllCardsQuery__
 *
 * To run a query within a React component, call `useGetAllCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCardsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAllCardsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllCardsQuery, GetAllCardsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllCardsQuery, GetAllCardsQueryVariables>(GetAllCardsDocument, baseOptions);
      }
export function useGetAllCardsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllCardsQuery, GetAllCardsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllCardsQuery, GetAllCardsQueryVariables>(GetAllCardsDocument, baseOptions);
        }
export type GetAllCardsQueryHookResult = ReturnType<typeof useGetAllCardsQuery>;
export type GetAllCardsLazyQueryHookResult = ReturnType<typeof useGetAllCardsLazyQuery>;
export type GetAllCardsQueryResult = ApolloReactCommon.QueryResult<GetAllCardsQuery, GetAllCardsQueryVariables>;
export const DeleteCardDocument = gql`
    mutation deleteCard($id: String!) {
  deleteCard(id: $id)
}
    `;
export type DeleteCardMutationFn = ApolloReactCommon.MutationFunction<DeleteCardMutation, DeleteCardMutationVariables>;

/**
 * __useDeleteCardMutation__
 *
 * To run a mutation, you first call `useDeleteCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCardMutation, { data, loading, error }] = useDeleteCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCardMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCardMutation, DeleteCardMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCardMutation, DeleteCardMutationVariables>(DeleteCardDocument, baseOptions);
      }
export type DeleteCardMutationHookResult = ReturnType<typeof useDeleteCardMutation>;
export type DeleteCardMutationResult = ApolloReactCommon.MutationResult<DeleteCardMutation>;
export type DeleteCardMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCardMutation, DeleteCardMutationVariables>;
export const GetAllCardsIdDocument = gql`
    query getAllCardsId($userId: Float!) {
  getAllCardsId(userId: $userId) {
    id
    cardId
    userAccountId
  }
}
    `;

/**
 * __useGetAllCardsIdQuery__
 *
 * To run a query within a React component, call `useGetAllCardsIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCardsIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCardsIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAllCardsIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllCardsIdQuery, GetAllCardsIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllCardsIdQuery, GetAllCardsIdQueryVariables>(GetAllCardsIdDocument, baseOptions);
      }
export function useGetAllCardsIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllCardsIdQuery, GetAllCardsIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllCardsIdQuery, GetAllCardsIdQueryVariables>(GetAllCardsIdDocument, baseOptions);
        }
export type GetAllCardsIdQueryHookResult = ReturnType<typeof useGetAllCardsIdQuery>;
export type GetAllCardsIdLazyQueryHookResult = ReturnType<typeof useGetAllCardsIdLazyQuery>;
export type GetAllCardsIdQueryResult = ApolloReactCommon.QueryResult<GetAllCardsIdQuery, GetAllCardsIdQueryVariables>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    accessToken
    user {
      id
      name
      userTypeId
      cpf
      RG
      createdAt
      email
      dateBirth
      bankAccountId
      password
      melhorEnvioToken
      block
      cardId
      ratting
      UserType {
        id
        name
      }
      ProfileAddress {
        id
        state
        city
        street
        addressTitle
        neighborhood
        number
        CEP
        complement
        references
      }
      ProfileContacts {
        id
        phone
      }
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const SetMelhorEnvioTokenDocument = gql`
    mutation setMelhorEnvioToken($token: String!, $refreshToken: String!, $userId: Float!) {
  setMelhorEnvioToken(token: $token, refreshToken: $refreshToken, userId: $userId)
}
    `;
export type SetMelhorEnvioTokenMutationFn = ApolloReactCommon.MutationFunction<SetMelhorEnvioTokenMutation, SetMelhorEnvioTokenMutationVariables>;

/**
 * __useSetMelhorEnvioTokenMutation__
 *
 * To run a mutation, you first call `useSetMelhorEnvioTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMelhorEnvioTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMelhorEnvioTokenMutation, { data, loading, error }] = useSetMelhorEnvioTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *      refreshToken: // value for 'refreshToken'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSetMelhorEnvioTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetMelhorEnvioTokenMutation, SetMelhorEnvioTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<SetMelhorEnvioTokenMutation, SetMelhorEnvioTokenMutationVariables>(SetMelhorEnvioTokenDocument, baseOptions);
      }
export type SetMelhorEnvioTokenMutationHookResult = ReturnType<typeof useSetMelhorEnvioTokenMutation>;
export type SetMelhorEnvioTokenMutationResult = ApolloReactCommon.MutationResult<SetMelhorEnvioTokenMutation>;
export type SetMelhorEnvioTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<SetMelhorEnvioTokenMutation, SetMelhorEnvioTokenMutationVariables>;
export const GetMelhorEnvioTokenDocument = gql`
    query getMelhorEnvioToken($userId: Float!) {
  getMelhorEnvioToken(userId: $userId) {
    token
    refreshToken
  }
}
    `;

/**
 * __useGetMelhorEnvioTokenQuery__
 *
 * To run a query within a React component, call `useGetMelhorEnvioTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMelhorEnvioTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMelhorEnvioTokenQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetMelhorEnvioTokenQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMelhorEnvioTokenQuery, GetMelhorEnvioTokenQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMelhorEnvioTokenQuery, GetMelhorEnvioTokenQueryVariables>(GetMelhorEnvioTokenDocument, baseOptions);
      }
export function useGetMelhorEnvioTokenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMelhorEnvioTokenQuery, GetMelhorEnvioTokenQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMelhorEnvioTokenQuery, GetMelhorEnvioTokenQueryVariables>(GetMelhorEnvioTokenDocument, baseOptions);
        }
export type GetMelhorEnvioTokenQueryHookResult = ReturnType<typeof useGetMelhorEnvioTokenQuery>;
export type GetMelhorEnvioTokenLazyQueryHookResult = ReturnType<typeof useGetMelhorEnvioTokenLazyQuery>;
export type GetMelhorEnvioTokenQueryResult = ApolloReactCommon.QueryResult<GetMelhorEnvioTokenQuery, GetMelhorEnvioTokenQueryVariables>;
export const InsertProductOnMelhorEnvioCartDocument = gql`
    mutation insertProductOnMelhorEnvioCart($ProductInfos: InsertTicketsOnCartRequest!, $userId: Float!, $purchaseId: Float!, $gateway: String) {
  insertProductOnMelhorEnvioCart(
    productInfos: $ProductInfos
    userId: $userId
    purchaseId: $purchaseId
    gateway: $gateway
  ) {
    url
  }
}
    `;
export type InsertProductOnMelhorEnvioCartMutationFn = ApolloReactCommon.MutationFunction<InsertProductOnMelhorEnvioCartMutation, InsertProductOnMelhorEnvioCartMutationVariables>;

/**
 * __useInsertProductOnMelhorEnvioCartMutation__
 *
 * To run a mutation, you first call `useInsertProductOnMelhorEnvioCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertProductOnMelhorEnvioCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertProductOnMelhorEnvioCartMutation, { data, loading, error }] = useInsertProductOnMelhorEnvioCartMutation({
 *   variables: {
 *      ProductInfos: // value for 'ProductInfos'
 *      userId: // value for 'userId'
 *      purchaseId: // value for 'purchaseId'
 *      gateway: // value for 'gateway'
 *   },
 * });
 */
export function useInsertProductOnMelhorEnvioCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertProductOnMelhorEnvioCartMutation, InsertProductOnMelhorEnvioCartMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertProductOnMelhorEnvioCartMutation, InsertProductOnMelhorEnvioCartMutationVariables>(InsertProductOnMelhorEnvioCartDocument, baseOptions);
      }
export type InsertProductOnMelhorEnvioCartMutationHookResult = ReturnType<typeof useInsertProductOnMelhorEnvioCartMutation>;
export type InsertProductOnMelhorEnvioCartMutationResult = ApolloReactCommon.MutationResult<InsertProductOnMelhorEnvioCartMutation>;
export type InsertProductOnMelhorEnvioCartMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertProductOnMelhorEnvioCartMutation, InsertProductOnMelhorEnvioCartMutationVariables>;
export const CalculateShippingValueDocument = gql`
    query calculateShippingValue($infos: RequestShippingCalculate!, $userId: Float!, $productId: Float!) {
  calculateShippingValue(infos: $infos, userId: $userId, productId: $productId) {
    additional_services {
      collect
      own_hand
      receipt
    }
    company {
      id
      name
      picture
    }
    custom_delivery_range {
      max
      min
    }
    custom_delivery_time
    custom_price
    delivery_range {
      max
      min
    }
    delivery_time
    discount
    id
    productId
    name
  }
}
    `;

/**
 * __useCalculateShippingValueQuery__
 *
 * To run a query within a React component, call `useCalculateShippingValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateShippingValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateShippingValueQuery({
 *   variables: {
 *      infos: // value for 'infos'
 *      userId: // value for 'userId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useCalculateShippingValueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalculateShippingValueQuery, CalculateShippingValueQueryVariables>) {
        return ApolloReactHooks.useQuery<CalculateShippingValueQuery, CalculateShippingValueQueryVariables>(CalculateShippingValueDocument, baseOptions);
      }
export function useCalculateShippingValueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalculateShippingValueQuery, CalculateShippingValueQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalculateShippingValueQuery, CalculateShippingValueQueryVariables>(CalculateShippingValueDocument, baseOptions);
        }
export type CalculateShippingValueQueryHookResult = ReturnType<typeof useCalculateShippingValueQuery>;
export type CalculateShippingValueLazyQueryHookResult = ReturnType<typeof useCalculateShippingValueLazyQuery>;
export type CalculateShippingValueQueryResult = ApolloReactCommon.QueryResult<CalculateShippingValueQuery, CalculateShippingValueQueryVariables>;
export const GenerateAndPrintTicketsDocument = gql`
    query generateAndPrintTickets($userId: Float!, $ticketId: Float!) {
  generateAndPrintTickets(userId: $userId, ticketId: $ticketId) {
    isPaid
    url
  }
}
    `;

/**
 * __useGenerateAndPrintTicketsQuery__
 *
 * To run a query within a React component, call `useGenerateAndPrintTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateAndPrintTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateAndPrintTicketsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useGenerateAndPrintTicketsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GenerateAndPrintTicketsQuery, GenerateAndPrintTicketsQueryVariables>) {
        return ApolloReactHooks.useQuery<GenerateAndPrintTicketsQuery, GenerateAndPrintTicketsQueryVariables>(GenerateAndPrintTicketsDocument, baseOptions);
      }
export function useGenerateAndPrintTicketsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GenerateAndPrintTicketsQuery, GenerateAndPrintTicketsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GenerateAndPrintTicketsQuery, GenerateAndPrintTicketsQueryVariables>(GenerateAndPrintTicketsDocument, baseOptions);
        }
export type GenerateAndPrintTicketsQueryHookResult = ReturnType<typeof useGenerateAndPrintTicketsQuery>;
export type GenerateAndPrintTicketsLazyQueryHookResult = ReturnType<typeof useGenerateAndPrintTicketsLazyQuery>;
export type GenerateAndPrintTicketsQueryResult = ApolloReactCommon.QueryResult<GenerateAndPrintTicketsQuery, GenerateAndPrintTicketsQueryVariables>;
export const GetServiceByServiceIdDocument = gql`
    query getServiceByServiceId($serviceId: String!) {
  getServiceByServiceId(serviceId: $serviceId) {
    id
    name
    picture
  }
}
    `;

/**
 * __useGetServiceByServiceIdQuery__
 *
 * To run a query within a React component, call `useGetServiceByServiceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceByServiceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceByServiceIdQuery({
 *   variables: {
 *      serviceId: // value for 'serviceId'
 *   },
 * });
 */
export function useGetServiceByServiceIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetServiceByServiceIdQuery, GetServiceByServiceIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetServiceByServiceIdQuery, GetServiceByServiceIdQueryVariables>(GetServiceByServiceIdDocument, baseOptions);
      }
export function useGetServiceByServiceIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetServiceByServiceIdQuery, GetServiceByServiceIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetServiceByServiceIdQuery, GetServiceByServiceIdQueryVariables>(GetServiceByServiceIdDocument, baseOptions);
        }
export type GetServiceByServiceIdQueryHookResult = ReturnType<typeof useGetServiceByServiceIdQuery>;
export type GetServiceByServiceIdLazyQueryHookResult = ReturnType<typeof useGetServiceByServiceIdLazyQuery>;
export type GetServiceByServiceIdQueryResult = ApolloReactCommon.QueryResult<GetServiceByServiceIdQuery, GetServiceByServiceIdQueryVariables>;
export const SearchTicketDocument = gql`
    query searchTicket($userId: Float!, $purchaseId: Float!) {
  searchTicket(userId: $userId, purchaseId: $purchaseId)
}
    `;

/**
 * __useSearchTicketQuery__
 *
 * To run a query within a React component, call `useSearchTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTicketQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      purchaseId: // value for 'purchaseId'
 *   },
 * });
 */
export function useSearchTicketQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchTicketQuery, SearchTicketQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchTicketQuery, SearchTicketQueryVariables>(SearchTicketDocument, baseOptions);
      }
export function useSearchTicketLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchTicketQuery, SearchTicketQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchTicketQuery, SearchTicketQueryVariables>(SearchTicketDocument, baseOptions);
        }
export type SearchTicketQueryHookResult = ReturnType<typeof useSearchTicketQuery>;
export type SearchTicketLazyQueryHookResult = ReturnType<typeof useSearchTicketLazyQuery>;
export type SearchTicketQueryResult = ApolloReactCommon.QueryResult<SearchTicketQuery, SearchTicketQueryVariables>;
export const TrackDocument = gql`
    query track($userId: Float!, $ticketId: String!) {
  track(userId: $userId, ticketId: $ticketId) {
    id
    status
    paid_at
    generated_at
    posted_at
    delivered_at
    canceled_at
    expired_at
    melhorenvio_tracking
  }
}
    `;

/**
 * __useTrackQuery__
 *
 * To run a query within a React component, call `useTrackQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useTrackQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrackQuery, TrackQueryVariables>) {
        return ApolloReactHooks.useQuery<TrackQuery, TrackQueryVariables>(TrackDocument, baseOptions);
      }
export function useTrackLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrackQuery, TrackQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrackQuery, TrackQueryVariables>(TrackDocument, baseOptions);
        }
export type TrackQueryHookResult = ReturnType<typeof useTrackQuery>;
export type TrackLazyQueryHookResult = ReturnType<typeof useTrackLazyQuery>;
export type TrackQueryResult = ApolloReactCommon.QueryResult<TrackQuery, TrackQueryVariables>;
export const CreateUserOnMelhorEnvioDocument = gql`
    mutation createUserOnMelhorEnvio($createMelhorEnvioUsersRequest: CreateMelhorEnvioUsersRequest!) {
  createUserOnMelhorEnvio(
    createMelhorEnvioUsersRequest: $createMelhorEnvioUsersRequest
  )
}
    `;
export type CreateUserOnMelhorEnvioMutationFn = ApolloReactCommon.MutationFunction<CreateUserOnMelhorEnvioMutation, CreateUserOnMelhorEnvioMutationVariables>;

/**
 * __useCreateUserOnMelhorEnvioMutation__
 *
 * To run a mutation, you first call `useCreateUserOnMelhorEnvioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserOnMelhorEnvioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserOnMelhorEnvioMutation, { data, loading, error }] = useCreateUserOnMelhorEnvioMutation({
 *   variables: {
 *      createMelhorEnvioUsersRequest: // value for 'createMelhorEnvioUsersRequest'
 *   },
 * });
 */
export function useCreateUserOnMelhorEnvioMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserOnMelhorEnvioMutation, CreateUserOnMelhorEnvioMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserOnMelhorEnvioMutation, CreateUserOnMelhorEnvioMutationVariables>(CreateUserOnMelhorEnvioDocument, baseOptions);
      }
export type CreateUserOnMelhorEnvioMutationHookResult = ReturnType<typeof useCreateUserOnMelhorEnvioMutation>;
export type CreateUserOnMelhorEnvioMutationResult = ApolloReactCommon.MutationResult<CreateUserOnMelhorEnvioMutation>;
export type CreateUserOnMelhorEnvioMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserOnMelhorEnvioMutation, CreateUserOnMelhorEnvioMutationVariables>;
export const CheckUserBalanceDocument = gql`
    query checkUserBalance($userId: Float!) {
  checkUserBalance(userId: $userId) {
    balance
    reserved
    debts
  }
}
    `;

/**
 * __useCheckUserBalanceQuery__
 *
 * To run a query within a React component, call `useCheckUserBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckUserBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckUserBalanceQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCheckUserBalanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckUserBalanceQuery, CheckUserBalanceQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckUserBalanceQuery, CheckUserBalanceQueryVariables>(CheckUserBalanceDocument, baseOptions);
      }
export function useCheckUserBalanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckUserBalanceQuery, CheckUserBalanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckUserBalanceQuery, CheckUserBalanceQueryVariables>(CheckUserBalanceDocument, baseOptions);
        }
export type CheckUserBalanceQueryHookResult = ReturnType<typeof useCheckUserBalanceQuery>;
export type CheckUserBalanceLazyQueryHookResult = ReturnType<typeof useCheckUserBalanceLazyQuery>;
export type CheckUserBalanceQueryResult = ApolloReactCommon.QueryResult<CheckUserBalanceQuery, CheckUserBalanceQueryVariables>;
export const InsertUserBalanceDocument = gql`
    query insertUserBalance($userId: Float!, $infos: InsertBalanceRequest!) {
  insertUserBalance(userId: $userId, infos: $infos)
}
    `;

/**
 * __useInsertUserBalanceQuery__
 *
 * To run a query within a React component, call `useInsertUserBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsertUserBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsertUserBalanceQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      infos: // value for 'infos'
 *   },
 * });
 */
export function useInsertUserBalanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InsertUserBalanceQuery, InsertUserBalanceQueryVariables>) {
        return ApolloReactHooks.useQuery<InsertUserBalanceQuery, InsertUserBalanceQueryVariables>(InsertUserBalanceDocument, baseOptions);
      }
export function useInsertUserBalanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InsertUserBalanceQuery, InsertUserBalanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InsertUserBalanceQuery, InsertUserBalanceQueryVariables>(InsertUserBalanceDocument, baseOptions);
        }
export type InsertUserBalanceQueryHookResult = ReturnType<typeof useInsertUserBalanceQuery>;
export type InsertUserBalanceLazyQueryHookResult = ReturnType<typeof useInsertUserBalanceLazyQuery>;
export type InsertUserBalanceQueryResult = ApolloReactCommon.QueryResult<InsertUserBalanceQuery, InsertUserBalanceQueryVariables>;
export const DesconectarConexaoDocument = gql`
    mutation desconectarConexao($userId: Float!) {
  desconectarConexao(userId: $userId)
}
    `;
export type DesconectarConexaoMutationFn = ApolloReactCommon.MutationFunction<DesconectarConexaoMutation, DesconectarConexaoMutationVariables>;

/**
 * __useDesconectarConexaoMutation__
 *
 * To run a mutation, you first call `useDesconectarConexaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDesconectarConexaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [desconectarConexaoMutation, { data, loading, error }] = useDesconectarConexaoMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDesconectarConexaoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DesconectarConexaoMutation, DesconectarConexaoMutationVariables>) {
        return ApolloReactHooks.useMutation<DesconectarConexaoMutation, DesconectarConexaoMutationVariables>(DesconectarConexaoDocument, baseOptions);
      }
export type DesconectarConexaoMutationHookResult = ReturnType<typeof useDesconectarConexaoMutation>;
export type DesconectarConexaoMutationResult = ApolloReactCommon.MutationResult<DesconectarConexaoMutation>;
export type DesconectarConexaoMutationOptions = ApolloReactCommon.BaseMutationOptions<DesconectarConexaoMutation, DesconectarConexaoMutationVariables>;
export const GetBiggerProductBidDocument = gql`
    query getBiggerProductBid($productId: Float!) {
  getBiggerProductBid(productId: $productId) {
    id
    bid
    UserAccount {
      id
      name
    }
  }
}
    `;

/**
 * __useGetBiggerProductBidQuery__
 *
 * To run a query within a React component, call `useGetBiggerProductBidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBiggerProductBidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBiggerProductBidQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetBiggerProductBidQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBiggerProductBidQuery, GetBiggerProductBidQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBiggerProductBidQuery, GetBiggerProductBidQueryVariables>(GetBiggerProductBidDocument, baseOptions);
      }
export function useGetBiggerProductBidLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBiggerProductBidQuery, GetBiggerProductBidQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBiggerProductBidQuery, GetBiggerProductBidQueryVariables>(GetBiggerProductBidDocument, baseOptions);
        }
export type GetBiggerProductBidQueryHookResult = ReturnType<typeof useGetBiggerProductBidQuery>;
export type GetBiggerProductBidLazyQueryHookResult = ReturnType<typeof useGetBiggerProductBidLazyQuery>;
export type GetBiggerProductBidQueryResult = ApolloReactCommon.QueryResult<GetBiggerProductBidQuery, GetBiggerProductBidQueryVariables>;
export const DeletePurchaseDocument = gql`
    mutation deletePurchase($purchaseId: String!, $userId: Float!) {
  deletePurchase(purchaseId: $purchaseId, userId: $userId)
}
    `;
export type DeletePurchaseMutationFn = ApolloReactCommon.MutationFunction<DeletePurchaseMutation, DeletePurchaseMutationVariables>;

/**
 * __useDeletePurchaseMutation__
 *
 * To run a mutation, you first call `useDeletePurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePurchaseMutation, { data, loading, error }] = useDeletePurchaseMutation({
 *   variables: {
 *      purchaseId: // value for 'purchaseId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeletePurchaseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePurchaseMutation, DeletePurchaseMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePurchaseMutation, DeletePurchaseMutationVariables>(DeletePurchaseDocument, baseOptions);
      }
export type DeletePurchaseMutationHookResult = ReturnType<typeof useDeletePurchaseMutation>;
export type DeletePurchaseMutationResult = ApolloReactCommon.MutationResult<DeletePurchaseMutation>;
export type DeletePurchaseMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePurchaseMutation, DeletePurchaseMutationVariables>;
export const SendSolicitationCancelPurchaseDocument = gql`
    mutation sendSolicitationCancelPurchase($purchaseId: String!, $userId: Float!, $descriptionCancel: String!) {
  sendSolicitationCancelPurchase(
    purchaseId: $purchaseId
    userId: $userId
    descriptionCancel: $descriptionCancel
  )
}
    `;
export type SendSolicitationCancelPurchaseMutationFn = ApolloReactCommon.MutationFunction<SendSolicitationCancelPurchaseMutation, SendSolicitationCancelPurchaseMutationVariables>;

/**
 * __useSendSolicitationCancelPurchaseMutation__
 *
 * To run a mutation, you first call `useSendSolicitationCancelPurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSolicitationCancelPurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSolicitationCancelPurchaseMutation, { data, loading, error }] = useSendSolicitationCancelPurchaseMutation({
 *   variables: {
 *      purchaseId: // value for 'purchaseId'
 *      userId: // value for 'userId'
 *      descriptionCancel: // value for 'descriptionCancel'
 *   },
 * });
 */
export function useSendSolicitationCancelPurchaseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendSolicitationCancelPurchaseMutation, SendSolicitationCancelPurchaseMutationVariables>) {
        return ApolloReactHooks.useMutation<SendSolicitationCancelPurchaseMutation, SendSolicitationCancelPurchaseMutationVariables>(SendSolicitationCancelPurchaseDocument, baseOptions);
      }
export type SendSolicitationCancelPurchaseMutationHookResult = ReturnType<typeof useSendSolicitationCancelPurchaseMutation>;
export type SendSolicitationCancelPurchaseMutationResult = ApolloReactCommon.MutationResult<SendSolicitationCancelPurchaseMutation>;
export type SendSolicitationCancelPurchaseMutationOptions = ApolloReactCommon.BaseMutationOptions<SendSolicitationCancelPurchaseMutation, SendSolicitationCancelPurchaseMutationVariables>;
export const GetAllUserPurchasesInOneProductDocument = gql`
    query getAllUserPurchasesInOneProduct($userId: Float!, $productId: Float!) {
  getAllUserPurchasesInOneProduct(userId: $userId, productId: $productId)
}
    `;

/**
 * __useGetAllUserPurchasesInOneProductQuery__
 *
 * To run a query within a React component, call `useGetAllUserPurchasesInOneProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUserPurchasesInOneProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUserPurchasesInOneProductQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetAllUserPurchasesInOneProductQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllUserPurchasesInOneProductQuery, GetAllUserPurchasesInOneProductQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllUserPurchasesInOneProductQuery, GetAllUserPurchasesInOneProductQueryVariables>(GetAllUserPurchasesInOneProductDocument, baseOptions);
      }
export function useGetAllUserPurchasesInOneProductLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllUserPurchasesInOneProductQuery, GetAllUserPurchasesInOneProductQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllUserPurchasesInOneProductQuery, GetAllUserPurchasesInOneProductQueryVariables>(GetAllUserPurchasesInOneProductDocument, baseOptions);
        }
export type GetAllUserPurchasesInOneProductQueryHookResult = ReturnType<typeof useGetAllUserPurchasesInOneProductQuery>;
export type GetAllUserPurchasesInOneProductLazyQueryHookResult = ReturnType<typeof useGetAllUserPurchasesInOneProductLazyQuery>;
export type GetAllUserPurchasesInOneProductQueryResult = ApolloReactCommon.QueryResult<GetAllUserPurchasesInOneProductQuery, GetAllUserPurchasesInOneProductQueryVariables>;
export const GetAllUserPurchasesDocument = gql`
    query getAllUserPurchases($userId: Float!) {
  getAllUserPurchases(userId: $userId) {
    id
    statusId
    Status {
      id
      engName
      ptName
    }
    price
    productQuantity
    pagarmeId
    paymentMethod
    userAccountId
    ticketId
    productShippingPrice
    paidAt
    trackingCode
    UserAccount {
      id
      name
      photo
      userTypeId
      cpf
      RG
      email
      password
      cardId
      ratting
      ProfileAddress {
        id
        state
        city
        street
        neighborhood
        number
        CEP
        complement
        references
      }
      ProfileContacts {
        id
        phone
      }
    }
    Product {
      id
      title
      subtitle
      amount
      typeNegotiation
      InitialValue
      price
      quantity
      stock
      categorieId
      description
      brand
      useState
      maxInstallments
      height
      width
      thickness
      material
      shippingWay
      deadline
      userAccountId
      UserAccount {
        id
        name
      }
    }
    PurchaseInvoice {
      id
      name
      path
    }
  }
}
    `;

/**
 * __useGetAllUserPurchasesQuery__
 *
 * To run a query within a React component, call `useGetAllUserPurchasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUserPurchasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUserPurchasesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAllUserPurchasesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllUserPurchasesQuery, GetAllUserPurchasesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllUserPurchasesQuery, GetAllUserPurchasesQueryVariables>(GetAllUserPurchasesDocument, baseOptions);
      }
export function useGetAllUserPurchasesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllUserPurchasesQuery, GetAllUserPurchasesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllUserPurchasesQuery, GetAllUserPurchasesQueryVariables>(GetAllUserPurchasesDocument, baseOptions);
        }
export type GetAllUserPurchasesQueryHookResult = ReturnType<typeof useGetAllUserPurchasesQuery>;
export type GetAllUserPurchasesLazyQueryHookResult = ReturnType<typeof useGetAllUserPurchasesLazyQuery>;
export type GetAllUserPurchasesQueryResult = ApolloReactCommon.QueryResult<GetAllUserPurchasesQuery, GetAllUserPurchasesQueryVariables>;
export const GetAllPurchasesDocument = gql`
    query getAllPurchases {
  getAllPurchases {
    updatedAt
  }
}
    `;

/**
 * __useGetAllPurchasesQuery__
 *
 * To run a query within a React component, call `useGetAllPurchasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPurchasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPurchasesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPurchasesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllPurchasesQuery, GetAllPurchasesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllPurchasesQuery, GetAllPurchasesQueryVariables>(GetAllPurchasesDocument, baseOptions);
      }
export function useGetAllPurchasesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllPurchasesQuery, GetAllPurchasesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllPurchasesQuery, GetAllPurchasesQueryVariables>(GetAllPurchasesDocument, baseOptions);
        }
export type GetAllPurchasesQueryHookResult = ReturnType<typeof useGetAllPurchasesQuery>;
export type GetAllPurchasesLazyQueryHookResult = ReturnType<typeof useGetAllPurchasesLazyQuery>;
export type GetAllPurchasesQueryResult = ApolloReactCommon.QueryResult<GetAllPurchasesQuery, GetAllPurchasesQueryVariables>;
export const GetPurchaseDocument = gql`
    query getPurchase($id: Float!) {
  getPurchase(id: $id) {
    id
    statusId
    price
    Status {
      id
      ptName
      engName
    }
    pagarmeId
    userAccountId
    Tickets {
      id
      ticketId
      url
    }
    UserAccount {
      id
      name
      photo
      userTypeId
      cpf
      RG
      email
      password
      cardId
      ratting
      ProfileAddress {
        id
        state
        city
        street
        neighborhood
        number
        CEP
        complement
        references
      }
      ProfileContacts {
        id
        phone
      }
    }
    Product {
      id
      title
      subtitle
      amount
      typeNegotiation
      InitialValue
      price
      quantity
      stock
      categorieId
      description
      brand
      useState
      maxInstallments
      height
      width
      thickness
      material
      shippingWay
      deadline
      userAccountId
      UserAccount {
        id
        name
        photo
        userTypeId
        cpf
        RG
        email
        password
        cardId
        ratting
        ProfileAddress {
          id
          state
          city
          street
          neighborhood
          number
          CEP
          complement
          references
        }
        ProfileContacts {
          id
          phone
        }
      }
    }
  }
}
    `;

/**
 * __useGetPurchaseQuery__
 *
 * To run a query within a React component, call `useGetPurchaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPurchaseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPurchaseQuery, GetPurchaseQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPurchaseQuery, GetPurchaseQueryVariables>(GetPurchaseDocument, baseOptions);
      }
export function useGetPurchaseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPurchaseQuery, GetPurchaseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPurchaseQuery, GetPurchaseQueryVariables>(GetPurchaseDocument, baseOptions);
        }
export type GetPurchaseQueryHookResult = ReturnType<typeof useGetPurchaseQuery>;
export type GetPurchaseLazyQueryHookResult = ReturnType<typeof useGetPurchaseLazyQuery>;
export type GetPurchaseQueryResult = ApolloReactCommon.QueryResult<GetPurchaseQuery, GetPurchaseQueryVariables>;
export const GetAllUserSellsDocument = gql`
    query getAllUserSells($sellerId: Float!) {
  getAllUserSells(sellerId: $sellerId) {
    id
    price
    statusId
    pagarmeId
    userAccountId
    createdAt
    productId
    Status {
      id
      engName
      ptName
    }
    ChatHub {
      id
    }
    UserAccount {
      id
      name
    }
    Product {
      id
      title
      subtitle
      amount
      typeNegotiation
      InitialValue
      price
      directValue
      finalOffersTime
      active
      quantity
      stock
      description
      categorieId
      brand
      useState
      maxInstallments
      height
      width
      thickness
      material
      shippingWay
      planId
      userAccountId
      Plan {
        id
        title
        price
      }
      UserAccount {
        id
        name
        photo
        recipientId
        bankAccountId
        userTypeId
        cpf
        RG
        email
        password
        cardId
        ratting
      }
      ProductsRating {
        id
        rating
        createdAt
        ratingDescription
        userAccountId
        UserAccount {
          id
          name
          photo
          recipientId
          bankAccountId
          userTypeId
          cpf
          RG
          email
          password
          cardId
          ratting
        }
        productId
      }
    }
  }
}
    `;

/**
 * __useGetAllUserSellsQuery__
 *
 * To run a query within a React component, call `useGetAllUserSellsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUserSellsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUserSellsQuery({
 *   variables: {
 *      sellerId: // value for 'sellerId'
 *   },
 * });
 */
export function useGetAllUserSellsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllUserSellsQuery, GetAllUserSellsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllUserSellsQuery, GetAllUserSellsQueryVariables>(GetAllUserSellsDocument, baseOptions);
      }
export function useGetAllUserSellsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllUserSellsQuery, GetAllUserSellsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllUserSellsQuery, GetAllUserSellsQueryVariables>(GetAllUserSellsDocument, baseOptions);
        }
export type GetAllUserSellsQueryHookResult = ReturnType<typeof useGetAllUserSellsQuery>;
export type GetAllUserSellsLazyQueryHookResult = ReturnType<typeof useGetAllUserSellsLazyQuery>;
export type GetAllUserSellsQueryResult = ApolloReactCommon.QueryResult<GetAllUserSellsQuery, GetAllUserSellsQueryVariables>;
export const GetAllSellsDocument = gql`
    query getAllSells($sellerId: Float!) {
  getAllSells(sellerId: $sellerId) {
    id
    price
    statusId
    pagarmeId
    userAccountId
    createdAt
    installmentsNumber
    productId
    Status {
      id
      engName
      ptName
    }
    UserAccount {
      id
      name
      photo
      recipientId
      bankAccountId
      userTypeId
      cpf
      RG
      email
      password
      cardId
      ratting
    }
    Product {
      id
      title
      subtitle
      amount
      typeNegotiation
      InitialValue
      price
      directValue
      finalOffersTime
      active
      quantity
      stock
      description
      categorieId
      brand
      useState
      maxInstallments
      height
      width
      thickness
      material
      shippingWay
      planId
      userAccountId
      Plan {
        id
        title
        price
      }
      UserAccount {
        id
        name
        photo
        recipientId
        bankAccountId
        userTypeId
        cpf
        RG
        email
        password
        cardId
        ratting
      }
      ProductsRating {
        id
        rating
        createdAt
        ratingDescription
        userAccountId
        UserAccount {
          id
          name
          photo
          recipientId
          bankAccountId
          userTypeId
          cpf
          RG
          email
          password
          cardId
          ratting
        }
        productId
      }
    }
    ChatHub {
      id
      title
      purchaseId
    }
  }
}
    `;

/**
 * __useGetAllSellsQuery__
 *
 * To run a query within a React component, call `useGetAllSellsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSellsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSellsQuery({
 *   variables: {
 *      sellerId: // value for 'sellerId'
 *   },
 * });
 */
export function useGetAllSellsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllSellsQuery, GetAllSellsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllSellsQuery, GetAllSellsQueryVariables>(GetAllSellsDocument, baseOptions);
      }
export function useGetAllSellsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllSellsQuery, GetAllSellsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllSellsQuery, GetAllSellsQueryVariables>(GetAllSellsDocument, baseOptions);
        }
export type GetAllSellsQueryHookResult = ReturnType<typeof useGetAllSellsQuery>;
export type GetAllSellsLazyQueryHookResult = ReturnType<typeof useGetAllSellsLazyQuery>;
export type GetAllSellsQueryResult = ApolloReactCommon.QueryResult<GetAllSellsQuery, GetAllSellsQueryVariables>;
export const GetTransactionDocument = gql`
    query getTransaction($purchaseId: Float!) {
  getTransaction(purchaseId: $purchaseId)
}
    `;

/**
 * __useGetTransactionQuery__
 *
 * To run a query within a React component, call `useGetTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionQuery({
 *   variables: {
 *      purchaseId: // value for 'purchaseId'
 *   },
 * });
 */
export function useGetTransactionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTransactionQuery, GetTransactionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTransactionQuery, GetTransactionQueryVariables>(GetTransactionDocument, baseOptions);
      }
export function useGetTransactionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTransactionQuery, GetTransactionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTransactionQuery, GetTransactionQueryVariables>(GetTransactionDocument, baseOptions);
        }
export type GetTransactionQueryHookResult = ReturnType<typeof useGetTransactionQuery>;
export type GetTransactionLazyQueryHookResult = ReturnType<typeof useGetTransactionLazyQuery>;
export type GetTransactionQueryResult = ApolloReactCommon.QueryResult<GetTransactionQuery, GetTransactionQueryVariables>;
export const GetPurchasesFiltersCsvDocument = gql`
    query getPurchasesFiltersCsv($purchaseFiltersInput: PurchaseFiltersInput!) {
  getPurchasesFiltersCsv(purchaseFiltersInput: $purchaseFiltersInput)
}
    `;

/**
 * __useGetPurchasesFiltersCsvQuery__
 *
 * To run a query within a React component, call `useGetPurchasesFiltersCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchasesFiltersCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchasesFiltersCsvQuery({
 *   variables: {
 *      purchaseFiltersInput: // value for 'purchaseFiltersInput'
 *   },
 * });
 */
export function useGetPurchasesFiltersCsvQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPurchasesFiltersCsvQuery, GetPurchasesFiltersCsvQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPurchasesFiltersCsvQuery, GetPurchasesFiltersCsvQueryVariables>(GetPurchasesFiltersCsvDocument, baseOptions);
      }
export function useGetPurchasesFiltersCsvLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPurchasesFiltersCsvQuery, GetPurchasesFiltersCsvQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPurchasesFiltersCsvQuery, GetPurchasesFiltersCsvQueryVariables>(GetPurchasesFiltersCsvDocument, baseOptions);
        }
export type GetPurchasesFiltersCsvQueryHookResult = ReturnType<typeof useGetPurchasesFiltersCsvQuery>;
export type GetPurchasesFiltersCsvLazyQueryHookResult = ReturnType<typeof useGetPurchasesFiltersCsvLazyQuery>;
export type GetPurchasesFiltersCsvQueryResult = ApolloReactCommon.QueryResult<GetPurchasesFiltersCsvQuery, GetPurchasesFiltersCsvQueryVariables>;
export const PurchaseFiltersDocument = gql`
    query purchaseFilters($purchaseFiltersInput: PurchaseFiltersInput!) {
  purchaseFilters(purchaseFiltersInput: $purchaseFiltersInput) {
    id
    price
    productQuantity
    paymentMethod
    productShippingPrice
    productShippingWay
    Bling_Nfcs {
      id
      nfcId
    }
    BlingNfscId
    ticketId
    serviceId
    refuseReason
    Tickets {
      id
      ticketId
      url
    }
    UserAccount {
      id
      name
      photo
      recipientId
      bankAccountId
      userTypeId
      cpf
      RG
      email
      password
      cardId
      ratting
      ProfileAddress {
        id
        state
        city
        addressTitle
        neighborhood
        deliveryAddress
        CEP
        complement
        references
        userAccountId
        street
        number
      }
      ProfileContacts {
        id
        phone
      }
    }
    Status {
      id
      engName
      ptName
    }
    ProductVariations {
      id
      ColorVariation {
        id
        name
      }
      TamanhoVariations {
        id
        name
      }
    }
    pagarmeId
    userAccountId
    createdAt
    productId
    trackingCode
    Product {
      id
      title
      subtitle
      amount
      typeNegotiation
      InitialValue
      price
      directValue
      finalOffersTime
      active
      quantity
      stock
      description
      categorieId
      brand
      useState
      weight
      maxInstallments
      height
      width
      thickness
      material
      shippingWay
      planId
      userAccountId
      Plan {
        id
        title
        price
      }
      UserAccount {
        id
        name
        photo
        recipientId
        bankAccountId
        userTypeId
        cpf
        RG
        email
        password
        cardId
        ratting
        ProfileContacts {
          phone
        }
      }
      ProductsRating {
        id
        rating
        createdAt
        ratingDescription
        userAccountId
        UserAccount {
          id
          name
          photo
          recipientId
          bankAccountId
          userTypeId
          cpf
          RG
          email
          password
          cardId
          ratting
        }
        productId
      }
      ProductCategories {
        id
        name
      }
      ProfileAddress {
        id
        state
        city
        addressTitle
        neighborhood
        deliveryAddress
        CEP
        complement
        references
        userAccountId
        street
        number
      }
    }
    ProfileAddress {
      id
      state
      city
      addressTitle
      neighborhood
      deliveryAddress
      CEP
      complement
      references
      userAccountId
      street
      number
    }
    PurchaseInvoice {
      id
      name
      path
    }
  }
}
    `;

/**
 * __usePurchaseFiltersQuery__
 *
 * To run a query within a React component, call `usePurchaseFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseFiltersQuery({
 *   variables: {
 *      purchaseFiltersInput: // value for 'purchaseFiltersInput'
 *   },
 * });
 */
export function usePurchaseFiltersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PurchaseFiltersQuery, PurchaseFiltersQueryVariables>) {
        return ApolloReactHooks.useQuery<PurchaseFiltersQuery, PurchaseFiltersQueryVariables>(PurchaseFiltersDocument, baseOptions);
      }
export function usePurchaseFiltersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PurchaseFiltersQuery, PurchaseFiltersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PurchaseFiltersQuery, PurchaseFiltersQueryVariables>(PurchaseFiltersDocument, baseOptions);
        }
export type PurchaseFiltersQueryHookResult = ReturnType<typeof usePurchaseFiltersQuery>;
export type PurchaseFiltersLazyQueryHookResult = ReturnType<typeof usePurchaseFiltersLazyQuery>;
export type PurchaseFiltersQueryResult = ApolloReactCommon.QueryResult<PurchaseFiltersQuery, PurchaseFiltersQueryVariables>;
export const PutTrackingCodeDocument = gql`
    mutation putTrackingCode($putTrackingCodeInputData: PutTrackingCodeInputData!) {
  putTrackingCode(putTrackingCodeInputData: $putTrackingCodeInputData)
}
    `;
export type PutTrackingCodeMutationFn = ApolloReactCommon.MutationFunction<PutTrackingCodeMutation, PutTrackingCodeMutationVariables>;

/**
 * __usePutTrackingCodeMutation__
 *
 * To run a mutation, you first call `usePutTrackingCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePutTrackingCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [putTrackingCodeMutation, { data, loading, error }] = usePutTrackingCodeMutation({
 *   variables: {
 *      putTrackingCodeInputData: // value for 'putTrackingCodeInputData'
 *   },
 * });
 */
export function usePutTrackingCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PutTrackingCodeMutation, PutTrackingCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<PutTrackingCodeMutation, PutTrackingCodeMutationVariables>(PutTrackingCodeDocument, baseOptions);
      }
export type PutTrackingCodeMutationHookResult = ReturnType<typeof usePutTrackingCodeMutation>;
export type PutTrackingCodeMutationResult = ApolloReactCommon.MutationResult<PutTrackingCodeMutation>;
export type PutTrackingCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<PutTrackingCodeMutation, PutTrackingCodeMutationVariables>;
export const GetAllPurchaseStatusDocument = gql`
    query getAllPurchaseStatus {
  getAllPurchaseStatus {
    id
    engName
    ptName
  }
}
    `;

/**
 * __useGetAllPurchaseStatusQuery__
 *
 * To run a query within a React component, call `useGetAllPurchaseStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPurchaseStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPurchaseStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPurchaseStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllPurchaseStatusQuery, GetAllPurchaseStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllPurchaseStatusQuery, GetAllPurchaseStatusQueryVariables>(GetAllPurchaseStatusDocument, baseOptions);
      }
export function useGetAllPurchaseStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllPurchaseStatusQuery, GetAllPurchaseStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllPurchaseStatusQuery, GetAllPurchaseStatusQueryVariables>(GetAllPurchaseStatusDocument, baseOptions);
        }
export type GetAllPurchaseStatusQueryHookResult = ReturnType<typeof useGetAllPurchaseStatusQuery>;
export type GetAllPurchaseStatusLazyQueryHookResult = ReturnType<typeof useGetAllPurchaseStatusLazyQuery>;
export type GetAllPurchaseStatusQueryResult = ApolloReactCommon.QueryResult<GetAllPurchaseStatusQuery, GetAllPurchaseStatusQueryVariables>;
export const ReportPurchaseProblemDocument = gql`
    mutation reportPurchaseProblem($purchaseId: Float!, $email: String!, $type: String!, $description: String!) {
  reportPurchaseProblem(
    purchaseId: $purchaseId
    email: $email
    type: $type
    description: $description
  )
}
    `;
export type ReportPurchaseProblemMutationFn = ApolloReactCommon.MutationFunction<ReportPurchaseProblemMutation, ReportPurchaseProblemMutationVariables>;

/**
 * __useReportPurchaseProblemMutation__
 *
 * To run a mutation, you first call `useReportPurchaseProblemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportPurchaseProblemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportPurchaseProblemMutation, { data, loading, error }] = useReportPurchaseProblemMutation({
 *   variables: {
 *      purchaseId: // value for 'purchaseId'
 *      email: // value for 'email'
 *      type: // value for 'type'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useReportPurchaseProblemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReportPurchaseProblemMutation, ReportPurchaseProblemMutationVariables>) {
        return ApolloReactHooks.useMutation<ReportPurchaseProblemMutation, ReportPurchaseProblemMutationVariables>(ReportPurchaseProblemDocument, baseOptions);
      }
export type ReportPurchaseProblemMutationHookResult = ReturnType<typeof useReportPurchaseProblemMutation>;
export type ReportPurchaseProblemMutationResult = ApolloReactCommon.MutationResult<ReportPurchaseProblemMutation>;
export type ReportPurchaseProblemMutationOptions = ApolloReactCommon.BaseMutationOptions<ReportPurchaseProblemMutation, ReportPurchaseProblemMutationVariables>;
export const GetPurchaseSlipDocument = gql`
    query getPurchaseSlip($purchaseId: Float!) {
  getPurchaseSlip(purchaseId: $purchaseId) {
    slip_url
  }
}
    `;

/**
 * __useGetPurchaseSlipQuery__
 *
 * To run a query within a React component, call `useGetPurchaseSlipQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseSlipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseSlipQuery({
 *   variables: {
 *      purchaseId: // value for 'purchaseId'
 *   },
 * });
 */
export function useGetPurchaseSlipQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPurchaseSlipQuery, GetPurchaseSlipQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPurchaseSlipQuery, GetPurchaseSlipQueryVariables>(GetPurchaseSlipDocument, baseOptions);
      }
export function useGetPurchaseSlipLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPurchaseSlipQuery, GetPurchaseSlipQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPurchaseSlipQuery, GetPurchaseSlipQueryVariables>(GetPurchaseSlipDocument, baseOptions);
        }
export type GetPurchaseSlipQueryHookResult = ReturnType<typeof useGetPurchaseSlipQuery>;
export type GetPurchaseSlipLazyQueryHookResult = ReturnType<typeof useGetPurchaseSlipLazyQuery>;
export type GetPurchaseSlipQueryResult = ApolloReactCommon.QueryResult<GetPurchaseSlipQuery, GetPurchaseSlipQueryVariables>;