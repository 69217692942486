import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import { useGetAllGroundsForReportQuery, useIncludeProductMutation, useRemoveProductMutation, useVerifyProductsInListQuery} from '../../../../generated/graphql';
import IUserAccount from '../../../../models/IUserAccount';
import { AppState } from '../../../../store';
import AlertModal, { ModalHandles } from '../../../AlertModal/AlertModal';
import { ModalContainer } from './styles';
interface BlockProductInputs {
	reasonForBlocking: string;
}
type Props = {
	productId: number;
};
const BlockProductModal: React.FC<Props> = ({ productId }) => {
	const [modalState, setModalState] = useState<boolean>(false);
	const [removeProductFromTheBlackList] = useRemoveProductMutation();
	const { data, loading } = useGetAllGroundsForReportQuery();
	const [includeProduct] = useIncludeProductMutation();
	const { register, handleSubmit } = useForm<BlockProductInputs>();
	const warningModalRef = useRef<ModalHandles>(null);
	const [warningModalDescription, setWarningModalDescription] = useState<
		string
	>('');
	const user: IUserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const {
		data: dataVerifyBlackList,
		loading: loadingBlackList,
	} = useVerifyProductsInListQuery({
		variables: { productId: productId },
	});
	
	const onSubmit = async (data: any) => {
		try {
			await includeProduct({
				variables: {
					blockReason: data.reasonForBlocking,
					productId: productId,
				},
			});
			setWarningModalDescription('Produto bloqueado com sucesso');
			warningModalRef.current?.handleOpenModal();
		} catch (err: any) {
			setWarningModalDescription('Falha ao bloquear anúncio');
			warningModalRef.current?.handleOpenModal();
		}
	};
	const removeProduct = async (): Promise<void> => {
		try {
			removeProductFromTheBlackList({
				variables: { productId: productId },
			});
			setWarningModalDescription('Produto desbloqueado com sucesso');
			warningModalRef.current?.handleOpenModal();
		} catch (err: any) {
			setWarningModalDescription('Falha ao desbloquer o produto');
			warningModalRef.current?.handleOpenModal();
		}
	};
	return (
		<>
			{user && user.userTypeId === 1 && (
				<button
					className="block-product"
					onClick={() =>
						!dataVerifyBlackList?.verifyProductsInList
							? setModalState(true)
							: removeProduct()
					}
				>
					{`${
						dataVerifyBlackList?.verifyProductsInList
							? 'Desbloquear produto'
							: 'Bloquear Produto'
					}`}
				</button>
			)}
			<AlertModal
				ref={warningModalRef}
				AreaTitle="Anúncio"
				DescriptionText={warningModalDescription}
				reload={false}
			/>
			<Modal
				open={modalState}
				onClose={() => setModalState(false)}
				onOpen={() => setModalState(true)}
				style={{
					width: '560px',
					height: '184px',
					padding: ' 5px 30px 5px',
				}}
			>
				<ModalContainer onSubmit={handleSubmit(onSubmit)}>
					<h2>Bloquear Produto</h2>
					<div className="select-container">
						<label>Motivo do bloqueio</label>
						<select
							{...register('reasonForBlocking',{ required: 'Selecione um motivo' })}
						>
							<option value="">motivo</option>
							{!loading &&
								data?.getAllGroundsForReport.map(
									(reason, index: number) => (
										<option
											value={reason.title}
											key={index}
										>
											{' '}
											{reason.title}{' '}
										</option>
									)
								)}
						</select>
					</div>
					<button type="submit">Concluir</button>
				</ModalContainer>
			</Modal>
		</>
	);
};

export default BlockProductModal;
