import styled from 'styled-components';

export const MainContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	padding-top: 40px;
	padding-bottom: 40px;
	background-color: #fff;

	@media (max-width: 768px) {
		width: 100%;
		padding-bottom: 40px;
	}
	.class-column {
		display: flex;
		flex-direction: column;

		@media (max-width: 768px) {
			width: 88%;
			min-width: 357px;
		}

		align-items: center;
		.card-container {
			display: flex;
			flex-direction: column;
			box-shadow: 3px 2px 9px #00000014;
			border-radius: 8px;
			margin-bottom: 20px;
			width: 700px;
			background-color: #fff;
			border: 1px solid black;
			border-right: 1px solid black;
			@media (max-width: 768px) {
				width: 100%;
			}

			.card-header {
				background-color: #05a5bf;
				border-radius: 7px;
				height: 53px;
				display: flex;
				flex-direction: row;
				align-items: center;

				h2 {
					margin-left: 10px;
					color: #ffffff;
					font-family: 'Poppins', semi-bold;
					font-weight: 600;
					font-size: 16px;
				}
				@media (max-width: 768px) {
					width: 100%;
					background: #05a5bf;
					box-shadow: 0px 3px 6px #00000029;

					h2 {
						color: white;
					}
				}
			}
			.mobile-divider {
				display: none;
			}

			.button-container {
				padding: 14px 57px 15px 57px;
				display: flex;
				flex-direction: column;

				@media (max-width: 768px) {
					padding: 10px;
					align-items: center;
				}

				button {
					height: 34px;
					border: none;
					background-color:#fff;
					border-radius: 7px;
					color: black;
					font-family: 'Poppins', regular;
					font-size: 20px;
					border: 1px solid black;

					@media (max-width: 768px) {
						font-size: 13px;
						width: 320px;
						height: 32px;
						font-weight: 400;
					}
				}
				button:hover {
					cursor: pointer;
				}
				button:focus {
					outline: none;
				}
			}
		}
		.card-infos {
			padding: 14px 57px 15px 26px;
			box-shadow: 3px 2px 9px #00000014;
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
			width: 698px;
			background-color: #fff;
			
			@media (max-width: 768px) {
				width: 100%;
			}
			background-color: #fff;
			.card-options {
				padding: 10px;
				display: flex;
				flex-direction: row;
				align-items: center;
				padding-top: 15px;
				padding-bottom: 15px;

				input {
					width: 45px;
					height: 34px;
				}
				label {
					color: black;
					font-family: 'Poppins', regular;
					font-size: 13px;
					font-weight: 400;
				}
				.input-color {
					border-radius: 1px solid black;
					border-radius:30px;
					div {
						flex-direction: row;
						border: 1px solid black;
						.MuiSvgIcon-root {
							width: 45px;
							height: 34px;
							border-radius: 30px;
							border: 1px solid black;
						}
						.MuiSvgIcon-root {
							width: 45px;
							height: 34px;
							border-radius: 30px;
							border: 1px solid black;
						}
					}
				}
			}
		}

		.card {
			padding: 15px 20px 15px 20px;
			box-shadow: 3px 2px 9px #00000014;
			border-radius: 8px;
			margin-bottom: 20px;
			width: 700px;
			background-color: #fff;
			display: flex;
			flex-direction: row;
			border: 1px solid black;
			
			@media (max-width: 768px) {
				width: 100%;
			}

			.name-and-price {
				display: flex;
				flex-direction: column;
				flex: 1;
				border-right: 1px solid black;

				h2 {
					color: #6b98a6;
					font-size: 16px;
					font-family: 'Poppins';
					font-weight: bold;
				}
				span {
					color: #6b97a5;
					font-size: 20px;
					font-family: 'Poppins', semi-bold;
					font-weight: 600;
				}
			}
			.payment-method {
				display: flex;
				flex-direction: column;
				flex: 1;
				padding-left: 37px;

				@media (max-width: 768px) {
					padding-left: 18px;
				}

				select {
					width: 156px;
					height: 46px;
					border: 1px solid black;
					border-radius: 4px;
					color: black;
					font-family: 'Poppins', regular;
					font-size: 16px;
					background-color: #fff;
				}
				label {
					color: black;
					font-family: 'Poppins', regular;
					font-size: 13px;
				}
				select:focus {
					outline: none;
				}
			}
		}

		.next-button-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			cursor: pointer;

			.back-button {
				background-color: #69b64a;
				width: 41px;
				height: 44px;
				margin-right: 5px;
				border: 1px solid black;
				border-radius: 7px;

				.back-icon {
					width: 11px;
					height: 18px;
					.a {
						fill: white;
					}
				}
			}

			.svg-container {
				background-color: #69b64a;
				width: 47px;
				height: 50px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				margin-right: 6px;
				border-radius: 7px;
				background-color: #69b64a;
				width: 41px;
				height: 44px;

				.back-icon {
					.a {
						fill: white;
					}
				}
			}
			.svg-container:hover,
			button:hover {
				cursor: pointer;
			}
			button:focus {
				outline: none;
			}

			button {
				width: 247px;
				height: 50px;
				background-color: #69b64a;
				color: white;
				font-family: 'Poppins';
				font-weight: bold;
				font-size: 18px;
				border: 1px solid black;
				border-radius: 7px;
				width: 261px;
				height: 44px;
				
			}
		}
	}
	.infos-card {
		display: flex;
		flex-direction: column;
		padding: 23px;
		box-shadow: 3px 2px 9px #00000014;
		border-radius: 8px;
		align-items: flex-start;
		background-color: #fff;
		width: 382px;
		height: max-content;
		justify-content: center;
		padding-bottom: 0px;
		border: 1px solid black;

		@media (max-width: 768px) {
			display: none;
		}

		.infos {
			display: flex;
			flex-direction: column;
			padding: 20px;
			color: #979797;
			font-family: 'Poppins';
			font-weight: 600;
			width: 100%;
			h4 {
				color: #6b98a6;
				font-family: 'Poppins';
				font-weight: 600;
			}

			label {
				color: black;
				font-family: 'Poppins', regular;
				font-size: 14px;
				font-weight: 400;
			}

		}

		.title-infos {
			width: 100%;
			padding: 20px;
			display: flex;
			flex-direction: column;
			h2 {
				color: #6b98a6;
				font-family: 'Poppins';
				font-weight: 600;
				font-size: 16px;
				margin-bottom: 5px;
			}
			label {
				color: black;
				font-family: 'Poppins', regular;
				font-size: 14px;
				margin-bottom: 5px;
			}
		}

		.price-info {
			padding: 20px;
			h2 {
				color: #6b98a6;
				font-family: 'Poppins';
				font-weight: 600;
				font-size: 17px;
			}
		}
	}
`;
export const Container = styled.div`
	background-color: #fff;
	display: flex;
	flex-direction: row;
	justify-content: center;

	@media (max-width:767px){
		height: 150%;
	}
`;
export const MobileContainer = styled.div`
	background-color: #eaeaea;
	display: flex;
	flex-direction: row;
	justify-content: center;
	height: 100%;
	width: 100%;
`;
