import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	height: max-content;
	background: transparent;
	.container {
		width: 70%;
		position: fixed;
		bottom: 20px;
		background: white;
		height: 60px;
		box-shadow: 0 0 10px #aaa;
		z-index: 1;
		padding: 10px 25px;
		display: flex;
		justify-content: space-between;
		border-radius: 8px;

		@media (max-width:475px){
			height:135px ;
			justify-content: space-around;
    		gap: 25px;
    		padding: 20px;
			width:80%;
		}

		p {
			font-family: 'Poppins';
			font-weight: 400;
			font-size:13px;
			@media (max-width:475px){
				font-size:11px;
			}
		}

		button {
			background-color: #6b97a5;
			border-radius: 5px;
			color: white;
			font-family: 'Poppins';
			font-weight: bold;
			font-size: 12px;
			width: 193px;
			height: 40px;
			border: none;
			cursor: pointer;
			@media (max-width:475px){
				margin-top: 15px;
				height:50%;
			}
		}
	}
`;
