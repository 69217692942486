import React, {
	forwardRef,
	useCallback,
	useState,
	useImperativeHandle,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Modal } from 'semantic-ui-react';
import { DescriptionText, TitleText } from './styles';
export interface ModalBankAccountHandles {
	OpenModal: () => void;
	handleCloseModal: () => void;
}

const WarningModal = React.forwardRef<ModalBankAccountHandles>((props, ref) => {

	const [state, setState] = useState({ dimmer: undefined, open: false });
	const show = (dimmer: any) => setState({ dimmer: undefined, open: true });

	const OpenModal = useCallback(() => {
		setState({ dimmer: undefined, open: true });
	}, []);

	const history = useHistory();

	const handleCloseModal = useCallback(() => {
		setState({ dimmer: undefined, open: false });
		history.push('/user/bank-account');
	}, []);

	useImperativeHandle(ref, () => {
		return {
			OpenModal,
			handleCloseModal
		};
	});

	return (
		<Modal
			centered={false}
			open={state.open}
			onClose={() => setState({ dimmer: undefined, open: false })}
			onOpen={() => setState({ dimmer: undefined, open: true })}
			closeOnDimmerClick={false}
			closeOnEscape={false}
			closeOnTriggerBlur={false}
			closeOnTriggerClick={false}
			closeOnDocumentClick={false}
		>
			<Modal.Header>
				<TitleText> Anúncio</TitleText>
			</Modal.Header>
			<Modal.Content>
				<Modal.Description>
					<DescriptionText>
						É necessário ter uma conta bancaria cadastrada para
						criar um anúncio
					</DescriptionText>
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={() => history.goBack()}>Cancelar</Button>
				<Button onClick={() => handleCloseModal()}>
					Cadastrar conta
				</Button>
			</Modal.Actions>
		</Modal>
	);
});

export default WarningModal;
