import styled from 'styled-components';

export const Header = styled.header`
	width: 100%;
	background: #ca667e;
	height: 301px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.text-container {
		width: 66%;
		max-width: 867px;

		h2 {
			color: white;
			font-family: 'Poppins';
			font-weight: 600;
			font-size: 38px;
			margin-bottom: 20px;
		}

		p,
		span {
			color: white;
			font-family: 'Poppins';
			font-weight: 600;
			font-size: 14px;
		}
		p {
			margin-bottom: 25px;
			line-height: 21px;
		}
	}

	@media (min-width:425px) and (max-width:767px){
		height:100%;
		.text-container{
			width: 80%;
			max-width: 475px;
		}
	}
	@media (min-width:375px) and (max-width:424px){
		height:100%;
		.text-container{
			width: 80%;
			max-width: 375px;

			h2{
				font-size:25px;
			}
			p,
		span {
			font-size: 13px;
		}
		p {
			margin-bottom: 25px;
			line-height: 21px;
		}
		}
	}

	@media (min-width:320px) and (max-width:374px){
		height:100%;
		.text-container{
			width: 90%;
			max-width: 320px;
			span{
				font-size:12px;
			}
			p {
				margin-bottom: 25px;
				line-height: 21px;
				font-size:12px;
			}
			h2{
				font-size:20px;
				margin-bottom: 15px;
				margin-top: 5px;
			}
		}
	}
`;



export const Aside = styled.aside`
	width: 100%;
	height: 48px;
	background: #05a9c4;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	.link-container {
		width: 66%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		span {
			color: white;
			font-family: 'Poppins';
			font-weight: 600;
			font-size: 13px;
		}
		.redirect-button {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			margin-left: 8px;
			width: 130px;
			height: 28px;
			background: #ffffff 0% 0% no-repeat padding-box;
			border-radius: 16px;
			color: #05a9c4;
			font-family: 'Poppins';
			font-weight: 600;
			font-size: 14px;
		}
	}

	@media (min-width:425px) and (max-width:767px){
		height:25%;
		.link-container{ 
			.redirect-button{
			margin-left: 8px;
			width: 23rem;
			height: 5rem;
			border-radius: 3rem;
			color: #05a9c4;
			font-family: 'Poppins';
			font-weight: 600;
			font-size: 13px;
			}
		}	
	}
	
	@media (min-width:375px) and (max-width:424px){
		height:25%;
		.link-container{ 
			.redirect-button{
			margin-left: 8px;
			width: 24rem;
			height: 5rem;
			border-radius: 3rem;
			color: #05a9c4;
			font-family: 'Poppins';
			font-weight: 600;
			font-size: 12px;
			}
		}	
	}
		
	@media (min-width:320px) and (max-width:374px){
		height:25%;
		.link-container{ 
			span{
				font-size:12px;
			}
			.redirect-button{
				margin-left: 8px;
				width: 23rem;
				height: 4rem;
				border-radius: 2rem;
				color: #05a9c4;
				font-family: 'Poppins';
				font-weight: 600;
				font-size: 10px;
			}
		}	
	}
`;
export const Main = styled.main`
	width: 100%;
	height: max-content;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 45px;
	background-color:#fff;
	.products-card {
		background: #fff 0% 0% no-repeat padding-box;
		box-shadow: 0px 2px 9px #00000014;
		border-radius: 8px;
		width: 60%;
		height: max-content;
		padding: 35px;
		border: 1px solid black;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		h2 {
			color: #6b98a6;
			font-family: 'Poppins';
			font-weight: 600;
			font-size: 17px;
		}
		p {
			color: black;
			font-family: 'Poppins';
			font-weight: 400;
			font-size: 13px;
		}
		.institutions {
			display: grid;
			padding: 1rem;
			border-bottom: 1px black solid;
			margin-bottom: 1.5rem;
			.PequenoPrincipeImg{
				height: 8rem;
			}
		}
		.products {
			margin-top: 25px;
			display: flex;
			column-gap: 20px;
			flex-wrap: wrap;
			height: max-content;
			align-items: center;

			.see-more {
				width: 86px;
				height: 61px;
				color: #6b98a6;
				font-size: 19px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				font-family: 'Poppins';
				font-weight: 600;
			}
		}
	}

	@media (min-width:425px) and (max-width:767px){
		padding:10px;
		.products-card{
			width: 100%;
		}
	}
	@media (min-width:375px) and (max-width:424px){
		padding: 10px;;
		.products-card{
			width: 100%;
		}
	}
	@media (min-width:320px) and (max-width:374px){
		padding:10px;
		.products-card{
			width: 100%;
		}
	}
`;
