import React, { useEffect, useState } from 'react'
import {
    useHistory,
    useLocation,
    useRouteMatch,
} from 'react-router-dom'
import { useSelector } from 'react-redux'
import IUserAccount from '../../../models/IUserAccount'
import { AppState } from '../../../store'
import {
    CommentsTitle,
    FlexContainer,
    ProductSellersContainer,
    RankingContainer,
    RankingContent,
    RankingTitle,
    ReputationContainter,
} from './Style'
import { ReactComponent as StarRate } from '../../../assets/icons/starReputation.svg'
import Gold from '../../../assets/icons/goldMedal.svg'
import {
    useGetAllUserRatingsQuery,
    useGetAllUserSellsQuery,
    useGetProductsSoldByPeriodQuery,
} from '../../../generated/graphql'
import UserReputationGraph from './SellerDashboardGraph/SellerDashboardGraph'
import Img from '../../Default/CommomImg/Img'
import _ from 'lodash'
import { Cell, Legend, Pie, PieChart } from 'recharts'
import useWindow from '../../../hooks/useWindow'

type ProdutosMaisVendidos = {
    name: string
    value: number
}

const SellerDashboard = () => {
    const history = useHistory()
    const { pathname } = useLocation()
    const window = useWindow()
    const user: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )
    const { data, loading } = useGetAllUserRatingsQuery({
        variables: { userSalerId: user.id },
        fetchPolicy: 'no-cache',
    })
    const { data: getAllProductSells, loading: getAllProductSellsLoading } =
        useGetProductsSoldByPeriodQuery({
            fetchPolicy: 'no-cache',
            variables: { userId: user.id, period: 30 },
        })
    const { path, url } = useRouteMatch()
    const { data: dataSells, loading: loadingSells } = useGetAllUserSellsQuery({
        variables: { sellerId: user.id },
    })
    const [produtosMaisVendidos, setprodutosMaisVendidos] =
        useState<ProdutosMaisVendidos[]>()

    useEffect(() => {
        if (!getAllProductSellsLoading && getAllProductSells) {
            const purschasesSells = _.orderBy(
                getAllProductSells.getProductsSoldByPeriod,
                ['n_totalProduto'],
                ['desc']
            )
            const purschasesConververt: ProdutosMaisVendidos[] = []
            for (const purschase of purschasesSells) {
                const produtoVendido: ProdutosMaisVendidos = {
                    name: purschase.Product.title,
                    value: Number(purschase.n_totalProduto),
                }
                purschasesConververt.push(produtoVendido)
            }
            setprodutosMaisVendidos(purschasesConververt)
        }
    }, [getAllProductSells])

    useEffect(() => {
        if (user && pathname === `/shopping/${user.id}/dashboard`) {
            const newPath = `${pathname}/god`
            history.push(newPath)
        }
    }, [history, pathname, user])

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']
    const verifyRating = (rating: number) => {
        if (rating === 1) {
            return (
                <>
                    <StarRate id="active-star"></StarRate>
                    <StarRate id="star"></StarRate>
                    <StarRate id="star"></StarRate>
                    <StarRate id="star"></StarRate>
                    <StarRate id="star"></StarRate>
                </>
            )
        }
        if (rating === 2) {
            return (
                <>
                    <StarRate id="active-star"></StarRate>
                    <StarRate id="active-star"></StarRate>
                    <StarRate id="star"></StarRate>
                    <StarRate id="star"></StarRate>
                    <StarRate id="star"></StarRate>
                </>
            )
        }
        if (rating === 3) {
            return (
                <>
                    <StarRate id="active-star"></StarRate>
                    <StarRate id="active-star"></StarRate>
                    <StarRate id="active-star"></StarRate>
                    <StarRate id="star"></StarRate>
                    <StarRate id="star"></StarRate>
                </>
            )
        }
        if (rating === 4) {
            return (
                <>
                    <StarRate id="active-star"></StarRate>
                    <StarRate id="active-star"></StarRate>
                    <StarRate id="active-star"></StarRate>
                    <StarRate id="active-star"></StarRate>
                    <StarRate id="star"></StarRate>
                </>
            )
        }
        if (rating === 5) {
            return (
                <>
                    <StarRate id="active-star"></StarRate>
                    <StarRate id="active-star"></StarRate>
                    <StarRate id="active-star"></StarRate>
                    <StarRate id="active-star"></StarRate>
                    <StarRate id="active-star"></StarRate>
                </>
            )
        }
    }

    return (
        <>
            <RankingContainer>
                <RankingTitle>
                    <div className="ranking-title-container">
                        <h2>Painel</h2>
                    </div>
                </RankingTitle>
                <FlexContainer>
                    <RankingContent>
                        <img src={Gold} alt=" " />
                        <h2>Vendedor</h2>
                        <span>
                            {dataSells?.getAllUserSells.length} vendas
                            realizadas
                        </span>
                    </RankingContent>
                    <UserReputationGraph data={dataSells?.getAllUserSells} />
                </FlexContainer>
                <FlexContainer>
                    <h2
                        style={{
                            color: '#6b97a5',
                            fontFamily: 'Poppins',
                            fontSize: '25px',
                            paddingTop: '1rem',
                        }}
                    >
                        Produtos mais vendidos no mês
                    </h2>
                </FlexContainer>
                <ProductSellersContainer>
                    {window.innerWidth > 767 && (
                        <PieChart width={400} height={300}>
                            <Pie
                                data={produtosMaisVendidos}
                                cx={120}
                                cy={150}
                                label
                                outerRadius={110}
                                fill="#8884d8"
                                dataKey="value"
                            >
                                {produtosMaisVendidos &&
                                    produtosMaisVendidos.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={COLORS[index % COLORS.length]}
                                        />
                                    ))}
                            </Pie>
                            {window.innerWidth > 767 && (
                                <Legend
                                    verticalAlign="bottom"
                                    height={25}
                                    width={1200}
                                />
                            )}
                        </PieChart>
                    )}
                    {window.innerWidth < 475 && (
                        <PieChart width={300} height={300}>
                            <Pie
                                data={produtosMaisVendidos}
                                cx={120}
                                cy={150}
                                label
                                outerRadius={110}
                                fill="#8884d8"
                                dataKey="value"
                            >
                                {produtosMaisVendidos &&
                                    produtosMaisVendidos.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={COLORS[index % COLORS.length]}
                                        />
                                    ))}
                            </Pie>
                            {window.innerWidth < 475 && (
                                <Legend
                                    verticalAlign="bottom"
                                    height={300}
                                    width={300}
                                />
                            )}
                        </PieChart>
                    )}
                </ProductSellersContainer>
                <FlexContainer></FlexContainer>
            </RankingContainer>

            <ReputationContainter>
                <CommentsTitle>
                    <div className="title-container">
                        <h2>Principais comentários sobre você</h2>
                    </div>
                </CommentsTitle>
                <div className="questions-card">
                    <>
                        {data &&
                            data.getAllUserRatings.map(
                                (rating: any, index: number) => (
                                    <>
                                        {rating.rating > 2 && (
                                            <div
                                                className="question"
                                                key={index}
                                            >
                                                <div className="user-img">
                                                    <Img
                                                        src={`${process.env.REACT_APP_STATIC_URL}/user/${rating.userAccountId}.jpeg`}
                                                    />
                                                </div>

                                                <div className="question-content">
                                                    <div className="question-infos">
                                                        <h2>
                                                            {
                                                                rating
                                                                    .UserAccount
                                                                    ?.name
                                                            }
                                                        </h2>
                                                    </div>

                                                    <div className="question-description">
                                                        <div className="rating-stars">
                                                            {verifyRating(
                                                                rating.rating
                                                            )}
                                                        </div>
                                                        <span>
                                                            {
                                                                rating.ratingDescription
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )
                            )}
                    </>
                </div>
            </ReputationContainter>
        </>
    )
}
export default SellerDashboard
