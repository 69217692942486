import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useLoginMutation } from '../../generated/graphql'
import {
    Container,
    IconPassWord,
    MensagemError,
    MensagemErrorAutenticacao,
    RegisterInput,
} from './Style'
import { Link } from 'react-router-dom'
import { updateUser } from '../../actions/UserActions'
import { updateCheckpointPath } from '../../actions/CheckpointActions'
import Checkpoint from '../../models/Checkpoint'
import { AppState } from '../../store'
import { setAccessToken } from '../../AccessToken'
import { useForm } from 'react-hook-form'
import logo from '../../assets/icons/logo.svg'
import { AiOutlineEye } from '@react-icons/all-files/ai/AiOutlineEye'
import { AiOutlineEyeInvisible } from '@react-icons/all-files/ai/AiOutlineEyeInvisible'
import { InputLabel } from '@material-ui/core'
import { ToastContainer, toast } from 'react-toastify'
import { useQuery } from '../../hooks/useQuery'

interface LoginInputs {
    email: string
    password: string
}

const UserLogin: React.FC = () => {
    const checkpoint: Checkpoint = useSelector(
        (state: AppState) => state.checkpoint.currentCheckpoint
    )
    const [login] = useLoginMutation()
    const [emailValue, setEmailValue] = useState('')
    const [seePassWord, setSeePassWord] = useState(false)
    const dispatch = useDispatch()
    const [loginError, setLoginError] = useState<string>('')
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<LoginInputs>({
        mode: 'all',
        reValidateMode: 'onChange',
    })

    const history = useHistory()
    const query = useQuery()
    const unavailable = query.get('unavailable') === 'true'
    const next: string | null = query.get('next')
    const email: string | null = query.get('email')
    const identifier: string | null = query.get('identifier')

    const handleRegister = (): void => {
        if (next) dispatch(updateCheckpointPath(next))
        history.push('/register')
    }
    const handleLogin = useCallback(async (data) => {
        try {
            const log = await login({
                variables: { email: data.email, password: data.password },
            })

            if (log.data) {
                dispatch(updateUser(log!.data!.login!.user! as any))
                setAccessToken(log.data.login.accessToken)
                localStorage.setItem('token', log.data.login.accessToken)
                if (log.data.login.user.userTypeId === 1) {
                    history.push('/home')
                } else {
                    if (next) {
                        history.push(`${next}`)
                    } else if (checkpoint?.path) {
                        history.push(checkpoint.path)
                    } else {
                        history.push('/')
                    }
                }
            }
        } catch (error: any) {
            console.log('Error', emailValue)
            if (
                error.message === 'GraphQL error: account not active' &&
                data.email
            ) {
                history.push('/account-activation-warning', {
                    state: {
                        email: data.email,
                    },
                })
            }

            if (
                error.message.substring(15, error.message.length) ===
                'not found'
            ) {
                toast.error('Falha na autenticação', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    type: 'error',
                    draggable: true,
                    progress: undefined,
                })
            } else if (
                error.message.substring(15, error.message.length) ===
                'password incorrect'
            ) {
                toast.error('Falha na autenticação', {
                    position: 'top-right',
                    autoClose: 5000,
                    type: 'error',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    }, [])
    const handleUnvailable = (): void => {
        if (unavailable && identifier) {
            toast.error('Já existe uma conta cadastrada neste CPF/CNPJ', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                type: 'error',
                draggable: true,
                progress: undefined,
            })
        }
        if (unavailable && email) {
            toast.error('Já existe uma conta cadastrada neste email', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                type: 'error',
                draggable: true,
                progress: undefined,
            })
            setValue('email', email)
        }
    }

    useEffect(() => {
        handleUnvailable()
    }, [unavailable])

    function exibirErro(error: any): JSX.Element | undefined {
        if (error.type === 'required')
            return <MensagemError>Campo obrigatório</MensagemError>
    }
    return (
        <>
            <Container onSubmit={handleSubmit(handleLogin)}>
                <ToastContainer />
                <div className="infos-container">
                    <img src={logo} alt="" />
                    <h1>Entrar</h1>
                    <h3>Acesse o Sambay e comece a negociar</h3>
                </div>
                <div className="line" />
                <div className="input-organizer">
                    <div
                        className="input-container"
                        style={{ marginBottom: '25px' }}
                    >
                        <InputLabel style={{ fontSize: 16 }}>Login:</InputLabel>
                        <RegisterInput
                            type="text"
                            placeholder="email@email.com"
                            border={
                                errors.email
                                    ? 'solid 1px red'
                                    : 'solid 1px black'
                            }
                            {...register('email', { required: true })}
                            onChange={(e) => setEmailValue(e.target.value)}
                        />

                        {errors.email && exibirErro(errors.email)}
                    </div>
                    <div className="input-container">
                        <InputLabel
                            style={{ fontSize: 16 }}
                            htmlFor="outlined-adornment-password"
                        >
                            Senha:
                        </InputLabel>
                        <RegisterInput
                            type={!seePassWord ? 'password' : 'text'}
                            border={
                                errors.password
                                    ? 'solid 1px red'
                                    : 'solid 1px black'
                            }
                            {...register('password', { required: true })}
                        />
                        {errors.password && exibirErro(errors.password)}
                        <IconPassWord
                            onClick={() => setSeePassWord(!seePassWord)}
                        >
                            {seePassWord ? (
                                <AiOutlineEye size={25} color="gray" />
                            ) : (
                                <AiOutlineEyeInvisible size={25} color="gray" />
                            )}
                        </IconPassWord>
                    </div>
                    <MensagemErrorAutenticacao style={{ marginTop: '10px' }}>
                        {loginError}
                    </MensagemErrorAutenticacao>
                    <button type="submit">Acesse já!</button>
                    <span className="styleConta">
                        <div>
                            Ainda não possui uma conta?
                            <a
                                style={{
                                    color: '#3E69AE',
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                }}
                                onClick={() => handleRegister()}
                            >
                                {' '}
                                Clique aqui
                            </a>
                        </div>

                        <Link
                            style={{
                                color: '#3E69AE',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                            to={'/reset-password'}
                        >
                            Esqueci minha senha
                        </Link>
                    </span>
                </div>
            </Container>
        </>
    )
}
export default UserLogin
