import IUserCards, {
	IInsertCardsAction,
	IInsertOneCardAction,
} from '../models/IUserCards';

export const InsertCards = (cards?: IUserCards[]): IInsertCardsAction => ({
	type: 'INSERT_CARDS',
	cards,
});

export const InsertOneCard = (
	card?: IUserCards | null
): IInsertOneCardAction => ({
	type: 'INSERT_ONE_CARD',
	card,
});
