// React
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Classes
import IProductsCart from '../../../../models/ICart'
import IUserAccount from '../../../../models/IUserAccount'
import { AppState } from '../../../../store'

// Styles
import { Container } from './styles'
import {
    FormControl,
    FormControlLabel,
    makeStyles,
    Radio,
    RadioGroup,
} from '@material-ui/core'
import {
    insertAddressProductAction,
    insertProductQuantity,
    insertProductShippingWay,
    insertServiceId,
} from '../../../../actions/ProductsCartActions'

import ChooseAddressModal from './ChooseAddressModal/ChooseAddressModal'

import {
    ResponseShippingCalculate,
    useCalculateShippingValueLazyQuery,
    useGetShippingsByQuoteLazyQuery,
} from '../../../../generated/graphql'

type Props = {
    item: IProductsCart
}
export function BuyProductsCartShippingChoiceItem({ item }: Props) {
    const [productQuantity, setProductQuantity] = useState<number>(1)
    const [shippingWay, setShippingWay] = useState<boolean>(false)
    const user: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )

    const dispatch = useDispatch()

    const [
        getMelhorEnvioShippings,
        {
            data: dataMelhorEnvioShippings,
        },
    ] = useCalculateShippingValueLazyQuery({})

    const [
        getShippingsByQuote,
        {
            data: dataShippingsByQuote,
        },
    ] = useGetShippingsByQuoteLazyQuery({})

    const [melhorEnvioShippings, setMelhorEnvioShippings] = useState<
        ResponseShippingCalculate[]
    >([])

    const [shippingsByQuote, setShippingsByQuote] = useState<any[]>([])

    useEffect(() => {
        if (
            item &&
            item.parameters.shippingWay &&
            (item.parameters.shippingWay === 'withdraw' ||
                item.parameters.shippingWay === 'freeDelivery')
        )
            setShippingWay(true)
    }, [item, item.parameters.shippingWay])

    useEffect(() => {
        if (
            !item?.parameters?.address &&
            item?.parameters?.shippingWay !== 'withdraw'
        ) {
            dispatch(
                insertAddressProductAction(
                    item?.parameters?.product?.id as any,
                    user!.ProfileAddress![0]!
                )
            )
        }
    }, [dispatch, item.parameters.address, item.parameters.product.id, item.parameters.shippingWay, user, user.ProfileAddress])

    const verifyShippingValues = useCallback(() => {
        if (
            item.parameters.address &&
            item.parameters.address.CEP &&
            user.ProfileAddress &&
            user.ProfileAddress.length > 0 &&
            user.ProfileAddress[0].CEP
        ) {
            getMelhorEnvioShippings({
                variables: {
                    infos: {
                        from: {
                            postal_code:
                                item!.parameters.product!.ProfileAddress!.CEP!,
                        },
                        to: {
                            postal_code: !item.parameters?.address?.CEP
                                ? user!.ProfileAddress![0]!.CEP!
                                : item!.parameters!.address!.CEP!,
                        },
                        products: [
                            {
                                height: item!.parameters!.product!.height!,
                                id: item!.parameters!.product!.id.toString(),
                                length: item!.parameters!.product!.thickness!,
                                weight: Number.parseInt(
                                    item!.parameters!.product!.weight!
                                ),
                                quantity: item!.parameters!.quantity,
                                width: item!.parameters!.product!.width!,
                                insurance_value:
                                    item!.parameters!.product!.price! *
                                    item!.parameters!.quantity,
                            },
                        ],
                    },
                    userId: item.parameters.product!.userAccountId!,
                    productId: item.parameters.product.id,
                },
            })
            getShippingsByQuote({
                variables: {
                    productId: item.parameters.product.id,
                    postalCode: Number.parseInt(
                        item.parameters.address.CEP
                            ? item.parameters.address.CEP
                            : user.ProfileAddress[0].CEP
                    ),
                },
            })
        }
    }, [getMelhorEnvioShippings, getShippingsByQuote, item, user])

    useEffect(() => {
        if (
            item &&
            item?.parameters?.address?.state !== 'RJ' &&
            user.ProfileAddress &&
            user.ProfileAddress?.length > 0
        ) {
            verifyShippingValues()
        }
    }, [item, user, verifyShippingValues])

    useEffect(() => {
        if (
            item &&
            item?.parameters?.address?.state !== 'RJ' &&
            user.ProfileAddress &&
            user.ProfileAddress?.length > 0
        ) {
            verifyShippingValues()
        }
    }, [item, item.parameters.address, user.ProfileAddress, verifyShippingValues])

    useEffect(() => {
        if (dataMelhorEnvioShippings) {
            setMelhorEnvioShippings(
                dataMelhorEnvioShippings!.calculateShippingValue!
            )
        }
    }, [dataMelhorEnvioShippings])

    useEffect(() => {
        if (dataShippingsByQuote && dataShippingsByQuote.getShippingsByQuote) {
            setShippingsByQuote(dataShippingsByQuote.getShippingsByQuote)
        }
    }, [dataShippingsByQuote])

    const useStyles = makeStyles({
        root: {
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
    })

    function StyledRadio(props: any) {
        const classes = useStyles()

        return (
            <Radio
                className={classes.root}
                disableRipple
                color="default"
                checked={props.checked}
                {...props}
                name="shipping"
                style={{
                    marginRight: '8px',
                }}
                onClick={() => props.onClick()}
            />
        )
    }
    function verifyProductStock(subtract: boolean) {
        if (!subtract) {
            if (productQuantity === item?.parameters?.product?.stock!) {
                alert('Não existem mais itens no estoque')
            }

            dispatch(insertProductQuantity(item.parameters.product.id, true))
            setProductQuantity(item?.parameters?.quantity + 1)
        } else if (subtract && item.parameters.quantity > 1) {
            dispatch(insertProductQuantity(item.parameters.product.id, false))
        }
    }

    function getShippingType(): string {
        if (item.parameters.product.freeDelivery) return 'freeDelivery'
        return 'withdraw'
    }

    return (
        <>
            <Container>
                <div className="card-infos">
                    <div className="product-header">
                        <h1>{item?.parameters?.product.title}</h1>
                    </div>
                    <FormControl component="fieldset" style={{ width: '100%' }}>
                        <RadioGroup aria-label="gender" name="gender2">
                            <>
                                {item &&
                                    item.parameters.product.withdraw !==
                                        'exclusivamente' &&
                                    !item.parameters.product.freeDelivery &&
                                    melhorEnvioShippings &&
                                    melhorEnvioShippings.map(
                                        (melhorEnvioShipping, index) => (
                                            <div
                                                className="card-options"
                                                key={index}
                                            >
                                                <FormControlLabel
                                                    className="label"
                                                    control={
                                                        <StyledRadio
                                                            name="shipping"
                                                            style={{
                                                                marginRight:
                                                                    '8px',
                                                            }}
                                                            checked={
                                                                item.parameters
                                                                    ?.service
                                                                    ?.id ===
                                                                    melhorEnvioShipping.id &&
                                                                item?.parameters
                                                                    ?.shippingWay !==
                                                                    'withdraw'
                                                            }
                                                            color="default"
                                                            onClick={(
                                                                e: any
                                                            ) => {
                                                                dispatch(
                                                                    insertProductShippingWay(
                                                                        item
                                                                            .parameters
                                                                            .product
                                                                            .id as any,
                                                                        'delivery'
                                                                    )
                                                                )
                                                                dispatch(
                                                                    insertServiceId(
                                                                        item
                                                                            .parameters
                                                                            .product
                                                                            .id,
                                                                        melhorEnvioShipping.id,
                                                                        melhorEnvioShipping.name,
                                                                        melhorEnvioShipping.custom_price
                                                                            ? melhorEnvioShipping.custom_price
                                                                            : undefined,
                                                                        melhorEnvioShipping.delivery_time
                                                                            ? melhorEnvioShipping.delivery_time
                                                                            : undefined
                                                                    )
                                                                )
                                                            }}
                                                        />
                                                    }
                                                    label={
                                                        <>
                                                            <label>
                                                                {melhorEnvioShipping
                                                                    .company
                                                                    ?.name +
                                                                    '- ' +
                                                                    melhorEnvioShipping.name}{' '}
                                                                -{' '}
                                                                {
                                                                    melhorEnvioShipping.custom_price
                                                                }{' '}
                                                                -{' '}
                                                                {`Chega em ${melhorEnvioShipping.delivery_time} dias`}
                                                                <br />
                                                                <br />
                                                            </label>
                                                        </>
                                                    }
                                                />
                                            </div>
                                        )
                                    )}
                                {item &&
                                    item.parameters.product.withdraw !==
                                        'exclusivamente' &&
                                    !item.parameters.product.freeDelivery &&
                                    shippingsByQuote &&
                                    shippingsByQuote.map(
                                        (shippingByQuote, index) => {
                                            return (
                                                <div
                                                    className="card-options"
                                                    key={index}
                                                >
                                                    <FormControlLabel
                                                        className="label"
                                                        control={
                                                            <StyledRadio
                                                                name="shipping"
                                                                style={{
                                                                    marginRight:
                                                                        '8px',
                                                                }}
                                                                checked={
                                                                    item
                                                                        .parameters
                                                                        ?.service
                                                                        ?.id ===
                                                                        shippingByQuote.id &&
                                                                    item
                                                                        ?.parameters
                                                                        ?.shippingWay !==
                                                                        'withdraw'
                                                                }
                                                                color="default"
                                                                onClick={() => {
                                                                    dispatch(
                                                                        insertProductShippingWay(
                                                                            item
                                                                                .parameters
                                                                                .product
                                                                                .id as any,
                                                                            'delivery'
                                                                        )
                                                                    )
                                                                    dispatch(
                                                                        insertServiceId(
                                                                            item
                                                                                .parameters
                                                                                .product
                                                                                .id,
                                                                            shippingByQuote.id,
                                                                            shippingByQuote.description ||
                                                                                '',
                                                                            (
                                                                                shippingByQuote.price /
                                                                                100
                                                                            ).toString(),
                                                                            shippingByQuote.deliveryTime
                                                                                ? shippingByQuote.deliveryTime
                                                                                : undefined
                                                                        )
                                                                    )
                                                                }}
                                                            />
                                                        }
                                                        label={
                                                            <>
                                                                <label>
                                                                    {shippingByQuote.carrier +
                                                                        '- ' +
                                                                        shippingByQuote.description}{' '}
                                                                    -{' '}
                                                                    {(
                                                                        shippingByQuote.price /
                                                                        100
                                                                    ).toLocaleString(
                                                                        'pt-br',
                                                                        {
                                                                            style: 'currency',
                                                                            currency:
                                                                                'BRL',
                                                                        }
                                                                    )}{' '}
                                                                    -{' '}
                                                                    {`Chega em ${shippingByQuote.deliveryTime} dias`}
                                                                    <br />
                                                                    <br />
                                                                </label>
                                                            </>
                                                        }
                                                    />
                                                </div>
                                            )
                                        }
                                    )}
                                <label
                                    style={{
                                        borderBottom: 'solid 1px black',
                                    }}
                                >
                                    <br />
                                    <br />
                                    {item.parameters.address && (
                                        <>
                                            {
                                                item?.parameters?.address
                                                    ?.addressTitle
                                            }
                                            <br />
                                            {item?.parameters?.address?.street +
                                                ', ' +
                                                item?.parameters?.address
                                                    ?.number +
                                                ' - ' +
                                                item?.parameters?.address
                                                    ?.city +
                                                ', ' +
                                                item?.parameters?.address
                                                    ?.state}
                                        </>
                                    )}

                                    <br />
                                    <br />
                                    <ChooseAddressModal item={item} />
                                </label>{' '}
                            </>
                            {item?.parameters.product.withdraw === 'Sim' ||
                            item?.parameters.product.withdraw ===
                                'exclusivamente' ? (
                                <div
                                    className="card-options"
                                    style={{
                                        border: 'none',
                                        paddingTop: '8px',
                                    }}
                                >
                                    <FormControlLabel
                                        className="label"
                                        control={
                                            <StyledRadio
                                                color="default"
                                                name="shipping"
                                                style={{
                                                    marginRight: '8px',
                                                }}
                                                checked={shippingWay}
                                                onClick={(e: any) => {
                                                    dispatch(
                                                        insertProductShippingWay(
                                                            item.parameters
                                                                .product.id,
                                                            getShippingType()
                                                        )
                                                    )
                                                    dispatch(
                                                        insertServiceId(
                                                            item.parameters
                                                                .product.id,
                                                            0,
                                                            '',
                                                            '',
                                                            0
                                                        )
                                                    )
                                                }}
                                                value={'withdraw'}
                                            />
                                        }
                                        label={
                                            <label>
                                                {item.parameters.product
                                                    .freeDelivery
                                                    ? 'Frete Grátis'
                                                    : 'Combinar com o vendedor'}
                                            </label>
                                        }
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                        </RadioGroup>
                    </FormControl>
                </div>
            </Container>
        </>
    )
}

export default BuyProductsCartShippingChoiceItem
