import React, {
	forwardRef,
	useCallback,
	useState,
	useImperativeHandle,
	SetStateAction,
	Dispatch,
} from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { DescriptionText, TitleText } from './styles';

export interface ModalHandles {
	handleOpenModal: () => void;
	handleCloseModal: () => void;
}
interface ModalProps {
	AreaTitle: string;
	DescriptionText: string;
	reload: boolean;
	descriptCancel:string;
	setCancelPurchase:Dispatch<SetStateAction<boolean>>;
	setDescriptCancel:Dispatch<SetStateAction<string>>;
}

const CancelPurchaseModal = forwardRef<ModalHandles, ModalProps>((props,ref) => {

	const [state, setState] = useState(false);

	

	const handleOpenModal = useCallback(() => {
		setState(true);
	}, [props]);
	
	const handleCloseModal = useCallback(() => {
		setState(false);

		if (props.reload) {
			window.location.reload();
		}
	}, [props]);

	useImperativeHandle(ref, () => {
		return {
			handleOpenModal,
			handleCloseModal
		};
	});

	return (
		<Modal
			centered={false}
			open={state}
			onClose={() => setState(false)}
			onOpen={() => setState(true)}
		>
			<Modal.Header>
				<TitleText> {props.AreaTitle}</TitleText>
			</Modal.Header>
			<Modal.Content>
				<Modal.Description>
					<DescriptionText>{props.DescriptionText}</DescriptionText>
					<DescriptionText>
						<div className='cancelDescription'>
							<label>Motivo do cancelamento:</label>
							<textarea 
								onChange={(e)=>props.setDescriptCancel(e.target.value)}
								rows={4}
								cols={50}/>
						</div>
					</DescriptionText>
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={() => handleCloseModal()}>Cancelar</Button>
				<Button 
					onClick={() => {props.setCancelPurchase(true),
					handleCloseModal()}}
					disabled={props.descriptCancel ? false : true}
					>Enviar Solicitação</Button>
			</Modal.Actions>
		</Modal>
	);
})

export default CancelPurchaseModal
