import React, { useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import ExchangePolicy from '../../components/ExchangePolicy/ExchangePolicy';

const ExchangePolicyPage = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Layout title="Política de Troca">
			<ExchangePolicy />
		</Layout>
	);
};

export default ExchangePolicyPage;
