import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { appSocket } from '../../appSocket';
import Events from '../../Events';
import {
	useGetProductLazyQuery,
	useUpdateUserNotificationStatusMutation,
} from '../../generated/graphql';
import IUserAccount from '../../models/IUserAccount';
import { AppState } from '../../store';
import { Container, NotificationsContainer } from './styles';
import { ReactComponent as Exit } from '../../assets/icons/exit.svg';
import { useHistory } from 'react-router';
import notification from '../../assets/sounds/notifications.mp3';
import Img from '../Default/CommomImg/Img';
import noPhoto from '../../assets/sem-produto-detalhe.png';

export enum PossibleNotifications {
	PurchaseStatus = 'Houve uma alteração no status de sua compra',
	ChatMessage = 'Você tem uma nova mensagem',
	RatingSuggest = 'Aproveite para avaliar sua última compra',
	ProductAvaliation = 'Seu produto tem uma nova avaliação',
	ProductQuestion = 'Seu produto tem uma nova pergunta',
	AddCart = 'Produto adicionado ao carrinho',
	ContactBuyer = "Seu produto foi comprado na modalidade 'a combinar', verifique sua área de vendas",
}
export interface INotification {
	notification: {
		notification: PossibleNotifications;
		userAccountId: number;
		read: boolean;
	};
	productId: number;
}

const NotificationsWrapper: React.FC = () => {
	const [show, setShow] = useState<boolean>(false);
	const user: IUserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const [notifications, setNotifications] = useState<INotification>();
	const [updateNotificationStatus] =
		useUpdateUserNotificationStatusMutation();
	const [getProduct, { data, loading, error }] = useGetProductLazyQuery({});
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		appSocket.on(Events.NOTIFICATION, (result: INotification) => {
			if (result.notification.userAccountId === user?.id) {
				if (
					result.notification.notification ===
						PossibleNotifications.PurchaseStatus ||
					result.notification.notification ===
						PossibleNotifications.RatingSuggest ||
					result.notification.notification ===
						PossibleNotifications.AddCart ||
					result.notification.notification ===
						PossibleNotifications.ContactBuyer
				) {
					setNotifications(result);

					/* setShow(true); */
				}
				getProduct({ variables: { id: result.productId } });
			}
		});
		return () => {
			appSocket.off(Events.PRODUCT_CHANGE_STATUS);
		};
	}, []);

	useEffect(() => {
		if (notifications) {
			setShow(true);
			//dispatch(updateReadNotifications(true))
			hiddenNotification();
		}
	}, [notifications]);

	const hiddenNotification = () => {
		setTimeout(function () {
			setShow(false);
		}, 10000);
	};
	return (
		<>
			{show && (
				<NotificationsContainer>
					<audio src={notification} autoPlay></audio>
					<Container className="product-cards">
						<div className="notification-header">
							<h1>{notifications?.notification.notification}</h1>
							<Exit onClick={() => setShow(false)} />
						</div>
						<div className="image-container">
							<Img
								src={`${process.env.REACT_APP_STATIC_URL}/product/${data?.getProduct.id}-1.jpeg`}
								alt=" "
								img={noPhoto}
							/>
							<div className="product-infos">
								<h2>{data?.getProduct?.title} </h2>
								<h5>
									{' '}
									{
										data?.getProduct?.price?.toLocaleString(
											'pt-br',
											{
												style: 'currency',
												currency: 'BRL',
											}
										) as any
									}{' '}
								</h5>
							</div>
						</div>
						<div className="redirect-button-container">
							{notifications?.notification.notification ===
								PossibleNotifications.AddCart && (
								<button
									className="redirect-button"
									onClick={() => history.push('/cart')}
								>
									Ir para o carrinho
								</button>
							)}

							{notifications?.notification.notification ===
								PossibleNotifications.PurchaseStatus && (
								<button
									className="redirect-button"
									onClick={() => history.push('/my-buys')}
								>
									Ir para minhas compras
								</button>
							)}
						</div>
					</Container>
				</NotificationsContainer>
			)}
		</>
	);
};

export default NotificationsWrapper;
