import React, { forwardRef, useEffect } from 'react';
import { Container } from './styles';
import AccountsCardItem from './AccountsCardItem/AccountsCardItem';
import { useGetAllUserAccountsQuery } from '../../../generated/graphql';
import UserAccountsList from '../../../models/AllUsers';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store';
import { addUserList } from '../../../actions/AllUsersAction';
import {MdSearch as Search} from 'react-icons/md';

type Props = {
	onChange: any;
};

const AccountsCard: React.RefForwardingComponent<HTMLInputElement, Props> = (
	{ onChange },
	ref
) => {
	const { data, loading } = useGetAllUserAccountsQuery();
	const AllUsers: UserAccountsList[] = useSelector(
		(state: AppState) => state.AllUsers.currentUsers
	);
	const dispatch = useDispatch();
	useEffect(() => {
		if (!loading && data && data.getAllUserAccounts) {
			dispatch(addUserList(data.getAllUserAccounts as any));
		}
	}, [data, loading]);

	return (
		<Container>
			<div className="title-and-search-bar-container">
				<h2 className="ConfigTitleContasCadastradas">Contas cadastradas ({data?.getAllUserAccounts.length})</h2>
				<div className="search-bar">
					<Search className="IconBuscar" />
					<input
						type="text"
						placeholder="Busque por um nome"
						ref={ref}
						onChange={onChange}
					/>
				</div>
			</div>
			<div className="accounts-container">
				{AllUsers &&
					AllUsers.map((account: UserAccountsList) => (
						<AccountsCardItem account={account} key={account.id} />
					))}
			</div>
		</Container>
	);
};

export default forwardRef(AccountsCard);
