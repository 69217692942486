import styled from 'styled-components';

export const ModalContainer = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;
export const ModalTop = styled.header`
	padding: 25px;
	border-bottom: solid 1px black;
	h1 {
		color: black;
		font-family: 'Poppins';
		font-weight: bold;
		font-size: min(15px, 1.2vw);
	}
`;
export const ModalMiddle = styled.main`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 25px;
	overflow-y: auto;
	p {
		display: flex;
		line-height: 35px;
		text-align: center;
		color: black;
		font-family: 'Poppins';
		font-weight: 400;
		outline:none;
		font-size: 20px;
	}

	input{
		width: 30vw;
		height: 10vh;
		font-size: 45px;
		outline-color: #6b97a5;
		border: 1px #6b97a5 solid;
	}
`;
export const ModalBottom = styled.footer`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 25px;

	.link {
		background: #6b97a5;
		border: none;
		width: 30%;
		max-width: 183px;
		min-width: 100px;
		height: 41px;
		border-radius: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: white;
		font-family: 'Poppins';
		font-weight: bold;
		font-size: min(12px, 1.2vw);
		cursor: pointer;
	}

	.close {
		background: #fff;
		border: none;
		border: solid 1px black;
		width: 30%;
		max-width: 183px;
		min-width: 100px;
		height: 41px;
		border-radius: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: black;
		font-family: 'Poppins';
		font-weight: bold;
		font-size: min(12px, 1.2vw);
		margin-right: 20px;
		cursor: pointer;
	}

	button:disabled{
		background-color: grey;
		cursor:not-allowed;
	}
`;
