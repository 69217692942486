import React from 'react'

import { Container, MainCard, PageContainer, PageHeader } from './styles'
import back from '../../assets/icons/back.svg'
import { Link } from 'react-router-dom'

const PrivacyPolicy: React.FC = () => {
    return (
        <PageContainer>
            <Container>
                <PageHeader>
                    <Link to="/">
                        <img src={back} alt="" />
                    </Link>
                    <Link to="/">
                        <h3>Política de Privacidade</h3>
                    </Link>
                </PageHeader>
                <MainCard>
                    <div className="items-container">
                        <h1>Privacidade da Informação / LGPD</h1>
                        <p>
                            Toda informação ou Dado Pessoal do Usuário é
                            armazenado em servidores ou meios magnéticos da mais
                            alta segurança. Salvo com relação às informações que
                            são publicadas nos Sites, o Sambay express adotará
                            todas as medidas possíveis para manter a
                            confidencialidade e a segurança das informações
                            sigilosas, porém não se responsabilizará por
                            eventuais prejuízos que sejam decorrentes da
                            divulgação de tais informações por parte de
                            terceiros que utilizem as redes públicas ou a
                            internet, subvertendo os sistemas de segurança para
                            acessar as informações de Usuários.
                        </p>
                        <p>
                            <strong>
                                O Usuário, ao assinar o respectivo Termo de Uso
                                do presente marketplace, expressamente, autoriza
                                que suas informações e dados pessoais* sejam
                                compartilhados pelo Sambay express com as demais
                                empresas integrantes do grupo econômico,
                                parceiros comerciais, autoridades e pessoas
                                físicas ou jurídicas que, eventualmente, aleguem
                                ter sido lesadas por Usuários.
                            </strong>
                        </p>
                        <p>
                            1. Responsabilidade assumida pelos terceiros que
                            recebem Informação Pessoal do Sambay express Ltda.
                        </p>
                        <p>
                            Em virtude dos acordos celebrados, o Sambay express
                            poderá disponibilizar e/ ou receber as Informações
                            Pessoais de terceiros.
                        </p>
                        <p>
                            O Usuário declara e garante que, ao receber e/ ou
                            disponibilizar Informações Pessoais na relação
                            estabelecida com o Sambay express, deverá atuar de
                            acordo com a legislação em vigor e cumprir as
                            seguintes obrigações:
                        </p>
                        <p>
                            (i) tratar a Informação Pessoal exclusivamente em
                            razão do objeto do acordo celebrado e conforme as
                            indicações do Sambay express, aplicando todas as
                            medidas de segurança que forem necessárias, sejam
                            elas técnicas e/ ou operacionais.
                        </p>
                        <p>
                            (ii) Notificar imediatamente o Sambay express quando
                            da ocorrência de qualquer incidente que tenha
                            potencial de afetar a segurança da Informação
                            Pessoal, incluindo, mas sem se limitar a (a)
                            recebimento de qualquer solicitação de uma
                            autoridade que tiver como objeto a divulgação de
                            Informação Pessoal; e/ ou (b) ocorrência de qualquer
                            incidente de segurança que afete a Informação
                            Pessoal.
                        </p>
                        <p>
                            (iii) Notificar o Sambay express ao receber qualquer
                            solicitação ou queixa por parte dos titulares de
                            dados a respeito da Informação Pessoal, abstendo-se
                            de contestar o titular sem a prévia aprovação, por
                            escrito, do Sambay express.
                        </p>
                        <p>
                            (iv) Excluir e/ ou anonimizar imediatamente a
                            Informação Pessoal em quaisquer dos seguintes casos,
                            salvo se existir algum impedimento legal para tanto:
                            (a) quando tenha terminado a relação jurídica com
                            Sambay express ou (b) por instruções expressas e por
                            escrito do Sambay express.
                        </p>
                        <p>
                            (v) Manter o Sambay express indene de qualquer
                            demanda, denúncia, reclamação e/ou procedimento,
                            interposto contra o terceiro pelo titular dos dados
                            pessoais e/ou qualquer autoridade administrativa ou
                            judicial, com o motivo de não cumprimento por ele de
                            suas obrigações em matéria de proteção dos dados
                            pessoais ou de qualquer outra obrigação aqui
                            contemplada.
                        </p>
                        <p>
                            (vi) Permitir que o Sambay express solicite toda a
                            documentação necessária para o cumprimento do dever
                            de segurança contemplado nesta cláusula.
                        </p>
                        <p>
                            * Por “Dados pessoais” se entendem, dentre outros:
                            nome, endereço, estado civil, e-mail, telefone,
                            profissão e escolaridade.
                        </p>
                        <p>
                            <strong>Sambay express Ltda</strong>
                        </p>
                        <p>Versão 11/2023.</p>
                    </div>
                </MainCard>
            </Container>
        </PageContainer>
    )
}

export default PrivacyPolicy
