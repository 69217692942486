import React, { useEffect, useState } from 'react'

function getRandomInt(min: number, max: number): number {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min)) + min
}

const HeaderBanner = () => {
    const [bannerText, setBannerText] = useState<string>()
    const [visible, setVisible] = useState<boolean>(true)
    const texts = [
        'Compra garantida ou seu dinheiro de volta: compromisso Sambay express.',
        'Frete mais barato do Brasil! E você, comprador, escolhe quem fará a entrega!',
        'Só quem confia no serviço tem o “Reclame Aqui” integrado no site!',
        'Menor comissão do Brasil! Anúncios grátis e loja virtual grátis!',
        'Anúncios grátis ilimitados!!',
    ]

    useEffect(() => {
        const timeout = (): void => setVisible(true)
        setTimeout(timeout, 1000)
    })

    useEffect(() => {
        setBannerText(texts[getRandomInt(0, texts.length - 1)])
    }, [texts])

    if (visible) {
        return (
            <div className="tw-bg-primary-800">
                <div className="tw-mx-auto tw-px-4 tw-py-1 sm:tw-px-8 sm:tw-py-2 md:tw-px-12 lg:tw-px-16">
                    <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-center tw-font-primary tw-text-sm tw-font-regular tw-text-white sm:tw-text-lg">
                        <span>{bannerText}</span>
                    </div>
                </div>
            </div>
        )
    }
    return <></>
}

export default HeaderBanner
