import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;

	width: 100%;

	header {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 40px;
	

		.back-button-container {
			width: 100%;

			height: 105px;
			.back-button {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-left: 7rem;
    			margin-top: 2rem;

				.voltarForgotPassword{
					cursor: pointer;
				}

				span {
					margin-left: 10px;
					color: #aaaaaa;
					font-family: 'Poppins', semi-bold;
					font-weight: 600;
					font-size: 15px;
				}
				img:hover {
					cursor: pointer;
				}
			}
		}
	}
	main {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
`;

export const HeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 600px;
	border-bottom: solid 1px black;
	padding-bottom: 29px;

	@media (max-width: 768px) {
		width: 100%;
	}
	h2 {
		font-size: 40px;
		color: #6b98a6;
		font-weight: 200;
		font-family: 'Poppins', extra-light;
		text-align: center;
	}
	h4 {
		color: #6b98a6;
		font-size: 17px;
		font-family: 'Poppins', semi-bold;
		font-weight: 600;
		text-align: center;
		padding-bottom: 3rem;
	}
`;

export const MainContainer = styled.div`
	width: 400px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const InputsContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	label {
		margin-bottom: 5px;
		font-size: 9px;
		color: black;
		font-family: 'Poppins';
		font-weight: bold;
		margin-left: 5px;
	}
	input {
		width: 315px;
		height: 42px;
		border: solid 1px black;
		border-radius: 8px;
		padding: 5px;
		color: #525252;
		font-family: 'Poppins', regular;
		font-size: 12px;
	}
	input:focus {
		outline: none;
	}
`;

export const SendButtonContainer = styled.div`
	margin-top: 65px;

	button {
		width: 193px;
		height: 48px;
		border: none;
		border-radius: 8px;
		background-color: #6b97a5;
		color: white;
		font-family: 'Poppins';
		font-weight: bold;
		font-size: 14px;
		margin-bottom: 10rem;
		cursor: pointer;
	}
	button:focus {
		outline: none;
	}
`;
export const SucessContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 56px; 
	background-color: #fff;

	.sucess-card {
		box-shadow: 3px 2px 9px #00000014;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 10px;
		background-color: white;
		padding-bottom: 0;
		width: 381px;
		height: 407px;
		border: 1px solid black;

		h2 {
			color: #6b98a6;
			font-size: 16px;
			width: 50%;
			text-align: center;
		}
		span {
			text-align: center;

			font-size: 14px;
			color: black;
			width: 284px;
		}
		img {
			width: 81px;
			height: 86px;
		}

		.card-actions {
			display: flex;
			flex-direction: column;
			width: 100%;
			padding: 18px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-top: 19px;
			padding-bottom: 0;

			.button-confirm {
				margin-bottom: 34px;
				background-color: #69b64a;
				color: white;
				border: none;
				border-radius: 7px;
				font-family: 'Poppins';
				font-weight: bold;
				width: 280px;
				height: 39px;
				font-size: 14px;
				margin-bottom: 21px;
				cursor: pointer;
			}

			.button-redirect {
				background-color: #05a5bf;
				color: white;
				border: none;
				border-radius: 7px;
				font-family: 'Poppins';
				font-weight: bold;
				width: 280px;
				height: 39px;
				font-size: 14px;
			}

			.button-confirm:focus,
			button-redirect:focus {
				outline: none;
			}

			.button-confirm:focus,
			button-redirect:focus {
				cursor: pointer;
			}
		}
	}
`;
