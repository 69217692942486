import React, {
	forwardRef,
	useCallback,
	useState,
	useImperativeHandle,
} from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { DescriptionText, TitleText } from './styles';

export interface ModalHandles {
	handleOpenModal: () => void;
	handleCloseModal: () => void;
}
interface ModalProps {
	AreaTitle: string;
	DescriptionText: string;
	reload: boolean;
}

const AlertModal: React.RefForwardingComponent<ModalHandles, ModalProps> = (
	props,
	ref
) => {
	const [state, setState] = useState(false);
	const show = (dimmer: any) => setState(true);
	const handleOpenModal = useCallback(() => {
		setState(true);
	}, []);
	const handleCloseModal = useCallback(() => {
		setState(false);

		if (props.reload) {
			window.location.reload();
		}
	}, [props]);

	useImperativeHandle(ref, () => {
		return {
			handleOpenModal,
			handleCloseModal,
		};
	});

	return (
		<Modal
			centered={false}
			open={state}
			onClose={() => setState(false)}
			onOpen={() => setState(true)}
		>
			<Modal.Header>
				<TitleText> {props.AreaTitle}</TitleText>
			</Modal.Header>
			<Modal.Content>
				<Modal.Description>
					<DescriptionText>{props.DescriptionText}</DescriptionText>
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={() => handleCloseModal()}>OK</Button>
			</Modal.Actions>
		</Modal>
	);
};

export default forwardRef(AlertModal);
