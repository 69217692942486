import styled from 'styled-components';

export const FilterOption = styled.div`
    display: flex;
    flex-direction: row;
    align-items:center;
    margin-bottom:12px;
.checkbox{
    border:solid 1px black;
    width: 18px;
    height: 18px;
    margin-right:8px;
    border-radius: 4px;
}
.checkbox:hover{
    cursor:pointer;
}
.active{
    background:#3e7dc5;
}
span{
    color:black;
    font-size:14px;
    font-family: 'Poppins', regular;
}
  
`;
