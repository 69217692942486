import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Container, TopButtons, Bottom, ModalContainer } from '../styles';
import {useDispatch} from 'react-redux'
import mobileSearch from '../../../../assets/mobileIcons/mobileSearch.svg';
import { Modal } from 'semantic-ui-react';
import { ReactComponent as Exit } from '../../../../assets/icons/exit.svg';
import { ReactComponent as NorthWest } from '../../../../assets/icons/northWest.svg';
import { appSocket } from '../../../../appSocket';
import Events from '../../../../Events';
import { useHistory } from 'react-router';
import { updateStatusModalBurger } from '../../../../actions/ModalBurgerAction';
export interface MobileSearchModalHandles {
	showModal: () => void;
}

export type SearchProduct = {
	id:number;
	title:string;

}

const MobileSearchModal: React.ForwardRefRenderFunction<MobileSearchModalHandles> = ({}, ref) => {

	const dispatch = useDispatch()

	const [state, setState] = useState(false);
	const [searchPreviewResult, setSearchResultPreview] = useState<SearchProduct[]>([]);
	const [search, setSearch] = useState('');

	useEffect(() => {
		if (search.length > 0) {
			appSocket.emit(Events.PRODUCT_SEARCH_PREVIEW, search);
		}
	}, [search]);

	appSocket.on(Events.RESULT_PRODUCT_SEARCH_PREVIEW, (result: SearchProduct[]) => {
		setSearchResultPreview(result);
	});

	
	
	const history = useHistory();

	const verifySearchInput = () => {
		if (search.length > 0) {
			history.push(`/search/${search}/:top`);
			setState(false)
			dispatch(updateStatusModalBurger(false))	
		}
	};

	const searchInputItem = (item:SearchProduct) => {
		history.push(`/product-view/${item.id}/all-data/`);
			setState(false)
			dispatch(updateStatusModalBurger(false))
	}
	useImperativeHandle(ref, () => {
		return {
			showModal
		}
	})
	const showModal = useCallback(() => {
			setState(true)		
	},[state])
	return (
			<Modal
				style={{ width: '100%', height: '1000%', padding:'0' }}
				open={state}
				onClose={() => setState(false)}
				onOpen={() => setState(true)}
			>
				<ModalContainer>
					<div className="top-container">
						<div className="icon-and-input">
							<div className="img-background">
								<img
									src={mobileSearch}
									alt=""
									onClick={() => verifySearchInput()}
								/>
							</div>
							<input
								type="text"
								placeholder="procure por um produto"
								onChange={(e) => setSearch(e.target.value)}
								value={search}
							/>
						</div>
						<Exit fill="black" onClick={() => setState(false)} />
					</div>
					<div className="bottom-container">
						{searchPreviewResult?.map((item:SearchProduct) => (
							<div className="item">
								<div className="container" onClick={() =>searchInputItem(item)}>
									<div className="fake-icon"></div>
									<span>{item.title}</span>
								</div>
								<NorthWest
									fill="black"
									className="icon"
									onClick={() =>searchInputItem(item)}
								/>
							</div>
						))}
					</div>
				</ModalContainer>
			</Modal>
	);
};

export default forwardRef(MobileSearchModal);
