import React from 'react';

import { Container } from './styles';
import NotificationsCardItem from './NotificationsCardItem/NotificationsCardItem';
import { Reports, useGetAllReportsQuery } from '../../../generated/graphql';

const NotificationsCard: React.FC = () => {
	const { data, loading } = useGetAllReportsQuery();
	return (
		<Container>
			<div className="title-container">
				<h2>Notificações</h2>
			</div>
			<div className="notifications-container">
				{!loading &&
					data?.getAllReports.map((Report: any) => (
						<NotificationsCardItem
							Report={Report}
							key={Report.id}
						/>
					))}
			</div>
		</Container>
	);
};

export default NotificationsCard;
