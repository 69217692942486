import styled from 'styled-components';

export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;

	.modal-title {
		display: flex;
		flex-direction: row;
		justify-content: center;
		border-bottom: solid 1px black;
		width: 100%;
		h2 {
			color: #beb800;

			font-family: 'Poppins';
			font-weight: 600;
			font-size: 25px;
			margin-bottom: 30px;
		}
	}

	.modal-input {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 40px;

		span {
			color: #969696;
			font-size: 14px;
			font-family: 'Poppins';
			font-weight: 600;
			margin-bottom: 20px;
		}
		input {
			border: solid 1px black;
			height: 70px;
			width: 100%;
			padding: 18px;
			text-align: center;
			font-size: 39px;
			color: #6b97a5;
			font-family: 'Poppins', extra-light;
			font-weight: 200;
			border-radius: 8px;
		}
		input:focus {
			outline: none;
		}
		p {
			font-size: 12px;
			color: #979797;
			font-family: 'Poppins', regular;
		}
	}
	.modal-actions {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-top: 36px;
		width: 50%;

		.negative-button {
			padding: 10px;
			button {
				background-color: #ca667e;
				border: none;
				color: white;
				border: none;
				color: white;
				width: 135px;
				height: 50px;
				border-radius: 6px;
				font-size: 14px;
				font-family: 'Poppins';
				font-weight: bold;
				cursor: pointer;
			}
		}
		.positive-button {
			padding: 10px;
			button {
				background-color: #6b97a5;
				border: none;
				color: white;
				border: none;
				color: white;
				width: 135px;
				height: 50px;
				border-radius: 6px;
				font-size: 14px;
				font-family: 'Poppins';
				font-weight: bold;
				cursor: pointer;
			}
		}
	}
`;
