import {
	InsertCookies,
	ShowProducts,
	ShowProductSearchFilters,
} from '../models/Cookies';

export const insertCookies = (accept: boolean): InsertCookies => ({
	type: 'CONFIRM_COOKIES',
	accept,
});
export const showProducts = (showProducts: boolean): ShowProducts => ({
	type: 'SHOW_PRODUCTS',
	showProducts,
});
export const showProductSearchFilters = (
	showProductSearchFilters: boolean
): ShowProductSearchFilters => ({
	type: 'SHOW_PRODUCT_SEARCH_FILTERS',
	showProductSearchFilters,
});
