import React, { useState, useEffect } from 'react';
import { MainContainer, Container } from './Style';
import {
	useGetProductQuery,
	useGetAllCardsQuery,
} from '../../generated/graphql';
import IUserAccount from '../../models/IUserAccount';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import Radio from '@material-ui/core/Radio';

import AddCardModal from './AddCardModal/AddCardModal';
import IUserCards from '../../models/IUserCards';
import { InsertCards } from '../../actions/UserCardsActions';
import { getOneShippingCost } from '../../utils/getShippingCost';
import { appSocket } from '../../appSocket';
import Events from '../../Events';
import { useHistory, useParams } from 'react-router';
import {
	AddCardId,
	AddInstallmentsNumber,
	AddPaymentMethod,
	RemoveCard,
} from '../../actions/BuyParamsActions';
import BuyParams from '../../models/BuyParams';
import { FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';
import { getTotalValue } from '../../utils/getBuyTotalValue';
import { ReactComponent as Back } from '../../assets/icons/back.svg';

type Props = {
	shippingValue?: string;
};
interface PageParams {
	productId: string;
}
const BuyPaymentProcess: React.FC<Props> = ({ shippingValue }) => {
	const user: IUserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const { data: cardsData, loading: cardLoading } = useGetAllCardsQuery({
		variables: { userId: user.id },
		fetchPolicy: 'cache-and-network',
	});
	const userCards: IUserCards[] = useSelector(
		(state: AppState) => state.UserCards.currentCards
	);
	const BuyParams: BuyParams = useSelector(
		(state: AppState) => state.BuyParams.currentParams
	);
	const pageParams = useParams<PageParams>();
	const [checked, setChecked] = useState<number>(0);

	const [read, setRead] = useState<string>('true');
	const [disabled, setDisabled] = useState<boolean>(true);
	const [paymentMethod, setPaymentMethod] = useState<string | undefined>();
	const [form, setForm] = useState<string>();
	const [validCheckBox, setValidCheckBox] = useState<boolean>(false);
	const installments = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
	const dispatch = useDispatch();

	const setCardData = (card: IUserCards) => {
		dispatch(AddCardId(card));
		dispatch(AddPaymentMethod('credit_card'));
	};

	useEffect(() => {
		if (read === 'true' || paymentMethod === 'boleto') {
			setDisabled(true);
		} else {
			setDisabled(false);
		}
	}, [read, paymentMethod]);

	useEffect(() => {
		if (!BuyParams.installmentsNumber) {
			dispatch(AddInstallmentsNumber(1));
		}
	}, [BuyParams]);

	useEffect(() => {
		dispatch(InsertCards(cardsData?.getAllCards));
	}, [cardsData]);

	useEffect(() => {
		if (paymentMethod == 'boleto') {
			setValidCheckBox(true);
		} else {
			setValidCheckBox(false);
		}
	}, [paymentMethod]);
	const history = useHistory();

	const valid = () => {
		if (BuyParams.paymentMethod) {
			history.push('/buy-confirm-process');
		} else {
			alert('Preencha todos os campos');
		}
	};

	return (
		<Container>
			<MainContainer>
				<div className="class-column">
					<div className="card-container">
						<div className="card-header">
							<h2>Forma de pagamento</h2>
						</div>

						<FormControl component="fieldset">
							<RadioGroup aria-label="gender" name="gender2">
								<div
									className="card-infos"
									style={{
										borderBottom: 'solid 1px black',
									}}
								>
									<div
										className="card-options"
										style={{
											flexDirection: 'column',
											alignItems: 'flex-start',
										}}
									>
										<>
											{userCards &&
												userCards.map((cards: any) => (
													<FormControlLabel
														className="label"
														control={
															<Radio
																color="default"
																className="input-color"
																name="cardId"
																checked={
																	BuyParams
																		?.card
																		?.id ===
																	cards.id
																		? true
																		: false
																}
																onChange={() =>
																	setCardData(
																		cards
																	)
																}
																value={cards.id}
															/>
														}
														label={
															<label>
																Cartão de
																credito final -{' '}
																{
																	cards.last_digits
																}
															</label>
														}
													/>
												))}
										</>
									</div>
								</div>

								<AddCardModal></AddCardModal>
								<div
									className="card-infos"
									style={{
										borderTop: 'solid 1px black',
									}}
								>
									<div className="card-options">
										<FormControlLabel
											className="label"
											control={
												<Radio
													color="default"
													className="input-color"
													name="cardId"
													onChange={() => {
														dispatch(
															AddInstallmentsNumber(
																1
															)
														);

														dispatch(
															AddPaymentMethod(
																'boleto'
															)
														);
														dispatch(RemoveCard());
													}}
													checked={
														BuyParams.paymentMethod ===
														'boleto'
															? true
															: false
													}
												/>
											}
											label={
												<label>Boleto bancário</label>
											}
										/>
									</div>
								</div>
							</RadioGroup>
						</FormControl>
					</div>

					<div className="card-container">
						<div className="card-header">
							<h2>Condições do pagamento</h2>
						</div>
						<FormControl component="fieldset">
							<RadioGroup aria-label="gender1" name="gender3">
								<div className="card-infos">
									<div
										className="card-options"
										style={{
											borderBottom: 'solid 1px black',
										}}
									>
										<FormControlLabel
											className="label"
											control={
												<Radio
													color="default"
													className="input-color"
													name="choice"
													onClick={() =>
														dispatch(
															AddInstallmentsNumber(
																1
															)
														)
													}
													checked={
														BuyParams.installmentsNumber ===
														1
															? true
															: false
													}
												/>
											}
											label={<label>A vista</label>}
										/>
									</div>
									<div
										className="card-options"
										style={{ border: 'none' }}
									>
										<FormControlLabel
											className="label"
											control={
												<Radio
													color="default"
													className="input-color"
													name="choice"
													onChange={() =>
														dispatch(
															AddInstallmentsNumber(
																2
															)
														)
													}
													checked={
														BuyParams.installmentsNumber >
														1
															? true
															: false
													}
													disabled={
														BuyParams.paymentMethod ===
														'boleto'
															? true
															: false
													}
												/>
											}
											label={
												<label>Parcelado em: </label>
											}
										/>

										<select
											onChange={(e) =>
												dispatch(
													AddInstallmentsNumber(
														parseInt(e.target.value)
													)
												)
											}
											value={BuyParams.installmentsNumber}
											disabled={
												BuyParams.paymentMethod ===
												'boleto'
													? true
													: false
											}
										>
											{installments.map((installment) => (
												<option
													value={installment}
													key={installment}
												>
													{' '}
													{installment} vezes{' '}
												</option>
											))}
										</select>
									</div>
								</div>
							</RadioGroup>
						</FormControl>
					</div>

					<div className="next-button-container">
						<button
							className="back-button"
							onClick={() =>
								history.push(
									`/buy-address-process/${BuyParams.product.id}`
								)
							}
						>
							<Back className="back-icon" />
						</button>

						<button onClick={() => valid()}>Continuar</button>
					</div>
				</div>
				<div className="infos-card">
					<div
						className="title-infos"
						style={{ borderBottom: 'solid 1px black' }}
					>
						<h2>
							{BuyParams.product.title.substring(0, 14)}
							...
						</h2>
						<label>Produto{BuyParams.product.useState}</label>
					</div>

					<div
						className="infos"
						style={{ borderBottom: 'solid 1px black' }}
					>
						<h4>Detalhes do envio</h4>
						<label>Envio básico via correios</label>
						<label>
							Custo:{' '}
							{shippingValue &&
								parseInt(shippingValue).toLocaleString(
									'pt-br',
									{
										style: 'currency',
										currency: 'BRL',
									}
								)}
						</label>
					</div>
					<div className="price-info">
						<h2>
							{' '}
							Total a pagar:{' '}
							{getTotalValue(
								BuyParams.quantity,
								BuyParams.product.price as any
							).toLocaleString('pt-br', {
								style: 'currency',
								currency: 'BRL',
							})}
						</h2>
					</div>
				</div>
			</MainContainer>
		</Container>
	);
};
export default BuyPaymentProcess;
