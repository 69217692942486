import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import IUserAccount from '../../models/IUserAccount';
import { AppState } from '../../store';
import {
	useCreateProductRatingMutation,
	useGetAllProductRatingsQuery,
	useDeleteRatingMutation,
	useUpdateProductRatingMutation,
	GetAllProductRatingsQuery,
} from '../../generated/graphql';
import { QuestionsCard } from './Style';
import moment from 'moment';

import { ReactComponent as Star } from '../../assets/icons/ratingStar.svg';
import Img from '../Default/CommomImg/Img';

type props = {
	productId: string;
	userSellerId: number | undefined | null;
};

const ProductRating: React.FC<props> = ({ productId, userSellerId }) => {
	const [rating, setRating] = useState<number>(0);
	const user: IUserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const [createProductRating] = useCreateProductRatingMutation();
	const [updateRating] = useUpdateProductRatingMutation();
	const { data, loading } = useGetAllProductRatingsQuery({
		variables: { productId: parseInt(productId) },
		fetchPolicy: 'no-cache',
	});

	const [ratingDescription, setRatingDescription] = useState<string>();
	const [deleteRating] = useDeleteRatingMutation();
	const [disabledTextArea, setDisabledTextArea] = useState<boolean>(true);
	const ratingStars = [1, 2, 3, 4, 5];
	const [ratingEditDescription, setRatingEditDescription] = useState<
		string | undefined
	>();

	const ratingFunction = async () => {
		await createProductRating({
			variables: {
				userId: user.id,
				sellerId: userSellerId as number,
				productId: parseInt(productId),
				rating: rating!,
				ratingDescription: ratingDescription!,
			},
		});
		window.location.reload();
	};
	const handleUpdate = async (ratingId: number) => {
		if (!rating || !ratingEditDescription) return;
		if (rating > 0) {
			try {
				await updateRating({
					variables: {
						rating: rating!,
						ratingDescription: ratingEditDescription,
						ratingId: ratingId,
					},
				});
			} catch (err: any) {
				console.log(err);
			}
		}
	};

	const deleteProductRatingFunction = async (id: number) => {
		await deleteRating({ variables: { id: id } });
		window.location.reload();
	};
	const valid = () => {
		const result = data?.getAllProductRatings.find(
			(value) => value.userAccountId == user.id
		);
		if (result || user.id !== userSellerId) {
			return (
				<>
					<div className="make-question">
						{' '}
						<h2>Avalie o vendedor</h2>
						<textarea
							onChange={(e) =>
								setRatingDescription(e.target.value)
							}
						/>
						<div
							className="rating-stars"
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
							}}
							onMouseLeave={() => {
								if (rating == 0) {
									for (let index = 1; index <= 5; index++) {
										const starIcon =
											document.getElementById(`${index}`);

										if (!starIcon) return;

										let classEx = 'star';
										starIcon.setAttribute('class', classEx);
									}
								}
							}}
						>
							<button
								style={{ marginRight: '10px' }}
								onClick={() => ratingFunction()}
							>
								Publicar avaliação
							</button>
							{ratingStars.map((star) => (
								<>
									<Star
										className={`${
											rating < star
												? 'star'
												: 'star-active'
										}`}
										id={`${star}`}
										onMouseOver={() => {
											if (rating == 0) {
												for (
													let index = 1;
													index <= 5;
													index++
												) {
													const starIcon =
														document.getElementById(
															`${index}`
														);

													if (!starIcon) return;

													if (index <= star) {
														let classEx =
															'star-active';
														starIcon.setAttribute(
															'class',
															classEx
														);
													} else {
														let classEx = 'star';
														starIcon.setAttribute(
															'class',
															classEx
														);
													}
												}
											}
										}}
										onClick={() => setRating(star)}
										style={{
											width: '22px',
											height: '16px',
										}}
										width="22px"
										height="16px"
									/>
								</>
							))}
						</div>
					</div>
				</>
			);
		}
	};

	function renderStars(rating: number) {
		var rows = [];
		for (var i = 1; i <= 5; i++) {
			rows.push(
				<Star
					className={`${i > rating ? 'star' : 'star-active'}`}
					style={{
						width: '22px',
						height: '16px',
					}}
					width="22px"
					height="16px"
				/>
			);
		}
		return <div>{rows}</div>;
	}

	return (
		<>
			<QuestionsCard>
				<div className="question-container">
					<div>
						<h1>Avaliações</h1>
					</div>
					{valid()}

					{data?.getAllProductRatings?.map(
						(Rating: any, index: number) => (
							<>
								<div className="questions-card" key={index}>
									<>
										<div className="question">
											<div className="user-img">
												<Img
													src={`${process.env.REACT_APP_STATIC_URL}/user/${Rating?.userAccountId}.jpeg`}
												/>
											</div>

											<div className="question-content">
												<div className="question-infos">
													<h2>
														{
															Rating.UserAccount
																?.name
														}
													</h2>
													<div className="avaliations">
														{renderStars(
															Rating.rating
														)}
													</div>
													<label>
														{moment(
															Rating.createdAt
														).format(
															'DD/MM/YYYY hh:mm'
														)}
													</label>
													{user.id ===
														Rating.UserAccount.id &&
														disabledTextArea && (
															<span
																className="edit-button"
																onClick={() => {
																	setDisabledTextArea(
																		false
																	);
																}}
																style={{
																	fontWeight:
																		'initial',
																}}
															>
																Editar
															</span>
														)}

													{user.id ===
														Rating.UserAccount.id &&
														!disabledTextArea && (
															<span
																className="salvar-button"
																onClick={() =>
																	handleUpdate(
																		Rating.id
																	)
																}
															>
																Salvar
															</span>
														)}
													{user.id ===
														Rating.UserAccount.id &&
														disabledTextArea && (
															<span
																className="delete-button"
																onClick={() =>
																	deleteProductRatingFunction(
																		Rating.id
																	)
																}
																style={{
																	fontWeight:
																		'initial',
																}}
															>
																Excluir
															</span>
														)}
													{!disabledTextArea && (
														<span
															className="cancel-button"
															onClick={() =>
																setDisabledTextArea(
																	true
																)
															}
														>
															Cancelar
														</span>
													)}
												</div>

												<div className="question-description">
													<textarea
														defaultValue={
															Rating.ratingDescription
														}
														style={{
															border: `${
																disabledTextArea
																	? 'none'
																	: '1px solid black'
															}`,
														}}
														disabled={
															disabledTextArea
														}
														onChange={(e) =>
															setRatingEditDescription(
																e.target.value
															)
														}
													/>
												</div>
											</div>
										</div>
									</>
								</div>
							</>
						)
					)}
				</div>
			</QuestionsCard>
		</>
	);
};
export default ProductRating;
