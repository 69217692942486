import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { appSocket } from '../../../appSocket'
import Events from '../../../Events'
import IUserAccount from '../../../models/IUserAccount'
import { AppState } from '../../../store'
import { Container, LocationContainer, Subtitle } from './styles'
import MobileMenu from './MobileMenu/MobileMenu'
import searchIcon from '../../../assets/icons/search-icon.svg'
import NotifyCard from '../../Notify/NotifyCard'
import logoLinkedin from '../../../assets/icons/logo_linkedin.svg'
import logoInstagram from '../../../assets/icons/logo_instagram.svg'
import logoFacebook from '../../../assets/icons/logo_facebook.svg'
import logoHelp from '../../../assets/icons/logo_help.svg'
import logoSambay from '../../../assets/icons/logo.svg'
import logoCarrinhodecompras from '../../../assets/icons/logo_carrinhodecompras.svg'
import sammy from '../../../assets/icons/sammy.png'
import logoBandeira from '../../../assets/icons/logo_bandeira.svg'
import iconLocation from '../../../assets/icons/icon_location.svg'
import iconUserprofile from '../../../assets/icons/icon_userprofile.svg'
import iconStore from '../../../assets/icons/icon_store.svg'
import iconMenuMobile from '../../../assets/icons/icon_menu_mobile.svg'
import iconSale from '../../../assets/icons/icon_sale.svg'
import news from '../../../assets/icons/news.svg'
import { showProducts } from '../../../actions/CookiesAction'
import CategoriesMenu from '../../CategoriesMenu/CategoriesMenu'
import ProfileMenu from '../../ProfileMenu/ProfileMenu'
import MobileProfileMenu from '../../MobileProfileMenu/MobileProfileMenu'
import Badge from '@material-ui/core/Badge'
import IProductsCart from '../../../models/ICart'
import { IModalBurgerState } from '../../../models/IModalBurger'
import HeaderBanner from '../../HeaderBanner/HeaderBanner'
import { MdMenu } from 'react-icons/md'
import { FaUserCircle } from 'react-icons/fa'
import useWindow from '../../../hooks/useWindow'

const UserHeader = () => {
    const window = useWindow()
    const [search, setSearch] = useState('')
    const [searchPreviewResult, setSearchResultPreview] = useState<string[]>([])
    const [showProfileMenu, setShowProfileMenu] = useState<boolean>(false)
    const [notification, setNotifications] = useState<Array<any>>([])
    const [notificationNotRead, setNotficationNotRead] = useState<any>([])

    const [mobileMenuIsVIsible, setVisible] = useState<boolean>(false)

    const modalBurgerState: IModalBurgerState = useSelector(
        (state: AppState) => state.modalBurgerReducer
    )

    useEffect(() => {
        if (!modalBurgerState.openModal) {
            setVisible(false)
        }
    }, [modalBurgerState])

    const history = useHistory()

    const verifySearchInput = () => {
        if (search.length > 0) {
            history.push(`/search/${search}/:null`)
        }
    }
    useEffect(() => {
        if (search.length > 0) {
            appSocket.emit(Events.PRODUCT_SEARCH_PREVIEW, search)
        }
    }, [search])

    useEffect(() => {
        if (notification.length > 0) {
            const notRead = notification.filter(
                (notificacao) => !notificacao.read
            )
            setNotficationNotRead(notRead)
        }
    }, [notification])

    const dispatch = useDispatch()
    appSocket.on(Events.RESULT_PRODUCT_SEARCH_PREVIEW, (result: string[]) => {
        setSearchResultPreview(result)
    })
    const user: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )

    const productsCart: IProductsCart[] = useSelector(
        (state: AppState) => state.ProductsCart.currentProductsCart
    )

    function handSearch(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            verifySearchInput()
        }
    }

    useEffect(() => {
        if (!mobileMenuIsVIsible) {
            dispatch(showProducts(true))
        } else {
            dispatch(showProducts(false))
        }
    }, [mobileMenuIsVIsible])

    useEffect(() => {
        if (user) {
            appSocket
                .emit(Events.LOAD_NOTIFICATIONS, user.id)
                .on('LOAD_NOTIFICATIONS', (result: any) => {
                    setNotifications(
                        result.map((value: any) => ({
                            notification: value.notification,
                            read: value.read,
                            userAccountId: value.userAccountId,
                        }))
                    )
                })
        }
    }, [user])

    return (
        <>
            <HeaderBanner />
            <Container>
                {window.innerWidth >= 1023 && (
                    <>
                        <div className="section-header">
                            <div className="section-header-initial-menu">
                                <a
                                    href={`${process.env.REACT_APP_STATIC_URL}/manual/manual-anuncio.pdf`}
                                    className="style-title-sale-at-sambay"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Venda na Sambay
                                </a>
                                <span className="style-span-follow-us">
                                    Siga-nos:
                                </span>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.linkedin.com/company/sambay-express/"
                                >
                                    <img
                                        src={logoLinkedin}
                                        className="style-icon-linkedin-header-menu"
                                        alt="icon-linkedin"
                                    />
                                </a>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.instagram.com/sambayexpress/"
                                >
                                    <img
                                        src={logoInstagram}
                                        className="style-icon-instagram-header-menu"
                                        alt="icon-instagram"
                                    />
                                </a>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.facebook.com/sambayexpress"
                                >
                                    <img
                                        src={logoFacebook}
                                        className="style-icon-facebook-header-menu"
                                        alt="icon-facebook"
                                    />
                                </a>
                            </div>
                            <div className="section-header-second-menu">
                                <img
                                    src={logoHelp}
                                    alt="logo_ajuda"
                                    className="style-icon-help"
                                    onClick={() => history.push(`/faq`)}
                                />
                                <span
                                    className="style-span-help"
                                    onClick={() => history.push(`/faq`)}
                                >
                                    Ajuda
                                </span>
                                {/* Condição mostrando opções para usuário logado. */}
                                {user ? (
                                    <>
                                        <NotifyCard />
                                        <button
                                            className="button-anuncie"
                                            onClick={() =>
                                                history.push(
                                                    `/register-product/:categoryId/product`
                                                )
                                            }
                                        >
                                            Anuncie
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <span
                                            className="style-span-register"
                                            onClick={() =>
                                                history.push(`/register`)
                                            }
                                        >
                                            Cadastrar
                                        </span>
                                        <span
                                            className="style-span-login"
                                            onClick={() =>
                                                history.push(`/login`)
                                            }
                                        >
                                            Entrar
                                        </span>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="section-subtitle-second">
                            <div
                                className={`
                                    tw-flex tw-flex-col tw-items-center tw-justify-between
                                    tw-font-primary tw-text-lg tw-font-semibold tw-text-white
                                `}
                            >
                                <img
                                    src={logoSambay}
                                    alt="logo_sambay"
                                    className="logo_sambay"
                                    onClick={() => history.push(`/`)}
                                />
                                <span>O Marketplace do Brasil!</span>
                            </div>
                            <div>
                                <div className="search-bar">
                                    <div className="items-container">
                                        <input
                                            type="text"
                                            placeholder="Pesquisar produtos..."
                                            value={search}
                                            onChange={(e) => {
                                                setSearch(e.target.value)
                                            }}
                                            onKeyPress={(e) => {
                                                handSearch(e)
                                            }}
                                        />
                                        <div className="search-bar-size">
                                            <img
                                                src={searchIcon}
                                                onClick={() =>
                                                    verifySearchInput()
                                                }
                                                alt=" "
                                                className="search-icon"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="tw-space-x-2 tw-p-2">
                                    <Link
                                        className="tw-text-white hover:tw-text-smoke-100"
                                        to="/search/category/Gibis,%20HQs%20e%20afins"
                                    >
                                        Gibis
                                    </Link>
                                    <Link
                                        className="tw-text-white hover:tw-text-smoke-100"
                                        to="/search/miniatura/:null"
                                    >
                                        Miniaturas
                                    </Link>
                                    <Link
                                        className="tw-text-white hover:tw-text-smoke-100"
                                        to="/search/category/Antiguidades%20e%20Cole%C3%A7%C3%B5es"
                                    >
                                        Coleções
                                    </Link>
                                    <Link
                                        className="tw-text-white hover:tw-text-smoke-100"
                                        to="/search/c%C3%A9dula/:null"
                                    >
                                        Cédulas
                                    </Link>
                                    <Link
                                        className="tw-text-white hover:tw-text-smoke-100"
                                        to="/search/moeda/:null"
                                    >
                                        Moedas
                                    </Link>
                                    <Link
                                        className="tw-text-white hover:tw-text-smoke-100"
                                        to="/search/chaveiro/:null"
                                    >
                                        Chaveiros
                                    </Link>
                                    <Link
                                        className="tw-text-white hover:tw-text-smoke-100"
                                        to="/search/ferrovia/:null"
                                    >
                                        Ferrovias
                                    </Link>
                                    <Link
                                        className="tw-text-white hover:tw-text-smoke-100"
                                        to="/search/category/Obras%20de%20Arte"
                                    >
                                        Artes
                                    </Link>
                                    <Link
                                        className="tw-text-white hover:tw-text-smoke-100"
                                        to="/search/category/Canetas%20especiais"
                                    >
                                        Canetas
                                    </Link>
                                    <Link
                                        className="tw-text-white hover:tw-text-smoke-100"
                                        to="/search/militaria/:null"
                                    >
                                        Militaria
                                    </Link>
                                </div>
                            </div>
                            <div className="section-shopping-cart">
                                <Badge
                                    badgeContent={productsCart.length}
                                    color="secondary"
                                >
                                    <img
                                        src={logoCarrinhodecompras}
                                        alt="carrinho-de-compras"
                                        className="style-icon-carrinho-de-compras"
                                        onClick={() => history.push(`/cart`)}
                                    />
                                </Badge>
                            </div>
                            <div className="section-icons">
                                <img
                                    src={sammy}
                                    alt="icon-novembro-azul"
                                    className="style-icon-novembro-azul"
                                />
                                <img
                                    src={logoBandeira}
                                    alt="bandeira-brasil"
                                    className="style-icon-bandeira"
                                />
                            </div>
                        </div>
                    </>
                )}
                {window.innerWidth >= 768 && window.innerWidth <= 1022 && (
                    <>
                        <div className="section-header-tablet">
                            <div
                                className="section-header-icon-menu"
                                onClick={() => setVisible(!mobileMenuIsVIsible)}
                            >
                                <img
                                    src={iconMenuMobile}
                                    alt="icon-menu-header"
                                    className="icon-menu-header"
                                />
                            </div>
                            <div className="section-header-logo-sambay">
                                <img
                                    src={logoSambay}
                                    alt="logo-sambay"
                                    className="logo-sambay"
                                    onClick={() => history.push('/')}
                                />
                                <span>O Marketplace do Brasil!</span>
                            </div>
                            <div className="section-header-button-login">
                                {/*  Condição mostrando opções para usuário logado. */}
                                {!user ? (
                                    <a
                                        href={`${process.env.REACT_APP_STATIC_URL}/manual/manual-anuncio.pdf`}
                                        className="style-header-button-sale-at-sambay"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Venda na Sambay
                                    </a>
                                ) : (
                                    <>
                                        <NotifyCard />
                                        <button
                                            className="style-header-button-login"
                                            onClick={() =>
                                                history.push(
                                                    '/register-product/:categoryId/product'
                                                )
                                            }
                                        >
                                            Anunciar
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                        <MobileMenu
                            positionLeft={
                                !mobileMenuIsVIsible ? '-330px' : '0px'
                            }
                            onClick={() => setVisible(false)}
                            mobile={mobileMenuIsVIsible}
                        />
                    </>
                )}

                {window.innerWidth <= 475 && (
                    <>
                        <div className="section-header-mobile">
                            <div
                                className="section-header-icon-menu"
                                onClick={() => setVisible(!mobileMenuIsVIsible)}
                            >
                                <MdMenu color="white" size="42.5" />
                            </div>
                            <div
                                className="section-header-sambay-logo"
                                onClick={() => history.push('/')}
                            >
                                <img
                                    src={logoSambay}
                                    alt="sambay-logo"
                                    className="sambay-logo-header-mobile"
                                />
                            </div>
                            {!user ? (
                                <div className="section-register-login-or-help">
                                    <span
                                        className="style-span-register-mobile"
                                        onClick={() => history.push(`/login`)}
                                    >
                                        Faça o login
                                    </span>
                                    <FaUserCircle
                                        size="35"
                                        color="white"
                                        onClick={() => history.push(`/login`)}
                                        style={{ cursor: 'pointer' }}
                                    ></FaUserCircle>
                                    <div className="section-shopping-cart">
                                        <Badge
                                            badgeContent={productsCart.length}
                                            color="secondary"
                                        >
                                            <img
                                                src={logoCarrinhodecompras}
                                                alt="carrinho-de-compras"
                                                className="style-icon-carrinho-de-compras"
                                                onClick={() =>
                                                    history.push(`/cart`)
                                                }
                                            />
                                        </Badge>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div
                                        className="section-profile"
                                        style={{
                                            alignItems: 'center',
                                            display: 'flex',
                                        }}
                                    >
                                        <span
                                            className="style-span-register-mobile"
                                            style={{
                                                fontSize: '14px',
                                                color: 'white',
                                                fontFamily: 'Poppins',
                                            }}
                                        >
                                            {user.name.split(' ')[0]}
                                        </span>
                                        <FaUserCircle
                                            size="35"
                                            color="white"
                                            style={{
                                                marginRight: '1.3rem',
                                                marginLeft: '0.8rem',
                                            }}
                                        ></FaUserCircle>
                                    </div>
                                    <div className="section-shopping-cart">
                                        <Badge
                                            badgeContent={productsCart.length}
                                            color="secondary"
                                        >
                                            <img
                                                src={logoCarrinhodecompras}
                                                alt="carrinho-de-compras"
                                                className="style-icon-carrinho-de-compras"
                                                onClick={() =>
                                                    history.push(`/cart`)
                                                }
                                                style={{ width: '35px' }}
                                            />
                                        </Badge>
                                    </div>
                                </>
                            )}
                        </div>

                        <div className="search-bar-mobile">
                            <div className="items-container-mobile">
                                <input
                                    type="text"
                                    placeholder="Pesquisar produtos..."
                                    value={search}
                                    onChange={(e) => {
                                        setSearch(e.target.value)
                                    }}
                                    onKeyPress={(e) => {
                                        handSearch(e)
                                    }}
                                />
                                <img
                                    src={searchIcon}
                                    onClick={() => verifySearchInput()}
                                    alt=" "
                                    className="search-icon"
                                />
                            </div>
                        </div>
                        {showProfileMenu && <MobileProfileMenu />}
                        <MobileMenu
                            positionLeft={
                                !mobileMenuIsVIsible ? '-330px' : '0px'
                            }
                            onClick={() => setVisible(false)}
                            mobile={mobileMenuIsVIsible}
                        />
                    </>
                )}
            </Container>

            <Subtitle>
                {/* CÓDIGO DE SUBTÍTULO PARA DESKTOP */}
                {window.innerWidth > 1023 && (
                    <>
                        <div className="section-subtitle">
                            <div className="section-subtitle-user-location">
                                <img
                                    src={iconLocation}
                                    alt="icone-localizaçao"
                                    className="subtitle-icon-location"
                                    onClick={() => history.push(`/login`)}
                                />
                                {!user ? (
                                    <>
                                        <div
                                            className="subtitle-column-location-span"
                                            onClick={() =>
                                                history.push(`/login`)
                                            }
                                        >
                                            <span className="subtitle-location-span-2">
                                                Faça o login
                                            </span>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="subtitle-column-location-span">
                                            {user &&
                                            user.ProfileAddress &&
                                            user.ProfileAddress.length > 0 ? (
                                                <>
                                                    <span className="subtitle-location-span-1">
                                                        Enviar para
                                                    </span>

                                                    <span className="subtitle-location-span-2">
                                                        {
                                                            user
                                                                .ProfileAddress[0]
                                                                .CEP
                                                        }
                                                    </span>
                                                </>
                                            ) : (
                                                <span className="subtitle-location-span-1">
                                                    Sem endereço de envio
                                                    cadastrado!
                                                </span>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="section-subtitle-categories-bar">
                                <CategoriesMenu />
                                <span
                                    className="span-all-categories"
                                    onClick={() =>
                                        history.push(`/search/promoção/:null`)
                                    }
                                >
                                    Ofertas
                                </span>
                                <span
                                    className="span-all-categories"
                                    onClick={() =>
                                        history.push(`/search/%20/:null`)
                                    }
                                >
                                    Novidades
                                </span>
                                <span
                                    className="span-all-categories"
                                    onClick={() =>
                                        history.push(`/search/category/Imoveis`)
                                    }
                                >
                                    Imóveis
                                </span>
                                <span
                                    className="span-all-categories"
                                    onClick={() =>
                                        history.push(
                                            `/search/category/Veículos`
                                        )
                                    }
                                >
                                    Veículos
                                </span>
                                <span
                                    className="span-all-categories"
                                    onClick={() =>
                                        history.push(`/social-projects`)
                                    }
                                >
                                    Projetos Sociais
                                </span>
                            </div>
                            {user && (
                                <div className="section-user-profile">
                                    <div
                                        className="section-user-store-title"
                                        onClick={() =>
                                            history.push(
                                                `/shopping/${user.id}/dashboard`
                                            )
                                        }
                                    >
                                        <img
                                            src={iconStore}
                                            alt="icone-usuario"
                                            className="icon-store"
                                        />
                                        <span className="user-store-span-name">
                                            Minha Loja
                                        </span>
                                    </div>
                                    <ProfileMenu />
                                </div>
                            )}
                        </div>
                    </>
                )}

                {/* CÓDIGO DE SUBTÍTULO PARA TABLET */}
                {window.innerWidth >= 768 && window.innerWidth <= 1022 && (
                    <>
                        <div className="section-tablet-subtitle">
                            {/* Condição mostrando opções para usuário logado. */}
                            {!user ? (
                                <>
                                    <CategoriesMenu />
                                    <div
                                        className="section-tablet-subtitle-my-store"
                                        onClick={() =>
                                            history.push(
                                                `/search/promoção/:null`
                                            )
                                        }
                                    >
                                        <img
                                            src={iconSale}
                                            alt="icon-minhas-loja"
                                            className="icon-subtitle-minha-loja"
                                        />
                                        <span className="style-span-subtitle-my-buys">
                                            Ofertas
                                        </span>
                                    </div>
                                    <div
                                        className="section-tablet-subtitle-my-store"
                                        onClick={() =>
                                            history.push(`/search/%20/:null`)
                                        }
                                    >
                                        <img
                                            src={news}
                                            alt="icon-minhas-loja"
                                            className="icon-subtitle-minha-loja"
                                        />
                                        <span className="style-span-subtitle-my-buys">
                                            Novidades
                                        </span>
                                    </div>
                                    <div
                                        className="section-tablet-subtitle-my-profile"
                                        onClick={() => history.push(`/login`)}
                                    >
                                        <img
                                            src={iconUserprofile}
                                            alt="icon-my-profile"
                                            className="icon-subtitle-my-profile"
                                        />
                                        <span className="style-span-subtitle-my-profile">
                                            Faça o login
                                        </span>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <CategoriesMenu />
                                    <div
                                        className="section-tablet-subtitle-my-store"
                                        onClick={() =>
                                            history.push(
                                                `/search/promoção/:null`
                                            )
                                        }
                                    >
                                        <img
                                            src={iconSale}
                                            alt="icon-minhas-loja"
                                            className="icon-subtitle-minha-loja"
                                        />
                                        <span className="style-span-subtitle-my-buys">
                                            Ofertas
                                        </span>
                                    </div>
                                    <div
                                        className="section-tablet-subtitle-my-store"
                                        onClick={() =>
                                            history.push(
                                                `/shopping/${user.id}/dashboard`
                                            )
                                        }
                                    >
                                        <img
                                            src={iconStore}
                                            alt="icon-minhas-loja"
                                            className="icon-subtitle-minha-loja"
                                        />
                                        <span className="style-span-subtitle-my-buys">
                                            Minha Loja
                                        </span>
                                    </div>
                                    <div className="section-tablet-subtitle-my-profile">
                                        <img
                                            src={iconUserprofile}
                                            alt="icon-my-profile"
                                            className="icon-subtitle-my-profile"
                                        />
                                        <span className="style-span-subtitle-my-profile">
                                            {user.name.split(' ')[0]}
                                        </span>
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                )}
                {/* CÓDIGO DE SUBTÍTULO PARA TABLET */}
                {window.innerWidth >= 768 && window.innerWidth <= 1022 && (
                    <>
                        <div className="section-tablet-subtitle">
                            {/* Condição mostrando opções para usuário logado. */}
                            {!user ? (
                                <>
                                    <CategoriesMenu />
                                    <div
                                        className="section-tablet-subtitle-my-store"
                                        onClick={() =>
                                            history.push(
                                                `/search/promoção/:null`
                                            )
                                        }
                                    >
                                        <img
                                            src={iconSale}
                                            alt="icon-minhas-loja"
                                            className="icon-subtitle-minha-loja"
                                        />
                                        <span className="style-span-subtitle-my-buys">
                                            Ofertas
                                        </span>
                                    </div>
                                    <div
                                        className="section-tablet-subtitle-my-store"
                                        onClick={() =>
                                            history.push(`/search/%20/:null`)
                                        }
                                    >
                                        <img
                                            src={news}
                                            alt="icon-minhas-loja"
                                            className="icon-subtitle-minha-loja"
                                        />
                                        <span className="style-span-subtitle-my-buys">
                                            Novidades
                                        </span>
                                    </div>
                                    <div
                                        className="section-tablet-subtitle-my-profile"
                                        onClick={() => history.push(`/login`)}
                                    >
                                        <img
                                            src={iconUserprofile}
                                            alt="icon-my-profile"
                                            className="icon-subtitle-my-profile"
                                        />
                                        <span className="style-span-subtitle-my-profile">
                                            Faça o login
                                        </span>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <CategoriesMenu />
                                    <div
                                        className="section-tablet-subtitle-my-store"
                                        onClick={() =>
                                            history.push(
                                                `/search/promoção/:null`
                                            )
                                        }
                                    >
                                        <img
                                            src={iconSale}
                                            alt="icon-minhas-loja"
                                            className="icon-subtitle-minha-loja"
                                        />
                                        <span className="style-span-subtitle-my-buys">
                                            Ofertas
                                        </span>
                                    </div>
                                    <div
                                        className="section-tablet-subtitle-my-store"
                                        onClick={() =>
                                            history.push(
                                                `/shopping/${user.id}/dashboard`
                                            )
                                        }
                                    >
                                        <img
                                            src={iconStore}
                                            alt="icon-minhas-loja"
                                            className="icon-subtitle-minha-loja"
                                        />
                                        <span className="style-span-subtitle-my-buys">
                                            Minha Loja
                                        </span>
                                    </div>
                                    <div className="section-tablet-subtitle-my-profile">
                                        <img
                                            src={iconUserprofile}
                                            alt="icon-my-profile"
                                            className="icon-subtitle-my-profile"
                                        />
                                        <span className="style-span-subtitle-my-profile">
                                            {user.name.split(' ')[0]}
                                        </span>
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                )}
                {window.innerWidth <= 767 && (
                    <>
                        <div className="section-subtitle-mobile">
                            <div className="section-subtitle-menu">
                                <div
                                    className="section-icon-mobile-ofertas"
                                    onClick={() =>
                                        history.push(`/search/promoção/:null`)
                                    }
                                >
                                    <span className="style-span-mobile-sales">
                                        Ofertas
                                    </span>
                                </div>
                                <div
                                    className="section-icon-mobile-novidades"
                                    onClick={() =>
                                        history.push(`/search/Venda/:null`)
                                    }
                                >
                                    <span className="style-span-mobile-sales">
                                        Novidades
                                    </span>
                                </div>
                                <div
                                    className="section-icon-mobile-antiguidades"
                                    onClick={() =>
                                        history.push(
                                            `/search/category/Antiguidades%20e%20Coleções`
                                        )
                                    }
                                >
                                    <span className="style-span-mobile-sales">
                                        Antiguidades
                                    </span>
                                </div>
                                <div
                                    className="section-icon-mobile-baratissimos"
                                    onClick={() =>
                                        history.push(
                                            `/search/category/Baratíssimos`
                                        )
                                    }
                                >
                                    <span className="style-span-mobile-sales">
                                        Baratíssimos
                                    </span>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </Subtitle>
            {window.innerWidth <= 767 && (
                <>
                    <LocationContainer>
                        <div className="section-location-mobile">
                            <div className="section-subtitle-user-location">
                                <img
                                    src={iconLocation}
                                    alt="icone-localizaçao"
                                    className="subtitle-icon-location"
                                    onClick={() =>
                                        history.push(`/user/data/top`)
                                    }
                                />
                                {!user ? (
                                    <>
                                        <div
                                            className="subtitle-column-location-span"
                                            onClick={() =>
                                                history.push(`/user/data/top`)
                                            }
                                        >
                                            <span className="subtitle-location-span-2">
                                                Enviar para
                                            </span>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div
                                            className="subtitle-column-location-span"
                                            onClick={() =>
                                                history.push(`/user/data/top`)
                                            }
                                        >
                                            {user &&
                                            user.ProfileAddress &&
                                            user.ProfileAddress.length > 0 ? (
                                                <>
                                                    <span className="subtitle-location-span-1">
                                                        Enviar para
                                                    </span>

                                                    <span className="subtitle-location-span-3">
                                                        {
                                                            user
                                                                .ProfileAddress[0]
                                                                .city
                                                        }{' '}
                                                        -{' '}
                                                        {
                                                            user
                                                                .ProfileAddress[0]
                                                                .CEP
                                                        }
                                                    </span>
                                                </>
                                            ) : (
                                                <span className="subtitle-location-span-1">
                                                    Sem endereço de envio
                                                    cadastrado!
                                                </span>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </LocationContainer>
                </>
            )}
        </>
    )
}

export default UserHeader
