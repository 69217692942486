import React from 'react';
import Layout from '../../components/Layout/Layout';
import UserLogin from '../../components/Login/UserLogin';

const LoginPage = () => {
	return (
		<Layout title="Entrar">
			<UserLogin />
		</Layout>
	);
};

export default LoginPage;
