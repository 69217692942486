
import React, { useEffect, useState, useRef } from 'react';
import {
	Purchase,
	useDeletePurchaseMutation,
	useGetPurchaseStatusByPurchaseIdQuery,
	useGetQrCodePixLazyQuery,
	useGetPurchaseSlipQuery,
	useSendSolicitationCancelPurchaseMutation,
} from '../../../generated/graphql';
import { FormaDePagamentoContainer } from '../Style';
import { env } from 'process';
import moment from 'moment';
import { useQRCode } from 'react-qrcodes';
import Img from '../../Default/CommomImg/Img';
import { Link as a, useHistory } from 'react-router-dom';
import IUserAccount from '../../../models/IUserAccount';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store';
import CancelPurchaseModal, { ModalHandles } from './../../../components/CancelPurchaseModal';
import { Modal } from 'semantic-ui-react';
import ReportProblemModal from '../ReportProblemModal';
import { toast } from 'react-toastify';
import noPhoto from '../../../assets/sem-produto-detalhe.png'

type MyBuysCardProps = {
	purchase: Purchase;
	index: number;
};

export function MyBuysCard(props: MyBuysCardProps) {
	enum PaymentMethod {
		credit_card = 'Cartão de crédito',
		boleto = 'Boleto Bancário',
		pix = 'PIX',
	}
	const [exibirQrCode, setExibirQrCode] = useState<boolean>(false);
	const [reportProblemModal, setReportProblemModal] = useState(false);
	const [tentativasReport,setTentativasReport] = useState<number>(0)
	const [selectedItem, setSelectedItem] = useState<Purchase>();
	const [qrcode, setQrCode] = useState<string>();

	const { data: slipData } = useGetPurchaseSlipQuery({
		fetchPolicy: 'no-cache',
		variables: {
			purchaseId: props.purchase.id,
		},
	});
	const modalAlertRef = useRef<ModalHandles>(null)
	const [operationMessage, setOperationMessage] = useState<string>('');
	const [reload,setReload] = useState(true)
	const [getQrPix, { data: getQrPixData, loading: getQrPixDataLoading }] =
		useGetQrCodePixLazyQuery({
			fetchPolicy: 'no-cache',
		});
	const [purchaseStatus, setPurchaseStatus] = useState<string>('');
	const [cancelPurchase,setCancelPurchase] = useState(false)
	const [descriptCancel,setDescriptCancel] = useState('')
	const [isBlock, setBlock] = useState(false);
	const [tentativasCompra,setTentativasCompra] = useState<number>(1)

	const user: IUserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const [deletePurchase, {}] = useSendSolicitationCancelPurchaseMutation();
	const history = useHistory();

	const { data: getPurchaseStatus } = useGetPurchaseStatusByPurchaseIdQuery({
		fetchPolicy: 'no-cache',
		variables: {
			getPurchaseStatusByPurchaseIdInputData: {
				purchaseId: props.purchase.id,
			},
		},
	});

	useEffect(() => {
		if (getPurchaseStatus) {
			setPurchaseStatus(getPurchaseStatus.getPurchaseStatusByPurchaseId);
		}
	}, [getPurchaseStatus]);

	useEffect(()=>{
	  async function cancelPurchas(){
		try {
			await deletePurchase({
				variables: {
					purchaseId: props.purchase.id.toString(),
					userId: props.purchase.Product?.UserAccount?.id || -1,
					descriptionCancel:descriptCancel
				},
			});
			alert('Pedido de cancelamento enviado com Sucesso!');
			window.location.reload();
		} catch (err: any) {
			alert(err.message.replace('GraphQL error:', ''));
		}
	  }

	  if(cancelPurchase) cancelPurchas()

	 
	},[cancelPurchase])

	
	const ExibirQrCode = () => {
		const [qrRef] = useQRCode({
			text: qrcode || 'zero',
			options: {
				level: 'H',
				margin: 10,
				scale: 8,
				width: 150,
				color: {
					dark: '#000000',
					light: '#ffffff',
				},
			},
		});

		// @ts-ignore
		return <canvas ref={qrRef} />;
	};

	async function buscarQrCodePix(purchaseId: number, purchase: Purchase) {
		setSelectedItem(purchase);
		getQrPix({
			variables: {
				purchaseId: purchaseId,
			},
		});
	}

	useEffect(() => {
		if (getQrPixData) {
			setQrCode(getQrPixData.getQrCodePix);
			setExibirQrCode(true);
		}
	}, [getQrPixData]);

	async function cancelarCompra(purchase: Purchase) {
		setOperationMessage('Toda transação que seja cancelada, obrigatoriamente, passará por nossa análise, antes de eventual devolução do valor pago. Para tanto, o prazo mínimo estipulado é de 7 (sete) dias corridos. Obrigado pela compreensão!')
		setReload(false);
		modalAlertRef.current?.handleOpenModal()
	}

	function handleOpenReportModal(item: Purchase) {
		setSelectedItem(item);
		setReportProblemModal(true);
		setTentativasCompra((prevState)=>prevState++)
	}

	function formatarMetodoDePagamento(pagamento: string) {
		if (pagamento === 'credit_card') return PaymentMethod.credit_card;
		if (pagamento === 'boleto') return PaymentMethod.boleto;
		if (pagamento === 'pix') return PaymentMethod.pix;
	}

	function totalCompra(price: string, shipPrice: string) {
		let valorTotal = Number(price) + Number(shipPrice);
		return valorTotal.toLocaleString('pt-br', {
			style: 'currency',
			currency: 'BRL',
		});
	}

	return (
		<>
			<CancelPurchaseModal
				ref={modalAlertRef}
				AreaTitle={'Cancelamento de Compra'}
				DescriptionText={operationMessage}
				reload={reload}
				setCancelPurchase={setCancelPurchase}
				setDescriptCancel={setDescriptCancel}
				descriptCancel={descriptCancel}
			/>
			<div className="card-main-container" key={props.index}>
				<div className="card-one">
					<div className="product-status-container">
						<div className="product-status">
							<h2>{purchaseStatus}</h2>
							{props.purchase.paymentMethod === 'boleto' && (
								<a
									target="_blank"
									rel="noopener noreferrer"
									className="boleto-download"
									href={`${slipData?.getPurchaseSlip.slip_url}`}
								>
									Baixar boleto
								</a>
							)}
							<a
								href={`${env.REACT_APP_API_URL}/static/invoice/${props.purchase?.PurchaseInvoice?.name}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								{' '}
								{props.purchase.statusId === 3 && (
									<button
										className="boleto-download"
										style={{
											marginTop: '5px',
										}}
									>
										Baixar NF
									</button>
								)}
							</a>

							{props.purchase.statusId === 3 && (
								<span>
									Pago dia
									{` ${moment(props.purchase.paidAt).format(
										'DD/MM/YYYY'
									)} às ${moment(
										props.purchase.paidAt
									).format('h:mm')}`}
									(Aguarde intruções do vendedor para o
									recebimento do produto)
								</span>
							)}
						</div>
					</div>
					<FormaDePagamentoContainer>
						<h3>Forma de Pagamento</h3>
						{exibirQrCode &&
						props.purchase.statusId === 5 &&
						props.purchase.paymentMethod === 'pix' &&
						selectedItem &&
						selectedItem.id === props.purchase.id ? (
							<>
								<ExibirQrCode />
								<button onClick={() => setExibirQrCode(false)}>
									Voltar
								</button>
							</>
						) : (
							<>
								<p>
									{formatarMetodoDePagamento(
										props.purchase.paymentMethod as string
									)}
								</p>
								{props.purchase.paymentMethod === 'pix' && (
									<>
										<button
											onClick={() =>
												buscarQrCodePix(
													props.purchase.id,
													props.purchase
												)
											}
										>
											Exibir QrCode
										</button>
									</>
								)}
							</>
						)}
					</FormaDePagamentoContainer>
					<div className="product-pic-and-infos">
						<div className="product-pic">
							<div className="border">
								<Img
									src={`${
										env.REACT_APP_API_URL
									}/static/product/${
										props.purchase?.Product!.id
									}-1.jpeg`}
									alt=" "
									img={noPhoto}
								/>
							</div>
						</div>
						<div className="product-infos">
							<h2>{props.purchase?.Product!.title}</h2>
							<h3>Quantidade: {props.purchase.productQuantity}.</h3>
							<h3>
								{parseFloat(
									props.purchase?.price!
								).toLocaleString('pt-br', {
									style: 'currency',
									currency: 'BRL',
								})}
								{props.purchase.productShippingPrice &&
									props.purchase.productShippingPrice >
										'0' && (
										<>
											+{' '}
											{parseFloat(
												props.purchase
													.productShippingPrice
											).toLocaleString('pt-br', {
												style: 'currency',
												currency: 'BRL',
											})}{' '}
											Frete Total:{' '}
											{totalCompra(
												props.purchase.price!,
												props.purchase
													.productShippingPrice
											)}
										</>
									)}
							</h3>
						</div>
					</div>
				</div>
				<div className="card-two">
					<div className="user-infos-container">				
						<Img	
							src={`${env.REACT_APP_API_URL}/static/user/${user.id}.jpeg`}
						/>
						<div className="user-infos">
							<h2>
								{props.purchase?.UserAccount!.name.substring(
									0,
									17
								)}
								...
							</h2>
						</div>
					</div>
					<div className="see-details">
						<button
							onClick={() =>
								history.push(
									`/my-buys-item/${props.purchase.id}`
								)
							}
							style={{marginBottom:'5px'}}
							disabled={props.purchase.statusId !== 3 || props.purchase.productShippingPrice === '0'}
							className={props.purchase.statusId !== 3 || props.purchase.productShippingPrice === '0' ? 'cancelar-compra-disabled': 'button-rastreio'}
						>
							Rastrear Produto
						</button>
						<Modal
							onClose={() => setReportProblemModal(false)}
							onOpen={() => setReportProblemModal(true)}
							open={reportProblemModal}
						>
							<ReportProblemModal
								closeModal={() => setReportProblemModal(false)}
								purchase={selectedItem!}
								setTentativas={setTentativasReport}
								tentativas={tentativasReport}

							/>
							
						</Modal>
							<button
								onClick={() =>
									handleOpenReportModal(props.purchase)
								}
								disabled={
									props.purchase.statusId !== 3
								}
								className={
									props.purchase.statusId !== 3
										? 'cancelar-compra-disabled'
										: 'cancelar-compra'
								}
							>
								Reportar problema
							</button>
						
						<button
							onClick={() => cancelarCompra(props.purchase)}
							disabled={
								props.purchase.paymentMethod === 'boleto' ||
								props.purchase.statusId === 6 ||
								props.purchase.statusId === 4 ||
								props.purchase.statusId !== 3
							}
							className={
								props.purchase.paymentMethod === 'boleto' ||
								props.purchase.statusId === 6 ||
								props.purchase.statusId === 4  ||
								props.purchase.statusId !== 3
									? 'cancelar-compra-disabled'
									: 'cancelar-compra'
							}
						>
							Cancelar Compra
						</button>
						<button
							onClick={() =>
								history.push(
									`/product-view/${
										props.purchase?.Product?.id
									}/product-avaliations`
								)
							}
							disabled={props.purchase.statusId !== 3}
							className={ props.purchase.statusId !== 3 ? 'cancelar-compra-disabled': 'avaliar-compra'}					
						>
							Avaliar Produto
						</button>
					</div>
				</div>
			</div>
		</>
	);
}

export default MyBuysCard;
