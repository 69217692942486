import React from 'react';
import BuyProductsInCartPayProcess from '../../components/BuyProductsInCart/BuyProductsInCartPaymentProcess/BuyProductsInCartPayProcess';
import Layout from '../../components/Layout/Layout';

const BuyProductInCartPayment = () => {
	return (
		<Layout title="Pagamento">
			<BuyProductsInCartPayProcess />
		</Layout>
	);
};

export default BuyProductInCartPayment;
