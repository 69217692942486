import React from 'react';
import BuyPaymentProcess from '../../components/BuyPaymentProcess/BuyPaymentProcess';
import Layout from '../../components/Layout/Layout';

const BuyPaymentProcessPage = () => {
	return (
		<Layout title="Pagamento">
			<BuyPaymentProcess />
		</Layout>
	);
};
export default BuyPaymentProcessPage;
