import React, {useCallback, useRef} from 'react';
import FilterOptionItem from './FilterOptionItem/FilterOptionItem';
import NotificationsCard from './NotificationsCard/NotificationsCard';
import { Container, FilterCard, FilterAndButtonContainer } from './styles';
import NewUserModal,{ModalNewUserHandles} from "../AdmRegisterCard/NewUserModal/NewUserModal";
import NewNotificationModal from "../AdmRegisterCard/NewNotificationModal/NewNotificationModal";

function AdmNotifications () {

	const modalRef = useRef<ModalNewUserHandles>(null);

	const openModal = useCallback(()=>{
		modalRef.current?.showModal();
	},[modalRef])

	return (
		<>
			<Container>
				<FilterAndButtonContainer>
					<FilterCard>
						<h2>Filtros</h2>

						<FilterOptionItem
							title="Todos"
						/>

						<FilterOptionItem
							title="Compradores"
						/>
						<FilterOptionItem
							title='Vendedores'
						/>


						<FilterOptionItem
							title='Moderadores'
						/>
						<FilterOptionItem
							title='Administradores'
						/>

						<FilterOptionItem
							title='FAQ'
						 />
					</FilterCard>
					<button onClick={() => openModal()} className="BotaoEmitirAviso">
						Emitir Aviso
					</button>
					<NewNotificationModal ref={modalRef}/>
				</FilterAndButtonContainer>
				<NotificationsCard/>
			</Container>
		</>
	);
};

export default AdmNotifications;
