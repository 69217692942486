export function currency(e: React.FormEvent<HTMLInputElement>) {
	let value = e.currentTarget.value;
	console.log('O valor é:', value);
	value = value.replace(/\D/g, '');
	value = value.replace(/(\d)(\d{2})$/, '$1,$2');
	value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

	e.currentTarget.value = value;
	return e;
}

export function currencyDefault(e: number) {
	let value = e.toString();
	value = value.replace(/\D/g, '');
	value = value.replace(/(\d)(\d{2})$/, '$1,$2');
	value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

	return value;
}

export function cepDefault(e: string) {
	let value = e;
	value = value.replace(/\D/g, '');
	value = value.replace(/(\d{5})(\d)/, '$1-$2');

	e = value;
	return e;
}

export function cep(e: React.FormEvent<HTMLInputElement>) {
	e.currentTarget.maxLength = 9;
	let value = e.currentTarget.value;
	value = value.replace(/\D/g, '');
	value = value.replace(/(\d{5})(\d)/, '$1-$2');

	e.currentTarget.value = value;
	return e;
}

export function number(e: React.FormEvent<HTMLInputElement>) {
	let value = e.currentTarget.value;
	value = value.replace(/\D/g, '');
	value = value.replace(/^0/, '');

	if (value.length > 10) {
		value = value.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
	} else if (value.length > 5) {
		value = value.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3');
	} else if (value.length > 2) {
		value = value.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
	} else {
		value = value.replace(/^(\d*)/, '($1');
	}

	e.currentTarget.value = value;
	return e;
}
export function setCpfMaskFunc(value: string) {
	if (value.length < 15) {
		return value
			.replace(/\D+/g, '')
			.replace(/(\d{3})(\d)/, '$1.$2')
			.replace(/(\d{3})(\d)/, '$1.$2')
			.replace(/(\d{3})(\d{1,2})/, '$1-$2');
	} else {
		return value
			.replace(/\D+/g, '')
			.replace(/(\d{2})(\d)/, '$1.$2')
			.replace(/(\d{3})(\d)/, '$1.$2')
			.replace(/(\d{3})(\d)/, '$1/$2')
			.replace(/(\d{4})(\d)/, '$1-$2')
			.replace(/(-\d{2})\d+?$/, '$1');
	}
}

export function phoneMaskFunc(value: string) {
	return value
		.replace(/\D+/g, '')
		.replace(/(\d{2})(\d)/, '($1) $2')
		.replace(/(\d{4})(\d)/, '$1-$2')
		.replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
		.replace(/(-\d{4})\d+?$/, '$1');
}
export function cepMaskFunc(value: string) {
	return value
		.replace(/\D+/g, '')
		.replace(/(\d{2})(\d{3})(\d)/, '$1.$2-$3')
		.replace(/(-\d{3})\d+?$/, '$1');
}
export function bankAccountMaskFunc(e: string) {
	let value = e.replace('-', '');
	/*   setBankAccountMask(value);
    setAccountDigit(value.substr(value.length - 1, value.length));
    setAccount(value.substr(0, value.length - 1)); */
	if (value) {
		switch (value.length) {
			case 8:
				return value.replace(/\D/g, '').replace(/(\d{7})(\d)/, '$1-$2');

			case 6:
				return value.replace(/\D/g, '').replace(/(\d{5})(\d)/, '$1-$2');

			case 9:
				return value.replace(/\D/g, '').replace(/(\d{8})(\d)/, '$1-$2');

			case 11:
				return value
					.replace(/\D/g, '')
					.replace(/(\d{10})(\d)/, '$1-$2');

			default:
				break;
		}
	}
}

export function dateMaskFunc(e: string) {
	return e.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
}
