import styled from 'styled-components';

export const Image = styled.div`
	position: fixed;
	top: 180px;
	right: 180px;
	@media(max-width:475px){
		right:20px;
	}
	@media(max-width:320px){
		right:10px;
	}

	.icon {
		&:hover {
			cursor: pointer;
			display: initial;
		}
	}
`;
export const Container = styled.div`
	width: 80%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;
export const MainCard = styled.main`
	width: 100%;
	height: max-content;
	background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
	background: #ffffff 0% 0% no-repeat padding-box;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 25px;
	padding-top: 0;

	h2 {
		font-family: 'Poppins';
		font-weight: 600;
		font-size: min(28px, 2vw);
		color: #6b97a5;
		margin-bottom: 25px;
	}

	.items-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		min-width: 100%;
		
		.styleManual{
			margin-bottom: 1.8rem;
		}
		.styleIcon{
			margin-right: 0.5rem;
		}
	}
`;
