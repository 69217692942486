import styled from 'styled-components';

export const SubCategorie = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid black;
    border-radius: 30px;
    opacity: 1;
    width: 16rem;
    height: 32px;
    padding: 20px;
    margin-right: 2rem;
    margin-bottom: 10px;
    font-family:'Poppins',semi-bold;
    font-weight:600;
    font-size: 13px;
    color: #6b97a5;

    button {
        height: 16px;
        background: #FF7E0F;
        border-radius: 12px;
        color: white;
        border: none;
        font-family: 'Poppins'semi-bold;
        font-size: 10px;
        margin-left: 10px;
    }
`;
