import styled from 'styled-components'

export const SnackBar = styled.div`
    background: #fff4e5;
    flex-direction: row;
    display: flex;
    height: 20vh;
    margin: auto;
    justify-content: center;
    align-items: center;

    h3 {
        color: #663c33;
        font-family: 'Poppins', semi-bold;
        font-size: 1em;
        font-weight: bold;
        margin-bottom: 1em;
    }
    button {
        height: 35px;
        width: 15em;
        border: solid 1px black;
        border-radius: 8px;
        font-family: 'Poppins', semi-bold;
        font-weight: bold;
        font-size: 0.8em;
        background-color: #6b97a5;
        margin: 0.5em;
    }
    button:hover {
        top: -4px;
        box-shadow: 0 4px 4px #999;
        transition: all 0.2s ease-in-out;
    }
    a {
        color: white;
    }
    .Alignment {
        display: flex;
        height: 2em;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 1em;
    }

    span {
        display: flex;
        flex-direction: row;
    }

    .CloseContainer {
        position: relative;
        height: 100%;
        background: none;
    }
    .CloseIcon {
        cursor: pointer;
        margin: 0;
    }
    .CloseIcon:hover {
        border-radius: 10px;
        top: -4px;
        box-shadow: 0 4px 4px #999;
        transition: all 0.2s ease-in-out;
    }
`

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 200px;
    background-color: #fff;
    @media (max-width: 475px) {
        padding: 0;
    }

    input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    input[type='number'] {
        -moz-appearance: textfield;
        appearance: textfield;
    }
    label {
        font-size: 12px;
    }
    input {
        font-size: 14;
    }
    h2 {
        color: #6b97a5;
        font-family: 'Poppins', semi-bold;
        font-weight: bold;
        font-size: 18px;
    }

    .form-style {
        width: 100%;
        @media (min-width: 320px) and (max-width: 475px) {
            width: 100%;
        }
    }
`

export const Container = styled.div`
    padding: 20px;
    margin-top: 0px;
    border: solid 1px black;
    border-radius: 7px;
    padding-left: 28px;
    padding-top: 25px;
    font-family: 'Poppins', semi-bold;

    span {
        display: flex;
        flex-direction: row;
        color: #ff0000;
        margin-top: 0.3rem;
        font-size: 14px;
        font-family: 'Poppins', semi-bold;
    }
    .options-container {
        width: 100%;
        height: 51px;
        border: 1px solid black;
        border-radius: 8px;
        opacity: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 11px;
        margin-top: 11px;

        span {
            color: black;
            font-size: 13px;
            font-family: 'Poppins', semi-bold;
        }
        @media (max-width: 475px) {
            width: 100%;
        }
    }

    .input-color {
        root {
            color: #6b98a6;
        }
        div {
            flex-direction: row;
            .MuiSvgIcon-root {
                width: 45px;
                height: 34px;
            }
            .MuiSvgIcon-root {
                width: 45px;
                height: 34px;
            }
        }
    }

    .title-input-container {
        display: flex;
        flex-direction: column;
        border: solid 1px black;
        padding: 4px;
        border-radius: 8px;
        margin-bottom: 25px;
        width: 100%;
        height: 51px;
        label {
            color: #6b97a5;
            font-family: 'Poppins', semi-bold;
            font-weight: bold;
            font-size: 12px;
            @media (max-width: 475px) {
                font-size: 14px;
            }
        }
        input {
            border: none;
            color: black;
            font-size: 22px;
            font-family: 'Poppins', semi-bold;
            height: 30px;
            font-size: 13px;
            background-color: #fff;
        }
        input:focus {
            outline: none;
        }
        input:focus::-webkit-input-placeholder {
            color: transparent;
        }
        @media (min-width: 320px) and (max-width: 475px) {
            width: 100%;
            display: flex;
            flex-direction: column;
        }
    }
    .div {
        display: flex;
        flex-direction: row;
        width: 100%;

        @media (min-width: 320px) and (max-width: 475px) {
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        .subtitle-input-container {
            display: flex;
            flex-direction: column;
            border: solid 1px black;
            padding: 4px;
            border-radius: 8px;
            margin-bottom: 25px;
            margin-right: 35px;
            width: 100%;
            height: 51px;
            label {
                color: #6b97a5;
                font-family: 'Poppins', semi-bold;
                font-weight: bold;
                font-size: 12px;
                @media (max-width: 475px) {
                    font-size: 14px;
                }
            }
            input {
                border: none;
                color: black;
                font-size: 22px;
                font-family: 'Poppins', semi-bold;
                font-size: 13px;
                background-color: #fff;
            }
            input:focus {
                outline: none;
            }
            input:focus::-webkit-input-placeholder {
                color: transparent;
            }
        }
        .choice-buttons-container {
            display: flex;
            flex-direction: row;
            border-radius: 8px;
            border: solid 1px black;
            margin-bottom: 25px;
            width: 179px;
            height: 51px;

            div {
                width: 100%;
                border: none;
                border-radius: 8px;
                font-family: 'Poppins', semi-bold;
                font-weight: bold;
                font-size: 13px;
                color: #6b97a5;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                &:hover {
                    cursor: pointer;
                }
                @media (max-width: 475px) {
                    font-size: 14px;
                }
            }

            .button-active-left {
                background-color: #6b97a5;
                color: white;
                outline: none;
                border-radius: 8px;
                font-family: 'Poppins', semi-bold;
                font-weight: bold;
                font-size: 13px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                @media (max-width: 475px) {
                    font-size: 14px;
                }
            }
            .button-active-right {
                background-color: #6b97a5;
                color: white;
                outline: none;
                border-radius: 8px;
                font-family: 'Poppins', semi-bold;
                font-weight: bold;
                font-size: 13px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                @media (max-width: 475px) {
                    font-size: 14px;
                }
            }
            @media (min-width: 320px) and (max-width: 475px) {
                width: 100%;
            }
        }
    }
    .description-container {
        display: flex;
        flex-direction: column;
        border: solid 1px black;
        padding: 6px;
        border-radius: 8px;
        margin-bottom: 25px;
        width: 100%;
        height: 89px;
        label {
            color: #6b97a5;
            font-family: 'Poppins', semi-bold;
            font-weight: bold;
            font-size: 12px;
            @media (max-width: 475px) {
                font-size: 14px;
            }
        }
        textarea {
            resize: none;
            min-height: 60px;
            border: none;
            color: black;
            font-size: 22px;
            font-family: 'Poppins', semi-bold;
            font-size: 13px;
            background-color: #fff;
        }
        textarea:focus {
            outline: none;
        }
    }
    .other-container {
        display: flex;
        flex-direction: row;
        gap: 20px;
        @media (max-width: 475px) {
            flex-direction: column;
            gap: 25px;
        }
        .inputs-organizer-brand {
            display: flex;
            flex-direction: column;
            border: solid 1px black;
            padding: 4px;
            border-radius: 8px;
            width: 100%;
            height: 51px;
            @media (max-width: 475px) {
                width: 100%;
            }

            label {
                color: #6b97a5;
                font-family: 'Poppins', semi-bold;
                font-weight: bold;
                font-size: 12px;
                @media (max-width: 475px) {
                    font-size: 14px;
                }
            }

            input {
                border: none;
                color: black;
                font-size: 13px;
                font-family: 'Poppins', semi-bold;
                background-color: #fff;
            }
            input:focus {
                outline: none;
            }
            input:focus::-webkit-input-placeholder {
                color: transparent;
            }
        }
        .inputs-organizer-size {
            display: flex;
            flex-direction: column;
            border: solid 1px black;
            padding: 4px;
            border-radius: 8px;
            width: 87px;
            height: 51px;

            label {
                color: #6b97a5;
                font-family: 'Poppins', semi-bold;
                font-weight: bold;
                font-size: 12px;
                height: 19px;
                @media (max-width: 475px) {
                    font-size: 14px;
                }
            }

            input {
                border: none;
                color: black;
                font-size: 13px;
                font-family: 'Poppins', semi-bold;
                background-color: #fff;
            }
            input:focus {
                outline: none;
            }
            input:focus::-webkit-input-placeholder {
                color: transparent;
            }
        }
        .inputs-organizer-material {
            display: flex;
            flex-direction: column;
            border: solid 1px black;
            padding: 4px;
            border-radius: 8px;
            width: 100%;
            height: 51px;
            @media (max-width: 475px) {
                width: 100%;
            }

            label {
                color: #6b97a5;
                font-family: 'Poppins', semi-bold;
                font-weight: bold;
                font-size: 12px;
                @media (max-width: 475px) {
                    font-size: 14px;
                }
            }

            input {
                border: none;
                color: black;
                font-size: 13px;
                font-family: 'Poppins', semi-bold;
                background-color: #fff;
            }
            input:focus {
                outline: none;
            }
            input:focus::-webkit-input-placeholder {
                color: transparent;
            }
        }
    }

    .prompt-delivery {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        .prompt-delivery-buttons {
            display: flex;
            flex-direction: row;
            border-radius: 8px;
            border: solid 1px black;
            width: 100%;
            height: 40px;

            .prompt-delivery-button {
                width: 90px;
                font-weight: bold;
                padding: 0px;
                font-size: 13px;
                cursor: pointer;
                outline: none;
                background-color: #fff;
                border: 1px solid black;
                color: #6b97a5;
                font-family: 'Poppins', bold;
                @media (max-width: 475px) {
                    width: 100%;
                }
            }

            #yes {
                border-radius: 8px;
                border: none;
                margin: 0px;
                width: 100%;
                @media (max-width: 475px) {
                    width: 100%;
                }
            }

            #no {
                border-radius: 8px;
                border: none;
                margin: 0px;
                width: 100%;
                @media (max-width: 475px) {
                    width: 100%;
                }
            }

            .active {
                color: #fff;
                background-color: #6b97a5;
            }
            @media (max-width: 475px) {
                width: 100%;
            }
        }
    }

    @media (min-width: 425px) and (max-width: 475px) {
        padding: 20px;
        margin-top: 0px;
        border: solid 1px black;
        border-radius: 7px;
        font-family: 'Poppins', semi-bold;
        flex-direction: column;
        display: flex;
        width: 100%;
        padding-right: 5px;
        padding-left: 5px;
    }
    @media (min-width: 375px) and (max-width: 424px) {
        padding: 20px;
        margin-top: 0px;
        border: solid 1px black;
        border-radius: 7px;
        padding-left: 0;
        font-family: 'Poppins', semi-bold;
        flex-direction: column;
        display: flex;
        width: 100%;
        padding-right: 5px;
        padding-left: 5px;
    }
    @media (min-width: 320px) and (max-width: 374px) {
        padding: 20px;
        margin-top: 0px;
        border: solid 1px black;
        border-radius: 7px;
        padding-left: 0;
        font-family: 'Poppins', semi-bold;
        flex-direction: column;
        display: flex;
        width: 100%;
        padding-right: 5px;
        padding-left: 5px;
    }
`
interface inputProps {
    borderColor?: string
}
export const SubTitleInputContainer = styled.div<inputProps>`
    display: flex;
    flex-direction: column;
    border: solid 1px ${(Prop) => Prop.borderColor};
    padding: 4px;
    border-radius: 8px;
    margin-bottom: 25px;
    width: 100%;
    height: 51px;
    label {
        color: #6b97a5;
        font-family: 'Poppins', semi-bold;
        font-weight: bold;
        font-size: 12px;
        @media (max-width: 475px) {
            font-size: 14px;
        }
    }
    input {
        border: none;
        color: black;
        font-size: 22px;
        font-family: 'Poppins', semi-bold;
        font-size: 13px;
        background-color: #fff;
    }
    input:focus {
        outline: none;
    }
    input:focus::-webkit-input-placeholder {
        color: transparent;
    }
    @media (min-width: 320px) and (max-width: 475px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-right: 0;
    }
`
export const TitleInputContainer = styled.div<inputProps>`
    display: flex;
    flex-direction: column;
    border: solid 1px ${(Prop) => Prop.borderColor};
    padding: 4px;
    border-radius: 8px;
    margin-bottom: 25px;
    width: 100%;
    height: 51px;

    label {
        color: #6b97a5;
        font-family: 'Poppins', semi-bold;
        font-weight: bold;
        font-size: 12px;
        @media (max-width: 475px) {
            font-size: 14px;
        }
    }
    input {
        border: none;
        color: black;
        font-size: 22px;
        font-family: 'Poppins', semi-bold;
        height: 18px;
        font-size: 13px;
        background-color: #fff;
    }
    input:focus {
        outline: none;
    }
    input:focus::-webkit-input-placeholder {
        color: transparent;
    }

    @media (min-width: 425px) and (max-width: 767px) {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    @media (min-width: 375px) and (max-width: 424px) {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    @media (min-width: 320px) and (max-width: 374px) {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
`
export const ChoiceContainer = styled.div<inputProps>`
    label {
        color: #6b97a5;
        font-family: 'Poppins', semi-bold;
        font-weight: bold;
        font-size: 12px;
        @media (max-width: 475px) {
            font-size: 14px;
        }
    }
    .choice-buttons-container {
        display: flex;
        flex-direction: row;
        border-radius: 8px;
        border: solid 1px black;
        margin-bottom: 25px;
        width: 100%;
        height: 40px;

        div {
            width: 100%;
            border: none;
            border-radius: 8px;
            font-family: 'Poppins', semi-bold;
            font-weight: bold;
            font-size: 13px;
            color: #6b97a5;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            &:hover {
                cursor: pointer;
            }
            @media (max-width: 475px) {
                font-size: 14px;
            }
        }

        .button-active-left {
            background-color: #6b97a5;
            color: white;
            outline: none;
            border-radius: 8px;
            font-family: 'Poppins', semi-bold;
            font-weight: bold;
            font-size: 13px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            @media (max-width: 475px) {
                font-size: 14px;
            }
        }
        .button-active-right {
            background-color: #6b97a5;
            color: white;
            outline: none;
            border-radius: 8px;
            font-family: 'Poppins', semi-bold;
            font-weight: bold;
            font-size: 13px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            @media (max-width: 475px) {
                font-size: 14px;
            }
        }
        @media (min-width: 320px) and (max-width: 475px) {
            width: 100%;
        }
    }
`

export const InputLocationContainer = styled.div<inputProps>`
    display: flex;
    flex-direction: column;
    label {
        color: #6b97a5;
        font-family: 'Poppins', semi-bold;
        font-weight: bold;
        font-size: 12px;
        @media (max-width: 475px) {
            font-size: 14px;
            padding-bottom: 15px;
        }
    }
    .container {
        width: 100%;
        flex-direction: row;
        display: flex;
        gap: 20px;
        @media (max-width: 475px) {
            flex-direction: column;
        }
    }
`

export const InputPropertyInformationsContainer = styled.div<inputProps>`
    display: flex;
    flex-direction: column;
    label {
        color: #6b97a5;
        font-family: 'Poppins', semi-bold;
        font-weight: bold;
        font-size: 12px;
        @media (max-width: 475px) {
            font-size: 14px;
            padding-bottom: 15px;
        }
    }
    .container {
        width: 100%;
        flex-direction: row;
        display: flex;
        gap: 20px;
    }
`

export const InputOrganizerSize = styled.div<inputProps>`
    display: flex;
    flex-direction: column;
    border: solid 1px ${(Prop) => Prop.borderColor};
    padding: 4px;
    border-radius: 8px;
    width: 100%;
    height: 51px;
    margin-bottom:2rem;

    span {
        display: flex;
        flex-direction: row;
        color: #ff0000;
        margin-top: 0.8rem;
        font-size: 12px;
        font-family: 'Poppins', semi-bold;
    }

    label {
        color: #6b97a5;
        font-family: 'Poppins', semi-bold;
        font-weight: bold;
        font-size: 12px;
        height: 19px;
        @media (max-width: 475px) {
            font-size: 14px;
        }
    }
    input {
        border: none;
        color: black;
        font-size: 13px;
        font-family: 'Poppins', semi-bold;
        background-color: #fff;
    }
    input:focus {
        outline: none;
    }
    input:focus::-webkit-input-placeholder {
        color: transparent;
    }

    select {
        border: none;
        color: black;
        font-size: 13px;
        font-family: 'Poppins', semi-bold;
        background-color: #fff;
    }
    select:focus {
        outline: none;
    }
    @media (max-width: 475px) {
        width: 100%;
        margin-right: 0;
    }
`
export const DescriptionContainer = styled.div<inputProps>`
    display: flex;
    flex-direction: column;
    border: solid 1px ${(Prop) => Prop.borderColor};
    padding: 6px;
    border-radius: 8px;
    width: 100%;
    height: 196px;
    label {
        color: #6b97a5;
        font-family: 'Poppins', semi-bold;
        font-weight: bold;
        font-size: 12px;
        @media (max-width: 475px) {
            font-size: 14px;
        }
    }
    @media (min-width: 320px) and (max-width: 475px) {
        width: 100%;
    }

    .ql-snow {
        border: 0 !important;
    }
`

export const ImagesContainer = styled.div`
    padding: 17px;
    margin-top: 0px;
    margin-bottom: 50px;
    border: solid 1px black;
    border-radius: 7px;
    padding-left: 28px;
    font-family: 'Poppins', semi-bold;

    span {
        h1 {
            font-family: 'Poppins', semi-bold;
            color: #6b97a5;
            font-size: 15px;
        }
    }
    .img-instructions {
        font-family: 'Poppins', semi-bold;
        color: #6b97a5;
    }

    p {
        color: black;
    }
    #h1-instruction {
    }
    .img-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        span {
            margin-bottom: 60px;
            height: 182px;
            width: 324px;
            margin-right: 35px;
            border: solid 1px black;
            border-radius: 8px;
            align-items: center;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 157px;
            height: 105px;
            label:hover {
                cursor: pointer;
            }
            .preview {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .delete-icon-background {
                    position: relative;

                    svg {
                        position: absolute;
                        left: 128px;
                        top: -53px;
                        background: white;
                        border-radius: 20px;
                        width: 27px;
                        fill: red;
                    }
                    svg:hover {
                        cursor: pointer;
                    }
                }
                img {
                    width: 157px;
                    height: 105px;
                    border-radius: 8px;
                }
            }
        }
        p {
            font-size: 15px;
            color: black;
            font-family: 'Poppins', semi-bold;
        }
    }
    input {
        display: none;
    }
    input:focus::-webkit-input-placeholder {
        color: transparent;
    }
    @media (max-width: 475px) {
        width: 100%;
    }
`

export const Title = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 35px;
    margin-right: 0 !important;

    h2 {
        font-family: 'Poppins', semi-bold;
        font-size: 16px;
        color: #6b97a5;
        font-weight: bold;
    }

    @media (min-width: 320px) and (max-width: 475px) {
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        margin-right: 0 !important;
        width: 100%;

        h2 {
            font-family: 'Poppins', semi-bold;
            font-size: 16px;
            color: #6b97a5;
            margin-right: 0;
            font-weight: bold;
        }
    }
`

export const TitleCategory = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    h2 {
        font-family: 'Poppins', semi-bold;
        font-size: 16px;
        color: #6b97a5;
        font-weight: bold;

        @media (max-width: 475px) {
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: center;
        }
    }
    @media (max-width: 475px) {
        width: 100%;
    }
`

type CategorysType = {
    variacao?: boolean
    colorvariantion?: string
}

export const Categorys = styled.div<CategorysType>`
    padding: 17px;
    margin-top: 0px;
    margin-bottom: 50px;
    border: solid 1px black;
    border-radius: 7px;
    padding-left: 28px;
    font-family: 'Poppins', semi-bold;
    span {
        h1 {
            font-family: 'Poppins', semi-bold;
            color: #6b97a5;
            font-size: 15px;
            margin: 0;
            margin-bottom: 25px;
            padding-top: 15px;
        }
        &:hover {
            cursor: pointer;
        }
    }
    .normal-size-active {
        background-color: blue;
    }
    .img-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin-bottom: 1rem;

        .normal-size {
            margin-bottom: 10px;
            height: 50px;
            width: 235px;
            margin-right: 35px;
            border: solid 1px black;
            border-radius: 8px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            color: black;
            font-family: 'Poppins', semi-bold;
        }
        .size {
            margin-bottom: 10px;
            height: 50px;
            width: 348px;
            margin-right: 35px;
            border: solid 1px black;
            border-radius: 8px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            color: black;
            font-family: 'Poppins', semi-bold;
        }
        .option {
            width: 210px;
            height: 30px;
            margin-right: 10px;
            margin-bottom: 10px;
            border: solid 1px black;
            border-radius: 8px;
            align-items: center;
            color: black;
            display: flex;
            flex-direction: row;
            font-size: 12.5px;
            justify-content: center;
            font-family: 'Poppins', semi-bold;
            background-color: #fff;

            @media (max-width: 475px) {
                width: 100%;
                height: 45px;
                font-size: 14px;
            }

            label:hover {
                cursor: pointer;
            }
            .preview {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                img {
                    height: 175px;
                    width: 274px;
                    border-radius: 8px;
                }
            }
        }
        .option-active {
            width: 210px;
            height: 30px;
            margin-right: 10px;
            margin-bottom: 10px;
            border: solid 1px black;
            border-radius: 8px;
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
            background-color: #6b97a5;
            color: white;

            label:hover {
                cursor: pointer;
            }

            @media (max-width: 475px) {
                width: 100%;
                height: 45px;
                font-size: 16px;
            }
        }
        @media (max-width: 475px) {
            width: 100%;
            justify-content: column;
        }
    }
    .sub {
        border-top: solid 1px #9e9e9e;
    }

    .img-instructions {
        display: flex;
        flex-direction: row;
        gap: 20px;
        .variation-button {
            background-color: 'white';
            border: black solid 1px;
            font-family: 'Poppins', semi-bold;
            color: black;
            font-size: 12px;
            border-radius: 8px;
            height: 40px;
            width: 100%;
            padding: 12px;
            cursor: pointer;
            margin-bottom: 0.8rem;
            display: flex;
            flex-direction: row;
            gap: 20px;
            @media (max-width: 475px) {
                width: 100%;
                color: black;
            }
        }
    }

    .variacao-container {
        margin-top: 2rem;
        display: ${(props) => (props.variacao ? 'flex' : 'none')};
        margin-bottom: 1.5rem;

        .container-column-variacao {
            display: flex;
            flex-direction: column;
            h2 {
                padding-top: 1rem;
                padding-bottom: 1rem;
            }
        }
        input {
            margin-right: 5px;
            width: 10vw;
            height: 35px;
            border-radius: 8px;
            border: solid 1px black;
            background-color: #fff;
            @media (max-width: 475px) {
                width: 100%;
            }
        }
        button {
            border: none;
            height: 35px;
            border-radius: 100%;
            cursor: pointer;
        }
        .margin-top-container-variations {
            .title-quantidade {
                padding-bottom: 1rem;
                padding-top: 1rem;
            }
        }
        .sugestao-container {
            display: flex;
            flex-direction: row;
            margin-top: 5rem;
        }
        @media (max-width: 475px) {
            flex-direction: column;
        }
    }
    .sell-type-buttons-container {
        .sell-type-container {
            display: flex;
            flex-direction: row;
            border-radius: 8px;
            border: solid 1px black;
            margin-bottom: 25px;
            width: 100%;
            height: 40px;
            margin-right: 25px;
            div {
                width: 100%;
                border: none;
                background-color: #fff;
                border-radius: 8px;
                color: #6b97a5;
                font-family: 'Poppins', semi-bold;
                font-weight: bold;
                font-size: 13px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                &:hover {
                    cursor: pointer;
                }
            }

            .button-sell-active {
                width: 100%;
                color: white;
                border: none;
                border-radius: 8px;
                font-family: 'Poppins', semi-bold;
                font-weight: bold;
                font-size: 13px;
                background-color: #6b97a5;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                @media (max-width: 475px) {
                    width: 100%;
                    margin-right: 0;
                }
            }
            .bargain-active {
                width: 100%;
                color: white;
                border: none;
                border-radius: 8px;
                font-family: 'Poppins', semi-bold;
                font-weight: bold;
                font-size: 13px;
                background-color: #6b97a5;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                @media (max-width: 475px) {
                    width: 100%;
                    margin-right: 0;
                }
            }
            @media (max-width: 475px) {
                width: 100%;
                margin-right: 0;
            }
        }
        @media (max-width: 475px) {
            width: 100%;
        }
    }
`

export const PriceTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;

    h2 {
        font-family: 'Poppins', semi-bold;
        font-size: 16px;
        color: #6b97a5;
        font-weight: bold;

        @media (max-width: 475px) {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
    }
    @media (max-width: 475px) {
        width: 100%;
    }
`

export const PricePayment = styled.div`
    padding: 17px;
    margin-top: 0px;
    margin-bottom: 50px;
    border: solid 1px black;
    border-radius: 7px;
    padding-left: 28px;
    font-family: 'Poppins', semi-bold;
    display: flex;

    .organizer {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        .stylesTitlePechincha {
            margin-top: 0;
        }
        .sell-type-buttons-container {
            .sell-type-container {
                display: flex;
                flex-direction: row;
                border-radius: 8px;
                border: solid 1px black;
                margin-bottom: 25px;
                width: 100%;
                height: 40px;
                div {
                    width: 100%;
                    border: none;
                    background-color: #fff;
                    border-radius: 8px;
                    color: #6b97a5;
                    font-family: 'Poppins', semi-bold;
                    font-weight: bold;
                    font-size: 13px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    &:hover {
                        cursor: pointer;
                    }
                }

                .button-sell-active {
                    width: 100%;
                    color: white;
                    border: none;
                    border-radius: 8px;
                    font-family: 'Poppins', semi-bold;
                    font-weight: bold;
                    font-size: 13px;
                    background-color: #6b97a5;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                }
                .bargain-active {
                    width: 100%;
                    color: white;
                    border: none;
                    border-radius: 8px;
                    font-family: 'Poppins', semi-bold;
                    font-weight: bold;
                    font-size: 13px;
                    background-color: #6b97a5;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                }
                @media (max-width: 475px) {
                    width: 100%;
                }
            }
            @media (max-width: 475px) {
                width: 100%;
            }
        }
        .sell-options {
            display: flex;
            flex-direction: row;
            gap: 20px;
            .inputs-organizer {
                display: flex;
                flex-direction: column;
                border: solid 1px black;
                padding: 4px;
                border-radius: 8px;

                label {
                    color: #6b97a5;
                    font-family: 'Poppins', semi-bold;
                    font-weight: bold;
                    font-size: 12px;
                }

                input {
                    border: none;
                    color: black;
                    font-size: 13px;
                    font-family: 'Poppins', semi-bold;
                    background-color: #fff;
                }
                input:focus {
                    outline: none;
                }
                input:focus::-webkit-input-placeholder {
                    color: transparent;
                }
                @media (max-width: 475px) {
                    width: 100%;
                }
            }
            @media (max-width: 475px) {
                flex-direction: column;
                width: 100%;
            }
        }
        @media (max-width: 475px) {
            width: 100%;
        }
    }
    @media (max-width: 475px) {
        width: 100%;
    }
`
export const InputOrganizer = styled.div<inputProps>`
    display: flex;
    flex-direction: column;
    border: solid 1px ${(Prop) => Prop.borderColor};
    padding: 4px;
    border-radius: 8px;
    margin-bottom: 25px;
    width: 100%;
    height: 51px;

    span {
        display: flex;
        left: auto;
        flex-direction: row;
        color: #ff0000;
        margin-top: 0.3rem;
        font-size: 14px;
        font-family: 'Poppins', semi-bold;
    }

    .inputBargain {
        display: none;
    }

    .field-info {
        background-color: #fff;
        border: solid 1px black;
        position: absolute;
        border-radius: 8px;
        padding: 0.8rem;
        color: #6b97a5;
        font-size: 13px;
        font-family: 'Poppins', semi-bold;
        width: 20rem;
        z-index: 1000;
        top: 55px;
        left: -1px;
        display: none;
    }
    .input-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .input-container .icon {
        fill: black;
    }

    .input-container .icon:hover + .field-info {
        display: initial;
    }

    label {
        color: #6b97a5;
        font-family: 'Poppins', semi-bold;
        font-weight: bold;
        font-size: 12px;
    }

    input {
        border: none;
        color: black;
        font-size: 13px;
        font-family: 'Poppins', semi-bold;
        background-color: #fff;
    }
    input:focus {
        outline: none;
    }
    input:focus::-webkit-input-placeholder {
        color: transparent;
    }
    select {
        border: none;
        color: black;
        font-size: 13px;
        font-family: 'Poppins', semi-bold;
        background-color: #fff;
    }
    select:focus {
        outline: none;
    }
    @media (max-width: 475px) {
        width: 100%;
    }
`

export const PlanTitleContainer = styled.div`
    display: flex;
    flex-direction: column;

    h2 {
        font-family: 'Poppins', semi-bold;
        font-size: 18px;
        color: #6b97a5;
        font-weight: bold;
        @media (min-width: 320px) and (max-width: 475px) {
            color: #6b97a5;
            font-family: 'Poppins', semi-bold;
            font-weight: bold;
            font-size: 18px;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            padding-top: 1rem;
        }
    }
`

export const Plan = styled.div`
    margin-top: 0px;
    border-radius: 7px;
    font-family: 'Poppins', semi-bold;

    .img-container {
        display: flex;
        flex-direction: column;
        span {
            margin-bottom: 24px;
            width: 100%;
            border: solid 1px #c7c7c7;
            border-radius: 8px;
            h1 {
                font-size: 28px;
                color: #69b54a;
                font-weight: 300;
                font-family: 'Poppins', Extra-light;
                padding: 15px 20px 0px;
            }
            .type {
                font-size: 15px;
                font-family: 'Poppins';
                font-weight: bold;
                color: #6b97a5;
                padding: 5px 20px;
                border-bottom: solid 1px #9e9e9e;
            }

            .condition {
                color: #6b97a5;
                font-size: 13px;
                font-family: 'Poppins', semi-bold;
                font-weight: bold;
                margin-top: 5px;
                padding: 0px 20px 0px;
            }

            .description {
                font-family: 'Poppins', semi-bold;
                color: black;
                font-size: 13px;
                padding: 20px;
                padding-top: 0px;
            }
        }
        .active {
            margin-bottom: 24px;
            width: 100%;
            border: solid 2px #3398cb;
            border-radius: 8px;
            h1 {
                font-size: 28px;
                color: #69b54a;
                font-weight: 300;
                font-family: 'Poppins', Extra-light;
                padding: 15px 20px 0px;
            }
            .type {
                font-size: 15px;
                font-family: 'Poppins';
                font-weight: bold;
                color: #6b97a5;
                padding: 5px 20px;
                border-bottom: solid 1px #9e9e9e;
            }

            .condition {
                color: #6b97a5;
                font-size: 13px;
                font-family: 'Poppins', semi-bold;
                font-weight: bold;
                margin-top: 5px;
                padding: 0px 20px 0px;
            }

            .description {
                font-family: 'Poppins', semi-bold;
                color: black;
                font-size: 13px;
                padding: 20px;
                padding-top: 0px;
            }
        }
        .active:hover,
        span:hover {
            cursor: pointer;
        }
    }

    @media (min-width: 320px) and (max-width: 475px) {
        margin-top: 0px;
        border-radius: 7px;
        font-family: 'Poppins', semi-bold;

        .img-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;

            span {
                margin-bottom: 24px;
                border: solid 1px #c7c7c7;
                border-radius: 8px;
                h1 {
                    font-size: 28px;
                    color: #69b54a;
                    font-weight: 300;
                    font-family: 'Poppins', Extra-light;
                    padding: 15px 20px 0px;
                }
                .type {
                    font-size: 15px;
                    font-family: 'Poppins';
                    font-weight: bold;
                    color: #6b97a5;
                    padding: 5px 20px;
                    border-bottom: solid 1px #9e9e9e;
                }
                .condition {
                    color: #6b97a5;
                    font-size: 13px;
                    font-family: 'Poppins', semi-bold;
                    font-weight: bold;
                    margin-top: 5px;
                    padding: 0px 20px 0px;
                }
                .description {
                    font-family: 'Poppins', semi-bold;
                    color: black;
                    font-size: 13px;
                    padding: 20px;
                    padding-top: 0px;
                }
            }
            .active {
                margin-bottom: 24px;
                width: 325px;
                margin-right: 35px;
                border: solid 2px #3398cb;
                border-radius: 8px;
                margin-right: 0;
                margin-bottom: 0;
                h1 {
                    font-size: 28px;
                    color: #69b54a;
                    font-weight: 300;
                    font-family: 'Poppins', Extra-light;
                    padding: 15px 20px 0px;
                }
                .type {
                    font-size: 15px;
                    font-family: 'Poppins';
                    font-weight: bold;
                    color: #6b97a5;
                    padding: 5px 20px;
                    border-bottom: solid 1px #9e9e9e;
                }

                .condition {
                    color: #6b97a5;
                    font-size: 13px;
                    font-family: 'Poppins', semi-bold;
                    font-weight: bold;
                    margin-top: 5px;
                    padding: 0px 20px 0px;
                }

                .description {
                    font-family: 'Poppins', semi-bold;
                    color: black;
                    font-size: 13px;
                    padding: 20px;
                    padding-top: 0px;
                }
            }
            .active:hover,
            span:hover {
                cursor: pointer;
            }
        }
    }
    @media (min-width: 375px) and (max-width: 424px) {
        margin-top: 0px;
        border-radius: 7px;
        font-family: 'Poppins', semi-bold;

        .img-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;

            span {
                margin-bottom: 24px;
                border: solid 1px #c7c7c7;
                border-radius: 8px;
                h1 {
                    font-size: 28px;
                    color: #69b54a;
                    font-weight: 300;
                    font-family: 'Poppins', Extra-light;
                    padding: 15px 20px 0px;
                }
                .type {
                    font-size: 15px;
                    font-family: 'Poppins';
                    font-weight: bold;
                    color: #6b97a5;
                    padding: 5px 20px;
                    border-bottom: solid 1px #9e9e9e;
                }
                .condition {
                    color: #6b97a5;
                    font-size: 13px;
                    font-family: 'Poppins', semi-bold;
                    font-weight: bold;
                    margin-top: 5px;
                    padding: 0px 20px 0px;
                }
                .description {
                    font-family: 'Poppins', semi-bold;
                    color: black;
                    font-size: 13px;
                    padding: 20px;
                    padding-top: 0px;
                }
            }
            .active {
                margin-bottom: 24px;
                width: 325px;
                margin-right: 35px;
                border: solid 2px #3398cb;
                border-radius: 8px;
                margin-right: 0;
                margin-bottom: 0;
                h1 {
                    font-size: 28px;
                    color: #69b54a;
                    font-weight: 300;
                    font-family: 'Poppins', Extra-light;
                    padding: 15px 20px 0px;
                }
                .type {
                    font-size: 15px;
                    font-family: 'Poppins';
                    font-weight: bold;
                    color: #6b97a5;
                    padding: 5px 20px;
                    border-bottom: solid 1px #9e9e9e;
                }

                .condition {
                    color: #6b97a5;
                    font-size: 13px;
                    font-family: 'Poppins', semi-bold;
                    font-weight: bold;
                    margin-top: 5px;
                    padding: 0px 20px 0px;
                }

                .description {
                    font-family: 'Poppins', semi-bold;
                    color: black;
                    font-size: 13px;
                    padding: 20px;
                    padding-top: 0px;
                }
            }
            .active:hover,
            span:hover {
                cursor: pointer;
            }
        }
    }

    @media (min-width: 320px) and (max-width: 374px) {
        margin-top: 0px;
        border-radius: 7px;
        font-family: 'Poppins', semi-bold;

        .img-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;

            span {
                margin-bottom: 24px;
                border: solid 1px #c7c7c7;
                border-radius: 8px;
                h1 {
                    font-size: 28px;
                    color: #69b54a;
                    font-weight: 300;
                    font-family: 'Poppins', Extra-light;
                    padding: 15px 20px 0px;
                }
                .type {
                    font-size: 15px;
                    font-family: 'Poppins';
                    font-weight: bold;
                    color: #6b97a5;
                    padding: 5px 20px;
                    border-bottom: solid 1px #9e9e9e;
                }
                .condition {
                    color: #6b97a5;
                    font-size: 13px;
                    font-family: 'Poppins', semi-bold;
                    font-weight: bold;
                    margin-top: 5px;
                    padding: 0px 20px 0px;
                }
                .description {
                    font-family: 'Poppins', semi-bold;
                    color: black;
                    font-size: 13px;
                    padding: 20px;
                    padding-top: 0px;
                }
            }
            .active {
                margin-bottom: 24px;
                width: 250px;
                margin-right: 35px;
                border: solid 2px #3398cb;
                border-radius: 8px;
                margin-right: 0;
                margin-bottom: 0;
                h1 {
                    font-size: 28px;
                    color: #69b54a;
                    font-weight: 300;
                    font-family: 'Poppins', Extra-light;
                    padding: 15px 20px 0px;
                }
                .type {
                    font-size: 15px;
                    font-family: 'Poppins';
                    font-weight: bold;
                    color: #6b97a5;
                    padding: 5px 20px;
                    border-bottom: solid 1px #9e9e9e;
                }

                .condition {
                    color: #6b97a5;
                    font-size: 13px;
                    font-family: 'Poppins', semi-bold;
                    font-weight: bold;
                    margin-top: 5px;
                    padding: 0px 20px 0px;
                }

                .description {
                    font-family: 'Poppins', semi-bold;
                    color: black;
                    font-size: 13px;
                    padding: 20px;
                    padding-top: 0px;
                }
            }
            .active:hover,
            span:hover {
                cursor: pointer;
            }
        }
    }
`

export const BtnConfirm = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    width: 100%;
    margin-top: 10px;

    .confirm-button {
        border: none;
        background-color: #6b97a5;
        border-radius: 8px;
        color: white;
        font-family: 'Poppins', semi-bold;
        font-weight: bold;
        font-size: 13px;
        width: 157px;
        height: 40px;
        text-align: center;
    }
    .delete-button {
        margin-right: 20px;
        width: 157px;
        height: 40px;
        border: solid 1px black;
        border-radius: 8px;
        color: #6b97a5;
        font-family: 'Poppins', semi-bold;
        font-weight: bold;
        font-size: 13px;
        background-color: white;
    }
    .delete-button:hover,
    .confirm-button {
        cursor: pointer;
    }

    .delete-button:focus,
    .confirm-button {
        outline: none;
    }
    @media (max-width: 475px) {
        width: 100%;
    }
`
export const DeliveryInformations = styled.div`
    display: flex;
    flex-direction: column;

    h1 {
        display: flex;
        flex-direction: row;
        font-size: 13px;
        color: #6b97a5;
        font-family: 'Poppins', semi-bold;
        font-weight: bold;
        @media (max-width: 475px) {
            font-size: 14px;
        }
    }
    span {
        display: flex;
        flex-direction: row;
        color: #000000;
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 1rem;
        font-family: 'Poppins', semi-bold;
    }
    .other-container {
        display: flex;
        flex-direction: row;
        align-content: space-between;
        margin-bottom: 25px;

        .inputs-organizer-brand {
            display: flex;
            flex-direction: column;
            border: solid 1px black;
            padding: 4px;
            border-radius: 8px;
            width: 100%;
            height: 51px;
            @media (max-width: 475px) {
                width: 100%;
            }
            label {
                color: #6b97a5;
                font-family: 'Poppins', semi-bold;
                font-weight: bold;
                font-size: 12px;
                @media (max-width: 475px) {
                    font-size: 14px;
                }
            }

            input {
                border: none;
                color: black;
                font-size: 13px;
                font-family: 'Poppins', semi-bold;
                background-color: #fff;
            }
            input:focus {
                outline: none;
            }
            input:focus::-webkit-input-placeholder {
                color: transparent;
            }
            select {
                border: none;
                color: black;
                font-size: 13px;
                font-family: 'Poppins', semi-bold;
                background-color: #fff;
            }
            select:focus {
                outline: none;
            }
        }
        .inputs-organizer-size {
            display: flex;
            flex-direction: column;
            border: solid 1px black;
            padding: 4px;
            border-radius: 8px;
            width: 87px;
            height: 51px;

            label {
                color: #6b97a5;
                font-family: 'Poppins', semi-bold;
                font-weight: bold;
                font-size: 12px;
                height: 19px;
                @media (max-width: 475px) {
                    font-size: 14px;
                }
            }

            input {
                border: none;
                color: black;
                font-size: 13px;
                font-family: 'Poppins', semi-bold;
            }
            input:focus {
                outline: none;
            }
            input:focus::-webkit-input-placeholder {
                color: transparent;
            }
        }
        .inputs-organizer-material {
            display: flex;
            flex-direction: column;
            border: solid 1px black;
            padding: 4px;
            border-radius: 8px;
            width: 100%;
            height: 51px;
            @media (max-width: 475px) {
                width: 100%;
            }

            label {
                color: #6b97a5;
                font-family: 'Poppins', semi-bold;
                font-weight: bold;
                font-size: 12px;
                @media (max-width: 475px) {
                    font-size: 14px;
                }
            }

            input {
                border: none;
                color: black;
                font-size: 13px;
                font-family: 'Poppins', semi-bold;
                background-color: #fff;
            }
            input:focus {
                outline: none;
            }
            input:focus::-webkit-input-placeholder {
                color: transparent;
            }
            select {
                border: none;
                color: black;
                font-size: 13px;
                font-family: 'Poppins', semi-bold;
                background-color: #fff;
            }
            select:focus {
                outline: none;
            }
        }
        @media (max-width: 475px) {
            flex-direction: column;
            width: 100%;
            gap: 25px;
        }
    }
`

export const InputOrganizerBrand = styled.div<inputProps>`
    display: flex;
    flex-direction: column;
    border: solid 1px ${(Prop) => Prop.borderColor};
    padding: 4px;
    border-radius: 8px;
    width: 100%;
    height: 51px;
    @media (max-width: 475px) {
        width: 100%;
    }
    label {
        color: #6b97a5;
        font-family: 'Poppins', semi-bold;
        font-weight: bold;
        font-size: 12px;
        @media (max-width: 475px) {
            font-size: 14px;
        }
    }
    input {
        border: none;
        color: black;
        font-size: 13px;
        font-family: 'Poppins', semi-bold;
        background-color: #fff;
    }
    input:focus {
        outline: none;
    }
    input:focus::-webkit-input-placeholder {
        color: transparent;
    }
    select {
        border: none;
        color: #6b97a5;
        font-size: 13px;
        font-family: 'Poppins', semi-bold;
        background-color: #fff;
    }
    select:focus {
        outline: none;
    }
`

export const InputOrganizerMaterial = styled.div<inputProps>`
    display: flex;
    flex-direction: column;
    border: solid 1px ${(Prop) => Prop.borderColor};
    padding: 4px;
    border-radius: 8px;
    width: 100%;
    height: 51px;
    margin-bottom:1.5rem;
    @media (max-width: 475px) {
        width: 100%;
    }

    label {
        color: #6b97a5;
        font-family: 'Poppins', semi-bold;
        font-weight: bold;
        font-size: 12px;
        @media (max-width: 475px) {
            font-size: 14px;
        }
    }

    input {
        border: none;
        color: black;
        font-size: 13px;
        font-family: 'Poppins', semi-bold;
        background-color: #fff;
    }
    input:focus {
        outline: none;
    }
    input:focus::-webkit-input-placeholder {
        color: transparent;
    }
    select {
        border: none;
        color: black;
        font-size: 13px;
        font-family: 'Poppins', semi-bold;
        background-color: #fff;
    }
    select:focus {
        outline: none;
    }
`
