import styled from 'styled-components'

export const ProductContainer = styled.div`
    width: 100%;
    height: 600px;
    background-color: #fff;

    .alice-carousel__slide-info {
        color: #6085db;
        border-radius: 3px;
        background-color: #ffffff;
        font-family: 'Poppins', regular;
        font-weight: bold;
        box-shadow: 0px 3px 6px #00000029;
    }

    .row {
        display: grid;
        grid-template-columns: 2fr 2fr 5fr 3fr 2fr 2fr;
        height: 600px;
        column-gap: 30px;

        @media (min-width: 320px) and (max-width: 475px) {
            column-gap: 5px;
            display: flex;
            flex-direction: column;
        }
        .report-link {
            grid-column-start: 4;
            grid-column-end: 4;
            grid-row-start: 5;
            grid-row-end: 5;
            align-self: center;
            justify-self: flex-end;
            width: 312px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            color: #3e69ae;
            text-decoration: underline;
            font-family: 'Poppins';
            font-weight: 400;
            height: 76px;
            width: 100%;

            &:hover {
                cursor: pointer;
            }
        }

        .images-container {
            grid-column-start: 3;
            grid-column-end: 3;
            grid-row-start: 2;
            grid-row-end: 5;
            display: flex;
            flex-direction: row-reverse;
            justify-content: start;
            height: 100%;
            width: 100%;
            min-width: 615px;

            @media (min-width: 425px) and (max-width: 768px) {
                max-width: 425px;
                min-width: 425px;
            }
            @media (min-width: 375px) and (max-width: 424px) {
                max-width: 375px;
                min-width: 375px;
            }
            @media (min-width: 320px) and (max-width: 374px) {
                max-width: 320px;
                min-width: 320px;
            }

            .mobile-carousel-item {
                display: block;
                max-height: 220px;
                width: auto;
                margin: 1rem;
                margin-left: auto;
                margin-right: auto;
                border-radius: 0.25rem;
                box-shadow: 0px 3px 6px #00000029;
            }

            .big-img-container {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;

                .big-img {
                    max-width: 544px;
                    max-height: 512px;
                    margin-left: 0.5rem;
                    margin-right: 0.5rem;
                    border-radius: 8px;

                    @media (min-width: 425px) and (max-width: 475px) {
                        width: auto;
                        margin-left: 0;
                        max-height: 280px;
                    }
                    @media (min-width: 375px) and (max-width: 424px) {
                        width: auto;
                        margin-left: 0;
                        max-height: 220px;
                    }
                    @media (min-width: 320px) and (max-width: 374px) {
                        width: auto;
                        margin-left: 0;
                        max-height: 180px;
                    }
                }
            }

            .images-container-vertical {
                display: flex;
                flex-direction: column;
                height: 100%;

                .images-row {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;

                    .more-imgs {
                        width: 76px;
                        height: 65px;
                        background-color: #c9c9c9;
                        border-radius: 8px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        color: #7e7e7e;
                        font-size: 22px;
                        font-family: 'Poppins', regular;
                    }
                    .small-img {
                        margin-right: 5px;
                        border-radius: 8px;

                        padding: 2px;
                        width: 76px;
                        height: 65px;
                        margin-bottom: 6px;
                        object-fit: cover;

                        @media screen and (max-width: 1366px) {
                            width: 76px;
                            height: 65px;
                            margin-right: 10px;
                            border-radius: 8px;

                            padding: 2px;
                        }
                    }
                }
            }
        }

        .state-content {
            grid-column-start: 4;
            grid-column-end: 4;
            grid-row-start: 2;
            grid-row-end: 2;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 3px 2px 9px #00000014;
            border-radius: 8px;
            padding: 22px;
            border-bottom: solid 10px #6085db;
            padding-bottom: 0px;
            padding-top: 8px;
            margin-bottom: 15px;
            height: 100%;

            span {
                color: #6b98a6;
                font-family: 'Poppins';
                font-weight: bold;
                font-size: 12px;

                @media screen and (max-width: 1366px) {
                    font-size: 14px;
                }
            }
            .price-content {
                height: 20%;
                h2 {
                    font-family: 'Poppins', semi-bold;
                    font-weight: 600;
                    font-size: 24px;
                    color: #383c3e;
                    font-weight: 600;
                    margin: 0;

                    @media screen and (max-width: 1366px) {
                        font-size: 24px;
                    }
                }
                p {
                    font-family: 'Poppins';
                    margin-left: 6px;
                    color: black;
                    font-size: 12px;
                }
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            p {
                font-family: 'Poppins', regular;
                margin-left: 6px;
                color: black;
            }

            @media (min-width: 425px) and (max-width: 475px) {
                padding-bottom: 0px;
                padding-top: 8px;
                margin-bottom: 0px;
                height: 40%;
                width: 100%;
                margin-left: 0;
                max-width: 425px;
            }
            @media (min-width: 375px) and (max-width: 424px) {
                padding-bottom: 0px;
                padding-top: 8px;
                margin-bottom: 0px;
                height: 40%;
                width: 100%;
                margin-left: 0;
                max-width: 375px;
            }
            @media (min-width: 320px) and (max-width: 374px) {
                padding-bottom: 0px;
                padding-top: 8px;
                margin-bottom: 0px;
                width: 100%;
                margin-left: 0;
                max-width: 320px;
            }
        }
        .state-content-promo {
            grid-column-start: 4;
            grid-column-end: 4;
            grid-row-start: 2;
            grid-row-end: 2;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 3px 2px 9px #00000014;
            border-radius: 8px;
            padding: 22px;
            border-bottom: solid 10px #6085db;
            padding-bottom: 0px;
            padding-top: 8px;
            margin-bottom: 15px;
            height: 100%;
            border-right: 1px solid black;
            border-top: 1px solid black;
            border-left: 1px solid black;
            background: #fff;
            .state-content-promo-header {
                color: #6b98a6;
                font-family: 'Poppins';
                font-weight: bold;
                font-size: 12px;
                width: 100%;

                @media screen and (max-width: 1366px) {
                    font-size: 13px;
                }
            }
            p {
                color: #6b98a6;
                font-family: 'Poppins';
                font-weight: bold;
                font-size: 12px;
                width: 70%;

                @media screen and (max-width: 1366px) {
                    font-size: 13px;
                }
            }
            .price-content {
                h2 {
                    font-family: 'Poppins', semi-bold;
                    font-weight: 600;
                    font-size: 30px;
                    color: #383c3e;
                    font-weight: 600;
                    margin: 0;

                    @media screen and (max-width: 1366px) {
                        font-size: 24px;
                    }
                }

                p {
                    color: #6b98a6;
                    font-family: 'Poppins';
                    font-weight: bold;
                    font-size: 12px;
                    width: 70%;
                }
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            p {
                font-family: 'Poppins';
                color: black;
            }

            @media (min-width: 425px) and (max-width: 475px) {
                padding-bottom: 0px;
                padding-top: 8px;
                margin-bottom: 0px;
                height: 30%;
                width: 100%;
                margin-left: 0;
                max-width: 425px;
                padding-bottom: 10px;
            }
            @media (min-width: 375px) and (max-width: 424px) {
                padding-bottom: 0px;
                padding-top: 8px;
                margin-bottom: 0px;
                height: 30%;
                width: 100%;
                margin-left: 0;
                max-width: 375px;
                padding-bottom: 10px;
            }
            @media (min-width: 320px) and (max-width: 374px) {
                padding-bottom: 0px;
                padding-top: 8px;
                margin-bottom: 0px;
                width: 100%;
                margin-left: 0;
                max-width: 320px;
                padding-bottom: 10px;
            }
        }
        .state-content-sale {
            grid-column-start: 4;
            grid-column-end: 4;
            grid-row-start: 2;
            grid-row-end: 2;
            background: #fff 0% 0% no-repeat padding-box;
            box-shadow: 3px 2px 9px #00000014;
            border-radius: 8px;
            padding: 22px;
            border-bottom: solid 10px #69b64a;
            padding-bottom: 0px;
            padding-top: 8px;
            margin-bottom: 15px;
            height: 100%;
            border-right: 1px solid black;
            border-left: 1px solid black;
            border-top: 1px solid black;
            .state-content-sale-header {
                color: #6b98a6;
                font-family: 'Poppins';
                font-weight: 500;
                font-size: 14px;
                width: 100%;

                @media screen and (max-width: 1366px) {
                    font-size: 13px;
                }
            }
            .price-content {
                h2 {
                    font-family: 'Poppins', semi-bold;
                    font-weight: 600;
                    font-size: 30px;
                    color: #383c3e;
                    font-weight: 600;
                    margin: 0;

                    @media screen and (max-width: 1366px) {
                        font-size: 24px;
                    }
                }
                p {
                    color: #6b98a6;
                    font-family: 'Poppins';
                    font-weight: 500;
                    font-size: 14px;
                    width: 70%;
                }
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            p {
                font-family: 'Poppins', regular;
                color: black;
            }

            @media (min-width: 425px) and (max-width: 475px) {
                padding-bottom: 0px;
                padding-top: 8px;
                margin-bottom: 0px;
                height: 100%;
                width: 100%;
                margin-left: 0;
                max-width: 425px;
            }
            @media (min-width: 375px) and (max-width: 424px) {
                padding-bottom: 0px;
                padding-top: 8px;
                margin-bottom: 0px;
                height: 100%;
                width: 100%;
                margin-left: 0;
                max-width: 375px;
            }
            @media (min-width: 320px) and (max-width: 374px) {
                padding-bottom: 0px;
                padding-top: 8px;
                margin-bottom: 0px;
                width: 100%;
                height: 100%;
                margin-left: 0;
                max-width: 320px;
            }
        }

        .content {
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 3px 2px 9px #00000014;
            border-radius: 8px;
            padding: 22px;
            border-bottom: solid 10px #ff7e0f;

            padding-bottom: 0px;
            padding-top: 8px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: max-content;
            margin-bottom: 10px;

            h3 {
                margin: 0;
                margin-bottom: 8px;
                font-size: 15px;
                color: #6085db;
                font-family: 'Poppins';
                font-weight: bold;
            }
            h1 {
                margin: 0;
                margin-bottom: 8px;
                color: #6085db;
                font-family: 'Poppins', semi-bold;
                font-weight: 600;
                font-size: 30px;
            }
            h2 {
                margin: 0;
                margin-bottom: 10px;
                color: #ff7e0f;
                font-family: 'Poppins', semi-bold;
                font-weight: 600;
                font-size: 16px;
            }
        }

        .name-content {
            grid-column-start: 4;
            grid-column-end: 4;
            grid-row-start: 4;
            grid-row-end: 4;
            display: flex;
            flex-direction: column;
            background: #fff 0% 0% no-repeat padding-box;
            box-shadow: 3px 2px 9px #00000014;
            border-radius: 8px;
            padding: 16px;
            width: 100%;
            border: 1px solid black;
            margin-top: 1rem;

            @media (min-width: 425px) and (max-width: 475px) {
                width: 100%;
                margin-left: 0;
            }

            @media (min-width: 375px) and (max-width: 424px) {
                width: 100%;
                margin-left: 0;
            }

            @media (min-width: 320px) and (max-width: 374px) {
                width: 100%;
                margin-left: 0;
            }

            justify-content: center;
            .name-padding {
                display: flex;
                flex-direction: row;
                justify-content: center;
                justify-content: start;
                align-items: center;
                margin-bottom: 15px;
                padding: 16px;
                padding-top: 9px;
                width: 100%;
                border-bottom: solid 1px black;
                .name-content-int {
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    h2 {
                        margin-bottom: 3px;
                        margin-left: 5px;
                        margin-top: 0;
                        color: #6b98a6;
                        font-family: 'Poppins';
                        font-weight: 500;
                        font-size: 18px;

                        @media screen and (max-width: 1366px) {
                            font-size: 18px;
                        }
                    }
                    span {
                        margin-left: 5px;
                        color: #6b98a6;
                        font-family: 'Poppins', semi-bold;
                        font-size: 14px;
                        font-weight: 500;
                    }
                    .go-to {
                        color: #3e69ae;
                        font-size: 14px;
                        font-family: 'Poppins', regular;
                        text-decoration: underline;
                        margin-left: 5px;
                    }
                }
            }
            .padding {
                display: flex;
                flex-direction: column;
                justify-content: center;
                justify-content: center;
                align-items: center;

                h2 {
                    color: #6085db;
                    font-size: 15px;
                    font-family: 'Poppins';
                    font-weight: bold;
                    margin: 0;
                }
                h1 {
                    color: #6085db;
                    font-size: 35px;
                    font-family: 'Poppins';
                    font-weight: 600;
                    margin: 0;
                }
            }

            .action-buttons-container {
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-align-self: center;
                -ms-flex-item-align: center;
                align-self: center;
                width: 100%;
                -webkit-align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                /* padding: 16px; */
                padding-left: 16px;
                padding-right: 16px;
                margin-bottom: 10px;
                .button-organizer-chat {
                    display: flex;
                    flex-direction: row;
                    background-color: #6b98a6;

                    align-items: center;
                    justify-content: center;

                    border-radius: 6px;
                    width: 256px;
                    height: 44px;
                    img {
                        margin-right: 3px;
                        width: 20px;
                        height: 20px;
                    }
                    span {
                        color: #ffffff;
                        font-family: 'Poppins';
                        font-weight: bold;
                        font-size: 19px;

                        @media screen and (max-width: 1366px) {
                            font-size: 19px;
                        }
                    }
                }
                .disabled {
                    background-color: #c9c9c9;
                    border: 1px solid black;
                }
                div {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    margin-top: 10px;
                    margin-bottom: 4px;
                    width: 256px;
                    height: 44px;
                    .button-organizer-cart {
                        display: flex;
                        flex-direction: row;
                        background-color: #69b64a;
                        align-items: center;
                        justify-content: center;
                        width: 215px;
                        height: 44px;
                        border-radius: 6px;
                        border: 1px solid black;

                        img {
                            margin-right: 3px;
                            width: 20px;
                            height: 20px;
                        }
                        span {
                            font-family: 'Poppins';
                            font-weight: bold;
                            color: #ffffff;
                            font-size: 19px;

                            @media screen and (max-width: 1366px) {
                                font-size: 19px;
                            }
                        }
                    }
                    .disabled {
                        background-color: #c9c9c9;
                    }
                    .button-add-item {
                        width: 54px;
                        height: 44px;
                        border: 2px solid #69b64a;
                        border-radius: 6px;
                        margin-right: 8px;
                        svg {
                            .a {
                                fill: #69b64a;
                            }
                        }
                    }
                    .disabled-add-item {
                        border: solid 2px #c9c9c9;
                        svg {
                            .a {
                                fill: #c9c9c9;
                            }
                        }
                    }
                    .active {
                        background: #69b64a;

                        svg {
                            .a {
                                fill: white;
                            }
                        }
                    }
                    .button-add-item:hover {
                        cursor: pointer;
                    }
                }

                .button-organizer-cart:hover,
                .button-organizer-dim:hover,
                .button-organizer-eye:hover,
                .button-organizer-chat:hover {
                    cursor: pointer;
                }

                .button-organizer-eye {
                    display: flex;
                    flex-direction: row;
                    background-color: #6085db;
                    margin-top: 25px;
                    align-items: center;
                    justify-content: center;
                    height: 41px;
                    width: 86%;
                    border-radius: 6px;
                    padding: 18px;

                    img {
                        margin-right: 3px;
                        width: 20px;
                        height: 20px;
                    }
                    span {
                        font-family: 'Poppins';
                        font-weight: bold;
                        color: #ffffff;
                        font-size: 25px;
                        @media screen and (max-width: 1366px) {
                            font-size: 11px;
                        }
                    }
                }
                .contact-seller-button-container {
                    button {
                        width: 241px;
                        height: 44px;
                        background: #6b98a6 0% 0% no-repeat padding-box;
                        border-radius: 6px;
                        font-family: 'Poppins';
                        font-weight: bold;
                        font-size: 14px;
                        border: none;
                        color: white;
                        cursor: pointer;
                    }
                    button:focus {
                        outline: none;
                    }
                }
            }
        }
        .button-organizer-dim {
            display: flex;
            flex-direction: row;
            background-color: #ff7e0f;

            align-items: center;
            justify-content: center;
            height: 48px;
            width: 100%;
            border-radius: 6px;
            padding: 18px;
            height: 52px;

            img {
                margin-right: 3px;
                width: 20px;
                height: 20px;
            }
            span {
                font-family: 'Poppins';
                font-weight: bold;
                color: #ffffff;
                font-size: 14px;
                @media screen and (max-width: 1366px) {
                    font-size: 14px;
                }
            }
        }
        .disabled {
            display: flex;
            flex-direction: row;
            background-color: #e1e1e1;
        }

        .button-organizer-buy-now {
            display: flex;
            flex-direction: row;
            background-color: #6085db;

            align-items: center;
            justify-content: center;
            height: 48px;
            width: 100%;
            border-radius: 6px;
            padding: 18px;
            height: 52px;

            img {
                margin-right: 3px;
                width: 20px;
                height: 20px;
            }
            span {
                font-family: 'Poppins';
                font-weight: bold;
                color: #ffffff;
                font-size: 14px;
                @media screen and (max-width: 1366px) {
                    font-size: 14px;
                }
            }
        }

        .adm-buttons {
            grid-column-start: 5;
            grid-column-end: 5;
            grid-row-start: 2;
            display: flex;
            flex-direction: column;
            width: 180px;
            height: max-content;

            button {
                width: 181px;
                height: 52px;
                border: none;
                &:focus {
                    outline: none;
                }
                &:hover {
                    cursor: pointer;
                }
            }
            .block-seller {
                margin-bottom: 15px;
                background: var(--unnamed-color-ca667e) 0% 0% no-repeat
                    padding-box;
                background: #ca667e 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 12px;
                color: white;
                font-family: 'Poppins';
                font-weight: bold;
                font-size: 15px;
            }
            .block-product {
                background: #fff 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border: 1px solid #ca667e;
                border-radius: 12px;
                color: #ca667e;
                font-family: 'Poppins';
                font-weight: bold;
                font-size: 15px;
            }
        }
    }

    .ui .page .modals .dimmer .transition .visible .active {
        padding: 0px;
    }
    #div-image-modal {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        img:hover {
            cursor: pointer;
        }
    }
    #image-modal {
        background: transparent;
        border: none;
        box-shadow: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
        }
    }
`
export const Timer = styled.div`
    width: 100%;
    height: 120%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
    .counter {
        width: 1010px;
        height: 84px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 3px 2px 9px #00000014;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: 30px;
        align-items: center;

        img {
            margin-right: 15px;
        }

        div {
            display: flex;
            flex-direction: column;
            span {
                font-family: 'Poppins';
                font-weight: 600;
                color: #6085db;
                font-size: 14px;
            }
            div {
                display: flex;
                flex-direction: row;
                margin-top: 18px;
                span {
                    font-family: 'Poppins';
                    font-weight: 600;
                    color: #6085db;
                    font-size: 27px;
                }
            }
        }
    }
`

export const ShippingsModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    .shipping-header {
        width: 100%;
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
        background-color: #04aac0;
        height: 53px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        h2 {
            margin-left: 2rem;
            color: #ffffff;
            font-family: 'Poppins', semi-bold;
            font-weight: 600;
            font-size: 18px;
        }

        .close-modal {
            margin-right: 1rem;
        }
    }
    .shipping-list {
        display: flex;
        flex-direction: column;
        margin: 1rem;
        padding: 1rem;
        .shipping-item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-content: center;
            padding: 1rem;
            border-bottom: solid gainsboro 1px;
            @media (min-width: 375px) and (max-width: 768px) {
                margin-top: 0.25rem;
                padding: 0.25rem;
            }
            .shipping-service {
                display: flex;
                flex-direction: row;
                align-items: center;
                @media (min-width: 375px) and (max-width: 768px) {
                    flex-direction: column;
                    align-items: flex-start;
                }
                .shipping-company {
                    max-width: 8rem;
                    max-height: 2rem;
                    width: auto;
                    height: auto;
                }
                .shipping-carrier {
                    margin: 0.25rem;
                    font-size: 1.25rem;
                }
                .shipping-name {
                    padding: 0.25rem;
                    margin: 0.5rem;
                    background-color: #6b98a6;
                    color: white;
                    font-family: 'Poppins', bold;
                    border-radius: 0.25rem;
                }
            }

            .shipping-price {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                h5 {
                    font-family: 'Poppins', bold;
                }
                span {
                    font-family: 'Poppins', medium;
                }
            }
        }
        .shipping-item:last-child {
            border-bottom: none;
        }
    }
`

export const DeliveryContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-row-start: 5;
    grid-row-end: 5;
    grid-column-start: 3;
    grid-column-end: 3;

    img {
        margin-right: 10px;
        margin-bottom: 4px;
    }

    @media (min-width: 425px) and (max-width: 475px) {
        margin-top: 10px;
    }
    @media (min-width: 375px) and (max-width: 424px) {
        margin-top: 10px;
    }
    @media (min-width: 320px) and (max-width: 374px) {
        margin-top: 10px;
    }

    .seller-infos-container {
        display: flex;
        flex-direction: row;
        width: 305px;
        @media screen and (max-width: 1366px) {
            width: 328px;
        }

        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 3px 2px 9px #00000014;
        height: 76px;
        border-radius: 8px;
        padding: 17px;

        img {
            margin-right: 10px;
        }

        .seller-infos {
            display: flex;
            flex-direction: column;
            h1 {
                margin: 0;
                color: #6b98a6;
                font-family: 'Poppins', semi-bold;
                font-weight: 600;
                font-size: 19px;
            }
            span {
                color: black;
                font-family: 'Poppins', regular;
                font-size: 14px;
                font-weight: 400;
            }
        }
    }

    .delivery {
        width: 100%;
        height: 100px;
        background: #fff;
        box-shadow: 3px 2px 9px #00000014;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        justify-content: flex-start;
        font-size: 30px;
        padding: 17px;
        align-items: flex-end;
        border: 1px solid black;
        margin: 1rem;

        @media (min-width: 425px) and (max-width: 475px) {
            width: 100%;
            margin-left: 0;
            margin-top: 0;
        }
        @media (min-width: 375px) and (max-width: 424px) {
            width: 100%;
            margin-left: 0;
            margin-top: 0;
        }
        @media (min-width: 320px) and (max-width: 374px) {
            width: 100%;
            margin-left: 0;
            margin-top: 0;
        }
        h2 {
            margin: 0;
            margin-bottom: 2px;
            color: #6b98a6;
            font-size: 14px;
            font-family: 'Poppins', semi-bold;
            font-weight: 600;
        }

        .input-organizer {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            #home-img {
                margin-right: 5px;
            }
            h2 {
                width: 180px;
            }

            label {
                color: black;
                font-family: 'Poppins', regular;
                font-size: 13px;
                margin-bottom: 4px;
                font-weight: 400;
            }
            input {
                width: 371px;
                height: 33px;
                border: 1px solid black;
                border-radius: 6px;
                font-size: 15px;
                /* color: #cecece; */
                padding: 10px;
                font-family: 'Poppins', regular;
                background-color: #fff;

                @media (min-width: 425px) and (max-width: 475px) {
                    width: 15rem;
                    margin-top: 0;
                }
                @media (min-width: 375px) and (max-width: 424px) {
                    width: 15rem;
                    margin-top: 0;
                }
                @media (min-width: 320px) and (max-width: 374px) {
                    width: 12rem;
                    margin-top: 0;
                }
            }
            input:focus {
                outline: none;
            }
        }

        .calc-button {
            width: 142px;
            height: 33px;
            border-radius: 6px;
            border: 1px solid black;
            color: white;
            font-size: 13px;
            font-family: 'Poppins', regular;
            margin-left: 10px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        .calc-button-active {
            cursor: pointer;
            background: #69b64a 0% 0% no-repeat padding-box;
        }

        .calc-button-disabled {
            cursor: not-allowed;
            background: #c9c9c9 0% 0% no-repeat padding-box;
        }
        .other-address-button {
            width: 239px;
            height: 33px;
            background: #69b64a 0% 0% no-repeat padding-box;
            border-radius: 6px;
            border: none;
            color: white;
            font-size: 13px;
            font-family: 'Poppins', regular;
            margin-left: auto;
        }
    }
`
