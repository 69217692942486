import React from 'react';

import { Container } from './styles';
import { MdChevronLeft as ChevronLeft } from 'react-icons/md'
import { useHistory } from 'react-router';

const SocialProjectsContactCard: React.FC = () => {
	const history = useHistory();
	return (
		<Container>
			<div>
				<div className="back-button" onClick={() => history.goBack()}>
					<ChevronLeft className="IconVoltar"/>
					<span>Voltar</span>
				</div>
				<header>
					<h1>Gostaria de se registrar seu projeto social?</h1> 
				</header>
				<div className="page-sub-title">
					<h4>
						Nos conte um pouco mais sobre sua causa e entraremos em
						contato 
					</h4>
				</div>

				<div className="email-input-container">
					<label>E-mail para contato</label>
					<input type="email" />
				</div>
				<div className="message-container">
					<label>Mensagem</label>
					<textarea name="" id=""></textarea>
				</div>
				<div className="form-action-button-container">
					<button type="submit" className="BotaoSubmitProjectsContact">Enviar</button>
				</div>
			</div>	
		</Container>
	);
};

export default SocialProjectsContactCard;
