import styled from 'styled-components';

export const CustomizationContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	height: 300px;
	background: #f7f7f7 0% 0% no-repeat padding-box;
	transition: all 3s;
	padding: 26px;
	.customize-brand-container {
		height: 100%;
		border-right: solid 1px black;
		flex: 0.3;

		.customize-brand {
			height: 200px;

			h2 {
				color: #6b97a5;
				font-size: 18px;
				font-family: 'Poppins', semi-bold;
				font-weight: 600;
				margin-bottom: 20px;
			}
			input {
				width: 302px;
				height: 34px;
				border: 1px solid black;
				border-radius: 8px;
				background-color: #fff;
				padding: 5px;
				color: #a8a8a8;
				font-size: 14px;
				font-family: 'Poppins', regular;
				font-family: 'Poppins';
				font-weight: 600;
				font-size: 18px;
			}
			input:focus {
				outline: none;
			}
			label {
			}
			.choose-color {
				width: 305px;
				height: 34px;
				background-color: #00bbaf;
				margin-top: 20px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				padding: 12px;
				border-radius: 8px;
				border: 1px solid #959595;

				span {
					color: white;
					font-size: 14px;
					font-family: 'Poppins';
					font-weight: bold;
				}
				input {
					display: none;
				}
			}
			.choose-color:hover {
				cursor: pointer;
			}
          
            .customize-brand-title {
              display: flex;
              flex-direction: row;
              h2 {
                margin-right: 6px;
              }
              
            }
          }
			.choose-archive {
				margin-top: 20px;
				background-color: #69b64a;
				width: 305px;
				height: 34px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				padding: 12px;
				border-radius: 8px;
				border: 1px solid #959595;
				input {
					display: none;
				}
				label {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					width: 100%;
				}
				span {
					color: white;
					font-size: 14px;
					font-family: 'Poppins';
					font-weight: bold;
				}
			}
		}
	}
	.customize-banner-container {
		height: 100%;
		border-right: solid 1px black;
		flex: 0.3;
		.customize-banner {
			height: 200px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: start;
			.title-container {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: start;
				width: 287px;
				h2 {
					color: #6b97a5;
					font-size: 18px;
					font-family: 'Poppins', semi-bold;
					font-weight: 600;
					margin-bottom: 20px;
				}
			}

			.standard-banners-container {
				display: flex;
				flex-direction: row;
				width: 287px;
				flex-wrap: wrap;
				img {
					width: 35px;
					margin-right: 28px;
					border-radius: 8px;
					height: 35px;
					margin-bottom: 10px;
				}
			}
			.choose-color {
				width: 305px;
				height: 34px;
				background-color: #959595;
				margin-top: 20px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				padding: 12px;
				border-radius: 8px;
				border: 1px solid #959595;

				span {
					color: white;
					font-size: 14px;
					font-family: 'Poppins';
					font-weight: bold;
				}
				input {
					display: none;
				}
			}
			.choose-color:hover {
				cursor: pointer;
			}
			.archive-name {
				width: 305px;
				height: 34px;
				margin-top: 20px;
				border: solid 1px black;
				padding: 5px;
				display: flex;
				background: #69b64a;
				color: white;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				border-radius: 8px;
				color: white;
				font-size: 14px;
				font-family: 'Poppins';
				font-weight: bold;
				img:hover {
					cursor: pointer;
				}
			}
		}
	}
	.button-save-container {
		height: 100%;
		flex: 0.3;
		.button-save {
			height: 200px;
			display: flex;
			padding: 46px;
			display: flex;
			flex-direction: column;
			button {
				width: 305px;
				height: 34px;
				border: none;
				background-color: #3e7dc5;
				border-radius: 8px;
				color: white;
				font-size: 14px;
				font-family: 'Poppins';
				font-weight: bold;
                margin:6px;
			}
		}
	}
`;