import styled from 'styled-components';

export const Container = styled.div``;
export const ModalContainer = styled.div``;

export const ModalTop = styled.header`
	margin-bottom: 20px;
	border-bottom: solid 1px black;
	padding-bottom: 10px;
	h1 {
		color: #beb800;
		font-size: 20px;
		font-family: 'Poppins';
		font-weight: bold;
	}
`;
export const ModalMiddle = styled.main`
	p {
		color: black;
		font-size: 13px;
		font-family: 'Poppins';
		font-weight: 400;
		width: 620px;
		word-break: break-word;
		margin-bottom: 35px;
	}
	.radio-buttons {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		height: 99px;
	}
	#radio-label {
		color: black;
		font-family: 'Poppins';
		font-weight: 400;
		font-size: 12px;
	}
`;
export const ModalBottom = styled.footer`
	display: flex;
	flex-direction: row;
	justify-content: center;

	.negative-button {
		width: 99px;
		height: 47px;
		background: #ca667e 0% 0% no-repeat padding-box;
		border-radius: 6px;
		margin-right: 15px;
		color: white;
		border: none;
		font-family: 'Poppins';
		font-weight: bold;
		font-size: 14px;
		cursor: pointer;
		&:focus {
			outline: none;
		}
	}
	.positive-button {
		width: 150px;
		height: 47px;
		background: #6b97a5 0% 0% no-repeat padding-box;
		border-radius: 6px;
		color: white;
		border: none;
		font-family: 'Poppins';
		font-weight: bold;
		font-size: 14px;
		cursor: pointer;
		&:focus {
			outline: none;
		}
	}
`;
