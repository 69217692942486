import React from 'react';
import Layout from '../../components/Layout/Layout';
import SocialProjectsContactCard from '../../components/SocialProjectsContactCard/SocialProjectsContactCard';

const SocialProjectsContact = () => {
	return (
		<Layout title="Registrar Projeto Social">
			<SocialProjectsContactCard />
		</Layout>
	);
};

export default SocialProjectsContact;
