import React from 'react';
import Layout from '../../components/Layout/Layout';
import ProductCategorieCard from '../../components/ProductCategorieCard/ProductCategorieCard';

const ProductCategories = () => {
	return (
		<Layout title="Categorias">
			<ProductCategorieCard />
		</Layout>
	);
};

export default ProductCategories;
