import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ProductContainer } from './styles'
import IUserAccount from '../../../../models/IUserAccount'
import { AppState } from '../../../../store'
import { useSelector } from 'react-redux'
import { GlassMagnifier } from 'react-image-magnifiers'
import { Modal } from 'semantic-ui-react'
import { ReactComponent as Exit } from '../../../../assets/icons/exit.svg'
import { ReactComponent as Back } from '../../../../assets/icons/back.svg'
import ReportProductModal from '../ReportProductModal/ReportProductModal'
import BlockProductModal from '../BlockProductModal/BlockProductModal'
import BlockUserModal from '../BlockUserModal/BlockUserModal'
import ProductSaleCardInfos from './ProductSaleCardInfos/ProductSaleCardInfos'
import AliceCarousel from 'react-alice-carousel'
import { QuoteShipping } from './Shipping'
import WarningModal, {
    ProductWarningModalHandles,
} from './ProductWarningModal/ProductWarningModal'
import useWindow from '../../../../hooks/useWindow'
import Img from '../../../Default/CommomImg/Img'
import noPhoto from '../../../../assets/sem-produto-detalhe.png'

type SlideInfo = {
    item: number
    itemsCount: number
}

type ProductPicture = {
    id: number
    imagePath: string
}

type Props = {
    Product: any
}

function renderSlideInfo(slideInfo: SlideInfo): string {
    return `${slideInfo.item}/${slideInfo.itemsCount}`
}

const ProductSaleCard: React.FC<Props> = ({ Product }) => {
    const { innerWidth } = useWindow()
    const user: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )
    const [stateImagesModal, setStateImagesModal] = useState({
        dimmer1: undefined,
        open: false,
    })
    const showImagesModal = (dimmer: any): void =>
        setStateImagesModal({ dimmer1: dimmer, open: true })
    const [selectedImage, setSelectedImage] = useState<number>(1)
    const modalRef = useRef<ProductWarningModalHandles>(null)
    const pictures: ProductPicture[] = []

    const changeSelectedPhoto = (action: string): void => {
        if (action === 'next') {
            if (selectedImage === Product.numberOfPhotos) {
                setSelectedImage(1)
            } else {
                setSelectedImage(selectedImage + 1)
            }
        } else {
            if (selectedImage === 1) {
                setSelectedImage(Product.numberOfPhotos)
            } else {
                setSelectedImage(selectedImage - 1)
            }
        }
    }

    const interatePicutrs = useCallback(() => {
        for (let index = 1; index <= Product.numberOfPhotos; index++) {
            pictures.push({
                id: index,
                imagePath: `${process.env.REACT_APP_STATIC_URL}/product/${
                    Product.id
                }-${index}.jpeg?${Date.now()}`,
            })
        }
    }, [Product.numberOfPhotos])

    useEffect(() => {
        interatePicutrs()
    }, [Product.numberOfPhotos])

    useEffect(() => {
        if (
            Product.categorieId === 50 ||
            Product.categorieId === 46 ||
            Product.categorieId === 65
        ) {
            modalRef.current?.handleCloseModal()
        }
    }, [Product.categorieId])

    const thumbs = [
        {
            id: 2,
            imagePath: `${process.env.REACT_APP_STATIC_URL}/product/${
                Product.id
            }-2.jpeg?${Date.now()}`,
        },
        {
            id: 3,
            imagePath: `${process.env.REACT_APP_STATIC_URL}/product/${
                Product.id
            }-3.jpeg?${Date.now()}`,
        },
        {
            id: 4,
            imagePath: `${process.env.REACT_APP_STATIC_URL}/product/${
                Product.id
            }-4.jpeg?${Date.now()}`,
        },
        {
            id: 5,
            imagePath: `${process.env.REACT_APP_STATIC_URL}/product/${
                Product.id
            }-5.jpeg?${Date.now()}`,
        },
    ]

    return (
        <>
            <WarningModal ref={modalRef} />
            <ProductContainer>
                <div className="row">
                    <div className="images-container">
                        {innerWidth > 768 && (
                            <>
                                <div className="big-img-container">
                                    <Img
                                        src={`${
                                            process.env.REACT_APP_STATIC_URL
                                        }/product/${
                                            Product.id
                                        }-1.jpeg?${Date.now()}`}
                                        className="big-img"
                                        style={{ objectFit: 'contain' }}
                                        onClick={() => {
                                            setSelectedImage(1)
                                            showImagesModal('default')
                                        }}
                                        img={noPhoto}
                                    />
                                </div>
                                <div className="images-container-vertical">
                                    <div className="images-row">
                                        {thumbs.map((thumb, index) => (
                                            <>
                                                {Product.numberOfPhotos >
                                                    index + 1 && (
                                                    <img
                                                        key={thumb.id}
                                                        src={thumb.imagePath}
                                                        alt=""
                                                        className="small-img"
                                                        onClick={() => {
                                                            setSelectedImage(
                                                                thumb.id
                                                            )
                                                            showImagesModal(
                                                                'default'
                                                            )
                                                        }}
                                                    />
                                                )}
                                            </>
                                        ))}

                                        {Product.numberOfPhotos > 5 && (
                                            <div className="more-imgs">
                                                +{Product.numberOfPhotos - 5}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="line-bottom"></div>
                            </>
                        )}
                        {innerWidth <= 768 && (
                            <>
                                <div className="big-img-container">
                                    <Img
                                        src={`${
                                            process.env.REACT_APP_STATIC_URL
                                        }/product/${
                                            Product.id
                                        }-1.jpeg?${Date.now()}`}
                                        className="big-img"
                                        style={{ objectFit: 'contain' }}
                                        onClick={() => {
                                            setSelectedImage(1)
                                            showImagesModal('default')
                                            // setOpen(true);
                                        }}
                                        img={noPhoto}
                                    />
                                </div>
                                <div className="images-container-vertical">
                                    <div className="images-row">
                                        {thumbs.map((thumb, index) => (
                                            <>
                                                {Product.numberOfPhotos >
                                                    index + 1 && (
                                                    <img
                                                        key={thumb.id}
                                                        src={thumb.imagePath}
                                                        alt=""
                                                        className="small-img"
                                                        onClick={() => {
                                                            setSelectedImage(
                                                                thumb.id
                                                            )
                                                            showImagesModal(
                                                                'default'
                                                            )
                                                        }}
                                                    />
                                                )}
                                            </>
                                        ))}
                                        {Product.numberOfPhotos > 5 && (
                                            <div className="more-imgs">
                                                t +{Product.numberOfPhotos}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="line-bottom" />
                            </>
                        )}

                        {innerWidth <= 768 && Product.numberOfPhotos > 1 && (
                            <>
                                <AliceCarousel
                                    mouseTracking
                                    disableButtonsControls
                                    disableDotsControls
                                    disableSlideInfo={false}
                                    renderSlideInfo={renderSlideInfo}
                                    items={pictures?.map(
                                        (picture: ProductPicture) => {
                                            return (
                                                <img
                                                    className="mobile-carousel-item"
                                                    key={picture.id}
                                                    src={picture.imagePath}
                                                    alt={`${picture.id}`}
                                                    onClick={() => {
                                                        setSelectedImage(
                                                            picture.id
                                                        )
                                                        showImagesModal(
                                                            'default'
                                                        )
                                                    }}
                                                />
                                            )
                                        }
                                    )}
                                />
                            </>
                        )}
                    </div>

                    <ProductSaleCardInfos
                        Product={Product}
                        disableBid={false}
                        disableButton={false}
                        finalTime={false}
                        buyWithDirectValue={true}
                    />

                    {Product &&
                        Product.UserAccount &&
                        Product.UserAccount.id &&
                        user &&
                        user.userTypeId === 1 && (
                            <div className="adm-buttons">
                                <BlockUserModal
                                    sellerId={Product.UserAccount.id}
                                />
                                <BlockProductModal productId={Product.id} />
                            </div>
                        )}

                    {Product.categorieId !== 7 && Product.categorieId !== 8 && (
                        <>{QuoteShipping(Product)}</>
                    )}

                    {Product.typeNegotiation === 'Venda' ||
                        (Product.typeNegotiation === 'Promoção' && (
                            <ReportProductModal
                                productId={Product.id}
                                productUserId={Product.UserAccount.id}
                            />
                        ))}
                </div>

                <Modal
                    dimmer={stateImagesModal.dimmer1}
                    open={stateImagesModal.open}
                    onClose={() =>
                        setStateImagesModal({
                            dimmer1: undefined,
                            open: false,
                        })
                    }
                    id="image-modal"
                    style={{
                        background: 'transparent',
                        boxShadow: 'none',
                    }}
                >
                    <Exit
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            filter: 'brightness(10)',
                            fill: 'white',
                        }}
                        onClick={() =>
                            setStateImagesModal({
                                dimmer1: undefined,
                                open: false,
                            })
                        }
                    />
                    <div
                        className="modal-image-container"
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <div
                            id="div-image-modal"
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: 20,
                            }}
                        >
                            {Product.numberOfPhotos > 1 && innerWidth > 768 && (
                                <Back
                                    style={{
                                        marginRight: '15px',
                                        width: '50px',
                                    }}
                                    onClick={() =>
                                        changeSelectedPhoto('previous')
                                    }
                                />
                            )}

                            <GlassMagnifier
                                className="big-img"
                                imageAlt="Example"
                                imageSrc={`${
                                    process.env.REACT_APP_STATIC_URL
                                }/product/${
                                    Product.id
                                }-${selectedImage}.jpeg?${Date.now()}`}
                                magnifierSize={
                                    innerWidth <= 768 ? '50%' : '25%'
                                }
                            />
                            {Product.numberOfPhotos > 1 && innerWidth > 768 && (
                                <Back
                                    style={{
                                        transform: 'rotate(180deg)',
                                        marginLeft: '15px',
                                        width: '50px',
                                    }}
                                    onClick={() => changeSelectedPhoto('next')}
                                />
                            )}
                        </div>
                    </div>
                </Modal>
            </ProductContainer>
        </>
    )
}

export default ProductSaleCard
