import styled from 'styled-components';

interface CardProps {
	bottomColor: string;
}

export const ProductCardContainer = styled.div<CardProps>`
	display: flex;
	flex-direction: column;
	margin-bottom: 40px;
	width: 160px;
	height: 291px;

	@media (max-width: 768px) {
		width: 100%;
		max-width: 335px;

		img {
			width: 100% !important;
		}
	}
	transition: all 0.3s;
	@media (min-width: 768px) {
		&:hover {
			-webkit-box-shadow: 0px 18px 36px -19px rgba(94, 87, 94, 0.59);
			-moz-box-shadow: 0px 18px 36px -19px rgba(94, 87, 94, 0.59);
			box-shadow: 0px 18px 36px -19px rgba(94, 87, 94, 0.59);
			.product-card-content {
				height: 160px;

				-webkit-box-shadow: 0px 18px 36px -19px rgba(94, 87, 94, 0.59);
				-moz-box-shadow: 0px 18px 36px -19px rgba(94, 87, 94, 0.59);
				box-shadow: 0px 18px 36px -19px rgba(94, 87, 94, 0.59);
			}
		}
	}

	img {
		border-top-left-radius: 13px;
		border-top-right-radius: 13px;
		width: 160px;
		height: 145px;
		object-fit: cover; /* 
		object-fit: contain; */
	}
	@media (min-width: 768px) {
		&:hover .product-card-content p {
			height: 45px;
		}
	}

	.product-social-project-icon {
		position: relative;
		z-index: 0;
		.social-project-icon-container {
			position: absolute;
			background-color: #ca667e;
			width: 32px;
			height: 32px;
			border-radius: 0px 13px;
			right: 0;

			.heart {
				position: absolute;
				margin: auto;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-color: white;
				height: 8px;
				width: 8px;
				transform: rotate(-45deg);
			}
			.heart::after {
				background-color: white;
				content: '';
				border-radius: 50%;
				position: absolute;
				width: 8px;
				height: 8px;
				top: 0px;
				left: 5px;
			}
			.heart::before {
				content: '';
				background-color: white;
				border-radius: 50%;
				position: absolute;
				width: 8px;
				height: 8px;
				top: -5px;
				left: 0px;
			}
		}
	}
	.bargain-products-icon {
		position: relative;
		z-index: 0;

		.bargain-products-container {
			position: absolute;
			background-color: #6085db;
			width: 32px;
			height: 32px;
			border-radius: 0px 13px;
			right: 0;
		}
	}

	.product-card-content {
		position: relative;
		padding: 12px;
		height: 155px;
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 0px 8px #00000014;
		border-bottom-left-radius: 13px;
		border-bottom-right-radius: 13px;
		word-break: break-word;
		flex-wrap: wrap;
		padding-bottom: 0px;

		transition: all 0.3s;
		h2 {
			color: #383c3e;
			font-family: 'Poppins';
			font-weight: 400;
			font-size: 14px;
			transition: all 0.3s;
			margin-top: 0.5rem;
		}

		p {
			color: #707070;
			font-size: 12px;
			height: 36px;
			transition: all 0.3s;
			margin-bottom: 16px;
		}
		.price-container {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			transition: all 0.3s;

			h2 {
				font-family: 'Poppins', semi-bold;
				font-weight: 600;
				color: #383c3e;
				font-size: 20px;
				margin: 0;
				transition: all 0.3s;
			}
			span {
				font-family: 'Poppins', regular;
				color: black;
				font-size: 12px;
				transition: all 0.1s;
			}

			p {
				word-wrap: break-word;
				height: 32px;
				transition: all 0.3s;
			}
		}

		.product-type-container {
			background-color: ${(Prop) => Prop.bottomColor};
			border-radius: 0 0 13px 13px;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;

			span {
				font-size: 11px;
				font-family: 'Poppins', regular;
				color: white;
				font-size: 11px;
				padding: 1rem;
			}
		}
	}
`;
