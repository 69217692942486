import styled from 'styled-components';

export const ProductMenu = styled.div`
	
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height:50%;
	padding-top:16em;
	background-color:#fff;
	div {
		display: flex;
		flex-direction: row;
		width: 1010px;
		height: 100%;
		justify-content: start;
		@media (min-width:425px) and (max-width:767px){
			justify-content:center;
			display: none;
			width: 100%;
		}
		@media (min-width:375px) and (max-width:424px){
			justify-content:center;
			display: none;
			width: 100%;
		}
		@media (min-width:320px) and (max-width:374px){
			justify-content:center;
			display: none;
			width: 100%;
		}
		.items {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			align-self: center;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			
			border-bottom: solid 3px #cecece;
			text-decoration: none;
			padding-left: 25px;
			padding-right: 25px;

			h2 {
				color: #6b97a5;
				margin-left: 20px;
				font-size: 14px;
				font-family: 'Poppins';
				font-weight: 600;
				margin: 0px;
			}
		}
		.items-active {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			align-self: center;
			padding-left: 25px;
			padding-right: 25px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			border-bottom: solid 3px #6b97a5;

			h2 {
				color: #6b97a5;
				margin-left: 20px;
				font-size: 14px;
				font-family: 'Poppins';
				font-weight: 600;
				margin: 0px;
			}
		}
	}
`;
