import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import IUserAccount from '../../../models/IUserAccount'
import { AppState } from '../../../store'
import {
    useGetAllBlingProductsQuery,
    usePublishBlingProductsMutation,
} from '../../../generated/graphql'
import { Card, MyProductContainer, Title } from '../Style'
import defaultImg from '../../../assets/icons/deleteBannerIcon.svg'

const BlingProducts = () => {
    const [blingProducts, setBlingProducts] = useState<any[]>([])
    const [selectedProductCodes, setSelectedProductCodes] = useState<string[]>(
        []
    )
    const [selectAll, setSelectAll] = useState(false)

    const user: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )

    const { data, loading } = useGetAllBlingProductsQuery({
        variables: {
            userId: user.id,
        },
    })

    const [publishBlingProducts] = usePublishBlingProductsMutation({
        onCompleted: () => {
            alert(
                'Produtos do Bling! publicados com sucesso, verifique a aba "Meus Produtos" para visualizá-los.'
            )
        },
        onError(error) {
            alert(error.message)
        },
    })

    const handleCheckboxChange = (index: number) => {
        const updatedBlingProducts = [...blingProducts]
        if (index === -1) {
            // "Select All" checkbox is clicked
            const selectAllValue = !selectAll
            for (const product of updatedBlingProducts) {
                product.selected = selectAllValue
            }
            setSelectAll(selectAllValue)
        } else {
            // Individual checkbox is clicked
            updatedBlingProducts[index].selected =
                !updatedBlingProducts[index].selected
            setSelectAll(
                updatedBlingProducts.every((product) => product.selected)
            )
        }
        setBlingProducts(updatedBlingProducts)

        // Update selected product codes
        const selectedCodes = updatedBlingProducts
            .filter((product) => product.selected && product.codigo)
            .map((product) => product.codigo)
        setSelectedProductCodes(selectedCodes)
    }

    const handlePublishBlingProducts = () => {
        // console.log([...selectedProductCodes])
        publishBlingProducts({
            variables: {
                userId: user.id,
                blingProductIds: selectedProductCodes,
            },
        })
    }

    useEffect(() => {
        if (data && data.getAllBlingProducts && !loading) {
            setBlingProducts(
                data.getAllBlingProducts
                    .filter(
                        (blingProduct) =>
                            blingProduct.estoqueAtual &&
                            blingProduct.estoqueAtual > 0 &&
                            blingProduct.codigo
                    )
                    .map((blingProduct) => ({
                        ...blingProduct,
                        selected: false,
                    }))
            )
            setSelectAll(false)
        }
    }, [data, loading])

    return (
        <MyProductContainer>
            <Title>
                <div className="title-organizer">
                    <div className="title-container tw-space-x-2">
                        <h2>Produtos do Bling!</h2>
                        {selectedProductCodes &&
                            selectedProductCodes.length > 0 && (
                                <button
                                    onClick={() => handlePublishBlingProducts()}
                                    className={`
                                        tw-bg-smoke-100 
                                        tw-text-black
                                        disabled:tw-cursor-not-allowed
                                        disabled:tw-bg-white
                                        disabled:tw-text-smoke-300`}
                                >
                                    {selectedProductCodes.length > 1
                                        ? 'Publicar Produtos'
                                        : 'Publicar Produto'}
                                </button>
                            )}
                    </div>
                </div>
            </Title>
            {loading ? (
                <h1>Carregando...</h1>
            ) : (
                <Card>
                    <div className="overflow-x-auto w-full">
                        <table className="table w-full">
                            {/* head */}
                            <thead>
                                <tr>
                                    <th>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={selectAll}
                                                onChange={() =>
                                                    handleCheckboxChange(-1)
                                                }
                                            />
                                        </label>
                                    </th>
                                    <th></th>
                                    <th>Código do Bling!</th>
                                    <th>Descrição</th>
                                    <th>Marca</th>
                                    <th>Preço</th>
                                    <th>Estoque</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {blingProducts &&
                                    blingProducts.map((blingProduct, index) => {
                                        return (
                                            <tr key={index}>
                                                <th>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            className="checkbox"
                                                            checked={
                                                                blingProduct.selected
                                                            }
                                                            onChange={() =>
                                                                handleCheckboxChange(
                                                                    index
                                                                )
                                                            }
                                                        />
                                                    </label>
                                                </th>
                                                <td>
                                                    <img
                                                        src={
                                                            blingProduct.imageThumbnail ||
                                                            defaultImg
                                                        }
                                                        alt={
                                                            blingProduct.descricao
                                                        }
                                                        style={{
                                                            width: '70px',
                                                            height: '70px',
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <div className="tw-font-semibold">
                                                        {blingProduct.codigo ||
                                                            'Sem Código'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        {blingProduct.descricao ||
                                                            'Sem Descrição'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        {blingProduct.marca ||
                                                            'Sem Marca'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        {Number.parseInt(
                                                            blingProduct.preco
                                                        ).toLocaleString(
                                                            'pt-br',
                                                            {
                                                                style: 'currency',
                                                                currency: 'BRL',
                                                            }
                                                        )}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        {
                                                            blingProduct.estoqueAtual
                                                        }
                                                    </div>
                                                </td>
                                                <th>
                                                    <a
                                                        href={`https://www.bling.com.br/produtos.php#edit/${blingProduct.id}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Ver no Bling!
                                                    </a>
                                                </th>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </div>
                </Card>
            )}
        </MyProductContainer>
    )
}

export default BlingProducts
