import React, { useEffect, useRef } from 'react'
import MelhorEnvioWarningModal, {
    ModalMelhorEnvioWarningHandles,
} from './MelhorEnvioWarningModal/MelhorEnvioWarningModal'
import { useDispatch, useSelector } from 'react-redux'
import menvlogo from '../../assets/melhorenvio.svg'
import blinglogo from '../../assets/bling.svg'
import { CardTitle, DataContainer } from './Style'
import IUserAccount from '../../models/IUserAccount'
import { AppState } from '../../store'
import BlingWarningModal, {
    ModalBlingWarningHandles,
} from './BlingWarningModal/BlingWarningModal'
import {
    useDesconectarConexaoMutation,
    useGetBlingAccountQuery,
    useGetMelhorEnvioTokenQuery,
} from '../../generated/graphql'
import { updateMelhorEnvioToken } from '../../actions/UserActions'
import { useQuery } from '../../hooks/useQuery'

const IntegrationsCard: React.FC = () => {
    const user: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )

    const dispatch = useDispatch()
    const query = useQuery()

    const integration = query.get('integration')
    const isConnected = query.get('connection_status') === 'success'

    const { data: getBlingAccountResponse } = useGetBlingAccountQuery({
        variables: {
            getBlingAccountInputData: {
                userId: user.id,
            },
        },
        fetchPolicy: 'cache-and-network',
    })

    const { data: dataMelhorEnvioToken } = useGetMelhorEnvioTokenQuery({
        variables: {
            userId: user.id,
        },
        fetchPolicy: 'cache-and-network',
    })

    const [desconectarMelhorEnvio] = useDesconectarConexaoMutation()

    const openModal = useRef<ModalMelhorEnvioWarningHandles>(null)

    const openModalBling = useRef<ModalBlingWarningHandles>(null)

    async function desconectMelhorEnvio(): Promise<void> {
        try {
            await desconectarMelhorEnvio({
                variables: {
                    userId: user.id,
                },
            })
            dispatch(updateMelhorEnvioToken(''))
            window.location.href = window.location.pathname
        } catch (error) {
            console.log('Erro ao desconectar', error)
        }
    }

    useEffect(() => {
        if (
            dataMelhorEnvioToken &&
            dataMelhorEnvioToken.getMelhorEnvioToken &&
            dataMelhorEnvioToken.getMelhorEnvioToken.token
        ) {
            dispatch(
                updateMelhorEnvioToken(
                    dataMelhorEnvioToken.getMelhorEnvioToken.token
                )
            )
        }
    }, [dataMelhorEnvioToken, dispatch])

    useEffect(() => {
        switch (integration) {
            case 'menvio':
                if (!isConnected) {
                    alert(
                        'Não foi possível conectar com o Melhor Envio, tente novamente mais tarde.'
                    )
                    break
                }
                alert('A conexão com o Melhor Envio foi realizada com sucesso.')
                break

            default:
                break
        }
    }, [integration, isConnected])

    return (
        <>
            <CardTitle>
                <DataContainer>
                    <img
                        src={menvlogo}
                        alt="MelhorEnvio"
                        height="42px"
                        width="auto"
                    />
                    {user && !user.melhorEnvioToken && (
                        <button
                            className="button-container"
                            onClick={() => openModal.current?.handleOpenModal()}
                        >
                            Conectar
                        </button>
                    )}
                    {user && user.melhorEnvioToken && (
                        <button
                            className="button-container"
                            style={{
                                backgroundColor: 'red',
                            }}
                            onClick={() => desconectMelhorEnvio()}
                        >
                            Desconectar
                        </button>
                    )}
                    <MelhorEnvioWarningModal ref={openModal} />
                    <BlingWarningModal ref={openModalBling} />
                </DataContainer>
                <DataContainer>
                    <img
                        src={blinglogo}
                        alt="BlingLogo"
                        height="42px"
                        width="auto"
                    />

                    {getBlingAccountResponse?.getBlingAccount?.apiKey && (
                        <>
                            <button
                                className="button-container"
                                onClick={() =>
                                    openModalBling.current?.handleOpenModal()
                                }
                            >
                                Conectado
                            </button>
                        </>
                    )}
                    {!getBlingAccountResponse?.getBlingAccount?.apiKey && (
                        <>
                            <button
                                className="button-container"
                                onClick={() =>
                                    openModalBling.current?.handleOpenModal()
                                }
                            >
                                Conectar
                            </button>
                        </>
                    )}
                </DataContainer>
            </CardTitle>
        </>
    )
}

export default IntegrationsCard
