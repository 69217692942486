import React from 'react';
import { useHistory } from 'react-router';
import { MdOutlineChevronLeft as Back } from 'react-icons/md';

const Navigation = () => {
	let history = useHistory();
	return (
		<div className="nav tw-flex tw-flex-row tw-justify-start tw-px-4 sm:tw-px-8 md:tw-px-12 lg:tw-px-16 tw-py-2 sm:tw-py-4">
			<button
				onClick={() => {
					history.goBack();
				}}
				className="tw-flex tw-flex-row tw-items-center tw-space-x-2 sm:tw-space-x-4 tw-py-2 sm:tw-py-4 tw-font-primary"
			>
				<span className="tw-rounded-full tw-bg-primary-700 tw-text-white tw-w-6 sm:tw-w-12 tw-h-6 sm:tw-h-12">
					<Back className="tw-h-6 sm:tw-h-12 tw-w-auto tw-fill-current" />
				</span>
				<span className="tw-font-regular tw-text-primary-700 tw-text-base sm:tw-text-2xl">
					Voltar
				</span>
			</button>
		</div>
	);
};

export default Navigation;
