import React from 'react';
import style from './FilterOptionItem.module.css';
type Props = {
	title?:string;
	active: boolean;
	onClick: any;
};
function FilterOptionItem({title,active,onClick}:Partial<Props>){
	return (
		<div onClick={onClick}>
			<input type="checkbox" className={style.buttonSelect}/>
			<span className={style.title}>{title}</span>
		</div>
	)
}




export default FilterOptionItem;
