import React from 'react'
import AliceCarousel from 'react-alice-carousel'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { useHistory } from 'react-router'
import { SliderContainer } from '../../utils/AliceCarouselCustom'
import 'react-alice-carousel/lib/alice-carousel.css'

// Banners
import bannerkitrev from '../../assets/banners/desktop/bannerkitrev.png'
import bannerzequinha from '../../assets/banners/desktop/2.png'
import bannerraridades from '../../assets/banners/desktop/raridades2.png'
import bannervantagens from '../../assets/banners/desktop/12.png'
import bannerkitrevmobile from '../../assets/banners/desktop/bannerkitrevmobile.png'
import bannerraridadesmobile from '../../assets/banners/desktop/bannerraridadesmobile.png'
import bannervantagensmobile from '../../assets/banners/desktop/bannersamboymobile.png'
import bannerzequinhamobile from '../../assets/banners/desktop/bannerzequinhamobile.png'

export default function BannerSlider(): JSX.Element {
    const history = useHistory()
    const renderPreviousButton = ({ isDisabled }: any): any => {
        return (
            <span style={{ opacity: isDisabled ? '0.5' : 1 }}>
                <MdChevronLeft color="black" size="3rem" />
            </span>
        )
    }

    const renderNextButton = ({ isDisabled }: any): any => {
        return (
            <span style={{ opacity: isDisabled ? '0.5' : 1 }}>
                <MdChevronRight color="black" size="3rem" />
            </span>
        )
    }

    const responsive = {
        0: { items: 1 },
        568: { items: 1 },
        1024: { items: 1 },
    }

    const imageClassNames =
        'tw-w-full tw-h-auto tw-object-cover tw-bg-white tw-cursor-pointer'

    const items = [
        <img
            src={bannervantagens}
            className={imageClassNames}
            key="bannervantagens"
        />,
        <img
            src={bannerkitrev}
            className={imageClassNames}
            onClick={() => history.push(`/product-view/1018/all-data`)}
            key="bannerkitrev"
        />,
        <img
            src={bannerzequinha}
            className={imageClassNames}
            onClick={() => history.push(`/shopping/16/products`)}
            key="bannerzequinha"
        />,
        <img
            src={bannerraridades}
            className={imageClassNames}
            onClick={() => history.push(`/shopping/6/products`)}
            key="bannerraridades"
        />
    ]
    const itemsMobile = [
        <img
            src={bannervantagensmobile}
            className={imageClassNames}
            key="bannerkitrev"
            onClick={() => history.push(`/product-view/1018/all-data`)}
        />,
        <img
            src={bannerkitrevmobile}
            className={imageClassNames}
            key="bannerkitrev"
            onClick={() => history.push(`/product-view/1018/all-data`)}
        />,
        <img
            src={bannerzequinhamobile}
            className={imageClassNames}
            key="bannerzequinha"
            onClick={() => history.push(`/shopping/16/products`)}
        />,
        <img
            src={bannerraridadesmobile}
            className={imageClassNames}
            key="bannerraridades"
            onClick={() => history.push(`/shopping/6/products`)}
        />,
        <img
            src={bannervantagensmobile}
            className={imageClassNames}
            key="bannervantagens"
        />,
    ]

    return (
        <SliderContainer>
            <AliceCarousel
                autoPlay
                autoPlayInterval={5000}
                animationDuration={150}
                disableDotsControls
                renderPrevButton={renderPreviousButton}
                renderNextButton={renderNextButton}
                responsive={responsive}
                infinite={true}
                items={window.innerWidth < 478 ? itemsMobile : items}
            />
        </SliderContainer>
    )
}
