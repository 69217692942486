import styled from 'styled-components'

export const Back = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    height: 70px;
    padding-left: 88px;
    background-color: #fff;
    padding-right: 246px;
    @media (max-width: 768px) {
        padding-right: 0;
        padding-left: 30px;
    }

    h2 {
        color: #6b97a5;
        margin-left: 20px;
        font-size: 30px;
        font-family: 'Poppins', semi-bold;
        font-weight: 600;
        margin-left: 0;

        @media (max-width: 768px) {
            font-size: 20px;
            font-weight: 500;
        }
    }
`

export const Menu = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 70px;
    justify-content: center;

    @media (max-width: 768px) {
        padding: 0;
    }

    .items {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-self: center;
        padding: 20px;
        border-bottom: solid 3px #cecece;
        text-decoration: none;
        @media (max-width: 768px) {
            padding: 7px;
        }

        h2 {
            color: #6b97a5;
            margin-left: 20px;
            font-size: 14px;
            font-family: 'Poppins';
            font-weight: 600;
            margin: 0px;

            @media (max-width: 768px) {
                font-size: 12px;
                padding-bottom: 10px;
            }
        }
    }
    .items-active {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-self: center;
        padding: 20px;
        border-bottom: solid 3px #6b97a5;
        @media (max-width: 768px) {
            padding: 7px;
        }

        h2 {
            color: #6b97a5;
            margin-left: 20px;
            font-size: 14px;
            font-family: 'Poppins';
            font-weight: 600;
            margin: 0px;
            @media (max-width: 768px) {
                font-size: 12px;
                padding-bottom: 10px;
            }
        }
    }
`

export const CardTitle = styled.div`
    padding: 88px;
    padding-top: 55px;
    padding-right: 0px;
    padding-bottom: 5px;
    padding-left: 0px;
    background-color: #fff;

    @media (max-width: 768px) {
        padding: 33px;
        padding-top: 0px;
        width: 100%;
        background-color: #fff;
    }

    h2 {
        font-family: 'Poppins', semi-bold;
        font-size: 20px;
        color: #6b97a5;
        margin-left: 0;
    }
    h3 {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Poppins', semi-bold;
        font-size: 20px;
        color: #6b97a5;
        margin-left: 0;
    }

    .button-container {
        margin-bottom: 1rem;

        @media (max-width: 768px) {
            display: flex;
            justify-content: flex-end;
        }

        .buttonAddEndereco {
            width: '281px';
            height: '58px';
            border: none;
            background-color: #5095ab;
            font-family: 'Poppins';
            font-weight: bold;
            color: white;
            margin-top: 30px;
            height: 3rem;
            border-radius: 0.438rem;
        }
        button {
            width: 10rem;
            cursor: pointer;
            border: none;
            background-color: #5095ab;
            font-family: 'Poppins';
            font-weight: bold;
            color: white;
            margin-top: 30px;
            height: 3rem;
            border-radius: 0.438rem;
        }
        .add-payment-form {
            background-color: #5095ab;
            border: none;
            color: white;
            font-family: 'Poppins';
            font-weight: bold;
            margin-top: 20px;
            border-radius: 7px;
            font-size: 14px;
            width: 330px;
            height: 57px;
            font-size: 14px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            @media (max-width: 768px) {
                border: solid 1px #5095ab;
                color: white;
                background: #5095ab;
                font-size: 12px;
                width: 324px;
                height: 52px;
            }
        }
        button:focus {
            outline: none;
        }
    }
`

export const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0px;
    border: solid 1px black;
    border-radius: 7px;
    font-family: 'Poppins', semi-bold;
    padding: 35px;

    @media (max-width: 768px) {
        width: 100%;
        border: none;
    }

    label:hover {
        cursor: pointer;
    }

    .image-border {
        padding: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (max-width: 768px) {
            border: none;

            padding: 0px;
        }

        img {
            padding: 5px;
            border: solid 1px black;
            height: 116px;
            border-radius: 112px;
            width: 85px;
            height: 85px;
            object-fit: cover;

            @media (max-width: 768px) {
                width: 58px;
                height: 58px;
            }
        }
    }
    input {
        display: none;
    }
    .infos-container {
        display: flex;
        flex-direction: column;
        margin-left: 25px;

        h2 {
            margin: 0;
            color: black;
            font-size: 19px;
            font-family: 'Poppins';
            font-weight: bold;

            @media (max-width: 768px) {
                font-size: 14px;
            }
        }
        span {
            color: #6b98a6;
            font-size: 15px;
            font-family: 'Poppins', regular;
            font-weight: 600;

            @media (max-width: 768px) {
                font-size: 12px;
            }
        }
    }
`

export const ImgContainer = styled.div`
    position: relative;

    transform: translate(-50% -50%);

    @media (max-width: 768px) {
        display: none;
    }
    @media screen and (max-width: 1366px) {
        right: 236px;
    }
    img {
    }
    #edit {
    }
    #edit:hover {
        cursor: pointer;
    }
`

export const CardContainerOrganizer = styled.div`
    border-radius: 7px;
    font-family: 'Poppins', semi-bold;
    padding: 88px;
    padding-bottom: 20px;
    padding-right: 0px;
    padding-left: 0px;
    padding-top: 55px;
    background-color: #fff;
    @media (max-width: 768px) {
        padding: 0px;
        padding-bottom: 20px;
    }
`

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;

    .limiter {
        width: 72%;
        padding-top: 30px;
        max-width: 980px;

        @media (min-width: 320px) and (max-width: 374px) {
            width: 100%;
            max-width: 320px;
        }
    }

    .container {
        width: 100%;
        height: 100%;
        @media (max-width: 768px) {
            width: 100%;
        }
    }
`
export const DataContainer = styled.div`
	margin-top: 1rem;
	border: solid 1px black;
	border-radius: 7px;
	font-family: 'Poppins', semi-bold;
	padding: 19px;
	padding-right: 30px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 980px;

	button{
		width:8rem;
		height:3rem;
		border: 1px solid black;
		background-color:#5095AB;
		font-family: 'Poppins';
		font-weight: bold;
		color: white;
		border-radius:0.438rem;
		cursor: pointer;
	}

	@media (max-width: 768px) {
		width: 100%;
		border: none;
		padding: 0;
	}

	.org {
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: flex-start;
		justify-content: center;

		h1 {
			font-family: 'Poppins', semi-bold;
			color: #6b97a5;
			font-weight: 600;
		}

		.field-group {
			width: 100%;
		}

		.first-line {
			width:100%;
			display: flex;
			flex-direction: row;

			@media (max-width: 768px) {
				width: 100%;
			}


				.buttonAddEndereco{
					width:9.688rem;
					height:3rem;
					border: none;
					background-color:rgb(5, 165, 191);
					font-family: 'Poppins';
					font-weight: bold;
					color: white;
					border-radius:0.438rem;
					cursor: pointer;
				}
			}

			.title-input-container {
				display: flex;
				flex-direction: column;
				border-radius: 8px;
				margin-bottom: 25px;
				@media (max-width: 768px) {
					width: 100%;
				}
				width: 849px;
				height: 48px;
				
				select {
					height: 6rem;
					margin-left: 0.1rem;
					padding: 0.5rem;
					border-radius: 0.5rem;
					cursor: not-allowed;
					color: black;
				}

				label {
					color: #6b97a5;
					font-family: 'Poppins';
					font-weight: bold;
					font-size: 12px;

					@media (min-width:425px) and (max-width:767px){
						font-size: 11px;
					}
					@media (min-width:375px) and (max-width:424px){
						font-size: 11px;
					}
					@media (min-width:320px) and (max-width:374px){
						font-size: 11px;
					}
				}
				input {
					border: solid 1px black;
					border-radius: 8px;
					padding: 7px;
					color: black;
					font-size: 14px;
					font-family: 'Poppins', regular;
					background-color: #DDDDDD;
					cursor: not-allowed;
				}
				input:focus {
					outline: none;
				}
			}
		}

		.second-line {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			column-gap: 15px;

			@media (max-width: 768px) {
				flex-direction: column;
				width: 100%;
			}
		}

		.third-line {
			width: 849px;
			display: flex;
			flex-direction: row;

			@media (max-width: 768px) {
				flex-direction: column;
				width: 100%;
			}
			.title-input-container {
				display: flex;
				flex-direction: column;
				border-radius: 8px;
				margin-bottom: 25px;
				margin-right: 20px;
				width: 849px;
				height: 48px;

				@media (max-width: 768px) {
					width: 100%;
				}

				label {
					color: #6b97a5;
					font-family: 'Poppins';
					font-weight: bold;
					font-size: 9px;

					@media (min-width:425px) and (max-width:767px){
						font-size: 11px;
					}
					@media (min-width:375px) and (max-width:424px){
						font-size: 11px;
					}
					@media (min-width:320px) and (max-width:374px){
						font-size: 11px;
					}
				}
				input {
					width: 436px;
					height: 35px;
					border: solid 1px black;
					border-radius: 8px;
					padding: 8px;
					color: black;
					font-size: 15px;
					font-family: 'Poppins', regular;
					background-color: #fff;

					@media (max-width: 768px) {
						width: 100%;
					}
				}
				input:focus {
					outline: none;
				}
			}
		}

		.small-input-container {
			display: flex;
			flex-direction: column;
			margin-bottom: 25px;
			label {
				color: #6b97a5;
				font-family: 'Poppins';
				font-weight: bold;
				font-size: 9px;

				@media (min-width:425px) and (max-width:767px){
					font-size: 11px;
				}
				@media (min-width:375px) and (max-width:424px){
					font-size: 11px;
				}
				@media (min-width:320px) and (max-width:374px){
					font-size: 11px;
				}
			}
			input {
				border: solid 1px black;
				border-radius: 8px;
				padding: 7px;
				color: black;
				font-size: 14px;
				font-family: 'Poppins', regular;
				background-color: #DDDDDD;
				cursor: not-allowed;
			}
			input:focus {
				outline: none;
			}
		}

		.remove-acc-container {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			button,
			.confirm-desc,
			.delete,
			.cancel {
				margin-left: 0.5rem;
				margin-right: 0.5rem;
			}

			button {
				background-color: #ff0000;
				border-radius: 5px;
				color: white;
				font-family: 'Poppins';
				font-weight: bold;
				font-size: 12px;
				width: 193px;
				height: 40px;
				border: none;
				cursor: pointer;
			}

			.confirm-desc {
				font-family: 'Poppins';
				font-weight: 600;
				font-size: 14px;
				color: #ca667e;
				margin-left: 0.5rem;
			}

			.delete,
			.cancel {
				display: flex;
				flex-direction: column;
				align-content: center;
				width: 38px;
				height: 38px;
				cursor: pointer;
				padding: 4px;
				border-radius: 999999999px;
				border: 1px solid;
			}

			.delete {
				border-color: #44B4D7;
			}

			.cancel {
				border-color: #CA667E;
			}
		}
	}
`

export const CardShowContainer = styled.div`
    margin-top: 0px;
    border: solid 1px black;
    border-radius: 7px;
    font-family: 'Poppins', semi-bold;
    padding: 10px;
    padding-left: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    @media (max-width: 768px) {
        margin-top: 25px;
        padding-left: 10px;
    }

    .card {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
            @media (max-width: 768px) {
                width: 40px;
                height: 25px;
            }
        }
    }

    h2 {
        color: #888888;
        font-family: 'Poppins';
        font-weight: bold;
        font-size: 17px;
        margin: 0;
        margin-left: 15px;
        @media (max-width: 768px) {
            display: flex;
            font-size: 14px;
        }
    }
`
export const CardShowContainerAccount = styled.div`
    margin-top: 0px;

    border-radius: 7px;
    font-family: 'Poppins', semi-bold;

    padding-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;

    .card {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    h2 {
        color: #888888;
        font-family: 'Poppins';
        font-weight: bold;
        font-size: 24px;
        margin: 0;
        margin-left: 15px;
    }
`
export const ContainerAccountMobile = styled.div`
    display: flex;
    flex-direction: column;
    border: solid 1px black;
    border-radius: 7px;
    padding: 11px 16px;
    row-gap: 14px;
    margin-top: 25px;

    .input-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        label {
            color: black;
            font-size: 'Poppins';
            font-weight: bold;
            font-size: 9px;
        }
        span {
            color: black;
            font-size: 13px;
            font-family: 'Poppins';
            font-weight: 400;
        }
    }
    .first-line,
    .second-line,
    .third-line {
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    .second-line {
        justify-content: space-between;
    }
`

export const ModalContainer = styled.div`
    height: 100%;
    .modal-title {
        padding: 20px;
        background-color: #fff;

        h2 {
            color: #6b97a5;
            font-family: 'Poppins';
            font-weight: bold;
            font-size: 17px;
        }
    }
    .line {
        border-bottom: solid 1px black;
    }

    .modal-inputs {
        padding: 61px;
        padding-top: 8px;
        background-color: #fff;

        @media (max-width: 768px) {
            padding-top: 0px;
            padding: 0px;
        }

        .modal-actions {
            padding: 22px;
            display: flex;
            flex-direction: row;
            @media (max-width: 768px) {
                justify-content: center;
            }
            justify-content: flex-end;

            .buttons-container {
                padding: 10px;

                .positive-button {
                    background-color: #6b97a5;
                    border: none;
                    padding: 20px;
                    cursor: pointer;
                    color: white;
                    border-radius: 7px;
                    font-family: 'Poppins';
                    font-weight: bold;
                    font-size: 18px;

                    @media (max-width: 768px) {
                        height: 40px;
                        width: 120px;
                        padding: 5px;
                        font-size: 12px;
                    }
                }
                .negative-button {
                    background-color: #ca667e;
                    border: none;
                    cursor: pointer;
                    padding: 20px;
                    color: white;
                    border-radius: 7px;
                    font-family: 'Poppins';
                    font-weight: bold;
                    font-size: 18px;
                    @media (max-width: 768px) {
                        height: 40px;
                        padding: 5px;
                        width: 120px;
                        font-size: 12px;
                    }
                }
            }
        }

        .third-line {
            display: flex;
            flex-direction: row;
            width: 100%;
            @media (max-width: 768px) {
                flex-direction: column;
                width: 100%;
            }
        }

        .fourth-line {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            @media (max-width: 768px) {
                flex-direction: column;
                width: 100%;
                row-gap: 25px;
            }
        }

        .first-line,
        .second-line,
        .third-line {
            .modal-inputs-organizer {
                display: flex;
                flex-direction: column;
                padding: 18px;

                label {
                    margin-bottom: 4px;
                    color: #6b97a5;
                    font-family: 'Poppins';
                    font-weight: 600;
                    font-size: 15px;
                }
                span {
                    color: red;
                    font-size: 0.6vw;
                    margin-top: 5px;
                    font-family: 'Poppins', regular;
                    @media (max-width: 768px) {
                        font-size: 12px;
                    }
                }
                input {
                    height: 35px;
                    border: solid 1px black;
                    border-radius: 7px;
                    font-family: 'Poppins', regular;
                    font-size: 14px;
                    color: black;
                    padding: 3px;
                    background-color: #fff;
                }
                input[type='number']::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                }
                input:focus:-ms-input-placeholder {
                    color: transparent;
                }
                input:focus::-webkit-input-placeholder {
                    color: transparent;
                }
                input[type='number'] {
                    -moz-appearance: textfield;
                    appearance: textfield;
                }

                input:focus {
                    outline: none;
                }

                select {
                    height: 35px;
                    border: solid 1px black;
                    cursor: pointer;
                    border-radius: 7px;
                    font-family: 'Poppins', regular;
                    color: black;
                    padding: 5px;
                    background-color: #fff;
                }
                select:focus {
                    outline: none;
                }
            }

            .modal-inputs-organizer-modal {
                display: flex;
                flex-direction: column;
                padding: 6px;
                border: solid 1px black;
                border-radius: 7px;
                font-family: 'Poppins', regular;
                label {
                    margin-bottom: 4px;
                    color: #6b97a5;
                    font-family: 'Poppins';
                    font-weight: 600;
                    font-size: 10px;
                }
                input {
                    border: none;
                    border-radius: 7px;
                    font-family: 'Poppins', regular;
                    color: black;
                    padding: 5px;
                    font-size: 13px;
                    background-color: white;
                }
                input:focus:-ms-input-placeholder {
                    color: transparent;
                }
                input:focus::-webkit-input-placeholder {
                    color: transparent;
                }
                input:focus {
                    outline: none;
                }

                select {
                    height: 51px;
                    border: solid 1px black;
                    border-radius: 7px;
                    font-family: 'Poppins', regular;
                    color: black;
                    padding: 5px;
                    background-color: #fff;
                }
                select:focus {
                    outline: none;
                }
            }
        }
        .fourth-line {
            padding-top: 25px;
            .modal-inputs-organizer {
                display: flex;
                flex-direction: column;
                padding: 18px;

                label {
                    margin-bottom: 4px;
                    color: #6b97a5;
                    font-family: 'Poppins';
                    font-weight: 600;
                    font-size: 15px;
                }
                span {
                    color: red;
                    font-size: 0.6vw;
                    margin-top: 5px;
                    font-family: 'Poppins', regular;
                }
                input {
                    height: 35px;
                    border: solid 1px black;
                    border-radius: 7px;
                    font-family: 'Poppins', regular;
                    font-size: 14px;
                    color: black;
                    padding: 3px;
                    background-color: #fff;
                }
                input[type='number']::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                }
                input:focus:-ms-input-placeholder {
                    color: transparent;
                }
                input:focus::-webkit-input-placeholder {
                    color: transparent;
                }
                input[type='number'] {
                    -moz-appearance: textfield;
                    appearance: textfield;
                }

                input:focus {
                    outline: none;
                }

                select {
                    height: 35px;
                    border: solid 1px black;
                    cursor: pointer;
                    border-radius: 7px;
                    font-family: 'Poppins', regular;
                    color: black;
                    padding: 5px;
                    background-color: #fff;
                }
                select:focus {
                    outline: none;
                }
            }

            .modal-inputs-organizer-modal {
                display: flex;
                flex-direction: column;
                padding: 6px;
                border: solid 1px black;
                border-radius: 7px;
                font-family: 'Poppins', regular;
                label {
                    margin-bottom: 4px;
                    color: #6b97a5;
                    font-family: 'Poppins';
                    font-weight: 600;
                    font-size: 10px;
                }
                input {
                    border: none;
                    border-radius: 7px;
                    font-family: 'Poppins', regular;
                    color: black;
                    padding: 5px;
                    font-size: 13px;
                    background-color: white;
                }
                input:focus:-ms-input-placeholder {
                    color: transparent;
                }
                input:focus::-webkit-input-placeholder {
                    color: transparent;
                }
                input:focus {
                    outline: none;
                }
                select {
                    height: 51px;
                    border: solid 1px black;
                    border-radius: 7px;
                    font-family: 'Poppins', regular;
                    color: black;
                    padding: 5px;
                    background-color: #fff;
                }
                select:focus {
                    outline: none;
                }
            }
        }
    }
`
