import styled from 'styled-components';
export const BackButton = styled.form`
`
export const Container = styled.form`
		display:flex;
		flex-direction: column;
		text-align: center;
		align-content: center;
		background-color:#fff;

	.back-button {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-top: 2.5rem;
    	margin-left: 0rem;
		cursor: pointer;
		width:20%;
		
		.IconVoltar{
			height: 23px;
    		width: 33px;
			color:#6b98a6;
		}
		span {
			color: #6b98a6;
			font-family: 'Poppins';
			font-weight: 600;
			font-size: 15px;
		}
	}
	header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		h1 {
			color: #6b98a6;
			font-size: 35px;
			font-family: 'Poppins';
			font-weight: 200;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			text-align: center;
			padding-top:2.5rem;

			@media (min-width:425px) and (max-width:767px){
				font-size:25px;
				word-break: break-word;
				font-weight:400;
				padding-top:1rem;
			}
			@media (min-width:375px) and (max-width:424px){
				font-size:25px;
				word-break: break-word;
				font-weight:400;
				padding-top:1rem;
			}
			@media (min-width:320px) and (max-width:374px){
				font-size:25px;
				word-break: break-word;
				font-weight:400;
				padding-top:1rem;
			}
		}
	}
	.page-sub-title {
		display: flex;
		text-align: center;
		justify-content: center;
		h4 {
			color: #6b98a6;
			font-family: 'Poppins';
			font-weight: 600;
			font-size: 20px;
			text-align: center;
			width:100%;
			margin-top:2.5rem;
			margin-bottom: 2.5rem;

			@media (min-width:425px) and (max-width:767px){
				font-weight: 300;
				font-size:15px;
				margin-top:1rem;
				margin-bottom:1rem;
			}
			@media (min-width:375px) and (max-width:424px){
				font-weight: 300;
				font-size:15px;
				margin-top:1rem;
				margin-bottom:1rem;
			}
			@media (min-width:320px) and (max-width:374px){
				font-weight: 300;
				font-size:15px;
				margin-top:1rem;
				margin-bottom:1rem;
			}
		}
	}
	.email-input-container,
	.message-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;

		label {
			color: black;
			font-size: 15px;
			font-family: 'Poppins';
			font-weight: 600;
			padding-bottom: 1rem;
		}
		input {
			border: 1px solid black;
			border-radius: 7px;
			width: 25%;
			padding: 13px;
			&:focus {
				outline: none;
			}
			@media (min-width:425px) and (max-width:767px){
				width:70%
			}
			@media (min-width:375px) and (max-width:424px){
				width:70%
			}
			@media (min-width:320px) and (max-width:374px){
				width:70%
			}
 		}
		textarea {
			border: 1px solid black;
			border-radius: 7px;
			width: 50%;
			padding: 13px;
			&:focus {
				outline: none;
			}
		}
	}
	.email-input-container {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		input {
			height: 40px;

			@media (min-width:425px) and (max-width:767px){
				height:25px;
			}
			@media (min-width:375px) and (max-width:424px){
				height:25px;
			}
			@media (min-width:320px) and (max-width:374px){
				height:25px;
			}
		}
	}
	.message-container {
		margin-top:2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align:center;
		textarea {
			height: 200px;
			resize: none;
			width: 25%;

			@media (min-width:425px) and (max-width:767px){
				width: 70%;
			}
			@media (min-width:375px) and (max-width:424px){
				width: 70%;
			}
			@media (min-width:320px) and (max-width:374px){
				width: 70%;
			}
		}
	}
	.form-action-button-container {
		grid-column-start: 4;
		grid-column-end: 4;
		grid-row-start: 6;
		grid-row-end: 6;
		display: flex;
		justify-content: center;
		align-items: center;

		button {
			height: 50px;
			background: var(--unnamed-color-e6ad00) 0% 0% no-repeat padding-box;
			background: #6b97a5 0% 0% no-repeat padding-box;
			border-radius: 8px;
			color: white;
			font-size: 16px;
			font-family: 'Poppins';
			font-weight: bold;
			border: none;
			width: 20%;
			cursor: pointer;
			margin-top:2rem;
		}
	}
`;
