import React from 'react';
import AdmProfile from '../../components/AdmProfile/AdmProfile';
import Layout from '../../components/Layout/Layout';
const AdmProfilePage = () => {
	return (
		<Layout>
			<AdmProfile />
		</Layout>
	);
};

export default AdmProfilePage;
