import styled from 'styled-components';

export const Loading = styled.div`
	background-color: white;
	position: relative;
	display: grid;
	place-items: center;

	width: 100%;
	height: 100vh;

	.pulsing-macaw {
		height: 18vh;
		width: auto;
		animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
	}
`;
