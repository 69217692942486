import styled from 'styled-components';

export const Container = styled.div`
	border: solid 1px black;
	border-radius: 8px;
	padding: 20px;
	width: 100%;
	min-width: 100%;
	margin-bottom: 20px;
	padding-bottom: 0px;

	@media (min-width:425px) and (max-width:768px){
		padding:15px;
	}
	@media (min-width:375px) and (max-width:424px){
		padding:15px;
	}
	@media (min-width:320px) and (max-width:374px){
		padding:10px;
	}

	.title {
		display: flex;
		justify-content: space-between;

		h2 {
			font-size: min(15px, 1.5vw);
			@media (min-width:425px) and (max-width:768px){
				font-size: 13px;
			}
			@media (min-width:375px) and (max-width:424px){
				font-size: 12px;
			}
			@media (min-width:320px) and (max-width:374px){
				font-size: 11px;
			}
		}

		.img {
			transform: rotate(0deg);
			transition: all 0.3s;

			&:hover {
				cursor: pointer;
			}
		}
		.rotate {
			transform: rotate(180deg);
			transition: all 0.3s;
		}
	}
	p {
		color: black;
		font-size: 12px;
		height: max-content;
		transition: all 0.3s;
		margin-bottom: 22px;
		word-break: break-word;
		padding-bottom: 20px;
	}
`;
