import styled from 'styled-components';

export const ModalContainer = styled.div`
	width: 100%;
	height: 100%;

	.modal-top {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		figure {
			svg {
				width: 67px;
				height: 67px;
				fill: #db0707;
				.a {
					fill: #db0707;
				}
			}
		}

		.modal-infos {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-bottom: 19px;
			h2 {
				color: #6b98a6;
				font-family: 'Poppins';
				font-weight: 600;
				font-size: 16px;
			}
			p {
				text-align: center;
				font-family: 'Poppins';
				font-weight: 400;
				color: black;
				font-size: 12px;
			}
		}
	}
	.modal-middle {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		label {
			color: #969696;
			font-family: 'Poppins';
			font-weight: bold;
			font-size: 9px;
			margin-bottom: 10px;
		}
		span {
			width: 100%;
			height: 107px;
			border: 1px solid #dbdbdb;
			padding: 15px;
			color: black;
			font-family: 'Poppins';
			font-weight: 400;
			line-height: 20px;
			overflow-y: auto;
		}
	}
	.modal-bottom {
		height: max-content;
		display: flex;
		flex-direction: column;
		margin-top: 40px;
		.close-modal {
			margin-bottom: 17px;
			height: 39px;
			background: #e96d79;
			border: none;
			color: white;
			font-family: 'Poppins';
			font-weight: bold;
			border-radius: 6px;

			&:hover {
				cursor: pointer;
			}
			&:focus {
				outline: none;
			}
		}
		.contact-adm {
			height: 39px;
			border: none;
			color: white;
			font-family: 'Poppins';
			font-weight: bold;
			background: #05a5bf;
			border-radius: 6px;
			&:hover {
				cursor: pointer;
			}
			&:focus {
				outline: none;
			}
		}
	}
`;
