import {UserAction, UserState} from '../models/IUserAccount';

const initialState: UserState = {
	currentUser: undefined,
};

export default function UserReducer(state = initialState, action: UserAction) {
	switch (action.type) {
		case 'UPDATE_USER':
			action.user.notReadNotifications = false;
			return { ...state, currentUser: action.user };

		case 'REMOVE_USER':
			return { ...state, currentUser: undefined };

		case 'UPDATE_USER_ADDRESS':
			return {
				...state,
				currentUser: {
					...state.currentUser,
					ProfileAddress: [...action.userAddres],
				},
			};
		case 'INSERT_USER_ADDRESS':
			return {
				...state,
				currentUser: {
					...state.currentUser,
					ProfileAddress: [
						...state.currentUser?.ProfileAddress!,
						action.address,
					],
				},
			};

		case 'SET_DELIVERY_ADDRESS':
			return {
				...state,
				currentUser: {
					...state.currentUser,
					ProfileAddress: [
						...state.currentUser!.ProfileAddress!.map((address) => {
							if (address.id === action.addressId) {
								return {
									...address,
									deliveryAddress: action.bool,
								};
							} else {
								return { ...address };
							}
						}),
					],
				},
			};

		case 'UPDATE_USER_TOKEN':
			return {
				...state,
				currentUser: {
					...state.currentUser,
					token: action.token,
				},
			};

		case 'UPDATE_BANK_ACCOUNT':
			return {
				...state,
				currentUser: {
					...state.currentUser,
					bankAccountId: action.bankAccountId,
				},
			};

		case 'INSERT_USER_PURCHASE_CARD_ID':
			return {
				...state,
				currentUser: {
					...state.currentUser,
					cardId: action.cardId,
					formaDePagamento:'credit_card'
				},
			};
		case 'INSERT_FORM_OF_PAYMENT':
			return {
				...state,
				currentUser: {
					...state.currentUser,
					formaDePagamento:action.formaDePagamento
				}
			};

		case 'UPDATE_NOT_READ_NOTIFICATIONS':
			return {
				...state,
				currentUser: {
					...state.currentUser,
					notReadNotifications: action.readNotification,
				},
			};
		case 'REMOVE_USER_PURCHASE_CARD_ID':
			return {
				...state,
				currentUser: {
					...state.currentUser,
					cardId: null,
				},
			};

		case 'INSERT_PURCHASE':
			if (
				state.currentUser?.Purchases &&
				state.currentUser?.Purchases?.length > 0
			) {
				return {
					...state,

					currentUser: {
						...state.currentUser,
						Purchases: [
							...state.currentUser.Purchases,
							action.UserPurchases,
						],
					},
				};
			} else {
				return {
					...state,

					currentUser: {
						...state.currentUser,
						Purchases: [action.UserPurchases],
					},
				};
			}

		case 'UPDATE_MELHOR_ENVIO_PAYMENT_METHOD':
			return {
				...state,
				currentUser: {
					...state.currentUser,
					melhorEnvioPaymentMethod: action.paymentMethod,
				},
			};
		case 'REMOVE_MELHOR_ENVIO_PAYMENT_METHOD':
			return {
				...state,
				currentUser: {
					...state.currentUser,
					melhorEnvioPaymentMethod: null,
				},
			};
		case 'UPDATE_MELHOR_ENVIO_TOKEN':
			return {
				...state,
				currentUser: {
					...state.currentUser,
					melhorEnvioToken: action.token,
				},
			};

		default:
			return state;
	}
}
