import styled from 'styled-components';

export const Container = styled.div`
display:flex;
flex-direction:column;
padding: 20px;
height: 100%;
    .flex-direction-container-modal{
        display: flex;
        justify-content: center;
        margin-bottom: 1.5rem;
        .title-contact-seller-modal{
            display: flex;
            .title-contact-seller-modal-props{
                font-size: 18px;
                font-family: 'Poppins', semi-bold;
                color: #6b98a6;
                font-weight: 610;
            }
        }
    }
    .style-title-h4{
        margin:0;
        padding-bottom:1.5rem;
        font-size: 13px;
        font-family: 'Poppins', semi-bold;
        color: #6b98a6;
    }
    .contact-seller-informations-modal{
        display: flex;
        flex-direction: row;
        margin-bottom: 2rem;
         h2{
            font-size: 16px;
            font-family: 'Poppins', semi-bold;
            color: #6b98a6;
            font-weight: 610;
            margin-right: 0.5rem;
            margin-bottom: 0;
        }   
        .h2-telefone{
            font-size: 16px;
            font-family: 'Poppins', semi-bold;
            color: #6b98a6;
            font-weight: 610;
            margin-right: 0.5rem;
            margin-bottom: 0;
            padding-top:5px;
        }
        .h2-mail{
            font-size: 16px;
            font-family: 'Poppins', semi-bold;
            color: #6b98a6;
            font-weight: 610;
            margin-bottom: 0;
            padding-top:5px;
            margin-top:0;
            padding-left:2rem;
        }
        .style-icon-phone{
            width:25px;
            height:25px;
            cursor:pointer;
            margin-left:1rem;
        }
        .style-icon-whats{
            width:25px;
            height:25px;
            cursor:pointer;
        }
        .icon-mail{
            width:25px;
            height:25px;
            cursor:pointer;
        }
        span{
            font-family: 'Poppins', semi-bold;
            color: black;
            font-weight: 350;
            padding-top: 0.2rem;
        }
        @media (min-width:320px) and (max-width:374px){
            h2{
                font-size:15px;
            }
            span{
                font-size:13px;
            }
        }
    }
    .contact-seller-button-container{
        display: flex;
        flex-direction:row;
        justify-content: center;
        cursor: pointer;
      
        .contact-seller-button{
            width: 60%;
            flex-direction: row;
            display: flex;
            color:white;
            justify-content: center;
            height: 2.4rem;
            background-color: #ff0000;
            border-radius: 5px;
            font-family: 'Poppins', semi-bold;
            font-size:13px;
            border: 1px solid black;
            padding-top: 0.6rem;
            cursor: pointer;
        }
    }
    
`;
