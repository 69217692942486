import styled from 'styled-components';

export const ModalContainer = styled.form`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	h2 {
		color: #ca667e;
		font-size: 15px;
		font-family: 'Poppins';
		font-weight: 600;
	}

	.select-container {
		display: flex;
		flex-direction: column;
		width: 100%;

		label {
			color: #6b97a5;
			font-size: 12px;
			font-family: 'Poppins';
			font-weight: 400;
			margin-bottom: 4px;
		}
		select {
			height: 30px;
			border: 1px solid #cdcdcd;
			border-radius: 7px;
		}
	}
	button {
		margin-top: 20px;
		width: 112px;
		height: 30px;
		background: var(--unnamed-color-69b64a) 0% 0% no-repeat padding-box;
		background: #69b64a 0% 0% no-repeat padding-box;
		border-radius: 12px;
		color: white;
		font-family: 'Poppins';
		font-weight: 400;
		border: none;
		&:hover {
			cursor: pointer;
		}
		&:focus {
			outline: none;
		}
	}
`;
