import {
	EditedProductAction,
	EditedProductState,
} from '../models/EditedProduct';
import { ProductState, ProductAction } from '../models/IProduct';

const initialState: EditedProductState = {
	editedProduct: undefined,
};

export default function EditedProductReducer(
	state = initialState,
	action: EditedProductAction
) {
	switch (action.type) {
		case 'UPDATE_PRODUCT':
			return { ...state, editedProduct: action.editedProduct };

		default:
			return state;
	}
}
