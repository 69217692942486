import styled from 'styled-components';

export const SliderContainer = styled.div`
	.alice-carousel__prev-btn,
	.alice-carousel__next-btn {
		display: flex;
		flex-direction: column;
		align-content: center;
		justify-content: center;
		position: absolute;
		top: 32%;
		height: 120px;
		width: 43px;
		background-color:#fff;
		opacity: 8.3;
		text-align: unset;
		cursor: pointer;
		background-color:#fff;
		@media (max-width: 478px) {
            display: none;
        }
        @media (min-width: 320px) and (max-width: 375px) {
            display: none;
        }
	}

	.alice-carousel__prev-btn {
		left: 0;
		border-radius: 0 3.8rem 3.8rem 0;
		box-shadow: 4px 3px 9px #00000014;
	}

	.alice-carousel__next-btn {
		right: 0;
		border-radius: 3.8rem 0 0 3.8rem;
		box-shadow: -4px 3px 9px #00000014;
	}
`;
