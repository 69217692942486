import React, { useCallback, useEffect, useRef, useState } from 'react';

import brand from '../../assets/icons/brand.svg';
import pencil from '../../assets/icons/pencil.svg';
import deleteIcon from '../../assets/icons/delete.svg';
import deleteCartProduct from '../../assets/icons/deleteCartProduct.svg';

import IUserAccount from '../../models/IUserAccount';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store';

import { useHistory, Route, Switch, useRouteMatch } from 'react-router-dom';
import {
	CardTitle,
	CardShowContainer,
	ModalContainer,
	PageContainer,
	Back,
} from './Style';
import {
	useCreateCardMutation,
	useGetAllCardsQuery,
	Card,
	useDeleteCardMutation,
	useGetAllCardsLazyQuery,
} from '../../generated/graphql';
import ProfileCard from './ProfileCard';
import ProfileCardControlCard from './ProfileCardControlCard';
import IntegrationsCard from './IntegrationsCard';
import { Modal } from 'semantic-ui-react';
import moment from 'moment';
import BankAccountModal from './BankAccountModal';
import { FieldError, useForm } from 'react-hook-form';
import AlertModal, { ModalHandles } from '../AlertModal/AlertModal';
import ProfileControlCardMobile from './ProfileControlCardMobile/ProfileControlCardMobile';
import { setCpfMaskFunc } from '../../utils/Masks';
import { toast, ToastContainer } from 'react-toastify'

interface RegisterCardValues {
	cardName: string;
	cardDateBirth: string;
	cardPhone: string;
	cardCpf: string;
	cardNumber: string;
	cardCvv: string;
	cardValidMonth: string;
	cardValidYear: string;
}

const UserProfile: React.FC = () => {

	const user: IUserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const [createCardMutation] = useCreateCardMutation();

	const [getAllCards,{ data, loading }] = useGetAllCardsLazyQuery({fetchPolicy:'no-cache'});

	const { register, handleSubmit, formState:{errors} } = useForm<RegisterCardValues>({
		mode: 'onSubmit',
		reValidateMode: 'onChange'
	})
	const [deleteCardMutation] = useDeleteCardMutation();
	const { path, url } = useRouteMatch();
	const [state, setState] = useState({ dimmer: undefined, open: false });
	const show = (dimmer: any) => setState({ dimmer: dimmer, open: true });
	const [cpfMask, setCpfMask] = useState<string>('');
	const modalRef = useRef<ModalHandles>(null);
	const [myCards,setMyCards] = useState<Card[]>([])
	const [operationMessage, setOperationMessage] = useState<string>('');
	const [reload, setReload] = useState<boolean>(true);

	const handleOpenModal = useCallback((status: boolean) => {
		if (!status) {
			setOperationMessage('Falha ao cadastrar cartão ');
			setReload(false);
		} else {
			setOperationMessage('Cartão cadastrado com sucesso!');
			setReload(true);
		}
		modalRef.current?.handleOpenModal();
	}, []);

	const verifyErrors = (error?: FieldError) => {
		if (error) {
			return 'solid 1px red';
		} else {
			return 'solid 1px black';
		}
	};

	document.addEventListener('keypress',(event)=>{
		if(event.key ==='Enter'){
		   handleSubmit(onSubmit)()
		}
	 })

	 useEffect(()=>{	
		if(data){
			setMyCards(data.getAllCards)
		}
	 },[data])

	 useEffect(()=>{
		if(user){
			getAllCards({
				variables:{
					userId:user.id
				}
			})
		}
	 },[])

	const onSubmit = async (data: any) => {
		try {
				await createCardMutation({
					variables: {
						userId: user.id,
						card_holder_name: data.cardName,
						card_cvv: data.cardCvv,
						card_expiration_date:
							data.cardValidMonth + data.cardValidYear,
						card_number: data.cardNumber,
						cpf: user.cpf!
					},
				});
			handleOpenModal(true);
		} catch (err: any) {
			handleOpenModal(false);
		}
	};

	const deleteCard = async (id: string) => {
		try {
			await deleteCardMutation({ variables: { id: id } });
			toast.success('Cartão de crédito removido com sucesso!',{
				position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                type:'success',
                draggable: true,
                progress: undefined,
			})
			getAllCards({variables:{
				userId:user.id
			}})
		} catch(error:any) {
			toast.error(`${error.message}`,{
				position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                type:'error',
                draggable: true,
                progress: undefined,
			})
		}
	};

	return (
		<>
			<Back>
				<h2>Meu Perfil</h2>
			</Back>
			<PageContainer>
				<ToastContainer/>
					<div className="limiter">
						<div style={{ width: '100%', display: 'flex' }}>
							{window.outerWidth > 768 ? (
								<ProfileCardControlCard></ProfileCardControlCard>
							) : (
								<ProfileControlCardMobile />
							)}
						</div>
						<Switch>
							<Route path={`${path}/data/:locale`}>
								<ProfileCard />
							</Route>
						</Switch>
						<Switch>
							<Route path={`${path}/card`}>
								<AlertModal
									ref={modalRef}
									AreaTitle={'Cadastro de cartão'}
									DescriptionText={operationMessage}
									reload={reload}
								/>
								<Modal
									dimmer={state.dimmer}
									open={state.open}
									onClose={() => state.open == false}
								>
									<ModalContainer>
										<form onSubmit={handleSubmit(onSubmit)}>
											<div className="modal-title">
												<h2>Cadastro de cartão</h2>
											</div>
											<div className="line"></div>
											<div className="modal-inputs">
												<div className="first-line">
													<div className="modal-inputs-organizer">
														<label>Nome</label>
														<input
															type="text"
															{...register('cardName',{
																required: true,
															})}
															// defaultValue={editedProduct?.title}
															style={{
																border: verifyErrors(
																	errors.cardName
																),
															}}
														/>
													</div>
												</div>
												<div className="second-line">
													<div
														className="modal-inputs-organizer"
														style={{ flex: '3' }}
													>
														<label>CPF/CNPJ</label>
														<input
															type="text"
															value={cpfMask}
															placeholder="xxx.xxx.xxx-xx"
															{...register('cardCpf',{
																required: true,
																pattern: {
																	value: /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})$/,
																	message:
																		'Insira um cpf/cnpj valido',
																},
															})}
															onChange={(e) => {
																setCpfMask(
																	setCpfMaskFunc(
																		e.target.value
																	)
																);
															}}
															style={{
																border: verifyErrors(
																	errors.cardCpf
																),
															}}
														/>
													</div>
												</div>
												<div className="third-line">
													<div
														className="modal-inputs-organizer"
														style={{ flex: '5' }}
													>
														<label>
															Numero do cartão:
														</label>
														<input
															type="text"
															{...register('cardNumber',{
																required:
																	'Campo obrigatório',
															})}
															maxLength={16}
															style={{
																border: verifyErrors(
																	errors.cardNumber
																),
															}}
															placeholder="xxxx xxxx xxxx xxxx"
														/>
													</div>
													<div
														className="modal-inputs-organizer"
														style={{ flex: '1' }}
													>
														<label>Validade</label>
														<div
															style={{
																display: 'flex',
																flexDirection:
																	'row',
																alignItems:
																	'center',
															}}
														>
															<select
																{...register('cardValidMonth',{
																	required:
																		'Campo obrigatório',
																})}
																style={{
																	marginRight:
																		'25px',
																	border: verifyErrors(
																		errors.cardValidMonth
																	),
																}}
															>
																<option value="">
																	Mês
																</option>
																<option value="01">
																	Janeiro
																</option>
																<option value="02">
																	Fevereiro
																</option>
																<option value="03">
																	Março
																</option>
																<option value="04">
																	Abril
																</option>
																<option value="05">
																	Maio
																</option>
																<option value="06">
																	Junho
																</option>
																<option value="07">
																	Julho
																</option>
																<option value="08">
																	Agosto
																</option>
																<option value="09">
																	Setembro
																</option>
																<option value="10">
																	Outubro
																</option>
																<option value="11">
																	Novembro
																</option>
																<option value="12">
																	Dezembro
																</option>
															</select>
														</div>
													</div>
													<div className="modal-inputs-organizer">
														<label>Ano:</label>
														<input
															type='text'
															placeholder='xx'
															maxLength={2}
															{...register('cardValidYear',{
																required:
																	'Campo obrigatório',
															})}
															style={{
																width:'5rem',
																marginRight:
																	'25px',
																border: verifyErrors(
																	errors.cardValidYear
																),
															}}
																/>
													</div>
													<div
														className="modal-inputs-organizer"
														style={{ flex: '1' ,width:'12rem'}}
													>
														<label>
															Código de seg:
														</label>
														<input
															type="text"
															{...register('cardCvv',{
																required:
																	'Campo obrigatório',
															})}
															maxLength={3}
															style={{
																marginRight:
																	'25px',
																border: verifyErrors(
																	errors.cardCvv
																),
															}}
															placeholder="xxx"
														/>
													</div>
												</div>
												<div className="modal-actions">
													<div className="buttons-container">
														<button
															onClick={() =>
																setState({
																	dimmer: undefined,
																	open: false,
																})
															}
															className="negative-button"
														>
															Cancelar
														</button>
													</div>
													<div className="buttons-container">
														<button
															className="positive-button"
															type="submit"
														>
															Cadastrar Cartão
														</button>
													</div>
												</div>
											</div>
										</form>
									</ModalContainer>
								</Modal>
								<CardTitle>
									{myCards.map(
											(card: Card, index: number) => (
												<>
													<CardShowContainer>
														<div
															className="card"
															style={{
																display: 'flex',
															}}
															key={index}
														>
															<img
																src={brand}
																alt=" "
															/>{' '}
															<h2>
																Cartão de
																crédito - Final{' '}
																{
																	card.last_digits
																}
															</h2>
														</div>
														<div>
															<img
																src={
																	deleteCartProduct
																}
																onClick={() =>
																	deleteCard(
																		card.id
																	)
																}
																alt=" "
															/>
														</div>
													</CardShowContainer>
												</>
											)
										)}

									<div className="button-container">
										<button
											onClick={() => show('blurring')}
											className="add-payment-form"
										>
											Adicionar nova forma de pagamento
										</button>
									</div>
								</CardTitle>
							</Route>
						</Switch>
						<Switch>
							<Route path={`${path}/bank-account`}>
								<BankAccountModal />
							</Route>
						</Switch>
						<Switch>
							<Route path={`${path}/integrations`}>
								<IntegrationsCard />
							</Route>
						</Switch>
					</div>
			</PageContainer>
		</>
	);
};
export default UserProfile;
