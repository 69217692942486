import {
	UserCustomizationAction,
	UserCustomizationsState,
} from '../models/IUserCustomizations';

const initialState: UserCustomizationsState = {
	currentCustomizations: undefined,
};

export default function UserCustomizationsReducer(
	state = initialState,
	action: UserCustomizationAction
) {
	switch (action.type) {
		/* case 'UPDATE_BACKGROUND_TYPE':
			return {
				...state,
				currentCustomizations: {
					...state.currentCustomizations,
					background: {
						...state.currentCustomizations?.background,
						type: action.backgroundType,
					},
				},
			};

		case 'UPDATE_BACKGROUND_PATH':
			return {
				...state,
				currentCustomizations: {
					...state.currentCustomizations,
					background: {
						...state.currentCustomizations?.background,
						path: action.path,
					},
				},
			};

		case 'UPDATE_BACKGROUND_COLOR':
			return {
				...state,
				currentCustomizations: {
					...state.currentCustomizations,
					background: {
						...state.currentCustomizations?.background,
						color: action.backgroundColor,
					},
				},
			};

		case 'UPDATE_BRAND_TYPE':
			return {
				...state,
				currentCustomizations: {
					...state.currentCustomizations,
					brand: {
						...state.currentCustomizations?.brand,
						type: action.brandType,
					},
				},
			};

		case 'UPDATE_BRAND_PATH':
			return {
				...state,
				currentCustomizations: {
					...state.currentCustomizations,
					brand: {
						...state.currentCustomizations?.brand,
						path: action.path,
					},
				},
			};

		
		

		
		case 'UPDATE_BACKGROUND_BANNER_ID':
			return {
				...state,
				currentCustomizations: {
					...state.currentCustomizations,
					background: {
						...state.currentCustomizations?.background,
						bannerId: action.id,
					},
				},
			}; */

		case 'INSERT_USER_CUSTOMIZATIONS':
			return {
				...state,
				currentCustomizations: action.userCustomizations,
			};

		case 'UPDATE_SHOP_NAME':
			return {
				...state,
				currentCustomizations: {
					...state.currentCustomizations,
					ShopName: action.brandName,
				},
			};
		case 'UPDATE_BRAND_TEXT_COLOR':
			return {
				...state,
				currentCustomizations: {
					...state.currentCustomizations,
					bannerTextColor: action.brandTextColor,
					hasBanner: false,
				},
			};
		case 'UPDATE_BRAND_COLOR':
			return {
				...state,
				currentCustomizations: {
					...state.currentCustomizations,
					brandColor: action.brandColor,
					hasBanner: false,
				},
			};
		case 'UPDATE_BACKGROUND_COLOR':
			return {
				...state,
				currentCustomizations: {
					...state.currentCustomizations,
					background: action.backgroundColor,
					hasBackground: false,
				},
			};
		case 'SET_CUSTOM_BACKGROUND':
			return {
				...state,
				currentCustomizations: {
					...state.currentCustomizations,
					background: action.background,
					hasBackground: true,
				},
			};
		case 'SET_CUSTOM_BRAND':
			return {
				...state,
				currentCustomizations: {
					...state.currentCustomizations,
					brand: action.brand,
					hasBanner: true,
				},
			};

		default:
			return state;
	}
}
