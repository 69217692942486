import styled from 'styled-components';

export const MainContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	padding-top: 40px;
	width: 1146px;
	background-color: #fff;
	.class-column {
		display: flex;
		flex-direction: column;
		align-items: center;

		.card-container {
			display: flex;
			flex-direction: column;
			box-shadow: 3px 2px 9px #00000014;
			border-radius: 8px;
			margin-bottom: 20px;
			width: 587px;
			background-color: white;

			.card-header {
				background-color: #05a5bf;
				border-top-left-radius: 7px;
				border-top-right-radius: 7px;
				height: 53px;
				display: flex;
				flex-direction: row;
				align-items: center;

				h2 {
					margin-left: 10px;
					color: #ffffff;
					font-family: 'Poppins', semi-bold;
					font-weight: 600;
					font-size: 16px;
				}
			}
			.card-infos {
				padding: 5px 25px 15px 25px;

				.card-options {
					padding: 10px;
					display: flex;
					flex-direction: row;
					align-items: center;
					padding-top: 15px;
					padding-bottom: 15px;

					input {
						width: 45px;
						height: 34px;
						border: 1px solid black;
					}
					select {
						width: 100px;
						height: 30px;
						border: 1px solid black;
						border-radius: 8px;
						margin-left: 10px;
					}
					label {
						color: black;
						font-family: 'Poppins', regular;
						font-size: 13px;
					}
					.input-color {
						div {
							flex-direction: row;
							.MuiSvgIcon-root {
								width: 45px;
								height: 34px;
							}
							.MuiSvgIcon-root {
								width: 45px;
								height: 34px;
							}
						}
					}
				}
			}

			.button-container {
				padding: 15px 33px 15px 33px;
				display: flex;
				flex-direction: column;

				button {
					height: 44px;
					border: none;
					background-color: #fff;
					border-radius: 7px;
					color: #979797;
					font-family: 'Poppins', regular;
					font-size: 20px;
				}
				button:hover {
					cursor: pointer;
				}
				button:focus {
					outline: none;
				}
			}
		}

		.next-button-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			cursor: pointer;
			.back-button {
				background-color: #69b64a;
				width: 34px;
				height: 36px;
				margin-right: 5px;
				border: 1px solid black;
				border-radius: 7px;

				.back-icon {
					width: 11px;
					height: 18px;
					.a {
						fill: white;
					}
				}
			}

			.svg-container {
				background-color: #69b64a;
				width: 47px;
				height: 50px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				margin-right: 6px;
				border-radius: 7px;
				background-color: #69b64a;

				.back-icon {
					.a {
						fill: white;
					}
				}
			}
			.svg-container:hover,
			button:hover {
				cursor: pointer;
			}
			button:focus {
				outline: none;
			}

			button {
				width: 247px;
				height: 50px;
				background-color: #69b64a;
				color: white;
				font-family: 'Poppins';
				font-weight: bold;
				font-size: 18px;
				border: 1px solid black;
				border-radius: 7px;
				width: 226px;
				height: 36px;
			}
		}
	}
	.infos-card {
		display: flex;
		flex-direction: column;
		padding: 23px;
		box-shadow: 3px 2px 9px #00000014;
		border-radius: 8px;
		align-items: flex-start;
		background-color: #fff;
		width: 382px;
		height: 298px;
		justify-content: center;
		border: 1px solid black;

		.infos {
			display: flex;
			flex-direction: column;
			padding: 20px;
			color: #979797;
			font-family: 'Poppins';
			font-weight: 600;
			width: 100%;

			h4 {
				color: black;
				font-size: 13px;
				font-family: 'Poppins';
				font-weight: 600;
			}

			label {
				color: #979797;
				font-family: 'Poppins', regular;
				font-size: 16px;
			}
		}
		.title-infos {
			width: 100%;
			padding: 20px;
			h2 {
				color: #6b98a6;
				font-family: 'Poppins';
				font-weight: 600;
				font-size: 16px;
				margin-bottom: 0px;
			}
			label {
				color: #979797;
				font-family: 'Poppins', regular;
				font-size: 12px;
			}
		}

		.price-info {
			padding: 20px;
			h2 {
				color: #6b98a6;
				font-family: 'Poppins';
				font-weight: 600;
				font-size: 17px;
			}
		}
	}
`;
export const Container = styled.div`
	background-color: #fff;
	display: flex;
	flex-direction: row;
	justify-content: center;
	height: 100%;
`;
