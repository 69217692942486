import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import IUserAccount from '../../../models/IUserAccount'
import { AppState } from '../../../store'
import {
    Purchase,
    useGetAllProductCategoriesQuery,
    usePurchaseFiltersLazyQuery,
    ProductCategories,
    useGetAllPurchaseStatusQuery,
    useGetPurchasesFiltersCsvLazyQuery,
    PurchaseFiltersInput,
} from '../../../generated/graphql'
import { MyProductContainer } from '../Style'
import { ContentContainer, Title, Card } from './Style'
import SalesCard from './SalesCard/SalesCard'
import { FaFileCsv } from 'react-icons/fa'
import {
    MdChevronLeft as Previous,
    MdChevronRight as Next,
} from 'react-icons/md'
import { Icon, Popup } from 'semantic-ui-react'

const Sales: React.FC = () => {
    const user: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )
    const [sales, setSales] = useState<Purchase[]>()
    const [limit, setLimit] = useState<number>(10)
    const [offset, setOffset] = useState<number>(0)
    const [searchValue, setSearchValue] = useState('')
    const { data: dataCategories, loading: loadingCategories } =
        useGetAllProductCategoriesQuery()
    const [
        filterPurchases,
        { data: dataPurchaseFilters, loading: loadingPurchaseFilters },
    ] = usePurchaseFiltersLazyQuery({
        fetchPolicy: 'no-cache',
    })
    const [getPurchasesFilterCsv, { data: dataPurchasesFilterCsv }] =
        useGetPurchasesFiltersCsvLazyQuery({})
    const { data: dataPurchaseStatus, loading: loadingPurchaseStatus } =
        useGetAllPurchaseStatusQuery()
    const [purchaseFilters, setPurchaseFilters] =
        useState<PurchaseFiltersInput>({
            sellerId: user.id,
            order: 'DESC',
            categories: [],
            typeNegotiation: ['Promoção', 'Venda'],
            limit,
        })

    useEffect(() => {
        if (!loadingCategories && !loadingPurchaseStatus) {
            setPurchaseFilters({
                ...purchaseFilters,
                categories: dataCategories?.getAllProductCategories?.map(
                    (categorie: ProductCategories) => {
                        return categorie.name
                    }
                ) as string[],
            })
        }
    }, [dataCategories, dataPurchaseStatus])

    useEffect(() => {
        if (!loadingPurchaseFilters && dataPurchaseFilters) {
            setSales(dataPurchaseFilters?.purchaseFilters as any)
        }
    }, [dataPurchaseFilters])
    useEffect(() => {
        filterPurchases({
            variables: {
                purchaseFiltersInput: purchaseFilters,
            },
        })
    }, [purchaseFilters])

    useEffect(() => {
        if (searchValue && sales) {
            const search = new RegExp(searchValue, 'i')

            const sellsFilter = sales.filter(
                (sells) =>
                    search.test(sells!.Product!.title) ||
                    search.test(sells!.UserAccount!.name) ||
                    search.test(sells!.paymentMethod!) ||
                    search.test(sells!.Status!.ptName)
            )
            setSales(sellsFilter)
        }
        if (searchValue?.length === 0) {
            filterPurchases({
                variables: {
                    purchaseFiltersInput: purchaseFilters,
                },
            })
        }
    }, [searchValue])

    function handleDownloadSalesCsv(): void {
        getPurchasesFilterCsv({
            variables: {
                purchaseFiltersInput: purchaseFilters,
            },
        })
    }

    useEffect(() => {
        if (dataPurchasesFilterCsv) {
            const blob = new Blob(
                [dataPurchasesFilterCsv.getPurchasesFiltersCsv],
                {
                    type: 'application/x-msexcel',
                }
            )
            const downloadLink = document.createElement('a')
            document.body.append(downloadLink)
            downloadLink.setAttribute('type', 'hidden')
            downloadLink.href = window.URL.createObjectURL(blob)
            downloadLink.download = `produtos_vendidos.csv`
            downloadLink.click()
        }
    }, [dataPurchasesFilterCsv])

    const getResults = (increase: boolean, length: number): void => {
        let newOffset = offset
        if (increase) {
            if (length === limit) {
                newOffset += limit
            }
        } else {
            if (offset - limit < 0) {
                newOffset = 0
            } else {
                newOffset -= limit
            }
        }

        setPurchaseFilters({
            ...purchaseFilters,
            offset: newOffset,
        })

        setOffset(newOffset)
    }

    const handleLimit = (newLimit: number): void => {
        setPurchaseFilters({
            ...purchaseFilters,
            limit: newLimit,
        })
        setLimit(newLimit)
    }

    return (
        <>
            <MyProductContainer>
                <Title>
                    <div className="title-organizer">
                        <h2>Minhas vendas</h2>
                        <FaFileCsv
                            onClick={handleDownloadSalesCsv}
                            size={26}
                            color="#04aac0"
                            style={{ marginTop: '0.2rem', cursor: 'pointer' }}
                        />
                    </div>
                </Title>
                <ContentContainer>
                    <div className="options-container">
                        <span>Listagem</span>
                        <select
                            onChange={(e) =>
                                setPurchaseFilters({
                                    ...purchaseFilters,
                                    order: e.target.value,
                                })
                            }
                            disabled={!!user.block}
                        >
                            <option value="DESC">Mais recentes</option>
                            <option value="ASC">Mais antigos</option>
                        </select>
                    </div>
                    <div className="options-container">
                        <span>Forma de Pagamento</span>
                        <select
                            onChange={(e) =>
                                setPurchaseFilters({
                                    ...purchaseFilters,
                                    paymentMethod:
                                        e.target.value === 'Todas'
                                            ? undefined
                                            : e.target.value,
                                })
                            }
                            disabled={!!user.block}
                        >
                            <option value={undefined}>Todas</option>
                            <option value="pix">PIX</option>
                            <option value="credit_card">
                                Cartão de Crédito
                            </option>
                            <option value="boleto">Boleto Bancário</option>
                        </select>
                    </div>

                    <div
                        className="options-container"
                        style={{
                            height: '52px',
                        }}
                    >
                        <span>Status da venda</span>
                        <select
                            onChange={(e) =>
                                setPurchaseFilters({
                                    ...purchaseFilters,
                                    status:
                                        e.target.value === 'Todas'
                                            ? undefined
                                            : e.target.value,
                                })
                            }
                            disabled={!!user.block}
                        >
                            <option value={undefined}>Todas</option>

                            {dataPurchaseStatus?.getAllPurchaseStatus.map(
                                (status, index: number) => (
                                    <option
                                        value={status!.engName!}
                                        key={index}
                                    >
                                        {' '}
                                        {status.ptName}{' '}
                                    </option>
                                )
                            )}
                        </select>
                    </div>
                    <div
                        className="options-container"
                        style={{
                            height: '52px',
                        }}
                    >
                        <span>Limitar resultados</span>
                        <select
                            onChange={(e) =>
                                handleLimit(Number.parseInt(e.target.value))
                            }
                            disabled={!!user.block || searchValue.length > 0}
                        >
                            <option value={10}>10 vendas por página</option>
                            <option value={20}>20 vendas por página</option>
                            <option value={30}>30 vendas por página</option>
                            <option value={40}>40 vendas por página</option>
                            <option value={50}>50 vendas por página</option>
                        </select>
                    </div>
                    <div
                        className="options-container"
                        style={{ width: '20rem' }}
                    >
                        <span>
                            Buscar Venda
                            <Popup
                                position="left center"
                                style={{ cursor: 'pointer' }}
                                trigger={<Icon name="info" color="yellow" />}
                                content={`Busque sua venda especifica Por nome do produto,Status de Pagamento,Nome do Comprador ou Identificador da Pagar.me`}
                            />
                        </span>

                        <input
                            type="text"
                            placeholder="Busque sua venda especifica"
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                    </div>
                </ContentContainer>

                <Card style={{ flexDirection: 'column' }}>
                    {!loadingPurchaseFilters &&
                        sales?.map((UserSellers: Purchase, index: number) => (
                            <>
                                <SalesCard
                                    UserSellers={UserSellers}
                                    key={index}
                                />
                            </>
                        ))}
                </Card>
                {sales && sales.length > 0 && (
                    <div
                        className={`
                        pagination tw-flex tw-flex-row tw-items-center
                        tw-justify-center tw-space-x-2 tw-py-4 tw-px-2
                    `}
                    >
                        <button
                            className={`
                            tw-flex tw-flex-row tw-items-center tw-font-primary
                            tw-text-xl tw-font-semibold tw-text-primary-700
                            disabled:tw-cursor-not-allowed disabled:tw-text-smoke-300`}
                            disabled={
                                offset === 0 ||
                                (searchValue !== undefined &&
                                    searchValue.length > 0)
                            }
                            onClick={() => {
                                if (sales && sales.length > 0 && offset > 0)
                                    getResults(false, sales.length)
                            }}
                        >
                            <Previous size={48} />
                            <span>Anterior</span>
                        </button>
                        <button
                            className={`
                            tw-flex tw-flex-row tw-items-center tw-font-primary
                            tw-text-xl tw-font-semibold tw-text-primary-700
                            disabled:tw-cursor-not-allowed disabled:tw-text-smoke-300
                        `}
                            disabled={
                                (sales && sales.length < limit) ||
                                (searchValue !== undefined &&
                                    searchValue.length > 0)
                            }
                            onClick={() => {
                                if (sales && sales.length === limit)
                                    getResults(true, sales.length)
                            }}
                        >
                            <span>Próximo</span>
                            <Next size={48} />
                        </button>
                    </div>
                )}
            </MyProductContainer>
        </>
    )
}
export default Sales
