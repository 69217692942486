import React, { useState } from 'react';
import { useHistory } from 'react-router';
import IUserAccount from '../../models/IUserAccount';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import UserHeader from './UserHeader/UserHeader';
import AdmininistratorHeader from './AdmininistratorHeader/AdministratorHeader';

const Header: React.FC = () => {
	const user: IUserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);

	return (
		<>
			{user && user.userTypeId === 1 ? (
				<AdmininistratorHeader />
			) : (
				<UserHeader />
			)}
		</>
	);
};
export default Header;
