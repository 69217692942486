import React from 'react';

import { Container } from './styles';

const ProhibitedItemsModal: React.FC = () => {
	return (
		<Container>
			<h1>Produtos proibidos no Sambay Express</h1>
			<ul>
				<li>
					{' '}
					- Produtos ou acessórios relacionados ao consumo de
					substâncias ilegais/ilícitas/alucinógenas.
				</li>
				<li>
					{' '}
					- Produtos ofensivos, discriminatórios ou que incitem ódio,
					preconceito e/ou segregação.
				</li>
				<li> - Produtos roubados/furtados/desviados.</li>
				<li> - Produtos novos frutos de carga roubada ou furtada.</li>
				<li> - Produtos de origem desconhecida.</li>
				<li> - Produtos falsificados/contrafeitos.</li>
				<li> - Produtos remanufaturados, mas vendidos como novos.</li>
				<li>
					{' '}
					- Produtos adulterados e/ou com embalagens adulteradas.{' '}
				</li>
				<li> - Produtos vendidos e entregues sem Nota Fiscal.</li>
				<li>
					{' '}
					- Produtos vendidos e entregues com Nota Fiscal adulterada,
					falsificada ou referente a produtos diversos dos vendidos.
				</li>
				<li>
					{' '}
					- Produtos que requeiram licenças/autorizações/permissões
					mas que sejam vendidos sem esses respectivos documentos.
				</li>
				<li>
					{' '}
					- Armas de fogo (e partes delas), explosivos de qualquer espécie, munições (completas ou partes delas), detonadores, espoletas e correlatos.
				</li>
			</ul>
		</Container>
	);
};

export default ProhibitedItemsModal;
