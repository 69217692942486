import React, {forwardRef,useCallback,useImperativeHandle,useRef,useState} from 'react';
import { CustomizationContainer } from './styles';
import chooseColor from '../../../assets/icons/chooseColor.svg';
import addPhoto from '../../../assets/icons/addPhoto.svg';
import {useCreateUserCustomizationMutation, useDeleteLogoMarcaMutation,useReturnToStandardMutation,useUploadBrandOrBackgroundMutation} from '../../../generated/graphql';
import IUserAccount from '../../../models/IUserAccount';
import { AppState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import {setCustomBackground,setCustomBrand,updateBackgroundColor,updateBrandColor,updateBrandName,updateBrandTextColor} from '../../../actions/UserCustomizations';
import IUserCustomizations from '../../../models/IUserCustomizations';
import AlertModal, { ModalHandles } from '../../AlertModal/AlertModal';
import {FiTrash2} from "@react-icons/all-files/fi/FiTrash2";
import {Popup} from "semantic-ui-react";

export interface CustomizationsHandles {
	showEditCustomizations: () => void;
}
type Props = {
	userId: number;
};

const CreateUserCustomizations: React.RefForwardingComponent<
	CustomizationsHandles,
	Props
> = ({ userId }, ref) => {
	const [showCustomization, setShowCustomization] = useState<boolean>(false);
	const [uploadBrandOrBackground] = useUploadBrandOrBackgroundMutation();
	const dispatch = useDispatch();
	const [reset] = useReturnToStandardMutation();
	const [deleteLogoMarca] = useDeleteLogoMarcaMutation()
	const [createOrEditCustomizations] = useCreateUserCustomizationMutation();
	const user: IUserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);

	const showEditCustomizations = useCallback(() => {
		if (showCustomization) {
			setShowCustomization(false);
		} else {
			setShowCustomization(true);
		}
	}, [showCustomization]);

	useImperativeHandle(ref, () => {
		return {
			showEditCustomizations,
		};
	});
	const userCustomizations: IUserCustomizations = useSelector(
		(state: AppState) => state.UserCustomizations.currentCustomizations
	);

	const modalRef = useRef<ModalHandles>(null);

	async function backToStandard():Promise<void> {
		try {
			if (await reset({ variables: { userId: user.id } })) {
				window.location.reload();
			}
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	const changeCustomization = async () => {
		await createOrEditCustomizations({
			variables: {
				brandColor: userCustomizations?.brandColor
					? userCustomizations?.brandColor
					: undefined,
				backgroundIsImage: userCustomizations?.hasBackground,
				brandIsImage: userCustomizations?.hasBanner,
				background: userCustomizations?.background
					? userCustomizations?.background
					: undefined,
				brand: userCustomizations?.brandColor
					? userCustomizations?.brand
					: undefined,
				brandTextColor: userCustomizations?.bannerTextColor
					? userCustomizations?.bannerTextColor
					: undefined,
				shopName: userCustomizations?.ShopName
					? userCustomizations?.ShopName
					: undefined,
				userId: user.id,
			},
		});
	};
	const uploadFile = async (file: any, type: string) => {
		if (type === 'background') {
			try {
				const result = await uploadBrandOrBackground({
					variables: {
						isBackground: true,
						Picture: file,
						userId: user.id,
					},
				});
				dispatch(
					setCustomBackground(result.data?.uploadBrandOrBackground)
				);
			} catch (error: any) {
				throw new Error(error.message);
			}
		} else {
			try {
				const result = await uploadBrandOrBackground({
					variables: {
						isBackground: false,
						Picture: file,
						userId: user.id,
					},
				});
				dispatch(setCustomBrand(result.data?.uploadBrandOrBackground));
			} catch (error: any) {
				throw new Error(error.message);
			}
		}
	};

	async function salvarAlteracoes():Promise<void>{
		setShowCustomization(false);
		await changeCustomization();
	}

	async function removeBrandAndBanner():Promise<void>{
		try{
			await deleteLogoMarca({ variables: { userId: user.id } })
			window.location.reload();
		}catch(error:any){
			throw new Error(error.message);
		}
		dispatch(updateBrandName(""))
	}

	return (
		<>
			<AlertModal
				ref={modalRef}
				AreaTitle={'Minha loja'}
				DescriptionText={'Capa adicionada com sucesso!'}
				reload={false}
			/>
			{showCustomization && (
				<CustomizationContainer>
					<div className="customize-brand-container">
						<div className="customize-brand">
							<div className="customize-brand-title">
								<h2>Customizar marca</h2>
								<Popup
									trigger={
										<FiTrash2
											style={{cursor:'pointer'}}
											onClick={removeBrandAndBanner}
											color='red' size={20}/>
									}
									content='Remover Marca'/>
							</div>

							<input
								type="text"
								onChange={(e) => {
									dispatch(updateBrandName(e.target.value));
								}}
								maxLength={30}
							/>

							<label htmlFor="colors">
								<div className="choose-color">
									<span>Escolha a cor do nome</span>
									<img src={chooseColor}></img>
									<input
										type="color"
										id="colors"
										onChange={(e) => {
											dispatch(
												updateBrandTextColor(
													e.target.value
												)
											);
										}}
									/>
								</div>
							</label>
							<label htmlFor="colors2">
								<div className="choose-color">
									<span>Escolha a cor do fundo</span>
									<img src={chooseColor}></img>
									<input
										type="color"
										id="colors2"
										onChange={(e) =>
											dispatch(
												updateBrandColor(e.target.value)
											)
										}
									/>
								</div>
							</label>
							<div className="choose-archive">
								<input
									type="file"
									id="uploadFile"
									onChange={(e: any) =>
										uploadFile(e.target.files[0], 'brand')
									}
								/>
								<label htmlFor="uploadFile">
									<span>Já tem uma marca? Suba aqui!</span>

									<img src={addPhoto}></img>
								</label>
							</div>
						</div>
					</div>
					<div className="customize-banner-container">
						<div className="customize-banner">
							<div className="title-container">
								<h2>Customizar capa</h2>
							</div>
							<div className="standard-banners-container">
								<img
									src={`${process.env.REACT_APP_STATIC_URL}/banner/1.png`}
									onClick={() =>
										dispatch(
											setCustomBackground(`${1}.png`)
										)
									}
									alt=""
								/>

								<img
									src={`${process.env.REACT_APP_STATIC_URL}/banner/2.png`}
									alt=""
									onClick={() =>
										dispatch(
											setCustomBackground(`${2}.png`)
										)
									}
								/>

								<img
									src={`${process.env.REACT_APP_STATIC_URL}/banner/3.png`}
									onClick={() =>
										dispatch(
											setCustomBackground(`${3}.png`)
										)
									}
									alt=""
								/>

								<img
									src={`${process.env.REACT_APP_STATIC_URL}/banner/4.png`}
									onClick={() =>
										dispatch(
											setCustomBackground(`${4}.png`)
										)
									}
									alt=""
								/>

								<img
									src={`${process.env.REACT_APP_STATIC_URL}/banner/5.png`}
									onClick={() =>
										dispatch(
											setCustomBackground(`${5}.png`)
										)
									}
									style={{ margin: '0' }}
									alt=""
								/>
							</div>

							<label htmlFor="colors3">
								<div className="choose-color">
									<span>Cor sólida</span>
									<img src={chooseColor} alt=""></img>
									<input
										type="color"
										id="colors3"
										onChange={(e) =>
											dispatch(
												updateBackgroundColor(
													e.target.value
												)
											)
										}
									/>
								</div>
							</label>
							<label htmlFor="inputBackground">
								<div className="archive-name">
									<input
										style={{ display: 'none' }}
										id="inputBackground"
										onChange={(e: any) =>
											uploadFile(
												e.target.files[0],
												'background'
											)
										}
										type="file"
									/>
									<span>
										{userCustomizations?.background
											? userCustomizations?.background
											: 'Subir capa personalizada'}
									</span>

									<img src={addPhoto} alt=""/>
								</div>
							</label>
						</div>
					</div>
					<div className="button-save-container">
						<div className="button-save">
							<button
								onClick={salvarAlteracoes}
								style={{ marginBottom: '10px' }}
							>
								Salvar alterações
							</button>
							<button onClick={backToStandard}>Redefinir</button>
						</div>
					</div>
				</CustomizationContainer>
			)}
		</>
	);
};

export default forwardRef(CreateUserCustomizations);
