import React, { useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import CommissionPolicy from '../../components/CommissionPolicy/CommissionPolicy';

const CommissionPolicyPage = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Layout title="Taxas e Comissionamento">
			<CommissionPolicy />
		</Layout>
	);
};

export default CommissionPolicyPage;
