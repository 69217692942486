import styled from 'styled-components';

export const ProfileMenuBar = styled.div`
	background-color: #fff;
	box-shadow: 0px 2px 8px #00000014;
	position: absolute;
	border-radius: 8px;
	padding: 5px;
	overflow: auto;
    height: 130px;
    z-index: 1;
    width: 23%;
    top: 3.5rem;
    right: 1%;
	.section-menu-bar{
        display: flex;
        flex-direction: column;
        justify-content: center;
        .section-option-profile{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height:30px;
            cursor: pointer;
            .style-icon-profile-bar{
                margin-right: 5px;
                height:45%;
            }
            .span-profile-menu-bar{
                font-size: 10px;
                font-family: 'Poppins';
                font-weight: 400;
                margin-top: 0;
            }
        }
        .section-option-sales{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height:30px;
            cursor: pointer;
            .icon-vendas{
                height:45%;
                margin-right: 5px;
            }
            .span-sales-menu-bar{
                font-size: 10px;
                font-family: 'Poppins';
                font-weight: 400;
                color: black;
            }
        }
        .section-option-help{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height:30px;
            cursor: pointer;
            .icon-help{
                height:45%;
                margin-right: 5px;
            }
            .span-help-menu-bar{
                font-size: 10px;
                font-family: 'Poppins';
                font-weight: 400;
                color: black;
            }
        }
        .section-option-exit{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height:30px;
            cursor: pointer;
            .icon-sair{
                height:45%;
                margin-right: 5px;
            }
            .span-sair-menu-bar{
                font-size: 10px;
                font-family: 'Poppins';
                font-weight: 400;
                color: black;
            }
        }
        .section-option-sac{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height:30px;
            cursor: pointer;
            .icon-suporte{
                height:18px;
                margin-right: 5px;
            }
            .span-sac-menu-bar{
                font-size: 10px;
                font-family: 'Poppins';
                font-weight: 400;
                color: black;
            }
        }


        .style-title-profile-menu-bar{
            font-family: 'Poppins';
            font-weight: 700;
            font-size: 16px;
            cursor: pointer;
            color:black;
            height: 40px;
            justify-content: center;
            display: flex;
            align-items: center;
            color:#6b97a5;
        }
        .span-profile-menu-bar{
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 15px;
            cursor: pointer;
            color:black;
            height: 40px;
            justify-content: center;
            display: flex;
            align-items: center;
        }
    }
`;
