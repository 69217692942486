import React, { useRef } from 'react';

import { Container, TopButtons, Bottom } from './styles';
import { ReactComponent as Categories } from '../../../assets/mobileIcons/categories.svg';
import { ReactComponent as Filters } from '../../../assets/mobileIcons/filters.svg';
import { ReactComponent as Favorites } from '../../../assets/mobileIcons/favorites.svg';
import mobileSearch from '../../../assets/mobileIcons/mobileSearch.svg';

import MobileSearchModal, {
	MobileSearchModalHandles,
} from './MobileSearch/MobileSearchModal';
import MobileCategoriesModal, {
	MobileCategoriesModalHandles,
} from './MobileCategoriesModal/MobileCategoriesModal';
import { useDispatch } from 'react-redux';
import { showProductSearchFilters } from '../../../actions/CookiesAction';

const MobileCategorysMenu: React.FC = () => {
	const modalRef = useRef<MobileSearchModalHandles>(null);
	const categoriesModalRef = useRef<MobileCategoriesModalHandles>(null);
	const dispatch = useDispatch();
	return (
		<Container>
			{/* <TopButtons> */}
				{/* AINDA NÂO FOI IMPLEMENTADO OS FAVORITOS */}
					{/* <div
						className="favorites"
						onClick={() => categoriesModalRef.current?.showModal()}
					>
						<Favorites className="icon" />
						<span>Favoritos</span>
					</div> */}
				{/* --/-- */}
				{/* <div
					className="categories"
					onClick={() => categoriesModalRef.current?.showModal()}
				>
					<Categories className="icon" />
					<span>Categorias</span>
				</div> */}

				{/* <div
					className="filters"
					onClick={() => modalRef.current?.showModal()}
				>
					<Filters className="icon" />
					<span>Filtro</span>
				</div> */}
			{/* </TopButtons> */}
			{/* <Bottom>
				<div className="img-background">
					<img src={mobileSearch} alt="" />
				</div>
				<span }>
					Procurar produtos
				</span>
			</Bottom> */}
			<MobileSearchModal ref={modalRef} />
			<MobileCategoriesModal ref={categoriesModalRef} />
		</Container>
	);
};

export default MobileCategorysMenu;
