import React, { useCallback, useRef, useState } from 'react';
import { useResetUserPasswordMutation } from '../../generated/graphql';

import {
	Container,
	HeaderContainer,
	InputsContainer,
	MainContainer,
	SendButtonContainer,
	SucessContainer,
} from './styles';
import { useHistory } from 'react-router';
import { Modal } from 'semantic-ui-react';
import back from '../../assets/icons/back.svg';
import sucess from '../../assets/icons/sucess.svg';
import AlertModal, { ModalHandles } from '../AlertModal/AlertModal';

const ForgotPassword: React.FC = () => {
	const [resetPassword] = useResetUserPasswordMutation();
	const [email, setEmail] = useState<string>();
	const history = useHistory();
	const modalRef = useRef<ModalHandles>(null);

	const resetPass = useCallback(async () => {
		try {
			await resetPassword({ variables: { email: email! } });
			setState({ dimmer: undefined, open: true });
		} catch (err: any) {
			modalRef.current?.handleOpenModal();
		}
	}, [resetPassword, email, setEmail]);
	const [state, setState] = useState({ dimmer: undefined, open: false });
	const show = (dimmer: any) => setState({ dimmer: dimmer, open: true });
	return (
		<Container>
			<AlertModal
				ref={modalRef}
				AreaTitle={'Redefinição de senha'}
				DescriptionText={
					'Falha ao enviar e-mail, verifique se o mesmo já possui cadastro na plataforma'
				}
				reload={false}
			/>
			<Modal
				open={state.open}
				onClose={() => !state.open}
				style={{ background: 'transparent', boxShadow: 'none' }}
			>
				<SucessContainer>
					<div className="sucess-card">
						<img src={sucess}></img>
						<h2>E-mail enviado</h2>
						<span>
							Verifique sua caixa de entrada e lixos eletrônicos,
							e acesse o link para prosseguir com a redefinição
						</span>
						<div className="card-actions">
							<button className="button-confirm">
								Reenviar e-mail
							</button>
							<button
								className="button-redirect"
								onClick={() => history.push('/')}
							>
								Ir para home
							</button>
						</div>
					</div>
				</SucessContainer>
			</Modal>
			<header>
				<div className="back-button-container">
					<div className="back-button">
						<img
							src={back}
							alt="voltar"
							onClick={() => history.push('/')}
						/>
						<span onClick={() => history.push('/')} className="voltarForgotPassword">Voltar</span>
					</div>
				</div>
				<HeaderContainer>
					<h2>Redefinição de Senha</h2>
					<h4>
						Enviaremos um e-mail com um link para redefinirmos sua
						senha
					</h4>
				</HeaderContainer>
			</header>
			<main>
				<MainContainer>
					<InputsContainer>
						<label>Email</label>
						<input
							type="email"
							onChange={(e) => setEmail(e.target.value)}
						/>
					</InputsContainer>
					<SendButtonContainer>
						<button onClick={() => resetPass()} className="StyleButtonEnviar">Enviar</button>
					</SendButtonContainer>
				</MainContainer>
			</main>
		</Container>
	);
};

export default ForgotPassword;
