import React, { useEffect, useState } from 'react'
import { Product, useGetAllProductsQuery } from '../../generated/graphql'
import ProductCard from '../ProductCard/ProductCard'
import AliceCarousel from 'react-alice-carousel'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { SliderContainer } from '../../utils/AliceCarouselCustomSlider'
import { Container } from './styles'
import { useHistory } from 'react-router'
import ALICE_CAROUSEL_DEFAULTS from '../../utils/AliceCarouselDefaults'

const PropertiesSlider: React.FC = () => {
    const { data, loading, error } = useGetAllProductsQuery({
        fetchPolicy: 'network-only',
        variables: {
            getAllProductInput: {
                type: '',
            },
        },
    })

    const [itens, setItens] = useState<any[]>()
    const history = useHistory()
    const responsive = {
        0: { items: 1 },
        320: { items: 2 },
        325: { items: 2 },
        425: { items: 2 },
        568: { items: 3 },
        768: { items: 4 },
        1024: { items: 6 },
    }

    const renderPrevButton = ({ isDisabled }: any): JSX.Element => {
        return (
            <span style={{ opacity: isDisabled ? '' : 1 }}>
                <MdChevronLeft color="black" size="3rem" />
            </span>
        )
    }

    useEffect(() => {
        if (data?.getAllProducts) {
            setItens(
                data.getAllProducts.filter(
                    (item) =>
                        item.categorieId !== 58 &&
                        item.typeNegotiation === 'Imoveis' &&
                        item.active &&
                        item.stock &&
                        item.stock > 0
                )
            )
        }
    }, [data])

    const renderNextButton = ({ isDisabled }: any): JSX.Element => {
        return (
            <span style={{ opacity: isDisabled ? '' : 1 }}>
                <MdChevronRight color="black" size="3rem" />
            </span>
        )
    }

    if (itens?.length === 0 || loading) {
        return <></>
    }

    return (
        <SliderContainer>
            <Container>
                <div className="home-container">
                    <div className="product-cards-container">
                        <div className="container-title">
                            <h1
                                onClick={() =>
                                    history.push(`/search/category/Imoveis`)
                                }
                            >
                                Imóveis
                            </h1>
                        </div>

                        <AliceCarousel
                            {...ALICE_CAROUSEL_DEFAULTS}
                            renderPrevButton={renderPrevButton}
                            renderNextButton={renderNextButton}
                            items={itens?.map((product: Product) => {
                                return (
                                    <ProductCard
                                        product={product}
                                        key={product?.id}
                                    />
                                )
                            })}
                        />
                    </div>
                </div>
            </Container>
        </SliderContainer>
    )
}

export default PropertiesSlider
