
import styled from 'styled-components';

export const Back = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px;
	height: 70px;
	background-color: #fff;
	text-align: center;
	justify-content: center;
	
	span {
		color: #6b98a6;
		font-size: 27px;
		font-weight: 600;
		align-items: center;
		text-align: center;

		@media (min-width:425px) and (max-width:767px){
			margin-left: 0;
			padding:0;
		}
		@media (min-width:375px) and (max-width:424px){
			margin-left: 0;
			padding:0;
		}
		@media (min-width:320px) and (max-width:374px){
			margin-left: 0;
			font-size: 20px;
			padding:0;
		}
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-left: 85px;
	padding-right: 85px;
	height: 100%;
	background-color:#fff;

	@media (min-width:425px) and (max-width:676px){
		padding: 0;
	}
	@media (min-width:375px) and (max-width:424px){
		padding: 0;
	}
	@media (min-width:320px) and (max-width:374px){
		padding: 0;	
	}
	
	.card-main-container {
        width: 100%;
        height: 35vh;
		display: flex;
		flex-direction: row;
		align-items: center;
		box-shadow: 2px 2px 8px #00000014;
		border-radius: 7px;
		margin-top: 20px;
		padding: 10px;
		background-color: #fff;
		border: 1px solid black;
		margin-bottom:20px;

		@media (min-width:425px) and (max-width:676px){
			padding:0;
			flex-direction: column;
			display: flex;
			width: 420px;
			height: 100%;
		}
		@media (min-width:375px) and (max-width:424px){
			padding:0;
			flex-direction: column;
			display: flex;
			width: 370px;
			height: 100%;
		}
		@media (min-width:320px) and (max-width:374px){
			padding:0;
			flex-direction: column;
			width: 315px;
			height: 100%;
		}

		.card-one {
			display: flex;
			flex-direction: row;
			align-items: center;
			flex: 2;

			
			@media (min-width:425px) and (max-width:767px){
				flex-direction: column;
				width:100%;
			}
			@media (min-width:375px) and (max-width:424px){
				flex-direction: column;
				width:100%;
			}
			@media (min-width:320px) and (max-width:374px){
				flex-direction: column;
				width:100%;
			}

			.product-pic-and-infos {
				display: flex;
				flex-direction: row;
				align-items: center;
				width:100%;

				@media (min-width:425px) and (max-width:767px){
					flex-direction: column;
					display: flex;
					justify-content: space-around;
				}
				@media (min-width:375px) and (max-width:424px){
					flex-direction: column;
					justify-content: space-around;
					display: flex;
				}
				@media (min-width:320px) and (max-width:374px){
					flex-direction: column;
					justify-content: space-around;
					display: flex;
				}

				.product-pic {
					border-left: solid 1px black;
					@media (min-width:425px) and (max-width:767px){
						border-left: none;
					}
					@media (min-width:375px) and (max-width:424px){
						border-left: none;
					}
					@media (min-width:320px) and (max-width:374px){
						border-left: none;
					}
					.border {
						height: 185px;
						padding-left: 30px;
						display: flex;
						flex-direction: row;
						align-items: center;

						@media (min-width:425px) and (max-width:767px){
							height: 100%;
							padding-left: 0;
							padding-right: 0;
							border-left: none;
						}
						@media (min-width:375px) and (max-width:424px){
							height: 100%;
							padding-left: 0;
							padding-right: 0;
							
						}
						@media (min-width:320px) and (max-width:374px){
							height: 100%;
							padding-left: 0;
							padding-right: 0;
						}

						img {
							border-radius: 20px;
							width: 135px;
							height: 89px;
							object-fit: contain;

							@media (min-width:425px) and (max-width:767px){
								width: 100px;
								margin-top: 1rem;
								border-radius: 5px;
								height: 60px;
							}
							@media (min-width:375px) and (max-width:424px){
								width: 100px;
								margin-top: 1rem;
								border-radius: 5px;
								height: 60px;
							}
							@media (min-width:320px) and (max-width:374px){
								width: 100px;
								margin-top: 1rem;
								border-radius: 5px;
								height: 60px;
							}
						}
					}
				}
				.product-infos {
					width: 10vw;
					text-align: center;
					margin-right:15px;

					@media (min-width:425px) and (max-width:767px){
						width: 100%;
						margin-right:0;
					}
					@media (min-width:375px) and (max-width:424px){
						width: 100%;
						margin-right:0;
					}
					@media (min-width:320px) and (max-width:374px){
						width: 100%;
						margin-right:0;
					}

					h3 {
						color: #6b97a5;
						font-size: 14px;
						font-family: 'Poppins', semi-bold;
					}
					span {
						color: #969696;
						font-size: 14.5px;
						font-family: 'Poppins', regular;
						width: 124px;
						word-break: break-word;
					}
					h2 {
						color: black;
						font-family: 'Poppins', semi-bold;
						font-size: 14px;
						font-weight: 700;
						text-align: center;
						white-space: nowrap;
    					overflow: hidden;
    					text-overflow: clip;

						@media (min-width:425px) and (max-width:767px){
							margin-top: 0.3rem;
							font-size: 12px;
							text-align:center;
							margin-bottom: 0;
							font-weight: 700;
							color: black;
							white-space: nowrap;
    						overflow: hidden;
    						text-overflow: clip;

						}
						@media (min-width:375px) and (max-width:424px){
							margin-top: 0.3rem;
							font-size: 12px;
							text-align:center;
							margin-bottom: 0;
							font-weight: 700;
							color: black;
							white-space: nowrap;
    						overflow: hidden;
    						text-overflow: clip;
						}
						@media (min-width:320px) and (max-width:374px){
							margin-top: 0.3rem;
							font-size: 12px;
							text-align:center;
							margin-bottom: 0;
							font-weight: 700;
							color: black;
							white-space: nowrap;
    						overflow: hidden;
    						text-overflow: clip;
						}
							
					}
				}
			}
			.product-status-container {
				flex: 1;
				width:100%;
				h2 {
					color: #6b97a5;
					font-family: 'Poppins', semi-bold;
					font-size: 17px;
					font-weight: 600;

					@media (min-width:425px) and (max-width:767px){
						font-size: 17px;
						font-weight: 700;
						padding-top:1.5rem;
					}
					@media (min-width:375px) and (max-width:424px){
						font-size: 15px;
						font-weight: 700;
						padding-top:1.5rem;
					}
					@media (min-width:320px) and (max-width:374px){
						font-size: 14px;
						font-weight: 700;
						padding-top:1.5rem;
					}

				}
				.product-status {
					padding: 50px;
					display: flex;
    				flex-direction: column;
    				align-items: center;
					text-align: center;

					@media (min-width:425px) and (max-width:767px){
						padding:0;
					}
					@media (min-width:375px) and (max-width:424px){
						padding:0;
					}
					@media (min-width:320px) and (max-width:374px){
						padding:0;
					}

					.boleto-download {
						width: 130px;
						height: 35px;
						background: #69b64a 0% 0% no-repeat padding-box;
						border: 1px solid black;
						border-radius: 8px;
						color: white;
						font-size: 14px;
						font-family: 'Poppins';
						font-weight: bold;
						cursor: pointer;

						&:hover {
							cursor: pointer;
						}

						@media (min-width:425px) and (max-width:767px){
							width: 116px;
   							height: 28px;
							font-size:11px;
						}
						@media (min-width:375px) and (max-width:424px){
							width: 116px;
   							height: 28px;
							font-size:11px;
						}
						@media (min-width:320px) and (max-width:374px){
							width: 116px;
   							height: 28px;
							font-size:11px;
						}
					}
				}
				span {
					color: black;
					font-family: 'Poppins', regular;
					font-size: 12px;
					text-align: center;
					margin-top: 5px;
					
					@media (min-width:425px) and (max-width:767px){
						width: 85%;
						font-size: 12px;
						margin-top:1rem;
					}
					@media (min-width:375px) and (max-width:424px){
						width: 85%;
						font-size: 12px;
						margin-top:1rem;
					}
					@media (min-width:320px) and (max-width:374px){
						width: 85%;
						font-size: 12px;
						margin-top:1rem;
					}
				}
			}
		}
		.card-two {
			display: flex;
			flex-direction: row;
			align-items: center;
			flex: 1.5;

			.user-infos-container {
				display: flex;
				flex-direction: row;
				align-items: center;
				width:100%;
				border-left: 1px solid black;
				height: 185px;
				justify-content:center;

				@media (min-width:425px) and (max-width:767px){
					padding:0;
					border-left: none;
				}
				@media (min-width:375px) and (max-width:424px){
					padding:0;
					border-left: none;
				}
				@media (min-width:320px) and (max-width:374px){
					padding:0;
					border-left: none;
				}

				.user-infos {
					display: flex;
					flex-direction: column;

					h2 {
						color: black;
						margin: 0px;
						align-self: start;
						font-size: 13px;
						font-family: 'Poppins';
						font-weight: bold;

						@media (min-width:320px) and (max-width:374px){
							font-size:11px;
						}
					}
					span {
						color: #969696;
					}
				}
				img {
					border-radius: 50px;
					margin-right: 10px;
					width: 50px;
					height: 50px;
					
					@media (min-width:425px) and (max-width:767px){
						width: 40px;
						height: 40px;
						border-radius: 40px;
					}
					@media (min-width:375px) and (max-width:424px){
						width: 35px;
						height: 35px;
						border-radius: 35px;
					}
					@media (min-width:320px) and (max-width:374px){
						width: 30px;
						height: 30px;
						border-radius: 30px;
					}
				}
			}

			.see-details {
				width:50%;
				border-left: solid 1px black;
				height: 185px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				@media (min-width:425px) and (max-width:767px){
					border-left: none;
					padding-left: 30px;
				}
				@media (min-width:375px) and (max-width:424px){
					border-left: none;
					padding-left: 30px;
				}
				@media (min-width:320px) and (max-width:374px){
					border-left: none;
					padding-left: 30px;
				}

				.button-rastreio {
					margin: 0;
					color: white;
					font-family: 'Poppins', semi-bold;
					font-size: 13px;
					font-weight: bold;
					margin-bottom: 0.5rem;
					border: 1px solid black;
					background-color: rgb(4, 170, 192);
					height: 35px;
					width:130px;

					@media (min-width:425px) and (max-width:767px){
						width: 116px;
						height: 28px;
						padding:0;
						font-size: 11px;
						font-weight: 700;
					}
					@media (min-width:375px) and (max-width:424px){
						width: 116px;
						height: 28px;
						padding:0;
						font-size: 11px;
						font-weight: 700;
					}
					@media (min-width:320px) and (max-width:374px){
						width: 116px;
						height: 28px;
						padding:0;
						font-size: 11px;
						font-weight: 700;
					}
				}
				.cancelar-compra {
					height: 35px;
					width:130px;
					background: #FF0000 0% 0% no-repeat padding-box;
					border: 1px solid black;
					border-radius: 8px;
					color: white;
					font-size: 13px;
					margin-top:0.5rem;
					font-family: 'Poppins';
					font-weight: bold;

					&:hover {
						cursor: pointer;
					}

					@media (min-width:425px) and (max-width:767px){
						width: 116px;
						height: 28px;
						padding:0;
						font-size: 11px;
						font-weight: 700;
					}
					@media (min-width:375px) and (max-width:424px){
						width: 116px;
						height: 28px;
						padding:0;
						font-size: 11px;
						font-weight: 700;
					}
					@media (min-width:320px) and (max-width:374px){
						width: 116px;
						height: 28px;
						padding:0;
						font-size: 11px;
						font-weight: 700;
					}
				}
				.avaliar-compra {
					height: 35px;
					width:130px;
					background: #469536 0% 0% no-repeat padding-box;
					border: 1px solid black;
					border-radius: 8px;
					color: white;
					font-size: 13px;
					margin-top:0.5rem;
					font-family: 'Poppins';
					font-weight: bold;
					margin-bottom:0.5rem;

					&:hover {
						cursor: pointer;
					}

					@media (min-width:425px) and (max-width:767px){
						width: 116px;
						height: 28px;
						padding:0;
						font-size: 11px;
						font-weight: 700;
					}
					@media (min-width:375px) and (max-width:424px){
						width: 116px;
						height: 28px;
						padding:0;
						font-size: 11px;
						font-weight: 700;
					}
					@media (min-width:320px) and (max-width:374px){
						width: 116px;
						height: 28px;
						padding:0;
						font-size: 11px;
						font-weight: 700;
					}
				}
				
				.cancelar-compra-disabled{
					background-color: #CDC9C9;
					margin-top: 6px;
					border: 1px solid black;
					
					:hover{
						cursor:not-allowed;
					}
					
					@media (min-width:425px) and (max-width:767px){
						width: 116px;
						height: 28px;
						padding:0;
						font-size: 11px;
						font-weight: 700;
					}
					@media (min-width:375px) and (max-width:424px){
						width: 116px;
						height: 28px;
						padding:0;
						font-size: 11px;
						font-weight: 700;
					}
					@media (min-width:320px) and (max-width:374px){
						width: 116px;
						height: 28px;
						padding:0;
						font-size: 11px;
						font-weight: 700;
					}
				}

				button {
					height: 35px;
					width:130px;
					background: #FF0000 0% 0% no-repeat padding-box;
					border-radius: 8px;
					color: white;
					font-size: 13px;
					font-family: 'Poppins';
					font-weight: bold;
					border: 1px solid black;
					&:hover {
						cursor: pointer;
					}

					@media (min-width:425px) and (max-width:767px){
						width: 116px;
						height: 28px;
						padding:0;
						font-size: 11px;
						font-weight: 700;
					}
					@media (min-width:375px) and (max-width:424px){
						width: 116px;
						height: 28px;
						padding:0;
						font-size: 11px;
						font-weight: 700;
					}
					@media (min-width:320px) and (max-width:374px){
						width: 116px;
						height: 28px;
						padding:0;
						font-size: 11px;
						font-weight: 700;
					}
							}
						}
					}

	}
`;


export const FormaDePagamentoContainer = styled.div`
	border-left: solid 1px black;
	height: 185px;
	width:100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	@media (min-width:425px) and (max-width:767px){
		width: auto;
		border-left: none;
		height:100px;
	}
	@media (min-width:375px) and (max-width:424px){
		width: auto;
		border-left: none;
		height:100px;
	}
	@media (min-width:320px) and (max-width:374px){
		width: auto;
		border-left: none;
		height:100px;
	}
	
	p {
		color: black;
		font-family: 'Poppins', semi-bold;
		font-size: 20px;
		font-weight: 600;
		@media (min-width:425px) and (max-width:767px){
			font-size: 12px;
			font-weight: 400;
		}
		@media (min-width:375px) and (max-width:424px){
			font-size: 12px;
			font-weight: 400;
		}
		@media (min-width:320px) and (max-width:374px){
			font-size: 12px;
			font-weight: 400;
		}
	}
	h3 {
		color: #6b97a5;
		font-family: 'Poppins', semi-bold;
		font-size: 15px;
		font-weight: 600;

		@media (min-width:425px) and (max-width:767px){
			font-size: 14px;
			font-weight: 700;
			text-align: center;
			margin-top:1rem;
		}
		@media (min-width:375px) and (max-width:424px){
			font-size: 13px;
			font-weight: 700;
			text-align: center;
			margin-top:1rem;
		}
		@media (min-width:320px) and (max-width:374px){
			font-size: 12px;
			font-weight: 700;
			text-align: center;
			margin-top:1rem;
		}
	}
	p{
		color: black;
		font-family: 'Poppins', semi-bold;
		font-size: 15px;
		font-weight: 600;

		@media (min-width:425px) and (max-width:767px){
			font-size: 12px;
			font-weight: 700;
			text-align: center;
		}
		@media (min-width:375px) and (max-width:424px){
			font-size: 12px;
			font-weight: 700;
			text-align: center;
		}
		@media (min-width:320px) and (max-width:374px){
			font-size: 11px;
			font-weight: 700;
			text-align: center;
		}

	}
	
	button{
		color:white;
		font-size: 13px;
		font-family: 'Poppins' sans-serif;
		font-weight: bold;
		border:1px solid black;
		border-radius: 8px;
		background-color: rgb(4,170,192);
		padding: 10px;
		cursor: pointer;
		height: 35px;
		width:130px;

		@media (min-width:425px) and (max-width:767px){
			width: 116px;
			height: 28px;
			font-size: 11px;
			text-align: center;
    		display: flex;
    		justify-content: center;
			align-items:center;
		}
		@media (min-width:375px) and (max-width:424px){
			width: 116px;
			height: 28px;
			font-size: 11px;
			text-align: center;
    		display: flex;
    		justify-content: center;
			align-items:center;
		}
		@media (min-width:320px) and (max-width:374px){
			width: 116px;
			height: 28px;
			font-size: 11px;
			text-align: center;
    		display: flex;
    		justify-content: center;
			align-items:center;
		}
	}
`