import React from 'react'
import { Link } from 'react-router-dom'
import { Product } from '../../generated/graphql'
import { ProductCardContainer } from './styles'
import { FaFireAlt } from '@react-icons/all-files/fa/FaFireAlt'
import Img from '../Default/CommomImg/Img'
import noPhoto from '../../assets/sem-produto-detalhe.png'

type Props = {
    product: Product
}

type CardDecoration = {
    color: string
    name: string
}

const ProductCard: React.FC<Props> = ({ product }) => {
    const verifyProductType = (): CardDecoration => {
        if (product.isSocialProject) {
            return {
                color: '#CA667E',
                name: 'Projeto Social',
            }
        }

        switch (product.typeNegotiation) {
            case 'Promoção': {
                return {
                    color: '#6085DB',
                    name: 'Promoção',
                }
            }
            case 'Venda': {
                return {
                    color: '#69B64A',
                    name: 'Produto',
                }
            }
            case 'Imoveis': {
                return {
                    color: '#05A5BF',
                    name: 'Imóvel',
                }
            }
            case 'Veiculos': {
                return {
                    color: '#FFC000',
                    name: 'Veículo',
                }
            }
            default: {
                return {
                    color: '#FF7E0F',
                    name: 'Leilão',
                }
            }
        }
    }
    return (
        <ProductCardContainer bottomColor={`${verifyProductType()?.color}`}>
            <Link
                to={`/product-view/${product.id}/all-data${
                    window.outerWidth < 768 ? '/' : ''
                }`}
            >
                {product.isSocialProject && (
                    <div className="product-social-project-icon">
                        <div className="social-project-icon-container">
                            <div className="heart"></div>
                        </div>
                    </div>
                )}
                {product.typeNegotiation === 'Promoção' && (
                    <div className="bargain-products-icon">
                        <div className="bargain-products-container">
                            <FaFireAlt
                                color="white"
                                size={20}
                                style={{ marginLeft: '5', marginTop: '4' }}
                            />
                        </div>
                    </div>
                )}
                <Img
                    src={`${process.env.REACT_APP_STATIC_URL}/product/${product.id}-1.jpeg`}
                    img={noPhoto}
                    alt="imagem do produto"
                />

                <div className="product-card-content">
                    <div className="price-container">
                        {product.price ? (
                            <h2>
                                {Number(product.price).toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL',
                                })}
                            </h2>
                        ) : (
                            <h2>
                                {Number(product.InitialValue).toLocaleString(
                                    'pt-br',
                                    {
                                        style: 'currency',
                                        currency: 'BRL',
                                    }
                                )}
                            </h2>
                        )}

                        {product.typeNegotiation === 'Promoção' && (
                            <>
                                 <div>
                                    <span className="tw-rounded tw-bg-primary-500 tw-px-1 tw-py-px tw-text-white ">
                                        {` ${product.useState}`}
                                    </span>
                                    {product.freeDelivery && (
                                         <span className={`
                                         tw-flex tw-appearance-none tw-flex-row tw-items-center tw-mt-1
                                         tw-space-x-1 tw-rounded tw-bg-primary-500
                                         tw-px-1 tw-py-px tw-text-base tw-font-semibold
                                         tw-text-white`}>
                                         <label>Frete Grátis!</label>
                                         </span>
                                    )}
                                </div>
                            </>
                        )}

                        {product.typeNegotiation === 'Venda' && (
                            <>
                                <div>
                                    <span className="tw-rounded tw-bg-primary-500 tw-px-1 tw-py-px tw-text-white">
                                        {`Produto ${product.useState}`}
                                    </span>
                                    {product.freeDelivery && (
                                         <span className={`
                                         tw-flex tw-appearance-none tw-flex-row tw-items-center tw-mt-1
                                         tw-space-x-1 tw-rounded tw-bg-primary-500
                                         tw-px-1 tw-py-px tw-text-base tw-font-semibold
                                         tw-text-white`}>
                                         <label>Frete Grátis!</label>
                                         </span>
                                    )}
                                </div>
                            </>
                        )}

                        {product.typeNegotiation === 'Imoveis' && (
                            <>
                                <div>
                                    <span className="tw-rounded tw-bg-primary-500 tw-px-1 tw-py-px tw-text-white">
                                        {`Imóvel ${product.useState}`}
                                    </span>
                                </div>
                            </>
                        )}

                        {product.typeNegotiation === 'Veiculos' && (
                            <>
                                <div>
                                    <span className="tw-rounded tw-bg-primary-500 tw-px-1 tw-py-px tw-text-white">
                                        {`Veículo ${product.useState}`}        
                                    </span>
                                </div>
                            </>
                        )}
                    </div>

                    <h2 className="tw-line-clamp-3">{product.title}</h2>
                    <div className="product-type-container">
                        <span>{verifyProductType()?.name}</span>
                    </div>
                </div>
            </Link>
        </ProductCardContainer>
    )
}

export default ProductCard
