import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	border-top: solid 1px #6b97a5;
	width: 950px;
	height: 84px;
	padding: 18px;
	margin-right: 29px;

	&:hover {
		background: #e1e1e1;
		cursor: pointer;
	}

	@media (max-aspect-ratio: 4/3) {
		margin-right: 9px;
		margin-bottom: 30px;
	}

	.user-photo {
		margin-right: 15px;

		img {
			width: 66px;
			height: 66px;
			border-radius: 50px;
			object-fit: cover;
		}
	}

	.user-informations {
		h3 {
			color: #0b0948;
			margin-bottom: -5px;
			font-family: 'Poppins';
			font-weight: bold;
			font-size: 20px;
		}
		span {
			color: #6b97a5;
			font-family: 'Poppins', regular;
			font-size: 10px;
		}
	}
`;
