import { Product, ProfileAddress } from '../generated/graphql';
import {
	IAddAddress,
	IAddCard,
	IAddInstallmentsNumber,
	IAddPaymentMethod,
	IAddProduct,
	IAddShippingValue,
	IAddShippingWay,
	IDecreaseProductQuantity,
	IIncreaseProductQuantity,
	IRemoveAddress,
	IRemoveCard,
	IUpdateProductPrice,
} from '../models/BuyParams';
import IUserCards from '../models/IUserCards';

export const addproduct = (product: Product): IAddProduct => ({
	type: 'ADD_PRODUCT',
	product,
});
export const UpdateProductPrice = (newPrice: number): IUpdateProductPrice => ({
	type: 'UPDATE_PRODUCT_PRICE',
	newPrice,
});

export const AddShippingWay = (shippingWay: string): IAddShippingWay => ({
	type: 'ADD_SHIPPING_WAY',
	shippingWay,
});
export const AddAddress = (address: ProfileAddress): IAddAddress => ({
	type: 'ADD_ADDRESS',
	address,
});
export const RemoveAddress = (): IRemoveAddress => ({
	type: 'REMOVE_ADDRESS',
});

export const AddShippingValue = (shippingValue: string): IAddShippingValue => ({
	type: 'ADD_SHIPPING_VALUE',
	shippingValue,
});

export const AddPaymentMethod = (paymentMethod: string): IAddPaymentMethod => ({
	type: 'ADD_PAYMENT_METHOD',
	paymentMethod,
});

export const AddCardId = (card: IUserCards): IAddCard => ({
	type: 'ADD_CARD',
	card,
});
export const RemoveCard = (): IRemoveCard => ({
	type: 'REMOVE_CARD',
});
export const AddInstallmentsNumber = (
	installmentsNumber: number
): IAddInstallmentsNumber => ({
	type: 'ADD_INSTALLMENTS_NUMBER',
	installmentsNumber,
});
export const IncreaseProductQuantityNumber = (
	productQuantity: number
): IIncreaseProductQuantity => ({
	type: 'INCREASE_PRODUCT_QUANTITY',
	productQuantity,
});
export const DecreaseProductQuantity = (
	productQuantity: number
): IDecreaseProductQuantity => ({
	type: 'DECREASE_PRODUCT_QUANTITY',
	productQuantity,
});
