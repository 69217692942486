import React from 'react'
import { Link } from 'react-router-dom'

import { Container, MainCard, PageContainer, PageHeader } from './styles'
import back from '../../assets/icons/back.svg'

const CommissionPolicy: React.FC = () => {
    return (
        <PageContainer>
            <Container>
                <PageHeader>
                    <Link to="/">
                        <img src={back} alt="" />
                    </Link>
                    <Link to="/">
                        <h3>Taxas e Comissionamento</h3>
                    </Link>
                </PageHeader>
                <MainCard>
                    <div className="items-container">
                        <h1>Taxas e Comissionamento</h1>
                        <p>
                            <strong>
                                Tabela de comissões cobradas no Sambay express
                                (abr/2023)
                            </strong>
                        </p>
                        <p>
                            <strong>
                                Todos os itens, com exceção dos móveis:{' '}
                            </strong>
                            9% (nove por cento).
                        </p>
                        <p>
                            <strong>Móveis: </strong>
                            17% (dezessete por cento).
                        </p>
                        <p>
                            <strong>Imóveis e Veículos: </strong>
                            0% (zero: cobrada apenas a tarifa de anúncio, quando
                            for o caso).
                        </p>
                        <p>
                            <strong>
                                Tabela de taxas de pagamento e transações 
                                (abr/2023)
                            </strong>
                        </p>
                        <p>
                            <strong>Taxa de Transferência (DOC/TED):</strong>
                        </p>
                        <p>R$ 3,67</p>
                        <p>
                            <strong>Taxa de Antecipação:</strong>
                        </p>
                        <p>2,85% por parcela</p>
                    </div>
                </MainCard>
            </Container>
        </PageContainer>
    )
}

export default CommissionPolicy
