import styled from 'styled-components';

interface BackgroundProps {
	background: string;
}

export const Container = styled.div<BackgroundProps>`
	width: 20vw;
	margin-left: 1rem;
	height: 12rem;
	background: ${(Prop) => Prop.background} 0% 0% no-repeat padding-box;
	border: 1px solid black;

	.disabled {
		background: #e1e1e1 0% 0% no-repeat padding-box;
	}
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 14px;
	margin-bottom: 30px;
	display: flex;
	flex-direction: row;
	padding: 15px;
	justify-content: flex-start;
	align-items: flex-start;

	&:hover {
		cursor: pointer;
	}
	
	.menu-icon {
		margin-right: 5px;
		width: 50px;
		height: 22px;
		.a {
			fill: #ff7e0f;
		}
	}
	.item-informations {
		h3 {
			color: #ff7e0f;
			margin-bottom: 4px;
			font-family: 'Poppins';
			font-weight: bold;
			font-size: 20px;
		}
		span {
			color: black;
			font-family: 'Poppins', regular;
			font-size: 12px;
		}
	}

`;
