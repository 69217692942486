import styled from 'styled-components';

export const Container = styled.form`
	padding: 28px 36px;
	background-color: #fff;

	h1 {
		color: #beb800;
		font-size: 20px;
		font-weight: bold;
		padding-bottom: 10px;
		margin: 0;
		font-family: 'Poppins', bold;
	}
`;

export const Options = styled.div`
	border-top: 1px solid black;
	padding-top: 30px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	row-gap: 20px;
	margin-bottom: 46px;
`;

export const InputOption = styled.div`
	label {
		font-size: 12px;
		margin-left: 13px;
		color: black;
		font-family: 'Poppins', semi-bold;
	}
`;

export const Description = styled.div`
	margin-bottom: 27px;

	span {
		color: black;
		font-family: 'Poppins', semi-bold;
		font-size: 14px;
	}

	textarea {
		margin-top: 14px;
		border-color: black;
		padding: 21px;
		width: 100%;
		height: 163px;
		font-family: 'Poppins', regular;
		font-size: 12px;
		color: black;
		outline: none;
		resize: none;
		background-color: #fff;
		border-radius: 8px;
	}
`;

export const Buttons = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;

	button + button {
		margin-left: 20px;
	}

	button {
		border-radius: 6px;
		height: 47px;
		padding: 0 30px;
		color: #fff;
		font-size: 14px;
		font-weight: bold;
		font-family: 'Poppins', bold;
		border: none;
		outline: none;
	}

	.cancel {
		background-color: #ca667e;
		cursor:pointer;
		border: 1px solid black;
	}

	.submit {
		background-color: #6b97a5;
		cursor:pointer;
		border: 1px solid black;
	}
`;
