import styled from 'styled-components';

export const ModalContainer = styled.form`
	display: flex;
	flex-direction: column;
	height: 100%;
`;
export const ModalTop = styled.header`
	padding: 10px 20px;
	border-bottom: solid 1px black;

	h2 {
		color: black;
		font-family: 'Poppins';
		font-weight: bold;
		font-size: min(15px, 1vw);
	}
`;
export const ModalMiddle = styled.main`
	padding: 10px 20px;
	display: flex;
	flex-direction: column;
	height: 100%;

	.back-button-container {
		display: flex;
		align-items: center;
		margin-bottom: 25px;

		img {
			margin-right: 10px;

			&:hover {
				cursor: pointer;
			}
		}
		span {
			color: black;
			font-family: 'Poppins';
			font-weight: 400;
			font-size: min(14px, 0.8vw);
		}
	}

	.balance {
		margin-bottom: 20px;
	}

	.input-container {
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
		.input {
			border-radius: 5px;
			border: solid 1px black;
			padding: 4px;
			color: #a8a8a8;
			font-family: 'Poppins', regular;
			font-weight: 400;
			font-size: 12px;
			&:focus {
				outline: none;
			}
			/* input[type='number'] {
				-moz-appearance: textfield;
			} */
		}
		/* input[type='number'] {
			-moz-appearance: textfield;
		} */

		.select {
			border-radius: 5px;
			border: solid 1px black;
			padding: 4px;
			color: #a8a8a8;
			font-family: 'Poppins', regular;
			font-weight: 400;
			font-size: 12px;

			&:focus {
				outline: none;
			}
		}

		label {
			font-family: 'Poppins';
			font-weight: bold;
			color: #969696;
			margin-bottom: 8px;
		}

		.error {
			border: solid 1px red;
		}
	}
`;
export const ModalBottom = styled.footer`
	padding: 10px 20px;
	display: flex;
	flex-direction: column;
	padding-bottom: 25px;

	span {
		margin-bottom: 15px;
		font-family: 'Poppins', regular;
		font-weight: 400;
		font-size: 12px;
		color: black;
	}

	.modal-actions {
		display: flex;
		justify-content: center;
		column-gap: 15px;

		.cancel-button {
			width: 25%;
			min-width: 90px;
			max-width: 116px;
			height: 41px;
			background: red;
			color: white;
			border: none;
			border-radius: 8px;
			font-family: 'Poppins';
			font-weight: bold;
			font-size: min(12px, 0.8vw);
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}

		.confirm-button {
			width: 25%;
			min-width: 90px;
			max-width: 116px;
			height: 41px;
			background: #6b97a5;
			color: white;
			border: none;
			border-radius: 8px;
			font-family: 'Poppins';
			font-weight: bold;
			font-size: min(12px, 0.8vw);
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}

		.disabled {
			opacity: 0.6;
		}
	}
`;
